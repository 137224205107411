/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import { useLocalize } from '@saviynt/common';
import {
  Box,
  Button,
  FooterActionBar,
  Icon,
  ModalIsland,
  Typography,
} from '@saviynt/design-system';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { msgs } from '../../../constants';

import './CustomPropertiesModal.css';

function CustomPropertiesModal({
  customPropertiesModalContentRef,
  // TODO: determine how to use this prop
  // eslint-disable-next-line no-unused-vars
  sectionRefIsScrolled,
  isCustomPropertiesModalOpen,
  setIsCustomPropertiesModalOpen,
  customProperties,
}) {
  const localize = useLocalize();

  const getModalIslandContent = () => (
    <Box className='CustomPropertiesModal-modalIslandContent'>
      {customProperties.map((customProperty, index) => {
        const isNotLastTwoValues =
          customProperties.length > 3 && index < customProperties.length - 2;

        return (
          <Box
            tag='div'
            className={classnames(
              'CustomPropertiesModal-customPropertiesRow',
              isNotLastTwoValues &&
                'CustomPropertiesModal-customPropertiesRow--withBorder'
            )}>
            <Typography kind='h3'>{customProperty.label}</Typography>
            <Typography
              kind='body2'
              className='CustomPropertiesModal-customPropertiesRow-value'>
              {customProperty.value}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );

  const getModalIslandFooterActionBar = () => (
    <Box className='CustomPropertiesModal-footer'>
      <Button
        kind='ghost'
        size='medium'
        onClick={() => {
          setIsCustomPropertiesModalOpen(false);
        }}>
        {localize(msgs.pam.sidePanel.cancel)}
      </Button>
    </Box>
  );

  return (
    <ModalIsland
      className='CustomPropertiesModal'
      onClose={() => {
        setIsCustomPropertiesModalOpen(false);
      }}
      isOpen={isCustomPropertiesModalOpen}
      headerIcon={<Icon kind='Feature' color='neutral-1000' size='large' />}
      contentBody={getModalIslandContent()}
      title={localize(msgs.pam.sidePanel.customProperties)}
      subtitle={localize(msgs.pam.sidePanel.customPropertiesModalSubheading)}
      sectionRef={customPropertiesModalContentRef}
      FooterActionBarComp={
        <FooterActionBar
          size='large'
          isScrolled={sectionRefIsScrolled}
          className='CustomPropertiesModal-footerActionBar'>
          {getModalIslandFooterActionBar()}
        </FooterActionBar>
      }
    />
  );
}

CustomPropertiesModal.propTypes = {
  customPropertiesModalContentRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  sectionRefIsScrolled: PropTypes.bool,
  isCustomPropertiesModalOpen: PropTypes.bool.isRequired,
  setIsCustomPropertiesModalOpen: PropTypes.func.isRequired,
  customProperties: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

CustomPropertiesModal.defaultProps = {
  customPropertiesModalContentRef: null,
  sectionRefIsScrolled: null,
};

export default CustomPropertiesModal;
