import {
  CLOUD_PROVIDERS,
  PLATFORMS_LABELS,
  TENANTS_LABELS,
} from '../components/LandingPageSidePanel/constants';

export const usePlatformLabel = (cloudProvider) =>
  // eslint-disable-next-line no-nested-ternary
  cloudProvider === CLOUD_PROVIDERS.AWS
    ? PLATFORMS_LABELS.AWS_ACCOUNT
    : cloudProvider === CLOUD_PROVIDERS.GCP
    ? PLATFORMS_LABELS.GCP_PROJECT
    : PLATFORMS_LABELS.AZURE_SUBSCRIPTION;

export const useTenantLabel = (cloudProvider) =>
  // eslint-disable-next-line no-nested-ternary
  cloudProvider === CLOUD_PROVIDERS.AWS
    ? TENANTS_LABELS.TENANT_AWS
    : cloudProvider === CLOUD_PROVIDERS.GCP
    ? TENANTS_LABELS.TENANT_GCP
    : TENANTS_LABELS.TENANT_AZURE;
