import React from 'react';
import { getCurrentDate } from '@saviynt/common';
import {
  differenceInHours,
  differenceInMinutes,
  endOfDay,
  isAfter,
  isSameDay,
  isSameHour,
  isSameMinute,
  isToday,
} from 'date-fns';

import SchedulerBlock from './SchedulerBlock/SchedulerBlock';
import { STATUS } from './constants';

/* ******************************
 * ********* NOW/TODAY **********
 * **************************** */

const now = getCurrentDate();
const isNow = isToday(now);

/**
 * A helper function for checking if a scheduled block time is happening today
 *
 * @function
 * @returns {boolean} A boolean denoting whether a scheduled block time is happening today
 *
 * @param blockTime {Date}
 */
const isSchedulerDayToday = (blockTime) => isNow && isSameDay(now, blockTime);

/* ******************************
 * *** MULTI-DAY RESERVATIONS ***
 * **************************** */

/**
 * A helper function for checking if a scheduled session covers multiple days
 *
 * @function
 * @returns {boolean} A boolean denoting whether a scheduled session covers multiple days
 *
 * @param session {Object} A session object
 */
const isMultiDayReservation = (session) => {
  const startDate = new Date(session.sessionStartDate);
  const endDate = new Date(session.sessionEndDate);

  return (
    !isSameDay(startDate, endDate) || isAfter(endDate, endOfDay(startDate))
  );
};

/* ******************************
 * ** SHORT-TERM RESERVATIONS ***
 * **************************** */

/**
 * A helper function for checking if a scheduled session is a short time reservation
 * session duration is less than or equal to 24 hours and
 * the end date is past the end of day of the start day (11:59PM UTC)
 *
 * @function
 * @returns {boolean} A boolean denoting whether a scheduled session is a short time reservation
 *
 * @param session {Object} A session object
 */
const isShortTermReservation = (session) => {
  const startDate = new Date(session.sessionStartDate);
  const endDate = new Date(session.sessionEndDate);

  return (
    differenceInHours(endDate, startDate) <= 24 &&
    isMultiDayReservation(session)
  );
};

/* ******************************
 * *** LONG-TERM RESERVATIONS ***
 * **************************** */

/**
 * A helper function for checking if a scheduled session is a long-term reservation
 * session duration is greater than 24 hours
 *
 * @function
 * @returns {boolean} A boolean denoting whether a scheduled session is a long-term reservation
 *
 * @param session {Object} A session object
 */
const isLongTermReservation = (session) => {
  const startDate = new Date(session.sessionStartDate);
  const endDate = new Date(session.sessionEndDate);

  return (
    differenceInHours(endDate, startDate) > 24 && isMultiDayReservation(session)
  );
};

/* ******************************
 * ****** SESSION DETAILS *******
 * **************************** */

/**
 * A helper function for checking if a session day is still reserved
 *
 * @function
 * @returns {boolean} A boolean denoting whether a session day is still reserved
 *
 * @param currentDate {Date} the current date
 * @param session {Object} A session object
 */
const isStillReserved = (currentDate, session) => {
  const endDate = new Date(session.sessionEndDate);

  return !isSameDay(currentDate, endDate);
};

/**
 * A helper function for mapping/constructing session details from a session response object
 *
 * @function
 * @returns {Object} A mapped session details object
 *
 * @param session {Object} A session object
 */
const getSessionDetails = (session) => ({
  firstname: session.firstname,
  lastname: session.lastname,
  username: session.username,
  sessionStartDate: session.sessionStartDate,
  sessionEndDate: session.sessionEndDate,
  requestId: session.requestId,
  sessionStatus: session.sessionStatus,
});

/* ******************************
 * ******* SCHEDULE BLOCK *******
 * **************************** */

/**
 * A helper function for mapping/constructing scheduler block for the scheduler day
 *
 * @function
 * @returns {Element} A constructed scheduler block for the scheduler day
 *
 * @param sessionDetails {Object} session details (if present) - for reserved blocks only
 * @param startTime {string} - a string start time
 * @param endTime {string} - a string end time
 * @param timeFormat {Object} - an object for formatting the time stamp
 * @param isModalView {boolean} - a boolean denoting whether the scheduler block is designed for a modal
 * @param showRequestId - a boolean denoting whether the scheduler block should display the request id
 */
const getScheduleBlock = (
  sessionDetails,
  startTime,
  endTime,
  timeFormat,
  isModalView,
  showRequestId
) => {
  if (sessionDetails) {
    return (
      <SchedulerBlock
        details={sessionDetails}
        startTime={startTime}
        endTime={endTime}
        timeFormat={timeFormat}
        isModalView={isModalView}
        status={STATUS.UNAVAILABLE}
        showRequestId={showRequestId}
      />
    );
  }

  return (
    <SchedulerBlock
      startTime={startTime}
      endTime={endTime}
      timeFormat={timeFormat}
      isModalView={isModalView}
      status={STATUS.AVAILABLE}
      showRequestId={showRequestId}
    />
  );
};

/**
 * A helper function for checking if 2 session times occur at the same time
 *
 * @function
 * @returns {boolean} A boolean denoting whether 2 session times occur at the same time
 *
 * @param timeA left-hand time to compare
 * @param timeB right-hand time to compare
 */
const isSameTime = (timeA, timeB) =>
  isSameHour(timeA, timeB) && isSameMinute(timeA, timeB);

/**
 * A helper function for checking if 2 session blocks occur subsequent/back-to-back
 *
 * @function
 * @returns {boolean} A boolean denoting whether 2 session blocks occur subsequent/back-to-back
 *
 * @param currentSessionStartDate start date of the current session
 * @param previousSessionEndDate end date of the previous session
 */
const isSubsequentSessionBlock = (
  currentSessionStartDate,
  previousSessionEndDate
) =>
  isSameTime(currentSessionStartDate, previousSessionEndDate) ||
  differenceInMinutes(currentSessionStartDate, previousSessionEndDate) <= 1;

export {
  getScheduleBlock,
  getSessionDetails,
  isLongTermReservation,
  isMultiDayReservation,
  isSameTime,
  isSchedulerDayToday,
  isShortTermReservation,
  isStillReserved,
  isSubsequentSessionBlock,
};
