/**
 * Formats a JavaScript Date object to a string suitable for storage in Redux to match user timezone.
 * This standardized format is useful for
 * storing date objects in Redux where serializability is important.
 *
 * @param {Date} date - The Date object to format.
 * @returns {string} A string representation of the date in the format
 * "EEE, dd LLL yyyy HH:mm:ss 'GMT'", adjusted to the GMT timezone.
 */

import { format } from 'date-fns';

// eslint-disable-next-line import/prefer-default-export
export const formatDateToReduxStore = (date) => {
  const formatDateFnsString = "EEE, dd LLL yyyy HH:mm:ss 'GMT'";
  const formatDateFnsOptions = { timeZone: 'GMT' };

  const formattedReduxDate = format(
    date,
    formatDateFnsString,
    formatDateFnsOptions
  );

  return formattedReduxDate;
};
