import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Chip, Icon } from '@saviynt/design-system';

export const JumpIcon = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.074 4.69025C20.1304 4.74848 20.1733 4.81498 20.2026 4.88576C20.2331 4.95945 20.25 5.04026 20.25 5.125V9.625C20.25 9.97018 19.9702 10.25 19.625 10.25C19.2798 10.25 19 9.97018 19 9.625V6.63388L11.0669 14.5669C10.8229 14.811 10.4271 14.811 10.1831 14.5669C9.93898 14.3229 9.93898 13.9271 10.1831 13.6831L18.1161 5.75H15.125C14.7798 5.75 14.5 5.47018 14.5 5.125C14.5 4.77982 14.7798 4.5 15.125 4.5H19.625C19.794 4.5 19.9473 4.56704 20.0597 4.67598C20.0646 4.68066 20.0693 4.68542 20.074 4.69025ZM5.53557 9.03557C5.71842 8.85272 5.96641 8.75 6.225 8.75H11.025C11.3702 8.75 11.65 8.47018 11.65 8.125C11.65 7.77982 11.3702 7.5 11.025 7.5H6.225C5.63489 7.5 5.06896 7.73442 4.65169 8.15169C4.23442 8.56896 4 9.13489 4 9.725V18.525C4 19.1151 4.23442 19.681 4.65169 20.0983C5.06896 20.5156 5.63489 20.75 6.225 20.75H15.025C15.6151 20.75 16.181 20.5156 16.5983 20.0983C17.0156 19.681 17.25 19.1151 17.25 18.525V13.725C17.25 13.3798 16.9702 13.1 16.625 13.1C16.2798 13.1 16 13.3798 16 13.725V18.525C16 18.7836 15.8973 19.0316 15.7144 19.2144C15.5316 19.3973 15.2836 19.5 15.025 19.5H6.225C5.96641 19.5 5.71842 19.3973 5.53557 19.2144C5.35272 19.0316 5.25 18.7836 5.25 18.525V9.725C5.25 9.46641 5.35272 9.21842 5.53557 9.03557Z"
      fill="#141419"
    />
  </svg>
);

const useStyles = makeStyles(() => ({
  icon: {
    marginRight: '4px',
    minWidth: '0',
  },
  chip: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

const MenuItemComponent = ({ option, handleMenuItemClick, selectedIndex }) => {
  const classes = useStyles();

  return (
    <MenuItem
      className={option.className}
      key={option}
      disabled={option.id === 0}
      selected={option.id === selectedIndex}
      onClick={(event) => handleMenuItemClick(event, option.id, option.url)}
    >
      <ListItemIcon className={classes.icon}>
        {option.showInfoIcon ? <Icon kind="InfoOutlined" /> : <JumpIcon />}
      </ListItemIcon>
      <ListItemText>{option.label}</ListItemText>
      {option.showChip && (
        <Chip
          className={classes.chip}
          size="xSmall"
          key={option.id}
          kind="boldFilled"
          label="New"
          color="success"
        />
      )}
    </MenuItem>
  );
};

MenuItemComponent.propTypes = {
  option: PropTypes.shape({
    label: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    showInfoIcon: PropTypes.bool,
    showChip: PropTypes.bool,
  }).isRequired,
  selectedIndex: PropTypes.string.isRequired,
  handleMenuItemClick: PropTypes.func.isRequired,
};

export default MenuItemComponent;
