export const SESSION_DETAILS_MODAL_ISLAND = {
  WINDOWS_SERVER: 'Windows Server',
  IP_PLACEHOLDER: 'Enter the system DNS host name or IP address',
  PORT_INPUT_PLACEHOLDER: 'Please enter a port number from 0-65353',
  PORT_NUMBER: 'Port Number',
  CANCEL: 'Cancel',
  LAUNCH: 'Launch',
  LAUNCH_SESSION: 'Launch Session',
  MODAL_ISLAND_SUBHEADING:
    'Specify the connection details of the remote system for this session.',
  SECONDARY_IP_CRIT_MESSAGE: 'Please provide a valid name or IP address',
  SECONDARY_PORT_CRIT_MESSAGE: 'Please enter a port number from 0-65353',
};

export const msgs = {
  pam: {
    sessionLauncher: {
      // Session Details Modal Island
      label: {
        ipInput: {
          id: 'pam.sessionLauncher.label.ipInput',
          defaultMessage: SESSION_DETAILS_MODAL_ISLAND.WINDOWS_SERVER,
        },
        portInput: {
          id: 'pam.sessionLauncher.label.portInput',
          defaultMessage: SESSION_DETAILS_MODAL_ISLAND.PORT_NUMBER,
        },
      },
      placeholder: {
        ipInput: {
          id: 'pam.sessionLauncher.placeholder.ipInput',
          defaultMessage: SESSION_DETAILS_MODAL_ISLAND.IP_PLACEHOLDER,
        },
        portInput: {
          id: 'pam.sessionLauncher.placeholder.portInput',
          defaultMessage: SESSION_DETAILS_MODAL_ISLAND.PORT_INPUT_PLACEHOLDER,
        },
      },
      buttons: {
        cancel: {
          id: 'pam.sessionLauncher.buttons.cancel',
          defaultMessage: SESSION_DETAILS_MODAL_ISLAND.CANCEL,
        },
        launch: {
          id: 'pam.sessionLauncher.buttons.launch',
          defaultMessage: SESSION_DETAILS_MODAL_ISLAND.LAUNCH,
        },
      },
      heading: {
        detailsHeading: {
          id: 'pam.sessionLauncher.heading.detailsHeading',
          defaultMessage: SESSION_DETAILS_MODAL_ISLAND.LAUNCH_SESSION,
        },
        detailsSubheading: {
          id: 'pam.sessionLauncher.heading.detailsSubheading',
          defaultMessage: SESSION_DETAILS_MODAL_ISLAND.MODAL_ISLAND_SUBHEADING,
        },
      },
      critical: {
        ipHelperText: {
          id: 'pam.sessionLauncher.critical.ipHelperText',
          defaultMessage:
            SESSION_DETAILS_MODAL_ISLAND.SECONDARY_IP_CRIT_MESSAGE,
        },
        portHelperText: {
          id: 'pam.sessionLauncher.critical.portHelperText',
          defaultMessage:
            SESSION_DETAILS_MODAL_ISLAND.SECONDARY_PORT_CRIT_MESSAGE,
        },
      },
    },
  },
};
