export const GetPrivilegedSessionsActionType = 'mysessions/getprivilegedsessionstype';
export const PrivilegedSessionsRecievedActionType = 'mysessions/privilegedsessionsrecievedtype';
export const PrivilegedSessionsFailedActionType = 'mysessions/privilegedsessionsfailedtype';
export const PrivilegedSessionsActionType = 'mysessions/privilegedsessionstype';
export const LaunchSessionActionType = 'mysessions/LaunchSessionActionType';
export const LaunchSessionsResponseRecievedActionType =
  'mysessions/LaunchSessionsResponseRecievedActionType';
export const resetSessionLaunchedFlagAction = 'mysessions/resetSessionLaunchedFlag';
export const resetDataPrivilegedSessionAction = 'mysessions/resetDataPrivilegedSession';
export const resetOpenFormFlagAction = 'mysessions/resetOpenFormDataFlag';
export const openFormDataAction = 'mysessions/openFormDataAction';
export const getMFAUrlsList = 'mysessions/mfaUrlsList';
export const getMFAUrlsListSuccess = 'mysessions/mfaUrlsListSuccess';
export const getMFAUrlsListFailure = 'mysessions/mfaUrlsListFailure';
export const submitSessionsRequestAction = 'mysessions/submitSessionsRequestAction';
export const submitSessionsResponseSuccessAction = 'mysessions/submitSessionsResponseSuccessAction';
export const closeRequestSubmitAction = 'mysessions/closeRequestSubmitSuccessAction';
export const setSessionDetModalAction = 'mysessions/setSessionDetModalAction';
export const setShowEndModalAction = 'mysessions/setShowEndModalAction';
export const getMfaEndSessionAction = 'mysessions/MfaEndSessionAction';
export const getServiceAccountCreateRequestSlotMFAAction =
  'getServiceAccountCreateRequestSlotMFAAction';
export const deleteSession = 'mysessions/deleteSession';


export const getPrivilegedSessions = ({
  listSearchText,
  sortByColumn,
  filter,
  reqBodyOptions,
}) => ({
  type: GetPrivilegedSessionsActionType,
  payload: { listSearchText, sortByColumn, filter, reqBodyOptions },
});

export const getPrivilegedSessionsRecieved = (data, totalSessionCount, count, status) => {
  const obj = { requestHistoryCount: count, data, totalSessionCount };

  return {
    type: PrivilegedSessionsRecievedActionType,
    payload: obj,
    status,
  };
};

export const getPrivilegedSessionsFailed = (data, status) => ({
  type: PrivilegedSessionsFailedActionType,
  payload: data,
  status,
});

export const captureSelectedPrivilegedSessionParams = data => ({
  type: PrivilegedSessionsActionType,
  payload: data.finalActiveSessionsSelected,
  typeOfAction: data.typeOfAction,
});

export const resetDataPrivilegedSessions = () => ({
  type: resetDataPrivilegedSessionAction,
});

export const LaunchSessions = (requestbody, param) => ({
  type: LaunchSessionActionType,
  payload: { requestbody, param },
});

export const LaunchSessionsResponseRecieved = (data, param) => ({
  type: LaunchSessionsResponseRecievedActionType,
  payload: { data, param },
});

export const resetLaunchSessionFlag = param => ({
  type: resetSessionLaunchedFlagAction,
  param,
});

export const resetOpenFormFlag = param => ({
  type: resetOpenFormFlagAction,
  param,
});

export const getMFAList = (data, showPassword) => ({
  type: 'getMFAUrlsList',
  data,
  showPassword,
});

export const getMFAListSuccess = response => ({
  type: 'getMFAUrlsListSuccess',
  response,
});

export const getMFAListFailure = error => ({
  type: 'getMFAUrlsListFailure',
  error,
});

export const openFormAction = formdata => ({
  type: openFormDataAction,
  formdata,
});

export const setSessionDetModal = data => ({
  type: setSessionDetModalAction,
  data,
});

export const handleSuccessCreateRequest = (status, data, param) => {
  const obj = { status, data, param };
  return {
    type: submitSessionsResponseSuccessAction,
    payload: obj,
  };
};

export const submitSessionsRequest = (data, fileData, param) => ({
  type: submitSessionsRequestAction,
  payload: data,
  fileData,
  from: param,
});

export const closeRequestSubmit = () => ({
  type: closeRequestSubmitAction,
});

export const getMFAEndSession = data => ({
  type: 'getMfaEndSessionAction',
  data,
});

export const setShowEnd = data => ({
  type: setShowEndModalAction,
  data,
});

export const getServiceAccountCreateRequestSlotMFA = data => ({
  type: getServiceAccountCreateRequestSlotMFAAction,
  data,
});

export const deleteSessionItem = sessionId => ({
  type: deleteSession,
  sessionId,
});
