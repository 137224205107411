export const invertedMessages = {
  "We're having trouble loading your Daily Summary at this time.":
    'Nav.KpisWidget.KpisRequestError',
  'Daily Summary': 'Nav.KpisWidget.DailySummaryHeading',
} as const;

export const kpiLabels: Record<string, string> = {
  'Nav.KpisWidget.Kpi-campaign-list': `{count, plural, one {<hide>{count}</hide> Pending Certification} other {<hide>{count}</hide> Pending Certifications}}`,
  'Nav.KpisWidget.Kpi-pending-approvals': `{count, plural, one {<hide>{count}</hide> Pending Approval} other {<hide>{count}</hide> Pending Approvals}}`,
  'Nav.KpisWidget.Kpi-request-history': `{count, plural, one {<hide>{count}</hide> Pending Request} other {<hide>{count}</hide> Pending Requests}}`,
  'Nav.KpisWidget.Kpi-change-self-password':
    '{count, plural, one {<hide>{count}</hide> Day Until Password Expires} other {<hide>{count}</hide> Days Until Password Expires}}',
} as const;
