import { useLocalize } from '@saviynt/common';

import { msgs } from '../../../components/ModalPageForms/constants';

const useModalPageStrings = (modalPageKind) => {
  const localize = useLocalize();

  // Localization
  // Page
  const MODAL_HEADER_TEXT = localize(msgs.pam[modalPageKind].page.modalHeader);
  const SUBMIT_BTN_TEXT = localize(msgs.pam.modalPage.page.submitBtn);
  // Critical
  const SUBMIT_TITLE_TEXT = localize(
    msgs.pam.modalPage.critical.submitErrorAlert
  );
  // Accordion Headings
  const ACCOUNT_HEADER_TEXT = localize(
    msgs.pam.modalPage.accordionHeading.accountHeader
  );
  const ACCOUNT_SUBHEADER_TEXT = localize(
    msgs.pam.modalPage.accordionHeading.accountSubheader
  );
  const APP_LAUNCHER_HEADER_TEXT = localize(
    msgs.pam.modalPage.accordionHeading.appLauncherHeader
  );
  const APP_LAUNCHER_SUBHEADER_TEXT = localize(
    msgs.pam.modalPage.accordionHeading.appLauncherSubheader
  );
  const DURATION_HEADER_TEXT = localize(
    msgs.pam.modalPage.accordionHeading.durationHeader
  );
  const DURATION_SUBHEADER_TEXT = localize(
    msgs.pam.modalPage.accordionHeading.durationSubheader
  );
  const JUST_DETAILS_HEADER_TEXT = localize(
    msgs.pam.modalPage.accordionHeading.detailsHeader
  );
  const JUST_DETAILS_SUBHEADER_TEXT = localize(
    msgs.pam.modalPage.accordionHeading.detailsSubheader
  );
  const CREDENTIAL_REQUEST_COMMENT_TEXT = localize(
    msgs.pam.modalPage.credentialRequest.comment
  );
  const EDITING_DURATION_CRITICAL_BANNER_TEXT = localize(
    msgs.pam.modalPage.critical.editingAlert
  );
  const ALERT_JUMP_TO_LINK_TEXT = localize(msgs.pam.common.links.view);
  const TCODE_HEADER_TEXT = localize(
    msgs.pam.modalPage.accordionHeading.tCodeHeader
  );
  const TCODE_SUBHEADER_TEXT = localize(
    msgs.pam.modalPage.accordionHeading.tCodeSubheader
  );

  return {
    MODAL_HEADER_TEXT,
    SUBMIT_BTN_TEXT,
    SUBMIT_TITLE_TEXT,
    ACCOUNT_HEADER_TEXT,
    ACCOUNT_SUBHEADER_TEXT,
    APP_LAUNCHER_HEADER_TEXT,
    APP_LAUNCHER_SUBHEADER_TEXT,
    DURATION_HEADER_TEXT,
    DURATION_SUBHEADER_TEXT,
    JUST_DETAILS_SUBHEADER_TEXT,
    JUST_DETAILS_HEADER_TEXT,
    CREDENTIAL_REQUEST_COMMENT_TEXT,
    EDITING_DURATION_CRITICAL_BANNER_TEXT,
    ALERT_JUMP_TO_LINK_TEXT,
    TCODE_HEADER_TEXT,
    TCODE_SUBHEADER_TEXT,
  };
};

export default useModalPageStrings;
