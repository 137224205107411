export const countries = [
    { code: 'ar', displayName: 'ARE', hoverName: 'Arabic', icon: 'arabic' },
    { code: 'fr_CA', displayName: 'CAN', hoverName: 'French (Canada)', icon: 'french' },
    { code: 'zh_CN', displayName: 'CHN', hoverName: 'Chinese (PRC)', icon: 'chinese' },
    { code: 'de', displayName: 'DEU', hoverName: 'German', icon: 'german' },
    { code: 'en', displayName: 'ENG', hoverName: 'English', icon: 'english' },
    { code: 'es', displayName: 'ESP', hoverName: 'Spanish (Spain)', icon: 'spanish' },
    { code: 'fr', displayName: 'FRA', hoverName: 'French', icon: 'french' },
    { code: 'it', displayName: 'ITA', hoverName: 'Italian', icon: 'italian' },
    { code: 'ja', displayName: 'JPN', hoverName: 'Japanese', icon: 'japnese' },
    { code: 'ko', displayName: 'KOR', hoverName: 'Korean', icon: 'korean' },
    { code: 'nl', displayName: 'NLD', hoverName: 'Dutch', icon: 'dutch' },
    { code: 'pl', displayName: 'POL', hoverName: 'Polish', icon: 'polish' },
    { code: 'pt', displayName: 'PRT', hoverName: 'Portuguese (Portugal)', icon: 'portuguese' },
    { code: 'ru', displayName: 'RUS', hoverName: 'Russian', icon: 'russian' },
    { code: 'th', displayName: 'THA', hoverName: 'Thai', icon: 'thai' },
    { code: 'tr', displayName: 'TUR', hoverName: 'Turkish', icon: 'turkish' },
    { code: 'zh_TW', displayName: 'TWN', hoverName: 'Chinese (Taiwan)', icon: 'chinese' },
]

export const ALL = { code: 'all', displayName: 'All', hoverName: 'All' };