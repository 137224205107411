import { type ActionType, getType } from 'typesafe-actions';

import { RequestStatus } from '../../../../models';
import { actions } from '../actions';
import type ShortcutsState from '../ShortcutsState';

import INITIAL_STATE from './initialState';

export default (
  // eslint-disable-next-line @typescript-eslint/default-param-last -- conform to reducer signature
  state = INITIAL_STATE,
  action: ActionType<typeof actions>
): ShortcutsState => {
  switch (action.type) {
    case getType(actions.shortcutsRequested):
      return {
        ...state,
        defaultShortcutsStatus: RequestStatus.Pending,
      };
    case getType(actions.shortcutsReceived):
      return {
        ...state,
        defaultShortcutsStatus: RequestStatus.Idle,
        defaultShortcutIds: action.payload,
      };
    case getType(actions.shortcutsNotReceived):
      return {
        ...state,
        defaultShortcutsStatus: RequestStatus.Idle,
        defaultShortcutIds: 'error',
      };
    case getType(actions.shortcutsUnauthorized):
      return {
        ...state,
        defaultShortcutsStatus: RequestStatus.Idle,
        defaultShortcutIds: 'unauthorized',
      };
    default:
      return state;
  }
};
