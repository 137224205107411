export const REQUEST_BASE_URL = '/request/privilegedAccess';

// Feel free to add/move your own msgs related constants here.
export const msgs = {
  pam: {
    common: {
      successExclamation: {
        id: 'pam.common.successExclamation',
        defaultMessage: 'Success!',
      },
    },
    landingPage: {
      errors: {
        assetsNotLoaded: {
          id: 'pam.landingPage.errors.assetsNotLoaded',
          defaultMessage: 'Assets Not Loaded',
        },
        assetsNotLoadedDesc: {
          id: 'pam.landingPage.errors.assetsNotLoadedDesc',
          defaultMessage:
            "Your list of privileged assets didn't load properly. Try again or contact your administrator.",
        },
      },
      hideSessions: {
        id: 'pam.landingPage.hideSessions',
        defaultMessage: 'Hide Sessions',
      },
      userAccessSearchInputPlaceholder: {
        id: 'pam.landingPage.userAccessSearchInputPlaceholder',
        defaultMessage:
          'Search by target system name, application name or role name',
      },
      description: {
        id: 'pam.landingPage.description',
        defaultMessage:
          'Connect to your privileged assets so you can launch live sessions and check credentials out when you need them.',
      },
      noMatchingSearchRecords: {
        id: 'pam.landingPage.noMatchingSearchRecords',
        defaultMessage: "Your search doesn't match any records",
      },
      sessionsHeader: {
        id: 'pam.landingPage.sessionsHeader',
        defaultMessage: 'Sessions',
      },
      availableAssetsHeader: {
        id: 'pam.landingPage.availableAssetsHeader',
        defaultMessage: 'Available Assets',
      },
    },
    requestSubmitAlertBanner: {
      pending: {
        id: 'pam.requestSubmitAlertBanner.pending',
        defaultMessage: 'Your request for {accountName} is Pending Approval.',
      },
      approved: {
        id: 'pam.requestSubmitAlertBanner.approved',
        defaultMessage:
          'Your request for {accountName} has been approved and will be available soon. Refresh your list in a few minutes.',
      },
    },
    requestRoleSubmitAlertBanner: {
      submitted: {
        id: 'pam.requestRoleSubmitAlertBanner.submitted',
        defaultMessage: 'Your request for {roleName} has been submitted.',
      },
    },
    upload: {
      uploadFailed: {
        id: 'pam.upload.uploadFailed',
        defaultMessage: 'Upload Failed',
      },
      uploadFailedDesc: {
        id: 'pam.upload.uploadFailedDesc',
        defaultMessage: 'Your files for {accountName} could not be uploaded.',
      },
    },
  },
};
