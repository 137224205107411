import {
  FETCH_PARALLEL_ENV,
  FETCH_PARALLEL_ENV_SUCCESS,
  FETCH_PARALLEL_ENV_ERROR,
} from '../actions/ParallelEnvAction';

export const initState = () => ({
  loading: false,
  data: null,
  errorMessage: null,
});

export const headerReducer =
  () =>
  (state = initState(), action) => {
    switch (action.type) {
      case FETCH_PARALLEL_ENV:
        return { ...state, loading: true };

      case FETCH_PARALLEL_ENV_SUCCESS:
        return { ...state, loading: false, data: action.data };

      case FETCH_PARALLEL_ENV_ERROR:
        return { ...state, loading: false, errorMessage: action.data };

      default:
        return state;
    }
  };

export default headerReducer();
