import React, { useState } from 'react';
import { useLocalize } from '@saviynt/common';
import { Box, Button, Chip, Icon, Typography } from '@saviynt/design-system';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { ModuleDetails, RoleDetails } from '../../../../../models/PamModels';
import { msgs, SECURITY_RANKINGS_LABELS } from '../../../constants';
import CustomPropertiesModal from '../../../Modals/Roles/CustomPropertiesModal/CustomPropertiesModal';
import SpvSection from '../../SpvSection/SpvSection';

import './SpvRole.css';

function SpvRole({ data, roleDetails, dataTestId, className }) {
  const classes = classnames('SpvRole', className);
  const localize = useLocalize();

  const [isCustomPropertiesModalOpen, setIsCustomPropertiesModalOpen] =
    useState(false);

  const hasSummaryFields =
    roleDetails?.roleDescription ||
    roleDetails?.roleDisplayName ||
    data?.roleDescription ||
    data?.platformType;

  const isNotNone = (value) => value.toLowerCase() !== 'none';

  const hasSecurityRankingsFields =
    (roleDetails?.risk && isNotNone(roleDetails?.risk)) ||
    (roleDetails?.soxCritical && isNotNone(roleDetails?.soxCritical)) ||
    (roleDetails?.sysCritical && isNotNone(roleDetails?.sysCritical)) ||
    (roleDetails?.privileged && isNotNone(roleDetails?.privileged)) ||
    // TODO: remove after it is corrected on the backend to be spelled correctly
    (roleDetails?.priviliged && isNotNone(roleDetails?.priviliged)) ||
    (roleDetails?.confidentiality && isNotNone(roleDetails?.confidentiality));

  const customPropertyString = 'customproperty';

  const hasCustomProperties = () => {
    let customPropertiesExist = false;

    Object.keys(roleDetails).forEach((key) => {
      if (key.includes(customPropertyString)) {
        customPropertiesExist = true;
      }
    });

    return roleDetails && customPropertiesExist;
  };

  const getCustomProperties = () => {
    const customPropertiesArray = [];

    Object.entries(roleDetails).forEach(([key, value]) => {
      if (key.includes(customPropertyString)) {
        const translationKey = `pam.sidePanel.${key}`;
        const reformattedKey = key.replace(
          customPropertyString,
          'Custom Property'
        );

        // ex: Custom Property 1
        msgs[translationKey] = {
          id: translationKey,
          defaultMessage: reformattedKey.replace(/(\d)/, ' $1'),
        };

        customPropertiesArray.push({
          label: localize(msgs[translationKey]),
          value,
        });
      }
    });

    return customPropertiesArray;
  };

  const isMoreThanFourCustomProperties = getCustomProperties().length > 4;

  const getSecurityRankings = (securityRanking) => {
    switch (securityRanking) {
      case SECURITY_RANKINGS_LABELS.VERY_LOW:
        return (
          <Chip
            kind='subtleFilled'
            color='success'
            size='small'
            label={localize(msgs.pam.sidePanel.securityRankingValues.veryLow)}
            className='SpvRoles-securityRankings'
          />
        );
      case SECURITY_RANKINGS_LABELS.LOW:
        return (
          <Chip
            kind='subtleFilled'
            color='success'
            size='small'
            label={localize(msgs.pam.sidePanel.securityRankingValues.low)}
            className='SpvRoles-securityRankings'
          />
        );
      case SECURITY_RANKINGS_LABELS.MEDIUM:
        return (
          <Chip
            kind='subtleFilled'
            color='warning'
            size='small'
            label={localize(msgs.pam.sidePanel.securityRankingValues.medium)}
            className='SpvRoles-securityRankings'
          />
        );
      case SECURITY_RANKINGS_LABELS.HIGH:
        return (
          <Chip
            kind='subtleFilled'
            color='critical'
            size='small'
            label={localize(msgs.pam.sidePanel.securityRankingValues.high)}
            className='SpvRoles-securityRankings'
          />
        );
      case SECURITY_RANKINGS_LABELS.CRITICAL:
        return (
          <Chip
            kind='subtleFilled'
            color='critical'
            size='small'
            label={localize(msgs.pam.sidePanel.securityRankingValues.critical)}
            className='SpvRoles-securityRankings'
          />
        );
      default:
        return null;
    }
  };

  const securityRankingsSectionHasDivider =
    hasSummaryFields || hasCustomProperties();

  return (
    <div className={classes} data-testid={dataTestId}>
      {hasSummaryFields && (
        <SpvSection
          title={localize(msgs.pam.sidePanel.summary)}
          fields={[
            {
              label: localize(msgs.pam.sidePanel.description),
              value: roleDetails?.roleDescription || data?.roleDescription,
            },
            {
              label: localize(msgs.pam.sidePanel.name),
              value: roleDetails?.roleDisplayName
                ? roleDetails?.roleName
                : null,
            },
            {
              label: localize(msgs.pam.sidePanel.application),
              value: data?.platformType,
            },
          ]}
        />
      )}
      {hasCustomProperties() && (
        <section>
          <SpvSection
            className={hasSummaryFields && 'SpvRole-section'}
            title={localize(msgs.pam.sidePanel.customProperties)}
            fields={
              isMoreThanFourCustomProperties
                ? getCustomProperties().slice(0, 4)
                : getCustomProperties()
            }>
            {isMoreThanFourCustomProperties && (
              <Box tag='div' className='SpvRole-additionalCustomProperties'>
                <Typography
                  kind='body2'
                  className='SpvRole-additionalCustomProperties--text'>
                  {`+${getCustomProperties().length - 4} more`}
                </Typography>
                <Button
                  type='button'
                  kind='ghost'
                  rightIcon={
                    <Icon kind='ArrowRight' size='medium' color='neutral-100' />
                  }
                  onClick={() => {
                    setIsCustomPropertiesModalOpen(true);
                  }}
                  className='SpvRole-showMoreCustomProperties'>
                  {localize(msgs.pam.sidePanel.showAll)}
                </Button>
              </Box>
            )}
          </SpvSection>
        </section>
      )}
      {hasSecurityRankingsFields && (
        <SpvSection
          className={securityRankingsSectionHasDivider && 'SpvRole-section'}
          title={localize(msgs.pam.sidePanel.securityRankings)}
          fields={[
            {
              label: localize(msgs.pam.sidePanel.risk),
              value: getSecurityRankings(roleDetails?.risk),
            },
            {
              label: localize(msgs.pam.sidePanel.soxCritical),
              value: getSecurityRankings(roleDetails?.soxCritical),
            },
            {
              label: localize(msgs.pam.sidePanel.sysCritical),
              value: getSecurityRankings(roleDetails?.sysCritical),
            },
            {
              label: localize(msgs.pam.sidePanel.privileged),
              value: getSecurityRankings(
                roleDetails?.privileged || roleDetails?.priviliged
              ),
            },
            {
              label: localize(msgs.pam.sidePanel.confidentiality),
              value: getSecurityRankings(roleDetails?.confidentiality),
            },
          ]}
        />
      )}
      {isCustomPropertiesModalOpen && hasCustomProperties() && (
        <CustomPropertiesModal
          isCustomPropertiesModalOpen={isCustomPropertiesModalOpen}
          setIsCustomPropertiesModalOpen={setIsCustomPropertiesModalOpen}
          customProperties={getCustomProperties()}
        />
      )}
    </div>
  );
}

SpvRole.propTypes = {
  data: ModuleDetails,
  roleDetails: RoleDetails.isRequired,
  dataTestId: PropTypes.string,
  className: PropTypes.string,
};

SpvRole.defaultProps = {
  data: null,
  dataTestId: null,
  className: null,
};

export default SpvRole;
