import React from 'react';
import './Footer.css';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { defineMessages } from '@formatjs/intl';
import { StringToHTML } from 'ui-components/src';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const Footer = props => {
    const messages = defineMessages({
        footer: {
            id: 'APP.FOOTER',
        },
    });

    return (
      <div className="footer-container">
        <StringToHTML
            string={props.intl.formatMessage(messages.footer, {
                0: new Date().getFullYear(),
                1: 'Saviynt',
                2: props.version || '',
            })}
            className="footer-layout"
        />
      </div>
    );
};

Footer.propTypes = {
    version: PropTypes.string.isRequired,
    intl: PropTypes.arrayOf(
        PropTypes.shape({
            defineMessages: PropTypes.func.isRequired,
        })
    ).isRequired,
};

const mapStateToProps = state => ({
    version: state.user.ecmVersion,
});

const ConnectedFooter = injectIntl(withRouter(connect(mapStateToProps)(Footer)));
ConnectedFooter.displayName = 'ConnectedFooter';
export default ConnectedFooter;
