import React, { useEffect, useState, } from 'react';
import { connect, useSelector } from 'react-redux';
import * as qs from 'query-string';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { checkEnvironment } from 'ui-common/src/lib/utils';
import ScrollPaginationDropdown from './ScrollPaginationDropdown';

const OutlinedScrollComponent = ({
  placeholder,
  _handleChange,
  attributeKey,
  defaultValue,
  required,
  showError,
  single,
  readOnly,
  descriptionType,
  scrollToError,
  intl,
  name,
  supportsHtmlTags,
  parentAttribute,
  fetchOptionsList,
  defaultOptions,
  includeBlankOption,
  clearOnBlur,
  clearOnEscape,
  ...props
}) => {
  const parsed = qs.parse(props.location.search, { decode: false });
  try {
    if (parsed.search) parsed.search = atob(parsed.search);
  } catch {
    // exception
  }

  const [items, setItems] = useState([]);
  const [hasNext, setHasNext] = useState(true);

  const PAGE_SIZE = 25;
  const { GATEWAY_API_URL } = checkEnvironment();
  const url = `${GATEWAY_API_URL}/api${props.apiURL}${props.endpointKey}`;

  useEffect(() => {
    if (!props.apiURL) setItems([]);
  }, [props.apiURL]);

  const fetchItems = (offset, findBy, clearList) => {
    if (clearList) setItems([]);
    if (typeof findBy !== 'string') return;
    if (props.apiURL && props.endpointKey !== undefined) {
      const reqBody = {
        offset,
        max: PAGE_SIZE,
        findBy,
      };
      fetchOptionsList(url, reqBody).then(res => {
        if (Array.isArray(res)) {
          if(includeBlankOption && findBy === "") res.unshift({id:"", value:"None"});
          if (clearList) setItems([...res]);
          else setItems([...items, ...res]);
          setHasNext(res.length === PAGE_SIZE);
        } else {
          setHasNext(false);
        }
      });
    }
  };

  const menuStyle = { width: '100%' };

  return (
    <ScrollPaginationDropdown
      multiSelect={!single}
      fetchItems={fetchItems}
      items={items}
      totalCount={0}
      hasNext={hasNext}
      offset={1}
      placeholder={placeholder}
      getId={option => option.id}
      getLabel={option => option.value}
      _handleChange={_handleChange}
      attributeKey={attributeKey}
      defaultValue={defaultValue}
      required={required}
      showError={showError}
      readOnly={readOnly}
      descriptionType={descriptionType}
      scrollToError={scrollToError}
      name={name}
      supportsHtmlTags={supportsHtmlTags}
      menuStyle={menuStyle}
      clearOnBlur={clearOnBlur}
      clearOnEscape= {clearOnEscape}
      {...props}
    />
  );
};

OutlinedScrollComponent.propTypes = { 
  _handleChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  attributeKey: PropTypes.string.isRequired,
  apiURL: PropTypes.string.isRequired,
  defaultValue: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  required: PropTypes.bool.isRequired,
  showError: PropTypes.bool.isRequired,
  single: PropTypes.bool,
  readOnly: PropTypes.bool.isRequired,
  descriptionType: PropTypes.string.isRequired,
  supportsHtmlTags: PropTypes.bool,
  includeBlankOption: PropTypes.bool
};
 
export default injectIntl(withRouter(OutlinedScrollComponent));
