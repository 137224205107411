
import React from 'react';

const SideMenuIconBack = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M3.50012 6.16675C3.22398 6.16675 3.00012 6.39061 3.00012 6.66675C3.00012 6.94289 3.22398 7.16675 3.50012 7.16675H14.5001C14.7763 7.16675 15.0001 6.94289 15.0001 6.66675C15.0001 6.39061 14.7763 6.16675 14.5001 6.16675H3.50012Z" fill="var(--SIDEMENU-ICON-HAMBURGER)"/>
<path d="M3.50012 11.5001C3.22398 11.5001 3.00012 11.7239 3.00012 12.0001C3.00012 12.2762 3.22398 12.5001 3.50012 12.5001H19.2929L17.4798 14.3132C17.2846 14.5085 17.2846 14.825 17.4798 15.0203C17.6751 15.2156 17.9917 15.2156 18.1869 15.0203L20.8482 12.359C20.9419 12.2681 21.0001 12.1409 21.0001 12.0001C21.0001 11.8793 20.9573 11.7685 20.8859 11.682C20.8758 11.6697 20.865 11.6578 20.8537 11.6465C20.8537 11.6465 20.8537 11.6465 20.8537 11.6465L18.1869 8.97986C17.9917 8.7846 17.6751 8.7846 17.4798 8.97987C17.2846 9.17513 17.2846 9.49172 17.4798 9.68697L19.293 11.5001H3.50012Z" fill="var(--SIDEMENU-ICON-HAMBURGER)"/>
<path d="M3.00012 17.3334C3.00012 17.0573 3.22398 16.8334 3.50012 16.8334H14.5001C14.7763 16.8334 15.0001 17.0573 15.0001 17.3334C15.0001 17.6096 14.7763 17.8334 14.5001 17.8334H3.50012C3.22398 17.8334 3.00012 17.6096 3.00012 17.3334Z" fill="var(--SIDEMENU-ICON-HAMBURGER)"/>
</svg>

);

export default SideMenuIconBack;

