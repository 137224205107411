import React from 'react';
import { useSelector } from 'react-redux';
import RequestHome from './views/RequestHomeDashboard';
import Gen2HomePage from './views/HomePage/HomePage';

const RequestHomePage = () => {
  const { gen2HomePage } = useSelector((state) => state.user);

  return <div>{gen2HomePage === '0' ? <RequestHome /> : <Gen2HomePage />}</div>;
};

export default RequestHomePage;
