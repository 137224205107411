import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MuiButton from '@material-ui/core/Button';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import { CloseButton } from '../CustomButtons';
import TimeframeForm from './TimeframeForm';
import style from './TimeframePopup.module.scss';

const Button = withStyles(theme => ({
  root: {
    textTransform: 'none',
    fontSize: 10,
    padding: 6,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
  },
}))(MuiButton);

const TimeframePopup = props => {
  const disableBusinessJustification =
    (props.configData === 0 || props.configData === 1) && props.businessJustification === '';
  return (
    <div className={style.popupContent}>
      <span className={style.popupHeaders}>
        {`${props.intl.formatMessage({ id: 'pa.timeframe.header' })} ${props.accountName}`}
      </span>
      <CloseButton handleClose={props.handlePopoverClose} small defaultPosition />
      <hr style={{ marginTop: 7, marginBottom: 20, width: 391, marginLeft: -18 }} />

      <TimeframeForm {...props} />
      <hr style={{ width: 391, marginLeft: -18 }} />
      <div className={`d-flex ${style.timeFrameButtons}`}>
        <button className={style.btnCancel} onClick={props.handlePopoverClose}>
          {props.intl.formatMessage({ id: 'Cancel' })}
        </button>
        <Button
          disabled={props.disableButton || disableBusinessJustification}
          variant="outlined"
          color="primary"
          onClick={props.keepSelecting}
        >
          {props.intl.formatMessage({ id: 'pa.timeframe.doneButton' })}
        </Button>

        {/* <Button
          disabled={props.disableButton}
          variant="outlined"
          color="primary"
          style={{ marginLeft: 8 }}
          onClick={props.proceedWithRequest}
        >
          Proceed with this Request
        </Button> */}
      </div>
    </div>
  );
};

TimeframePopup.propTypes = {
  accountName: PropTypes.string.isRequired,
  handlePopoverClose: PropTypes.func.isRequired,
  keepSelecting: PropTypes.func.isRequired,
  proceedWithRequest: PropTypes.func.isRequired,
  disableButton: PropTypes.bool.isRequired,
  intl: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string.isRequired,
      formatMessage: PropTypes.func.isRequired,
    })
  ).isRequired,
};

export default injectIntl(TimeframePopup);
