import React, { useEffect } from 'react';
import { Button, ModalIsland } from '@saviynt/design-system';
import { type Action, type Dispatch } from 'redux';

import { IntlBridge } from '../../../utilities';
import {
  type AppDispatch,
  privateActions,
  type RootState,
  useDispatch,
  useSelector,
} from '../store';

const CODE_TOGGLE_DEFAULT = 'false' as const;

interface SelectedState {
  isOpen: boolean;
  onSuccessMessage?: Action;
  shouldSkipPrompt: boolean;
}

/**
 * Allows users to verify their identity if MFA is enabled. To activate the
 * modal, dispatch a "verificationRequired" action. The action should include a
 * "callback action", which will be dispatched when the user successfully
 * verifies their identity.
 */
export default IntlBridge(PromptModal);

function PromptModal() {
  const dispatch = useDispatch();
  const { isOpen, onSuccessMessage, shouldSkipPrompt } =
    useSelector(selectState);
  const onVerifyClick = () => dispatchVerifications(dispatch, onSuccessMessage);
  const onClose = () => dispatch(privateActions.verificationDismissed());

  useEffect(() => {
    if (shouldSkipPrompt && isOpen) {
      dispatchVerifications(dispatch, onSuccessMessage);
    }
  }, [shouldSkipPrompt, isOpen, dispatch, onSuccessMessage]);

  if (shouldSkipPrompt) {
    return null;
  }

  return (
    <ModalIsland
      onClose={onClose}
      isOpen={isOpen}
      title='Verify Your Identity'
      contentBody={
        <div>
          MFA required but not verified{' '}
          <Button onClick={onVerifyClick}>Verify</Button>
        </div>
      }
    />
  );
}

function selectState(state: RootState): SelectedState {
  const featureMfa = state.user.domainInfo?.FEATURE_MFA === true;
  const { isOpen, onSuccessMessage } = state.mfaPrompt;
  const codeToggleEnabled = !['0', 'false', 'disabled'].includes(
    (
      localStorage.getItem('code-toggle:nav/mfa-prompt') ?? CODE_TOGGLE_DEFAULT
    ).toLowerCase()
  );
  const shouldSkipPrompt = !featureMfa || !codeToggleEnabled;

  return { shouldSkipPrompt, isOpen, onSuccessMessage };
}

function dispatchVerifications(
  dispatch: AppDispatch,
  onSuccessMessage?: Action
) {
  if (onSuccessMessage) {
    (dispatch as Dispatch)(onSuccessMessage);
  }

  dispatch(privateActions.verificationSucceeded());
}
