/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-curly-spacing */
import React, { useCallback, useRef, useState } from 'react';
import {
  debounce,
  DEFAULT_INPUT_DEBOUNCE_DELAY,
  Logger,
  useLocalize,
} from '@saviynt/common';
import {
  Box,
  ButtonIcon,
  Chip,
  Icon,
  InlineMessage,
  MenuInput,
} from '@saviynt/design-system';
import PropTypes from 'prop-types';

import { getTcodesApi } from '../../../../utilities/api/getDataFromApi';
import { msgs } from '../../constants';

import './MpaTcodeSelection.css';

const MAX_TCODES_SELECTABLE = 20;

function MpaTcodeSelection({
  tCodesSelection,
  setTCodesSelection,
  endpointDetails,
}) {
  const localize = useLocalize();
  const offsetApiRef = useRef(0);
  const lastScrollTopRef = useRef(0); // track the last scroll position

  const TCODES_PLACEHOLDER_TEXT = localize(
    msgs.pam.modalPage.tCodes.tCodesPlaceholder
  );
  const TCODES_NOT_FOUND = localize(msgs.pam.modalPage.tCodes.noTcodes);
  const LOADING_RESULTS = localize(msgs.pam.modalPage.tCodes.loadingResults);
  const MAX_ALLOWED_TCODES = localize(
    msgs.pam.modalPage.tCodes.maxAllowedTcodes
  );

  const LOAD_INCREMENT = 10;
  const [searchTerm, setSearchTerm] = useState('');
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMoreOptions, setHasMoreOptions] = useState(true);
  const [showMaxSelectionMessage, setShowMaxSelectionMessage] = useState(false);

  function convertOptionsToJson(initialOptions) {
    if (!initialOptions) {
      return [];
    }

    if (
      initialOptions.every(
        (option) =>
          typeof option === 'object' &&
          Object.prototype.hasOwnProperty.call(option, 'label')
      )
    ) {
      return initialOptions;
    }

    if (Array.isArray(initialOptions)) {
      const convertedOptions = initialOptions.map((option) => ({
        label: option,
        value: option,
      }));

      return convertedOptions;
    }

    return [];
  }

  const loadOptions = useCallback(
    (searchTermValue, offsetValue = 0) => {
      setIsLoading(true);

      if (searchTermValue === '') {
        setIsLoading(false);
        setOptions([]);

        return;
      }

      getTcodesApi(endpointDetails?.endpointKey, searchTermValue, offsetValue)
        .then((response) => {
          const newOptions = convertOptionsToJson(response.result.tcodes);

          setOptions((prevOptions) =>
            offsetValue === 0 ? newOptions : [...prevOptions, ...newOptions]
          );
          setHasMoreOptions(response.result.tcodes.length > 0);
        })
        .catch((err) => {
          Logger.error('Error fetching Tcodes', err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [endpointDetails]
  );

  // Wrap loadOptions with debounce
  const debouncedLoadOptions = useCallback(
    debounce(loadOptions, DEFAULT_INPUT_DEBOUNCE_DELAY),
    [loadOptions]
  );

  const handleScroll = (e) => {
    /* typing a new char in search after infinite scrolling at least once causes
    scroll state to change and this function is called 'unexpectedly'.
    added the isScrollingDown check to avoid calling the api when typing a new char only.
    isScrollingDown will be false in the scenario above.
    */

    const { scrollTop, scrollHeight, clientHeight } = e.target;

    const isBottom = scrollHeight - scrollTop <= clientHeight;
    const isScrollingDown = scrollTop > lastScrollTopRef.current;

    if (isBottom && isScrollingDown && hasMoreOptions && !isLoading) {
      setIsLoading(true);
      offsetApiRef.current += LOAD_INCREMENT;
      debouncedLoadOptions(searchTerm, offsetApiRef.current);
    }

    lastScrollTopRef.current = scrollTop;
  };

  const handleSelectionChange = (selectedOptions) => {
    if (selectedOptions?.length > MAX_TCODES_SELECTABLE) {
      setShowMaxSelectionMessage(true);
    } else {
      setShowMaxSelectionMessage(false);
      setTCodesSelection(selectedOptions);
    }
  };

  return (
    <Box>
      <MenuInput
        options={options}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        selectedOptions={tCodesSelection}
        setSelectedOptions={handleSelectionChange}
        onChange={() => {}}
        placeholder={TCODES_PLACEHOLDER_TEXT}
        isLoading={isLoading}
        prefixIcon={
          <Icon kind='search' color='neutral-600' size='smallMedium' />
        }
        backgroundColor='secondary'
        noDataText={TCODES_NOT_FOUND}
        suffixIcon={<Icon kind='Check' color='navy-700' size='medium' />}
        isMulti
        onScroll={handleScroll}
        hasMoreOptions={hasMoreOptions}
        className='MpaTcodeSelection'
        dropdownClassName='MpaTcodeSelection-dropdown'
        loadingText={LOADING_RESULTS}
        onSearchTermChange={(value) => {
          offsetApiRef.current = 0;
          setSearchTerm(value);
          debouncedLoadOptions(value.trim());
        }}
        onInputReset={() => {
          setOptions([]);
        }}
        heightConfigs={{ maxHeight: '12.5rem' }}
        menuItemHeightConfigs={{ height: '2rem' }}
      />
      <div className='MpaTcodeSelection-Chips'>
        {tCodesSelection &&
          tCodesSelection?.map((option) => (
            <Chip
              key={option.label}
              size='medium'
              label={option.label}
              kind='outlined'
              rightIcon={
                <ButtonIcon
                  size='small'
                  kind='ghost'
                  icon={
                    <Icon kind='close' color='neutral-1000' size='xSmall' />
                  }
                  onClick={() =>
                    setTCodesSelection(
                      tCodesSelection?.filter((item) => item !== option)
                    )
                  }
                />
              }
            />
          ))}
        {showMaxSelectionMessage && (
          <div>
            <InlineMessage
              leftIcon={<Icon kind='AlertCritical' color='critical-700' />}
              text={MAX_ALLOWED_TCODES}
              size='small'
              className='InlineMessage--critical'
            />
          </div>
        )}
      </div>
    </Box>
  );
}

MpaTcodeSelection.propTypes = {
  tCodesSelection: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  setTCodesSelection: PropTypes.func.isRequired,
  endpointDetails: PropTypes.shape({ endpointKey: PropTypes.string.isRequired })
    .isRequired,
};

export default MpaTcodeSelection;
