import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Box } from '@saviynt/design-system';
import './Overlay.css';

const Overlay = ({ isOpen, onClose }) => {
  if (!isOpen) {
    return null;
  }

  const handleOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return ReactDOM.createPortal(
    <Box
      tag="div"
      className={`Overlay ${isOpen ? 'Overlay--visible' : ''}`}
      onClick={handleOverlayClick}
      data-testid="overlay-background"
    />,
    document.body
  );
};

Overlay.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Overlay;
