import React from 'react';
import PropTypes from 'prop-types';

export const NewAccount = () => {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.4195 12.2744C12.4195 10.0512 14.2217 8.24891 16.4449 8.24891C18.6681 8.24891 20.4704 10.0512 20.4704 12.2744C20.4704 14.4976 18.6681 16.2998 16.4449 16.2998C14.2217 16.2998 12.4195 14.4976 12.4195 12.2744ZM16.4449 9.58225C14.9581 9.58225 13.7528 10.7876 13.7528 12.2744C13.7528 13.7612 14.9581 14.9665 16.4449 14.9665C17.9318 14.9665 19.1371 13.7612 19.1371 12.2744C19.1371 10.7876 17.9318 9.58225 16.4449 9.58225Z"
        fill="#212328"
      />
      <path
        d="M16.483 17.1378C14.3459 17.1378 12.4914 17.7416 11.0344 18.7503L11.0312 18.7525L11.0128 18.7653C10.998 18.7758 10.9779 18.7902 10.9535 18.8084C10.9046 18.8448 10.8377 18.8966 10.7587 18.9628C10.6016 19.0944 10.3922 19.287 10.1813 19.533C9.76967 20.0132 9.29449 20.7661 9.29449 21.7093V23.5845C9.29449 23.9527 9.59297 24.2511 9.96115 24.2511C10.3293 24.2511 10.6278 23.9527 10.6278 23.5845V21.7093C10.6278 21.2204 10.879 20.7678 11.1937 20.4007C11.346 20.2229 11.4998 20.0813 11.6151 19.9847C11.6723 19.9367 11.7189 19.9008 11.7495 19.8781C11.7592 19.8709 11.7672 19.865 11.7735 19.8605C11.777 19.858 11.78 19.8559 11.7823 19.8542L11.7864 19.8513L11.7934 19.8465C13.0151 19.0007 14.6018 18.4711 16.483 18.4711C18.3466 18.4711 20.0021 19.0732 21.2956 19.9355C21.7703 20.252 22.0113 20.6896 22.1355 21.0691C22.1975 21.2587 22.2279 21.4273 22.2425 21.5461C22.2498 21.6051 22.2531 21.6505 22.2546 21.6785C22.2553 21.6925 22.2556 21.702 22.2557 21.7065L22.2557 21.7087L22.2556 21.717L22.256 21.7298L22.2563 21.7399L22.2572 21.7728L22.2602 21.8982C22.2626 22.0059 22.2654 22.1586 22.2673 22.3372C22.2711 22.6961 22.271 23.153 22.2561 23.5601C22.2427 23.928 22.53 24.2372 22.898 24.2507C23.2659 24.2642 23.5751 23.9768 23.5886 23.6088C23.6046 23.1711 23.6045 22.6904 23.6006 22.323C23.5986 22.1384 23.5957 21.9807 23.5932 21.8688L23.5901 21.7377L23.5891 21.7023L23.589 21.6972L23.5887 21.6784C23.5883 21.6608 23.5876 21.6376 23.5861 21.6093C23.5832 21.5529 23.5773 21.4758 23.5658 21.3825C23.5428 21.1967 23.4966 20.9416 23.4028 20.6547C23.2152 20.0812 22.8277 19.3545 22.0352 18.8261C20.5469 17.8339 18.6375 17.1378 16.483 17.1378Z"
        fill="#212328"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.44727 5.80556C4.44727 4.94645 5.14371 4.25 6.00282 4.25H24.225C26.5569 4.25 28.4473 6.14035 28.4473 8.47222V26.6944C28.4473 27.5536 27.7508 28.25 26.8917 28.25H8.66949C6.33762 28.25 4.44727 26.3596 4.44727 24.0278V5.80556ZM6.00282 5.58333C5.88009 5.58333 5.7806 5.68283 5.7806 5.80556V24.0278C5.7806 25.6233 7.074 26.9167 8.66949 26.9167H26.8917C27.0144 26.9167 27.1139 26.8172 27.1139 26.6944V8.47222C27.1139 6.87673 25.8205 5.58333 24.225 5.58333H6.00282Z"
        fill="#212328"
      />
    </svg>
  );
};

export const EnterpriseRole = () => {
  return (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.17383 6.44444C5.17383 5.09441 6.26824 4 7.61827 4H20.0627C21.4127 4 22.5072 5.09442 22.5072 6.44444V10.9709C22.5072 11.3391 22.2087 11.6376 21.8405 11.6376C21.4723 11.6376 21.1738 11.3391 21.1738 10.9709V6.44444C21.1738 5.83079 20.6764 5.33333 20.0627 5.33333H7.61827C7.00462 5.33333 6.50716 5.83079 6.50716 6.44444V26.6667H13.3961C13.7642 26.6667 14.0627 26.9651 14.0627 27.3333C14.0627 27.7015 13.7642 28 13.3961 28H5.84049C5.4723 28 5.17383 27.7015 5.17383 27.3333V6.44444Z"
        fill="#212328"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.9025 13.3333C20.0325 13.3333 18.5166 14.8493 18.5166 16.7193C18.5166 18.5892 20.0325 20.1052 21.9025 20.1052C23.7725 20.1052 25.2885 18.5892 25.2885 16.7193C25.2885 14.8493 23.7725 13.3333 21.9025 13.3333ZM19.85 16.7193C19.85 15.5856 20.7689 14.6667 21.9025 14.6667C23.0361 14.6667 23.9551 15.5856 23.9551 16.7193C23.9551 17.8529 23.0361 18.7718 21.9025 18.7718C20.7689 18.7718 19.85 17.8529 19.85 16.7193Z"
        fill="#212328"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M26.4734 21.604C23.6139 19.9004 20.0509 19.9004 17.1913 21.604C16.3537 22.103 15.8405 23.0061 15.8405 23.9811V27.3333C15.8405 27.7015 16.139 27.9999 16.5072 27.9999H27.1576C27.5257 27.9999 27.8242 27.7015 27.8242 27.3333V23.9811C27.8242 23.0061 27.311 22.103 26.4734 21.604ZM17.8737 22.7494C20.3128 21.2964 23.3519 21.2964 25.791 22.7494C26.225 23.008 26.4909 23.4759 26.4909 23.9811V26.6666H17.1738V23.9811C17.1738 23.4759 17.4397 23.008 17.8737 22.7494Z"
        fill="#212328"
      />
      <path
        d="M11.8405 9.5395C11.8405 9.17131 11.542 8.87283 11.1738 8.87283C10.8056 8.87283 10.5072 9.17131 10.5072 9.5395V11.3173C10.5072 11.6855 10.8056 11.9839 11.1738 11.9839C11.542 11.9839 11.8405 11.6855 11.8405 11.3173V9.5395Z"
        fill="#212328"
      />
      <path
        d="M11.1738 14.2062C11.542 14.2062 11.8405 14.5046 11.8405 14.8728V16.6506C11.8405 17.0188 11.542 17.3173 11.1738 17.3173C10.8056 17.3173 10.5072 17.0188 10.5072 16.6506V14.8728C10.5072 14.5046 10.8056 14.2062 11.1738 14.2062Z"
        fill="#212328"
      />
      <path
        d="M11.8405 20.2062C11.8405 19.838 11.542 19.5395 11.1738 19.5395C10.8056 19.5395 10.5072 19.838 10.5072 20.2062V21.9839C10.5072 22.3521 10.8056 22.6506 11.1738 22.6506C11.542 22.6506 11.8405 22.3521 11.8405 21.9839V20.2062Z"
        fill="#212328"
      />
      <path
        d="M16.5072 8.87283C16.8753 8.87283 17.1738 9.17131 17.1738 9.5395V11.3173C17.1738 11.6855 16.8753 11.9839 16.5072 11.9839C16.139 11.9839 15.8405 11.6855 15.8405 11.3173V9.5395C15.8405 9.17131 16.139 8.87283 16.5072 8.87283Z"
        fill="#212328"
      />
      <path
        d="M17.1738 14.8728C17.1738 14.5046 16.8753 14.2062 16.5072 14.2062C16.139 14.2062 15.8405 14.5046 15.8405 14.8728V16.6506C15.8405 17.0188 16.139 17.3173 16.5072 17.3173C16.8753 17.3173 17.1738 17.0188 17.1738 16.6506V14.8728Z"
        fill="#212328"
      />
    </svg>
  );
};
export const AddAccess = () => {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.133 5.30141C12.3062 4.2467 9.97025 4.87261 8.91554 6.69942C7.86083 8.52622 8.48674 10.8622 10.3136 11.9169C11.9433 12.8578 13.9782 12.4611 15.1472 11.0675L17.9434 12.6819L17.3761 13.6645C17.192 13.9833 17.3013 14.3911 17.6201 14.5752C17.939 14.7592 18.3467 14.65 18.5308 14.3311L19.0981 13.3486L20.2837 14.033L19.2658 15.796C19.0817 16.1149 19.191 16.5226 19.5098 16.7067C19.8287 16.8908 20.2364 16.7815 20.4205 16.4627L21.7717 14.1224C21.9558 13.8035 21.8465 13.3958 21.5277 13.2117L19.2057 11.8711C19.1997 11.8674 19.1937 11.8638 19.1876 11.8602C19.1814 11.8567 19.1753 11.8533 19.1691 11.8499L15.814 9.91287C16.4369 8.20355 15.763 6.24248 14.133 5.30141ZM10.0702 7.36608C10.7568 6.177 12.2772 5.76959 13.4663 6.45611C14.6554 7.14262 15.0628 8.6631 14.3763 9.85218C13.6898 11.0413 12.1693 11.4487 10.9802 10.7622C9.79113 10.0756 9.38372 8.55517 10.0702 7.36608Z"
        fill="#212328"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.3118 19.246C17.9144 19.5925 18.2737 20.2109 18.3111 20.8659L24.8781 18.3514C25.9663 17.9347 27.1942 18.3978 27.7362 19.4293C28.3141 20.5292 27.9139 21.8893 26.8323 22.5008L18.3811 27.2791C17.6127 27.7136 16.7035 27.8255 15.8527 27.5904L11.4169 26.3648C10.6032 26.1399 9.74361 26.14 8.92989 26.3651L5.74329 27.2466C5.55887 27.2976 5.36143 27.2669 5.20119 27.1623C5.04096 27.0578 4.93338 26.8894 4.90583 26.7L4.00504 20.5089C3.9594 20.1952 4.14096 19.8927 4.43928 19.7855L6.9784 18.8729C7.13252 18.8175 7.27249 18.7288 7.38829 18.613L8.80389 17.1973C9.86916 16.132 11.5165 15.9137 12.8226 16.6646L17.3118 19.246ZM9.74671 18.1401C10.3859 17.5009 11.3743 17.3699 12.1579 17.8205L16.6471 20.4019C16.9389 20.5697 17.0594 20.9291 16.9275 21.2389C16.7989 21.5412 16.4688 21.7043 16.1505 21.6227L11.75 20.4953C11.3933 20.404 11.0301 20.619 10.9387 20.9757C10.8474 21.3324 11.0624 21.6956 11.4191 21.787L15.8196 22.9143C16.4202 23.0682 17.0348 22.9322 17.5048 22.5847C17.5532 22.5777 17.6016 22.5653 17.6492 22.5471L25.3549 19.5965C25.8121 19.4214 26.3281 19.616 26.5559 20.0495C26.7987 20.5117 26.6305 21.0832 26.176 21.3402L17.7248 26.1185C17.2638 26.3791 16.7183 26.4463 16.2078 26.3053L11.7721 25.0796C10.7258 24.7905 9.62063 24.7906 8.57442 25.08L6.11643 25.76L5.40292 20.856L7.42937 20.1277C7.76842 20.0058 8.07635 19.8105 8.33111 19.5558L9.74671 18.1401Z"
        fill="#212328"
      />
    </svg>
  );
};
export const UpdateUser = () => {
  return (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.74291 8.75226C8.74291 6.12793 10.8704 4.00049 13.4947 4.00049C16.119 4.00049 18.2465 6.12793 18.2465 8.75226C18.2465 11.3766 16.119 13.504 13.4947 13.504C10.8704 13.504 8.74291 11.3766 8.74291 8.75226ZM13.4947 5.33382C11.6067 5.33382 10.0762 6.86431 10.0762 8.75226C10.0762 10.6402 11.6067 12.1707 13.4947 12.1707C15.3826 12.1707 16.9131 10.6402 16.9131 8.75226C16.9131 6.86431 15.3826 5.33382 13.4947 5.33382Z"
        fill="#212328"
      />
      <path
        d="M24.7496 16C24.4893 15.7397 24.0672 15.7397 23.8068 16L20.4735 19.3333C20.2131 19.5937 20.2131 20.0158 20.4735 20.2761C20.7338 20.5365 21.1559 20.5365 21.4163 20.2761L23.6115 18.0809V27.3325C23.6115 27.7007 23.91 27.9992 24.2782 27.9992C24.6464 27.9992 24.9449 27.7007 24.9449 27.3325V18.0809L27.1401 20.2761C27.4005 20.5365 27.8226 20.5365 28.0829 20.2761C28.3433 20.0158 28.3433 19.5937 28.0829 19.3333L24.7496 16Z"
        fill="#212328"
      />
      <path
        d="M7.90375 17.0886C11.2594 14.9994 15.5125 15.0062 18.8614 17.1062L19.8934 17.7533C20.2053 17.9489 20.6168 17.8546 20.8124 17.5427C21.008 17.2308 20.9137 16.8193 20.6017 16.6237L19.5697 15.9766C15.789 13.6058 10.9874 13.5981 7.19904 15.9567C5.65891 16.9156 4.72266 18.6014 4.72266 20.4156V27.3324C4.72266 27.7006 5.02113 27.999 5.38932 27.999C5.75751 27.999 6.05599 27.7006 6.05599 27.3324V20.4156C6.05599 19.0619 6.75458 17.804 7.90375 17.0886Z"
        fill="#212328"
      />
    </svg>
  );
};
export const RequestRole = () => {
  return (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.5917 5.11133C14.2596 5.11133 12.369 7.00188 12.369 9.33399C12.369 11.6661 14.2596 13.5566 16.5917 13.5566C18.9238 13.5566 20.8143 11.6661 20.8143 9.33399C20.8143 7.00188 18.9238 5.11133 16.5917 5.11133ZM13.7024 9.33399C13.7024 7.73826 14.9959 6.44466 16.5917 6.44466C18.1874 6.44466 19.481 7.73826 19.481 9.33399C19.481 10.9297 18.1874 12.2233 16.5917 12.2233C14.9959 12.2233 13.7024 10.9297 13.7024 9.33399Z"
        fill="#212328"
      />
      <path
        d="M11.2183 17.0434C14.4726 15.1046 18.5275 15.1046 21.7818 17.0434C22.4115 17.4185 22.7972 18.0973 22.7972 18.8303V26.2225C22.7972 26.5906 23.0957 26.8891 23.4639 26.8891C23.8321 26.8891 24.1306 26.5906 24.1306 26.2225V18.8303C24.1306 17.6275 23.4975 16.5135 22.4642 15.8979C22.0387 15.6444 21.6011 15.4203 21.1541 15.2255C22.886 14.2407 25.0169 14.2594 26.7336 15.2817C27.0021 15.4416 27.1667 15.7311 27.1667 16.0436V21.9988C27.1667 22.367 27.4651 22.6655 27.8333 22.6655C28.2015 22.6655 28.5 22.367 28.5 21.9988V16.0436C28.5 15.2611 28.0881 14.5364 27.4158 14.1361C25.2467 12.8444 22.5444 12.8444 20.3753 14.1361L19.6167 14.5878C19.5832 14.6078 19.5521 14.6302 19.5235 14.6547C17.543 14.1228 15.4543 14.1231 13.474 14.6554C13.4451 14.6307 13.4138 14.608 13.38 14.5879L12.6221 14.1364C10.4537 12.8446 7.75179 12.8446 5.58335 14.1364C4.91156 14.5366 4.5 15.2609 4.5 16.0428V21.9988C4.5 22.367 4.79848 22.6655 5.16667 22.6655C5.53486 22.6655 5.83333 22.367 5.83333 21.9988V16.0428C5.83333 15.7307 5.99761 15.4416 6.26576 15.2819C7.98205 14.2594 10.1129 14.2409 11.8442 15.2263C11.3979 15.4209 10.9609 15.6447 10.5359 15.8979C9.50262 16.5135 8.86958 17.6275 8.86958 18.8303V26.2225C8.86958 26.5906 9.16805 26.8891 9.53624 26.8891C9.90443 26.8891 10.2029 26.5906 10.2029 26.2225V18.8303C10.2029 18.0973 10.5887 17.4185 11.2183 17.0434Z"
        fill="#212328"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.47786 9.7866C6.47786 8.30837 7.67621 7.11003 9.15444 7.11003C10.6327 7.11003 11.831 8.30837 11.831 9.7866C11.831 11.2648 10.6327 12.4632 9.15444 12.4632C7.67621 12.4632 6.47786 11.2648 6.47786 9.7866ZM9.15444 8.44336C8.41259 8.44336 7.8112 9.04475 7.8112 9.7866C7.8112 10.5285 8.41259 11.1298 9.15444 11.1298C9.89629 11.1298 10.4977 10.5285 10.4977 9.7866C10.4977 9.04475 9.89629 8.44336 9.15444 8.44336Z"
        fill="#212328"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M26.5213 9.7866C26.5213 8.3081 25.3223 7.11003 23.8438 7.11003C22.3654 7.11003 21.1664 8.3081 21.1664 9.7866C21.1664 11.2651 22.3654 12.4632 23.8438 12.4632C25.3223 12.4632 26.5213 11.2651 26.5213 9.7866ZM23.8438 8.44336C24.5865 8.44336 25.188 9.04502 25.188 9.7866C25.188 10.5282 24.5865 11.1298 23.8438 11.1298C23.1012 11.1298 22.4997 10.5282 22.4997 9.7866C22.4997 9.04502 23.1012 8.44336 23.8438 8.44336Z"
        fill="#212328"
      />
    </svg>
  );
};
export const Entitlements = () => {
  return (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.83268 4C5.46449 4 5.16602 4.29848 5.16602 4.66667V22.8889C5.16602 24.7298 6.6584 26.2222 8.49935 26.2222H17.5369C17.9051 26.2222 18.2036 25.9237 18.2036 25.5556C18.2036 25.1874 17.9051 24.8889 17.5369 24.8889H8.49935C7.39478 24.8889 6.49935 23.9935 6.49935 22.8889V5.33333H20.9438C22.0484 5.33333 22.9438 6.22876 22.9438 7.33333V10.7472C22.9438 11.1154 23.2423 11.4139 23.6105 11.4139C23.9786 11.4139 24.2771 11.1154 24.2771 10.7472V7.33333C24.2771 5.49239 22.7847 4 20.9438 4H5.83268Z"
        fill="#212328"
      />
      <path
        d="M10.0421 8.66667C9.67391 8.66667 9.37543 8.96514 9.37543 9.33333C9.37543 9.70152 9.67391 10 10.0421 10H17.1532C17.5214 10 17.8199 9.70152 17.8199 9.33333C17.8199 8.96514 17.5214 8.66667 17.1532 8.66667H10.0421Z"
        fill="#212328"
      />
      <path
        d="M9.37543 12.4444C9.37543 12.0763 9.67391 11.7778 10.0421 11.7778H17.1532C17.5214 11.7778 17.8199 12.0763 17.8199 12.4444C17.8199 12.8126 17.5214 13.1111 17.1532 13.1111H10.0421C9.67391 13.1111 9.37543 12.8126 9.37543 12.4444Z"
        fill="#212328"
      />
      <path
        d="M10.0421 14.8889C9.67391 14.8889 9.37543 15.1874 9.37543 15.5556C9.37543 15.9237 9.67391 16.2222 10.0421 16.2222H13.5977C13.9658 16.2222 14.2643 15.9237 14.2643 15.5556C14.2643 15.1874 13.9658 14.8889 13.5977 14.8889H10.0421Z"
        fill="#212328"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.4993 17.1111C18.4993 14.5338 20.5887 12.4444 23.166 12.4444C25.7433 12.4444 27.8327 14.5338 27.8327 17.1111C27.8327 18.3519 27.3485 19.4795 26.5587 20.3154V27.3332C26.5587 27.6015 26.3979 27.8436 26.1505 27.9477C25.9032 28.0517 25.6176 27.9973 25.4258 27.8097L23.166 25.5991L20.9062 27.8097C20.7144 27.9973 20.4288 28.0517 20.1815 27.9477C19.9342 27.8436 19.7733 27.6015 19.7733 27.3332V20.3154C18.9836 19.4795 18.4993 18.3519 18.4993 17.1111ZM23.166 13.7778C21.3251 13.7778 19.8327 15.2702 19.8327 17.1111C19.8327 18.9521 21.3251 20.4444 23.166 20.4444C25.007 20.4444 26.4993 18.9521 26.4993 17.1111C26.4993 15.2702 25.007 13.7778 23.166 13.7778ZM21.1067 21.3V25.7484L22.6998 24.1899C22.9589 23.9365 23.3731 23.9365 23.6322 24.1899L25.2254 25.7484V21.3C24.6042 21.6059 23.9052 21.7778 23.166 21.7778C22.4268 21.7778 21.7278 21.6059 21.1067 21.3Z"
        fill="#212328"
      />
    </svg>
  );
};
const RequestTypeColumn = ({ column }) => {
  const renderRequestIcon = (type) => {
    switch (true) {
      case type === 'New Account':
        return <NewAccount />;
      case type === 'Enterprise Role Request':
        return <EnterpriseRole />;
      case type === 'Add Access':
        return <AddAccess />;
      case type === 'Update User':
        return <UpdateUser />;
      case type === 'Modify Role Request':
        return <RequestRole />;
      case type === 'Update Entitlement Request':
        return <Entitlements />;
      default:
        return <NewAccount />;
    }
  };

  return (
    <div className="type-id-column d-flex">
      <div className="request-icon">{renderRequestIcon(column.requesttype)}</div>
      <div className="request-details">
        <h5>{column.requesttype}</h5>
        <span>{column.requestid}</span>
      </div>
    </div>
  );
};

RequestTypeColumn.propTypes = {
  column: PropTypes.shape({
    requesttype: PropTypes.string.isRequired,
    requestid: PropTypes.number.isRequired,
  }).isRequired,
};

export default RequestTypeColumn;
