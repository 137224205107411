// Use CRUD naming to align with RESTful API (as opposed to GraphQL or RPC)
const baseUrl = process.env.REACT_APP_NAV_API ?? '';

// eslint-disable-next-line import/prefer-default-export -- follow nav api pattern
export async function readKpi(
  requestUrl: string,
  requestMethod: string,
  requestBody: object | undefined,
  augmentHeaders: ((headers: HeadersInit) => HeadersInit) | undefined = (h) => h
): Promise<Record<string, unknown>> {
  const headers: HeadersInit = requestBody
    ? { 'Content-Type': 'application/json' }
    : {};

  const response = await fetch(baseUrl + requestUrl, {
    method: requestMethod,
    body: JSON.stringify(requestBody),
    headers: augmentHeaders(headers),
  });

  return response.json() as Promise<Record<string, unknown>>;
}
