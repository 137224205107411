import {
  loginInfoSuccessActionType,
} from 'ui-common/src/utils/LoginActions';
import moment from 'moment';
import {
  SAVE_PROFILE_IMAGE,
  CLEAR_PROFILE_IMAGE,
  FETCH_PROFILE_MENU,
  FETCH_LANGUAGE_LIST,
  CLOSE_LANGUAGE_MODAL,
  FETCH_GET_HELP,
} from '../actions/profileMenuAction';

import { FETCH_ECM_VERSION_SUCCESS } from '../features/MainLayout/Version/redux/constants';

export const initState = () => ({
  email: null,
  firstName: null,
  lastName: null,
  username: null,
  version: null,
  lastLoginIP: null,
  lastLoginTime: null,
  menuList: [],
  profileImage: null,
  isLanguageModal: false,
  languagesSupported: {},
  helpDetails: {
    isHelpEnabled: false,
    isDocEnabled: false,
  },
});

export const profileReducer = () => (state = initState(), action) => {
  switch (action.type) {
    case FETCH_ECM_VERSION_SUCCESS:
      return {
        ...state,
        ecmVersion: action.version,
      };

    case loginInfoSuccessActionType: {
      const {
        email, firstName, lastName, username, lastLoginIP, lastLoginTime,
      } = action.payload;

      return {
        ...state,
        email,
        firstName,
        lastName,
        username,
        lastLoginIP,
        lastLoginTime: moment
          .utc(lastLoginTime, 'YYYY-MM-DD HH:mm:ss')
          .local()
          .format('MMM DD YYYY hh:mm A'),
      };
    }

    case FETCH_PROFILE_MENU: {
      return {
        ...state,
        menuList: action.data,
      };
    }

    case FETCH_GET_HELP: {
      return {
        ...state,
        helpDetails: action.data,
      };
    }

    case FETCH_LANGUAGE_LIST: {
      return {
        ...state,
        isLanguageModal: true,
        languagesSupported: action.data,
      };
    }

    case CLOSE_LANGUAGE_MODAL: {
      return {
        ...state,
        isLanguageModal: false,
      };
    }

    case SAVE_PROFILE_IMAGE: {
      return {
        ...state,
        profileImage: action.url,
      };
    }

    case CLEAR_PROFILE_IMAGE: {
      return {
        ...state,
        profileImage: null,
      };
    }

    default:
      return state;
  }
};

export default profileReducer();
