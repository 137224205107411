/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { localize } from '@saviynt/common';
import {
  Box,
  Button,
  FooterActionBar,
  Icon,
  InlineMessage,
  InputField,
  Loader,
  ModalIsland,
  RadioButton,
  Tooltip,
  Typography,
} from '@saviynt/design-system';
import PropTypes from 'prop-types';

import { CredentialRequestAccountSelection } from '../../../../models/PamModels';
import { msgs } from '../../constants';
import {
  isAcctDescToolTipDisabled,
  isAcctDescWithinCharLimit,
} from '../../helpers';

import '../ModalPageListIsland.css';

function AccountListModalIsland({
  accountListArray,
  accountListFilteredArray,
  accountListModalContentRef,
  sectionRefIsScrolled,
  accountSelection,
  setAccountSelection,
  isAccountListModal,
  setIsAccountListModal,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  isFetching,
  setAccountListArray,
  debouncedSearch,
}) {
  const [searchValue, setSearchValue] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [modalAccountSelection, setModalAccountSelection] = useState(null);
  const intl = useIntl();

  const lastScrollTopRef = useRef(0);
  const hasReachedBottomRef = useRef(false);

  const BTN_SAVE_TEXT = localize(intl, msgs.pam.common.save);
  const BTN_CANCEL_TEXT = localize(intl, msgs.pam.common.cancel);
  const ISLAND_HEADING_TEXT = localize(
    intl,
    msgs.pam.modalPage.accordionHeading.accountHeader
  );
  const NO_MATCHING_RECORDS_TEXT = localize(
    intl,
    msgs.pam.modalPage.accordionHeading.noAccountMatchingRecords
  );
  const ACCT_DESC_TRUNCATE_TEXT = localize(
    intl,
    msgs.pam.modalPage.accountSelection.accountDescriptionTruncateLabel
  );

  useEffect(() => {
    setModalAccountSelection(accountSelection);
  }, [accountSelection]);

  const handleScroll = () => {
    if (!isAccountListModal) return;

    const modalContent = accountListModalContentRef.current;
    const isBottom =
      modalContent.scrollHeight - modalContent.scrollTop <=
      modalContent.clientHeight;
    const isScrollingDown = modalContent.scrollTop > lastScrollTopRef.current;

    if (
      isBottom &&
      isScrollingDown &&
      !hasReachedBottomRef.current &&
      hasNextPage &&
      !isFetchingNextPage
    ) {
      hasReachedBottomRef.current = true;
      fetchNextPage().then(() => {
        // after the API call and new items are added
        hasReachedBottomRef.current = false;
      });
    }

    lastScrollTopRef.current = modalContent.scrollTop;
  };

  useEffect(() => {
    if (!isAccountListModal) return;

    const modalContent = accountListModalContentRef.current;

    modalContent.addEventListener('scroll', handleScroll);

    // eslint-disable-next-line consistent-return
    return () => modalContent.removeEventListener('scroll', handleScroll);
  }, [isAccountListModal]);

  const handleClick = (e, value) => {
    e.stopPropagation();
    setModalAccountSelection(value);
  };

  const handleCancel = () => {
    setIsAccountListModal(false);
    setModalAccountSelection(accountSelection);
  };

  const handleSave = () => {
    setIsAccountListModal(false);

    // below code replaces the first element (radio) with the element selected
    // only if the element selected was not in the original list shown.
    // ie., if selected account is from searching, infinite scroll, etc.
    if (modalAccountSelection) {
      const existsInList = accountListArray?.find(
        (account) => account.value === modalAccountSelection.value
      );

      if (!existsInList) {
        setAccountListArray([
          modalAccountSelection,
          ...accountListArray.slice(1),
        ]);
      }
    }

    setAccountSelection(modalAccountSelection);
  };

  const handleSearchChange = (value) => {
    setSearchValue(value);
    setIsSearching(true);
    debouncedSearch(value).finally(() => setIsSearching(false));
  };

  const renderSpinner = () => (
    <Box className='ModalPageListIsland--isLoading'>
      <Loader kind='dots' format='inline' color='brand' size='medium' />
    </Box>
  );

  const renderModalContent = () => {
    if ((isSearching || isFetching) && !isFetchingNextPage) {
      return renderSpinner();
    }

    if (!isFetching && accountListFilteredArray?.length === 0) {
      return (
        <InlineMessage>
          <Box className='ModalPageListIsland-noMatchingSearchRecords'>
            <Icon kind='alertCriticalOutline' />
            <Typography
              kind='h3'
              className='ModalPageListIsland-noMatchingSearchRecords-title'>
              {NO_MATCHING_RECORDS_TEXT}
            </Typography>
          </Box>
        </InlineMessage>
      );
    }

    return (
      <Box className='ModalPageListIsland-radioButtons'>
        {accountListFilteredArray?.map((account) => (
          <Tooltip
            title={ACCT_DESC_TRUNCATE_TEXT}
            aria-label={ACCT_DESC_TRUNCATE_TEXT}
            text={account.description}
            isDisabled={isAcctDescToolTipDisabled(account.description)}
            trigger={
              <RadioButton
                key={`accountModalRadio-${account.value}`}
                id={`accountModalRadio-${account.value}`}
                name='accountListSelectionRadioGroup'
                title={account.title}
                description={
                  isAcctDescWithinCharLimit(account.description)
                    ? account.description
                    : null
                }
                value={account}
                radioSelection={modalAccountSelection}
                onClick={handleClick}
                className='ModalPageListIsland-radioButton'
                isTruncated
              />
            }
          />
        ))}
        {isFetchingNextPage && renderSpinner()}
      </Box>
    );
  };

  const getModalIslandContent = () => (
    <Box className='ModalPageListIsland-modalIslandContent'>
      <Box className='ModalPageListIsland-modalIslandContent-search'>
        <InputField
          name='modalIsland-search'
          kind='search'
          placeholder='Search by account name or description'
          prefixIcon={<Icon kind='search' size='smallMedium' />}
          value={searchValue}
          setValue={handleSearchChange}
        />
      </Box>
      {renderModalContent()}
    </Box>
  );

  const modalIslandFooterActionBar = () => (
    <Box className='ModalPageListIsland-footerActionBar'>
      <Button kind='outlined' size='medium' onClick={() => handleCancel()}>
        {BTN_CANCEL_TEXT}
      </Button>
      <Button
        isDisabled={!modalAccountSelection}
        kind='filled'
        size='medium'
        onClick={() => handleSave()}>
        {BTN_SAVE_TEXT}
      </Button>
    </Box>
  );

  return (
    <ModalIsland
      className='ModalPageListIsland'
      onClose={() => setIsAccountListModal(false)}
      isOpen={isAccountListModal}
      headerIcon={<Icon kind='account' size='large' />}
      title={ISLAND_HEADING_TEXT}
      contentBody={getModalIslandContent()}
      sectionRef={accountListModalContentRef}
      FooterActionBarComp={
        <FooterActionBar size='large' isScrolled={sectionRefIsScrolled}>
          {modalIslandFooterActionBar()}
        </FooterActionBar>
      }
    />
  );
}

AccountListModalIsland.propTypes = {
  accountListArray: PropTypes.arrayOf(CredentialRequestAccountSelection),
  accountListFilteredArray: PropTypes.arrayOf(
    CredentialRequestAccountSelection
  ),
  accountListModalContentRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  sectionRefIsScrolled: PropTypes.bool,
  accountSelection: CredentialRequestAccountSelection,
  setAccountSelection: PropTypes.func.isRequired,
  isAccountListModal: PropTypes.bool.isRequired,
  setIsAccountListModal: PropTypes.func.isRequired,
  fetchNextPage: PropTypes.func.isRequired,
  hasNextPage: PropTypes.bool.isRequired,
  isFetchingNextPage: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  setAccountListArray: PropTypes.func.isRequired,
  debouncedSearch: PropTypes.func.isRequired,
};

AccountListModalIsland.defaultProps = {
  accountListArray: null,
  accountListFilteredArray: null,
  accountListModalContentRef: null,
  sectionRefIsScrolled: null,
  accountSelection: null,
};

export default AccountListModalIsland;
