import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import './SortBy.scss';

const SortBy = ({ options, setGlobalSortByColumn }) => {
  const [dropDownSelectedvalue, setDropDownSelectedvalue] = useState(options[0]);
  const searchRef = useRef(null);

  const sortByColumnChange = option => {
    setGlobalSortByColumn(option.value);
    setDropDownSelectedvalue(option);
  };

  return (
    <div ref={searchRef} className="d-flex">
      <div className="list-dropdown-sortby">
        <Dropdown
          id="search_categories"
          options={options}
          onChange={sortByColumnChange}
          value={`Sort By ${dropDownSelectedvalue.label}`}
          aria-label="dropdown"
        />
      </div>
    </div>
  );
};

SortBy.propTypes = {
  GlobalSortByColumn: PropTypes.string.isRequired,
  setGlobalSortByColumn: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default SortBy;
