import { type ThunkAction } from 'redux-thunk';
import { type ActionType } from 'typesafe-actions';

import { type MenuId, RequestStatus } from '../../../../models';
import * as api from '../api';
import type RootState from '../RootState';

import * as actions from './actions';
import kpiConfigs from './kpiConfigs';

type Actions = ActionType<typeof actions>;
type KpisThunkAction = ThunkAction<void, RootState, unknown, Actions>;

export const requestKpis =
  (kpis: 'unauthorized' | string[] | undefined): KpisThunkAction =>
  (dispatch, getState) => {
    if (getState().kpis.defaultKpisStatus === RequestStatus.Pending) {
      return;
    }

    dispatch(actions.kpisRequested());

    if (!kpis) {
      dispatch(actions.kpisNotReceived());

      return;
    }

    if (kpis === 'unauthorized') {
      dispatch(actions.kpisUnauthorized());

      return;
    }

    dispatch(actions.kpisReceived(kpis));
  };

export const requestKpi =
  (id: MenuId, username: string): KpisThunkAction =>
  async (dispatch, getState) => {
    const kpiConfig = kpiConfigs[id];

    if (kpiConfig === undefined) {
      dispatch(actions.kpiNotReceived(id));

      return;
    }

    if (getState().kpis.kpis[id]?.status === RequestStatus.Pending) {
      return;
    }

    dispatch(actions.kpiRequested(id));

    try {
      const {
        requestUrl,
        requestMethod,
        requestBody,
        requestHeaders,
        processResponse,
      } = kpiConfig;
      const kpi = await api.readKpi(
        requestUrl(username),
        requestMethod,
        requestBody?.(username),
        requestHeaders?.bind(null, username)
      );
      const metric = processResponse(kpi);

      if (typeof metric === 'number') {
        dispatch(actions.kpiReceived({ id, metric }));
      } else {
        dispatch(actions.kpiNotReceived(id));
      }
    } catch (e) {
      dispatch(actions.kpiNotReceived(id));
    }
  };
