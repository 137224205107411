import { type ActionType, getType } from 'typesafe-actions';

import { RequestStatus } from '../../../../models';
import { actions } from '../actions';
import { type KpisState } from '../KpisState';

import INITIAL_STATE from './initialState';

export default (
  // eslint-disable-next-line @typescript-eslint/default-param-last -- conform to reducer signature
  state = INITIAL_STATE,
  action: ActionType<typeof actions>
): KpisState => {
  switch (action.type) {
    case getType(actions.kpisRequested):
      return {
        ...state,
        defaultKpisStatus: RequestStatus.Pending,
      };
    case getType(actions.kpisReceived):
      return {
        ...state,
        defaultKpisStatus: RequestStatus.Idle,
        defaultKpiIds: action.payload,
      };
    case getType(actions.kpisNotReceived):
      return {
        ...state,
        defaultKpisStatus: RequestStatus.Idle,
        defaultKpiIds: 'error',
      };
    case getType(actions.kpisUnauthorized):
      return {
        ...state,
        defaultKpisStatus: RequestStatus.Idle,
        defaultKpiIds: 'unauthorized',
      };
    case getType(actions.kpiRequested):
      return {
        ...state,
        kpis: {
          ...state.kpis,
          [action.payload]: {
            status: RequestStatus.Pending,
            metric: 0,
          },
        },
      };
    case getType(actions.kpiReceived):
      return {
        ...state,
        kpis: {
          ...state.kpis,
          [action.payload.id]: {
            status: RequestStatus.Idle,
            metric: action.payload.metric,
          },
        },
      };
    case getType(actions.kpiNotReceived):
      return {
        ...state,
        kpis: {
          ...state.kpis,
          [action.payload]: {
            status: RequestStatus.Idle,
            metric: 'error',
          },
        },
      };
    default:
      return state;
  }
};
