import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

const useStyles = makeStyles(() => ({
  root: {
    background: 'transparent',
  },
  toggleButton: {
    color: 'var(--navbar-title)',
    borderRadius: '3px !important',
    border: '1px solid #dfe0eb !important',
    margin: '0 6px 0 0 !important',
    textTransform: 'capitalize',
    height: '30px',
    fontSize: '12px',
    padding: '0px 9px !important',
  },
  toggleButtonColor: {
    borderRadius: '3px !important',
    border: '1px solid #dfe0eb !important',
    margin: '0 6px 0 0 !important',
    textTransform: 'capitalize',
    height: '30px',
    fontSize: '12px',
    padding: '0px 9px !important',
  },
  selctedToggleButton: {
    backgroundColor: 'var(--activetab-bg) !important',
  },
}));

const StyledToggleButtonGroup = withStyles(theme => ({
  grouped: {
    margin: theme.spacing(0.5),
    border: 'none',
    padding: theme.spacing(0, 1),
    '&:not(:first-child)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-child': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))(ToggleButtonGroup);

const ToggleButtonComponent = ({
  options,
  defaultValue,
  onToggleChange,
  colorOverride,
  tabIndex,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(defaultValue);

  const handleChange = (event, newValue) => {
    if (newValue !== null) {
      setValue(newValue);
      onToggleChange(newValue);
    }
  };

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <Grid container spacing={2} direction="column" alignItems="left">
      <Grid item>
        <StyledToggleButtonGroup
          size="small"
          value={value}
          exclusive
          onChange={handleChange}
          className={classes.root}
        >
          {options.map(option => (
            <ToggleButton
              key={option.value}
              value={option.value}
              className={`${colorOverride ? classes.toggleButtonColor : classes.toggleButton}`}
              classes={{ selected: classes.selctedToggleButton }}
              tabIndex={tabIndex || 0}
            >
              {option.label}
            </ToggleButton>
          ))}
        </StyledToggleButtonGroup>
      </Grid>
    </Grid>
  );
};

ToggleButtonComponent.propTypes = {
  defaultValue: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  onToggleChange: PropTypes.func.isRequired,
};

export default ToggleButtonComponent;
