import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { withStyles } from '@material-ui/core/styles';

const TicketButton = withStyles({
  root: {
    backgroundColor: '#e0e0e0',
    color: '#747474',
    padding: '5px 8px',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  label: {
    fontSize: 11,
    alignItems: 'center',
  },
  icon: {
    width: 16,
    height: 16,
  },
})(props => {
  const { classes, handleClick, intl } = props;
  return (
    <Button
      classes={{ root: classes.root, label: classes.label }}
      variant="contained"
      color="default"
      onClick={handleClick}
    >
      <AddIcon className={classes.icon} />
      <span style={{ color: 'var(--dark-grey)' }}>
        {intl.formatMessage({ id: 'SAPAddTicket' })}
      </span>
    </Button>
  );
});

TicketButton.propTypes = {
  intl: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string.isRequired,
      formatMessage: PropTypes.func.isRequired,
    })
  ).isRequired,
};

export default injectIntl(TicketButton);
