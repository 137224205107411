import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import { Icon } from 'ui-components/src';
import { Logger } from 'ui-common/src';

const useStyles = makeStyles(theme => ({
  colorPrimary: {
    color: '#b4b4b4',
  },
  fontSizeSmall: {
    fontSize: 18,
  },
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
  search: {
    borderRadius: 3,
    backgroundColor: '#ffffff',
    border: '1px solid transparent',
    margin: '0 !important',
    '&:hover': {
      // border: '1px solid  #ececec',
      backgroundColor: '#ffffff',
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
    },
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-start',
    alignItmes: 'center',
  },
  searchIcon: {
    width: theme.spacing(4),
    height: '100%',
    pointerEvents: 'none',
    alignSelf: 'center',
    textAlign: 'center',
    paddingLeft: theme.spacing(1),
    paddingTop: "5px",
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 1),
    transition: theme.transitions.create('width'),
    width: '100%',
  },
}));

const SearchComponent = ({
  onTextChange,
  hideIcon,
  placeholder,
  smallFont,
  fixedWidth,
  defaultValue,
}) => {
  const classes = useStyles();
  const textInput = useRef(null);

  const handleChange = event => {
    const searchVal = event.target.value;
    onTextChange(searchVal);
  };

  const onKeyDown = event => {
    if (event.keyCode === 8) {
      Logger.log('BACKSPACE was pressed ');
      Logger.log(event.target.value);
    }
    if (event.keyCode === 46) {
      Logger.log('Delete was pressed ');
    }
  };

  return (
    <div className={classes.search} style={fixedWidth ? { width: fixedWidth } : {}}>
      {!hideIcon && (
        <div className={classes.searchIcon}>
          {/* <SearchIcon className={clsx(classes.colorPrimary, classes.fontSizeSmall)} /> */}
       <Icon name="searchIcon"/>
        </div>
      )}
      <InputBase
        placeholder={placeholder}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        defaultValue={defaultValue}
        inputProps={{
          'aria-label': 'Search',
          style: smallFont ? { fontSize: '12px' } : {},
        }}
        inputRef={textInput}
        onChange={handleChange}
        onKeyDown={onKeyDown}
        fontSize={smallFont ? '12px' : 'inherit'}
      />
    </div>
  );
};

SearchComponent.propTypes = {
  onTextChange: PropTypes.func,
  hideIcon: PropTypes.bool,
  smallFont: PropTypes.bool,
  fixedWidth: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  defaultValue: PropTypes.string.isRequired,
};

SearchComponent.defaultProps = {
  onTextChange: () => {},
  hideIcon: false,
  smallFont: false,
  fixedWidth: '',
};

export default SearchComponent;
