import { type UnknownAction } from 'redux';
import { createAction } from 'typesafe-actions';

// Public actions
export default {
  /** Triggers the verification modal if MFA is enabled.
   * @param {UnknownAction} onSuccessMessage redux message dispatched on successful verification
   */
  verificationRequired: createAction(
    'verification/verificationRequired'
  )<UnknownAction>(),
};

// Private actions
export const verificationDismissed = createAction(
  'verification/verificationDismissed'
)<void>();

export const verificationSucceeded = createAction(
  'verification/verificationSucceeded'
)<void>();
