import React, {
  type Dispatch,
  type JSX,
  type KeyboardEvent,
  type MouseEvent,
  type SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useResizeObserver } from '@saviynt/common';
import { Icon, Loader } from '@saviynt/design-system';
import classNames from 'classnames';

import { type MenuIdsResponse, RequestStatus } from '../../../models';
import {
  findAccessibleMenuLinks,
  type MenuLink,
  type SideMenuNode,
  useCookies,
  useInvertedLocalize,
} from '../../../utilities';
import { type RootState, thunks, useDispatch, useSelector } from '../store';

import { invertedMessages } from './messages';
import ShortcutTile from './ShortcutTile';

import './ShortcutsWidget.css';

interface SelectedState {
  defaultShortcutIds?: MenuIdsResponse;
  defaultShortcutsStatus: RequestStatus;
  sideMenu: SideMenuNode[] | null;
  menuList: MenuLink[] | undefined;
  quicklinks: string[] | 'unauthorized' | undefined;
}

type Page = 'beginning' | 'end';
type FormFactor = 'Desktop' | 'Tablet' | 'Mobile';

export interface ShortcutsWidgetProps {
  onCustomClick(id: string): void;
  className?: string;
}

ShortcutsWidget.defaultProps = {
  className: undefined,
};

const MIN_TABLET_TILE_WIDTH = 178;
const TILE_GAP = 0.875 /* rem */ * 16; /* px */
const DEFAULT_PAGE_SIZE = 3;

export default function ShortcutsWidget({
  onCustomClick,
  ...props
}: ShortcutsWidgetProps): JSX.Element | null {
  const l = useInvertedLocalize(invertedMessages);
  const [pageSize, setPageSize] = useState<number | null>(null);
  const [page, setPage] = useState<Page>('beginning');
  const [formFactor, setFormFactor] = useState<FormFactor>('Desktop');
  const [{ userKey }] = useCookies('userKey');
  const dispatch = useDispatch();
  const {
    defaultShortcutIds,
    defaultShortcutsStatus,
    sideMenu,
    menuList,
    quicklinks,
  } = useSelector(selectState);
  const shortcuts = useMemo(
    () =>
      findAccessibleMenuLinks({
        userKey,
        sideMenu,
        menuList,
        menuIds: defaultShortcutIds,
      }),
    [defaultShortcutIds, sideMenu, menuList, userKey]
  );

  useEffect(() => {
    if (defaultShortcutIds === undefined && quicklinks !== undefined) {
      dispatch(thunks.requestShortcuts(quicklinks));
    }
  }, [dispatch, defaultShortcutIds, quicklinks]);

  const hasError = defaultShortcutIds === 'error';
  const isLoading =
    defaultShortcutsStatus !== RequestStatus.Idle || shortcuts === null;
  const ref = useResizeObserver(hasError || isLoading ? () => {} : onResize);
  const baseClassName = `Nav_ShortcutsWidget Nav_ShortcutsWidget--is${formFactor}`;

  if (hasError) {
    const errorClassName = classNames(
      baseClassName,
      props.className,
      'Nav_ShortcutsWidget--error'
    );

    return (
      <div className={errorClassName}>
        <Icon
          kind='AlertCriticalOutline'
          color='critical-700'
          className='Nav_ShortcutsWidget-errorIcon'
        />{' '}
        <span className='Nav_ShortcutsWidget-errorMessage'>
          {l(
            'There was a problem loading your shortcuts. Refresh the page to retry.'
          )}
        </span>
      </div>
    );
  }

  if (isLoading) {
    const loadingClassName = classNames(
      baseClassName,
      props.className,
      'Nav_ShortcutsWidget--loading'
    );

    return (
      <div className={loadingClassName}>
        <Loader kind='dots' format='inline' color='brand' size='small' />
      </div>
    );
  }

  const className = classNames(baseClassName, props.className);
  const effectivePageSize = pageSize ?? shortcuts.length;
  const start = page === 'beginning' ? 0 : shortcuts.length - effectivePageSize;
  const end = start + effectivePageSize;
  const onPageIndicatorInteraction = onPageIndicatorInteractionFactory(
    page,
    setPage
  );
  const ariaLabel = page === 'beginning' ? 'First page' : 'Last page';
  const showPageIndicator = pageSize !== null;
  const isMobileFormFactor = formFactor === 'Mobile';

  return (
    <div className={className} ref={ref}>
      <div className='Nav_ShortcutsWidget-tiles'>
        {shortcuts.slice(start, end).map((shortcut) => (
          <ShortcutTile
            className='Nav_ShortcutsWidget-tiles-tile'
            key={shortcut.id}
            shortcut={shortcut}
            onCustomClick={onCustomClick}
            isMobileFormFactor={isMobileFormFactor}
          />
        ))}
      </div>
      {showPageIndicator && (
        <div
          className={`Nav_ShortcutsWidget-pageIndicator Nav_ShortcutsWidget-pageIndicator--${page}`}
          onClick={onPageIndicatorInteraction}
          role='button'
          onKeyDown={onPageIndicatorInteraction}
          tabIndex={0}
          aria-label={ariaLabel}
        />
      )}
    </div>
  );

  /** Updates responsive state (formFactor and pageSize) */
  function onResize(contentRect: DOMRect) {
    if (ref.current === null || shortcuts === null) {
      return;
    }

    const style = window.getComputedStyle(ref.current);

    // These custom properties are set by parent component via CSS container query.
    if (style.getPropertyValue('--is-desktop') === 'true') {
      setFormFactor('Desktop');
      setPageSize(null);

      return;
    }

    if (style.getPropertyValue('--is-tablet') === 'true') {
      setFormFactor('Tablet');
    } else if (style.getPropertyValue('--is-mobile') === 'true') {
      setFormFactor('Mobile');
    }

    const isPagingNeeded = shortcuts.length >= DEFAULT_PAGE_SIZE;

    if (!isPagingNeeded) {
      setPageSize(null);

      return;
    }

    const tileWidth =
      (contentRect.width - TILE_GAP * DEFAULT_PAGE_SIZE) / DEFAULT_PAGE_SIZE;

    if (tileWidth < MIN_TABLET_TILE_WIDTH) {
      setPageSize(DEFAULT_PAGE_SIZE - 1);

      return;
    }

    setPageSize(DEFAULT_PAGE_SIZE);
  }
}

function selectState(state: RootState): SelectedState {
  const {
    shortcuts: { defaultShortcutIds, defaultShortcutsStatus },
    app: { sideMenu },
    profile: { menuList },
    user: {
      homepage: { quicklinks },
    },
  } = state;

  return {
    defaultShortcutsStatus,
    defaultShortcutIds,
    sideMenu,
    menuList,
    quicklinks,
  };
}

type HtmlUIEvent = MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>;

function onPageIndicatorInteractionFactory(
  page: Page,
  setPage: Dispatch<SetStateAction<Page>>
): (e: HtmlUIEvent) => void {
  return (e: HtmlUIEvent) => {
    e.preventDefault();

    if ('key' in e && e.key !== 'Enter') {
      return;
    }

    setPage(page === 'beginning' ? 'end' : 'beginning');
  };
}
