export const CREDENTIAL_REQUEST_REQUESTOR = 'CREDENTIAL_REQUEST_REQUESTOR';
export const CREDENTIAL_REQUEST_PAMTYPE_CREDENTIAL =
  'CREDENTIAL_REQUEST_PAMTYPE_CREDENTIAL';
export const CREDENTIAL_REQUEST_PAMTYPE_JUSTINTIME =
  'CREDENTIAL_REQUEST_PAMTYPE_JUSTINTIME';
export const CREDENTIAL_REQUEST_ACCOUNT_ID = 'CREDENTIAL_REQUEST_ACCOUNT_ID';
export const CREDENTIAL_REQUEST_ENDPOINT_KEY =
  'CREDENTIAL_REQUEST_ENDPOINT_KEY';
export const CREDENTIAL_REQUEST_DATABASE_PRIVILEGES =
  'CREDENTIAL_REQUEST_DATABASE_PRIVILEGES';
export const CREDENTIAL_REQUEST_REMOTE_APP_DETAILS =
  'CREDENTIAL_REQUEST_REMOTE_APP_DETAILS';
export const CREDENTIAL_REQUEST_BUSINESS_JUSTIFICATION =
  'CREDENTIAL_REQUEST_BUSINESS_JUSTIFICATION';
export const CREDENTIAL_REQUEST_START_END_DATES =
  'CREDENTIAL_REQUEST_START_END_DATES';
export const CLEAR_CREDENTIAL_REQUEST_FORM_DATES =
  'CLEAR_CREDENTIAL_REQUEST_FORM_DATES';
export const CLEAR_CREDENTIAL_REQUEST_FORM = 'CLEAR_CREDENTIAL_REQUEST_FORM';
export const DURATION_HAS_UNSAVED_CHANGES = 'DURATION_HAS_UNSAVED_CHANGES';
export const CREDENTIAL_REQUEST_TCODES = 'CREDENTIAL_REQUEST_TCODES';

export const handleIsCredentialsRequest = (success) => ({
  type: CREDENTIAL_REQUEST_PAMTYPE_CREDENTIAL,
  successMessage: success,
});

export const handleIsJustInTimeRequest = (data, success) => ({
  type: CREDENTIAL_REQUEST_PAMTYPE_JUSTINTIME,
  payload: data,
  successMessage: success,
});

export const handleCredentialFormRequestor = (data, success) => ({
  type: CREDENTIAL_REQUEST_REQUESTOR,
  payload: data,
  successMessage: success,
});

export const handleCredentialFormAccountId = (data, success) => ({
  type: CREDENTIAL_REQUEST_ACCOUNT_ID,
  payload: data,
  successMessage: success,
});

export const handleCredentialFormEndpointKey = (data, success) => ({
  type: CREDENTIAL_REQUEST_ENDPOINT_KEY,
  payload: data,
  successMessage: success,
});

export const handleCredentialFormDatabasePrivleges = (data, success) => ({
  type: CREDENTIAL_REQUEST_DATABASE_PRIVILEGES,
  payload: data,
  successMessage: success,
});

export const handleCredentialFormRemoteAppDetails = (data, success) => ({
  type: CREDENTIAL_REQUEST_REMOTE_APP_DETAILS,
  payload: data,
  successMessage: success,
});

export const handleCredentialFormJustification = (data, success) => ({
  type: CREDENTIAL_REQUEST_BUSINESS_JUSTIFICATION,
  payload: data,
  successMessage: success,
});

export const handleCredentialFormDates = (startDate, endDate, success) => ({
  type: CREDENTIAL_REQUEST_START_END_DATES,
  payload: { start: startDate, end: endDate },
  successMessage: success,
});

export const handleClearCredentialFormDates = (success) => ({
  type: CLEAR_CREDENTIAL_REQUEST_FORM_DATES,
  successMessage: success,
});

export const handleClearCredentialForm = (success) => ({
  type: CLEAR_CREDENTIAL_REQUEST_FORM,
  successMessage: success,
});

export const handleIsDurationBeingEditedWithChanges = (data, success) => ({
  type: DURATION_HAS_UNSAVED_CHANGES,
  payload: data,
  successMessage: success,
});

export const handleCredentialFormTCodeDetails = (data, success) => ({
  type: CREDENTIAL_REQUEST_TCODES,
  payload: data,
  successMessage: success,
});
