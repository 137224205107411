/* eslint-disable object-curly-newline */
import { Cookies } from 'react-cookie';

import HttpServiceSingleton, {
  HttpService,
} from '../../misc/HttpService/HttpService';

import getAccountDetailsApiUrl from './urls/getAccountDetailsApiUrl';
import getAccountListApiUrl from './urls/getAccountListApiUrl';
import getAccountTypesApiUrl from './urls/getAccountTypesApiUrl';
import getAssetTypeApiUrl from './urls/getAssetTypeApiUrl';
import getCancelApiUrl from './urls/getCancelApiUrl';
import getCheckInApiUrl from './urls/getCheckInApiUrl';
import getEndpointDetailsApiUrl from './urls/getEndpointDetailsApiUrl';
import getExtendSessionUrl from './urls/getExtendSessionUrl';
import getListRowApiUrl from './urls/getListRowApiUrl';
import getPlatformTypeApiUrl from './urls/getPlatformTypeApiUrl';
import getRequestDetailsUrl from './urls/getRequestDetailsUrl';
import getRequestFileUploadApiUrl from './urls/getRequestFileUploadApiUrl';
import getRolesDetailsApiUrl from './urls/getRolesDetailsApiUrl';
import getRolesEntitlementsApiUrl from './urls/getRolesEntitlementsApiUrl';
import getRolesListApiUrl from './urls/getRolesListApiUrl';
import getSessionDetailsApiUrl from './urls/getSessionDetailsApiUrl';
import getSessionsAccountScheduleUrl from './urls/getSessionsAccountScheduleUrl';
import getTcodesApiUrl from './urls/getTcodesApiUrl';
import postCredentialRequestUrl from './urls/postCredentialRequestUrl';

const cookies = new Cookies();

export const getPlatformTypeApi = () =>
  HttpServiceSingleton.get(getPlatformTypeApiUrl()).then(
    (response) => response
  );

export const getAssetTypeApi = () =>
  HttpServiceSingleton.get(getAssetTypeApiUrl()).then((response) => response);

export const getAccountTypesApi = () =>
  HttpServiceSingleton.get(getAccountTypesApiUrl()).then(
    (response) => response
  );

export const getAccountDetailsApi = (accountKey) =>
  HttpServiceSingleton.get(getAccountDetailsApiUrl(accountKey)).then(
    (response) => response
  );

export const getListRowApi = () =>
  HttpServiceSingleton.post(getListRowApiUrl(), {}).then(
    (response) => response
  );

export const getEndpointDetailsApi = (endpointKey) =>
  HttpServiceSingleton.get(getEndpointDetailsApiUrl(endpointKey)).then(
    (response) => response
  );

export const getListRowApiFilterAndSearch = (
  platformTypes,
  assetTypes,
  roleTypes,
  search
) => {
  const filterBy = {};

  if (platformTypes.length > 0) {
    filterBy.platformTypes = platformTypes;
  }

  if (assetTypes.length > 0) {
    filterBy.assetTypes = assetTypes;
  }

  if (roleTypes.length > 0) {
    filterBy.roleTypes = roleTypes;
  }

  return HttpServiceSingleton.post(getListRowApiUrl(), {
    filterBy,
    findBy: search,
  }).then((response) => response);
};

export const getAccountListApi = (
  listKey,
  pamType,
  findBy,
  max,
  offset = 0
) => {
  const requestBody = {
    filterBy: { endpointKeys: [listKey], pamType },
  };

  if (findBy) {
    requestBody.findBy = findBy;
  }

  if (max) {
    requestBody.max = max;
  }

  if (offset !== undefined) {
    requestBody.offset = offset;
  }

  return HttpServiceSingleton.post(getAccountListApiUrl(), requestBody).then(
    (response) => response
  );
};

export const getCheckInApi = (row) =>
  HttpServiceSingleton.post(getCheckInApiUrl(), {
    endSessionModels: [
      {
        requestAccessKey: row.requestAccessKey,
        // TODO: For roles, update after integrating accounts
        accountName: row.accountName || 'N/A',
        userName: cookies.get('user_name'),
        resourceId: row.endpointDisplayName || row.requestId,
        removeaccess: true,
      },
    ],
  }).then((response) => response);

export const getCancelApi = (requestKey, userName) =>
  HttpServiceSingleton.put(getCancelApiUrl(requestKey), {
    approver: userName,
    action: 'discontinue',
  }).then((response) => response);

export const getSessionDetailsApi = (requestAccessKey, userKey) =>
  HttpServiceSingleton.get(
    getSessionDetailsApiUrl(requestAccessKey, userKey)
  ).then((response) => response);

export const getSessionsAccountScheduleApi = (
  accountKey,
  endpointKey,
  fromDate,
  toDate
) =>
  HttpServiceSingleton.post(getSessionsAccountScheduleUrl(), {
    findBy: '',
    filterBy: {
      fromDate,
      toDate,
      endpointKeys: [endpointKey],
      ...(accountKey && { accountKeys: [accountKey] }),
    },
    offset: 0,
    max: 100,
  }).then((response) => response);

export const postCredentialRequest = (request) =>
  HttpServiceSingleton.post(postCredentialRequestUrl(), request).then(
    (response) => response
  );

export const getRequestDetailsApi = (userName, requestId) =>
  HttpServiceSingleton.get(getRequestDetailsUrl(userName, requestId)).then(
    (response) => response
  );

export const getFileUploadMetadataApi = (metadataType) =>
  Promise.resolve({
    configData: JSON.parse(localStorage.getItem('uiTrackConfig') ?? '{}')[
      metadataType
    ],
  });

export const getRequestFileUploadApi = async (
  requestKey,
  attachmentsObj,
  errorFunction = () => {}
) => {
  const httpServiceObj = new HttpService();

  httpServiceObj.deleteHeader('Content-Type');

  return httpServiceObj
    .post(
      getRequestFileUploadApiUrl(cookies.get('user_name'), requestKey),
      attachmentsObj,
      errorFunction,
      false // don't stringify request body
    )
    .then((response) => response)
    .catch((error) => error);
};

export const getExtendSessionApi = (
  requestAccessKey,
  enddate,
  businessjustification
) =>
  HttpServiceSingleton.post(getExtendSessionUrl(), [
    {
      accesskey: requestAccessKey,
      username: cookies.get('user_name'),
      enddate,
      businessjustification,
    },
  ]).then((response) => response);

export const getTcodesApi = (endpointName, searchTerm, offset = 0, max = 10) =>
  HttpServiceSingleton.post(getTcodesApiUrl(), {
    endpointKey: endpointName,
    offset,
    max,
    filterBy: { entitlementName: searchTerm },
  }).then((response) => response);

export const getRolesListApi = (findBy, assetTypes, platformTypes) =>
  HttpServiceSingleton.post(getRolesListApiUrl(), {
    findBy,
    filterBy: {
      assetTypes,
      platformTypes,
    },
  }).then((response) => response);

export const getRolesDetailsApi = (roleKey) =>
  HttpServiceSingleton.get(getRolesDetailsApiUrl(roleKey)).then(
    (response) => response
  );

export const getRolesEntitlementsApi = (
  roleKey,
  findBy,
  filterBy,
  offset = 0,
  max = 1000
) =>
  HttpServiceSingleton.post(getRolesEntitlementsApiUrl(), {
    roleKey,
    findBy,
    filterBy: {
      assetTypes: filterBy.assetTypes,
      platformTypes: filterBy.platformTypes,
    },
    offset,
    max,
  }).then((response) => response);

// use api call if/when endpoint ready.
export const getRoleTypeApi = () =>
  Promise.resolve({ EMERGENCYACCESSROLE: 'Emergency Access' });
