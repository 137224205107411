import parser from 'url-parameter-parser';
import AntPathMatcher from 'ant-path-matcher';
import SessionStorageService from 'ui-common/src/utils/SessionStorageService';
import findIfQueryParamsMatch from './findIfQueryParamsMatch';
import { homePageUrl } from '../Constants';

const findAssociatedMenuForNavigatedUrl = (menuData, location) => {
  // if home page, return null

  if (location === homePageUrl) {
    SessionStorageService.set('currentMenu', null);
    localStorage.setItem('currentMenu', null);
    return SessionStorageService.get('currentMenu');
  }

  let associatedMenu = null;
  const navigationLinkAndMenuMap = new Map(JSON.parse(SessionStorageService.get('navMap'))) || new Map();

  const link = location.startsWith('/ECMv6') ? location.toString().substring(6) : location;

  if (link != null && link !== homePageUrl) {
    const findMatch = (menu) => {
      if (associatedMenu != null) {
        return;
      }

      if (menu.link) {
        const matcher = new AntPathMatcher();
        const match = (pattern, path) => matcher.match(pattern, path);

        // check if url contains query params
        if (link.toString().includes('?')) {
          const locationPathName = link.split('?');
          const apiDataPathName = menu.link.toString().split('?');

          // first match the path name
          const matchFoundForPathNames = !apiDataPathName[0].toString().startsWith('/')
            ? match(`/${apiDataPathName[0]}`, locationPathName[0])
            : match(apiDataPathName[0], locationPathName[0]);

          if (matchFoundForPathNames) {
            // check for query params match
            const matchFoundForQueryParams = findIfQueryParamsMatch(parser(menu.link), parser(link));

            if (matchFoundForQueryParams) {
              associatedMenu = menu;
              SessionStorageService.set('currentMenu', JSON.stringify(menu));
              localStorage.setItem('currentMenu', JSON.stringify(menu));
              navigationLinkAndMenuMap.set(link, menu);
              SessionStorageService.set('navMap', JSON.stringify(Array.from(navigationLinkAndMenuMap.entries())));
              localStorage.setItem('navMap', JSON.stringify(Array.from(navigationLinkAndMenuMap.entries())));
            }
          }
        } else {
          const matchFoundForPathNames = !(menu.link).toString().startsWith('/')
            ? match(`/${menu.link}`, link)
            : match(menu.link, link);

          if (matchFoundForPathNames) {
            associatedMenu = menu;
            SessionStorageService.set('currentMenu', JSON.stringify(menu));
            localStorage.setItem('currentMenu', JSON.stringify(menu))
            navigationLinkAndMenuMap.set(link, menu);
            SessionStorageService.set('navMap', JSON.stringify(Array.from(navigationLinkAndMenuMap.entries())));
            localStorage.setItem('navMap', JSON.stringify(Array.from(navigationLinkAndMenuMap.entries())));

          }
        }
      }

      if (menu.subMenu) {
        if (associatedMenu == null) {
          for (const item of menu.subMenu) {
            findMatch(item);
          }
        }
      }
    };

    for (const menu of menuData) {
      findMatch(menu);

      if (associatedMenu != null) {
        return associatedMenu;
      }
    }
  }

  // const navMap = new Map(JSON.parse(SessionStorageService.get('navMap')));
  let navMap = new Map(JSON.parse(SessionStorageService.get('navMap')));
  if(!navMap) {
    navMap = new Map(JSON.parse(localStorage.getItem("navMap")));
  }

  if (navMap.size > 0 && navMap.has(link) && navMap.get(link) !== 'null') {
    return navMap.get(link);
  }

  /* ToDo - To be deleted after testing
  const currentMenu = SessionStorageService.get('currentMenu') === 'null' ? SessionStorageService.get('currentMenu')
    : JSON.parse(SessionStorageService.get('currentMenu'));*/
  
  let currentMenu = null;
  if (SessionStorageService.get('currentMenu') === 'null') {
    currentMenu = JSON.parse(localStorage.getItem('currentMenu'));
    SessionStorageService.set('currentMenu', currentMenu);
  } else {
    currentMenu = JSON.parse(SessionStorageService.get('currentMenu'));
  }

  navigationLinkAndMenuMap.set(link, currentMenu);
  console.log('navigationLinkAndMenuMap', navigationLinkAndMenuMap);
  SessionStorageService.set('navMap', JSON.stringify(Array.from(navigationLinkAndMenuMap.entries())));
  localStorage.setItem('navMap', JSON.stringify(Array.from(navigationLinkAndMenuMap.entries())));
  return currentMenu;
};

export default findAssociatedMenuForNavigatedUrl;
