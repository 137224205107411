import * as C from './actions'
import { countries, ALL } from './constants'

const defaultState = {
  messageLabels: {},
  editedLabels: [],
  allLangSelected: false,
  selectedLanguages: ['en'],
  unselectedLanguage: null
}

const saveMessagePropSuccess = (state) => {
  return {
    ...state,
    editedLabels: []
  }
}

const getMessgPropEntry = (key, values) => {
  return {
    messageKey: key,
    messageValues: values
  }
}

const getLangObject = (lang, value) => {
  return { language: lang, content: value}
}

const editMessageProp = (state, action) => {
  const { key, language, value } = action;
  const { editedLabels } = state;
  if (key && language) {
    const matchingProps = editedLabels.filter(obj => obj.messageKey === key)
    if (matchingProps && matchingProps.length > 0) {
      const restProps = editedLabels.filter(obj => obj.messageKey !== key)
      const msgValues = matchingProps[0].messageValues.filter(row => row.language !== language)
      const newValues = msgValues.concat(getLangObject(language, value));
      return {
        ...state,
        editedLabels: [ ...restProps, getMessgPropEntry(key, newValues) ]
      }
    } else {
      return {
        ...state,
        editedLabels: state.editedLabels.concat(getMessgPropEntry(key, [getLangObject(language, value)]))
      }
    }
  }
}

const revertEditMessageProp = (state, action) => {
  const { key, language, value } = action;
  const { editedLabels } = state;
  if (key && language) {
    const matchingProps = editedLabels.filter(obj => obj.messageKey === key)
    if (matchingProps && matchingProps.length > 0) {
      const restProps = editedLabels.filter(obj => obj.messageKey !== key)
      const msgValues = matchingProps[0].messageValues.filter(row => row.language !== language)
      if (msgValues.length > 0) {
        return {
          ...state,
          editedLabels: [ ...restProps, getMessgPropEntry(key, msgValues) ]
        }
      } else {
        if (restProps.length === 0) {
          return {
            ...state,
            editedLabels: []
          }
        }
        return {
          ...state,
          editedLabels: [ ...restProps ]
        }
      }
    }
  } else {
    return {
      ...state,
      editedLabels: []
    }
  }
}

const setSelectededLang = (state, action) => {
  const { langCode, selected } = action;
  if (selected) {
    return {
      ...state,
      selectedLanguages: state.selectedLanguages.concat(langCode),
      unselectedLanguage: null
    }
  } else {
    return {
      ...state,
      selectedLanguages: state.selectedLanguages.filter(lang => lang !== langCode)
    }
  }
}

const setAllLangSelected = (state) => {
  return {
    ...state,
    allLangSelected: true,
    selectedLanguages: countries.map(c => c.code),
    unselectedLanguage: null
  }

}

const setAllLangUnSelected = (state) => {
  return {
    ...state,
    allLangSelected: false,
    selectedLanguages: ['en']
  }
}

const removeLangSelection = (state, action) => {
  const { langCode } = action;
  return {
    ...state,
    unselectedLanguage: langCode
  }
}

const clearRemoveLangSelection = (state) => {
  return {
    ...state,
    unselectedLanguage: null
  }
}

const getLabelVals = (messageValues, langCode) => {
  if (langCode === ALL.code) {
    return messageValues.filter(mv => mv.language === 'en')
  } else {
    return messageValues.filter(mv => mv.language !== langCode)
  }
}

const removeEditedLangLabels = (state, action) => {
  const { langCode } = action;
  const { editedLabels } = state;
  const newLabels = []
  editedLabels.forEach(el => {
    const labelVals = getLabelVals(el.messageValues, langCode)
    if (labelVals.length > 0) {
      newLabels.push({
        messageKey: el.messageKey,
        messageValues: labelVals
      })
    }
  })
  return {
    ...state,
    editedLabels: newLabels
  }
}

export const MessageLabelReducer = (state = defaultState, action) => {
    switch (action.type) {

      case C.GET_MESSAGE_LABELS_SUCCESS: {
        return{
          ...state,
          messageLabels: action.payload
        }
      }

      case C.EDIT_MESSAGE_PROP:
        return editMessageProp(state, action);

      case C.SET_SELECTED_LANGUAGE:
        return setSelectededLang(state, action);  

      case C.REVERT_EDIT_MSG_PROP:
        return revertEditMessageProp(state, action);

      case C.SET_ALL_LANG_SELECTED:
        return setAllLangSelected(state);

      case C.SET_ALL_LANG_UNSELECTED:
          return setAllLangUnSelected(state);

      case C.REMOVE_LANGUAGE_SELECTION:
          return removeLangSelection(state, action);
      
      case C.CLEAR_REMOVE_LANGUAGE_SELECTION:
          return clearRemoveLangSelection(state)

      case C.REMOVE_EDITED_LANGUAGE_LABELS:
          return removeEditedLangLabels(state, action);    

      case C.SAVE_MESSAGE_PROP_SUCCESS:
      case C.CANCEL_EDIT_MSG_OP:
        return saveMessagePropSuccess(state);

      default: {
        return state
      }
    }
  
  }
