import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import MenuItem from '../MenuItem/MenuItem';
import Modal from '../../Modal/Modal';
import AboutDetails from './AboutDetails';

export const AboutIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.1987 7.91667C13.1987 8.42293 12.7883 8.83333 12.2821 8.83333C11.7758 8.83333 11.3654 8.42293 11.3654 7.91667C11.3654 7.41041 11.7758 7 12.2821 7C12.7883 7 13.1987 7.41041 13.1987 7.91667Z"
      fill="#032185"
    />
    <path
      d="M13.0495 10.5329C13.1737 10.6617 13.2196 10.8469 13.1699 11.0187L11.7586 15.902L12.9581 15.2435C13.2002 15.1106 13.5042 15.1991 13.637 15.4412C13.7699 15.6832 13.6814 15.9872 13.4393 16.1201L11.8881 16.9716C11.238 17.3285 10.4806 16.7222 10.6865 16.0097L11.9681 11.5753L11.0651 11.8007C10.7972 11.8676 10.5258 11.7046 10.4589 11.4367C10.3921 11.1688 10.555 10.8974 10.823 10.8305L12.5685 10.3948C12.742 10.3515 12.9254 10.4041 13.0495 10.5329Z"
      fill="#032185"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12Z"
      fill="#032185"
    />
  </svg>
);

const About = ({ intl }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <MenuItem
        label={intl.formatMessage({
          id: 'gen2.profileMenu.label.about',
        })}
        icon={<AboutIcon />}
        handleClick={handleClickOpen}
      />
      <Modal open={open} handleClose={handleClickClose}>
        <AboutDetails onClose={handleClickClose} />
      </Modal>
    </>
  );
};

About.propTypes = {
  intl: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string.isRequired,
      formatMessage: PropTypes.func.isRequired,
    }),
  ).isRequired,
};

export default injectIntl(About);
