import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
// import SearchIcon from '@material-ui/icons/Search';
import SideNavSearchIcon from '../../ECMv6/assets/SideNavSearchIcon';
import SideNavSearchIconOpen from '../../ECMv6/assets/SideNavSearchIconOpen';
import { injectIntl } from 'react-intl';
import SearchCloseIcon from '../../ECMv6/assets/SearchCloseIcon';
import { testIds as t } from 'ui-common/src/constants';

const SearchBar = ({
  data,
  handleSearchData,
  intl,
  open,
  handleReset,
  searchKey,
  setSearchKey,
}) => {
  // const [searchKey, setSearchKey] = useState('');
  const useStyles = makeStyles((theme) => {
    const searchClass = {
      display: 'flex',
      flex: '0 0 auto',
      alignItems: 'center',
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: open ? 'var(--SIDEMENU-MENUITEM-BACKGROUND)' : 'transparent',
    };

    return {
      search: {
        ...searchClass,
        margin: open ? '0 12px 12px 12px' : '0 12px 9px',
        width: 'auto',
        minHeight: open ? 'auto' : '35px',
      },
      searchIcon: {
        padding: '0 6px',
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1,
      },
      inputRoot: {
        color: 'inherit',
        width: '100%',
        borderRadius: '4px',
        transition: 'border 3s',
        // border: open ? "1px solid #00245B" : "none", //the variable is nt available for this color code. Need to change later.
        border: open ? '1px solid var(--SIDEMENU-SEARCH-BORDER)' : 'none', //the variable is nt available for this color code. Need to change later.
        height: '32px',
      },
      inputInput: {
        margin: theme.spacing(1, 1, 1, 0),
        visibility: open ? 'visible' : 'hidden',
        // vertical padding + font size from searchIcon
        paddingLeft: 'calc(1em + 22px)',
        transition: theme.transitions.create('width'),
        width: '100%',
        '&::placeholder': {
          fontSize: '14px',
          color: 'var(--SIDEMENU-SUBTEXT-COLOR)',
          opacity: '1',
        },
        paddingRight: 10,
      },
      focus: {
        border: open ? '#00245B' : '',
        borderRadius: theme.shape.borderRadius,
      },
      clearIcon: {
        transform: 'translateX(-30px)',
        scale: 0.8,
        cursor: 'pointer',
        right: '-5px',
        position: 'relative',
      },
    };
  });
  const classes = useStyles();
  const searchData = (dataArray, searchTerm) =>
    dataArray.flatMap((obj) => {
      const getTranslatedText = (text) => intl.formatMessage({ id: `gen2.leftnav.label.${text}` });
      const objHasSearchTerm = Object.entries(obj).some(
        ([key]) =>
          key !== 'subMenu' &&
          getTranslatedText(obj.id).toLowerCase().includes(searchTerm.toLowerCase())
      );
      const matchedChildren = searchData(
        obj.subMenu && obj.subMenu?.length ? obj.subMenu : [],
        searchTerm
      );
      return matchedChildren.length > 0 || (objHasSearchTerm && !obj.subMenu)
        ? [
            {
              ...obj,
              subMenu: matchedChildren,
            },
          ]
        : [];
    });

  const handleChange = (event) => {
    const searchTerm = event.target.value;
    setSearchKey(searchTerm);

    /* Code to Show/Hide the PAM menus in the left navigation based on pamGen2 flag */
    const PRIVILEGED_ACCESS = 'privileged-access';
    const MENU_TO_ALLOWED_IF_PAM_ENABLED = {
      ASSET_SESSIONS: 'assets-sessions',
      PRIV_SVC_ACCT_MGMT: 'privileged-service-account-management',
    };

    const MENU_TO_ALLOWED_IF_PAM_DISABLED = {
      NEW_PRIV_ACCESS: 'new-privileged-access',
      PRIV_SESSIONS: 'privileged-sessions',
      PAST_SESSIONS: 'past-sessions',
      PRIV_SVC_ACCT_MGMT: MENU_TO_ALLOWED_IF_PAM_ENABLED.PRIV_SVC_ACCT_MGMT,
    };
    const pamGen2Flag = window.localStorage.getItem('pamGen2');
    data[0].subMenu.forEach((item) => {
      if (item.id === PRIVILEGED_ACCESS) {
        const allowedIds = Boolean(parseInt(pamGen2Flag))
          ? Object.values(MENU_TO_ALLOWED_IF_PAM_ENABLED)
          : Object.values(MENU_TO_ALLOWED_IF_PAM_DISABLED);
        item.subMenu = item.subMenu.filter((ele) => allowedIds.includes(ele.id));
      }
    });

    const result = searchData(data, searchTerm);
    if (searchTerm !== '') {
      handleSearchData(result, true);
    } else {
      handleSearchData(result, false);
    }
  };

  const handleClose = () => {
    console.log('123');
    setSearchKey('');
    handleReset();
  };

  return (
    <div className={classes.search}>
      {open ? (
        <div className={classes.searchIcon}>
          <SideNavSearchIconOpen style={{ color: 'var(--SIDEMENU-SUBTEXT-COLOR' }} />
        </div>
      ) : (
        <div className={classes.searchIcon}>
          <SideNavSearchIcon style={{ color: 'var(--SIDEMENU-SUBTEXT-COLOR' }} />
        </div>
      )}
      {open ? (
        <InputBase
          placeholder="Search pages & features"
          onChange={handleChange}
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
            focused: classes.focus,
          }}
          inputProps={{ 'aria-label': 'Search pages & features' }}
          value={searchKey}
          data-testid={`${t.SIDE_MENU_SEARCH}`}
        />
      ) : null}
      {open && searchKey.length > 0 ? (
        <span className={classes.clearIcon} onClick={handleClose}>
          <SearchCloseIcon />
        </span>
      ) : null}
    </div>
  );
};

export default injectIntl(SearchBar);
