import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Cookies } from 'react-cookie';
import { Loader } from 'ui-components/src';
import { Footer } from 'ui-home/src/features/MainLayout/Footer';
import Logs from './src/Logs/Logs';
import styles from './src/admin.module.css';
import ErrorBoundary from './ErrorBoundry';

const cookies = new Cookies();

const Admin = () => (
  <div className={styles.page}>
    <div className={styles.container}>
      <ErrorBoundary>
        <Suspense key="UI-Admin" fallback={<Loader />}>
          <Router basename="/admin">
            <Route exact path="/logs" component={Logs} />
            <Route exact path="/adminHome/manageLogs" component={Logs} />
          </Router>
        </Suspense>
      </ErrorBoundary>
    </div>
    {cookies.get('leftNavGen2') === '1' ? null : <div className={styles.footer}>
      <Footer />
    </div>}
  </div>
);

export default Admin;
