import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import classnames from 'classnames'
import './style.scss'

export interface AppVersionProps {
  /**
   * Application Version string
   */
  appVersion: string;
  /**
   * Footer text string
   */
  footerText?: string;
  /**
   * Classname for container class
   */
  className?: string;
  /**
   * show/hide version popup
   */
  open?: boolean;
  /**
   * Optional close popup handler
   */
  onClose?: () => void;
}

const AppVersion: React.FC<AppVersionProps> = ({
  appVersion,
  className,
  open = false,
  onClose = () => {},
}) => {
  const containerClass = classnames('app-version-container-paper',className);
  return (
    <Dialog classes={{
      paper: containerClass
    }} aria-labelledby="simple-dialog-title"
    onBackdropClick={onClose}
     open={open}>
      <div className='app-version-container'>
        <div className='app-version-close-icon'>
          <CloseIcon onClick={onClose} />
        </div>
        <div className='app-version-logo' />
        <div className='app-version-text-row1'>Saviynt</div>
        <div className='app-version-text-row2'>{appVersion}</div>
      </div>
    </Dialog>
  );
};
export default AppVersion;
