/* Please be mindful of functions that are added to this misc file.
There's a good chance your function fits into a more
specific file that already exists, or should be created. */
/* eslint-disable import/prefer-default-export */
import { useCallback, useEffect } from 'react';

import { Logger } from './logger';

/**
 * Note: you most likely do not need this cookie function.
 * First check if react-cookie is available in your app.
 *
 * Retrieve the value of a cookie by name.
 * @param {string} name - name of the cookie.
 * @returns {string|null} value of the cookie, or null if not found.
 */
const getCookieValue = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);

  if (parts.length === 2) return parts.pop().split(';').shift();

  return null;
};

/**
 * Note: This function helps add classes to the 'root' element,
 * you still need to add the CSS in your app to hide the elements.
 *
 * Hides elements (toast, snackbar, loader) from the host app based on certain conditions.
 * If flagName is provided, check if the flagValue matches the cookie value.
 * If the flagValues match, add the class names to the 'root' element.
 *
 * @param {boolean} shouldHideIfNoFlag - whether to hide elements if no flagName is passed.
 * @param {string|null} flagName - name of the flag to check against the cookie value.
 * @param {string|null} flagValue - value of the flag to match against the cookie value.
 * @returns {void}
 *
 * // hide elements only if the pamGen2 cookie is set to '1':
 * @example hideElementsFromHostApp(false, 'pamGen2', '1');
 *
 * @example hideElementsFromHostApp(true); // hide elements always
 *
 * @example hideElementsFromHostApp(false); // noop
 */
export const hideElementsFromHostApp = (
  shouldHideIfNoFlag = false,
  flagName = null,
  flagValue = null
) => {
  if (flagName) {
    const flagCookieValue = getCookieValue(flagName);

    if (flagCookieValue === flagValue) {
      Logger.log(
        'Setting classes to hide elements from host app based on flag.'
      );
      document.getElementById('root')?.classList?.add('hideHostMessages');
      document.getElementById('root')?.classList?.add('hideHostLoader');
    }

    return;
  }

  if (shouldHideIfNoFlag) {
    Logger.log('Setting classes to hide elements from host app.');
    document.getElementById('root')?.classList?.add('hideHostMessages');
    document.getElementById('root')?.classList?.add('hideHostLoader');
  }
};

/**
 * Cleanup function for hideElementsFromHostApp function above
 */
export const removeClassesForHidingElements = () => {
  document.getElementById('root')?.classList?.remove('hideHostMessages');
  document.getElementById('root')?.classList?.remove('hideHostLoader');
};

/**
 * Custom hook to handle outside clicks
 *
 * @param {React.RefObject} ref - React ref for the target element
 * @param {Function} onClick - Function to execute on outside click
 * @param {boolean} shouldAttach - Flag to attach/detach the listener
 */
export const useOutsideClickEffect = (ref, onClick, shouldAttach = true) => {
  const handleOutsideClick = useCallback(
    (event) => {
      if (
        ref.current instanceof HTMLElement &&
        !ref.current.contains(event.target)
      ) {
        onClick();
      }
    },
    [ref, onClick]
  );

  useEffect(() => {
    if (shouldAttach) {
      document.addEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [handleOutsideClick, shouldAttach]);
};
