import _ from 'lodash';
import {
  loginRequestActionType,
  loginFailedActionType,
  loginSuccessActionType,
  loginInfoSuccessActionType,
  userInfoActionType,
  sessiontoast,
  logoutActionType,
  userModulesSuccessActionType,
  APP_CONSTANTS_SUCCESS,
  APP_MESSAGES_SUCCESS,
  GET_HEADER_MENU_SUCCESS,
  FULL_SCREEN_GLOBAL_OPEN,
  GET_INITIAL_DATA_ACTION,
  GET_INITIAL_DATA_SUCCESS_ACTION,
  UPDATE_EMAIL_LANGUAGE,
  UPDATE_LANGUAGE_STATE,
  PAGE_RELOAD,
  GET_LEFTNAV_MODERN_EXP_GEN2_SUCCESS_ACTION,
  GET_HOMEPAGE_MODERN_EXP_GEN2_SUCCESS_ACTION,
  EXPAND_NAVIGATION_LOGIN_SUCCESS_ACTION,
  GET_SIDEMENU_REQUEST_CONFIG_SUCCESS_ACTION,
  GET_PAM_GEN2_SUCCESS_ACTION,
  GET_CERTS_GEN2_SUCCESS_ACTION,
  GET_HOMEPAGE_QUICKLINKS_SUCCESS_ACTION,
  GET_HOMEPAGE_KPIS_SUCCESS_ACTION,
  SET_CUSTOMER_INFO,
  SET_PENDO_INFO
} from 'ui-common/src/utils/LoginActions';

import { LANGUAGE_CHANGE_STATE } from './constants';

import { FETCH_ECM_VERSION_SUCCESS } from '../Version/redux/constants';

export const loginReducer = (
  state = {
    userData: [],
    userInfo: [],
    userModules: [],
    userDetails: {},
    loginInfo: {},
    error: null,
    messages: {},
    session: false,
    fullscreenGlobalOpen: false, // global redux state to tell header component that fullscreen open. or closed for accessability
    headerMenu: {},
    appLoading: false,
    ecmVersion: null,
    editLanguage: null,
    gen2HomePage: '0',
    requestgen2config: '1',
    pamGen2: null,
    certsGen2: null,
    homepage: {},
    customerInfo: {
      customerName: null, 
      environment: null
    },
    pendoInfo: {
      apiKey: null
    }

  },
  action
) => {
  switch (action.type) {
    case loginRequestActionType: {
      return { ...state, userData: [], session: false };
    }

    case loginSuccessActionType: {
      return { ...state, userData: action.payload, session: false };
    }

    case loginFailedActionType: {
      return {
        ...state,
        error: 'LoginError',
        session: false,
      };
    }
    case loginInfoSuccessActionType: {
      return {
        ...state,
        loginInfo: action.payload,
      };
    }

    case userInfoActionType: {
      return { ...state, userInfo: action.payload, session: false };
    }
    case APP_CONSTANTS_SUCCESS: {
      return { ...state, domainInfo: action.payload };
    }
    case APP_MESSAGES_SUCCESS: {
      return { ...state, messages: action.payload };
    }
    case userModulesSuccessActionType: {
      const urlList = [];
      const featureNames = [];
      const blockedNames = [];
      const blockedUrls = [];
      let blockedUrlList = [];
      let featureNameList = [];
      const { BLOCKED_URLS } = state.domainInfo || {};
      if (BLOCKED_URLS) {
        if (action.payload) {
          if (action.payload.mappedFeatures) {
            _.forEach(action.payload.mappedFeatures, (val) => {
              if (val.url) urlList.push(val.url);
              if (val.name) featureNames.push(val.name);
            });
          }
          if (action.payload.blockedFeatures) {
            _.forEach(action.payload.blockedFeatures, (val) => {
              if (val.url) blockedUrls.push(val.url);
              if (val.name) blockedNames.push(val.name);
            });
          }
        }
        featureNameList = [...new Set(featureNames)];
        blockedUrlList = [...new Set(blockedUrls)];
        return { ...state, userModules: urlList, featureNameList, blockedUrlList, blockedNames };
      } else {
        if (action.payload) {
          _.forEach(action.payload, (val) => {
            if (val.url) urlList.push(val.url);
            if (val.name) featureNames.push(val.name);
          });
        }
        featureNameList = [...new Set(featureNames)];
        return { ...state, userModules: urlList, featureNameList };
      }
    }

    case sessiontoast: {
      return { ...state, session: action.payload };
    }

    case logoutActionType:
      return {
        ...state,
        userData: [],
        userInfo: [],
        userModules: [],
        userDetails: {},
        loginInfo: {},
        error: null,
        session: false,
        fullscreenGlobalOpen: false,
      };

    case GET_HEADER_MENU_SUCCESS: {
      action.payload.ecmDateFormat =
        action.payload && action.payload.ecmDateFormat
          ? action.payload.ecmDateFormat.replace('DD', 'dd').replace('YYYY', 'yyyy')
          : 'MM-dd-yyyy';
      return {
        ...state,
        headerMenu: action.payload,
      };
    }

    case FULL_SCREEN_GLOBAL_OPEN: {
      return {
        ...state,
        fullscreenGlobalOpen: action.payload,
      };
    }

    case GET_INITIAL_DATA_ACTION: {
      return {
        ...state,
        appLoading: true,
      };
    }

    case GET_INITIAL_DATA_SUCCESS_ACTION: {
      return {
        ...state,
        appLoading: false,
      };
    }

    case GET_LEFTNAV_MODERN_EXP_GEN2_SUCCESS_ACTION:
      return {
        ...state,
        leftNavGen2: action.payload.configData,
      };

    case FETCH_ECM_VERSION_SUCCESS:
      return {
        ...state,
        ecmVersion: action.version,
      };

    case UPDATE_EMAIL_LANGUAGE:
      return {
        ...state,
        editLanguage: LANGUAGE_CHANGE_STATE.INPROGRESS,
      };

    case UPDATE_LANGUAGE_STATE:
      return {
        ...state,
        editLanguage: action.result,
      };

    case GET_HOMEPAGE_MODERN_EXP_GEN2_SUCCESS_ACTION:
      return {
        ...state,
        gen2HomePage: action.payload.configData,
      };
    case EXPAND_NAVIGATION_LOGIN_SUCCESS_ACTION:
      return {
        ...state,
        ...action.payload,
      };
    case GET_SIDEMENU_REQUEST_CONFIG_SUCCESS_ACTION:
      return {
        ...state,
        requestgen2config: action.payload.configData,
      };

    case GET_PAM_GEN2_SUCCESS_ACTION:
      return {
        ...state,
        pamGen2: action.payload.configData,
      };
    case GET_CERTS_GEN2_SUCCESS_ACTION:
      return {
        ...state,
        certsGen2: action?.payload?.configData,
      };
    case GET_HOMEPAGE_QUICKLINKS_SUCCESS_ACTION:
      return {
        ...state,
        homepage: {
          ...state.homepage,
          quicklinks: action?.payload,
        },
      };
    case GET_HOMEPAGE_KPIS_SUCCESS_ACTION:
      return {
        ...state,
        homepage: {
          ...state.homepage,
          kpis: action?.payload,
        },
      };
    case PAGE_RELOAD:
      action.callback();
      return state;

    default: {
      return state;
    }

    case SET_CUSTOMER_INFO:
      return {
        ...state,
        customerInfo: action.payload
      }

    case SET_PENDO_INFO:
      return {
        ...state,
        pendoInfo: action.payload
      }
  }

};

export default loginReducer;
