import React from 'react';
import { Loader, Typography } from '@saviynt/design-system';
import classNames from 'classnames';

import { type MenuId, RequestStatus } from '../../../models';
import { Link, useLocalize } from '../../../utilities';

import { kpiLabels } from './messages';

import './KpiTile.css';

interface Props {
  id: MenuId;
  link?: string;
  status?: RequestStatus;
  metric?: number | 'error';
  className?: string;
}

KpiTile.defaultProps = {
  link: '',
  className: undefined,
  status: undefined,
  metric: undefined,
};

export default function KpiTile({
  id,
  link = '',
  status,
  metric,
  className,
}: Props): JSX.Element | null {
  const localize = useLocalize();
  const localizeId = `Nav.KpisWidget.Kpi-${id}`;
  const label = localize(
    {
      id: localizeId,
      defaultMessage: kpiLabels[localizeId],
    },
    { count: metric, hide: () => '' }
  );

  if (metric === 'error') {
    // See commit 08624295f6 for implementation with error message tile.
    return null;
  }

  if (status !== RequestStatus.Idle || metric === undefined) {
    const loadingClassName = classNames(
      'Nav_KpiTile',
      'Nav_KpiTile--loading',
      className
    );

    return (
      <Link href={link} className={loadingClassName} title={link}>
        <Loader kind='dots' format='inline' color='brand' size='small' />
      </Link>
    );
  }

  const metricClassName = classNames('Nav_KpiTile', className);

  return (
    <Link href={link} className={metricClassName} title={link}>
      <div className='Nav_KpiTile-main'>
        <div className='Nav_KpiTile-metric'>{metric}</div>
        <Typography kind='h3' className='Nav_KpiTile-label'>
          {label}
        </Typography>
      </div>
    </Link>
  );
}
