import React, { useEffect, useState } from 'react';
import {
  camelCaseToTitleCase,
  formatLocalizedDateTime,
  useLocalize,
} from '@saviynt/common';
import {
  Box,
  Button,
  Icon,
  InputField,
  TabContent,
  TabGroup,
  TabItem,
  Tooltip,
  Typography,
  useTooltipText,
} from '@saviynt/design-system';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import {
  Entitlement,
  ModuleDetails,
  RoleDetails,
  Session,
  SessionDetails,
} from '../../../../../../models/PamModels';
import { msgs } from '../../../../constants';
import SpvEndpoint from '../../../Endpoints/SpvEndpoint/SpvEndpoint';
import SpvEntitlements from '../../../Roles/SpvEntitlements/SpvEntitlements';
import SpvRole from '../../../Roles/SpvRole/SpvRole';
import SpvSection from '../../../SpvSection/SpvSection';

import './SpvActiveSession.css';

const CREDENTIALED_KEYS = {
  PASSWORD: 'password',
  PRIVATE_KEY: 'privateKey',
  SECRET: 'secret',
};

/**
 * To render copy button with tooltip functionality
 * When user clicks on copy button
 * value gets copied button colour will change to green
 * after 5 second, It will come to default state
 */

function CopyButtonWithTooltip({
  handleCopyOnClick,
  isCopyButtonClicked,
  copyButtonKey,
  copyButtonValue,
}) {
  const {
    tooltipText,
    isDisabled,
    handleTooltipTriggerClick,
    handleTooltipClose,
    tooltipCloseDelay,
    tooltipCloseDelayAfterClick,
  } = useTooltipText({
    initialText: 'Copy',
    updatedText: 'Copied',
    closeDelay: 50,
    closeDelayAfterClick: 5000,
  });
  const isCopyButtonClickedWithDisable = !isDisabled && isCopyButtonClicked;

  const handleOnClick = async () => {
    await handleCopyOnClick(copyButtonKey, copyButtonValue);
    handleTooltipTriggerClick();
  };

  return (
    tooltipText && (
      <Tooltip
        text={tooltipText}
        placement='top'
        closeDelay={tooltipCloseDelay}
        closeDelayAfterClick={tooltipCloseDelayAfterClick}
        onClose={handleTooltipClose}
        isDisabled={isCopyButtonClickedWithDisable}
        trigger={
          <Button
            type='button'
            kind='outlined'
            size='large'
            isSuccess={isCopyButtonClickedWithDisable}
            leftIcon={
              <Icon
                kind={isCopyButtonClickedWithDisable ? 'check' : 'Copy'}
                size='medium'
                color='neutral-100'
              />
            }
            onClick={() => handleOnClick()}
            dataTestId={`copy-button-${copyButtonKey}`}
          />
        }
      />
    )
  );
}

function SpvActiveSession({
  userAccessData,
  accountDetails,
  endpointDetails,
  session,
  sessionDetails,
  roleDetails,
  entitlements,
  dataTestId,
  className,
}) {
  const classes = classnames('SpvActiveSession', className);

  const [activeTab, setActiveTab] = useState('1');
  const [showPassword, setShowPassword] = useState(false);
  const [showPrivateKey, setShowPrivateKey] = useState(false);
  const [showSecret, setShowSecret] = useState(false);
  const [clickedCopyButton, setClickedCopyButton] = useState('');

  const isCredentialless = session?.pamType?.toUpperCase() === 'CREDENTIALLESS';

  const localize = useLocalize();

  const handleChange = (value) => {
    setActiveTab(value);
  };

  const hasSessionTimeFields =
    session?.sessionStartDate || session?.sessionEndDate;

  // TODO: update to not use mock tcodes when applicable
  const tCodes = session?.codes?.split(',');
  const sapTransactionCodesHasFields = tCodes?.length > 0;

  const hasTargetSystemFields =
    endpointDetails?.customproperty9 || endpointDetails?.customproperty35;

  const remoteAppName = userAccessData?.remoteAppMetadata?.displayName;

  const showRemoteAppName =
    isCredentialless && remoteAppName && remoteAppName !== 'Terminal';

  const hasSessionAccountFields =
    session?.accountName ||
    accountDetails?.description ||
    userAccessData?.accountDescription ||
    accountDetails?.platformType ||
    accountDetails?.pamAccountType ||
    accountDetails?.accountConfig?.authenticationType;

  const hasRole = userAccessData?.roleKey && roleDetails;
  const hasEntitlements = userAccessData?.roleKey && entitlements?.length > 0;

  const credentiallessSectionHasDivider =
    hasSessionTimeFields || sapTransactionCodesHasFields;
  const remoteAppSectionHasDivider =
    credentiallessSectionHasDivider || hasTargetSystemFields;
  const accountSectionHasDivider =
    remoteAppSectionHasDivider || showRemoteAppName;

  const handleCopyOnClick = async (key, value) => {
    setClickedCopyButton(key);

    await navigator.clipboard.writeText(value);
  };

  const handleMaskedFields = (key) => {
    switch (key) {
      case CREDENTIALED_KEYS.PASSWORD:
        setShowPassword(!showPassword);
        break;
      case CREDENTIALED_KEYS.PRIVATE_KEY:
        setShowPrivateKey(!showPrivateKey);
        break;
      case CREDENTIALED_KEYS.SECRET:
        setShowSecret(!showSecret);
        break;
      default:
        break;
    }
  };

  const showToggleState = (key) => {
    switch (key) {
      case CREDENTIALED_KEYS.PASSWORD:
        return showPassword;
      case CREDENTIALED_KEYS.PRIVATE_KEY:
        return showPrivateKey;
      case CREDENTIALED_KEYS.SECRET:
        return showSecret;
      default:
        break;
    }

    return null;
  };

  const isMaskedField = (key, isTypePassword) => {
    const isPassword = key === CREDENTIALED_KEYS.PASSWORD && !showPassword;

    const isPrivateKey =
      key === CREDENTIALED_KEYS.PRIVATE_KEY && !showPrivateKey;

    const isSecret = key === CREDENTIALED_KEYS.SECRET && !showSecret;

    return (
      (isTypePassword && isPassword) ||
      (isTypePassword && isPrivateKey) ||
      (isTypePassword && isSecret)
    );
  };

  useEffect(() => {
    let passwordTimeout;

    if (showPassword) {
      passwordTimeout = setTimeout(() => {
        setShowPassword(false);
      }, 5000);
    }

    return () => {
      clearTimeout(passwordTimeout);
    };
  }, [showPassword]);

  useEffect(() => {
    let privateKeyTimeout;

    if (showPrivateKey) {
      privateKeyTimeout = setTimeout(() => {
        setShowPrivateKey(false);
      }, 5000);
    }

    return () => {
      clearTimeout(privateKeyTimeout);
    };
  }, [showPrivateKey]);

  useEffect(() => {
    let secretTimeout;

    if (showSecret) {
      secretTimeout = setTimeout(() => {
        setShowSecret(false);
      }, 5000);
    }

    return () => {
      clearTimeout(secretTimeout);
    };
  }, [showSecret]);

  return (
    <section className={classes} data-testid={dataTestId}>
      <TabGroup
        className='SpvActiveSession-tabGroup'
        value={activeTab}
        onChange={handleChange}>
        <TabItem
          label={localize(msgs.pam.sidePanel.session)}
          value='1'
          icon={
            <Icon kind='PrivilegedSession' size='medium' color='neutral-1000' />
          }
        />
        {!userAccessData?.roleKey && (
          <TabItem
            label={localize(msgs.pam.sidePanel.asset)}
            value='2'
            icon={
              <Icon
                kind='AssetTargetSystem'
                size='medium'
                color='neutral-1000'
              />
            }
          />
        )}
        {hasRole && (
          <TabItem
            label={localize(msgs.pam.sidePanel.role)}
            value='3'
            icon={<Icon kind='Role' size='medium' color='neutral-1000' />}
          />
        )}
        {hasEntitlements && (
          <TabItem
            label={localize(msgs.pam.sidePanel.entitlements)}
            value='4'
            icon={
              <Icon kind='Entitlement' size='medium' color='neutral-1000' />
            }
          />
        )}
      </TabGroup>
      <TabContent
        className='SpvActiveSession-tabContent'
        value={activeTab}
        tabValue='1'>
        {sessionDetails && (
          <SpvSection className='SpvActiveSession-credentials'>
            {Object.entries(sessionDetails).map(([key, valueObject]) => {
              const translationKey = `credentialledField${key}`;
              const isCopyButtonClicked = clickedCopyButton === key;

              msgs[translationKey] = {
                id: `consoleCredential.${key}`,
                defaultMessage: camelCaseToTitleCase(key),
              };

              const field = localize(msgs[translationKey]);
              const { value } = valueObject;
              const { effect } = valueObject;

              const isTypePassword =
                effect === 'PASSWORD' || effect === 'TEXTAREAPASSWORD';

              return (
                <section className='SpvActiveSession-credential'>
                  <Box className='SpvActiveSession-credential-field'>
                    <InputField
                      label={
                        <Typography
                          kind='h4'
                          className='SpvActiveSession-credential-label'>
                          {field}
                        </Typography>
                      }
                      type={
                        isMaskedField(key, isTypePassword) ? 'password' : 'text'
                      }
                      placeholder={field}
                      name={field.toLowerCase()}
                      suffixButtonType={isTypePassword ? 'password' : null}
                      value={value}
                      suffixOnClick={() => {
                        handleMaskedFields(key);
                      }}
                      suffixIsToggle={showToggleState(key)}
                      setValue={() => {}}
                      isReadOnly
                    />
                  </Box>
                  <CopyButtonWithTooltip
                    handleCopyOnClick={handleCopyOnClick}
                    isCopyButtonClicked={isCopyButtonClicked}
                    copyButtonKey={key}
                    copyButtonValue={value}
                  />
                </section>
              );
            })}
          </SpvSection>
        )}
        {hasSessionTimeFields && (
          <SpvSection
            className={sessionDetails && 'SpvActiveSession-tabSection'}
            title={localize(msgs.pam.sidePanel.time)}
            fields={[
              {
                label: localize(msgs.pam.sidePanel.startTime),
                value: formatLocalizedDateTime(session?.sessionStartDate),
              },
              {
                label: localize(msgs.pam.sidePanel.endTime),
                value: formatLocalizedDateTime(session?.sessionEndDate),
              },
            ]}
          />
        )}
        {sapTransactionCodesHasFields && (
          <SpvSection
            className={hasSessionTimeFields && 'SpvActiveSession-tabSection'}
            title={localize(msgs.pam.sidePanel.sapTransactionCodes)}
            fields={[
              {
                label: localize(msgs.pam.sidePanel.tCodesSelected),
                value: tCodes.join(', '),
              },
            ]}
          />
        )}
        {isCredentialless && hasTargetSystemFields && (
          <SpvSection
            className={
              credentiallessSectionHasDivider && 'SpvActiveSession-tabSection'
            }
            title={localize(msgs.pam.sidePanel.targetSystem)}
            fields={[
              {
                label: localize(msgs.pam.sidePanel.targetServer),
                value: endpointDetails?.customproperty9,
              },
              {
                label: localize(msgs.pam.sidePanel.portNumber),
                value: endpointDetails?.customproperty35,
              },
            ]}
          />
        )}
        {showRemoteAppName && (
          <SpvSection
            className={
              remoteAppSectionHasDivider && 'SpvActiveSession-tabSection'
            }
            title={localize(msgs.pam.sidePanel.remoteApplication)}
            fields={[
              {
                label: localize(msgs.pam.sidePanel.name),
                value: remoteAppName,
              },
            ]}
          />
        )}
        {hasSessionAccountFields && !hasRole && (
          <SpvSection
            className={
              accountSectionHasDivider && 'SpvActiveSession-tabSection'
            }
            title={localize(msgs.pam.sidePanel.account)}
            fields={[
              {
                label: localize(msgs.pam.sidePanel.name),
                value: session?.accountName,
              },
              {
                label: localize(msgs.pam.sidePanel.platform),
                value: accountDetails?.platformType,
              },
              {
                label: localize(msgs.pam.sidePanel.description),
                value:
                  accountDetails?.description ||
                  userAccessData?.accountDescription,
              },
              {
                label: localize(msgs.pam.sidePanel.accountType),
                value: accountDetails?.pamAccountType,
              },
              {
                label: localize(msgs.pam.sidePanel.authenticationMechanism),
                value: accountDetails?.accountConfig?.authenticationType,
              },
            ]}
          />
        )}
      </TabContent>
      {!userAccessData?.roleKey && (
        <TabContent
          className='SpvActiveSession-assetTab'
          value={activeTab}
          tabValue='2'>
          <SpvEndpoint data={endpointDetails} />
        </TabContent>
      )}
      {hasRole && (
        <TabContent
          className='SpvActiveSession-tabContent'
          value={activeTab}
          tabValue='3'>
          <SpvRole data={userAccessData} roleDetails={roleDetails} />
        </TabContent>
      )}
      {hasEntitlements && (
        <TabContent
          className='SpvActiveSession-tabContent'
          value={activeTab}
          tabValue='4'>
          <SpvEntitlements
            data={userAccessData}
            initialEntitlements={entitlements}
          />
        </TabContent>
      )}
    </section>
  );
}

SpvActiveSession.propTypes = {
  userAccessData: ModuleDetails,
  accountDetails: ModuleDetails,
  endpointDetails: ModuleDetails,
  session: Session,
  sessionDetails: SessionDetails,
  roleDetails: RoleDetails,
  entitlements: PropTypes.arrayOf(Entitlement),
  dataTestId: PropTypes.string,
  className: PropTypes.string,
};

SpvActiveSession.defaultProps = {
  userAccessData: null,
  accountDetails: null,
  endpointDetails: null,
  session: null,
  sessionDetails: null,
  roleDetails: null,
  entitlements: null,
  dataTestId: null,
  className: null,
};

CopyButtonWithTooltip.propTypes = {
  handleCopyOnClick: PropTypes.func,
  isCopyButtonClicked: PropTypes.bool,
  copyButtonKey: PropTypes.string,
  copyButtonValue: PropTypes.string,
};

CopyButtonWithTooltip.defaultProps = {
  handleCopyOnClick: null,
  isCopyButtonClicked: null,
  copyButtonKey: null,
  copyButtonValue: null,
};

export default SpvActiveSession;
