import { useEffect, useState } from 'react';

const LandingPagePaginationService = (filteredEntities) => {
  const [pageCount, setPageCount] = useState(1);
  const [pageIndex, setPageIndex] = useState(1);
  // Change this to what ever the API request length desired
  const [pagRange, setPagRange] = useState(500);
  const [resArrayLength, setResArrayLength] = useState(1);
  const [paginatedEntities, setPaginatedEntities] = useState(null);

  const startItem = (pageIndex - 1) * pagRange;
  const endItem = pageIndex * pagRange;

  useEffect(() => {
    setPageCount(Math.ceil(resArrayLength / pagRange));
  }, [pagRange, resArrayLength, filteredEntities]);

  useEffect(() => {
    setResArrayLength(filteredEntities?.result?.userAccesses?.length);
    setPaginatedEntities(
      filteredEntities?.result?.userAccesses?.slice(startItem, endItem)
    );
    // Return to page 1 when a filter is applied
    setPageIndex(1);
  }, [filteredEntities]);

  useEffect(() => {
    setPaginatedEntities(
      filteredEntities?.result?.userAccesses?.slice(startItem, endItem)
    );
  }, [pageIndex, pagRange]);

  useEffect(() => {
    setPageIndex(1);
  }, [pagRange]);

  return {
    pageCount,
    pageIndex,
    setPageIndex,
    pagRange,
    setPagRange,
    resArrayLength,
    paginatedEntities,
  };
};

export default LandingPagePaginationService;
