import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import MenuItem from '../MenuItem/MenuItem';

export const DownloadIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.0002 3C12.2763 3 12.5002 3.22386 12.5002 3.5L12.5002 16.3784L16.8243 12.0524C17.0195 11.8571 17.3361 11.8571 17.5314 12.0523C17.7267 12.2475 17.7268 12.5641 17.5316 12.7594L12.354 17.9392C12.2602 18.033 12.133 18.0857 12.0003 18.0857C11.9557 18.0857 11.9117 18.0797 11.8694 18.0682C11.8227 18.0556 11.7787 18.0364 11.7385 18.0116C11.7054 17.9913 11.6746 17.967 11.6467 17.9392L6.46897 12.7594C6.27375 12.5641 6.27381 12.2475 6.46911 12.0523C6.66441 11.8571 6.981 11.8571 7.17622 12.0524L11.5002 16.3781L11.5002 3.5C11.5002 3.22386 11.7241 3 12.0002 3Z"
      fill="#032185"
    />
    <path
      d="M4.06107 20C3.78492 20 3.56107 20.2239 3.56107 20.5C3.56107 20.7761 3.78492 21 4.06107 21H19.9392C20.2154 21 20.4392 20.7761 20.4392 20.5C20.4392 20.2239 20.2154 20 19.9392 20H4.06107Z"
      fill="#032185"
    />
  </svg>
);

const MyDownloads = ({ intl, menuDetails }) => {
  const onClick = () => {
    window.open(menuDetails.link, '_self');
  };

  return (
    <MenuItem
      label={intl.formatMessage({
        id: `gen2.profileMenu.label.${menuDetails.id}`,
      })}
      icon={<DownloadIcon />}
      handleClick={onClick}
    />
  );
};

MyDownloads.propTypes = {
  menuDetails: PropTypes.shape({
    id: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }).isRequired,
  intl: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string.isRequired,
      formatMessage: PropTypes.func.isRequired,
    }),
  ).isRequired,
};

export default injectIntl(MyDownloads);
