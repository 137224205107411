import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import MenuItem from '../MenuItem/MenuItem';
import Modal from '../../Modal/Modal';
import ActivityDetails from './ActivityDetails';

export const RecentActivityIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="request-history">
      <g id="Vector">
        <path
          d="M4.44963 3.00024C3.80529 3.00024 3.28296 3.52258 3.28296 4.16691V17.5067C3.28296 19.2556 4.70072 20.6733 6.44962 20.6733H8.77774C9.05388 20.6733 9.27774 20.4495 9.27774 20.1733C9.27774 19.8972 9.05388 19.6733 8.77774 19.6733H6.44962C5.25301 19.6733 4.28296 18.7033 4.28296 17.5067V4.16691C4.28296 4.07486 4.35758 4.00024 4.44963 4.00024H15.0937C16.2903 4.00024 17.2603 4.97029 17.2603 6.16691V7.99419C17.2603 8.27033 17.4842 8.49419 17.7603 8.49419C18.0365 8.49419 18.2603 8.27033 18.2603 7.99419V6.16691C18.2603 4.41801 16.8426 3.00024 15.0937 3.00024H4.44963Z"
          fill="#032185"
        />
        <path
          d="M6.24797 6.61841C6.24797 6.34227 6.47182 6.11841 6.74797 6.11841H14.5941C14.8703 6.11841 15.0941 6.34227 15.0941 6.61841C15.0941 6.89455 14.8703 7.11841 14.5941 7.11841H6.74797C6.47182 7.11841 6.24797 6.89455 6.24797 6.61841Z"
          fill="#032185"
        />
        <path
          d="M6.74797 9.0979C6.47182 9.0979 6.24797 9.32176 6.24797 9.5979C6.24797 9.87404 6.47182 10.0979 6.74797 10.0979H10.0826C10.3587 10.0979 10.5826 9.87404 10.5826 9.5979C10.5826 9.32176 10.3587 9.0979 10.0826 9.0979H6.74797Z"
          fill="#032185"
        />
        <path
          d="M6.74797 12.0771C6.47182 12.0771 6.24797 12.3009 6.24797 12.5771C6.24797 12.8532 6.47182 13.0771 6.74797 13.0771H7.69521C7.97135 13.0771 8.19521 12.8532 8.19521 12.5771C8.19521 12.3009 7.97135 12.0771 7.69521 12.0771H6.74797Z"
          fill="#032185"
        />
        <path
          d="M15.65 12.2431C15.65 11.9669 15.4261 11.7431 15.15 11.7431C14.8738 11.7431 14.65 11.9669 14.65 12.2431V15.7537C14.65 15.8863 14.7027 16.0135 14.7964 16.1072L16.2587 17.5696C16.454 17.7648 16.7706 17.7648 16.9658 17.5696C17.1611 17.3743 17.1611 17.0577 16.9658 16.8624L15.65 15.5466V12.2431Z"
          fill="#032185"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.5826 15.4328C9.5826 12.358 12.0752 9.86548 15.1499 9.86548C18.2246 9.86548 20.7172 12.358 20.7172 15.4328C20.7172 18.5075 18.2246 21.0001 15.1499 21.0001C12.0752 21.0001 9.5826 18.5075 9.5826 15.4328ZM15.1499 10.8655C12.6275 10.8655 10.5826 12.9103 10.5826 15.4328C10.5826 17.9552 12.6275 20.0001 15.1499 20.0001C17.6724 20.0001 19.7172 17.9552 19.7172 15.4328C19.7172 12.9103 17.6724 10.8655 15.1499 10.8655Z"
          fill="#032185"
        />
      </g>
    </g>
  </svg>
);

const RecentActivity = ({ intl }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <MenuItem
        label={intl.formatMessage({ id: 'gen2.profileMenu.label.recentActivity' })}
        icon={<RecentActivityIcon />}
        handleClick={handleClickOpen}
      />
      <Modal open={open} handleClose={handleClickClose}>
        <ActivityDetails onClose={handleClickClose} />
      </Modal>
    </>
  );
};

RecentActivity.propTypes = {
  intl: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string.isRequired,
      formatMessage: PropTypes.func.isRequired,
    }),
  ).isRequired,
};

export default injectIntl(RecentActivity);
