import { combineReducers } from 'redux';
import moment from 'moment';
import {
  REQUEST_ACTIONS_LIST,
  REQUEST_ACTIONS_LIST_SUCCESS,
  REQUEST_ACTIONS_LIST_FAILURE,
  REQUEST_FEATURES_LIST,
  REQUEST_FEATURES_LIST_SUCCESS,
  REQUEST_TEAM_USER_LIST_SUCCESS,
  REQUEST_RECENT_USER_LIST,
  REQUEST_RECENT_USER_LIST_SUCCESS,
  REQUEST_RECENT_USER_LIST_FAILURE,
  REQUEST_FEATURES_LIST_FAILURE,
  REQUEST_IMPORTANT_ACTIONS_LIST,
  REQUEST_IMPORTANT_ACTIONS_LIST_SUCCESS,
  REQUEST_IMPORTANT_ACTIONS_LIST_FAILURE,
  ADD_KPI_DATA,
  REQUESTHOME_PENDING_ACTIONS_SUCCESS,
  REQUESTHOME_PENDING_ACTIONS,
  RESET_REFRESH_KPI_FLAG,
  FETCH_WELCOME_TILES,
  FETCH_WELCOME_TILES_SUCCESS,
  FETCH_WELCOME_TILES_FAILURE,
  FETCH_RECENT_REQUEST,
  FETCH_RECENT_REQUEST_SUCCESS,
  FETCH_RECENT_REQUEST_FAILURE,
  FETCH_HELP_REQUEST_SUCCESS,
  FETCH_HELP_REQUEST_FAILURE,
  FETCH_BANNER_REQUEST_SUCCESS,
  FETCH_BANNER_REQUEST_FAILURE,
  HIDE_ALERT_BANNER,
  FETCH_APP_RECOMMENDATIONS,
  FETCH_APP_RECOMMENDATIONS_SUCCESS,
  FETCH_APP_RECOMMENDATIONS_FAILURE,
  FETCH_REQUEST_ACCESS_DETAILS_SUCCESS,
  FETCH_REQUEST_ACCESS_DETAILS_FAILURE,
  FETCH_DEFAULT_WIDGET_SUCCESS,
  FETCH_DEFAULT_WIDGET_FAILURE,
} from './requestHome-actions';
import {
  REFRESH_HOME_KPI,
  FETCH_FEATURE_NAME_LIST,
  FETCH_FEATURE_NAME_LIST_SUCCESS,
  FETCH_FEATURE_NAME_LIST_FAILURE,
} from './kpiForm-actions';

const initialState = {
  actionsitems: {},
  featureitems: [],
  activityItems: [],
  noRecords: false,
  recentloading: false,
  loading: false,
  loader: false,
  loadingImpActions: false,
  kpiData: {},
  pendingActions: {},
  pendingActionsLoading: false,
  userList: [],
  recent: '',
  refreshKPI: false,
  featureNamesList: [],
  welcomeTiles: [],
  recentRequests: [],
  helpDetails: {
    isHelpEnabled: false,
    isDocEnabled: false,
  },
  bannerDetails: {
    isBannerEnabled: false,
  },
  appRecommendations: [],
  requestAccessDetails: [],
  hasRequestAccess: false,
  defaultWidget: {},
};

export const requestHomeReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_ACTIONS_LIST:
      return {
        ...state,
        activityItems: [],
        loading: true,
        noRecords: false,
      };
    case REQUEST_ACTIONS_LIST_SUCCESS:
      const resp = action.response;
      let noRecords = false;
      if (resp.userActivities && resp.userActivities.length > 0) {
        resp.userActivities.forEach((item) => {
          if (item.activities.length === 0) {
            noRecords = true;
          }
        });
      } else {
        noRecords = true;
      }
      return {
        ...state,
        loading: false,
        activityItems: action.response.userActivities,
        noRecords: action.response.noRecords || noRecords,
      };
    case REQUEST_ACTIONS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        noRecords: true,
      };
    case REQUEST_TEAM_USER_LIST_SUCCESS: {
      if (action.searchText) action.response.count = state.userList.count;
      return {
        ...state,
        userList: action.response,
      };
    }
    case REQUEST_RECENT_USER_LIST:
      return {
        ...state,
        recentloading: true,
        recent: '',
      };

    case REQUEST_RECENT_USER_LIST_SUCCESS:
      return {
        ...state,
        recentloading: false,
        recent: action.response,
      };
    case REQUEST_RECENT_USER_LIST_FAILURE:
      return {
        ...state,
        recentloading: false,
      };
    case REQUEST_FEATURES_LIST:
      return {
        ...state,
        featureitems: [],
        loader: true,
      };
    case REQUEST_FEATURES_LIST_SUCCESS:
      return {
        ...state,
        loader: false,
        featureitems: action.response.features,
      };
    case REQUEST_FEATURES_LIST_FAILURE:
      return {
        ...state,
        featureitems: [],
      };
    case REQUEST_IMPORTANT_ACTIONS_LIST:
      return {
        ...state,
        actionsitems: {},
        activityItems: [],
        loadingImpActions: true,
      };
    case REQUEST_IMPORTANT_ACTIONS_LIST_SUCCESS:
      return {
        ...state,
        loadingImpActions: false,
        actionsitems: action.response,
      };
    case REQUEST_IMPORTANT_ACTIONS_LIST_FAILURE:
      return {
        ...state,
        loadingImpActions: false,
        actionsitems: action.response,
      };
    case ADD_KPI_DATA:
      return {
        ...state,
        kpiData: {
          ...state.kpiData,
          [action.payload.key]: action.payload.data,
        },
      };
    case REQUESTHOME_PENDING_ACTIONS:
      return {
        ...state,
        pendingActionsLoading: true,
      };
    case REQUESTHOME_PENDING_ACTIONS_SUCCESS:
      const today = [];
      const lastWeek = [];
      const others = [];
      let finalJson = [];
      const count =
        action.requestType === 'Request'
          ? action.responseJson.count
          : action.responseJson.totalCount;
      if (action.requestType === 'Request') finalJson = action.responseJson.requests;
      else finalJson = action.responseJson.certifications;
      finalJson.forEach((element) => {
        if (
          (element.requestDate &&
            moment().isSame(moment(moment.utc(element.requestDate)).local(), 'day')) ||
          (element.startdate &&
            moment().isSame(moment(moment.utc(element.startdate)).local(), 'day'))
        ) {
          today.push(element);
        } else if (
          (element.requestDate &&
            moment(moment.utc(element.requestDate))
              .local()
              .isAfter(moment().set({ hour: 0, minute: 0 }).add(-7, 'day'))) ||
          (element.startdate &&
            moment(moment.utc(element.startdate))
              .local()
              .isAfter(moment().set({ hour: 0, minute: 0 }).add(-7, 'day')))
        ) {
          lastWeek.push(element);
        } else others.push(element);
      });

      return {
        ...state,
        pendingActions: {
          today,
          lastWeek,
          others,
          count,
        },
        pendingActionsLoading: false,
      };
    case REFRESH_HOME_KPI:
      return {
        ...state,
        refreshKPI: true,
      };
    case RESET_REFRESH_KPI_FLAG:
      return {
        ...state,
        refreshKPI: false,
      };

    case FETCH_FEATURE_NAME_LIST:
      return {
        ...state,
        featureNamesList: [],
        loader: true,
      };
    case FETCH_FEATURE_NAME_LIST_SUCCESS:
      return {
        ...state,
        loader: false,
        featureNamesList: action.response,
      };
    case FETCH_FEATURE_NAME_LIST_FAILURE:
      return {
        ...state,
        featureNamesList: [],
      };
    case FETCH_WELCOME_TILES:
      return {
        ...state,
        welcomeTiles: [],
        loader: true,
      };
    case FETCH_WELCOME_TILES_SUCCESS:
      return {
        ...state,
        loader: false,
        welcomeTiles: action.response,
      };
    case FETCH_WELCOME_TILES_FAILURE:
      return {
        ...state,
        welcomeTiles: [],
        welcomeTilesFetchError: true,
      };
    case FETCH_RECENT_REQUEST:
      return {
        ...state,
        recentRequests: [],
        loader: true,
      };
    case FETCH_RECENT_REQUEST_SUCCESS:
      return {
        ...state,
        loader: false,
        recentRequests: action.response,
      };
    case FETCH_RECENT_REQUEST_FAILURE:
      return {
        ...state,
        recentRequests: [],
        recentRequestsFetchError: true,
      };
    case FETCH_HELP_REQUEST_SUCCESS:
      return {
        ...state,
        loader: false,
        helpDetails: action.response,
      };
    case FETCH_HELP_REQUEST_FAILURE:
      return {
        ...state,
        helpDetails: {
          isHelpEnabled: false,
          isDocEnabled: false,
          isHelpFetchError: true,
        },
      };
    case FETCH_BANNER_REQUEST_SUCCESS:
      return {
        ...state,
        loader: false,
        bannerDetails: action.response,
      };
    case FETCH_BANNER_REQUEST_FAILURE:
      return {
        ...state,
        bannerDetails: { isBannerEnabled: false },
      };
    case HIDE_ALERT_BANNER:
      return {
        ...state,
        bannerDetails: { isBannerEnabled: false },
      };
    case FETCH_APP_RECOMMENDATIONS:
      return {
        ...state,
        appRecommendations: [],
      };
    case FETCH_APP_RECOMMENDATIONS_SUCCESS:
      return {
        ...state,
        loader: false,
        appRecommendations: action.response,
      };
    case FETCH_APP_RECOMMENDATIONS_FAILURE:
      return {
        ...state,
        appRecommendations: [],
      };
    case FETCH_REQUEST_ACCESS_DETAILS_SUCCESS:
      return {
        ...state,
        requestAccessDetails: action.response,
      };
    case FETCH_REQUEST_ACCESS_DETAILS_FAILURE:
      return {
        ...state,
        requestAccessDetails: [],
        hasRequestAccess: true,
      };
    case FETCH_DEFAULT_WIDGET_SUCCESS:
      return {
        ...state,
        defaultWidget: action.response,
      };
    case FETCH_DEFAULT_WIDGET_FAILURE:
      return {
        ...state,
        defaultWidget: {},
      };
    default: {
      return state;
    }
  }
};
const rootReducer = combineReducers({
  initialLoad: requestHomeReducer,
});
export default rootReducer;
