import React, { useState, useEffect } from 'react';
import moment from 'moment';
// eslint-disable-next-line import/extensions
import PropTypes from 'prop-types';
import { A } from '../../A';
import styles from '../GridBoot.module.scss';
import { DateFilterWidget } from './DateFilterWidget';
import { injectIntl } from 'react-intl';

const DateFilter = ({
  column,
  showColumnFilter,
  setShowColumnFilter,
  appliedFilters = {},
  setAppliedFilters,
  setAntdComponentOpen,
  setSortedColumn,
  sortedColumn,
  intl,
  maxAllowedDate,
}) => {
  const { key } = column;
  const [selectedDate, setSelectedDate] = useState({
    // mode: "within",
    // value: "2019-04-08 00:00:00/2019-04-09 23:59:00" //range format '2019-04-08 00:00:00/2019-04-09 23:59:00' , date format '2019-04-08 00:00:00'
  });
  const [columnFilterType, setColumnFilterType] = useState(false);
  const [resetColumn, setResetColumn] = useState(false);
  const isCalendarOpen = false;
  useEffect(() => {
    setShowColumnFilter('none');
    if (columnFilterType) {
      setTimeout(() => {
        setShowColumnFilter(column.key);
      }, 1000);
    }
  }, [columnFilterType]);
  useEffect(() => {
    const dates = appliedFilters[column.key] ? appliedFilters[column.key].split('/') : [];
    let value;
    let mode;
    if (dates.length > 1) {
      if (dates[0] && dates[1]) {
        value = [moment(dates[0], 'YYYY-MM-DD HH:mm:ss'), moment(dates[1], 'YYYY-MM-DD HH:mm:ss')];
        mode = 'within';
      }
      if (dates[0] && !dates[1]) {
        value = moment(dates[0], 'YYYY-MM-DD HH:mm:ss');
        mode = 'after';
      }
      if (!dates[0] && dates[1]) {
        value = moment(dates[1], 'YYYY-MM-DD HH:mm:ss');
        mode = 'before';
      }
    }
    if (dates.length === 1) {
      value = moment(dates[0], 'YYYY-MM-DD HH:mm:ss');
      mode = 'after';
    }
    if (dates.length === 0) {
      value = null;
      mode = 'within';
    }
    setSelectedDate({
      value,
      mode,
    });
  }, []);
  return (
    <div
      className={
        key === (showColumnFilter || isCalendarOpen)
          ? `${styles.show} col-filter-box`
          : `${styles.hide} col-filter-box`
      }
    >
      <div className="sorting-btn">
        <button
          type="submit"
          className={
            sortedColumn.columnName === key && sortedColumn.order === 'asc'
              ? 'btn btn-outline-primary active'
              : 'btn btn-outline-primary'
          }
          onClick={() =>
            setSortedColumn({
              columnName: column.key,
              order: 'asc',
            })
          }
        >
          {intl.formatMessage({ id: 'DateFilter.A-Z' })}
        </button>
        <button
          type="submit"
          className={
            sortedColumn.columnName === key && sortedColumn.order === 'desc'
              ? 'btn btn-outline-primary active'
              : 'btn btn-outline-primary'
          }
          onClick={() =>
            setSortedColumn({
              columnName: column.key,
              order: 'desc',
            })
          }
        >
          {intl.formatMessage({ id: 'DateFilter.Z-A' })}
        </button>
      </div>
      <form action="">
        <DateFilterWidget
          setAntdComponentOpen={setAntdComponentOpen}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          resetDates={false}
          resetColumn={resetColumn}
          maxAllowedDate={maxAllowedDate}
        />

        <div className="action">
          <A
            href="#"
            className="btn-reset"
            onClick={e => {
              e.preventDefault();
              setColumnFilterType(true);
              setResetColumn(true);
              setAntdComponentOpen(false);
              setAppliedFilters({ ...appliedFilters, [key]: undefined });
              if (column.key === sortedColumn.columnName) {
                setSortedColumn({ order: 'asc' });
              }
              //   setCheckedFilters({ ...checkedFilters, [key]: [] });
            }}
          >
            {intl.formatMessage({ id: 'DateFilter.Reset' })}
          </A>
          <button
            type="button"
            className="btn btn-outline-primary btn-apply"
            disabled={
              selectedDate.mode === 'within' &&
              selectedDate.value &&
              !(selectedDate.value[0] < selectedDate.value[1])
            }
            onClick={e => {
              e.preventDefault();
              let dateStr;
              if (selectedDate.mode === 'within') {
                dateStr = `${selectedDate.value[0]}/${selectedDate.value[1]}`;
              }
              if (selectedDate.mode === 'before') {
                dateStr = `/${selectedDate.value}`;
              }
              if (selectedDate.mode === 'after') {
                dateStr = selectedDate.value;
              }
              setAppliedFilters({
                ...appliedFilters,
                [column.key]: dateStr,
              });
              setResetColumn(false);
              setColumnFilterType(false);
              setShowColumnFilter('none');
            }}
          >
            {intl.formatMessage({ id: 'DateFilter.Apply' })}
          </button>
        </div>
      </form>
    </div>
  );
};

DateFilter.propTypes = {
  column: PropTypes.shape({
    key: PropTypes.string.isRequired,
  }).isRequired,
  sortedColumn: PropTypes.arrayOf(
    PropTypes.shape({
      columnName: PropTypes.number.isRequired,
      order: PropTypes.number.isRequired,
    })
  ).isRequired,
  showColumnFilter: PropTypes.bool.isRequired,
  setShowColumnFilter: PropTypes.func.isRequired,
  appliedFilters: PropTypes.arrayOf.isRequired,
  setAppliedFilters: PropTypes.func.isRequired,
  setAntdComponentOpen: PropTypes.func.isRequired,
  setSortedColumn: PropTypes.func.isRequired,
};

DateFilter.defaultProps = {
  maxAllowedDate: false,
};

// eslint-disable-next-line import/prefer-default-export
const DateFilterIntl = injectIntl(DateFilter);

export { DateFilterIntl as DateFilter };
