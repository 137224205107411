import { type KpiConfigs } from '../../models';

// See commit 310c764004d24e1c54402d0a5e6ea290559acab8 for previous implementation of pending-approvals

const kpiConfigs: KpiConfigs = {
  'campaign-list': {
    requestUrl: () => '/ECM/cert/fetchCertificationList',
    requestMethod: 'POST',
    requestBody: (username) => ({
      certifierUsername: username,
      refreshProgress: 'N',
      offset: '0',
      max: '1',
      status: 'new,inprogress,readytosubmit',
    }),
    processResponse: (response) => response.totalCount,
  },
  'pending-approvals': {
    requestUrl: () => '/ECM/api/v5/getPendingRequests',
    requestMethod: 'POST',
    requestBody: () => ({ max: 0 }),
    requestHeaders: (username, headers) => ({
      ...headers,
      SAVUSERNAME: username,
    }),
    processResponse: (response) => response.total,
  },
  'change-self-password': {
    requestUrl: (username) =>
      `/ECMv6/api/idw/users/activity/${username}?feature=password`,
    requestMethod: 'GET',
    processResponse: (response) => response.daysToExpireUserPassword ?? 0,
  },
} as const;

export default kpiConfigs;

// todo move to thunks
