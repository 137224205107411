import { createIntl } from 'react-intl';
import store from './store';

const select = (state) => {
    return state.user.messages || {};
}

export const getIntl = () => {
    const locale = 'en';
    const messages =  store ? select(store.getState()) : {};
    // const intlProvider = new IntlProvider({ locale, messages });
    // const { intl } = intlProvider.getChildContext();
    const intl = createIntl({ locale, messages });
    return intl;
}
