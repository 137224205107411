import React, { useState, useEffect } from 'react';
import MuiTextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useDebounce } from 'use-debounce';

const TextField = withStyles(theme => ({
  root: {
    width: '100%',
    '& label.Mui-focused': {
      // color: theme.palette.primary.main,
      visibility: 'hidden',
    },
    '& .MuiInputLabel-root': {
      fontSize: 12,
      top: 7,
      position: 'relative',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.primary.main,
    },
    '& .MuiOutlinedInput-input': {
      padding: 14,
    },
    '& .MuiOutlinedInput-root': {
      color: 'var(--black)',
      fontSize: 12,
      '& fieldset': {
        borderColor: 'var(--grey-border-input)',
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '&.Mui-focused fieldset': {
        border: '1px solid',
        borderColor: theme.palette.primary.main,
      },
    },
    '& .MuiInputBase-multiline': {
      padding: 0,
    },
  },
}))(MuiTextField);

const OutlinedTextField = ({
  name,
  placeholder,
  _handleChange,
  attributeKey,
  defaultValue,
  isPassword,
  showError,
  errorMessage,
  multiline,
  required,
  customEndAdornmentIcon,
  isDisabled,
  inputProps
}) => {
  const [value, setValue] = useState(defaultValue || '');
  const [debouncedText] = useDebounce(value, 500);
  const DEFAULT_ROWS_MAX = 10;

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);
  useEffect(() => {
    if (debouncedText) {
      _handleChange(value, attributeKey);
    } else {
      setValue('');
      _handleChange('', attributeKey);
    }
  }, [debouncedText]);

  const handleChange = e => setValue(e.target.value);

  const passwordProps = isPassword
    ? {
        type: 'password',
        autoComplete: 'current-password',
        disabled: isDisabled,
      }
    : {};

  return (
    <>
      <TextField
        multiline={multiline}
        margin="none"
        variant="outlined"
        value={value}
        InputLabelProps={{
          // style: { fontSize: 12, top: 7, position: 'relative' },
          disableAnimation: true,
          shrink: false,
        }}
        InputProps={{
          // style: { height: 42, fontSize: 12 },
          endAdornment: customEndAdornmentIcon || null,
        }}
        label={
          <div className={value ? 'hide' : ''}>
            <span dangerouslySetInnerHTML={{ __html: placeholder }} />
            {required && <span className="mandatory">&nbsp;*</span>}
          </div>
        }
        rowsMax={DEFAULT_ROWS_MAX}
        id={name}
        onChange={handleChange}
        name={name}
        error={showError}
        helperText={showError ? errorMessage : null}
        inputProps={inputProps}
        {...passwordProps}
      />
    </>
  );
};

OutlinedTextField.propTypes = {
  _handleChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  attributeKey: PropTypes.string.isRequired,
  defaultValue: PropTypes.string.isRequired,
  isPassword: PropTypes.bool,
  showError: PropTypes.bool,
  multiline: PropTypes.bool,
  errorMessage: PropTypes.string,
  // _onFocus: PropTypes.func.isRequired,
  // required: PropTypes.bool.isRequired,
  // showError: PropTypes.bool.isRequired,
  // readOnly: PropTypes.bool.isRequired,
  customEndAdornmentIcon: PropTypes.string,
  isDisabled: PropTypes.bool,
};

OutlinedTextField.defaultProps = {
  showError: false,
  multiline: false,
  isPassword: false,
  errorMessage: 'Invalid Input',
  customEndAdornmentIcon: '',
  isDisabled: false,
};

export default OutlinedTextField;
