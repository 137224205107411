import React from 'react';
import styles from './LayoutWrapper.module.css';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

const LayoutWrapper = ({ children }) => {
  const isMenuPinned = useSelector((state) => state.user?.isMenuPinned);

  const classes = window.location.href?.includes('/login')
    ? styles.menuClosed
    : isMenuPinned
    ? styles.menuOpen
    : styles.menuClosed;

  const isNewSidebarClass = window.location.href?.includes('/login') ? '' : styles.sideBarSpacing;

  const className = classnames(
    classes,
    isNewSidebarClass,
    isNewSidebarClass && 'sideBarSpacing',
    isMenuPinned && 'Gen2NavMenuOpen'
  );

  return <div className={className}>{children}</div>;
};

export default LayoutWrapper;
