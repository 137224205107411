import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import generateIndexFromKeys from 'ui-common/src/utils/generateIndexFromKeys';
import Loader from 'ui-components/src/components/Loader/Loader';
import Row from '../Row/Row';
import useQuery from '../../../utils/useQueryHook';
import { defaultConfig } from '../../../reducers/logsReducer';
import ShowSpinner from '../ShowSpinner/ShowSpinner';
import useInViewHook from '../../../utils/useInViewHook';
import { getResultsForScroll, updateMarkers } from '../../../actions/logsAction';
import DownloadFloat from '../DownloadFloat/DownloadFloat';
import ScrollToTop from '../ScrollToTop/ScrollToTop';
import { sequentialLogsDuration } from '../../../../constant';

const ShowRefDiv = ({ scrollRef, isVisible, dataID }) => {
  if (isVisible) {
    return <div ref={scrollRef} data-id={dataID} />;
  }

  return null;
};

const RowContent = ({ intl }) => {
  const dispatch = useDispatch();
  const queryParam = useQuery();
  const appendRef = useRef(null);
  const loading = useSelector((state) => state.admin.logs.loading);
  const dataLoading = useSelector((state) => state.admin.logs.dataLoading);
  const resultsFromState = useSelector((state) => state.admin.logs.results);
  const resultsStartScrollIndex = useSelector((state) => state.admin.logs.resultsStartScrollIndex);
  const scrollMarkers = useSelector((state) => state.admin.logs.scrollMarkers);
  const query = useSelector((state) => state.admin.logs.query);
  const hasMoreDataFromApi = useSelector((state) => state.admin.logs.hasMoreData);
  const firstScrollRef = useRef(null);
  const lastScrollRef = useRef(null);
  const isFirstRowVisible = useInViewHook(firstScrollRef);
  const isLastRowVisible = useInViewHook(lastScrollRef);
  const [results, setResults] = useState([]);
  const [scrollIndex, setScrollIndex] = useState(0);
  const rowToAppend = defaultConfig.noOfRecordsToView / 2;
  const showFirstRowSpinner = isFirstRowVisible && (
    resultsFromState[0]?.searchId !== results[0]?.searchId || scrollIndex > rowToAppend
  );

  const hasMoreData = dataLoading || (results.length > 0 && (
    hasMoreDataFromApi || (scrollIndex - resultsStartScrollIndex < resultsFromState.length)
  ));

  const setResultsInStateWithMarker = (data, append = true) => {
    setResults((prevState) => {
      if (prevState.length > 0) {
        const resultsWithoutAppendMarkers = prevState.filter((f) => !f.appendMarker);

        let slicedResults = resultsWithoutAppendMarkers;

        if (append) {
          if (resultsWithoutAppendMarkers.length >= defaultConfig.noOfRecordsToView) {
            const startIndex = defaultConfig.noOfRecordsToView - (resultsWithoutAppendMarkers.length - data.length);
            const endIndex = resultsWithoutAppendMarkers.length;

            slicedResults = resultsWithoutAppendMarkers.slice(startIndex, endIndex);
          }

          return [
            ...slicedResults,
            { appendMarker: true },
            ...data,
          ];
        }

        if (resultsWithoutAppendMarkers.length >= defaultConfig.noOfRecordsToView) {
          const startIndex = 0;
          const endIndex = defaultConfig.noOfRecordsToView - data.length;

          slicedResults = resultsWithoutAppendMarkers.slice(startIndex, endIndex);
        }

        return [
          ...data,
          { appendMarker: true },
          ...slicedResults,
        ];
      }

      return [...data];
    });
  };

  useEffect(() => {
    if (
      appendRef.current !== null && scrollIndex !== 0 && (
        (isFirstRowVisible && results.findIndex((f) => f.searchId === scrollMarkers[0]) === -1)
            || (isLastRowVisible && hasMoreData)
      )
    ) {
      // used scrollInToView on appendRef.current but could not get the desired visual behaviour so going with window.scrollTo
      // appendRef.current.scrollIntoView({ behavior: 'smooth', block: 'center', alignToTop: true });
      setTimeout(() => window.scrollTo(0, appendRef.current.offsetTop - 300), 100);
    }
  }, [results]);

  useEffect(() => {
    if (results.length === 0 && resultsFromState.length > 0) {
      setResultsInStateWithMarker(resultsFromState.slice(scrollIndex, rowToAppend));
      setScrollIndex((prevState) => prevState + rowToAppend);
    } else if (scrollMarkers.length === 1 && results.length > 0) {
      setResults(resultsFromState.slice(0, rowToAppend));
      setScrollIndex(rowToAppend);
    }
  }, [resultsFromState]);

  useEffect(() => {
    const isVisible = isFirstRowVisible;

    if (isVisible && !dataLoading) {
      if (scrollIndex <= rowToAppend && resultsFromState[0]?.searchId === results[0]?.searchId) {
        return;
      }

      const prevScrollIndex = scrollIndex - rowToAppend;
      const getDataFromApi = prevScrollIndex !== 0 && prevScrollIndex === resultsStartScrollIndex;

      const startIndex = prevScrollIndex - resultsStartScrollIndex;
      const endIndex = startIndex + rowToAppend;

      setResultsInStateWithMarker(resultsFromState.slice(startIndex, endIndex), false);
      setScrollIndex((prevState) => prevState - rowToAppend);

      if (getDataFromApi) {
        const newMarkerLength = scrollMarkers.length - (
          (defaultConfig.noOfRecordsToStoreInMemory / defaultConfig.noOfRecordsToQuery) + 1
        );
        const newQuery = { ...query, searchAfter: scrollMarkers[newMarkerLength] };
        const markers = scrollMarkers.filter((f, i) => i !== scrollMarkers.length - 1);

        dispatch(updateMarkers([...markers]));
        dispatch(getResultsForScroll(newQuery, false));
      }
    }

  }, [isFirstRowVisible, dataLoading]);

  useEffect(() => {
    const isVisible = isLastRowVisible;
    const nextScrollIndex = scrollIndex + rowToAppend;

    if (isVisible && !dataLoading) {
      const getDataFromApi = (nextScrollIndex % defaultConfig.noOfRecordsToQuery) === 0;
      const startIndex = scrollIndex - resultsStartScrollIndex;
      const endIndex = startIndex + rowToAppend;

      setResultsInStateWithMarker(resultsFromState.slice(startIndex, endIndex));
      setScrollIndex((prevState) => prevState + rowToAppend);

      if (getDataFromApi && hasMoreDataFromApi) {
        const lastSearchID = resultsFromState[resultsFromState.length - 1].searchId;
        const newQuery = { ...query, searchAfter: lastSearchID };

        dispatch(updateMarkers([...scrollMarkers, lastSearchID]));
        dispatch(getResultsForScroll(newQuery, true));
      }
    }
  }, [isLastRowVisible, dataLoading]);

  const generateUrl = ({ searchId, timestamp }) => {
    const formatDateTimeStamp = (date) => moment(date).format();
    const params = {
      fromDate: formatDateTimeStamp(moment(timestamp).subtract(sequentialLogsDuration, 'second')),
      toDate: formatDateTimeStamp(moment(timestamp).add(sequentialLogsDuration, 'second')),
      searchAfter: searchId,
    };

    let newQuery = { ...query, ...params };

    return ({
      pathname: '/logs',
      search: `?${new URLSearchParams(newQuery)}`,
      state: newQuery,
    });
  };

  if (loading) return <Loader />;

  return (
    <>
      <ShowRefDiv scrollRef={firstScrollRef} isVisible dataID={0} />
      <ShowSpinner isDisplayed={showFirstRowSpinner} />
      {
        results.map((result, i) => {
          if (result.appendMarker) {
            return <ShowRefDiv scrollRef={appendRef} isVisible dataID={i} />;
          }

          const message = result.message?.trim();
          const searchAfterKey = parseInt(queryParam.get('searchAfter'), 10);
          const isSearchID = searchAfterKey && searchAfterKey === result.searchId;
          const showLastRowSpinner = results.length - 1 === i && hasMoreData;

          return (
            <div key={generateIndexFromKeys(result.searchId, i)}>
              <Row
                intl={intl}
                timestamp={moment(result.timestamp).format()}
                serviceName={result.servicename}
                level={result.level}
                threadName={result.threadName}
                className={result.className}
                message={message}
                url={generateUrl(result)}
                isSearchID={isSearchID}
              />
              <ShowRefDiv scrollRef={lastScrollRef} isVisible={showLastRowSpinner} dataID={i} />
              <ShowSpinner isDisplayed={showLastRowSpinner} />
            </div>
          );
        })
      }
      <DownloadFloat isDisplayed={!isFirstRowVisible} intl={intl} />
      <ScrollToTop isDisplayed={!isFirstRowVisible} />
    </>
  );
};

export default RowContent;
