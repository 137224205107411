const TabPanel = ({
  index, value, children,
}) => {
  if (index === value) {
    return children;
  }

  return null;
};

export default TabPanel;
