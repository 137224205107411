export const invertedMessages = {
  'There was a problem loading this item.':
    'Nav.RecentAssignmentsWidget.SingleRequestError',
  'My Recent Assignments': 'Nav.RecentAssignmentsWidget.Heading',
  "We're having trouble loading your Recent Assignments at this time.":
    'Nav.RecentAssignmentsWidget.AllRequestsError',
  Certification: 'Nav.RecentAssignmentsWidget.CertificationIdLabel',
  'Ends at': 'Nav.RecentAssignmentsWidget.CertificationEndsAtLabel',
  Progress: 'Nav.RecentAssignmentsWidget.CertificationProgressLabel',
  Overdue: 'Nav.RecentAssignmentsWidget.CertificationOverdue',
  Complete: 'Nav.RecentAssignmentsWidget.CertificationComplete',
  'Due in {count} d': 'Nav.RecentAssignmentsWidget.CertificationDueIn',
  'Review Request': 'Nav.RecentAssignmentsWidget.RequestIdLabel',
  Type: 'Nav.RecentAssignmentsWidget.RequestTypeLabel',
  'Requested by': 'Nav.RecentAssignmentsWidget.RequestedByLabel',
  Status: 'Nav.RecentAssignmentsWidget.RequestStatusLabel',
  Due: 'Nav.RecentAssignmentsWidget.RequestDueDateLabel',
  'Nothing to see here, you’re all caught up for now!':
    'Nav.RecentAssignmentsWidget.NoAssignmentsMessage',
} as const;

export const messages: Record<string, string> = {} as const;
