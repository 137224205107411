const defaultTheme = {
  primary: [55, 81, 255],
  primaryBg: [223, 224, 235],
  secondaryBg: [234, 238, 251],
  primaryBgLi: [242, 244, 255],
  primaryCardBg: [105, 116, 208],
  primaryCardBgText: [199, 205, 255],
  secondary: [234, 169, 69],
  lightBorder: [210, 219, 255],
  hoverBg: [244, 246, 250],
  pageBg: [247, 248, 252],
  darkBg: [38, 39, 49],
  darkBgLi: [50, 50, 62],
  darkText: [208, 208, 208],
  darkTitle: [208, 208, 208],
  darkLine: [65, 65, 80],
  navBarTitle: [100, 100, 100],
  navBarBg: [255, 255, 255],
  navBarLabel: [131, 129, 129],
  navBarLabelHover: [55, 81, 255],
  navBarLabelActive: [255, 255, 255],
  navBarIcon: [131, 129, 129],
  navBarIconHover: [55, 81, 255],
  navBarIconActive: [255, 255, 255],
  sodBg: [127, 105, 212],
  sodBgAsync: [229, 227, 247],
  approvalIconBg: [239, 241, 255],
  disabledColor: [116, 116, 116],
  primaryTextColor: [116, 116, 116],
  requestReviewFooterBg: [255, 255, 255],
  requestReviewSodPopupColor:[20,20,25],
  requestReviewSodPopupSecColor:[103,107,128],
};

const marriottTheme = {
  primary: [155, 34, 66],
  primaryBg: [250, 240, 242],
  primaryBgLi: [253, 249, 249],
  secondaryBg: [250, 240, 242],
  primaryCardBg: [194, 81, 111],
  primaryCardBgText: [255, 167, 190],
  secondary: [234, 169, 69],
  lightBorder: [244, 213, 219],
  hoverBg: [253, 249, 249],
  pageBg: [249, 249, 249],
  darkBg: [255, 255, 255],
  darkBgLi: [253, 249, 249],
  darkText: [51, 51, 51],
  darkTitle: [36, 36, 36],
  darkLine: [221, 221, 221],
  navBarTitle: [100, 100, 100],
  navBarBg: [255, 255, 255],
  navBarLabel: [131, 129, 129],
  navBarLabelHover: [155, 34, 66],
  navBarLabelActive: [155, 34, 66],
  navBarIcon: [131, 129, 129],
  navBarIconHover: [155, 34, 66],
  navBarIconActive: [155, 34, 66],
};

const fidelityTheme = {
  primary: [2, 128, 128],
  primaryBg: [223, 224, 235],
  secondaryBg: [236, 249, 253],
  primaryBgLi: [242, 244, 255],
  primaryCardBg: [105, 116, 208],
  primaryCardBgText: [199, 205, 255],
  secondary: [234, 169, 69],
  lightBorder: [236, 249, 253],
  hoverBg: [244, 246, 250],
  pageBg: [247, 248, 252],
  darkBg: [38, 39, 49],
  darkBgLi: [50, 50, 62],
  darkText: [208, 208, 208],
  darkTitle: [208, 208, 208],
  darkLine: [65, 65, 80],
  navBarTitle: [100, 100, 100],
  navBarBg: [255, 255, 255],
  navBarLabel: [131, 129, 129],
  navBarLabelHover: [2, 128, 128],
  navBarLabelActive: [2, 128, 128],
  navBarIcon: [131, 129, 129],
  navBarIconHover: [2, 128, 128],
  navBarIconActive: [2, 128, 128],
};

export { defaultTheme, marriottTheme, fidelityTheme };
