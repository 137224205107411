import { checkEnvironment } from 'ui-common/src/lib/utils';


const { CHAT_BOT_KB_TOKENS, THEME } = checkEnvironment();
const configData = {
  commentsMinChars: 3,
  loaderMinDelay: 800,
  isChatBots: false,
  chatBotKBTokens: CHAT_BOT_KB_TOKENS,
  theme: THEME,
  errorMessageTimeOut: 15000,
  timeDelayBeforeRequest: 15,
};
export default configData;
export {FEATURE_FLAG, isBetaFeatureEnabled} from './features';