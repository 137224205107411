import React from 'react';
import { injectIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { hideAlertBanner } from '../../../redux/requestHome-actions';
import './AlertBanner.scss';

export const Close = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.62204 3.08591C2.78476 2.92319 3.04858 2.92319 3.21129 3.08591L10 9.87461L16.7887 3.08595C16.9514 2.92324 17.2152 2.92324 17.3779 3.08595C17.5407 3.24867 17.5407 3.51249 17.3779 3.67521L10.5893 10.4639L17.3779 17.2525C17.5407 17.4152 17.5407 17.6791 17.3779 17.8418C17.2152 18.0045 16.9514 18.0045 16.7887 17.8418L10 11.0531L3.21129 17.8418C3.04858 18.0045 2.78476 18.0045 2.62204 17.8418C2.45932 17.6791 2.45932 17.4153 2.62204 17.2526L9.41076 10.4639L2.62204 3.67516C2.45932 3.51244 2.45932 3.24863 2.62204 3.08591Z"
        fill="white"
      />
    </svg>
  );
};
const AlertBanner = ({ intl }) => {
  const dispatch = useDispatch();
  const { bannerDetails } = useSelector((state) => state.requestHome.initialLoad);

  const alertClass = classnames(
    'alert-banner-section',
    bannerDetails?.criticality === 'Critical' && 'alert-critical',
    bannerDetails?.criticality === 'Info' && `alert-information`
  );

  const closeAlertBanner = () => {
    dispatch(hideAlertBanner());
  };

  return (
    <div className={alertClass}>
      {bannerDetails?.labelId && (
        <div className="alert-message">
          {intl.formatMessage({
            id: bannerDetails?.labelId,
          })}
        </div>
      )}
      <div
        className="alert-close"
        role="button"
        tabIndex={0}
        onClick={() => {
          closeAlertBanner();
        }}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            closeAlertBanner();
          }
        }}
      >
        <Close />
      </div>
    </div>
  );
};

AlertBanner.propTypes = {
  intl: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};

export default injectIntl(AlertBanner);
