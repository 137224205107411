import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import DatePicker from './DatePicker';
import styles from './DateTimeRangePicker.module.css';
import './DateTimeRangePicker.css';
import './Clock.css';
import './Calendar.css';

const DateTimeRangePicker = ({
  startDate, endDate, handleSelectedRange, intl, minDate, maxDate,
}) => {
  const [value, setValue] = useState([new Date(), new Date()]);

  useEffect(() => {
    if ((startDate !== '' && startDate !== value[0]) || (endDate !== '' && endDate !== value[1])) {
      setValue([startDate, endDate]);
    }
  }, [startDate, endDate]);

  const handleApply = () => {
    handleSelectedRange(value);
  };

  return (
    <div className={styles.container}>
      <DatePicker
        format="y-MM-dd h:mm:ss a"
        onChange={setValue}
        value={value}
        closeWidgets={false}
        minDate={minDate}
        maxDate={maxDate}
        isCalendarOpen
        showDoubleView
        renderSecondHand
      />
      <div className={styles.button}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleApply}
        >
          {intl.formatMessage({ id: 'Admin.Log.Viewer.apply' })}
        </Button>
      </div>
    </div>
  );
};

export default DateTimeRangePicker;
