import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MaterialTooltip from '@material-ui/core/Tooltip';

const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: 12,
    backgroundColor: theme.palette.common.black,
  },
  arrow: {
    color: theme.palette.common.black,
  },
}))(MaterialTooltip);

const Tooltip = ({
  children, title, ariaLabel, placement = 'top',
}) => (
  <StyledTooltip
    title={title}
    aria-label={ariaLabel}
    placement={placement}
    arrow
  >
    {children}
  </StyledTooltip>
);

export default Tooltip;
