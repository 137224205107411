import React from 'react';

function getBrowserFullscreenElementProp() {
  if (typeof document.fullscreenElement !== 'undefined') {
    return 'fullscreenElement';
  }

  if (typeof document.mozFullScreenElement !== 'undefined') {
    return 'mozFullScreenElement';
  }

  if (typeof document.msFullscreenElement !== 'undefined') {
    return 'msFullscreenElement';
  }

  if (typeof document.webkitFullscreenElement !== 'undefined') {
    return 'webkitFullscreenElement';
  }

  return null;
}

export default function useFullscreen() {
  const [isFullscreen, setIsFullscreen] = React.useState(
    document[getBrowserFullscreenElementProp()] != null,
  );

  const setFullscreen = () => {
    document.documentElement
      .requestFullscreen()
      .then(() => {
        setIsFullscreen(document[getBrowserFullscreenElementProp()] != null);
      })
      .catch(() => {
        setIsFullscreen(false);
      });
  };

  React.useLayoutEffect(() => {
    document.onfullscreenchange = () => setIsFullscreen(document[getBrowserFullscreenElementProp()] != null);
  });

  return [isFullscreen, setFullscreen];
}
