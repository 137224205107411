/**
 * Searches through a list of options to find the first one that is checked and returns its value.
 * If no checked option is found, returns null.
 *
 * @param {Object[]} options - An array of objects, each representing an option with a 'isChecked' property.
 * @returns {(string|null)} - The value of the checked option, or null if none are checked.
 *
 * @example
 * // Example call with options array
 * const options = [
 *   { isChecked: false, value: 'Option 1' },
 *   { isChecked: true, value: 'Option 2' },
 *   { isChecked: false, value: 'Option 3' }
 * ];
 * const checkedValue = getCheckedInterval(options);
 * console.log(checkedValue); // Output: 'Option 2'
 */
const getCheckedInterval = (options) => {
  const checkedOption = options.find((option) => option.isChecked);

  return checkedOption ? checkedOption.value : null;
};

export default getCheckedInterval;
