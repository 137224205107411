import { checkEnvironment } from '../lib/utils';

export const FEATURE_FLAG = { 
    INTELLIGENCE: {
        IDENTITY_MATCH : "IDENTITY_MATCH_AND_MERGE"
    }
}

export const isBetaFeatureEnabled = feature => {
    return checkEnvironment().BETA_FEATURES.includes(feature);
}