/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { Button, ModalIsland } from '@saviynt/design-system';
import { MODAL_TYPES } from '../../utils/constants';
import { message } from '../../utils/helper';

const configureModal = (isOpen, modalType, selectedVariant, onPrimaryAction, onSecondaryAction, onClose, intl) => {
  const modalData = MODAL_TYPES[modalType];

  return (
    <ModalIsland
      kind={modalData?.kind || 'warning'}
      alertTitle={message(intl, modalData.title)}
      alertSubtitle={message(intl, modalData.subTitle)?.replace('###ACTIVE_THEME###', selectedVariant?.styles?.displayName)}
      isOpen={isOpen}
      onClose={() => onClose()}
      primaryButton={(
        <Button type="button" kind="outlined" size="medium" onClick={onPrimaryAction}>
          {message(intl, modalData.primaryButton)}
        </Button>
      )}
      secondaryButton={(
        <Button type="button" kind="ghost" size="medium" onClick={onSecondaryAction}>
          {message(intl, modalData.secondaryButton)}
        </Button>
      )}
    />
  );
};

const DynamicModal = ({
  isOpen, modalType, selectedVariant, onPrimaryAction, onSecondaryAction, onClose, intl
}) => configureModal(isOpen, modalType, selectedVariant, onPrimaryAction, onSecondaryAction, onClose, intl);

DynamicModal.propTypes = {
  modalType: PropTypes.oneOf(['delete', 'unsaved']).isRequired,
  selectedVariant: PropTypes.string.isRequired,
  onPrimaryAction: PropTypes.func.isRequired,
  onSecondaryAction: PropTypes.func.isRequired,
};

export default DynamicModal;
