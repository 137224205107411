/* eslint-disable */
/* eslint-disable consistent-return */

import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Logger } from '../utilities/logger';

function usePendo() {
  try {
    const state = useSelector(selectState);
    const isMissingParameters = Object.values(state).some(
      (value) => value === undefined
    );
    const {
      neoHomePage,
      neoUnifiedNav,
      neoArs,
      neoCerts,
      neoPam,
      pam,
      ecmVersion,
      isHelpEnabled,
      homePageQuicklinks,
      homePageKpis,
      apiKey,
      customerName,
      environment,
      locale,
      roles,
      userKey,
    } = state;

    useEffect(() => {
      try {
        if (window.pendo) {
          Logger.log('Pendo is already initialized.');
          return;
        }

        if (isMissingParameters) {
          Logger.log(
            'Missing required parameters, skipping Pendo initialization.'
          );
          return;
        }

        const visitorId = `${customerName}#${environment}#${userKey}`;
        const accountId = customerName;

        /** Initialize Pendo, considering the pendo script might not have finished downloading/executing yet */
        function initializePendo(retries = 2) {
          if (!window.pendo) {
            if (retries > 0) {
              Logger.log('Pendo script is not ready, retrying initialization');
            } else {
              Logger.log(
                'Pendo script failed to load after multiple attempts.'
              );
            }

            return;
          }

          try {
            const config = {
              visitor: {
                id: visitorId, // generated visitor ID
                roles: roles,
                language: locale,
              },
              account: {
                id: accountId, // generated account ID
                neoHomePage,
                neoUnifiedNav,
                neoArs,
                neoCerts,
                neoPam,
                pam,
                ecmVersion,
                isHelpEnabled,
                homePageQuicklinks,
                homePageKpis,
              },
            };

            window.pendo.initialize(config);
            Logger.log('Pendo initialized successfully with config:', config);
          } catch (error) {
            Logger.error('Error initializing Pendo (1):', error);

            if (retries > 0) {
              Logger.log('Retrying Pendo initialization');
              setTimeout(() => initializePendo(retries - 1), 1000); // Retry initialization
            } else {
              Logger.log('Pendo initialization failed after multiple attempts');
            }
          }
        }

        loadPendo(apiKey);
        initializePendo();
      } catch (error) {
        Logger.error('Error initializing Pendo (3):', error);
      }
    }, [
      isMissingParameters,
      neoHomePage,
      neoUnifiedNav,
      neoArs,
      neoCerts,
      neoPam,
      pam,
      ecmVersion,
      isHelpEnabled,
      homePageQuicklinks,
      homePageKpis,
      apiKey,
      customerName,
      environment,
      locale,
      roles,
      userKey,
    ]);
  } catch (error) {
    Logger.error('Error initializing Pendo (2):', error);
  }
}

// Function to load Pendo script
function loadPendo(apiKey) {
  Logger.log('Attempting to load Pendo script');
  (function (p, e, n, d, o) {
    let v;
    let w;
    let x;
    let y;
    let z;
    o = p[d] = p[d] || {};
    o._q = o._q || [];
    v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
    for (w = 0, x = v.length; w < x; ++w) {
      (function (m) {
        o[m] =
          o[m] ||
          function () {
            o._q[m === v[0] ? 'unshift' : 'push'](
              [m].concat([].slice.call(arguments, 0))
            );
          };
      })(v[w]);
    }
    y = e.createElement(n);
    y.async = true;
    y.src = `${atob(
      'aHR0cHM6Ly9jZG4ucGVuZG8uaW8vYWdlbnQvc3RhdGljLw=='
    )}${apiKey}/pendo.js`;
    z = e.getElementsByTagName(n)[0];
    z.parentNode.insertBefore(y, z);
  })(window, document, 'script', 'pendo');
}

function selectState(state) {
  const {
    gen2HomePage,
    leftNavGen2,
    requestgen2config,
    certsGen2,
    domainInfo,
    pamGen2,
    ecmVersion,
    homepage: { quicklinks, kpis },
    userInfo: { id: userKey, roles, userSavRoleMap },
    customerInfo: { customerName, environment },
    pendoInfo: { apiKey },
  } = state.user;
  const helpDetails = state.profile.helpDetails;

  return {
    neoHomePage: gen2HomePage === undefined ? undefined : gen2HomePage === '1',
    neoUnifiedNav: leftNavGen2 === undefined ? undefined : leftNavGen2 === '1',
    neoArs:
      requestgen2config === undefined ? undefined : requestgen2config === '1',
    neoCerts: certsGen2 === undefined ? undefined : certsGen2 === '1',
    neoPam: pamGen2 === undefined ? undefined : pamGen2 === '1',
    pam: domainInfo === undefined ? undefined : domainInfo.PAM_SERVICES_ENABLED,
    ecmVersion,
    isHelpEnabled:
      helpDetails === undefined ? undefined : helpDetails.isHelpEnabled === '1',
    homePageQuicklinks: quicklinks,
    homePageKpis: kpis,
    apiKey,
    customerName,
    environment,
    locale:
      userSavRoleMap === undefined ? undefined : userSavRoleMap.locale || 'en',
    roles,
    userKey,
  };
}

export default usePendo;
