/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';

const renderFilterPills = (MenuMulti, ButtonSelect, platform, asset, role) => (
  <>
    <MenuMulti
      options={platform.options}
      label='Platform'
      trigger={
        <ButtonSelect
          kind='pill'
          label='Platform'
          widthConfigs={{ maxWidth: '15rem' }}
        />
      }
      onChange={platform.setSelected}
      heightToShowScrollbar='20rem'
      size='medium'
    />
    <MenuMulti
      options={asset.options}
      label='Asset Type'
      trigger={
        <ButtonSelect
          kind='pill'
          label='Asset Type'
          widthConfigs={{ maxWidth: '15rem' }}
        />
      }
      onChange={asset.setSelected}
    />
    <MenuMulti
      options={role.options}
      label='Role Type'
      trigger={
        <ButtonSelect
          kind='pill'
          label='Role Type'
          widthConfigs={{ maxWidth: '15rem' }}
        />
      }
      onChange={role.setSelected}
      size='medium'
    />
  </>
);

export { renderFilterPills };
