const findIfQueryParamsMatch = (apiDataQueryParam, locationQueryParam) => {
  let isFlag = false;

  if (apiDataQueryParam.isEmpty) {
    return true;
  }

  for (const key in apiDataQueryParam) {
    isFlag = false;
    // console.log(apiDataQueryParam[key], locationQueryParam[key]);

    if (apiDataQueryParam[key] === locationQueryParam[key]) {
      isFlag = true;
    } else {
      return isFlag;
    }
  }

  return isFlag;
};

export default findIfQueryParamsMatch;
