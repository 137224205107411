import React from 'react';
import PropTypes from 'prop-types';
import './ListView.scss';

const ListView = ({ data, columns }) => (
  <div className="list_view_container h-100">
    <div className=" p-0 h-100">
      {data.length > 0 ? (
        data.map(row => (
          <div className="content d-flex bd-highlight" key={`${row.requestId}${row.instanceId}`}>
            {columns.map(col => (
              <div className="d-flex list-content" key={col}>
                {row[col]}
              </div>
            ))}
          </div>
        ))
      ) : (
        <div />
      )}
    </div>
  </div>
);

ListView.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      requestId: PropTypes.string.isRequired,
      instanceId: PropTypes.func.isRequired,
    })
  ).isRequired,
  columns: PropTypes.oneOf.isRequired,
};

export default ListView;
