import React, { useState } from 'react';
import { Cookies } from 'react-cookie';
import { formatLocalizedDateTime, useLocalize } from '@saviynt/common';
import {
  Box,
  Chip,
  DownloadFile,
  Icon,
  TabContent,
  TabGroup,
  TabItem,
} from '@saviynt/design-system';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import {
  Entitlement,
  ModuleDetails,
  RequestDetails,
  RoleDetails,
  Session,
} from '../../../../../../models/PamModels';
import getFileDownloadApiUrl from '../../../../../../utilities/api/urls/getFileDownloadApiUrl';
import { msgs } from '../../../../constants';
import SpvEndpoint from '../../../Endpoints/SpvEndpoint/SpvEndpoint';
import SpvEntitlements from '../../../Roles/SpvEntitlements/SpvEntitlements';
import SpvRole from '../../../Roles/SpvRole/SpvRole';
import SpvSection from '../../../SpvSection/SpvSection';

import './SpvPendingSession.css';

function SpvPendingSession({
  userAccessData,
  accountDetails,
  endpointDetails,
  pendingSession,
  requestDetails,
  roleDetails,
  entitlements,
  dataTestId,
  className,
}) {
  const classes = classnames('SpvPendingSession', className);
  const [activeTab, setActiveTab] = useState('1');
  const localize = useLocalize();
  const cookies = new Cookies();

  const handleChange = (value) => {
    setActiveTab(value);
  };

  const hasRequestDetailsFields =
    pendingSession?.requestId ||
    requestDetails?.sessionStatus ||
    requestDetails?.requestDate ||
    requestDetails?.sessionStartDate ||
    requestDetails?.sessionEndDate;

  const isCredentialless =
    pendingSession?.pamType?.toUpperCase() === 'CREDENTIALLESS';
  const remoteAppName = userAccessData?.remoteAppMetadata?.displayName;

  const showRemoteAppName =
    isCredentialless && remoteAppName && remoteAppName !== 'Terminal';

  const hasSessionAccountFields =
    pendingSession?.accountName ||
    accountDetails?.description ||
    userAccessData?.accountDescription ||
    accountDetails?.pamAccountType ||
    accountDetails?.accountConfig?.authenticationType;

  const hasPendingRequestComments =
    requestDetails?.comments && requestDetails?.comments.length > 0;

  const hasPendingRequestAttachments =
    requestDetails?.attachments && requestDetails?.attachments.length > 0;

  const hasRole = userAccessData?.roleKey && roleDetails;
  const hasEntitlements = userAccessData?.roleKey && entitlements?.length > 0;

  const renderComments = () => {
    const fields = [];

    requestDetails?.comments.forEach((comment) => {
      const { commentor } = comment;
      const { firstName, lastName, userName } = commentor;

      const commentDate = comment.date;

      const labelTitle = `${firstName || ''} (${userName}) ${
        lastName || ''
      } at ${formatLocalizedDateTime(`${commentDate}Z`)}:`;

      fields.push({ label: labelTitle, value: comment.comment });
    });

    return fields;
  };

  const requestStatus = !pendingSession?.sessionStatus ? (
    <Chip
      kind='subtleFilled'
      color='info'
      size='small'
      label={localize(msgs.pam.sidePanel.pendingApproval)}
      className='SpvPendingSession-pendingApproval'
    />
  ) : null;

  const getAssignees = () => {
    const assignees = [];

    requestDetails?.endpoints?.[0]?.approvals?.[0]?.assignee?.map(
      (assignedPerson) => assignees.push(assignedPerson.displayName)
    );

    return assignees.join(',');
  };

  const accountSectionHasDivider = showRemoteAppName || hasRequestDetailsFields;
  const commentsSectionHasDivider =
    accountSectionHasDivider || hasSessionAccountFields;
  const attachmentsSectionHasDivider =
    commentsSectionHasDivider || hasPendingRequestComments;

  return (
    <section className={classes} data-testid={dataTestId}>
      <TabGroup
        className='SpvPendingSession-tabGroup'
        value={activeTab}
        onChange={handleChange}>
        <TabItem
          label={localize(msgs.pam.sidePanel.session)}
          value='1'
          icon={
            <Icon kind='PrivilegedSession' size='medium' color='neutral-1000' />
          }
        />
        {!userAccessData?.roleKey && (
          <TabItem
            label={localize(msgs.pam.sidePanel.asset)}
            value='2'
            icon={
              <Icon
                kind='AssetTargetSystem'
                size='medium'
                color='neutral-1000'
              />
            }
          />
        )}
        {hasRole && (
          <TabItem
            label={localize(msgs.pam.sidePanel.role)}
            value='3'
            icon={<Icon kind='Role' size='medium' color='neutral-1000' />}
          />
        )}
        {hasEntitlements && (
          <TabItem
            label={localize(msgs.pam.sidePanel.entitlements)}
            value='4'
            icon={
              <Icon kind='Entitlement' size='medium' color='neutral-1000' />
            }
          />
        )}
      </TabGroup>
      <TabContent
        className='SpvPendingSession-tabContent'
        value={activeTab}
        tabValue='1'>
        {hasRequestDetailsFields && (
          <SpvSection
            title={localize(msgs.pam.sidePanel.requestDetails)}
            fields={[
              {
                label: localize(msgs.pam.sidePanel.requestStatus),
                value: requestStatus,
              },
              {
                label: localize(msgs.pam.sidePanel.justification),
                value: requestDetails?.endpoints?.[0]?.businessJustification,
              },
              // TODO: update to not use mock tcodes when applicable
              {
                label: localize(msgs.pam.sidePanel.sapTransactionCodes),
                value:
                  requestDetails?.codes?.split(',').join(', ') ||
                  pendingSession?.codes?.split(',').join(', '),
              },
              {
                label: localize(msgs.pam.sidePanel.requestId),
                value: pendingSession?.requestId,
              },
              {
                label: localize(msgs.pam.sidePanel.requestTime),
                value: formatLocalizedDateTime(
                  `${requestDetails?.requestDate}Z`
                ),
              },
              {
                label: localize(msgs.pam.sidePanel.assignedTo),
                value: getAssignees(),
              },
              {
                label: localize(msgs.pam.sidePanel.startTime),
                value: formatLocalizedDateTime(
                  pendingSession?.sessionStartDate
                ),
              },
              {
                label: localize(msgs.pam.sidePanel.endTime),
                value: formatLocalizedDateTime(pendingSession?.sessionEndDate),
              },
              {
                label: localize(msgs.pam.sidePanel.ticketNumber),
                value: requestDetails?.ticketId,
              },
            ]}
          />
        )}
        {showRemoteAppName && (
          <SpvSection
            className={
              hasRequestDetailsFields && 'SpvPendingSession-tabSection'
            }
            title={localize(msgs.pam.sidePanel.remoteApplication)}
            fields={[
              {
                label: localize(msgs.pam.sidePanel.name),
                value: remoteAppName,
              },
            ]}
          />
        )}
        {hasSessionAccountFields && !hasRole && (
          <SpvSection
            className={
              accountSectionHasDivider && 'SpvPendingSession-tabSection'
            }
            title={localize(msgs.pam.sidePanel.account)}
            fields={[
              {
                label: localize(msgs.pam.sidePanel.name),
                value: pendingSession?.accountName,
              },
              {
                label: localize(msgs.pam.sidePanel.platform),
                value: accountDetails?.platformType,
              },
              {
                label: localize(msgs.pam.sidePanel.description),
                value:
                  accountDetails?.description ||
                  userAccessData?.accountDescription,
              },
              {
                label: localize(msgs.pam.sidePanel.accountType),
                value: accountDetails?.pamAccountType,
              },
              {
                label: localize(msgs.pam.sidePanel.authenticationMechanism),
                value: accountDetails?.accountConfig?.authenticationType,
              },
            ]}
          />
        )}
        {hasPendingRequestComments && (
          <SpvSection
            className={
              commentsSectionHasDivider && 'SpvPendingSession-tabSection'
            }
            title={localize(msgs.pam.sidePanel.comments)}
            fields={renderComments()}
          />
        )}
        {hasPendingRequestAttachments && (
          <SpvSection
            className={
              attachmentsSectionHasDivider && 'SpvPendingSession-tabSection'
            }
            title={localize(msgs.pam.sidePanel.attachments)}>
            <Box className='SpvPendingSession-downloadFile'>
              {requestDetails?.attachments.map((attachment) => {
                const fileUrl = getFileDownloadApiUrl(
                  cookies.get('user_name'),
                  attachment.attachmentKey
                );

                return (
                  <DownloadFile
                    fileName={attachment.fileName}
                    fileSize={attachment.size}
                    fileUrl={fileUrl}
                  />
                );
              })}
            </Box>
          </SpvSection>
        )}
      </TabContent>
      {!userAccessData?.roleKey && (
        <TabContent
          className='SpvPendingSession-assetTab'
          value={activeTab}
          tabValue='2'>
          <SpvEndpoint data={endpointDetails} />
        </TabContent>
      )}
      {hasRole && (
        <TabContent
          className='SpvPendingSession-tabContent'
          value={activeTab}
          tabValue='3'>
          <SpvRole data={userAccessData} roleDetails={roleDetails} />
        </TabContent>
      )}
      {hasEntitlements && (
        <TabContent
          className='SpvPendingSession-tabContent'
          value={activeTab}
          tabValue='4'>
          <SpvEntitlements
            data={userAccessData}
            initialEntitlements={entitlements}
          />
        </TabContent>
      )}
    </section>
  );
}

SpvPendingSession.propTypes = {
  userAccessData: ModuleDetails,
  accountDetails: ModuleDetails,
  endpointDetails: ModuleDetails,
  pendingSession: Session,
  requestDetails: RequestDetails,
  roleDetails: RoleDetails,
  entitlements: PropTypes.arrayOf(Entitlement),
  dataTestId: PropTypes.string,
  className: PropTypes.string,
};

SpvPendingSession.defaultProps = {
  userAccessData: null,
  accountDetails: null,
  endpointDetails: null,
  pendingSession: null,
  requestDetails: null,
  roleDetails: null,
  entitlements: null,
  dataTestId: null,
  className: null,
};

export default SpvPendingSession;
