export const lists = (intl) => [
  {
    duration: 'days',
    durationLabel: intl.formatMessage({ id: 'Admin.Log.Viewer.days' }),
    value: 0,
    flag: true,
    label: intl.formatMessage({ id: 'Admin.Log.Viewer.today' }),
  },
  // {
  //   duration: 'days',
  //   durationLabel: intl.formatMessage({ id: 'Admin.Log.Viewer.days' }),
  //   value: 7,
  //   flag: true,
  //   label: `${intl.formatMessage({ id: 'Admin.Log.Viewer.this' })} ${intl.formatMessage({
  //     id: 'Admin.Log.Viewer.week',
  //   })}`,
  // },
  {
    duration: 'minutes',
    durationLabel: intl.formatMessage({ id: 'Admin.Log.Viewer.minutes' }),
    value: 15,
  },
  {
    duration: 'minutes',
    durationLabel: intl.formatMessage({ id: 'Admin.Log.Viewer.minutes' }),
    value: 30,
  },
  {
    duration: 'hours',
    durationLabel: intl.formatMessage({ id: 'Admin.Log.Viewer.hours' }),
    value: 1,
  },
  {
    duration: 'hours',
    durationLabel: intl.formatMessage({ id: 'Admin.Log.Viewer.hours' }),
    value: 24,
  },
  {
    duration: 'days',
    durationLabel: intl.formatMessage({ id: 'Admin.Log.Viewer.days' }),
    value: 7,
  },
];

export const defaultDateTimeQuery = (intl) => lists(intl)[1];

const services = [
  { name: 'ECM', value: 'ecm' },
  { name: 'Jobs', value: 'ecm-worker' },
  { name: 'Request', value: 'arsms' },
  { name: 'User', value: 'userms' },
  { name: 'Identity warehouse', value: 'idwms' },
  { name: 'Restart', value: 'bouncer' },
  { name: 'Gateway', value: 'gateway' },
  { name: 'Connector', value: 'connectorms' },
  { name: 'Windows Connector', value: 'windows-connectorms' },
];

export const serviceNamesMapping = (isAuthMSEnabled = false, isPAMServicesEnabled = false, isTaskRunnerMSEnabled = false) => {
  // eslint-disable-next-line prefer-const

  if (isAuthMSEnabled && !services.some(cred => cred.name === 'Authentication')) {
    services.push({ name: 'Authentication', value: 'authms' });
  }
  if (isTaskRunnerMSEnabled && !services.some(cred => cred.name === 'Custom Extensions') ) {
    services.push({ name: 'Custom Extensions', value: 'taskrunnerms' });
  }
  let newServices = [...services];

  if (isPAMServicesEnabled) {
    newServices.push({ name: 'PAM', value: 'pamms' });
    newServices.push({ name: 'RDP gateway', value: 'rdp-gateway' });
    newServices.push({ name: 'SSH gateway', value: 'ssh-gateway' });
  }

return newServices;
};



export const sequentialLogsDuration = 60;
export const noServicesSelected = 'none';
