import { DynamicModuleLoader } from 'redux-dynamic-modules';
import * as React from 'react';
import VersionPage from './containers/VersionPage';
import getVersionModule from './redux/version-module';

export default function Dynamic() {
  return (
    <DynamicModuleLoader modules={[getVersionModule()]}>
      <VersionPage />
    </DynamicModuleLoader>
  );
}
