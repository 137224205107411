import * as C from './constants'

export const requestVersionList = () => {
  return {
  type: C.REQUEST_VERSION_LIST,
}};

export const requestVersionListSuccess = response => ({
  type: C.REQUEST_VERSION_LIST_SUCCESS,
  response,
});

export const requestVersionListFailure = failure => ({
  type: C.REQUEST_VERSION_LIST_FAILURE,
  failure,
});

export const fetchECMVersion = () => ({
  type: C.FETCH_ECM_VERSION
});

export const fetchECMVersionSucces = (resp) => ({
  type: C.FETCH_ECM_VERSION_SUCCESS,
  version: resp.version,
});

export const fetchECMVersionFailed = (data) => ({
  type: C.FETCH_ECM_VERSION_FAILED,
  data,
});

export const updateUIVersion = (version) => ({
  type: C.UPDATE_UI_VERSION,
  version
});
