import React from 'react';
import { Logger } from '@saviynt/common';

import IFrameLoader from '../../shared/components/iframe-loader';
import ISPM_CONSTANTS from '../../shared/services/constants';

/**
 * Dashboard Component
 *
 * contains Apache superset dashboard and Chat overlay
 * @returns {JSX.Element} rendered dashboard component with chat overlay
 */
function Dashboard() {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  Logger.info('Rendering Dashboard Component');

  return (
    <main className='dashboard-page'>
      <IFrameLoader
        iframeURL={ISPM_CONSTANTS.IFRAME_URL}
        title='Apache superset dashboard'
      />
    </main>
  );
}

export default Dashboard;
