import React, { useEffect, useState, useRef } from 'react';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
// import MenuIcon from '@material-ui/icons/Menu';
import List from '@material-ui/core/List';
import { useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import SideMenuIcon from '../../ECMv6/assets/SideMenuIcon';
import ResponsiveBackIcon from '../../ECMv6/assets/ResponsiveBackIcon';
import Logo from '../Logo/Logo';
import SearchBar from '../SearchBar/SearchBar';
import { homePageUrl } from '../../Constants';
import ChildMenu from '../ChildMenu/ChildMenu';
import SearchResults from '../SearchResults/SearchResults';
import { Footer } from '../Footer';
import SideMenuIconBack from '../../ECMv6/assets/SideMenuIconBack';
import SideMenuSmallIcon from '../../ECMv6/assets/SideMenuSmallIcon';
import { useOutsideClickEffect } from '@saviynt/common';
import { testIds as t } from 'ui-common/src/constants';

const SideMenu = ({
  classes,
  expandSideMenu,
  collapseSideMenu,
  handleDrawerClick,
  location,
  isSideMenuAvailable,
  handleDrawerOpen,
  handleDrawerClose,
  mobileDrawer,
  handleMobileDrawerClick,
  showFooter,
  slideDuration,
  handleSearchInactive,
}) => {
  const IsMobileDevice = useMediaQuery('(max-width:480px)');
  const data = useSelector((state) => state.app.sideMenu);
  const sideMenuLoading = useSelector((state) => state.app.sideMenuLoading);
  const [topLevelMenuOpen, setTopLevelMenuOpen] = useState(false);
  const isMenuPinned = useSelector((state) => state.user?.isMenuPinned);

  // to capture menu id when user browses through side menu, but does not click on link
  const [selectedMenuId, setSelectedMenuId] = useState('');

  const [searchData, setSearchData] = useState(data);
  const [searchKey, setSearchKey] = useState('');
  const [isSearchActive, setIsSearchActive] = useState(false);

  // when unpinning, mouseenter behavior is disabled until the menu is fully closed
  const [isMenuClosing, setIsMenuClosing] = useState(false);

  const [isClickedOutside, setIsClickedOutside] = useState(false);

  const [isCursorOutside, setIsCursorOutside] = useState(false);

  const navElRef = useRef(null);

  const sideMenuRef = useRef(null);

  const handleReset = () => {
    setIsSearchActive(false);
    setSearchData(data);
    setSearchKey('');

    if (location === homePageUrl) {
      setTopLevelMenuOpen(false);
    }
  };

  useEffect(() => {
    if (location !== homePageUrl) {
      setTopLevelMenuOpen(true);
    }
  }, [location]);

  // for handling expand/collapse of level 1 menu on hover off
  useEffect(() => {
    if ((isCursorOutside && isClickedOutside) || collapseSideMenu) {
      setTopLevelMenuOpen(true);
      setSelectedMenuId('');
    }
    if (collapseSideMenu && isSearchActive && !expandSideMenu) {
      const timeoutID = setTimeout(() => {
        handleReset();
      }, 5000);
      return () => {
        clearTimeout(timeoutID);
      };
    }
  }, [collapseSideMenu, isClickedOutside]);

  // for handling expand/collapse of level 1 menu on hover in when in homepage
  useEffect(() => {
    if (location === homePageUrl && collapseSideMenu && !isSearchActive) {
      setTopLevelMenuOpen(false);
    }
  }, [expandSideMenu]);

  useEffect(() => {
    if (isSearchActive) {
      if (navElRef.current) {
        navElRef.current.scrollTop = 0;
      }
    } else {
      handleSearchInactive();
    }
  }, [isSearchActive]);

  const handleBackClick = (menuId) => {
    setTopLevelMenuOpen(!topLevelMenuOpen);
    setSelectedMenuId(menuId);
  };

  const handleSearchData = (searchResult, isSearch) => {
    if (location === homePageUrl) {
      if (isSearch) {
        setTopLevelMenuOpen(true);
      } else {
        setTopLevelMenuOpen(false);
      }
    }
    setIsSearchActive(isSearch);
    setSearchData(searchResult);
  };

  const handleMouseEnter = () => {
    if (!isMenuClosing) {
      handleDrawerOpen();
    }
    setIsCursorOutside(false);
    setIsClickedOutside(false);
  };

  const handleMouseLeave = () => {
    handleDrawerClose();
    setIsCursorOutside(true);
    setIsClickedOutside(false);
  };

  const handleButtonClick = () => {
    if (isMenuPinned) {
      setIsMenuClosing(true);
      setTimeout(() => {
        setIsMenuClosing(false);
      }, slideDuration);
    }
    handleDrawerClick();
  };

  const handleClickOutside = () => {
    setIsClickedOutside(true);
  };

  useOutsideClickEffect(sideMenuRef, handleClickOutside, true);

  if (data && !sideMenuLoading) {
    return IsMobileDevice ? (
      <Drawer
        open={mobileDrawer}
        onClose={handleMobileDrawerClick}
        classes={{
          paper: classes.mobileDrawer,
        }}
        data-testid={`${t.SIDE_MENU}`}
      >
        <div className={expandSideMenu ? classes.toolbar : classes.toolbarMobileCentered}>
          <Logo isSideMenuAvailable={isSideMenuAvailable} isWhite isSideNav />{' '}
          <IconButton
            onClick={handleMobileDrawerClick}
            className={expandSideMenu ? classes.menuButtonExpanded : classes.menuButton}
          >
            <ResponsiveBackIcon htmlColor="var(--SIDEMENU-ICON-HAMBURGER)" />
          </IconButton>
        </div>
        <Divider style={{ 'background-color': 'transparent' }} />
        <SearchBar
          data={data}
          handleSearchData={handleSearchData}
          open
          handleReset={handleReset}
          searchKey={searchKey}
          setSearchKey={setSearchKey}
        />
        <List component="nav" className={classes.navBar} ref={navElRef}>
          <SearchResults
            isSearchActive={isSearchActive ? expandSideMenu : false}
            searchData={searchData}
            classes={classes}
            expandSideMenu={expandSideMenu}
            handleReset={handleReset}
          >
            <ChildMenu
              data={searchData}
              selected={location}
              handleBackClick={handleBackClick}
              topLevelMenuOpen={topLevelMenuOpen}
              expandSideMenu
              collapseSideMenu={collapseSideMenu}
              selectedMenuId={selectedMenuId}
              isSearchActive={isSearchActive}
            />
          </SearchResults>
        </List>
        <Footer classes={classes} expandSideMenu={expandSideMenu} />
      </Drawer>
    ) : (
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: expandSideMenu,
          [classes.drawerClose]: !expandSideMenu,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: expandSideMenu,
            [classes.drawerClose]: !expandSideMenu,
          }),
        }}
        style={{
          // background: getBackgroundColor(),
          background: 'var(--SIDEMENU-BACKGROUND)',
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        data-testid={`${t.SIDE_MENU}`}
      >
        <div
          className={expandSideMenu ? classes.toolbar : classes.toolbarCentered}
          ref={sideMenuRef}
        >
          {expandSideMenu ? (
            <>
              <Logo
                isSideMenuAvailable={isSideMenuAvailable}
                isWhite
                isSideNav
                testId={t.SIDE_MENU_LOGO}
              />{' '}
              <IconButton
                onClick={handleButtonClick}
                className={classes.sideMenuIcon}
                data-testid={t.SIDE_MENU_PIN_BUTTON}
              >
                {isMenuPinned ? (
                  <SideMenuIconBack htmlColor="var(--SIDEMENU-ICON-HAMBURGER)" />
                ) : (
                  <SideMenuSmallIcon htmlColor="var(--SIDEMENU-ICON-HAMBURGER)" />
                )}
              </IconButton>
            </>
          ) : (
            <IconButton onClick={handleButtonClick} className={classes.menuButton}>
              <SideMenuIcon htmlColor="var(--SIDEMENU-ICON-HAMBURGER)" />
            </IconButton>
          )}
        </div>
        <Divider style={{ 'background-color': 'transparent' }} />
        <SearchBar
          data={data}
          handleSearchData={handleSearchData}
          open={expandSideMenu}
          handleReset={handleReset}
          searchKey={searchKey}
          setSearchKey={setSearchKey}
        />
        <List component="nav" className={classes.navBar} ref={navElRef}>
          <SearchResults
            isSearchActive={isSearchActive ? expandSideMenu : false}
            searchData={searchData}
            classes={classes}
            expandSideMenu={expandSideMenu}
            handleReset={handleReset}
          >
            <ChildMenu
              data={searchData}
              selected={location}
              handleBackClick={handleBackClick}
              topLevelMenuOpen={topLevelMenuOpen}
              expandSideMenu={expandSideMenu}
              collapseSideMenu={collapseSideMenu}
              selectedMenuId={selectedMenuId}
              isSearchActive={isSearchActive}
            />
          </SearchResults>
        </List>
        {showFooter && (
          <Footer classes={classes} expandSideMenu={expandSideMenu} isNotMobileFooter />
        )}
      </Drawer>
    );
  }

  return null;
};

export default SideMenu;
