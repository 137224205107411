import React, { type JSX } from 'react';
import { Chip, Icon, Typography } from '@saviynt/design-system';
import { differenceInDays, isPast } from 'date-fns';

import { useInvertedLocalize } from '../../../utilities';
import { type PendingRequest } from '../models';

import { invertedMessages } from './messages';
import RecentAssignmentRow from './RecentAssignmentRow';
import {
  type ConvertToUtc,
  type RecentAssignmentsInvertedLocalize,
} from './types';

import './RequestRow.css';

interface Props {
  req: PendingRequest;
  convertToUtc: ConvertToUtc;
}

export default function RequestRow({ req, convertToUtc }: Props): JSX.Element {
  const l = useInvertedLocalize(invertedMessages);

  return (
    <RecentAssignmentRow
      className='Nav_RequestRow'
      icon={<Icon kind='RequestApproval' size='large' color='teal-500' />}
      sourceAndPurpose={
        <div className='Nav_RequestRow-sourceAndPurpose'>
          <Typography
            className='Nav_RequestRow-sourceAndPurposeHeading'
            kind='body2-bold'>
            {l('Review Request')}
          </Typography>
          <Typography
            className='Nav_RequestRow-sourceAndPurposeId'
            kind='body2'>
            {req.requestid}
          </Typography>
        </div>
      }
      details={
        <div className='Nav_RequestRow-details'>
          <Typography className='Nav_RequestRow-detailsLabel' kind='body3'>
            {l('Type')}
          </Typography>
          <Typography className='Nav_RequestRow-detailsValue' kind='body2'>
            {req.requesttype}
          </Typography>
        </div>
      }
      subject1={
        <div className='Nav_RequestRow-subject1'>
          <Typography className='Nav_RequestRow-subject1Label' kind='body3'>
            {l('Requested by')}
          </Typography>
          <Typography className='Nav_RequestRow-subject1Value' kind='body2'>
            {req.requestor}
          </Typography>
        </div>
      }
      subject2={
        <div className='Nav_RequestRow-subject2'>
          <Typography className='Nav_RequestRow-subject2Label' kind='body3'>
            {l('Due')}
          </Typography>
          <Typography className='Nav_RequestRow-subject2Value' kind='body2'>
            {convertToUtc(req.duedate)}
          </Typography>
        </div>
      }
      assignmentStatus={assignmentStatus(l, req.duedate)}
    />
  );
}

function assignmentStatus(
  l: RecentAssignmentsInvertedLocalize,
  dueDate: Date
): JSX.Element | undefined {
  const size = 'small';
  const kind = 'subtleFilled';

  if (isPast(dueDate)) {
    return (
      <Chip size={size} kind={kind} color='critical' label={l('Overdue')} />
    );
  }

  const count = differenceInDays(dueDate, new Date());

  return (
    <Chip
      size={size}
      kind={kind}
      color={count <= 1 ? 'critical' : 'brandSecondary'}
      label={l('Due in {count} d', { count })}
    />
  );
}
