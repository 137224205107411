import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

export const ChevronDown = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.62204 6.16403C2.78476 6.00131 3.04858 6.00131 3.21129 6.16403L10 12.9527L16.7887 6.16403C16.9514 6.00131 17.2152 6.00131 17.378 6.16403C17.5407 6.32675 17.5407 6.59057 17.378 6.75329L10.2946 13.8366C10.1319 13.9993 9.86809 13.9993 9.70537 13.8366L2.62204 6.75329C2.45932 6.59057 2.45932 6.32675 2.62204 6.16403Z"
      fill="#254B86"
    />
  </svg>
);

export const Check = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.8537 5.96496C21.0489 6.16031 21.0487 6.47689 20.8534 6.67207L8.47894 19.0357C8.385 19.1296 8.25757 19.1822 8.12477 19.182C7.99197 19.1818 7.86471 19.1288 7.77105 19.0346L3.14551 14.3843C2.95076 14.1886 2.95161 13.872 3.14739 13.6772C3.34317 13.4825 3.65975 13.4833 3.85449 13.6791L8.12664 17.9741L20.1466 5.96465C20.3419 5.76947 20.6585 5.76961 20.8537 5.96496Z"
      fill="#00245B"
    />
  </svg>
);

export const Myself = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1025 3.50098C9.61726 3.50098 7.60254 5.5157 7.60254 8.00098C7.60254 10.4863 9.61726 12.501 12.1025 12.501C14.5878 12.501 16.6025 10.4863 16.6025 8.00098C16.6025 5.5157 14.5878 3.50098 12.1025 3.50098ZM8.60254 8.00098C8.60254 6.06798 10.1695 4.50098 12.1025 4.50098C14.0355 4.50098 15.6025 6.06798 15.6025 8.00098C15.6025 9.93397 14.0355 11.501 12.1025 11.501C10.1695 11.501 8.60254 9.93397 8.60254 8.00098Z"
      fill="#212328"
    />
    <path
      d="M5.93023 16.4584C9.66966 14.2306 14.329 14.2306 18.0685 16.4584C18.8524 16.9254 19.3327 17.7706 19.3327 18.6831V20.999C19.3327 21.2752 19.5565 21.499 19.8327 21.499C20.1088 21.499 20.3327 21.2752 20.3327 20.999V18.6831C20.3327 17.4182 19.6669 16.2467 18.5803 15.5993C14.5255 13.1837 9.4732 13.1837 5.41842 15.5993C4.33175 16.2467 3.66602 17.4182 3.66602 18.6831V20.999C3.66602 21.2752 3.88987 21.499 4.16602 21.499C4.44216 21.499 4.66602 21.2752 4.66602 20.999V18.6831C4.66602 17.7706 5.14629 16.9254 5.93023 16.4584Z"
      fill="#212328"
    />
  </svg>
);

export const SomeoneElse = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.57906 3.5C7.61082 3.5 6.01523 5.09558 6.01523 7.06383C6.01523 9.03208 7.61082 10.6277 9.57906 10.6277C11.5473 10.6277 13.1429 9.03208 13.1429 7.06383C13.1429 5.09558 11.5473 3.5 9.57906 3.5ZM7.01523 7.06383C7.01523 5.64787 8.1631 4.5 9.57906 4.5C10.995 4.5 12.1429 5.64787 12.1429 7.06383C12.1429 8.47979 10.995 9.62766 9.57906 9.62766C8.1631 9.62766 7.01523 8.47979 7.01523 7.06383Z"
      fill="#212328"
    />
    <path
      d="M5.65193 13.2013C8.00558 11.7991 10.9425 11.852 13.3106 13.2628C13.5479 13.4042 13.8548 13.3264 13.9961 13.0892C14.1374 12.852 14.0597 12.5451 13.8224 12.4037C11.1536 10.8138 7.82351 10.7435 5.14012 12.3422L4.7927 12.5491C3.68104 13.2114 3 14.4099 3 15.7038V21C3 21.2761 3.22386 21.5 3.5 21.5C3.77614 21.5 4 21.2761 4 21V15.7038C4 14.7622 4.49558 13.8901 5.30451 13.4082L5.65193 13.2013Z"
      fill="#212328"
    />
    <path
      d="M16.5 12.5C16.7761 12.5 17 12.7239 17 13V16.5H20.5C20.7761 16.5 21 16.7239 21 17C21 17.2761 20.7761 17.5 20.5 17.5H17V21C17 21.2761 16.7761 21.5 16.5 21.5C16.2239 21.5 16 21.2761 16 21V17.5H12.5C12.2239 17.5 12 17.2761 12 17C12 16.7239 12.2239 16.5 12.5 16.5H16V13C16 12.7239 16.2239 12.5 16.5 12.5Z"
      fill="#212328"
    />
  </svg>
);

export const MultiplePeople = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6327 3.5C12.7235 3.5 11.1758 5.0477 11.1758 6.95688C11.1758 8.86607 12.7235 10.4138 14.6327 10.4138C16.5418 10.4138 18.0895 8.86607 18.0895 6.95688C18.0895 5.0477 16.5418 3.5 14.6327 3.5ZM12.1758 6.95688C12.1758 5.59998 13.2758 4.5 14.6327 4.5C15.9896 4.5 17.0895 5.59998 17.0895 6.95688C17.0895 8.31378 15.9896 9.41377 14.6327 9.41377C13.2758 9.41377 12.1758 8.31378 12.1758 6.95688Z"
      fill="#212328"
    />
    <path
      d="M10.5363 13.097C13.1865 11.7024 16.3847 11.777 18.9762 13.3209C19.5162 13.6426 19.8471 14.2248 19.8471 14.8534V21.0002C19.8471 21.2763 20.0709 21.5002 20.3471 21.5002C20.6232 21.5002 20.8471 21.2763 20.8471 21.0002V14.8534C20.8471 13.8724 20.3308 12.9639 19.488 12.4618C16.6386 10.7643 13.1318 10.6586 10.2006 12.1449L9.82585 11.9217C8.03988 10.8577 5.81456 10.8577 4.0286 11.9217C3.48644 12.2447 3.1543 12.8292 3.1543 13.4602V19.2466C3.1543 19.5228 3.37815 19.7466 3.6543 19.7466C3.93044 19.7466 4.1543 19.5228 4.1543 19.2466V13.4602C4.1543 13.1815 4.30098 12.9234 4.5404 12.7808C5.98655 11.9192 7.78254 11.9049 9.24032 12.7378C8.62788 13.2615 8.26595 14.0321 8.26595 14.8534V21.0002C8.26595 21.2763 8.48981 21.5002 8.76595 21.5002C9.04209 21.5002 9.26595 21.2763 9.26595 21.0002V14.8534C9.26595 14.2248 9.59679 13.6426 10.1368 13.3209C10.2004 13.283 10.2644 13.246 10.3288 13.2098C10.405 13.1896 10.4764 13.1514 10.5363 13.097Z"
      fill="#212328"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.79899 8.25819C4.79899 7.05903 5.77111 6.08691 6.97027 6.08691C8.16944 6.08691 9.14155 7.05903 9.14155 8.25819C9.14155 9.45736 8.16944 10.4295 6.97027 10.4295C5.77111 10.4295 4.79899 9.45736 4.79899 8.25819ZM6.97027 7.08691C6.32339 7.08691 5.79899 7.61131 5.79899 8.25819C5.79899 8.90508 6.32339 9.42948 6.97027 9.42948C7.61715 9.42948 8.14155 8.90508 8.14155 8.25819C8.14155 7.61131 7.61715 7.08691 6.97027 7.08691Z"
      fill="#212328"
    />
  </svg>
);

const useStyles = makeStyles({
  inputRoot: {
    background: '#F7F9FC',
    border: '1px solid #CFD9E9',
    padding: '5px !important',
  },
  input: {
    color: '#00245B',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    textAlign: 'center',
    '&::placeholder': {
      opacity: 1,
      color: '#00245B',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px',
    },
  },
  option: {
    color: '#212328',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    '& > .option-icon': {
      marginRight: 10,
    },
    '&:hover': {
      backgroundColor: 'rgba(55, 81, 255, 0.08)',
    },
  },
});

const RequestingForDropdown = ({
  intl, username, options, onOptionChange,
}) => {
  const classes = useStyles();
  const [selectedOption, setSelectedOption] = useState({});

  const renderOptionIcon = (type) => {
    switch (true) {
      case type === 'Myself':
        return <Myself />;
      case type === 'Someone Else':
        return <SomeoneElse />;
      case type === 'Multiple People':
        return <MultiplePeople />;
      default:
        return null;
    }
  };

  const handleChange = (event, val) => {
    if (val) {
      onOptionChange(val);
      setSelectedOption(val);
    }
  };

  const getPlaceholder = () => {
    if (options.length > 0) {
      if (options[0].id === 'myself') {
        return `${options[0].label} (${username})`;
      }
      return `${options[0].label}`;
    }
    return '';
  };

  return (
    <div className="requestingForDropdown">
      <Autocomplete
        popupIcon={<ChevronDown />}
        id="requesting-dropdown"
        options={options}
        className="dropdown-options"
        classes={{
          option: classes.option,
          inputRoot: classes.inputRoot,
          input: classes.input,
        }}
        disablePortal
        disableClearable
        getOptionLabel={(option) => option.label}
        renderOption={(option) => (
          <>
            <span className="selected-icon">
              {selectedOption.label === option.label && <Check />}
            </span>
            <span className="option-icon">{renderOptionIcon(option.label)}</span>
            <span
              className={`option-value ${
                selectedOption.label === option.label ? 'option-value-selected' : ''
              }`}
            >
              {option.label}
            </span>
          </>
        )}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={getPlaceholder()}
            inputProps={{
              ...params.inputProps,
              readOnly: true,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
          />
        )}
      />
    </div>
  );
};

RequestingForDropdown.propTypes = {
  intl: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  username: PropTypes.string.isRequired,
  options: PropTypes.shape({
    label: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  onOptionChange: PropTypes.func.isRequired,
};

export default injectIntl(RequestingForDropdown);
