/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  debounce,
  localize,
  pluralizeString,
  SECONDS_PER_DAY,
  SECONDS_PER_HOUR,
  SECONDS_PER_MINUTE,
} from '@saviynt/common';
import {
  Box,
  Icon,
  InlineMessage,
  InputField,
  MenuInput,
  Typography,
} from '@saviynt/design-system';
import PropTypes from 'prop-types';

import { DurationMenuOption } from '../../../../models/PamModels';
import { INPUT_DEBOUNCE_DELAY } from '../../../../utilities/debounce';
import { INTERVALS, msgs } from '../../constants';

import ByTypeDateAndTimePicker from './ByTypeDateAndTimePicker';
import getCheckedInterval from './getCheckedInterval';

function CustomDuration({
  isNowTab,
  isDurationCustom,
  customDurationInputValue,
  setCustomDurationInputValue,
  durationChipValue,
  checkedIntervalOption,
  setCheckedIntervalOption,
  intervalOptions,
  setIntervalOptions,
  maxRequestTimeAsNumber,
  rangeMaxDate,
  futureTabStartDate,
  setFutureTabStartDate,
  futureTabEndDate,
  setFutureTabEndDate,
  isCustomOutOfRange,
  isExpandedDuration,
}) {
  const [debouncedIsCustomOutOfRange, setDebouncedIsCustomOutOfRange] =
    useState(isCustomOutOfRange);
  const intl = useIntl();

  const INVALID_INTERVAL_TEXT = localize(
    intl,
    msgs.pam.modalPage.customDuration.invalidInterval
  );
  const CUSTOM_INPUT_LABEL_TEXT = localize(
    intl,
    msgs.pam.modalPage.customDuration.inputLabel
  );
  const CUSTOM_INPUT_PLACEHOLDER_TEXT = localize(
    intl,
    msgs.pam.modalPage.customDuration.inputPlacholder
  );
  const customRangeCriticalMessage = (date) =>
    localize(intl, msgs.pam.modalPage.critical.customRangeCritical, { date });

  // If the interval changes reset the Input
  useEffect(() => {
    if (isDurationCustom && isExpandedDuration) {
      const checkedOption = getCheckedInterval(intervalOptions);

      setCustomDurationInputValue('');
      setCheckedIntervalOption(checkedOption);
    }
  }, [intervalOptions]);

  // Effect to debounce isCustomOutOfRange changes
  const debouncedCheck = useCallback(
    debounce((isOutOfRange) => {
      setDebouncedIsCustomOutOfRange(isOutOfRange);
    }, INPUT_DEBOUNCE_DELAY),
    []
  );

  useEffect(() => {
    if (isCustomOutOfRange) {
      debouncedCheck(isCustomOutOfRange);
    } else {
      debouncedCheck.cancel();
      setDebouncedIsCustomOutOfRange(false);
    }

    return () => debouncedCheck.cancel();
  }, [isCustomOutOfRange, debouncedCheck]);

  const handleIntervalChange = (selectedOption) => {
    setIntervalOptions((currentOptions) =>
      currentOptions.map((option) => ({
        ...option,
        isChecked: option.value === selectedOption.value,
      }))
    );
  };

  const handleCustomInputChange = (event) => {
    const input = event.target.value;

    if (input === '') {
      setCustomDurationInputValue('');

      return;
    }

    const positiveIntOrFloatRegex = /^\d*(\.\d{0,2})?$/;

    if (positiveIntOrFloatRegex.test(input)) {
      setCustomDurationInputValue(input);
    }
  };

  const parseInlineMaxTime = () => {
    const days = Math.floor(maxRequestTimeAsNumber / SECONDS_PER_DAY);
    const hours = Math.floor(
      (maxRequestTimeAsNumber % SECONDS_PER_DAY) / SECONDS_PER_HOUR
    );
    const minutes = Math.floor(
      (maxRequestTimeAsNumber % SECONDS_PER_HOUR) / SECONDS_PER_MINUTE
    );
    const totalMinutes = Math.floor(
      maxRequestTimeAsNumber / SECONDS_PER_MINUTE
    );
    const totalHours = Math.floor(maxRequestTimeAsNumber / SECONDS_PER_HOUR);

    let timeString = '';

    switch (checkedIntervalOption) {
      case INTERVALS.MINUTES:
        timeString = `${totalMinutes} ${pluralizeString(
          'minute',
          totalMinutes !== 1
        )}`;
        break;
      case INTERVALS.HOURS:
        timeString = `${totalHours} ${pluralizeString(
          'hour',
          totalHours !== 1
        )}`;

        if (minutes > 0) {
          timeString += ` ${minutes} ${pluralizeString(
            'minute',
            minutes !== 1
          )}`;
        }

        break;
      case INTERVALS.DAYS:
        timeString = `${days} ${pluralizeString('day', days !== 1)}`;

        if (hours > 0) {
          timeString += ` ${hours} ${pluralizeString('hour', hours !== 1)}`;
        }

        if (minutes > 0) {
          timeString += ` and ${minutes} ${pluralizeString(
            'minute',
            minutes !== 1
          )}`;
        }

        break;
      default:
        timeString = INVALID_INTERVAL_TEXT;
    }

    return timeString.trim();
  };

  if (isDurationCustom) {
    return (
      <section className='MpaTimeAccessDuration-customDuration'>
        <Box className='MpaTimeAccessDuration-inputFields'>
          <Box className='MpaTimeAccessDuration-enterNumberInput'>
            <InputField
              type='text'
              inputmode='numeric'
              placeholder={CUSTOM_INPUT_PLACEHOLDER_TEXT}
              name='Interval Number'
              label={
                <Typography kind='label' htmlFor='Custom duration'>
                  {CUSTOM_INPUT_LABEL_TEXT}
                </Typography>
              }
              backgroundColor='secondary'
              value={customDurationInputValue}
              onChange={handleCustomInputChange}
              isCritical={debouncedIsCustomOutOfRange}
              CriticalHelperText={
                <InlineMessage
                  text={customRangeCriticalMessage(parseInlineMaxTime())}
                  colorTheme='critical'
                  size='small'
                  leftIcon={<Icon kind='AlertCritical' color='critical-700' />}
                />
              }
            />
          </Box>
          <Box className='MpaTimeAccessDuration-enterNumberInput MpaTimeAccessDuration-MenuInput'>
            <MenuInput
              options={intervalOptions}
              onChange={handleIntervalChange}
              backgroundColor='secondary'
              isCritical={debouncedIsCustomOutOfRange}
              // CriticalHelperText text is left blank to help align the MenuInput with InputField when the isCustomUnderFiveMin is true
              CriticalHelperText={
                <InlineMessage
                  leftIcon={<div />}
                  text=''
                  colorTheme='critical'
                  size='small'
                />
              }
              isReadOnly
            />
          </Box>
        </Box>
      </section>
    );
  }

  if (futureTabStartDate && futureTabEndDate) {
    return (
      <Box className='MpaTimeAccessDuration-inputFields'>
        <ByTypeDateAndTimePicker
          type='end'
          isNowTab={isNowTab}
          futureTabStartDate={futureTabStartDate}
          setFutureTabStartDate={setFutureTabStartDate}
          futureTabEndDate={futureTabEndDate}
          setFutureTabEndDate={setFutureTabEndDate}
          isDurationCustom={isDurationCustom}
          rangeMaxDate={rangeMaxDate}
          durationChipValue={durationChipValue}
        />
      </Box>
    );
  }

  return null;
}

CustomDuration.propTypes = {
  isNowTab: PropTypes.bool.isRequired,
  isDurationCustom: PropTypes.bool.isRequired,
  customDurationInputValue: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  setCustomDurationInputValue: PropTypes.func.isRequired,
  durationChipValue: PropTypes.number,
  checkedIntervalOption: PropTypes.string.isRequired,
  setCheckedIntervalOption: PropTypes.func.isRequired,
  intervalOptions: PropTypes.arrayOf(DurationMenuOption).isRequired,
  setIntervalOptions: PropTypes.func.isRequired,
  maxRequestTimeAsNumber: PropTypes.number.isRequired,
  rangeMaxDate: PropTypes.instanceOf(Date).isRequired,
  futureTabStartDate: PropTypes.instanceOf(Date),
  setFutureTabStartDate: PropTypes.func.isRequired,
  futureTabEndDate: PropTypes.instanceOf(Date),
  setFutureTabEndDate: PropTypes.func.isRequired,
  isCustomOutOfRange: PropTypes.bool.isRequired,
  isExpandedDuration: PropTypes.bool.isRequired,
};

CustomDuration.defaultProps = {
  customDurationInputValue: '',
  durationChipValue: null,
  futureTabStartDate: null,
  futureTabEndDate: null,
};

export default CustomDuration;
