import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import { ShowMessageModal } from 'ui-common/src/utils/ResponseHandler-actions';
import AppLogo from 'ui-home/src/features/MainLayout/Header/AppLogo/AppLogo';
import DynamicModal from './Components/DynamicModal/DynamicModal';
import { deleteLogo, getLogoToShow } from '../../redux/adminHome-actions';
import { loadImage } from './utils/helper';
import classes from './BrandingConfig.module.scss';

const LogoCard = ({
  name,
  setIsDoneDisabled,
  selectionCallback,
  logoPopupMode,
  selectedLogoName,
  intl,
}) => {
  const dispatch = useDispatch();
  const logoToShow = useSelector((state) => state.adminHome?.brandingConfig?.logoToShow);
  const [imageSrc, setImageSrc] = useState('');
  const [showDeleteLogoModal, setShowDeleteLogoModal] = useState(false);
  const [selectedLogo, setSelectedLogo] = useState('');
  const [modalType, setModalType] = useState('');
  const deletedLogoError = useSelector(
    (state) => state.adminHome?.brandingConfig?.deletedLogoError
  );
  const deleteLogoSuccess = useSelector(
    (state) => state.adminHome?.brandingConfig?.deleteLogoSuccess
  );
  const getImageSource = () => {
    const dynamicDataUrl = loadImage(logoToShow[name]);
    return `${dynamicDataUrl}`;
  };
  const MODAL_ACTIONS = {
    deleteLogo: {
      primaryAction: () => dispatch(deleteLogo(name)),
      secondaryAction: () => setShowDeleteLogoModal(false),
    },
  };

  useEffect(() => {
    if (deletedLogoError) {
      setShowDeleteLogoModal(false);
      setIsDoneDisabled(true);
    }
  }, [deletedLogoError]);

  useEffect(() => {
    if (deleteLogoSuccess) {
      setIsDoneDisabled(true);
      setShowDeleteLogoModal(false);
    }
  }, [deleteLogoSuccess]);

  useEffect(() => {
    if (!logoToShow[name]) {
      dispatch(getLogoToShow({ name }));
    }
  }, []);

  useEffect(() => {
    if (logoToShow[name]) {
      const imageSrcTemp = getImageSource();
      setImageSrc(imageSrcTemp);
    }
  }, [logoToShow[name]]);

  const onSelectedLogoDelete = (e) => {
    setShowDeleteLogoModal(true);
    setModalType('deleteLogo');
  };

  const onSelectedLogoClick = (e, name) => {
    setIsDoneDisabled(false);
    selectionCallback && selectionCallback(name, logoPopupMode);
  };

  return (
    <>
      <div className={`${classes.logoCardWithName}`}>
        <div
          tabIndex="-1"
          className={`${classes.logoCard}  ${selectedLogoName === name && classes.selected}`}
          onClick={(e) => onSelectedLogoClick(e, name)}
        >
          <div
            onClick={(e) => {
              onSelectedLogoDelete(e, name);
            }}
            className={classes.logoDelete}
          />
          <AppLogo src={imageSrc} isSideNav />
        </div>
        <div className={classes.logoSelectName}>{name}</div>
      </div>
      {showDeleteLogoModal && modalType && (
        <DynamicModal
          isOpen={showDeleteLogoModal}
          onClose={() => setShowDeleteLogoModal(false)}
          modalType={modalType}
          selectedVariant={selectedLogo}
          onPrimaryAction={MODAL_ACTIONS[modalType].primaryAction}
          onSecondaryAction={MODAL_ACTIONS[modalType].secondaryAction}
          intl={intl}
        />
      )}
    </>
  );
};

export default injectIntl(LogoCard);

// todo proptypes validation
