import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import {
  Entitlement,
  ModuleDetails,
  RequestDetails,
  RoleDetails,
  SessionDetails,
} from '../../../../../models/PamModels';

import SpvActiveSession from './Active/SpvActiveSession';
import SpvFutureApprovedSession from './FutureApproved/SpvFutureApprovedSession';
import SpvPendingSession from './Pending/SpvPendingSession';

import './SpvSession.css';

function SpvSession({
  data,
  session,
  sessionDetails,
  accountDetails,
  endpointDetails,
  requestDetails,
  roleDetails,
  entitlements,
  dataTestId,
  className,
}) {
  const classes = classnames('SpvSession', className);

  const isPendingSession = !data.sessionStatus && requestDetails;
  const isFutureApprovedSession = data.sessionStatus === 1 && requestDetails;
  const isActiveSession = data.sessionStatus === 2 && sessionDetails;

  const renderSpvSession = () => {
    // Pending Session
    if (isPendingSession) {
      return (
        <SpvPendingSession
          userAccessData={data}
          accountDetails={accountDetails}
          endpointDetails={endpointDetails}
          pendingSession={session}
          requestDetails={requestDetails}
          roleDetails={roleDetails}
          entitlements={entitlements}
        />
      );
    }

    // Future Approved Session
    if (isFutureApprovedSession) {
      return (
        <SpvFutureApprovedSession
          userAccessData={data}
          accountDetails={accountDetails}
          endpointDetails={endpointDetails}
          pendingSession={session}
          requestDetails={requestDetails}
          roleDetails={roleDetails}
          entitlements={entitlements}
        />
      );
    }

    // Active Session
    if (isActiveSession) {
      return (
        <SpvActiveSession
          userAccessData={data}
          accountDetails={accountDetails}
          endpointDetails={endpointDetails}
          session={session}
          sessionDetails={sessionDetails}
          roleDetails={roleDetails}
          entitlements={entitlements}
        />
      );
    }

    return (
      <SpvActiveSession
        userAccessData={data}
        accountDetails={accountDetails}
        endpointDetails={endpointDetails}
        roleDetails={roleDetails}
        entitlements={entitlements}
        session={session}
      />
    );
  };

  return (
    <div className={classes} data-testid={dataTestId}>
      {renderSpvSession()}
    </div>
  );
}

SpvSession.propTypes = {
  data: ModuleDetails,
  session: ModuleDetails,
  accountDetails: ModuleDetails,
  endpointDetails: ModuleDetails,
  sessionDetails: SessionDetails,
  requestDetails: RequestDetails,
  roleDetails: RoleDetails,
  entitlements: PropTypes.arrayOf(Entitlement),
  dataTestId: PropTypes.string,
  className: PropTypes.string,
};

SpvSession.defaultProps = {
  data: null,
  session: null,
  accountDetails: null,
  endpointDetails: null,
  sessionDetails: null,
  requestDetails: null,
  roleDetails: null,
  entitlements: null,
  dataTestId: null,
  className: null,
};

export default SpvSession;
