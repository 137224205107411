import React from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import MuiAvatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';
import AvatarLogo from '../AvatarLogo';
import styles from './BreadcrumbNav.module.scss';

function handleClick(e) {
  e.preventDefault();
}

const Avatar = withStyles({
  root: {
    backgroundColor: '#fff',
  },
  img: {
    width: '71%',
    borderRadius: 0,
    objectFit: 'contain',
  },
})(MuiAvatar);

const InactiveLink = ({ anchorText, link }) => {
  return (
    <Link to={link} className={styles.link}>
      <div style={{ marginRight: 20, opacity: 0.8 }}>
        <svg height="16" width="10" className="icon-color" style={{ fill: '#646464' }}>
          <path
            d="M7.948 16.654L.243 9.077a.805.805 0 0 1 0-1.154L7.948.346a1.217 1.217 0 0 1 1.7 0 1.17 1.17 0 0 1 0 1.672L3.058 8.5l6.59 6.481a1.171 1.171 0 0 1 0 1.673 1.217 1.217 0 0 1-1.7 0"
            fill
          />
        </svg>
      </div>
    </Link>
  );
};

const ActiveLink = ({ anchorText, link }) => {
  return (
    <Link to={link} onClick={handleClick} aria-current="page" className={styles.link}>
      <AvatarLogo connectionType={anchorText} />
      {/* <span>
        <Avatar
          src={`/ECMv6/assets/images/${anchorText.split('-')[0].toLowerCase()}_logo.png`}
          alt={anchorText}
        />
      </span> */}
      {/* <span className={styles.active}>{anchorText}</span> */}
    </Link>
  );
};

const BreadcrumbNav = ({ navPath }) => {
  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb" separator="" classes={{ separator: styles.separator }}>
        {navPath.map((e, i) =>
          i !== navPath.length - 1 ? <InactiveLink {...e} /> : <ActiveLink {...e} />
        )}
      </Breadcrumbs>
    </div>
  );
};

BreadcrumbNav.propTypes = {
  navPath: PropTypes.arrayOf(
    PropTypes.shape({
      anchorText: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    })
  ).isRequired,
};

InactiveLink.propTypes = {
  anchorText: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

ActiveLink.propTypes = {
  anchorText: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default BreadcrumbNav;
