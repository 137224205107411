import React from 'react';
import { useLocalize } from '@saviynt/common';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { ModuleDetails } from '../../../../../../../models/PamModels';
import { msgs } from '../../../../../constants';
import SpvSection from '../../../../SpvSection/SpvSection';

import './SpvApplication.css';

function SpvApplication({ data, dataTestId, className }) {
  const classes = classnames('SpvApplication', className);
  const localize = useLocalize();

  const hasSummaryFields = data.connectionType || data.description;
  const hasSystemDetailsFields = data.customproperty10;

  return (
    <div className={classes} data-testid={dataTestId}>
      {hasSummaryFields && (
        <SpvSection
          title={localize(msgs.pam.sidePanel.summary)}
          fields={[
            {
              label: localize(msgs.pam.sidePanel.platform),
              value: data.connectionType,
            },
            {
              label: localize(msgs.pam.sidePanel.description),
              value: data.description,
            },
          ]}
        />
      )}
      {hasSystemDetailsFields && (
        <SpvSection
          title={localize(msgs.pam.sidePanel.systemDetails)}
          fields={[
            {
              label: localize(msgs.pam.sidePanel.location),
              value: data.customproperty10,
            },
          ]}
          className='SpvApplication-section'
        />
      )}
    </div>
  );
}

SpvApplication.propTypes = {
  data: ModuleDetails,
  dataTestId: PropTypes.string,
  className: PropTypes.string,
};

SpvApplication.defaultProps = {
  data: null,
  dataTestId: null,
  className: null,
};

export default SpvApplication;
