import {
  type TypedUseSelectorHook,
  useSelector as useGenericSelector,
} from 'react-redux';

import { type MenuId } from '../models';

export interface MenuLink {
  id: MenuId;
  link: string;
}

interface MenuBranch {
  id: MenuId;
  subMenu: SideMenuNode[];
}

export type SideMenuNode = MenuLink | MenuBranch;

export interface HostState {
  user: {
    domainInfo:
      | {
          GATEWAY_API_URL: string;
          FEATURE_MFA?: boolean;
        }
      | undefined;
    homepage: {
      quicklinks?: string[] | 'unauthorized';
      kpis?: string[] | 'unauthorized';
    };
  };
  app: { sideMenu: SideMenuNode[] | null };
  profile: { menuList?: MenuLink[] };
}

export const useSelector: TypedUseSelectorHook<HostState> = useGenericSelector;
