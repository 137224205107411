/**
 * Account Description Validation
 */

// UX-specified character count
const ACCT_DESC_CHAR_COUNT_LIMIT = 500;
const ACCT_DESC_CHAR_COUNT_TRUNCATE_LIMIT = 120;

// Check that the account description falls within the UX-specified character count
export const isAcctDescWithinCharLimit = (acctDesc) =>
  acctDesc.length <= ACCT_DESC_CHAR_COUNT_LIMIT;

// Check that the account description falls within the string truncation limit
export const isAcctDescWithinTruncateLimit = (acctDesc) =>
  acctDesc.length < ACCT_DESC_CHAR_COUNT_TRUNCATE_LIMIT;

// Check for disabling/enabling account description tooltip
export const isAcctDescToolTipDisabled = (acctDesc) =>
  isAcctDescWithinCharLimit(acctDesc) &&
  isAcctDescWithinTruncateLimit(acctDesc);
