import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import MenuWrapper from '../MenuItem/MenuWrapper';
import ToggleButton from '../../ToggleButton/ToggleButton';
import { InitializeMuteEmail } from '../../../actions/profileMenuAction';

export const Mail = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.8335 5C3.82097 5 3.00016 5.82081 3.00016 6.83333V8.75643C2.99995 8.76493 2.99995 8.77341 3.00016 8.78186V17.1667C3.00016 18.1792 3.82097 19 4.8335 19H19.1668C20.1793 19 21.0002 18.1792 21.0002 17.1667V8.77906C21.0003 8.77246 21.0003 8.76585 21.0002 8.75923V6.83333C21.0002 5.82081 20.1793 5 19.1668 5H4.8335ZM20.0002 8.43448V6.83333C20.0002 6.3731 19.6271 6 19.1668 6H4.8335C4.37326 6 4.00016 6.3731 4.00016 6.83333V8.4345L12.0001 11.7286L20.0002 8.43448ZM4.00016 9.51596V17.1667C4.00016 17.6269 4.37326 18 4.8335 18H19.1668C19.6271 18 20.0002 17.6269 20.0002 17.1667V9.51593L12.1905 12.7317C12.0686 12.7819 11.9317 12.7819 11.8098 12.7317L4.00016 9.51596Z"
      fill="#00245B"
    />
  </svg>
);
const useStyles = makeStyles(() => ({
  iconContainer: {
    marginRight: 10,
    minWidth: 0,
  },
}));

const MuteEmail = ({ intl, menuDetails }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.user.userInfo);
  const [isEmailMute, setIsEmailMute] = useState(false);

  useEffect(() => {
    if (userInfo?.muteEmail) {
      setIsEmailMute(userInfo.muteEmail);
    }
  }, [userInfo]);

  const onMenuClick = (event) => {
    event.stopPropagation();
    dispatch(InitializeMuteEmail(!isEmailMute));
    setIsEmailMute(!isEmailMute);
  };

  return (
    <MenuWrapper onMenuClick={onMenuClick}>
      <span className={classes.iconContainer}>
        <Mail />
      </span>
      <ToggleButton
        onToggleChange={() => {}}
        checked={isEmailMute}
        label={intl.formatMessage({
          id: `gen2.profileMenu.label.${menuDetails.id}`,
        })}
        position="left"
      />
    </MenuWrapper>
  );
};

MuteEmail.propTypes = {
  menuDetails: PropTypes.shape({
    id: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }).isRequired,
  intl: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string.isRequired,
      formatMessage: PropTypes.func.isRequired,
    }),
  ).isRequired,
};

export default injectIntl(MuteEmail);
