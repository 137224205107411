/**
 * Function wraps the hideHostElements function from common.
 * If anything changes (flag, etc), only this file needs to be updated.
 *
 * @function hideHostElementsWrapper
 * @returns {void}
 */

import { hideElementsFromHostApp } from '@saviynt/common';

const hideHostElementsWrapper = () => {
  hideElementsFromHostApp(false, 'pamGen2', '1');
};

export default hideHostElementsWrapper;

/**
 * Function to remove dynamic body classes from the document.
 * It removes the 'd-block' class from the first element with the class 'dynamicModules'.
 *
 * @function removeDynamicBodyClasses
 * @returns {void}
 */
export const removeDynamicBodyClasses = () => {
  const dynamicbody = document.getElementsByClassName('dynamicModules')[0];

  if (dynamicbody) {
    dynamicbody.classList.remove('d-block');
  }
};
