import React, { type JSX } from 'react';
import { Chip, Icon, Typography } from '@saviynt/design-system';

import { useInvertedLocalize } from '../../../utilities';
import { type PendingCertification } from '../models';

import { invertedMessages } from './messages';
import RecentAssignmentRow from './RecentAssignmentRow';
import {
  type ConvertToUtc,
  type RecentAssignmentsInvertedLocalize,
} from './types';

import './CertificationRow.css';

interface Props {
  cert: PendingCertification;
  convertToUtc: ConvertToUtc;
}

export default function CertificationRow({
  cert,
  convertToUtc,
}: Props): JSX.Element {
  const l = useInvertedLocalize(invertedMessages);

  return (
    <RecentAssignmentRow
      className='Nav_CertificationRow'
      icon={<Icon kind='Certification' size='large' color='blue-500' />}
      sourceAndPurpose={
        <div className='Nav_CertificationRow-sourceAndPurpose'>
          <Typography
            className='Nav_CertificationRow-sourceAndPurposeHeading'
            kind='body2-bold'>
            {l('Certification')}
          </Typography>
          <Typography
            className='Nav_CertificationRow-sourceAndPurposeId'
            kind='body2'>
            {cert.id}
          </Typography>
        </div>
      }
      details={
        <div className='Nav_CertificationRow-details'>
          <Typography
            className='Nav_CertificationRow-detailsLabel'
            kind='body3'>
            {l('Type')}
          </Typography>
          <Typography
            className='Nav_CertificationRow-detailsValue'
            kind='body2'>
            {cert.type}
          </Typography>
        </div>
      }
      subject1={
        <div className='Nav_CertificationRow-subject1'>
          <Typography
            className='Nav_CertificationRow-subject1Label'
            kind='body3'>
            {l('Progress')}
          </Typography>
          <Typography
            className='Nav_CertificationRow-subject1Value'
            kind='body2'>
            {cert.progress}
          </Typography>
        </div>
      }
      subject2={
        <div className='Nav_CertificationRow-subject2'>
          <Typography
            className='Nav_CertificationRow-subject2Label'
            kind='body3'>
            {l('Ends at')}
          </Typography>
          <Typography
            className='Nav_CertificationRow-subject2Value'
            kind='body2'>
            {convertToUtc(cert.enddate)}
          </Typography>
        </div>
      }
      assignmentStatus={assignmentStatus(l, cert.dueIn)}
    />
  );
}

function assignmentStatus(
  l: RecentAssignmentsInvertedLocalize,
  dueIn: string
): JSX.Element | undefined {
  if (dueIn === 'OverDue') {
    return (
      <Chip
        size='small'
        kind='subtleFilled'
        color='critical'
        label={l('Overdue')}
      />
    );
  }

  if (dueIn === 'Complete') {
    return (
      <Chip
        size='small'
        kind='subtleFilled'
        color='success'
        label={l('Complete')}
      />
    );
  }

  const numericMatches = dueIn.match(/\d+/);

  if (!numericMatches) {
    return undefined;
  }

  const dayCount = Number(numericMatches[0]);

  return (
    <Chip
      kind='subtleFilled'
      color={dayCount <= 1 ? 'critical' : 'brandSecondary'}
      label={l('Due in {count} d', { count: dayCount })}
    />
  );
}
