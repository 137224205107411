const DISABLE_ERROR_NOTIFICATION_PATTERN = ["\/ECMv6\/api\/idw\/res\/*",
  "\/ECMv6\/api\/ars\/requests\/[0-9]+$", "\/ECMv6\/api\/ars\/requests\/$",
  "\/ECMv6\/api\/ars\/request\/access\/sod\/account",
  "\/ECMv6\/api\/ars\/request\/access\/sod\/enterprise-role","\/ECMv6\/api\/pam/\/privilegedinstance\/accounts"];

export const generateQueryParam = dict => {
  const query = Object.keys(dict)
    .filter(k => dict[k] !== undefined)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(dict[k])}`)
    .join('&');
  if (query && query.length > 0) {
    return `?${query}`;
  }
  return '';
};

export const hideErrorNotification = apiUrl => {
   return DISABLE_ERROR_NOTIFICATION_PATTERN.some(p => {
     const regex = new RegExp(p, 'g');
     return regex.test(apiUrl);
   })
};
