import React, { useState, useEffect } from 'react';
// eslint-disable-next-line import/extensions
import PropTypes from 'prop-types';

import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Chip from '@material-ui/core/Chip';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { injectIntl } from 'react-intl';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { checkEnvironment, userAttributePrefix, userAttributePostfix } from 'ui-common/src/lib/utils';
import './AdvancedFilter.scss';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ExpansionPanel = withStyles({
  root: {
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    minHeight: 56,
    padding: 0,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: 0,
  },
}))(MuiExpansionPanelDetails);

const useStyles = makeStyles(theme => ({
  checboxWidth: {
    width: '100%',
    height: '100%',
  },
  fieldAlignment: { height: '100%' },
  chiproot: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  chipItem: {
    marginTop: 7,
    marginRight: 5,
    padding: '0px 5px',
    height: '100%',
  },
  chipLabel: {
    whiteSpace: 'pre-wrap',
  },
  formControl: {
    width: 150,
  },
}));

const RequestedForTemplate = ({
  getDistinctColumnValues,
  column,
  appliedFilters,
  setAppliedFilters,
  dataSource,
  requestBody,
  preAppliedFilters,
  intl,
  loadingColumns,
}) => {
  const [selectedList, setSelectedList] = useState([]);
  const [selectedListMain, setSelectedListMain] = useState([]);
  const defaultUserName = 'username';
  const [expanded, setExpanded] = useState(false);
  const classes = useStyles();
  const [selctedUserAttribute, setSelctedUserAttribute] = React.useState(defaultUserName);
  const [userAttrText, setUserAttrText] = useState('');
  const [selectedAttributeList, setSelectedAttributeList] = useState({});
  const [userAttributes, setUserAttributes] = useState(['username']);

  const handleUserAttributeChange = event => {
    setSelctedUserAttribute(event.target.value);
  };

  const filterOptions = createFilterOptions({
    limit: 500,
  });

  const onKeyDown = async event => {
    if (event.keyCode === 13 && userAttrText.trim()) {
      const sel = selectedAttributeList[selctedUserAttribute] || [];
      sel.push(userAttrText);
      setSelectedAttributeList({ ...selectedAttributeList, [selctedUserAttribute]: sel });
      if (Array.isArray(appliedFilters[column.key]))
        setAppliedFilters({
          ...appliedFilters,
          [column.key]: { [defaultUserName]: selectedList, [selctedUserAttribute]: sel },
        });
      else
        setAppliedFilters({
          ...appliedFilters,
          [column.key]: { ...appliedFilters[column.key], [selctedUserAttribute]: sel },
        });
      setUserAttrText('');
    }
  };

  useEffect(() => {
    const url = { ...column.filter };
    const selected = Object.keys(appliedFilters).length === 0 ? [] : appliedFilters[column.key];
    const selectedMain =
      Object.keys(appliedFilters).length === 0 ? [] : appliedFilters[column.mainkey];
    // if (selected && selected.length === 0)
    //   setSelectedList(selected);
    if (Object.keys(appliedFilters).length === 0) {
      setSelectedAttributeList({});
      setSelectedList([]);
      setSelectedListMain([]);
    }
    if (selectedMain !== undefined && Array.isArray(appliedFilters[column.mainkey])) {
      setSelectedListMain(selectedMain);
    }
    if (appliedFilters[column.key] && typeof appliedFilters[column.key] === 'object') {
      const selAttrList = {};
      Object.keys(appliedFilters[column.key]).forEach(key => {
        if (
          Array.isArray(appliedFilters[column.key][key]) &&
          appliedFilters[column.key][key].length
        ) {
          if (key === defaultUserName) setSelectedList(appliedFilters[column.key][key]);
          else {
            selAttrList[key] = appliedFilters[column.key][key];
          }
        }
      });
      setSelectedAttributeList(selAttrList);
    }
  }, [appliedFilters]);

  useEffect(() => {
    const url = { ...column.filter };
    const selected =
      Object.keys(preAppliedFilters).length === 0 ? [] : preAppliedFilters[column.key];
    const selectedMain =
      Object.keys(preAppliedFilters).length === 0 ? [] : preAppliedFilters[column.mainkey];
    if (
      Array.isArray(preAppliedFilters[column.mainkey]) &&
      selectedMain !== undefined &&
      selectedMain.length > 0
    ) {
      getDistinctColumnValues(column.mainkey, url.filterURL, requestBody, column);
      setSelectedListMain(selectedMain);
      setExpanded(true);
      // setAppliedFilters({
      //   ...preAppliedFilters,
      //   [column.key]: { userName: preAppliedFilters[column.key] },
      // });
    }
    if (
      preAppliedFilters[column.key] &&
      typeof preAppliedFilters[column.key] === 'object' &&
      Object.keys(preAppliedFilters[column.key]).length
    ) {
      getDistinctColumnValues(column.mainkey, url.filterURL, requestBody, column);
      const selAttrList = {};
      Object.keys(preAppliedFilters[column.key]).forEach(key => {
        if (
          Array.isArray(preAppliedFilters[column.key][key]) &&
          preAppliedFilters[column.key][key].length
        ) {
          if (key === defaultUserName) setSelectedList(preAppliedFilters[column.key][key]);
          else {
            selAttrList[key] = preAppliedFilters[column.key][key];
          }
        }
      });
      setSelectedAttributeList(selAttrList);
      setExpanded(true);
    }
  }, [preAppliedFilters]);

  useEffect(() => {
    const IDW_ENDPOINT = checkEnvironment().IDWMS_API_URL;
    fetch(`${IDW_ENDPOINT}/config/USERATTWHILEHASEARCH`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-NOLOADER': true,
      },
    })
      .then(response => response.json())
      .then(data => {
        if (data.configData) {
          const arr = data.configData.split('_').filter(item => item !== 'username');
          arr.unshift(defaultUserName);
          setUserAttributes(arr);
        }
      })
      .catch(() => { });
  }, []);

  const onExpand = (e, expanded) => {
    if (expanded) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
    if (expanded && dataSource.length === 0) {
      const url = { ...column.filter };
      getDistinctColumnValues(column.mainkey, url.filterURL, requestBody, column);
    }
  };

  let Appkey = column.key;
  if (column.key === 'reqId') Appkey = 'ApprovalId';
  if (column.key === 'requestId') Appkey = 'HistoryId';

  return (
    <div>
      <ExpansionPanel square expanded={expanded} onChange={onExpand}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1d-content"
          id="panel1d-header"
        >
          <div className="advfilter-title">{column.label}</div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className="d-flex flex-column panel-det-padd">
          {expanded && (
            <>
              {!loadingColumns.includes(column.key) ? (
                <>
                  <div className="d-flex">
                    <FormControl className={classes.formControl}>
                      <Select
                        value={selctedUserAttribute}
                        onChange={handleUserAttributeChange}
                        displayEmpty
                        className={classes.selectEmpty}
                      >
                        {userAttributes.map(attr => (
                          <MenuItem value={attr}>
                            {' '}
                            {intl.formatMessage({
                              id:
                                attr === 'username'
                                  ? `AdvanceFilter.UserAttribute.${attr}`
                                  : `${userAttributePrefix}${attr}${userAttributePostfix}`,
                            })}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {selctedUserAttribute === defaultUserName ? (
                      <Autocomplete
                        noOptionsText={intl.formatMessage({ id: 'ColumnFilter.NoOptionsText' })}
                        filterOptions={filterOptions}
                        defaultValue={selectedList}
                        multiple
                        filterSelectedOptions
                        id="filter-demo"
                        options={dataSource}
                        popupIcon={null}
                        debug
                        value={selectedList}
                        onChange={(e, selections) => {
                          if (Array.isArray(appliedFilters[column.key]))
                            setAppliedFilters({
                              ...appliedFilters,
                              [column.key]: { [defaultUserName]: selections },
                            });
                          else
                            setAppliedFilters({
                              ...appliedFilters,
                              [column.key]: {
                                ...appliedFilters[column.key],
                                [defaultUserName]: selections,
                              },
                            });
                          setSelectedList(selections);
                        }}
                        getOptionLabel={option =>
                          column.filter && column.filter.intlSupportRequired && option
                            ? intl.formatMessage({ id: option })
                            : option
                        }
                        style={{ width: '100%' }}
                        renderInput={params => (
                          <TextField
                            classes={{
                              root: classes.checboxWidth,
                            }}
                            className="autoCompleteField"
                            {...params}
                            label=""
                            placeholder={column.label}
                          />
                        )}
                      />
                    ) : (
                        <TextField
                          classes={{
                            root: classes.checboxWidth,
                          }}
                          label=""
                          placeholder={column.label}
                          value={userAttrText}
                          onChange={e => setUserAttrText(e.target.value)}
                          onKeyDown={onKeyDown}
                        />
                      )}
                  </div>
                </>
              ) : (
                  <div className="d-flex justify-center adv-fil-loader">
                    <img src="/ECMv6/assets/images/Double Ring-1s-80px.gif" />
                  </div>
                )}
            </>
          )}
          <div className={classes.root}>
            {selectedList.map(item => (
              <Chip
                classes={{ root: classes.chipItem, label: classes.chipLabel }}
                className="selectedLabel"
                label={`${intl.formatMessage({
                  id: `AdvanceFilter.UserAttribute.username`,
                })}: ${item}`}
                onDelete={() => {
                  setAppliedFilters({
                    ...appliedFilters,
                    [column.key]: {
                      ...appliedFilters[column.key],
                      [defaultUserName]: selectedList.filter(a => a !== item),
                    },
                  });
                  setSelectedList(selectedList.filter(a => a !== item));
                }}
                variant="outlined"
              />
            ))}
          </div>
          <div className={classes.root}>
            {Object.keys(selectedAttributeList).map(key => {
              return selectedAttributeList[key].map(val => {
                return (
                  <Chip
                    classes={{ root: classes.chipItem, label: classes.chipLabel }}
                    className="selectedLabel"
                    label={`${intl.formatMessage({
                      id:
                        key === 'username'
                          ? `AdvanceFilter.UserAttribute.${key}`
                          : `${userAttributePrefix}${key}${userAttributePostfix}`,
                    })}: ${val}`}
                    onDelete={() => {
                      const filter = selectedAttributeList[key].filter(item => item !== val);
                      setSelectedAttributeList({
                        ...selectedAttributeList,
                        [key]: filter,
                      });
                      setAppliedFilters({
                        ...appliedFilters,
                        [column.key]: { ...appliedFilters[column.key], [key]: filter },
                      });
                    }}
                    variant="outlined"
                  />
                );
              });
            })}
            {selectedListMain.map(val => {
              return (
                <Chip
                  classes={{ root: classes.chipItem, label: classes.chipLabel }}
                  className="selectedLabel"
                  label={`${intl.formatMessage({
                    id: `AdvanceFilter.UserAttribute.username`,
                  })}: ${val}`}
                  onDelete={() => {
                    setSelectedListMain(selectedListMain.filter(item => item !== val));
                    setAppliedFilters({
                      ...appliedFilters,
                      [column.mainkey]: selectedListMain.filter(item => item !== val),
                    });
                  }}
                  variant="outlined"
                />
              );
            })}
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
};

RequestedForTemplate.propTypes = {
  getDistinctColumnValues: PropTypes.func.isRequired,
  setAppliedFilters: PropTypes.func.isRequired,
  column: PropTypes.objectOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
      filter: PropTypes.objectOf(
        PropTypes.shape({
          filterURL: PropTypes.string,
          type: PropTypes.string,
        })
      ),
    })
  ).isRequired,
  appliedFilters: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  dataSource: PropTypes.arrayOf(PropTypes.string).isRequired,
  requestBody: PropTypes.objectOf(
    PropTypes.shape({
      connectiontype: PropTypes.string,
    })
  ).isRequired,
  intl: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string.isRequired,
      formatMessage: PropTypes.func.isRequired,
    })
  ).isRequired,
  loadingColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
};
export default injectIntl(RequestedForTemplate);
