/**
 * Sets a cookie with a name and value, with an optional expiration in days.
 * @param {string} name - The name of the cookie.
 * @param {string} value - The value of the cookie.
 * @param {number} daysToExpire - The number of days until the cookie should expire.
 *
 * @example setCookieDaysTillExpired('user', 'JohnDoe', 7);
 * Sets a cookie named 'user' with the value 'JohnDoe' that expires in 7 days.
 */

const setCookieDaysTillExpired = (name, value, daysToExpire) => {
  let expires = '';

  if (daysToExpire) {
    const date = new Date();

    date.setTime(date.getTime() + daysToExpire * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }

  document.cookie = `${name}=${value || ''}${expires}; path=/`;
};

/**
 * Retrieves the value of a specified cookie by name.
 * @param {string} name - The name of the cookie to retrieve.
 * @returns {string|null} The value of the cookie, or null if not found.
 *
 * @example getCookie('user');
 * Returns the value of the 'user' cookie, or null if it does not exist.
 */

const getCookie = (name) => {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');

  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];

    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }

  return null;
};

export { getCookie, setCookieDaysTillExpired };
