import React from 'react';
import { useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import styles from './About.module.scss';

export const SaviyntLogo = () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="65"
    height="65"
    viewBox="0 0 581 581"
  >
    <path
      d="M272 1C201.4 5.6 135.5 35.5 85.5 85.5c-39.9 39.9-68.1 91.9-79 145.9C2.3 252.3.8 267.9.8 290.5c0 48.4 10.1 90.5 32.1 133 36 69.9 98.8 122 174.5 144.9 44.4 13.5 95 15.6 142.2 6.1 84.3-17 158.6-73.5 198.5-151 22-42.5 32.1-84.6 32.1-133s-10.1-90.5-32.1-133C504.1 72 420.1 13.7 325 2.6 308.3.7 287.3 0 272 1zm119 66c0 .5-.8 1-1.7 1-1 0-7.7 2-14.8 4.4-42.4 14.2-70.5 36.8-78.4 63-2.6 8.4-2.8 22.1-.5 31 3.4 13 12.5 29 26.8 47.1 4.4 5.5 18.3 21.8 31 36.3 35.1 40.1 47.7 57 59.2 79.8 9.5 18.7 13.3 31.8 15.4 53.3 2.6 26.5-.2 54.1-8 78.3-5.8 17.9-21.6 46.4-29.3 52.6-2.2 1.9-200.4 3.2-199.3 1.4.4-.7 1.4-1.2 2.2-1.2 3.6 0 27.1-8.7 38.9-14.5 41.2-20 61.4-49.2 55.1-79.6-4.8-22.6-14.7-37.5-57.6-86.4-21.8-24.9-29.7-34.4-38.9-46.7-20.1-27.2-32.1-53.2-35.5-76.8-3.4-24.1-3-45.7 1.5-68.5 4.4-23.3 15.1-48.3 27.6-65l6.8-9 25-.5c49-1.1 174.5-1 174.5 0z"
      fill="#1c74bc"
    />
  </svg>
);

const AboutDetails = ({ onClose, intl }) => {
  const ecmVersion = useSelector((state) => state.user.ecmVersion);
  const versionText = `${intl.formatMessage({
    id: 'Header.about.version',
  })} ${ecmVersion}`;

  return (
    <div className={styles.container}>
      <div className={styles.closeIcon}>
        <CloseIcon onClick={onClose} data-testid="close" />
      </div>
      <div className={styles.logo}>
        <SaviyntLogo />
      </div>
      <h4 className={styles.title}>Saviynt</h4>
      <h5 className={styles.version}>{versionText}</h5>
    </div>
  );
};

AboutDetails.propTypes = {
  onClose: PropTypes.func.isRequired,
  intl: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string.isRequired,
      formatMessage: PropTypes.func.isRequired,
    })
  ).isRequired,
};

export default injectIntl(AboutDetails);
