import classnames from 'classnames';
import Popover from '@material-ui/core/Popover';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useSelector } from 'react-redux';
import DateRangeIcon from '@material-ui/icons/DateRange';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { TabContext } from '@material-ui/lab';
import styles from './QuickSearch.module.css';
import Input from '../Input/Input';
import TabPanel from '../TabPanel/TabPanel';
import QuickSelect from '../QuickSelect/QuickSelect';
import DisplayLabel from '../DisplayLabel/DisplayLabel';
import { defaultDateTimeQuery } from '../../../../constant';
import DateTimeRangePicker from '../DateTimeRangePicker/DateTimeRangePicker';

const QuickSearch = ({
  className, handleUpdateSelection, intl, searchText, setSearchText
}) => {
  const ES_LOGS_MAX_AGE_IN_DAYS = useSelector((state) => state.user.domainInfo?.ES_LOGS_MAX_AGE_IN_DAYS);
  const selection = useSelector((state) => state.admin?.logs.selection);
  const minDateForDatePicker = moment().subtract(ES_LOGS_MAX_AGE_IN_DAYS || 7, 'days').add(1, 'days');
  const defaultQuery = defaultDateTimeQuery(intl);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [tabValue, setTabValue] = useState(0);
  const [value, setValue] = useState(defaultQuery.value);
  const [duration, setDuration] = useState(intl.formatMessage({ id: 'Admin.Log.Viewer.minutes' }));
  const [searchData, setSearchData] = useState({
    searchText: '',
    startDateTime: new Date(),
    endDateTime: new Date(),
  });
  const intlTextLast = intl.formatMessage({ id: 'Admin.Log.Viewer.last' });

  useEffect(() => {
    if ((selection.fromDate !== '' && selection.fromDate !== searchData.startDateTime)
        || (selection.toDate !== '' && selection.toDate !== searchData.endDateTime)) {
      const stDate = selection.fromDate;
      const edDate = selection.toDate;

      setSearchData((previousState) => ({
        ...previousState,
        startDateTime: stDate,
        endDateTime: edDate,
      }));
      setSearchText({
        dur: '',
        val: '',
        labelValue: `${moment(stDate).format('ll LTS')} - ${moment(edDate).format('ll LTS')}`,
      });
    }
  }, [selection]);

  // Event Handlers
  const handlePopOver = (event) => {
    event.currentTarget.focus();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleInputChange = (event) => {
    setValue(event.target.value);
  };
  const handleDurationChange = (event) => {
    setDuration(event.target.value);
  };
  const handleApply = (e) => {
    e.preventDefault();
    setSearchText({
      val: value,
      dur: duration,
      labelValue: intlTextLast,
    });

    const newSelection = Object.assign(selection, {
      fromDate: moment().subtract(value, duration),
      toDate: moment(),
    });

    handleUpdateSelection(newSelection);
    handleClose();
  };
  const handleCommon = (list) => {
    const stringOutput = DisplayLabel({ ...list, intl });

    setSearchText({
      val: list.value,
      dur: list.duration,
      labelValue: stringOutput,
    });

    // for Today
    if (list.value === 0) {
      handleUpdateSelection(Object.assign(selection, {
        fromDate: moment().startOf(list.duration),
        toDate: moment(),
      }));
    } else {
      // for other dates
      handleUpdateSelection(Object.assign(selection, {
        fromDate: moment().subtract(list.value, list.duration),
        toDate: moment(),
      }));
    }

    handleClose();
  };
  const handleTabChange = (v) => {
    setTabValue(v);
  };

  const handleDateChangeProp = (range) => {
    const stDate = range[0];
    const edDate = range[1];

    setSearchData((previousState) => ({
      ...previousState,
      startDateTime: stDate,
      endDateTime: edDate,
    }));
    setSearchText({
      dur: '',
      val: '',
      labelValue: `${moment(stDate).format('ll LTS')} - ${moment(edDate).format('ll LTS')}`,
    });
    handleUpdateSelection(Object.assign(selection, {
      fromDate: moment(stDate),
      toDate: moment(edDate),
    }));
    handleClose();
  };

  const searchDisplayTextWithLast = searchText.labelValue + ' ' + searchText.val + ' ' + searchText.dur;
  const searchDisplayValue = searchText.labelValue === intlTextLast ? searchDisplayTextWithLast : searchText.labelValue;

  return (
    <div data-testid="searchDiv" className={classnames(className, styles.container)}>
      <Input
        startAdornment={(
          <InputAdornment
            position="end"
            onClick={handlePopOver}
            className={styles.calendarIcon}
            data-testid="nameInput"
          >
            <DateRangeIcon />
          </InputAdornment>
        )}
        value={searchDisplayValue}
        onClick={handlePopOver}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <TabContext value={tabValue}>
          <Tabs
            indicatorColor="primary"
            variant="fullWidth"
            value={tabValue}
            onChange={(e, v) => handleTabChange(v)}
          >
            <Tab label={intl.formatMessage({ id: 'Admin.Log.Viewer.quickSelect' })} />
            <Tab label={intl.formatMessage({ id: 'Admin.Log.Viewer.dateSelect' })} />
          </Tabs>
          <div className={styles.popoverContainer} data-testid="tabdiv">
            <TabPanel value={tabValue} index={0}>
              <QuickSelect
                duration={duration}
                value={value}
                intl={intl}
                handleApply={handleApply}
                handleCommon={handleCommon}
                handleInputChange={handleInputChange}
                handleDurationChange={handleDurationChange}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={1} className={styles.dateContainer}>
              <div className={styles.dateContainer}>
                <DateTimeRangePicker
                  intl={intl}
                  minDate={minDateForDatePicker.toDate()}
                  maxDate={moment().toDate()}
                  startDate={moment(searchData.startDateTime).toDate()}
                  endDate={moment(searchData.endDateTime).toDate()}
                  handleSelectedRange={handleDateChangeProp}
                  size="small"
                  disableFutureDates
                  disableYear
                />
              </div>
            </TabPanel>
          </div>
        </TabContext>
      </Popover>
    </div>
  );
};

export default QuickSearch;
