import { Tooltip } from '@saviynt/design-system';
import PropTypes from 'prop-types';
import React from 'react';
import BrandingIconBuilder from '../BrandingIconBuilder/BrandingIconBuilder';
import ColorPicker from './ColourPicker';

const ColorPickerTile = ({
  openColourPickerType,
  handleColourChange,
  callBack,
  type,
  color,
  text,
  themeName,
  classes,
  isEdited,
  resetColourType,
  resetTooltip,
}) => (
  <div
    className={`${classes.colourPickerWrap} ${openColourPickerType === type && classes.selected}`}
  >
    <div className={`${classes.colourSwatchStrip}`}>
      <div className={classes.colourSwatchStripInner}>
        <div className={classes.colourSwatchAndLabel}>
          <ColorPicker
            colour={color || undefined}
            handleColourChange={handleColourChange(themeName || '', 'color', type)}
            callBack={callBack(type)}
          >
            <div className={classes.dFlex}>
              <BrandingIconBuilder
                kind="colourswatch"
                size="full"
                colours={[color]}
                className={classes.mr_8}
              />
            </div>
          </ColorPicker>
          <span>{text}</span>
        </div>
        <ColorPicker
          colour={color || undefined}
          handleColourChange={handleColourChange(themeName || '', 'color', type)}
          callBack={callBack(type)}
          edit
        >
          <div className={classes.dFlex}>
            {!isEdited && (
              <span className={`${classes.bc_prefixIcon} ${isEdited && classes.dNone}`}>
                <BrandingIconBuilder kind="edit" size="full" />
              </span>
            )}
          </div>
        </ColorPicker>
        {isEdited && (
          <Tooltip
            text={resetTooltip}
            placement="right"
            delay={250}
            trigger={
              <span className={classes.bc_prefixIcon_static} onClick={() => resetColourType(type)}>
                <BrandingIconBuilder kind="reset" size="full" />
              </span>
            }
          ></Tooltip>
        )}
      </div>
    </div>
  </div>
);

ColorPickerTile.propTypes = {
  openColourPickerType: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  themeName: PropTypes.string.isRequired,
  handleColourChange: PropTypes.func.isRequired,
  callBack: PropTypes.func.isRequired,
  classes: PropTypes.shape({}).isRequired,
  isEdited: PropTypes.func.isRequired,
  resetColourType: PropTypes.func.isRequired,
  resetTooltip: PropTypes.string.isRequired,
};

export default ColorPickerTile;
