import React, { useState, useEffect, useRef } from 'react';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Pagination from '@material-ui/lab/Pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { checkEnvironment } from 'ui-common/src/lib/utils';
import 'react-alice-carousel/lib/alice-carousel.css';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import './AppsRecommendation.scss';

export const ArrowRight = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
      <path
        d="M12.098 5.13907C11.9352 4.97638 11.6714 4.97644 11.5087 5.13919C11.346 5.30194 11.3461 5.56576 11.5088 5.72845L16.0793 10.297H2.91667C2.68655 10.297 2.5 10.4836 2.5 10.7137C2.5 10.9438 2.68655 11.1304 2.91667 11.1304H16.0748L11.5088 15.6946C11.3461 15.8573 11.346 16.1211 11.5087 16.2839C11.6714 16.4466 11.9352 16.4467 12.098 16.284L17.3779 11.0061C17.456 10.928 17.5 10.822 17.5 10.7115C17.5 10.6009 17.456 10.4949 17.3779 10.4168L12.098 5.13907Z"
        fill="#3751FF"
      />
    </svg>
  );
};
export const ChevronLeft = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.6036 3.14669C16.7988 3.34195 16.7988 3.65854 16.6036 3.8538L8.45711 12.0002L16.6036 20.1467C16.7988 20.342 16.7988 20.6585 16.6036 20.8538C16.4083 21.0491 16.0917 21.0491 15.8964 20.8538L7.39645 12.3538C7.20118 12.1585 7.20118 11.842 7.39645 11.6467L15.8964 3.14669C16.0917 2.95143 16.4083 2.95143 16.6036 3.14669Z"
        fill="#212328"
      />
    </svg>
  );
};

export const ChevronRight = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.39632 20.8538C7.20106 20.6585 7.20106 20.342 7.39632 20.1467L15.5428 12.0002L7.39633 3.8538C7.20106 3.65854 7.20106 3.34195 7.39633 3.14669C7.59159 2.95143 7.90817 2.95143 8.10343 3.14669L16.6034 11.6467C16.7987 11.842 16.7987 12.1585 16.6034 12.3538L8.10343 20.8538C7.90817 21.0491 7.59159 21.0491 7.39632 20.8538Z"
        fill="#212328"
      />
    </svg>
  );
};

export const ApplicationIcon = () => {
  return (
    <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.306641" width="48" height="48" rx="24" fill="#EBEDFF" />
      <path
        d="M13.4316 24.75L12.6816 16.125L22.4316 13.125L23.1816 13.875V22.125L22.4316 23.25L14.1816 25.875L13.4316 24.75Z"
        fill="white"
      />
      <path
        d="M13.0566 38.25V29.25L22.8066 26.25L23.5566 27V35.25L22.8066 36.375L14.1816 39L13.0566 38.25Z"
        fill="white"
      />
      <path
        d="M26.1816 21.75L25.4316 12.375L35.1816 9.375L35.9316 10.125V18.375L35.1816 19.875L26.9316 22.125L26.1816 21.75Z"
        fill="white"
      />
      <path
        d="M25.8066 34.125L25.4316 25.125L35.1816 22.5L35.9316 22.875V31.5L35.1816 33L26.5566 35.625L25.8066 34.125Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.8736 17.3626C12.8736 16.2793 13.7157 15.1517 14.7544 14.844L21.8472 12.743C22.886 12.4353 23.728 13.0641 23.728 14.1474V21.5449C23.728 22.6282 22.886 23.7559 21.8472 24.0636L14.7544 26.1646C13.7157 26.4722 12.8736 25.8434 12.8736 24.7601V17.3626ZM14.7544 15.6286C14.1312 15.8132 13.6259 16.4898 13.6259 17.1398V24.5373C13.6259 25.1873 14.1312 25.5645 14.7544 25.3799L21.8472 23.2789C22.4705 23.0943 22.9757 22.4178 22.9757 21.7678V14.3703C22.9757 13.7203 22.4705 13.343 21.8472 13.5276L14.7544 15.6286Z"
        fill="#033176"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.5566 16.7041C11.5566 15.6207 12.3987 14.4931 13.4374 14.1854L20.8396 11.9928C21.7074 11.7357 22.411 12.2611 22.411 13.1663L21.6587 13.3891C21.6587 12.9173 21.292 13.2551 20.8396 13.3891L14.2867 15.4157C13.6634 15.6003 13.1097 16.3349 13.1097 16.9849L13.2498 24.0464C13.2498 24.5884 12.7302 24.9309 13.2498 24.7769V25.5616C12.3147 25.8385 11.5566 25.2725 11.5566 24.2972V16.7041Z"
        fill="#033176"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.8736 30.5324C12.8736 29.4491 13.7157 28.3214 14.7544 28.0137L21.8472 25.9128C22.886 25.6051 23.728 26.2339 23.728 27.3172V34.7147C23.728 35.798 22.886 36.9256 21.8472 37.2333L14.7544 39.3343C13.7157 39.642 12.8736 39.0132 12.8736 37.9299V30.5324ZM14.7544 28.7984C14.1312 28.983 13.6259 29.6595 13.6259 30.3095V37.707C13.6259 38.357 14.1312 38.7343 14.7544 38.5497L21.8472 36.4487C22.4705 36.2641 22.9757 35.5875 22.9757 34.9375V27.54C22.9757 26.89 22.4705 26.5128 21.8472 26.6974L14.7544 28.7984Z"
        fill="#033176"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.5566 29.874C11.5566 28.7906 12.3987 27.663 13.4374 27.3553L20.8209 25.1682C21.6991 24.9081 22.411 25.4397 22.411 26.3556L21.6587 26.5784C21.6587 26.0959 21.2836 26.2185 20.8209 26.3556L14.2867 28.3618C13.6634 28.5464 13.2498 29.224 13.2498 29.874V36.9926C13.2498 37.5345 12.7302 38.1007 13.2498 37.9468V38.7314C12.3147 39.0084 11.5566 38.4424 11.5566 37.4671V29.874Z"
        fill="#033176"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.5449 26.9048C25.5449 25.8692 26.3498 24.7914 27.3427 24.4972L34.5683 22.3569C35.5612 22.0628 36.3661 22.6639 36.3661 23.6994V31.2353C36.3661 32.2709 35.5612 33.3488 34.5683 33.6429L27.3427 35.7832C26.3498 36.0773 25.5449 35.4762 25.5449 34.4407V26.9048ZM27.3427 25.2472C26.747 25.4237 26.264 26.0704 26.264 26.6918V34.2277C26.264 34.849 26.747 35.2096 27.3427 35.0332L34.5683 32.8929C35.164 32.7164 35.647 32.0697 35.647 31.4483V23.9124C35.647 23.2911 35.164 22.9305 34.5683 23.1069L27.3427 25.2472Z"
        fill="#033176"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24.2113 26.3206C24.2113 25.2373 25.0534 24.1096 26.0921 23.802L33.4961 21.6088C34.363 21.352 35.0657 21.8768 35.0657 22.7808L34.3134 23.0037C34.3134 22.5329 33.9475 22.87 33.4961 23.0037L26.8405 24.8311C26.2173 25.0157 26.0559 25.7503 26.0559 26.4003L25.9291 33.4618C25.9291 34.018 25.3959 34.5441 25.9291 34.3862V35.1708C24.9804 35.4518 24.2113 34.8775 24.2113 33.888V26.3206Z"
        fill="#033176"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.5449 13.7347C25.5449 12.6991 26.3498 11.6212 27.3427 11.3271L34.5683 9.18681C35.5612 8.8927 36.3661 9.49374 36.3661 10.5293V18.0652C36.3661 19.1007 35.5612 20.1786 34.5683 20.4727L27.3427 22.6131C26.3498 22.9072 25.5449 22.3061 25.5449 21.2706V13.7347ZM27.3427 12.0771C26.747 12.2536 26.264 12.9003 26.264 13.5216V21.0576C26.264 21.6789 26.747 22.0395 27.3427 21.8631L34.5683 19.7227C35.164 19.5463 35.647 18.8995 35.647 18.2782V10.7423C35.647 10.121 35.164 9.76034 34.5683 9.93681L27.3427 12.0771Z"
        fill="#033176"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24.2113 13.1507C24.2113 12.0674 25.0534 10.9397 26.0921 10.6321L33.4642 8.44834C34.3487 8.18635 35.0657 8.72176 35.0657 9.64423L34.3134 9.86707C34.3134 9.37794 33.1941 9.78444 32.7251 9.92336L27.2328 11.8849C26.6096 12.0695 25.9291 12.8041 25.9291 13.4541V20.2533C25.9291 20.8094 25.3959 21.3742 25.9291 21.2163V22.0009C24.9804 22.2819 24.2113 21.7076 24.2113 20.7181V13.1507Z"
        fill="#033176"
      />
    </svg>
  );
};

const useStyles = makeStyles({
  toolTip: {
    fontSize: '14px',
    lineHeight: '21px',
    padding: '8px 12px',
    backgroundColor: 'var(--black)',
  },
  arrow: {
    '&::before': {
      backgroundColor: 'var(--black)',
    },
  },
});

const AppsRecommendation = ({ intl }) => {
  const classes = useStyles();
  const ref = useRef(null);
  const [width, setWidth] = useState(0);
  const [cardWidth, setCardWidth] = useState(0);

  const isMobileDevice = useMediaQuery('(min-width:481px) and (max-width:549px)');
  const isTabletSmallDevice = useMediaQuery('(min-width:550px) and (max-width:768px)');
  const isTabletDevice = useMediaQuery('(min-width:769px) and (max-width:1012px)');
  const isMediumDevice = useMediaQuery('(min-width:992px) and (max-width:1400px)');
  const isLargeDevice = useMediaQuery('(min-width:1401px) and (max-width:1500px)');
  const isExtraLargeDevice = useMediaQuery('(min-width:1500px)');

  useEffect(() => {
    setWidth(ref.current.offsetWidth);
    const getwidth = () => {
      setWidth(ref.current.offsetWidth);
    };
    window.addEventListener('resize', getwidth);
    return () => window.removeEventListener('resize', getwidth);
  }, []);

  const getItemsPerPage = () => {
    if (isExtraLargeDevice) {
      return 5;
    } else if (isLargeDevice) {
      return 5;
    } else if (isMediumDevice) {
      return 4;
    } else if (isTabletDevice) {
      return 3;
    } else if (isTabletSmallDevice) {
      return 2;
    } else if (isMobileDevice) {
      return 1;
    } else {
      return 1;
    }
  };

  const ITEMS_PER_PAGE = getItemsPerPage();
  const ecmUrl = checkEnvironment().ECM_URL;
  const { appRecommendations } = useSelector((state) => state.requestHome.initialLoad);
  const [paginationData, setPaginationData] = useState([]);

  useEffect(() => {
    if (ITEMS_PER_PAGE) {
      setPaginationData(appRecommendations.slice(0, ITEMS_PER_PAGE));
    }
  }, [ITEMS_PER_PAGE]);

  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, []);

  useEffect(() => {
    if (ITEMS_PER_PAGE >= 3) {
      setCardWidth(width / ITEMS_PER_PAGE - 30);
    } else if (ITEMS_PER_PAGE === 2) {
      setCardWidth(width / ITEMS_PER_PAGE - 50);
    } else if (ITEMS_PER_PAGE === 1) {
      setCardWidth(width - 100);
    }
  }, [width]);

  useEffect(() => {
    // when the component gets mounted
    setWidth(ref.current.offsetWidth);
    // to handle page resize
    const getwidth = () => {
      setWidth(ref.current.offsetWidth);
    };
    window.addEventListener('resize', getwidth);
    // remove the event listener before the component gets unmounted
    return () => window.removeEventListener('resize', getwidth);
  }, []);

  const handleChange = (event, value) => {
    setPage(value);
    onPageChange(value);
  };

  const pageCount = () => {
    const count = Math.ceil(appRecommendations.length / ITEMS_PER_PAGE);
    if (count === 0) {
      return 1;
    }
    return count;
  };

  const onPageChange = (currentPage) => {
    const firstPageIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const lastPageIndex = firstPageIndex + ITEMS_PER_PAGE;
    setPaginationData(appRecommendations.slice(firstPageIndex, lastPageIndex));
  };

  const onPreviousPage = () => {
    const firstPageIndex = (page - 2) * ITEMS_PER_PAGE;
    const lastPageIndex = firstPageIndex + ITEMS_PER_PAGE;
    setPage(page - 1);
    setPaginationData(appRecommendations.slice(firstPageIndex, lastPageIndex));
  };

  const onNextPage = () => {
    const firstPageIndex = page * ITEMS_PER_PAGE;
    const lastPageIndex = firstPageIndex + ITEMS_PER_PAGE;
    setPage(page + 1);
    setPaginationData(appRecommendations.slice(firstPageIndex, lastPageIndex));
  };

  const appNavigation = (endpoint) => {
    const appUrl = `${ecmUrl}/jbpmworkflowmanagement/gotofirststep?epname=${endpoint.endpointName}`;
    // window.location.replace(appUrl);
    window.location.href = appUrl;
  };

  const items = paginationData.map((item) => (
    <div className="recommended-card" style={{ width: cardWidth }}>
      <div
        className={`app-section ${
          item?.description ? 'appSection-with-description' : 'appSection-without-description'
        }`}
        tabIndex="0"
        onClick={() => appNavigation(item)}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            appNavigation(item);
          }
        }}
      >
        <div className="app-icon">
          <ApplicationIcon />
        </div>
        <div
          className={`app-content ${
            item?.description ? 'app-with-description' : 'app-without-description'
          }`}
        >
          <h5 className="app-title">{item?.displayName ? item.displayName : item.endpointName}</h5>
          {item?.description && (
            <Tooltip
              title={item?.description}
              aria-label="description"
              arrow
              classes={{
                tooltip: classes.toolTip,
                arrow: classes.arrow,
              }}
            >
              <p className="app-description" style={{ width: cardWidth / 2 + 20 }}>
                {item?.description}
              </p>
            </Tooltip>
          )}
        </div>
      </div>
      <div className="app-button">
        <Button
          color="primary"
          disableElevation
          endIcon={<ArrowRight />}
          onClick={() => appNavigation(item)}
        >
          {intl.formatMessage({
            id: 'NewRequestHome.AppsRecommended.RequestButton.Label',
          })}
        </Button>
      </div>
    </div>
  ));

  const swiperItems = appRecommendations.map((item) => (
    <SwiperSlide>
      <div className="recommended-card">
        <div
          className={`app-section ${
            item?.description ? 'appSection-with-description' : 'appSection-without-description'
          }`}
          role="button"
          tabIndex="0"
          onClick={() => appNavigation(item)}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              appNavigation(item);
            }
          }}
        >
          <div className="app-icon">
            <ApplicationIcon />
          </div>
          <div
            className={`app-content ${
              item?.description ? 'app-with-description' : 'app-without-description'
            }`}
          >
            <h5 className="app-title">
              {item?.displayName ? item.displayName : item.endpointName}
            </h5>
            {item?.description && (
              <Tooltip
                title={item?.description}
                aria-label="description"
                arrow
                classes={{
                  tooltip: classes.toolTip,
                  arrow: classes.arrow,
                }}
              >
                <p className="app-description">{item?.description}</p>
              </Tooltip>
            )}
          </div>
        </div>
        <div className="app-button">
          <Button
            color="primary"
            disableElevation
            endIcon={<ArrowRight />}
            onClick={() => appNavigation(item)}
          >
            {intl.formatMessage({
              id: 'NewRequestHome.AppsRecommended.RequestButton.Label',
            })}
          </Button>
        </div>
      </div>
    </SwiperSlide>
  ));

  return (
    <div className="apps-recommendation-section">
      <div className="recommendation-title">
        <h4 className="apps-recommendation-title">
          {intl.formatMessage({ id: 'NewRequestHome.AppsRecommended.Title' })}
        </h4>
        <p className="apps-recommendation-description">
          {intl.formatMessage({
            id: 'NewRequestHome.AppsRecommended.Description',
          })}
        </p>
      </div>
      <div className="recommendation-cards-section recommendation-pagination-view">
        <div className="pagination-section d-flex">
          {page !== 1 && (
            <Button
              variant="contained"
              color="primary"
              disableElevation
              className="slide-button prev-button"
              onClick={onPreviousPage}
            >
              <ChevronLeft />
            </Button>
          )}
          <div className="recommendation-apps" ref={ref}>
            {items}
          </div>
          {pageCount() !== page && (
            <Button
              variant="contained"
              color="primary"
              disableElevation
              className="slide-button next-button"
              onClick={onNextPage}
            >
              <ChevronRight />
            </Button>
          )}
        </div>
        <Pagination
          hideNextButton
          hidePrevButton
          boundaryCount={pageCount()}
          count={pageCount()}
          page={page}
          color="primary"
          onChange={handleChange}
          size={'small'}
        />
      </div>
      <div className="recommendation-cards-section recommendation-swiper-view">
        <Swiper
          slidesPerView={'auto'}
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          className="mySwiper"
        >
          {swiperItems}
        </Swiper>
      </div>
    </div>
  );
};

export default injectIntl(AppsRecommendation);
