import React from 'react';
import {
  MenuItem, withStyles,
} from '@material-ui/core';

const CustomMenuItem = withStyles(() => ({
  root: {
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: '#212328',
    '&.Mui-disabled': {
      opacity: 1,
    },
  },
}))(MenuItem);

const CustomMenuItemComponent = ({ item }) => (
  <CustomMenuItem
    key={item}
    disabled
  >
    {item.label}
  </CustomMenuItem>
);

export default CustomMenuItemComponent;
