export { type MfaPromptState as VerificationState } from './MfaPromptState';

import type RootState from './RootState';
export { type RootState };

import * as privateActions from './actions';
import publicActions from './actions';
export { publicActions, privateActions };

export { default as reducer, INITIAL_STATE } from './reducer';

import {
  type TypedUseSelectorHook,
  useDispatch as useGenericDispatch,
  useSelector as useGenericSelector,
} from 'react-redux';
export const useSelector: TypedUseSelectorHook<RootState> = useGenericSelector;

import { type ThunkDispatch } from 'redux-thunk';
import { type ActionType } from 'typesafe-actions';
export type AppDispatch = ThunkDispatch<
  RootState,
  unknown,
  ActionType<typeof publicActions & Omit<typeof privateActions, 'default'>>
>;
export const useDispatch: () => AppDispatch = useGenericDispatch;
