import React from 'react';
import PropTypes from 'prop-types';
import styles from './UserAttributes.module.scss';
import { EllipsedLabel } from 'ui-components/src';
import { injectIntl } from 'react-intl';
import { userAttributePrefix, userAttributePostfix } from 'ui-common/src/lib/utils';

const UserAttributes = ({ userAttributes, intl }) => {
  const userAttri = userAttributes ? userAttributes[0] : {};
  const userAttriPre = Object.keys(userAttri).filter(item => userAttri[item]).length > 0;
  return (
    <div className={styles.endpointAttribute}>
      {userAttriPre && <h6>{intl.formatMessage({
        id: 'RequestHistory.UserAttributes.Title',
      })}</h6>}
      <div className={`${styles.dynamicAttributeHeader} flex-wrap`}>
        {Object.keys(userAttri).map(attr => {
          if (userAttri[attr])
            return (
              <div className="d-flex flex-column  col-md-4 flex-wrap pl-0">
                <span className={styles.dynamicLabelKey}>{intl.formatMessage({
                  id: `${userAttributePrefix}${attr}${userAttributePostfix}`,
                })}</span>
                <span className={`${styles.dynamicColor} mb-2`}> <EllipsedLabel label={userAttri[attr]} maxLength={45} /></span>
              </div>
            );
          return null;
        })}
      </div>
    </div>
  );
};
UserAttributes.propTypes = {
  userAttributes: PropTypes.shape({
    groupName: PropTypes.string,
    attributesList: PropTypes.shape({
      attributeKey: PropTypes.string,
      format: PropTypes.string,
    }),
  }).isRequired,
};
export default injectIntl(UserAttributes);
