import './Global404.css';

import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { push } from 'react-router-redirect';

function Global404() {
  const { IDWMS_API_URL, APPLICATION_LOGO } = useSelector(state => state.user.domainInfo || {});

  return (
    <div className='Global404-pageNotFound'>
      <a href='/request/requestHome'>
        <img
          className='Global404-logo'
          src={`${IDWMS_API_URL}/resource/image/app/${APPLICATION_LOGO}`}
          alt='app-logo'
        />
      </a>
      <div className="Global404-error-content">
        <h1 className="Global404-text-header">404</h1>
        <div className="Global404-error-body">Page Not Found</div>
        <p className="Global404-error-details">
          The page you are looking has been removed or temporarily unavailable
        </p>
        <Link
          to="/request/requestHome"
          className="Global404-btn-back"
          onClick={() => push('/request/requestHome')}
        >
          Back to Home
        </Link>
      </div>
      <div className="Global404-error-illustration">
        <img src="/ECMv6/assets/images/illustration-404.png" alt="Page not found" />
      </div>
    </div>
  );
}

export default Global404;
