import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles'; // styled,
/* import SwipeableViews from 'react-swipeable-views';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme'; */

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

import Box from '@material-ui/core/Box';
import './RequestDetailsTabs.scss';
import { SaviyntGrid } from '../Grid/SaviyntGrid';
import { mockDataRoleSummary } from './mockDataRoleSummary';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}
/* ToDo - const StyledTab1 = styled(Tab)({
  // minHeight: 'auto',
  padding: 0,
  marginRight: 10,
  flexDirection: 'inherit',
  textTransform: 'initial',
  width: 50,
}); */

const StyledTab = withStyles(() => ({
  root: {
    padding: 0,
    marginRight: 10,
    flexDirection: 'inherit',
    textTransform: 'initial',
    minWidth: 120,
    width: 100,
    color: 'var(--grey-header)'
  },
  fullWidth: {
    flexGrow: 0,
  },
}))(props => <Tab fullWidth {...props} />);

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    minHeight: 15,
    borderTopStyle: 'unset',
    width: '100%',
  },
  colorDefault: {
    color: 'red',
    boxShadow: 10,
  },
}));
const columns = [
  {
    viewKey: 'entitlementvalue',
    key: 'entitlementvalue',
    label: 'ENTITLEMENT VALUE',
  },
  {
    viewKey: 'entitlementtype',
    key: 'entitlementtype',
    label: 'ENTITLEMENT TYPE',
  },
  {
    viewKey: 'systemname',
    key: 'systemname',
    label: 'SYSTEM NAME',
  },
  {
    viewKey: 'description',
    key: 'description',
    label: 'DESCRIPTION',
  },
];
const data = [
  {
    entitlementvalue: 'AWSStack1019- SaviyntNetwork Adminstrator-18Z9VRHBO6NAL',
    entitlementtype: 'AWS Role',
    systemname: 'AWS-928312015323-Asgard',
    description: 'some description',
  },
  {
    entitlementvalue: 'AWSStack1019- SaviyntNetwork Adminstrator-18Z9VRHBO6NAL',
    entitlementtype: 'AWS Role',
    systemname: 'AWS-928312015323-Asgard',
    description: 'some description',
  },
  {
    entitlementvalue: 'AWSStack1019- SaviyntNetwork Adminstrator-18Z9VRHBO6NAL',
    entitlementtype: 'AWS Role',
    systemname: 'AWS-928312015323-Asgard',
    description: 'some description',
  },
];
export default function RequestDetailsTabs() {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  return (
    <div>
      <AppBar
        position="static"
        color="default"
        style={{ boxShadow: 'none', borderBottomStyle: 'inset', left: 20 }}
      >
        <Tabs
          className={classes.root}
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
          fullWidth
          TabIndicatorProps={{
            style: {
              height: 3,
              borderTopStyle: 'unset',
              boxShadow: 'none',
              width: 100,
              marginLeft: 15,
            },
          }}
          style={{ background: '#FFFFFF' }}
          tabItemContainerStyle={{ backgroundColor: '#0D47A1' }}
          inkBarStyle={{ backgroundColor: '#FFC107', color: 'rgba(255, 255, 255, 0)' }}
        >
          <StyledTab
            className={classes.root}
            fullWidth={false}
            style={{ width: '100px' }}
            label="Role Summary"
            {...a11yProps(0)}
          />
          <StyledTab
            className={classes.root}
            fullWidth={false}
            label="Entitlement"
            {...a11yProps(1)}
          />
          <StyledTab
            className={classes.root}
            fullWidth={false}
            label="Insights"
            {...a11yProps(2)}
            disabled
          />
        </Tabs>
      </AppBar>
      {/* ToDo<SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      > */}
      <TabPanel value={value} index={0} dir={theme.direction}>
        <div className="user-general-summary-tab">
          <div className="tab-container">
            <div className="tab-body">
              <div className="basics-container">
                <h6 className="basics-label">User Properties - {mockDataRoleSummary.rolename}</h6>
                <div className="d-flex body-content justify-content-between mt-4">
                  <div className="d-flex flex-column">
                    <span className="label-key">GROUP NAME - </span>
                    <span className="label-value mt-1">ReadOnlyAccess</span>
                  </div>
                  <div className="d-flex flex-column">
                    <span className="label-key">CREATION NAME</span>
                    <span className="label-value mt-1">2019-02-18 13:51 PDT</span>
                  </div>
                </div>

                <div className="d-flex body-content justify-content-between mt-4">
                  <div className="d-flex flex-column">
                    <span className="label-key">USER ARN</span>
                    <span className="label-value mt-1">arn:aws:iam::661222050851:user</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </TabPanel>
      <TabPanel value={value} index={1} dir={theme.direction}>
        <div className="ele-container">
          <div className="entitlement-heading">Entitlement Summary</div>
          <span className="entitlement-type-header">Entitlement Type</span>
          <div className="entitlement-type-section">
            <span className="badge badge-ele">AWS Role(4)</span>
            <span className="badge badge-ele">AWS Policy(4)</span>
            <span className="badge badge-ele">Ec2Instance(4)</span>
            <span className="badge badge-ele">AWS Group(4)</span>
            <span className="badge badge-ele">SNSTopic(1)</span>
          </div>
          <span className="system-name-heading">System Name</span>
          <div className="system-type-section">
            <span className="badge badge-sys">AWS-928312015323-Asgard(8)</span>
            <span className="badge badge-ele">AWS Dev(12)</span>
          </div>
          <div className="ele-content">
            <span className="entitlement-heading">20 Entitlement</span>
            <SaviyntGrid
              columns={columns}
              data={data}
              setAppliedFilters={() => {}}
              appliedFilters={{}}
              reqBodyOptions={{}}
              setReqBodyOptions={() => {}}
              topOffset={24 + 54}
            />
          </div>
        </div>
      </TabPanel>
      {/* ToDo - <TabPanel value={value} index={2} dir={theme.direction}>
        <div className="user-general-summary-tab">
          <div className="tab-container">
            <div className="tab-body">
              <div className="basics-container">
                <h6 className="basics-label">User Properties</h6>
                <div className="d-flex body-content justify-content-between mt-4">
                  <div className="d-flex flex-column">
                    <span className="label-key">GROUP NAME</span>
                    <span className="label-value mt-1">ReadOnlyAccess</span>
                  </div>
                  <div className="d-flex flex-column">
                    <span className="label-key">CREATION NAME</span>
                    <span className="label-value mt-1">2019-02-18 13:51 PDT</span>
                  </div>
                </div>

                <div className="d-flex body-content justify-content-between mt-4">
                  <div className="d-flex flex-column">
                    <span className="label-key">USER ARN</span>
                    <span className="label-value mt-1">arn:aws:iam::661222050851:user</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </TabPanel> */}
      {/* </SwipeableViews> */}
    </div>
  );
}
RequestDetailsTabs.propTypes = {
  value: PropTypes.number.isRequired,
};
