import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Cookies } from 'react-cookie';

export default function useMfaVerification() {
  const { FEATURE_HTTPONLY_ACCESSTOKEN, GATEWAY_API_URL, FEATURE_MFA } = useSelector(
    (state) => state.user.domainInfo || {}
  );

  useEffect(() => {
    async function mfaVerification() {
      const cookies = new Cookies();

      if (!FEATURE_MFA || !GATEWAY_API_URL) {
        return;
      }

      const response = await fetch(`${GATEWAY_API_URL}/api/authms/api/mfa`, {
        method: 'GET',
        headers: {
          ...(FEATURE_HTTPONLY_ACCESSTOKEN || {
            Authorization: `${cookies.get('token_type')} ${cookies.get('access_token')}`,
          }),
        },
      });

      if (!response.ok) {
        return;
      }

      const mfa = await response.json();
      if (mfa.requiresRegistration) {
        window.location.replace('/ECM/mfa/registerMfa');
        return;
      }

      if (mfa.enabled && !mfa.loginVerified) {
        window.location.replace('/ECM/mfa/verifyMfa');
        return;
      }
    }

    mfaVerification();
  }, [FEATURE_HTTPONLY_ACCESSTOKEN, GATEWAY_API_URL, FEATURE_MFA]);
}
