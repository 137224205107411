import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { createBrowserHistory } from 'history';
import { BrowserRouter } from 'react-router-dom';
import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import ScopedCssBaseline from '@material-ui/core/ScopedCssBaseline';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import SideMenu from './SideMenu/SideMenu';
import Header from './Header/Header';
import styles from './Layout.module.css';
import { InitializeSideMenu } from '../actions/sideMenuAction';
import { usePendo } from '@saviynt/common';
import { InitializeProfileMenu, InitializeGetHelp } from '../actions/profileMenuAction';
import { useTheme } from '../features/AdminHome/views/BrandingConfig/utils/helper';
import {
  getActiveFavIcon,
  getActiveHeaderLogo,
  getActiveLogo,
} from '../features/AdminHome/redux/adminHome-actions';
import { appMessages, getIsMenuPinnedSuccess } from 'ui-common/src/utils/LoginActions';
import { LOCALE } from 'ui-common/src/constants';
import { mfaPrompt } from '@saviynt/nav';
import { useIntl } from 'react-intl';
const history = createBrowserHistory({ window });
const DESKTOP_DRAWER_WIDTH = 240;
const MOBILE_DRAWER_WIDTH = 280;
export const IS_MENU_PINNED_KEY = 'gen2.layout.isMenuPinned';
const SLIDE_DURATION = 300;
const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(55, 81, 255, 1)',
      contrastText: '#fff',
    },
    typography: {
      fontFamily: 'Rubik, sans-serif',
      fontSize: 12,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'capitalize',
        lineHeight: 'inherit',
        border: 'none',
        borderRadius: 3,
        fontSize: 12,
        textAlign: 'center',
        letterSpacing: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
      },
      contained: {
        padding: '9px 15px',
      },
    },
  },
});

export const useStyles = makeStyles((theme) => ({
  sideMenuIcon: {
    paddingRight: '22px',
  },
  appBar: {
    zIndex: theme.zIndex.drawer,
    boxShadow: 'rgb(0 0 0 / 6%) 0 4px 12px',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: DESKTOP_DRAWER_WIDTH,
    width: `calc(100% - ${DESKTOP_DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 0,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: DESKTOP_DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: DESKTOP_DRAWER_WIDTH,
    // background: 'var(--SIDEMENU-BACKGROUND)',
    color: 'var(--SIDEMENU-TEXT-COLOR)',
    overflow: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      // duration: theme.transitions.duration.enteringScreen,
      duration: `${SLIDE_DURATION}ms`,
    }),
    background: 'inherit',
  },
  drawerClose: {
    // background: 'var(--SIDEMENU-BACKGROUND)',
    color: 'var(--SIDEMENU-COLOR)',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      // duration: theme.transitions.duration.leavingScreen,
      duration: `${SLIDE_DURATION}ms`,
    }),
    overflowX: 'hidden',
    overflowY: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: 60,
    },
    background: 'inherit',
  },
  toolbar: {
    flex: '0 0 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    ...theme.mixins.toolbar,
  },
  toolbarCentered: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    ...theme.mixins.toolbar,
  },
  toolbarMobileCentered: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  navBar: {
    paddingTop: 0,
    flex: '1 1 auto',
    overflow: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&::-moz-scrollbar': {
      display: 'none',
    },
    scrollbarWidth: 'none',
    scrollbarColor: 'transparent transparent',
  },
  searchText: {
    display: 'block',
    margin: '8px 16px',
    color: 'var(--SIDEMENU-SUBTEXT-COLOR)',
  },
  leftNavigationFooter: {
    flex: '0 0 auto',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '1rem',
    color: 'var(--SIDEMENU-TEXT-COLOR)',
    background: 'var(--SIDEMENU-BACKGROUND)',
    padding: 0,
    overflow: 'hidden',
    opacity: '0',
    whiteSpace: 'break-spaces',
    height: '90px',
    wordBreak: 'break-word',
    textAlign: 'center',
  },
  leftNavigationFooterHide: {
    width: 0,
    padding: 0,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      // duration: theme.transitions.duration.leavingScreen,
      duration: '300ms',
    }),
  },
  leftNavigationFooterShow: {
    width: 'inherit',
    padding: '1rem',
    opacity: '1',
  },
  notMobileFooter: {
    overflow: 'hidden',
  },
  mobileDrawer: {
    width: MOBILE_DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    overflowY: 'hidden',
    background: 'var(--SIDEMENU-BACKGROUND)',
    // background: getBackgroundColor(),
    color: 'var(--SIDEMENU-COLOR)',
  },
}));

const addSideMenuClasses = (sideMenu, isMenuPinned) => {
  const ecmLayout = window.document.getElementById('pageLayout');
  if (ecmLayout) {
    if (!sideMenu) {
      ecmLayout.setAttribute('class', 'LayoutWithoutSideNav');
    } else if (sideMenu && isMenuPinned) {
      ecmLayout.setAttribute('class', 'LayoutWithSideNavMenuOpen');
    } else if (sideMenu && !isMenuPinned) {
      ecmLayout.setAttribute('class', 'LayoutWithSideNav');
    }
  }
};

function setMenuScrollPosition() {
  // get reference to the left nav, not the header nav.
  const allNavs = Array.from(document.querySelectorAll('nav[class^="MuiList-root"]'));
  const scrollContainer = allNavs?.filter(
    (nav) => !nav.closest('div[class^="Header_profileMainContainer"]')
  )[0];
  const selectedIndicator = scrollContainer?.querySelector('[class^="MenuItem_selected"]');
  const selectedItem = selectedIndicator ? selectedIndicator.nextElementSibling : null;

  if (!selectedItem) {
    return;
  }

  selectedItem.scrollIntoView({ behavior: 'instant', block: 'center' });
  selectedItem.closest('div[class^="MuiPaper-root"]').scrollTop = 0;
}

const Layout = ({ children, isVisible, hideSideMenu }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const classes = useStyles(theme);
  const sideMenuData = useSelector((state) => state.app.sideMenu);
  const isMenuPinned = useSelector((state) => state.user?.isMenuPinned);
  const [expandSideMenu, setExpandSideMenu] = useState(isMenuPinned);
  const [collapseSideMenu, setCollapseSideMenu] = useState(!isMenuPinned);
  const sideMenuAvailable = sideMenuData !== null && sideMenuData?.length > 0;
  const [mobileDrawer, setMobileDrawer] = React.useState(false);
  const [lsGen2ThemeState, setLSGen2ThemeState] = useState();
  const [showFooter, setShowFooter] = useState(isMenuPinned);
  const activeTheme = useSelector((state) => state.adminHome?.brandingConfig?.activeTheme) || {};
  const locale = useSelector((state) => state.user.userInfo?.userSavRoleMap?.locale);
  const gatewayApiUrl = useSelector((state) => state.user.domainInfo?.GATEWAY_API_URL);

  usePendo();
  const { setThemeVars } = useTheme();

  const showSideMenu = hideSideMenu || sideMenuAvailable;

  addSideMenuClasses(sideMenuAvailable, isMenuPinned);

  useEffect(() => {
    const handleStorageChange = () => {
      setLSGen2ThemeState(localStorage.getItem('activeThemeGen2'));
    };

    window.addEventListener('storage', handleStorageChange);

    if (!sideMenuAvailable) {
      dispatch(InitializeSideMenu());
    }
    dispatch(InitializeProfileMenu());
    dispatch(InitializeGetHelp());
    setThemeVars();

    const footer = window.document.querySelector('.footer');
    if (footer) {
      footer.style.visibility = 'hidden';
    }

    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  useEffect(() => {
    if (activeTheme) {
      setThemeVars();
      dispatch(getActiveLogo());
      dispatch(getActiveHeaderLogo());
      dispatch(getActiveFavIcon());
    }
  }, [activeTheme]);

  useEffect(() => {
    if (isMenuPinned) setTimeout(() => setMenuScrollPosition(), 0);
  }, [showSideMenu]);

  useEffect(() => {
    // Show the correct language to the user, and cache preferred language in
    // localStorage.

    // On page load, we retrieve translations in language specified in
    // localStorage. If none is specified, we optimistically start retrieving
    // English. As page load continues, we retrieve user's preferred language
    // (`locale`) from the server.

    // If preferred language differs from what we have in localStorage, we
    // update localStorage and retrieve translations in preferred language. If
    // preferred language is `null` (meaning they have never chosen a language),
    // we retrieve English - which may have already been loaded and results in a
    // noop.
    if (gatewayApiUrl && locale !== undefined && localStorage.getItem(LOCALE) !== locale) {
      localStorage.setItem(LOCALE, locale);
      dispatch(appMessages(locale ?? 'en', gatewayApiUrl));
    }
  }, [locale, gatewayApiUrl]);

  const handleDrawerOpen = () => {
    setExpandSideMenu(true);
    setCollapseSideMenu(false);
    setShowFooter(true);

    if (!isMenuPinned) setTimeout(() => setMenuScrollPosition(), 0);
  };

  const handleDrawerClose = () => {
    setExpandSideMenu(false);
    setCollapseSideMenu(true);
    setShowFooter(false);
  };

  // on click of toggle button
  const handleDrawerClick = () => {
    if (isMenuPinned) {
      handleDrawerClose();
    }
    dispatch(getIsMenuPinnedSuccess({ isMenuPinned: !isMenuPinned }));
  };

  const handleMobileDrawerClick = () => {
    setMobileDrawer(!mobileDrawer);
    setExpandSideMenu(true);
    setTimeout(() => setMenuScrollPosition(), 0);
  };

  const handleSearchInactive = () => {
    setTimeout(() => setMenuScrollPosition(), 0);
  };

  return (
    <BrowserRouter history={history}>
      <div className={classnames(styles.page)}>
        {/* Commenting the code for future use, uncomment along with the below footer */}
        {/* <div className={styles.container}> */}
        <ScopedCssBaseline>
          <ThemeProvider theme={theme}>
            {isVisible && (
              <Header
                classes={classes}
                isSideMenuAvailable={showSideMenu}
                showLogo={!expandSideMenu && !isMenuPinned}
                isVisible
                handleMobileDrawerClick={handleMobileDrawerClick}
              />
            )}
            {showSideMenu && isVisible && (
              <SideMenu
                classes={classes}
                expandSideMenu={expandSideMenu || isMenuPinned}
                collapseSideMenu={collapseSideMenu && !isMenuPinned}
                isSideMenuAvailable={showSideMenu}
                handleDrawerClick={handleDrawerClick}
                handleDrawerOpen={handleDrawerOpen}
                handleDrawerClose={handleDrawerClose}
                location={`${window.location.pathname}${window.location.search}`}
                mobileDrawer={mobileDrawer}
                handleMobileDrawerClick={handleMobileDrawerClick}
                showFooter={showFooter || isMenuPinned}
                slideDuration={SLIDE_DURATION}
                handleSearchInactive={handleSearchInactive}
              />
            )}
          </ThemeProvider>
        </ScopedCssBaseline>
        <main className={classes.content}>
          <div aria-label="content">{children}</div>
        </main>
      </div>
      <mfaPrompt.Component intl={intl} />
    </BrowserRouter>
  );
};

Layout.propTypes = {
  isVisible: PropTypes.bool,
  hideSideMenu: PropTypes.bool,
};

Layout.defaultProps = {
  isVisible: true,
  hideSideMenu: false,
};

export default Layout;
