/* eslint-disable import/prefer-default-export */
/**
 * A utility function to know the div element is DOM view or not .
 * @param {*} el - element
 * @returns
 */
export const isInViewport = (el) => {
  const rect = el.getBoundingClientRect();
  const viewportWidth =
    window.innerWidth || document.documentElement.clientWidth;
  const viewportHeight =
    window.innerHeight || document.documentElement.clientHeight;

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= viewportHeight &&
    rect.right <= viewportWidth &&
    rect.top <= viewportHeight - el.clientHeight &&
    rect.left <= viewportWidth - el.clientWidth
  );
};
