import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Menu, Fade,
} from '@material-ui/core';

const DropDownMenu = withStyles({
  paper: {
    background: '#FFFFFF',
    boxShadow: '0px 2px 16px 2px rgba(17, 21, 48, 0.08)',
    borderRadius: '3px',
    marginTop: '48px',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    TransitionComponent={Fade}
    {...props}
  />
));

export default DropDownMenu;
