
import React from 'react';

const SideMenuIconBack = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M20.5 6.16675C20.7761 6.16675 21 6.39061 21 6.66675C21 6.94289 20.7761 7.16675 20.5 7.16675H9.5C9.22386 7.16675 9 6.94289 9 6.66675C9 6.39061 9.22386 6.16675 9.5 6.16675H20.5Z" fill="var(--SIDEMENU-ICON-HAMBURGER)"/>
<path d="M20.5 11.5001C20.7761 11.5001 21 11.7239 21 12.0001C21 12.2762 20.7761 12.5001 20.5 12.5001H4.70719L6.5203 14.3132C6.71556 14.5085 6.71556 14.825 6.5203 15.0203C6.32504 15.2156 6.00846 15.2156 5.81319 15.0203L3.15188 12.359C3.05821 12.2681 3 12.1409 3 12.0001C3 11.8793 3.04285 11.7685 3.11418 11.682C3.12437 11.6697 3.13514 11.6578 3.14645 11.6465C3.14643 11.6465 3.14646 11.6465 3.14645 11.6465L5.8132 8.97986C6.00847 8.7846 6.32505 8.7846 6.52031 8.97987C6.71557 9.17513 6.71556 9.49172 6.5203 9.68697L4.70713 11.5001H20.5Z" fill="var(--SIDEMENU-ICON-HAMBURGER)"/>
<path d="M21 17.3334C21 17.0573 20.7761 16.8334 20.5 16.8334H9.5C9.22386 16.8334 9 17.0573 9 17.3334C9 17.6096 9.22386 17.8334 9.5 17.8334H20.5C20.7761 17.8334 21 17.6096 21 17.3334Z" fill="var(--SIDEMENU-ICON-HAMBURGER)"/>
</svg>

);

export default SideMenuIconBack;

