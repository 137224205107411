import React from 'react';
import { push } from 'react-router-redirect';
import './Global404.scss';
import { useSelector } from 'react-redux';

const Module404 = () => {
  const { IDWMS_API_URL, APPLICATION_LOGO } = useSelector(state => state.user.domainInfo || {});

  return (
    <div className="pageNotFound">
      <a href="/request/requestHome">
        <img
          className="logo"
          src={`${IDWMS_API_URL}/resource/image/app/${APPLICATION_LOGO}`}
          alt="app-logo"
        />
      </a>
      <div className="d-flex flex-column  justify-content-space-around">
        <h1 className="text-header">404</h1>
        <div className="error-body">Page Not Found</div>
        <p className="error-details">
          The page you are looking has been removed or temporarily unavailable
        </p>
        <button
          type="button"
          onClick={() => push('/request/requestHome')}
          className="btn btn-primary btn-hback"
        >
          Back to Home
        </button>
      </div>
      <div className="d-none d-lg-block error-illustration">
        <img src="/ECMv6/assets/images/illustration-404.png" alt="Page not found" />
      </div>
    </div>
  );
};

export default Module404;
