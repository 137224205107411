import { useEffect, useState } from 'react';
import { Logger } from '@saviynt/common';

import { getFileUploadMetadataApi } from '../utilities/api/getDataFromApi';

const REQUEST_TYPES = {
  MAX_SIZE: 'REQUEST_ATTACHMENT_MAX_FILE_SIZE',
  MAX_COUNT: 'REQUEST_ATTACHMENT_MAX_FILE_COUNT',
};

// file types are hardcoded for now:
const ALLOWED_FILE_TYPES =
  '.xls,.xlsx,.csv,.txt,.doc,.docx,.pdf,.msg,.ppt,.pptx,.rtf,.png,.jpeg,.jpg,.xml,.json';

const BYTES_PER_MB = 1024 * 1024;

const MAX_FILE_SIZE_FALLBACK = 1 * BYTES_PER_MB; // 1MB
const MAX_FILE_COUNT_FALLBACK = 5;

/**
 * Custom hook for requesting upload metadata.
 * The metadata object contains information such as
 * max file size, max file count, and allowed file types.
 * If an error occurs during the API call, default values are used for the metadata.
 *
 * @returns {Object} The file upload metadata object.
 */

function useRequestUploadsMetaData() {
  const [fileUploadMetaDataObj, setFileUploadMetaDataObj] = useState(null);

  useEffect(() => {
    const fetchMetadata = async () => {
      try {
        const metadataSize = getFileUploadMetadataApi(REQUEST_TYPES.MAX_SIZE);
        const metadataCount = getFileUploadMetadataApi(REQUEST_TYPES.MAX_COUNT);

        const [responseFileSize, responseFileCount] = await Promise.all([
          metadataSize,
          metadataCount,
        ]);

        setFileUploadMetaDataObj({
          MAX_FILE_SIZE: responseFileSize?.configData || MAX_FILE_SIZE_FALLBACK,
          MAX_FILE_COUNT:
            responseFileCount?.configData || MAX_FILE_COUNT_FALLBACK,
          ALLOWED_FILE_TYPES,
        });
      } catch (error) {
        Logger.error('File Upload Metadata Error:', error);
        // Error defaults
        setFileUploadMetaDataObj({
          MAX_FILE_SIZE: MAX_FILE_SIZE_FALLBACK,
          MAX_FILE_COUNT: MAX_FILE_COUNT_FALLBACK,
          ALLOWED_FILE_TYPES,
        });
      }
    };

    fetchMetadata();
  }, []);

  return fileUploadMetaDataObj;
}

export default useRequestUploadsMetaData;
