import React, { useState, useRef } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import InputBase from '@material-ui/core/InputBase';
// import SearchIcon from '@material-ui/icons/Search';
import './GridSearch.scss';

const SearchIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 28 29">
      <g fill="none" fill-rule="evenodd" opacity=".3">
          <g fill="#000" fill-rule="nonzero">
              <g>
                  <g>
                      <path d="M19.08 18.254l4.666 4.667-.825.825-4.667-4.667.825-.825zm-6.83-14.17c4.51 0 8.167 3.656 8.167 8.166s-3.657 8.167-8.167 8.167-8.167-3.657-8.167-8.167S7.74 4.083 12.25 4.083zm0 1.166c-3.866 0-7 3.134-7 7s3.134 7 7 7 7-3.134 7-7-3.134-7-7-7z" transform="translate(-122 -183) translate(114 179) translate(8 4)"/>
                  </g>
              </g>
          </g>
      </g>
  </svg>
)


const GridSearch = ({ globalSearchText, setGlobalSearchText, intl }) => {
  const [searchText, setSearchText] = useState(globalSearchText);
  const searchRef = useRef(null);
  const inputRef = useRef();
  const onChangeSearchText = e => {
    setSearchText(e.target.value);
    if (e.target.value.length > 2) {
      if (e.key === 'Enter') {
        setGlobalSearchText(e.target.value);
        setSearchText('');
      }
    }
  };

  const clearValue = () => {
    setSearchText('');
    setGlobalSearchText('');
  };

  return (
    <div ref={searchRef} className="d-flex">
      <div className="grid-searchfull">
        <SearchIcon className="gridSearch-icon" fontSize="large" color="disabled" />
        <InputBase
          className="searchBox"
          value={searchText}
          placeholder={intl.formatMessage({ id: 'GridSeach.InputBase.placeholder' })}
          onChange={onChangeSearchText}
          onKeyPress={onChangeSearchText}
          margin="dense"
          ref={inputRef}
          inputProps={{
            tabIndex: 1,
            style: { fontSize: 12, color: '#72777f', paddingTop: 0, paddingBottom: 0 },
            'aria-label': intl.formatMessage({ id: 'GridSeach.InputBase.arialLabel' }),
          }}
        />
        {/* {searchText.length > 0 && ( */}

        <span
          className={`${searchText && searchText.length > 0 ? 'iconDisplay' : 'iconHidden'
            } list-close pull-right pull-down`}
          onClick={() => clearValue()}
          role="presentation"
        >
          <svg width="9.193" height="9.191">
            <g
              fill="none"
              stroke="#969696"
              strokeLinecap="round"
              strokeMiterlimit="10"
              strokeWidth="1.5"
            >
              <path data-name="CANCEL ICON2" d="M1.06 1.061l7.072 7.071" />
              <path data-name="CANCEL ICON1" d="M8.132 1.061L1.061 8.132" />
            </g>
          </svg>
        </span>
      </div>
    </div>
  );
};

GridSearch.propTypes = {
  setGlobalSearchText: PropTypes.func.isRequired,
  globalSearchText: PropTypes.string.isRequired,
  intl: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string.isRequired,
      formatMessage: PropTypes.func.isRequired,
    })
  ).isRequired,
};

export default injectIntl(GridSearch);
