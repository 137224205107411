const addParentMenu = (data, parentID = []) => {
  const result = data.map((it) => {
    if (it.subMenu?.length > 0) {
      return {
        ...it,
        parentID: [...parentID],
        subMenu: addParentMenu(it.subMenu, [...parentID, it.id]),
      };
    }

    return {
      ...it,
      parentID: [...parentID],
    };
  });

  return result;
};

export default addParentMenu;
