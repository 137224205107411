import React, { useState, useEffect, useRef, createRef } from 'react';
import PropTypes from 'prop-types';
import styles from './ScrollNavigator.module.scss';
import scrollTo from './scrollHelper';

// const getDuration = (from, to, increment = 1, maxDuration = 300) => {
//   return Math.min(maxDuration, Math.abs(from - to) * increment);
// };

const ScrollNavigator = props => {
  const { children, navComponents } = props;
  const flatChildren = Array.isArray(children) ? children.flat().filter(e => !!e) : [];
  const shiftedNav = [
    ...Array(Math.max(0, flatChildren.length - navComponents.length)).fill(''),
    ...navComponents,
  ];
  const viewport = useRef(null);
  const sectionRefs = useRef([...Array(20)].map(() => createRef()));
  const [currHeight, setCurrHeight] = useState(0);
  const [mouseHeight, setMouseHeight] = useState(0);
  const [anchorIndex, setAnchorIndex] = useState(0);
  const [hoverIndex, setHoverIndex] = useState(0);
  const [hoverMode, setHoverMode] = useState(false);

  const getNavIndex = () => (hoverMode ? hoverIndex : anchorIndex);

  const sectionHeights = [];
  const OFFSET_SPACE = 5;
  const DEFAULT_LAPSE = 150; // ss

  function resetState() {
    setCurrHeight(0);
    setMouseHeight(0);
    setAnchorIndex(0);
    setHoverIndex(0);
  }

  function clickScroll(idx, lapse) {
    setMouseHeight(currHeight);
    setHoverMode(false);
    const newPosition = sectionHeights[idx];
    return scrollTo({
      element: viewport.current,
      to: newPosition,
      duration: lapse,
      scrollDirection: 'scrollTop',
      callback: () => {
        setAnchorIndex(idx);
        setHoverIndex(idx);
      },
      context: this,
    });
  }

  function updateHoverAnchor() {
    if (mouseHeight >= sectionHeights[hoverIndex + 1]) {
      setHoverIndex(hoverIndex + 1);
    } else if (mouseHeight < sectionHeights[hoverIndex]) {
      setHoverIndex(hoverIndex - 1);
    }
  }

  function handleMouseMove(e) {
    // const bounds = e.target.getBoundingClientRect();
    // const y = e.clientY - bounds.top;
    // setMouseHeight(y + sectionHeights[hoverIndex]);
    const y = e.screenY - 280; // how to get rid of the hard-coded 280
    setMouseHeight(y + currHeight);
    setHoverMode(true);
    updateHoverAnchor();
  }

  function handleHoverScroll() {
    const scrolled = viewport.current.scrollTop - currHeight;
    setMouseHeight(mouseHeight + scrolled || 0);
    setCurrHeight(viewport.current.scrollTop);
    setHoverMode(true);
  }

  function scrollAndUpdate() {
    handleHoverScroll();
    updateHoverAnchor();
  }

  // function updateAnchor() {
  //   if (currHeight >= sectionHeights[anchorIndex + 1]) {
  //     setAnchorIndex(anchorIndex + 1);
  //   } else if (currHeight < sectionHeights[anchorIndex]) {
  //     setAnchorIndex(anchorIndex - 1);
  //   }
  // }
  function handleScroll() {
    setCurrHeight(viewport.current.scrollTop);
    updateHoverAnchor();
  }

  const wrapNav = (base, index) => {
    return (
      <div
        role="button"
        className={`${index === getNavIndex() ? styles.hightlight : ''} ${base ? '' : styles.hide}`}
        onClick={clickScroll.bind(this, index, DEFAULT_LAPSE)}
        onKeyDown={clickScroll.bind(this, index, DEFAULT_LAPSE)}
        tabIndex="0"
      >
        {base}
      </div>
    );
  };

  const wrapSection = (base, index) => {
    return (
      <div
        className={styles.card}
        ref={sectionRefs.current[index]}
        key={`key-${index}`}
        onMouseOver={handleMouseMove}
        onFocus={() => {}}
      >
        {base}
      </div>
    );
  };

  function updateSectionHeights() {
    [...Array(flatChildren.length)]
      .map((_, i) => {
        return sectionRefs.current[i] && sectionRefs.current[i].current
          ? sectionRefs.current[i].current.offsetTop
          : 0;
      })
      .forEach((h, i) => {
        sectionHeights[i] = h - OFFSET_SPACE - viewport.current.offsetTop;
      });
    sectionHeights.push(viewport.current.scrollHeight + 1);
    sectionHeights[0] = 0;
  }

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevNavComponents = usePrevious(navComponents);

  useEffect(() => {
    if (prevNavComponents && navComponents && prevNavComponents.length !== navComponents.length) {
      clickScroll(0, 0);
      resetState();
    }
  }, [navComponents]);

  useEffect(() => {
    updateSectionHeights();
  });

  return (
    <div className={styles.container}>
      <div className={styles.navigator}>{shiftedNav.map(wrapNav)}</div>
      <div
        className={styles.scrollable}
        ref={viewport}
        onScroll={hoverMode ? scrollAndUpdate : handleScroll}
        onMouseMove={handleMouseMove}
      >
        {flatChildren.map(wrapSection)}
      </div>
    </div>
  );
};

ScrollNavigator.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
  navComponents: PropTypes.arrayOf(PropTypes.node),
};

ScrollNavigator.defaultProps = {
  children: [],
  navComponents: [],
};

export default ScrollNavigator;
