/* eslint-disable import/prefer-default-export */
import React from 'react';

export const renderModalPageFooterActionBar = (
  FooterActionBar,
  accordionIsScrolled,
  Button,
  Icon,
  formSubmitHandler,
  shouldSubmitBeFilled,
  isRequestLoading,
  SUBMIT_BTN_TEXT
) => (
  <FooterActionBar
    className='ModalPage-footer'
    isScrolled={accordionIsScrolled}>
    <Button
      kind={shouldSubmitBeFilled ? 'filled' : 'outlined'}
      isLoading={isRequestLoading}
      onClick={() => formSubmitHandler()}
      rightIcon={<Icon kind='arrowRight' />}>
      {SUBMIT_BTN_TEXT}
    </Button>
  </FooterActionBar>
);
