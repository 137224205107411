import { useEffect, useRef } from 'react';
import { debounce } from '@saviynt/common';
import { useQuery } from '@tanstack/react-query';

import { mockData } from '../models/MockData/MockData';
import { getListRowApiFilterAndSearch } from '../utilities/api/getDataFromApi';
import { INPUT_DEBOUNCE_DELAY } from '../utilities/debounce';

const getListRowQueryConfigs = (
  checkedPlatforms,
  checkedAssets,
  checkedRoles,
  searchBarValue,
  isUsingMockData
) => ({
  queryKey: [
    'listRowData',
    checkedPlatforms,
    checkedAssets,
    checkedRoles,
    searchBarValue,
  ],
  queryFn: () =>
    getListRowApiFilterAndSearch(
      checkedPlatforms,
      checkedAssets,
      checkedRoles,
      searchBarValue
    ).then((response) => {
      if (response.ok !== undefined && response.ok === false) {
        throw new Error('getListRowApiFilterAndSearch API call failed.');
      }

      if (isUsingMockData) return mockData;

      return response;
    }),
  refetchOnWindowFocus: true,
  enabled: false,
  retry: 1, // for failed requests
});

/**
 * Custom hook for fetching list row data.
 *
 * @param {string} searchBarValue - value of the search bar.
 * @param {Array} checkedPlatforms - array of selected platforms.
 * @param {Array} checkedAssets - array of selected assets.
 * @param {Array} checkedRoles - array of selected roles.
 * @param {function} setListRowData - function/setter to set the list row data.
 * @returns {Object} - object containing the fetched list row data and various loading/error states.
 */
function useFetchListRowData(
  searchBarValue,
  checkedPlatforms,
  checkedAssets,
  checkedRoles,
  setListRowData,
  isUsingMockData
) {
  const {
    data: fetchedListRowData,
    isLoading: isListRowApiLoading,
    isFetching: isListRowApiFetching,
    error: listRowApiError,
    isSuccess: isListRowApiSuccess,
    isError: isListRowApiError,
    refetch,
  } = useQuery(
    getListRowQueryConfigs(
      checkedPlatforms,
      checkedAssets,
      checkedRoles,
      searchBarValue,
      isUsingMockData
    )
  );

  const debouncedRefetch = useRef(
    debounce(() => {
      refetch();
    }, INPUT_DEBOUNCE_DELAY)
  ).current;

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    // refetch if any deps change from their initial 'blank state'
    if (
      searchBarValue !== '' ||
      checkedPlatforms.length !== 0 ||
      checkedAssets.length !== 0 ||
      checkedRoles.length !== 0
    ) {
      debouncedRefetch();
    }

    return () => {
      debouncedRefetch?.cancel();
    };
  }, [
    searchBarValue,
    checkedPlatforms,
    checkedAssets,
    checkedRoles,
    debouncedRefetch,
  ]);

  useEffect(() => {
    if (isListRowApiSuccess) {
      setListRowData(fetchedListRowData);
    }
  }, [isListRowApiSuccess, fetchedListRowData]);

  return {
    fetchedListRowData,
    isListRowApiLoading,
    isListRowApiFetching,
    listRowApiError,
    isListRowApiSuccess,
    isListRowApiError,
  };
}

export default useFetchListRowData;
