export const AdminHomeLoaded = 'adminHome/initialLoaded';
export const FEATURE_LIST_SUCCESS = 'adminHome/FEATURE_LIST_SUCCESS';
export const FEATURE_LIST = 'adminHome/FEATURE_LIST';
export const UPDATE_FEATURE = 'adminHome/UPDATE_FEATURE';
export const VIEW_PREVIEW_THEME = 'VIEW_PREVIEW_THEME';
export const SHOWV_PREVIEW_MODAL = 'SHOWV_PREVIEW_MODAL';
export const FETCH_THEME_CONFIG_LIST = 'FETCH_THEME_CONFIG_LIST';
export const FETCH_THEME_CONFIG_LIST_SUCCESS = 'FETCH_THEME_CONFIG_LIST_SUCCESS';
export const FETCH_THEME_CONFIG_LIST_FAILURE = 'FETCH_THEME_CONFIG_LIST_FAILURE';
export const UPDATE_THEME_CONFIG_LIST = 'UPDATE_THEME_CONFIG_LIST';
export const UPDATE_THEME_CONFIG_LIST_SUCCESS = 'UPDATE_THEME_CONFIG_LIST_SUCCESS';
export const UPDATE_THEME_CONFIG_LIST_FAILURE = 'UPDATE_THEME_CONFIG_LIST_FAILURE';
export const DOWNLOAD_ATTACHEMENT = 'adminHome/DOWNLOAD_ATTACHEMENT';
export const DOWNLOAD_LOGIN_LOGOUT_LOGO = 'adminHome/DOWNLOAD_LOGIN_LOGOUT_LOGO';
export const UPLOAD_AS_FILE = 'adminHome/UPLOAD_AS_FILE';
export const REQUEST_MANAGE_LOGS_LIST = 'REQUEST_MANAGE_LOGS_LIST';
export const REQUEST_MANAGE_LOGS_LIST_SUCCESS = 'REQUEST_MANAGE_LOGS_LIST_SUCCESS';
export const REQUEST_MANAGE_LOGS_LIST_FAILURE = 'REQUEST_MANAGE_LOGS_LIST_FAILURE';

export const adminHomeLoaded = (adminHomeData) => ({
  type: AdminHomeLoaded,
  payload: adminHomeData,
});

export const getFeatureList = () => ({
  type: FEATURE_LIST,
});

export const featureListSuccess = (featureList) => ({
  type: FEATURE_LIST_SUCCESS,
  payload: featureList,
});

export const updateFeature = (feature) => ({
  type: UPDATE_FEATURE,
  payload: feature,
});

export const viewPreviewTheme = (themeObj) => ({
  type: VIEW_PREVIEW_THEME,
  themeObj,
});

export const showPreviewModal = (show) => ({
  type: SHOWV_PREVIEW_MODAL,
  show,
});

export const fetchThemeConfigList = () => ({
  type: FETCH_THEME_CONFIG_LIST,
});

export const fetchThemeConfigListSuccess = (response) => ({
  type: FETCH_THEME_CONFIG_LIST_SUCCESS,
  response,
});

export const fetchThemeConfigListFailure = (error) => ({
  type: FETCH_THEME_CONFIG_LIST_FAILURE,
  error,
});

export const updateThemeConfigList = (configThemeObj, handleSuccess) => ({
  type: UPDATE_THEME_CONFIG_LIST,
  configThemeObj,
  handleSuccess,
});

export const updateThemeConfigListSuccess = (response) => ({
  type: UPDATE_THEME_CONFIG_LIST_SUCCESS,
  response,
});

export const updateThemeConfigListFailure = (error) => ({
  type: UPDATE_THEME_CONFIG_LIST_FAILURE,
  error,
});
export const downloadAttachment = (urlType, data, method, fileName, handleDownloadResponse) => ({
  type: DOWNLOAD_ATTACHEMENT,
  urlType,
  data,
  method,
  fileName,
  handleDownloadResponse,
});

export const downloadLoginLogoutLogo = (
  urlType,
  data,
  method,
  fileName,
  handleDownloadLoginLogoutLogoResponse
) => ({
  type: DOWNLOAD_LOGIN_LOGOUT_LOGO,
  urlType,
  data,
  method,
  fileName,
  handleDownloadLoginLogoutLogoResponse,
});

export const uploadAsFile = (
  urlType,
  fileData,
  path,
  handleUploadResponse,
  handleFailureResponse
) => ({
  type: UPLOAD_AS_FILE,
  urlType,
  fileData,
  path,
  handleUploadResponse,
  handleFailureResponse,
});

export const uploadLogo = (urlType, fileData, handleUploadResponse, handleFailureResponse) => ({
  type: UPLOAD_LOGO,
  urlType,
  fileData,
  handleUploadResponse,
  handleFailureResponse,
});

export const uploadFavIcon = (urlType, fileData, handleUploadResponse, handleFailureResponse) => ({
  type: UPLOAD_FAVICON,
  urlType,
  fileData,
  handleUploadResponse,
  handleFailureResponse,
});

export const requestManageLogsList = (data) => ({
  type: REQUEST_MANAGE_LOGS_LIST,
  data,
});

export const requestManageLogsListSuccess = (response) => ({
  type: REQUEST_MANAGE_LOGS_LIST_SUCCESS,
  response,
});

export const requestManageLogsListFailure = (error) => ({
  type: REQUEST_MANAGE_LOGS_LIST_FAILURE,
  error,
});

// gen2 brandingActions
export const GET_ALL_THEMES = 'GET_ALL_THEMES';
export const GET_ALL_THEMES_SUCCESS = 'GET_ALL_THEMES_SUCCESS';
export const GET_ALL_THEMES_FAILURE = 'GET_ALL_THEMES_FAILURE';
export const GET_ACTIVE_THEME = 'GET_ACTIVE_THEME';
export const GET_ACTIVE_THEME_SUCCESS = 'GET_ACTIVE_THEME_SUCCESS';
export const GET_ACTIVE_THEME_FAILURE = 'GET_ACTIVE_THEME_FAILURE';
export const CREATE_THEME = 'CREATE_THEME';
export const CREATE_THEME_SUCCESS = 'CREATE_THEME_SUCCESS';
export const CREATE_THEME_FAILURE = 'CREATE_THEME_FAILURE';
export const UPDATE_THEME = 'UPDATE_THEME';
export const UPDATE_THEME_SUCCESS = 'UPDATE_THEME_SUCCESS';
export const UPDATE_THEME_FAILURE = 'UPDATE_THEME_FAILURE';
export const DELETE_THEME = 'DELETE_THEME';
export const DELETE_THEME_SUCCESS = 'DELETE_THEME_SUCCESS';
export const DELETE_LOGO_SUCCESS = 'DELETE_LOGO_SUCCESS';
export const DELETE_THEME_FAILURE = 'DELETE_THEME_FAILURE';
export const DELETE_LOGO_FAILURE = 'DELETE_LOGO_FAILURE';
export const ACTIVATE_THEME = 'ACTIVATE_THEME';
export const ACTIVATE_THEME_SUCCESS = 'ACTIVATE_THEME_SUCCESS';
export const ACTIVATE_THEME_FALIURE = 'ACTIVATE_THEME_FALIURE';

export const GET_ALL_LOGOS = 'GET_ALL_LOGOS';
export const GET_ALL_LOGOS_SUCCESS = 'GET_ALL_LOGOS_SUCCESS';
export const GET_ALL_LOGOS_FAILURE = 'GET_ALL_LOGOS_FAILURE';
export const GET_ACTIVE_LOGO = 'GET_ACTIVE_LOGO';
export const GET_ACTIVE_LOGO_SUCCESS = 'GET_ACTIVE_LOGO_SUCCESS';
export const GET_ACTIVE_LOGO_FAILURE = 'GET_ACTIVE_LOGO_FAILURE';
export const GET_ACTIVE_HEADER_LOGO = 'GET_ACTIVE_HEADER_LOGO';
export const GET_ACTIVE_HEADER_LOGO_SUCCESS = 'GET_ACTIVE_HEADER_LOGO_SUCCESS';
export const GET_ACTIVE_HEADER_LOGO_FAILURE = 'GET_ACTIVE_HEADER_LOGO_FAILURE';
export const GET_ACTIVE_FAVICON = 'GET_ACTIVE_FAVICON';
export const GET_ACTIVE_FAVICON_SUCCESS = 'GET_ACTIVE_FAVICON_SUCCESS';
export const GET_ACTIVE_FAVICON_FAILURE = 'GET_ACTIVE_FAVICON_FAILURE';
export const GET_ACTIVE_LOGIN_LOGOUT_SUCCESS = 'GET_ACTIVE_LOGIN_LOGOUT_SUCCESS';
export const GET_ACTIVE_LOGIN_LOGOUT_FAILURE = 'GET_ACTIVE_LOGIN_LOGOUT_FAILURE';
export const GET_LOGO = 'GET_LOGO';
export const GET_LOGO_SUCCESS = 'GET_LOGO_SUCCESS';
export const GET_LOGO_FAILURE = 'GET_LOGO_FAILURE';
export const GET_LOGO_TO_SHOW = 'GET_LOGO_TO_SHOW';
export const GET_LOGO_TO_SHOW_SUCCESS = 'GET_LOGO_TO_SHOW_SUCCESS';
export const GET_LOGO_TO_SHOW_FAILURE = 'GET_LOGO_TO_SHOW_FAILURE';
export const ADD_LOGO = 'ADD_LOGO';
export const UPLOAD_LOGO = 'UPLOAD_LOGO';
export const UPLOAD_FAVICON = 'UPLOAD_FAVICON';
export const UPLOAD_LOGO_SUCCESS = 'UPLOAD_LOGO_SUCCESS';
export const UPLOAD_LOGO_FAILURE = 'UPLOAD_LOGO_FAILURE';
export const UPLOAD_FAVICON_SUCCESS = 'UPLOAD_FAVICON_SUCCESS';
export const UPLOAD_FAVICON_FAILURE = 'UPLOAD_FAVICON_FAILURE';
export const DELETE_LOGO = 'DELETE_LOGO';
export const GET_FAVICON = 'GET_FAVICON';
export const GET_FAVICON_SUCCESS = 'GET_FAVICON_SUCCESS';
export const GET_FAVICON_FAILURE = 'GET_FAVICON_FAILURE';

export const getAllThemes = () => ({ type: GET_ALL_THEMES });
export const getAllThemesSuccess = (response) => ({ type: GET_ALL_THEMES_SUCCESS, response });
export const getAllThemesFailure = (error) => ({ type: GET_ALL_THEMES_FAILURE, error });

export const getActiveTheme = () => ({ type: GET_ACTIVE_THEME });
export const getActiveThemeSuccess = (response) => ({ type: GET_ACTIVE_THEME_SUCCESS, response });
export const getActiveThemeFailure = (error) => ({ type: GET_ACTIVE_THEME_FAILURE, error });

export const createTheme = (payload) => ({ type: CREATE_THEME, payload });
export const createThemeSuccess = (response) => ({ type: CREATE_THEME_SUCCESS, response });
export const createThemeFailure = (error) => ({ type: CREATE_THEME_FAILURE, error });

export const updateTheme = (payload, isThemeActive) => ({
  type: UPDATE_THEME,
  payload,
  isThemeActive,
});
export const updateThemeSuccess = (response, isThemeActive) => ({
  type: UPDATE_THEME_SUCCESS,
  response,
  isThemeActive,
});
export const updateThemeFailure = (error) => ({ type: UPDATE_THEME_FAILURE, error });

export const deleteTheme = (payload) => ({ type: DELETE_THEME, payload });
export const deleteThemeSuccess = (response) => ({ type: DELETE_THEME_SUCCESS, response });
export const deleteThemeFailure = (error) => ({ type: DELETE_THEME_FAILURE, error });

export const activateTheme = (payload) => ({ type: ACTIVATE_THEME, payload });
export const activateThemeSuccess = (response) => ({ type: ACTIVATE_THEME_SUCCESS, response });
export const activateThemeFailure = (error) => ({ type: ACTIVATE_THEME_FALIURE, error });

export const getActiveLogo = (payload) => ({ type: GET_ACTIVE_LOGO, payload });
export const getActiveLogoSuccess = (response) => ({ type: GET_ACTIVE_LOGO_SUCCESS, response });
export const getActiveLogoFailure = (error) => ({ type: GET_ACTIVE_LOGO_FAILURE, error });

export const getActiveFavIcon = (payload) => ({ type: GET_ACTIVE_FAVICON, payload });
export const getActiveFavIconSuccess = (response) => ({
  type: GET_ACTIVE_FAVICON_SUCCESS,
  response,
});
export const getActiveFavIconFailure = (error) => ({
  type: GET_ACTIVE_FAVICON_FAILURE,
  error,
});

export const getActiveHeaderLogo = (payload) => ({ type: GET_ACTIVE_HEADER_LOGO, payload });
export const getActiveHeaderLogoSuccess = (response) => ({
  type: GET_ACTIVE_HEADER_LOGO_SUCCESS,
  response,
});
export const getActiveHeaderLogoFailure = (error) => ({
  type: GET_ACTIVE_HEADER_LOGO_FAILURE,
  error,
});

export const getLogo = (payload) => ({ type: GET_LOGO, payload });
export const getLogoSuccess = (response) => ({ type: GET_LOGO_SUCCESS, response });
export const getLogoFailure = (error) => ({ type: GET_LOGO_FAILURE, error });

export const getLogoToShow = (payload) => ({ type: GET_LOGO_TO_SHOW, payload });
export const getLogoToShowSuccess = (response) => ({ type: GET_LOGO_TO_SHOW_SUCCESS, response });
export const getLogoToShowFailure = (error) => ({ type: GET_LOGO_TO_SHOW_FAILURE, error });

export const getAllLogos = () => ({ type: GET_ALL_LOGOS });
export const getAllLogosFailure = (error) => ({ type: GET_ALL_LOGOS_FAILURE, error });
export const addLogo = (logo) => ({ type: ADD_LOGO, logo });
export const deleteLogo = (logoKey) => ({ type: DELETE_LOGO, logoKey });
export const getFavIcon = () => ({ type: GET_FAVICON });
export const deleteLogoSuccess = (response) => ({ type: DELETE_LOGO_SUCCESS, response });
export const deleteLogoFailure = (error) => ({ type: DELETE_LOGO_FAILURE, error });
