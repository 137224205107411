export const ACCOUNT_TYPE = {
  CREDENTIAL: 'credential',
  CREDENTIALLESS: 'credentialless',
};

export const EXTEND_MSGS = {
  extendFailedDefaultMessage: {
    id: 'pam.extend.failed.default',
    defaultMessage: 'Session Extend failed. Please contact administrator.',
  },
  alertBannerFailureTitle: {
    id: 'pam.checkin.failedTitle',
    defaultMessage: `Failed!`,
  },
  alertBannerSuccessTitle: {
    id: 'pam.checkin.successTitle',
    defaultMessage: `Success!`,
  },
};
