import { type FunctionComponent, type SVGAttributes } from 'react';

import ChangeAccountPassword from './changeAccountPassword.svg';
import ChangeServiceAccountPassword from './changeServiceAccountPassword.svg';
import CreateEndpoint from './createEndpoint.svg';
import CreateEntitlement from './createEntitlement.svg';
import CreateUserRequest from './createUserRequest.svg';
import CurrentAccess from './currentAccess.svg';
import EmergencyAccessID from './emergencyAccessID.svg';
import EmergencyAccessRoles from './emergencyAccessRoles.svg';
import ManageDelegation from './manageDelegation.svg';
import ManageMyAccess from './manageMyAccess.svg';
import ManageServiceAccounts from './manageServiceAccounts.svg';
import OneClickDisable from './oneClickDisable.svg';
import PendingApproval from './pendingApproval.svg';
import PendingTasks from './pendingTasks.svg';
import ResetOthersPassword from './resetOthersPassword.svg';
import ResetUserPassword from './resetUserPassword.svg';
import SavedRequests from './savedRequests.svg';
import StartAccessRequest from './startAccessRequest.svg';
import UpdateEndpoint from './updateEndpoint.svg';
import UpdateEntitlement from './updateEntitlement.svg';
import UpdateUserRequest from './updateUserRequest.svg';
import ViewRequestHistory from './viewRequestHistory.svg';
import UploadUserRequest from './uploadUserRequest.svg';
import UploadOrganization from './uploadOrganization.svg';
import UpdateExistingOrganization from './updateExistingOrganization.svg';
import CreateNewOrganization from './createNewOrganization.svg';

const illustrations: Record<
  string,
  FunctionComponent<SVGAttributes<SVGElement>>
> = {
  'change-account-password-for-self': ChangeAccountPassword,
  'reset-service-account-password': ChangeServiceAccountPassword,
  'view-saved-requests': SavedRequests,
  'request-history': ViewRequestHistory,
  'pending-approvals': PendingApproval,
  'manage-my-access': ManageMyAccess,
  'manage-delegate': ManageDelegation,
  'reset-account-password-for-others': ResetOthersPassword,
  'reset-user-password': ResetUserPassword,
  'request-emergency-access-IDs': EmergencyAccessID,
  'request-emergency-access-roles': EmergencyAccessRoles,
  'view-existing-access': CurrentAccess,
  'pending-tasks': PendingTasks,
  'manage-service-accounts': ManageServiceAccounts,
  'create-new-endpoint': CreateEndpoint,
  'update-existing-endpoint': UpdateEndpoint,
  'one-click-disable': OneClickDisable,
  'create-new-entitlement': CreateEntitlement,
  'update-existing-entitlement': UpdateEntitlement,
  'create-user-request': CreateUserRequest,
  'update-user-request': UpdateUserRequest,
  'custom:start-access-request': StartAccessRequest,
  'upload-user-request': UploadUserRequest,
  'upload-organization': UploadOrganization,
  'update-existing-organization': UpdateExistingOrganization,
  'create-new-organization': CreateNewOrganization,
};

export default illustrations;
