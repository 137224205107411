import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import Divider from '@material-ui/core/Divider';
import MyDownloads from '../MyDownloads/MyDownloads';
import MyUploads from '../MyUploads/MyUploads';
import RecentActivity from '../RecentActivity/RecentActivity';
import RecentActivityQuestions from '../RecentActivityQuestions/RecentActivityQuestions';
import Language from '../Language/Language';
import About from '../About/About';
import ChangePassword from '../ChangePassword/ChangePassword';
import ChangeUserAPIPassword from '../ChangeUserAPIPassword/ChangeUserAPIPassword';
import Logout from '../Logout/Logout';
import MuteEmail from '../MuteEmail/MuteEmail';
import FullScreen from '../FullScreen/FullScreen';

const useStyles = makeStyles(() => ({
  separator: {
    margin: '5px 10px',
    background: '#E4E5EB',
  },
}));

const MenuItems = () => {
  const classes = useStyles();
  const { menuList } = useSelector((state) => state.profile);

  const getMenuComponent = (menu) => {
    switch (true) {
      case menu.id === 'change-self-password':
        return <ChangePassword menuDetails={menu} />;

      case menu.id === 'my-downloads':
        return <MyDownloads menuDetails={menu} />;

      case menu.id === 'my-uploads':
        return <MyUploads menuDetails={menu} />;

      case menu.id === 'reset-security-questions':
        return <RecentActivityQuestions menuDetails={menu} />;

      case menu.id === 'change-user-api-password':
        return <ChangeUserAPIPassword menuDetails={menu} />;

      case menu.id === 'language':
        return <Language menuDetails={menu} />;

      case menu.id === 'mute-email':
        return <MuteEmail menuDetails={menu} />;

      case menu.id === 'logout':
        return (
          <>
            <Divider className={classes.separator} />
            <Logout menuDetails={menu} />
          </>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <About />
      <FullScreen />
      <RecentActivity />
      <Divider className={classes.separator} />
      {menuList.map((item) => (
        <>
          {getMenuComponent(item)}
          {/* {item.isSeparator ? <Divider className={classes.separator} /> : null } */}
        </>
      ))}
    </>
  );
};

export default MenuItems;
