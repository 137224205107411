import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';

const useStyles = makeStyles({
  root: {
    borderRadius: 1,
    width: '100%',
    height: '38px',
  },
  input: {
    padding: '10px 13px',
    color: 'var(--gray-dark)',
    backgroundColor: 'var(--white)',
    '&::placeholder': {
      color: 'var(--grey-font)',
      opacity: 0.8,
    },
  },
  adornedStart: {
    paddingLeft: '7px',
    backgroundColor: 'var(--white)',
  },
  inputAdornedStart: {
    marginLeft: '8px',
  },
  adornedEnd: {
    paddingRight: '4px',
  },
  notchedOutline: {
    borderColor: 'var(--primary-bg)',
  },
});

const Input = (props) => {
  const classes = useStyles();

  return <OutlinedInput classes={classes} {...props} />;
};

export default Input;
