import React from 'react';
// TODO - Commented code to be deleted after testing. 
/*
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
*/
import styles from './ExpandableIcons.module.css';
import ExpandMoreIcon from '../../ECMv6/assets/ExpandMoreIcon';
import ExpandLessIcon from '../../ECMv6/assets/ExpandLessIcon';

const ExpandableIcons = ({ isExpandable, isOpen, level }) => {
  if (isExpandable) {
    if (!isOpen && level === 1) {
      return <ExpandLessIcon rotate="90" className={styles.expandLevel1Icon} />;
    }
    return isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />;
  }
  return null;
};

export default ExpandableIcons;
