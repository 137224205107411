import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, ButtonCore } from '@saviynt/design-system';
import DropDownMenu from '../DropDownMenu/DropDownMenu';
import MenuItems from './MenuItems/MenuItems';
import UserDetails from './UserDetails/UserDetails';
import useDisplayName from '../../utils/useDisplayName';

const useStyles = makeStyles(() => ({
  profileIcon: {
    padding: 0,
    outline: (open) => (open ? '2px solid #3751FF' : '0'),
    /* TODO: Remove !important if Metronic from bootstrap is removed*/
    borderRadius: '4rem !important',
    border: 0,
  },
}));

const Profile = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const classes = useStyles(anchorEl || false);

  let { profileImage } = useSelector((state) => state.profile);
  const displayName = useDisplayName();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ButtonCore
        aria-label="profile"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={classes.profileIcon}
        data-testid="profile"
      >
        <Avatar size="medium" src={profileImage ? profileImage : null}>
          {displayName}
        </Avatar>
      </ButtonCore>
      <DropDownMenu anchorEl="body" open={Boolean(anchorEl)} onClose={handleClose}>
        <UserDetails />
        <MenuItems />
      </DropDownMenu>
    </>
  );
};

export default Profile;
