export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const CLOSE_NOTIFICATION = 'CLOSE_NOTIFICATION';

const ShowNotification = payload => dispatch => {
  dispatch({
    type: SHOW_NOTIFICATION,
    payload,
  });
};

const CloseNotification = () => dispatch => {
  dispatch({
    type: CLOSE_NOTIFICATION,
  });
};

export { ShowNotification, CloseNotification };
