import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useCountdown } from './useCountdown';
import { deleteSessionItem } from '../../Redux/PrivilegedSessions-actions';
import './CountdownTimer.css';

const renderTimeCounter = (days, hours, minutes, seconds) => {
  if (days === 1 && hours === 1) {
    return `${days} day, ${hours} hour`;
  }
  if (days === 1 && hours > 1) {
    return `${days} day, ${hours} hours`;
  }
  if (days > 1 && hours > 1) {
    return `${days} days, ${hours} hours`;
  }
  if (days > 1 && hours === 1) {
    return `${days} days, ${hours} hour`;
  }
  if (days > 1) {
    return `${days} days`;
  }
  if (days === 1) {
    return `${days} day`;
  }
  if (hours > 0 && minutes > 0) {
    return `${hours} ${hours > 1 ? 'hours' : 'hour'}, ${minutes} minutes`;
  }
  if (hours > 0 && minutes === 0) {
    return `${hours} ${hours > 1 ? 'hours' : 'hour'}`;
  }
  if (hours === 1) {
    return `${hours} hour`;
  }
  if (hours === 0 && days === 0 && minutes > 0) {
    return `${minutes} minutes`;
  }
  if (minutes === 0 && seconds > 0) {
    return `${seconds} seconds`;
  }
  return '';
};

const CountdownTimer = ({ targetDate, leftText, item, hideTimer }) => {
  const dispatch = useDispatch();
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  const onExpiration = item => {
    dispatch(deleteSessionItem(item.requestAccessKey));
  };

  if (days + hours + minutes + seconds <= 0) {
    if (new Date(item.enddate).getTime() - new Date().getTime() <= 0) {
      onExpiration(item);
    }
    return '';
  }

  return (
    <>
      {!hideTimer && (
        <>
          {renderTimeCounter(days, hours, minutes, seconds)}{' '}
          <span className="label-left">{leftText}</span>
        </>
      )}
    </>
  );
};

CountdownTimer.defaultProps = {
  hideTimer: false,
};

CountdownTimer.propTypes = {
  targetDate: PropTypes.number.isRequired,
  leftText: PropTypes.string.isRequired,
  item: PropTypes.shape({
    requestAccessKey: PropTypes.number.isRequired,
    enddate: PropTypes.string.isRequired,
  }).isRequired,
};

export default CountdownTimer;
