/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable react/jsx-wrap-multilines */
import './DatabaseJitModalIsland.css';

import { localize } from '@saviynt/common';
import {
  Box,
  Button,
  CheckboxGroup,
  FooterActionBar,
  Icon,
  ModalIsland,
  Typography,
  useCheckboxGroup,
} from '@saviynt/design-system';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { ACCOUNT_JIT_SELECTION, msgs } from '../../constants';

function DatabaseJitModalIsland({
  isOpen,
  setIsDatabaseJitCreateAccount,
  setDatabaseJitAccountInfo,
  databaseJitAccountInfo,
  setAccountSelection,
  sectionRefIsScrolled,
  databaseJitModalContentRef,
}) {
  const intl = useIntl();
  const SELECT_ALL = localize(intl, msgs.pam.common.selectAll);
  const DESELECT_ALL = localize(intl, msgs.pam.common.deselectAll);
  const [checkboxButtonLabel, setCheckboxButtonLabel] = useState(SELECT_ALL);

  const checkboxConfigs = [
    {
      name: 'SELECT',
      label: localize(intl, msgs.pam.common.db.selectLabel),
      isChecked: false,
      kind: 'card',
      title: localize(intl, msgs.pam.common.db.select).toUpperCase(),
      showHoverLabel: true,
    },
    {
      name: 'ALTER',
      isChecked: false,
      label: localize(intl, msgs.pam.common.db.alterLabel),
      kind: 'card',
      title: localize(intl, msgs.pam.common.db.alter).toUpperCase(),
      showHoverLabel: true,
    },
    {
      name: 'CREATE',
      isChecked: false,
      label: localize(intl, msgs.pam.common.db.createLabel),
      kind: 'card',
      title: localize(intl, msgs.pam.common.db.create).toUpperCase(),
      showHoverLabel: true,
    },
    {
      name: 'INSERT',
      isChecked: false,
      label: localize(intl, msgs.pam.common.db.insertLabel),
      kind: 'card',
      title: localize(intl, msgs.pam.common.db.insert).toUpperCase(),
      showHoverLabel: true,
    },
    {
      name: 'UPDATE',
      isChecked: false,
      label: localize(intl, msgs.pam.common.db.updateLabel),
      kind: 'card',
      title: localize(intl, msgs.pam.common.db.update).toUpperCase(),
      showHoverLabel: true,
    },
  ];

  const {
    checkboxes,
    checkboxStates,
    checkAllCheckboxes,
    uncheckAllCheckboxes,
    getCheckedNames,
    setCheckedFromArrayOfNames,
  } = useCheckboxGroup(checkboxConfigs);

  const handleCheckboxButtonClick = () => {
    if (checkboxButtonLabel === SELECT_ALL) {
      checkAllCheckboxes();
    } else {
      uncheckAllCheckboxes();
    }
  };

  const handleSave = () => {
    // send info back to the parent.
    setDatabaseJitAccountInfo((prevState) => ({
      ...prevState,
      entitlements: getCheckedNames(),
    }));
    setAccountSelection(ACCOUNT_JIT_SELECTION);
    setIsDatabaseJitCreateAccount(false);
  };

  useEffect(() => {
    const areAllChecked = checkboxStates.every(
      (checkbox) => checkbox.isChecked
    );

    setCheckboxButtonLabel(areAllChecked ? DESELECT_ALL : SELECT_ALL);
  }, [checkboxStates]);

  useEffect(() => {
    setCheckedFromArrayOfNames(databaseJitAccountInfo.entitlements);
  }, []);

  const renderedModalContent = (
    <Box tag='section'>
      <Typography kind='h2'>
        {localize(intl, msgs.pam.cts.db.jitAccountDetails)}
      </Typography>
      <Box className='DatabaseJitModal-accountDetailsWrapper'>
        <Box className='DatabaseJitModal-accountNameWrapper'>
          <Typography kind='h4' className='DatabaseJitModal-accountName'>
            {localize(intl, msgs.pam.cts.db.jitAccountName)}
          </Typography>
          <Typography
            kind='body2'
            className='DatabaseJitModal-accountNameValue'>
            {localize(intl, msgs.pam.cts.db.jitAccount)}
          </Typography>
        </Box>
        <Box className='DatabaseJitModal-accountDescWrapper'>
          <Typography kind='h4' className='DatabaseJitModal-accountDesc'>
            {localize(intl, msgs.pam.cts.db.jitAccountDesc)}
          </Typography>
          <Typography
            kind='body2'
            className='DatabaseJitModal-accountDescValue'>
            {localize(intl, msgs.pam.cts.db.jitAccountValue)}
          </Typography>
        </Box>
      </Box>
      <div className='DatabaseJitModal-entitlementsHeaderWrapper'>
        <Typography kind='h2'>
          {localize(intl, msgs.pam.cts.db.jitEntitlementsNeeded)}
        </Typography>
        <Button onClick={handleCheckboxButtonClick}>
          {checkboxButtonLabel}
        </Button>
      </div>
      <Box className='DatabaseJitModal-checkboxes'>
        <CheckboxGroup checkboxes={checkboxes} />
      </Box>
    </Box>
  );

  const renderedModalFooterActionBar = (
    <Box tag='section' className='DatabaseJitModal-footerActionBar'>
      <Button
        kind='outlined'
        size='medium'
        onClick={() => setIsDatabaseJitCreateAccount(false)}>
        Cancel
      </Button>
      <Button
        isDisabled={getCheckedNames().length === 0}
        kind='filled'
        size='medium'
        onClick={() => handleSave()}>
        Save
      </Button>
    </Box>
  );

  return (
    <ModalIsland
      className='DatabaseJitModal'
      onClose={() => setIsDatabaseJitCreateAccount(false)}
      isOpen={isOpen}
      title={localize(intl, msgs.pam.cts.db.jitTempPrivAcctModalTitle)}
      subtitle={localize(intl, msgs.pam.cts.db.jitTempAcctModalSubTitle)}
      contentBody={renderedModalContent}
      headerIcon={<Icon kind='AddCircle' size='medium' />}
      sectionRef={databaseJitModalContentRef}
      FooterActionBarComp={
        <FooterActionBar size='large' isScrolled={sectionRefIsScrolled}>
          {renderedModalFooterActionBar}
        </FooterActionBar>
      }
    />
  );
}

DatabaseJitModalIsland.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsDatabaseJitCreateAccount: PropTypes.func.isRequired,
  setDatabaseJitAccountInfo: PropTypes.func.isRequired,
  databaseJitAccountInfo: PropTypes.shape({
    entitlements: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  setAccountSelection: PropTypes.func.isRequired,
  sectionRefIsScrolled: PropTypes.bool.isRequired,
  databaseJitModalContentRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
};

export default DatabaseJitModalIsland;
