import {
  ShowLoaderType,
  HideLoaderType,
  SHOW_NOTIFICATION_MODAL,
  HIDE_NOTIFICATION_MODAL
} from 'ui-common/src/utils/Loading-actions';

const defaultState = {
  isLoading: false,
  notificationDialog: {
    visible: false
  }
}

const showNotificationModal = (state, action) => {
  const { notificationType, title, details } = action;
  return {
    ...state,
    notificationDialog: {
      type: notificationType,
      visible: true,
      title,
      details,
    }
  }
}

const hideNotificationModal = (state) => {
  return {
    ...state,
    notificationDialog: {
      visible: false,
    }
  }
}

export const LoadingReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ShowLoaderType: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case HideLoaderType: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case SHOW_NOTIFICATION_MODAL:
      return showNotificationModal(state, action);
    case HIDE_NOTIFICATION_MODAL:
      return hideNotificationModal(state);
    default: {
      return state;
    }
  }
};

export default LoadingReducer;
