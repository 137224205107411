import React from 'react';
import { ListRow } from '@saviynt/design-system';
import PropTypes from 'prop-types';

import useCreateRowObject from '../../hooks/use-createrowobject';

const KINDS = {
  ENDPOINT: 'endpoint',
  SESSION: 'session',
  ACCOUNT: 'account',
};

function ListRowWrapper({
  kind,
  listItemObj,
  isCheckInModalOpen,
  setIsCheckInModalOpen,
  setIconClickedForRow,
  setCheckInModalKind,
  isSelected,
  listRowOnClick,
  index,
  listRowData,
  setListRowData,
  setIsExtendModalOpen,
  onLaunchSession,
  setIsSessionTargetModalVisible,
  setSessionDetailsForAd,
}) {
  const createObjectForRow = useCreateRowObject(
    listItemObj,
    isCheckInModalOpen,
    setIsCheckInModalOpen,
    setIconClickedForRow,
    setCheckInModalKind,
    listRowData,
    setListRowData,
    setIsExtendModalOpen,
    onLaunchSession,
    setIsSessionTargetModalVisible,
    setSessionDetailsForAd
  );
  const listItem = createObjectForRow();

  const accountDescription =
    listItem.description || listItem.accountDescription;

  return (
    <div
      className={
        isSelected(listItemObj)
          ? 'LandingPage-listRowContainer--isSelected'
          : 'LandingPage-listRowContainer'
      }>
      <ListRow
        category={listItem.category}
        title={listItem.title}
        description={
          kind === KINDS.ACCOUNT ? accountDescription : listItem.description
        }
        status={!isSelected(listItemObj) && listItem.status}
        primaryIcon={!isSelected(listItemObj) && listItem.primaryIcon}
        secondaryIcon={
          kind !== KINDS.ENDPOINT &&
          !isSelected(listItemObj) &&
          listItem.secondaryIcon
        }
        moreOptionsIcon={!isSelected(listItemObj) && listItem.moreOptionsIcon}
        hasBookmark={false}
        onRowClick={() => listRowOnClick(listItemObj)}
        className={
          isSelected(listItemObj)
            ? 'LandingPage-listRow--isSelected'
            : 'LandingPage-listRow'
        }
        type={listItem.type}
        dataTestId={`${kind}-row-${index}`}
      />
    </div>
  );
}

ListRowWrapper.propTypes = {
  // listItemObj: PropTypes.shape({...}).isRequired, // TODO: add shape
  kind: PropTypes.string.isRequired,
  isCheckInModalOpen: PropTypes.bool.isRequired,
  setIsCheckInModalOpen: PropTypes.func.isRequired,
  setIconClickedForRow: PropTypes.func.isRequired,
  setCheckInModalKind: PropTypes.func.isRequired,
  isSelected: PropTypes.func.isRequired,
  listRowOnClick: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  listRowData: PropTypes.shape({
    result: PropTypes.shape({
      userAccesses: PropTypes.instanceOf(Array).isRequired,
    }).isRequired,
    count: PropTypes.number.isRequired,
  }).isRequired,
  setListRowData: PropTypes.func.isRequired,
  setIsExtendModalOpen: PropTypes.func.isRequired,
  onLaunchSession: PropTypes.func.isRequired,
  setIsSessionTargetModalVisible: PropTypes.func.isRequired,
  setSessionDetailsForAd: PropTypes.func.isRequired,
};
ListRowWrapper.defaultProps = {};

export default ListRowWrapper;
