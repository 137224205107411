export const STORE_REQUEST_SUBMITTED_ID = 'STORE_REQUEST_SUBMITTED_ID';
export const CLEAR_REQUEST_ID = 'CLEAR_REQUEST_ID';

export const requestSubmittedId = (requestId, isRoleRequest, success) => ({
  type: STORE_REQUEST_SUBMITTED_ID,
  payload: { requestId, isRoleRequest },
  successMessage: success,
});

export const clearRequestSubmittedId = (success) => ({
  type: CLEAR_REQUEST_ID,
  successMessage: success,
});
