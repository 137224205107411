import { Link } from 'react-router-dom';
import LaunchIcon from '@material-ui/icons/Launch';
import React from 'react';
import Tooltip from '../Tooltip/Tooltip';

const HoverLink = ({
  isHeading, url, className, intl,
}) => {
  if (!isHeading) {
    return (
      <div className={className}>
        <Tooltip
          title={intl.formatMessage({ id: 'Admin.Log.Viewer.shareToolTip' })}
          aria-label={intl.formatMessage({ id: 'Admin.Log.Viewer.shareToolTip' })}
        >
          <Link to={url} target="_blank"><LaunchIcon /></Link>
        </Tooltip>
      </div>
    );
  }

  return null;
};

export default HoverLink;
