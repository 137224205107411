import React, { type JSX, type ReactNode } from 'react';
import classNames from 'classnames';

import './RecentAssignmentRow.css';

// See commit 93c6c46fe5 for a deeplink implementation
interface Props {
  className?: string;
  sourceAndPurpose: ReactNode;
  details: ReactNode;
  subject1?: ReactNode;
  subject2?: ReactNode;
  assignmentStatus: ReactNode;
  icon: ReactNode;
}

RecentAssignmentRow.defaultProps = {
  className: undefined,
  subject1: undefined,
  subject2: undefined,
};

export default function RecentAssignmentRow({
  className,
  sourceAndPurpose,
  details,
  subject1,
  subject2,
  assignmentStatus,
  icon,
}: Props): JSX.Element {
  const assignmentClassName = classNames('Nav_RecentAssignmentRow', className);

  return (
    <div className={assignmentClassName}>
      <div className='Nav_RecentAssignmentRow-sourceAndPurpose'>
        {icon}
        {sourceAndPurpose}
      </div>
      <div className='Nav_RecentAssignmentRow-details'>{details}</div>
      <div className='Nav_RecentAssignmentRow-subject1'>{subject1}</div>
      <div className='Nav_RecentAssignmentRow-subject2'>{subject2}</div>
      <div className='Nav_RecentAssignmentRow-status'>{assignmentStatus}</div>
    </div>
  );
}
