export {} from './declarations';

export {} from './models';

import { IntlBridge } from './utilities';
export {} from './utilities';

import {
  shortcuts,
  kpis,
  recentAssignments,
  mfaPrompt as mfaPromptFeature,
} from './features';
export const ShortcutsWidget = IntlBridge(shortcuts.Component);
export const { reducer: shortcutsReducer } = shortcuts;
export const KpisWidget = IntlBridge(kpis.Component);
export const { reducer: kpisReducer } = kpis;
export const RecentAssignmentsWidget = IntlBridge(recentAssignments.Component);
export const { reducer: recentAssignmentsReducer } = recentAssignments;
export const mfaPrompt = { ...mfaPromptFeature }; // parcel bug https://github.com/parcel-bundler/parcel/issues/8748

export {} from './devHarness';
