import React, { useState } from 'react';
import { ModalIsland, Button } from '@saviynt/design-system';

const FallbackUIerror = () => {
  const [isOpen4, setIsOpen4] = useState(true);

  return (
    <div className="error-fallback-ui-modal">
      <ModalIsland
        kind="critical"
        alertTitle="Feature Unavailable"
        alertSubtitle="The server can't display the Left Navigation and Home Page because of a network problem. You can try again later."
        onClose={() => setIsOpen4(false)}
        isOpen={isOpen4}
        primaryButton={
          <Button
            type="button"
            kind="outlined"
            isEnabled
            size="medium"
            onClick={() => setIsOpen4(false)}
          >
            Close
          </Button>
        }
        secondaryButton=""
      />
    </div>
  );
};

export default FallbackUIerror;
