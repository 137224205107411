import { type ThunkAction } from 'redux-thunk';
import { type ActionType } from 'typesafe-actions';

import { RequestStatus } from '../../../../models';
import type RootState from '../RootState';

import * as actions from './actions';

type Actions = ActionType<typeof actions>;
type ShortcutsThunkAction = ThunkAction<void, RootState, unknown, Actions>;

// eslint-disable-next-line import/prefer-default-export -- follow nav thunks file pattern
export const requestShortcuts =
  (shortcuts: string[] | 'unauthorized' | undefined): ShortcutsThunkAction =>
  (dispatch, getState) => {
    if (getState().shortcuts.defaultShortcutsStatus === RequestStatus.Pending) {
      return;
    }

    dispatch(actions.shortcutsRequested());

    if (!shortcuts) {
      dispatch(actions.shortcutsNotReceived());

      return;
    }

    if (shortcuts === 'unauthorized') {
      dispatch(actions.shortcutsUnauthorized());

      return;
    }

    dispatch(actions.shortcutsReceived(shortcuts));
  };
