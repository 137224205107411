import React from 'react';
import classnames from 'classnames';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { push } from 'react-router-redirect';
import Fade from '@material-ui/core/Fade';
import replaceLinksWithCookies from '../../../../ui-common/src/lib/replaceLinksWithCookies';
import styles from './MenuItem.module.css';
import ExpandableIcons from '../ExpandableIcons/ExpandableIcons';
import BackChevron from '../BackChevron/BackChevron';
import GetIcons from '../../utils/GetIcons';
import findAssociatedMenuForNavigatedUrl from '../../utils/findAssociatedMenuForNavigatedUrl';
import { whiteListedLinks } from '../../Constants';
import { testIds as t } from 'ui-common/src/constants';

const checkUrlIsECM = (url) => whiteListedLinks.includes(`/${url.split('/')[1]}`);

function getAnchorProps(to, handleClick, data) {
  if (!to) {
    return { onClick: handleClick };
  }

  let link = replaceLinksWithCookies(to);
  link = link.startsWith('/') ? link : `/${link}`;

  const isLinkECM = checkUrlIsECM(link);
  if (isLinkECM || checkUrlIsECM(window.location.pathname)) {
    return {
      link: isLinkECM ? link : `/ECMv6${link}`,
      onClick: () => findAssociatedMenuForNavigatedUrl(data, link),
    };
  }

  const onClick = (e) => {
    findAssociatedMenuForNavigatedUrl(data, link);

    const { altKey, ctrlKey, metaKey, shiftKey } = e;
    if (altKey || ctrlKey || metaKey || shiftKey) {
      return;
    }

    e.preventDefault();
    push(link);
  };

  return { link: `/ECMv6${link}`, onClick };
}

const useStyles = makeStyles({
  root: {
    color: 'var(--SIDEMENU-TEXT-COLOR)',
    // color: getTextColor(),
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    padding: '0',
    paddingLeft: '13px',
    paddingRight: '8px',
    '&:hover': {
      background: 'var(--SIDEMENU-HOVER-BACKGROUND)',
      color: 'var(--SIDEMENU-HOVER-TEXT-COLOR)',
      '&:before': {
        background: 'var(--SIDEMENU-HOVER-BACKGROUND)',
        transform: 'scale(1)',
      },
    },
  },
  primary: {
    color: 'var(--SIDEMENU-TEXT-COLOR)',
    // color: getTextColor(),
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    whiteSpace: 'initial',
    visibility: 'visible',
    fontSize: '13px',
    width: 145,
  },
  secondary: {
    color: 'var(--SIDEMENU-SUBTEXT-COLOR)',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    whiteSpace: 'initial',
    visibility: 'visible',
  },
  title: {
    color: 'var(--SIDEMENU-TEXT-COLOR)',
    // color: getTextColor(),
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    whiteSpace: 'initial',
    visibility: 'visible',
    margin: '12px 0',
    paddingLeft: '0Px',
  },
  childMenuItem: {
    fontSize: '12px',
  },
  doNotDisplay: {
    display: 'none',
  },
  icon: {
    display: 'flex',
    justifyContent: 'flex-start',
    minWidth: '0',
    marginRight: '0px',
    width: '24px',
  },
  iconWithoutMargin: {
    display: 'flex',
    justifyContent: 'flex-start',
    minWidth: '0',
    marginLeft: '2px',
  },
  iconWithLeftMargin: {
    display: 'flex',
    justifyContent: 'flex-start',
    minWidth: '0',
    // marginLeft: '6px',
  },
});

const MenuItem = ({
  intl,
  icon,
  title,
  subTitle,
  selected,
  isOpen,
  isExpandable,
  nestedClassName,
  isParentOpen,
  level,
  handleClick,
  handleBackClick,
  to,
  collapseSideMenu,
  isSideMenuOpen,
  color,
  isSearchActive,
  isTranslated,
  suppressAnimation = false,
}) => {
  const classes = useStyles();

  const getTranslatedText = (text) => intl.formatMessage({ id: `gen2.leftnav.label.${text}` }); // gen2.leftnav.label
  const data = useSelector((state) => state.app.sideMenu);
  const isBackIconVisible =
    isParentOpen && isOpen !== false && !isSearchActive ? !collapseSideMenu : false;
  const isSubtitle = subTitle ? getTranslatedText(subTitle) : null;
  const secondaryText = isTranslated ? subTitle : isSubtitle;
  const getCurrentMenu = JSON.parse(localStorage.getItem('currentMenu'));
  const getCSSForTitle = (cssClass) => {
    if (isSideMenuOpen) {
      return classnames(
        cssClass,
        styles.level3Text,
        (getCurrentMenu?.parentID?.join(',').includes(title) || selected) && styles.bold,
        level == 3 && styles.level3Label
      );
    }
    return classes.doNotDisplay;
  };
  const getCSSForIcon = () => {
    if (isSearchActive && isSideMenuOpen && level === 1) {
      return classes.iconWithLeftMargin;
    }
    if (isSideMenuOpen && !isBackIconVisible) {
      return classes.icon;
    }
    if (isBackIconVisible) {
      return classes.iconWithoutMargin;
    }
    return classes.iconWithLeftMargin;
  };
  const getFontSize = (level) => level !== 1 && classes.childMenuItem;
  const classname = classnames(
    styles.menuItem,
    selected ? styles.selectedMenuItem : styles.notSelectedMenuItem
  );

  const { link, onClick } = getAnchorProps(to, handleClick, data);

  return (
    <div data-testid={selected ? 'selected' : 'not-selected'} className={classname}>
      <div
        className={
          (selected && (level === 3 || !isSideMenuOpen) && getCurrentMenu?.parentID?.length >= 1) ||
          ((level === 2 || !isSideMenuOpen) &&
            getCurrentMenu?.id === title &&
            getCurrentMenu?.parentID?.length === 1) ||
          (level === 1 && !isOpen && getCurrentMenu?.parentID?.join(',').includes(title))
            ? styles.selected
            : styles.notSelected
        }
        // style={{ borderColor: color }}
        // style={{ borderColor: getPrimaryColor() }}
        style={{ borderColor: '--SIDEMENU-ICON-PRIMARY' }}
      />
      <ListItem
        button
        selected={selected}
        classes={classes}
        className={[
          nestedClassName,
          selected && getCurrentMenu?.id === title ? styles.selectedMenuBackground : '',
        ]}
        component={link ? 'a' : 'div'}
        href={link}
        onClick={onClick}
        data-testId={`${t.SIDE_MENU}--${title}`}
      >
        <BackChevron isVisible={isBackIconVisible} handleBackClick={handleBackClick} />
        <ListItemIcon classes={{ root: getCSSForIcon() }}>
          {(level === 1 || level === 2) && <GetIcons id={icon} />}
        </ListItemIcon>
        <Fade in={isSideMenuOpen} timeout={suppressAnimation ? 0 : 500}>
          <ListItemText
            primary={getTranslatedText(title)}
            secondary={secondaryText}
            classes={{
              root: getCSSForTitle(classes.title),
              primary: `${getCSSForTitle(classes.primary, selected)} ${getFontSize(level)}`,
              secondary: getCSSForTitle(classes.secondary),
            }}
          />
        </Fade>
        <Fade in={isSideMenuOpen} timeout={suppressAnimation ? 0 : 1000}>
          <div
            className={
              (level === 1 && styles.parentArrowIcon) ||
              (level === 2 && (isOpen ? styles.childArrowIconOpen : styles.childArrowIconClose))
            }
          >
            <ExpandableIcons
              isExpandable={isSideMenuOpen ? isExpandable : false}
              isOpen={isOpen}
              level={level}
            />
          </div>
        </Fade>
      </ListItem>
    </div>
  );
};

MenuItem.defaultProps = {
  isParentOpen: false,
  level: 1,
  isTranslated: false,
};
export default injectIntl(MenuItem);
