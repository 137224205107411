/* eslint-disable react/jsx-curly-spacing */
import React, { useState } from 'react';
import { useLocalize } from '@saviynt/common';
import { Icon, TabContent, TabGroup, TabItem } from '@saviynt/design-system';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { mockSessions } from '../../../../../models/MockData/MockData';
import {
  Entitlement,
  ModuleDetails,
  RoleDetails,
} from '../../../../../models/PamModels';
import Scheduler from '../../../../Scheduler/Scheduler';
import { msgs } from '../../../constants';
import SpvEntitlements from '../SpvEntitlements/SpvEntitlements';
import SpvRole from '../SpvRole/SpvRole';

import './SpvRoles.css';

function SpvRoles({ data, roleDetails, entitlements, dataTestId, className }) {
  const classes = classnames('SpvRoles', className);

  const [activeTab, setActiveTab] = useState('1');
  const localize = useLocalize();

  // TODO: remove isSchedulerEnabled when accounts are integrated with roles API
  const isSchedulerEnabled = false;

  const hasRole = data.roleKey && roleDetails;
  const hasEntitlements = data.roleKey && entitlements?.length > 0;

  const handleChange = (value) => {
    setActiveTab(value);
  };

  return (
    <section className={classes} data-testid={dataTestId}>
      <TabGroup value={activeTab} onChange={handleChange}>
        {hasRole && (
          <TabItem
            label={localize(msgs.pam.sidePanel.role)}
            value='1'
            icon={<Icon kind='Role' size='medium' color='neutral-1000' />}
          />
        )}
        {hasEntitlements && (
          <TabItem
            label={localize(msgs.pam.sidePanel.entitlements)}
            value='2'
            icon={
              <Icon kind='Entitlement' size='medium' color='neutral-1000' />
            }
          />
        )}
        {/* TODO: remove isSchedulerEnabled when scheduler roles API is ready */}
        {isSchedulerEnabled && (
          <TabItem
            label={localize(msgs.pam.sidePanel.schedule)}
            value='3'
            icon={<Icon kind='Calendar' size='medium' color='neutral-1000' />}
          />
        )}
      </TabGroup>
      {hasRole && (
        <TabContent
          className='SpvRoles-tabContent'
          value={activeTab}
          tabValue='1'>
          <SpvRole data={data} roleDetails={roleDetails} />
        </TabContent>
      )}
      {hasEntitlements && (
        <TabContent
          className='SpvRoles-tabContent'
          value={activeTab}
          tabValue='2'>
          <SpvEntitlements data={data} initialEntitlements={entitlements} />
        </TabContent>
      )}
      <TabContent
        className='SpvRoles-tabContent'
        value={activeTab}
        tabValue='3'>
        <Scheduler
          sessions={mockSessions}
          className='SpvRoles-Scheduler'
          timeFormat={{ roundToNearest: 'minute', is24HourFormat: false }}
          showRequestId={false}
        />
      </TabContent>
    </section>
  );
}

SpvRoles.propTypes = {
  data: ModuleDetails,
  roleDetails: RoleDetails.isRequired,
  entitlements: PropTypes.arrayOf(Entitlement).isRequired,
  dataTestId: PropTypes.string,
  className: PropTypes.string,
};

SpvRoles.defaultProps = {
  data: null,
  dataTestId: null,
  className: null,
};

export default SpvRoles;
