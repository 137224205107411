/**
 * Checks if an object is empty.
 *
 * @param {Object} obj  The object to check.
 * @returns {boolean} True if the object has no own properties, false otherwise.
 */
const isObjectEmpty = (obj) => Object.keys(obj).length === 0;

/**
 * Recursively removes `null` and `undefined` values from an array or object, including nested structures.
 * Also removes any empty objects or arrays from the removal of those values.
 *
 * @param {Array|Object} arrayOrObject - The array or object to trim.
 * @returns {Array|Object} The trimmed array or object with `null`, `undefined`, and empty objects/arrays removed.
 */

const trimEmptyUndefinedNull = (arrayOrObject) => {
  if (!arrayOrObject) return arrayOrObject;

  if (Array.isArray(arrayOrObject)) {
    return arrayOrObject
      .filter((item) => item !== null && item !== undefined)
      .map((item) =>
        typeof item === 'object' ? trimEmptyUndefinedNull(item) : item
      )
      .filter(
        (item) =>
          !(Array.isArray(item) && item.length === 0) &&
          !(item && typeof item === 'object' && isObjectEmpty(item))
      );
  }

  if (typeof arrayOrObject === 'object') {
    return Object.entries(arrayOrObject).reduce((acc, [key, originalValue]) => {
      const cleanedValue =
        typeof originalValue === 'object'
          ? trimEmptyUndefinedNull(originalValue)
          : originalValue;

      if (
        cleanedValue !== null &&
        cleanedValue !== undefined &&
        !(typeof cleanedValue === 'object' && isObjectEmpty(cleanedValue))
      ) {
        acc[key] = cleanedValue;
      }

      return acc;
    }, {});
  }

  return arrayOrObject;
};

export { isObjectEmpty, trimEmptyUndefinedNull };
