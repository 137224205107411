/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Button, IconButton } from '@material-ui/core';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import './ImageUploadModal.scss';

export const Close = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.62204 8.62204C8.78476 8.45932 9.04858 8.45932 9.21129 8.62204L16 15.4107L22.7887 8.62209C22.9514 8.45937 23.2152 8.45937 23.378 8.62209C23.5407 8.78481 23.5407 9.04862 23.3779 9.21134L16.5893 16L23.3779 22.7887C23.5407 22.9514 23.5407 23.2152 23.378 23.3779C23.2152 23.5406 22.9514 23.5406 22.7887 23.3779L16 16.5893L9.21129 23.378C9.04858 23.5407 8.78476 23.5407 8.62204 23.378C8.45932 23.2152 8.45932 22.9514 8.62204 22.7887L15.4108 16L8.62204 9.21129C8.45932 9.04858 8.45932 8.78476 8.62204 8.62204Z"
      fill="#212328"
    />
  </svg>
);

const useStyles = makeStyles({
  backButton: {
    backgroundColor: '#ebedff',
    borderRadius: '4px',
    color: '#3751ff',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
  },
  saveButton: {
    borderRadius: '4px',
    color: '#FFF',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
  },
});

const Dialog = withStyles({
  root: {
    zIndex: '1600 !important',
  },
})(MuiDialog);

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: '12px 24px',
    borderBottom: '1px solid #CECFD9',
  },
  title: {
    color: '#212328',
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '28px',
    marginBottom: '0',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    padding: '5px',
  },
  margin: {
    margin: theme.spacing(1),
  },
});

const DialogActions = withStyles({
  root: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '9px 24px',
  },
  spacing: {
    '& :not(:first-child)': {
      marginLeft: 0,
      marginTop: 8,
    },
  },
})(MuiDialogActions);

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} timeout={1000} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { classes, onClose } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} style={{ width: 1099 }}>
      <h6 className={classes.title}>Crop</h6>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          data-testid="closeOwnerModalBtn"
        >
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(() => ({
  root: {
    padding: '0',
    background: 'rgba(0, 0, 0)',
  },
}))(MuiDialogContent);

const ImagePreviewModal = ({
  onDialogClose, open, imgURL, onPreviewImage,
}) => {
  const classes = useStyles();
  const cropperRef = useRef(null);
  const [croppedImg, setCroppedImg] = useState('');

  const onCrop = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;

    setCroppedImg(cropper.getCroppedCanvas().toDataURL());
  };

  // const rotate = () => {
  //   const imageElement = cropperRef?.current;
  //   const cropper = imageElement?.cropper;

  //   cropper.rotate(-90);
  // };

  return (
    <Dialog
      open={open}
      onClose={() => onDialogClose(croppedImg)}
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          height: '675px',
          maxWidth: '1099px',
          borderRadius: '4px',
        },
      }}
    >
      <DialogTitle id="mage-preview-dialog" onClose={onDialogClose} />
      <DialogContent>
        <div className="image-preview">
          <Cropper
            ref={cropperRef}
            style={{ height: 500, width: '100%' }}
            zoomTo={0.5}
            crop={onCrop}
            initialAspectRatio={1}
            src={imgURL}
            viewMode={1}
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            background={false}
            responsive
            autoCropArea={1}
            checkOrientation={false}
            guides
            dragMode="move"
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          disableElevation
          onClick={onDialogClose}
          classes={{ root: classes.backButton }}
        >
          Back
        </Button>
        <div className="preview-action-buttons">
          {/* <Button
            variant="outlined"
            onClick={rotate}
            disableElevation
            color="primary"
            startIcon={<Rotate90DegreesCcwIcon />}
          >
            Rotate
          </Button> */}
          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={() => onPreviewImage(croppedImg)}
            classes={{ root: classes.saveButton }}
          >
            Save
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

ImagePreviewModal.propTypes = {
  onDialogClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  imgURL: PropTypes.string.isRequired,
  onPreviewImage: PropTypes.func.isRequired,
};

export default injectIntl(withRouter(ImagePreviewModal));
