
import React from 'react';
const SearchCloseIcon = () => (
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="close">
<path id="Vector" fill-rule="evenodd" clip-rule="evenodd" d="M2.09763 2.09788C2.22781 1.9677 2.43886 1.9677 2.56904 2.09787L8.00001 7.52884L13.431 2.09791C13.5611 1.96774 13.7722 1.96774 13.9024 2.09791C14.0325 2.22809 14.0325 2.43914 13.9024 2.56932L8.47142 8.00024L13.9024 13.4312C14.0325 13.5613 14.0325 13.7724 13.9024 13.9026C13.7722 14.0328 13.5611 14.0328 13.431 13.9026L8.00001 8.47165L2.56904 13.9026C2.43886 14.0328 2.22781 14.0328 2.09763 13.9026C1.96746 13.7724 1.96746 13.5614 2.09763 13.4312L7.52861 8.00024L2.09763 2.56928C1.96746 2.43911 1.96746 2.22805 2.09763 2.09788Z" fill="#89A3C7"/>
</g>
</svg>
);
export default SearchCloseIcon;
