/* eslint-disable import/prefer-default-export */
/* eslint-disable no-console */

export const Logger = {
  // NODE_ENV appears to be misreporting 'development' in host.
  isLocalhost:
    window.location.hostname === 'localhost' ||
    window.location.hostname === 'loopback' ||
    window.location.port.startsWith('300'),

  log: (...data) => Logger.isLocalhost && console.log(...data),
  warn: (...data) => Logger.isLocalhost && console.warn(...data),
  error: (...data) => Logger.isLocalhost && console.error(...data),
  info: (...data) => Logger.isLocalhost && console.info(...data),
  debug: (...data) => Logger.isLocalhost && console.debug(...data),
  table: (...data) => Logger.isLocalhost && console.table(...data),
};
