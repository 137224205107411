import { type ActionType, getType } from 'typesafe-actions';

import { RequestStatus } from '../../../../models';
import { actions } from '../actions';
import type RecentAssignmentsState from '../RecentAssignmentsState';

import INITIAL_STATE from './initialState';

export default (
  // eslint-disable-next-line @typescript-eslint/default-param-last -- conform to reducer signature
  state = INITIAL_STATE,
  action: ActionType<typeof actions>
): RecentAssignmentsState => {
  switch (action.type) {
    case getType(actions.certificationsRequested):
      return {
        ...state,
        certificationsStatus: RequestStatus.Pending,
      };
    case getType(actions.certificationsReceived):
      return {
        ...state,
        certificationsStatus: RequestStatus.Idle,
        certifications: action.payload,
      };
    case getType(actions.certificationsNotReceived):
      return {
        ...state,
        certificationsStatus: RequestStatus.Idle,
        certifications: 'error',
      };
    case getType(actions.requestsRequested):
      return {
        ...state,
        requestsStatus: RequestStatus.Pending,
      };
    case getType(actions.requestsReceived):
      return {
        ...state,
        requestsStatus: RequestStatus.Idle,
        requests: action.payload,
      };
    case getType(actions.requestsNotReceived):
      return {
        ...state,
        requestsStatus: RequestStatus.Idle,
        requests: 'error',
      };
    default:
      return state;
  }
};
