import { useEffect, useRef, useState } from 'react';
import throttle from './throttle';

const useInViewHook = (scrollRef) => {
  const observerRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    observerRef.current = new IntersectionObserver((entries) => {
      throttle(setIsVisible(entries[0].isIntersecting), 10);
    });

    if (scrollRef.current) {
      observerRef.current.observe(scrollRef.current);
    }

    return () => {
      observerRef.current.disconnect();
    };
  }, [scrollRef.current]);

  return isVisible;
};

export default useInViewHook;
