export const TABS_DATA = [
  { value: 'bc_tab_themes', label: 'UI.Gen2.Admin.Branding.Tab.TabHeader.Themes' },
  { value: 'bc_tab_logos', label: 'UI.Gen2.Admin.Branding.Tab.TabHeader.LogosTab' },
  // { value: 'bc_tab_images', label: 'Images' },
];

export const MODAL_TYPES = {
  delete: {
    title: 'UI.Gen2.Admin.Branding.Theme.Modal.Alert.Delete.Title',
    subTitle: 'UI.Gen2.Admin.Branding.Theme.Modal.Alert.Delete.Description',
    primaryButton: 'UI.Gen2.Admin.Branding.Theme.Modal.Alert.Delete.Button.Primary',
    secondaryButton: 'UI.Gen2.Admin.Branding.Theme.Modal.Alert.Delete.Button.Secondary',
  },
  deleteLogo: {
    title: 'Delete Logo',
    subTitle:
      'If you delete a logo that’s currently in use, the default Saviynt logo will replace it. Do you want to delete it?',
    primaryButton: 'UI.Gen2.Admin.Branding.Theme.Modal.Alert.Delete.Button.Primary',
    secondaryButton: 'UI.Gen2.Admin.Branding.Theme.Modal.Alert.Delete.Button.Secondary',
  },
  unsaved: {
    title: 'UI.Gen2.Admin.Branding.Theme.Modal.Alert.Unsaved.Title',
    subTitle: 'UI.Gen2.Admin.Branding.Theme.Modal.Alert.Unsaved.Description',
    primaryButton: 'UI.Gen2.Admin.Branding.Theme.Modal.Alert.Unsaved.Button.Primary',
    secondaryButton: 'UI.Gen2.Admin.Branding.Theme.Modal.Alert.Delete.Button.Secondary',
  },
  createtheme: {
    title: 'UI.Gen2.Admin.Branding.Theme.Modal.Alert.createDiscard.Title',
    subTitle: 'UI.Gen2.Admin.Branding.Theme.Modal.Alert.createDiscard.Description',
    primaryButton: 'UI.Gen2.Admin.Branding.Theme.Modal.Alert.Unsaved.Button.Primary',
    secondaryButton: 'UI.Gen2.Admin.Branding.Theme.Modal.Alert.Delete.Button.Secondary',
  },
  navMenuColors: {
    title: 'UI.Gen2.Admin.Branding.Theme.Modal.Example.NavColors.Title',
  },
  navLogo: {
    title: 'UI.Gen2.Admin.Branding.Theme.Modal.Example.NavLogo.Title',
  },
  headerLogo: {
    title: 'UI.Gen2.Admin.Branding.Theme.Modal.Example.HeaderLogo.Title',
  },
  brandLogo: {
    title: 'UI.Gen2.Admin.Branding.Theme.Modal.Example.BrandLogo.Title',
  },
  favIcon: {
    title: 'UI.Gen2.Admin.Branding.Theme.Modal.Example.FavIcon.Title',
  },
  cannotDelete: {
    title: 'UI.Gen2.Admin.Branding.Theme.Modal.Alert.CannotDelete.Title',
    subTitle:
      'UI.Gen2.Admin.Branding.Theme.Modal.Alert.CannotDelete.Description',
    primaryButton: 'UI.Gen2.Admin.Branding.Theme.Modal.Alert.CannotDelete.Button.Primary',
  },
};

export const NEW_THEME_INITIAL_DATA = {
  name: '',
  isOOTB: false,
  isActive: false,
  styles: {
    primary: '#66ACFF',
    secondary: '#212328',
    background: '#ffffff',
    isMulticolor: false,
    displayName: '',
  },
  logos: {
    logo: '',
    headerlogo: '',
  },
};

export const NEW_THEME_PAYLOAD = {
  name: '',
  type: 'navigation',
  styles: {
    displayName: '',
    isMulticolor: false,
    primary: null,
    secondary: null,
    background: null,
  },
  logos: {
    logo: '',
    header: '',
  },
};

export const THEME_CHANGEABLE_ITEMS_STYLES = ['primary', 'secondary', 'background', 'displayName'];
export const THEME_CHANGEABLE_ITEMS_LOGOS = ['logo', 'headerlogo'];

export const defaultGen2Theme = {
  name: 'saviynt-classic',
  isOOTB: true,
  isActive: false,
  appearance: 'dark',
  styles: {
    primary: '#00C1C7',
    secondary: '#ffffff',
    background: '#001A40',
    isMulticolor: true,
    displayName: 'Saviynt Classic',
  },
  logos: {
    logo: null,
    headerlogo: null,
  },
};
