import React from 'react';
import Fab from '@material-ui/core/Fab';
import ArrowUpwardSharpIcon from '@material-ui/icons/ArrowUpwardSharp';
import { useDispatch, useSelector } from 'react-redux';
import styles from './ScrollToTop.module.css';
import { getResults } from '../../../actions/logsAction';

const ScrollToTop = ({ isDisplayed }) => {
  const dispatch = useDispatch();
  const query = useSelector((state) => state.admin?.logs.query);

  const handleScrollToTop = () => {
    dispatch(getResults(query));
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  if (isDisplayed) {
    return (
      <div className={styles.scrollToTop}>
        <Fab color="primary" aria-label="add" onClick={handleScrollToTop}>
          <ArrowUpwardSharpIcon />
        </Fab>
      </div>
    );
  }

  return null;
};

export default ScrollToTop;
