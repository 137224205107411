import React from 'react';
import NavColorsExampleIllustration from 'ui-home/src/ECMv6/assets/NavColorsExampleIllustration';
import NavLogoExampleIllustration from 'ui-home/src/ECMv6/assets/NavLogoExampleIllustration';
import FavIconExampleIllustration from 'ui-home/src/ECMv6/assets/FavIconExampleIllustration';
import BrandLogoExampleIllustration from 'ui-home/src/ECMv6/assets/BrandLogoExampleIllustration';
import classes from './ExampleModal.module.scss';
import { message } from '../../utils/helper';
import { Icon } from '@saviynt/design-system';

const WCAGLink = ({ intl }) => <a target="_blank" href="https://www.w3.org/WAI/WCAG21/Understanding/contrast-minimum.html" rel="noreferrer">{message(intl, 'UI.Gen2.Admin.Branding.Theme.Modal.Example.WCAG.LinkText')}</a>;

const NavMenuColorsExample = ({ intl }) => (
  <article className={classes.navColorsContainer}>
    <div>
      <p className={classes.bodyText}>
        {message(intl, 'UI.Gen2.Admin.Branding.Theme.Modal.Example.NavMenuColors.BodyText')}
      </p>
    </div>
    <div>
      <p className={classes.highlightText}>
        <Icon kind="alertfilled" size="small" />
        <span>
        <span className={classes.highlightTextTitle}>{message(intl, 'UI.Gen2.Admin.Branding.Theme.Modal.Example.NavMenuColors.HighLight.Header')}</span>
          <span>
            {' '}
            {message(intl, 'UI.Gen2.Admin.Branding.Theme.Modal.Example.NavMenuColors.HighLight.Description')}
            {' '}
            <WCAGLink intl={intl} />
          </span>
        </span>
      </p>
    </div>
    <div>
      <NavColorsExampleIllustration />
    </div>
  </article>
);

const NavLogoExample = ({ intl }) => (
  <article className={classes.navLogoContainer}>
    <div>
      <p className={classes.bodyText}>
        {message(intl, 'UI.Gen2.Admin.Branding.Theme.Modal.Example.NavLogo.BodyText')}
        <ul>
          <li>{message(intl, 'UI.Gen2.Admin.Branding.Theme.Modal.Example.NavLogo.BodyText.Bullet1')}</li>
          <li>{message(intl, 'UI.Gen2.Admin.Branding.Theme.Modal.Example.NavLogo.BodyText.Bullet2')}</li>
        </ul>
      </p>
    </div>
    <div>
      <p className={classes.highlightText}>
        <Icon kind="alertfilled" size="small" />
        <span>
          <span>
            {' '}
            {message(intl, 'UI.Gen2.Admin.Branding.Theme.Modal.Example.NavLogo.HighLight.Description')}
            {' '}
            <WCAGLink intl={intl}  />
          </span>
        </span>
      </p>
    </div>
    <div>
      <NavLogoExampleIllustration />
    </div>
  </article>
);

const BrandLogoExample = ({ intl }) => (
  <article className={classes.navLogoContainer}>
    <div>
      <p className={classes.bodyText}>
        {message(intl, 'UI.Gen2.Admin.Branding.Theme.Modal.Example.BrandLogo.BodyText')}
        <ul>
          <li>{message(intl, 'UI.Gen2.Admin.Branding.Theme.Modal.Example.BrandLogo.BodyText.Bullet1')}</li>
          <li>{message(intl, 'UI.Gen2.Admin.Branding.Theme.Modal.Example.BrandLogo.BodyText.Bullet2')}</li>
        </ul>
      </p>
    </div>
    <div>
      <p className={classes.highlightText}>
        <Icon kind="alertfilled" size="small" />
        <span>
          <span>
            {' '}
            {message(intl, 'UI.Gen2.Admin.Branding.Theme.Modal.Example.BrandLogo.HighLight.Description')}
            {' '}
            <WCAGLink intl={intl} />
          </span>
        </span>
      </p>
    </div>
    <div>
      <BrandLogoExampleIllustration />
    </div>
  </article>
);

const FavIcon = ({ intl }) => (
  <article className={classes.navLogoContainer}>
    <div>
      <p className={classes.bodyText}>
        {message(intl, 'UI.Gen2.Admin.Branding.Theme.Modal.Example.FavIcon.BodyText')}
        <ul>
          <li>{message(intl, 'UI.Gen2.Admin.Branding.Theme.Modal.Example.FavIcon.BodyText.Bullet1')}</li>
          <li>{message(intl, 'UI.Gen2.Admin.Branding.Theme.Modal.Example.FavIcon.BodyText.Bullet2')}</li>
        </ul>
      </p>
    </div>
    <div>
      <FavIconExampleIllustration />
    </div>
  </article>
);

const ExampleModals = ({ variant, intl }) => {
  switch (variant) {
    case 'navMenuColors': return <NavMenuColorsExample intl={intl} />;
    case 'navLogo': return <NavLogoExample intl={intl} />;
    case 'headerLogo':
    case 'brandLogo': return <BrandLogoExample intl={intl} />;
    case 'favIcon': return <FavIcon intl={intl} />;
    default: return <p>Coming Soon</p>;
  }
};

export default ExampleModals;
