export const UPDATE_KPI_CODE = 'kpiForm/neworupdateKpiFormCode';
export const UPDATE_KPI_ATTRIBUTES = 'kpiForm/updateKPIAttributes';
export const NEW_KPI_ATTRIBUTES = 'kpiForm/newKPIAttributes';
export const REFRESH_HOME_KPI = 'kpiForm/REFRESH_HOME_KPI';
export const FETCH_FEATURE_NAME_LIST = 'kpiForm/FETCH_FEATURE_NAME_LIST';
export const FETCH_FEATURE_NAME_LIST_SUCCESS = 'kpiForm/FETCH_FEATURE_NAME_LIST_SUCCESS';
export const FETCH_FEATURE_NAME_LIST_FAILURE = 'kpiForm/FETCH_FEATURE_NAME_LIST_FAILURE';

export const newKpiCode = ({ codeStr, fileName }, refreshKPI) => ({
  type: UPDATE_KPI_CODE,
  payload: { resourceBody: codeStr, fileName, folderPath: '/app/kpi' },
  refreshKPI,
});
export const updateKpiAttributes = payload => ({
  type: UPDATE_KPI_ATTRIBUTES,
  payload,
});

export const newKpiAttributes = payload => ({
  type: NEW_KPI_ATTRIBUTES,
  payload,
});

export const refreshKPI = () => ({
  type: REFRESH_HOME_KPI,
});

export const fetchFeatureNameList = () => ({
  type: FETCH_FEATURE_NAME_LIST,
});

export const fetchFeatureNameListSuccess = response => ({
  type: FETCH_FEATURE_NAME_LIST_SUCCESS,
  response,
});

export const fetchFeatureNameListFailure = error => ({
  type: FETCH_FEATURE_NAME_LIST_FAILURE,
  error,
});
