import PropTypes from 'prop-types';
import React from 'react';
import { IntlProvider } from 'react-intl';

/**
 * Component for conditional rendering of the IntlProvider only when
 * it's pam running on localhost:3001.
 *
 * @param {boolean} isPamLocalhostPort3001 - Bool indicating if pam is running on localhost:3001.
 * @param {ReactNode} props.children - The child components to render.
 * @returns {ReactNode} - The conditionally wrapped component.
 */

function LocalizedWrapper({ children }) {
  const isPamLocalhostPort3001 =
    (window.location.hostname === 'localhost' ||
      window.location.hostname === 'loopback') &&
    window.location.port === '3001';

  return isPamLocalhostPort3001 ? (
    <IntlProvider locale='en'>{children}</IntlProvider>
  ) : (
    children
  );
}

LocalizedWrapper.propTypes = { children: PropTypes.node.isRequired };

export default LocalizedWrapper;
