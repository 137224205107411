import React from 'react';
import { useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Icon, Link } from '@saviynt/design-system';
import './GetHelp.scss';

export const HelpGuideIcon = () => {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.80925 21.3532C7.25108 21.3529 7.609 20.9944 7.6087 20.5526L7.60869 20.5219C10.2352 14.9784 14.6966 10.4724 20.2078 7.7893H20.3727C20.8146 7.7893 21.1731 7.43113 21.1731 6.9893C21.1731 6.58405 20.8718 6.24917 20.481 6.19651L20.3703 5.94837C20.1941 6.02696 20.0189 6.10728 19.8446 6.1893H19.5126C19.3539 6.1893 19.206 6.2355 19.0816 6.31517C18.9572 6.2355 18.8094 6.1893 18.6507 6.1893H17.789C17.3751 6.1893 16.893 6.11936 16.3215 6.01032C16.111 5.97017 15.8767 5.92214 15.631 5.87178C15.2557 5.79487 14.8537 5.71249 14.4701 5.64449C14.0351 5.56736 13.6199 5.85751 13.5428 6.29256C13.4656 6.7276 13.7558 7.1428 14.1908 7.21992C14.5697 7.28709 14.9152 7.3582 15.2623 7.42964C15.5103 7.48068 15.7592 7.5319 16.0216 7.58197C16.3426 7.6432 16.668 7.69917 16.9901 7.73747C13.1155 10.1337 9.85671 13.4299 7.50491 17.3346C7.46186 17.0939 7.40947 16.8464 7.35598 16.6012C7.32714 16.469 7.29734 16.3349 7.26708 16.1988C7.15392 15.6898 7.03442 15.1523 6.93635 14.5828C6.86138 14.1474 6.44762 13.8552 6.0122 13.9301C5.57678 14.0051 5.28458 14.4189 5.35956 14.8543C5.46487 15.4659 5.59513 16.0515 5.70913 16.564C5.7383 16.6951 5.7664 16.8215 5.79275 16.9422C5.92939 17.5686 6.00834 18.0038 6.00834 18.2925L6.00836 19.0466C6.00837 19.1829 6.04245 19.3112 6.10254 19.4235C6.04248 19.5358 6.00843 19.6641 6.00846 19.8004L6.00857 20.1678C5.96901 20.2541 5.92987 20.3407 5.89115 20.4275L6.00867 20.4799L6.0087 20.5537C6.009 20.9955 6.36742 21.3535 6.80925 21.3532Z"
        fill="#00245B"
      />
      <path
        d="M5.92068 11.8858C6.35488 11.9675 6.77312 11.6818 6.85484 11.2476C7.03963 10.2656 7.47054 9.33906 8.32345 8.54298C9.20673 7.71856 10.1076 7.31121 10.9993 7.13445C11.4327 7.04854 11.7144 6.62756 11.6285 6.19416C11.5426 5.76077 11.1216 5.47907 10.6882 5.56498C9.51188 5.79816 8.3386 6.34019 7.23172 7.37332C6.0961 8.43327 5.52125 9.68273 5.28244 10.9516C5.20072 11.3858 5.48647 11.8041 5.92068 11.8858Z"
        fill="#00245B"
      />
      <path
        d="M56.6932 43.0553C56.8293 42.948 57.001 42.8839 57.1877 42.8838C57.6125 42.8835 57.9601 43.2142 57.9867 43.6323L58.1087 43.6868C58.069 43.7757 58.0289 43.8643 57.9884 43.9528L57.9885 44.4301C57.9886 44.5651 57.9552 44.6922 57.8962 44.8038C57.9552 44.9153 57.9886 45.0424 57.9886 45.1774L57.9887 45.9251C57.9887 46.2097 58.0673 46.6401 58.2041 47.2617C58.2302 47.3806 58.2581 47.505 58.2871 47.6341L58.2875 47.6358C58.4016 48.1445 58.5321 48.7261 58.6375 49.3335C58.713 49.7689 58.4214 50.183 57.9861 50.2586C57.5507 50.3341 57.1366 50.0425 57.0611 49.6071C56.963 49.0422 56.8436 48.5095 56.7303 48.0043C56.7001 47.8694 56.6703 47.7366 56.6415 47.6055C56.5826 47.3379 56.5249 47.067 56.4795 46.8052C54.1272 50.7016 50.8711 53.9906 47.0012 56.3821C47.3249 56.42 47.652 56.4757 47.9748 56.5368C48.2374 56.5865 48.4863 56.6373 48.7345 56.6879C49.0811 56.7586 49.4261 56.829 49.8044 56.8956C50.2396 56.9721 50.5303 57.3869 50.4538 57.8221C50.3772 58.2572 49.9624 58.5479 49.5273 58.4714C49.1445 58.4041 48.7435 58.3226 48.3688 58.2464C48.1228 58.1964 47.8881 58.1488 47.6774 58.1089C47.1062 58.0009 46.624 57.9315 46.2097 57.9315H45.3481C45.1895 57.9315 45.0416 57.8853 44.9173 57.8057C44.7929 57.8853 44.645 57.9315 44.4864 57.9315H44.1414C43.9717 58.0113 43.801 58.0894 43.6295 58.1659L43.522 57.9248C43.1293 57.8738 42.8259 57.5381 42.8259 57.1315C42.8259 56.6897 43.1845 56.3315 43.6263 56.3315H43.7786C49.2982 53.6481 53.766 49.1369 56.3942 43.5858C56.415 43.4158 56.4891 43.2623 56.5992 43.1425C56.6154 43.1067 56.6315 43.0708 56.6475 43.0349L56.6932 43.0553Z"
        fill="#00245B"
      />
      <path
        d="M57.1415 52.9112C57.2242 52.4771 57.6431 52.1923 58.0771 52.275C58.5111 52.3577 58.7959 52.7766 58.7133 53.2106C58.4734 54.4696 57.8975 55.708 56.763 56.7581C55.6544 57.784 54.48 58.321 53.3035 58.5515C52.87 58.6364 52.4496 58.3538 52.3647 57.9202C52.2797 57.4866 52.5624 57.0662 52.996 56.9813C53.89 56.8062 54.7922 56.4019 55.6762 55.5838C56.5272 54.7962 56.9568 53.8806 57.1415 52.9112Z"
        fill="#00245B"
      />
      <path
        d="M7.60171 43.5776C7.5498 43.1862 7.21489 42.8841 6.80925 42.8838C6.36742 42.8835 6.009 43.2415 6.0087 43.6833L6.00846 44.4366C6.00843 44.5729 6.04248 44.7012 6.10255 44.8135C6.04245 44.9258 6.00837 45.0541 6.00836 45.1904L6.00834 45.9445C6.00834 46.2333 5.92939 46.6686 5.79275 47.2949C5.76662 47.4147 5.73877 47.5399 5.70986 47.6699L5.70961 47.671L5.70921 47.6728C5.59521 48.1853 5.46487 48.7713 5.35956 49.3829C5.28459 49.8183 5.57679 50.2321 6.01221 50.3071C6.44763 50.3821 6.86138 50.0899 6.93636 49.6544C7.03442 49.0849 7.15382 48.5478 7.26697 48.0388C7.29727 47.9025 7.32711 47.7683 7.35598 47.6359C7.41656 47.3583 7.47572 47.0776 7.52152 46.8072C9.90652 50.757 13.2203 54.0825 17.1607 56.4815C16.7832 56.5188 16.3993 56.5835 16.0216 56.6556C15.7596 56.7056 15.5111 56.7567 15.2635 56.8077L15.2629 56.8078L15.2623 56.8079C14.9152 56.8794 14.5697 56.9505 14.1908 57.0177C13.7558 57.0948 13.4656 57.51 13.5428 57.945C13.6199 58.3801 14.0351 58.6702 14.4701 58.5931C14.8538 58.5251 15.2556 58.4427 15.631 58.3658C15.8767 58.3154 16.111 58.2674 16.3215 58.2272C16.893 58.1182 17.3751 58.0483 17.789 58.0483H18.6507C18.8094 58.0483 18.9572 58.0021 19.0816 57.9224C19.206 58.0021 19.3539 58.0483 19.5126 58.0483H20.1101C20.1966 58.0879 20.2833 58.1271 20.3703 58.1659L20.4235 58.0467C20.8419 58.0207 21.1731 57.6732 21.1731 57.2483C21.1731 57.061 21.1087 56.8887 21.0008 56.7524L21.0221 56.7047C20.984 56.6877 20.9459 56.6706 20.9079 56.6534C20.791 56.5483 20.643 56.4771 20.4794 56.4553C14.8383 53.7868 10.2702 49.2188 7.60171 43.5776Z"
        fill="#00245B"
      />
      <path
        d="M6.85483 52.9898C6.77312 52.5556 6.35489 52.2698 5.92068 52.3516C5.48647 52.4333 5.20072 52.8515 5.28243 53.2857C5.52124 54.5547 6.09608 55.8042 7.23171 56.8642C8.33858 57.8974 9.51185 58.4394 10.6882 58.6726C11.1216 58.7585 11.5426 58.4768 11.6285 58.0434C11.7144 57.61 11.4327 57.1891 10.9993 57.1031C10.1076 56.9264 9.20674 56.519 8.32347 55.6945C7.47055 54.8984 7.03963 53.9718 6.85483 52.9898Z"
        fill="#00245B"
      />
      <path
        d="M56.3883 20.5525C56.388 20.9944 56.7459 21.3528 57.1877 21.3531C57.6296 21.3534 57.988 20.9954 57.9883 20.5536L57.9883 20.4812L58.1087 20.4275C58.069 20.3386 58.0289 20.25 57.9884 20.1616L57.9885 19.8068C57.9886 19.6718 57.9552 19.5447 57.8962 19.4331C57.9552 19.3216 57.9886 19.1945 57.9886 19.0595L57.9887 18.3119C57.9887 18.0272 58.0673 17.5969 58.2041 16.9753C58.2303 16.8564 58.2582 16.7319 58.2871 16.6028L58.2875 16.6013C58.4016 16.0927 58.5321 15.5108 58.6375 14.9036C58.713 14.4682 58.4214 14.0541 57.9861 13.9785C57.5508 13.903 57.1366 14.1946 57.0611 14.63C56.963 15.1949 56.8436 15.7276 56.7303 16.2328C56.7001 16.3675 56.6703 16.5006 56.6415 16.6315C56.5896 16.867 56.5388 17.105 56.4964 17.3371C54.1761 13.4838 50.9726 10.223 47.1658 7.83467C47.4355 7.79845 47.7066 7.7514 47.9748 7.70068C48.2373 7.65102 48.4863 7.60021 48.7345 7.54957C49.081 7.47885 49.4261 7.40843 49.8044 7.34189C50.2396 7.26536 50.5303 6.85056 50.4538 6.41541C50.3772 5.98026 49.9624 5.68955 49.5273 5.76607C49.1445 5.83339 48.7435 5.91488 48.3689 5.99101C48.1228 6.04101 47.8881 6.08871 47.6774 6.12856C47.1062 6.23659 46.624 6.30597 46.2097 6.30597H45.3481C45.1895 6.30597 45.0416 6.35214 44.9173 6.43179C44.7929 6.35214 44.645 6.30597 44.4864 6.30597H44.4C44.1453 6.18308 43.8884 6.06386 43.6295 5.94837L43.4626 6.32266C43.0991 6.39807 42.8259 6.72012 42.8259 7.10597C42.8259 7.54779 43.1845 7.90597 43.6263 7.90597H44.0287C49.429 10.601 53.7992 15.0547 56.3883 20.5159L56.3883 20.5525Z"
        fill="#00245B"
      />
      <path
        d="M57.1415 11.3261C57.2242 11.7601 57.6431 12.0449 58.0771 11.9622C58.5111 11.8795 58.7959 11.4607 58.7132 11.0266C58.4734 9.76774 57.8975 8.52934 56.7629 7.47934C55.6544 6.45342 54.48 5.91643 53.3035 5.686C52.8699 5.60107 52.4496 5.88372 52.3647 6.31731C52.2797 6.7509 52.5624 7.17124 52.996 7.25616C53.89 7.43127 54.7922 7.83554 55.6762 8.65361C56.5272 9.44119 56.9568 10.3568 57.1415 11.3261Z"
        fill="#00245B"
      />
      <path
        d="M25.262 18.9861C22.4847 20.4209 20.2228 22.7154 18.8289 25.5167L17.3964 24.804C18.9419 21.6979 21.4486 19.1553 24.5277 17.5646L25.262 18.9861Z"
        fill="#00245B"
      />
      <path
        d="M38.7381 18.9861C41.3469 20.3338 43.5011 22.4402 44.9082 25.0128L46.3119 24.2451C44.752 21.3931 42.3649 19.0589 39.4724 17.5646L38.7381 18.9861Z"
        fill="#00245B"
      />
      <path
        d="M18.8289 38.5975C20.2228 41.3988 22.4847 43.6933 25.262 45.1281L24.5277 46.5496C21.4486 44.9589 18.9419 42.4163 17.3964 39.3102L18.8289 38.5975Z"
        fill="#00245B"
      />
      <path
        d="M44.9082 39.1014C43.5011 41.674 41.3469 43.7804 38.7381 45.1281L39.4724 46.5496C42.3649 45.0553 44.752 42.7211 46.3119 39.8691L44.9082 39.1014Z"
        fill="#00245B"
      />
      <path
        d="M11.0846 44.2138C11.0101 44.0867 10.8467 44.0441 10.7197 44.1186C10.5926 44.1931 10.55 44.3565 10.6245 44.4836C12.6456 47.9303 15.4818 50.8411 18.8684 52.9515C18.9934 53.0294 19.1579 52.9912 19.2358 52.8662C19.3137 52.7412 19.2755 52.5768 19.1505 52.4989C15.837 50.4341 13.062 47.586 11.0846 44.2138Z"
        fill="#00245B"
      />
      <path
        d="M52.8558 19.8464C52.9303 19.9734 53.0937 20.016 53.2207 19.9415C53.3477 19.867 53.3903 19.7036 53.3158 19.5766C51.5058 16.4898 49.0421 13.8329 46.1148 11.7958C45.9939 11.7117 45.8278 11.7415 45.7436 11.8624C45.6595 11.9833 45.6893 12.1494 45.8102 12.2336C48.6744 14.2267 51.0849 16.8264 52.8558 19.8464Z"
        fill="#00245B"
      />
      <path
        d="M13.2064 44.8211C13.1236 44.6993 12.9577 44.6678 12.8359 44.7506C12.7142 44.8335 12.6826 44.9994 12.7655 45.1211C14.69 47.9493 17.222 50.3309 20.1739 52.0783C20.3007 52.1533 20.4642 52.1114 20.5392 51.9846C20.6143 51.8579 20.5723 51.6944 20.4456 51.6193C17.5613 49.9119 15.087 47.5847 13.2064 44.8211Z"
        fill="#00245B"
      />
      <path
        d="M50.7338 19.239C50.8167 19.3608 50.9826 19.3923 51.1043 19.3095C51.2261 19.2266 51.2576 19.0608 51.1748 18.939C50.8966 18.5301 50.6056 18.1306 50.3026 17.741C50.2122 17.6248 50.0447 17.6038 49.9284 17.6943C49.8122 17.7847 49.7912 17.9522 49.8816 18.0685C50.1777 18.4492 50.462 18.8396 50.7338 19.239Z"
        fill="#00245B"
      />
      <path
        d="M48.9549 16.5504C48.8471 16.6507 48.6783 16.6446 48.578 16.5367C47.0934 14.9403 45.3817 13.5579 43.4946 12.4408C43.3679 12.3658 43.326 12.2022 43.401 12.0755C43.476 11.9487 43.6396 11.9068 43.7663 11.9818C45.6977 13.1251 47.4493 14.5399 48.9686 16.1735C49.0689 16.2814 49.0628 16.4501 48.9549 16.5504Z"
        fill="#00245B"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.5777 5.49051C19.543 5.15357 19.7265 4.81845 20.0535 4.67559C23.713 3.07689 27.7543 2.19043 32.0004 2.19043C36.2466 2.19043 40.2878 3.07689 43.9474 4.67559C44.2749 4.8187 44.4585 5.15472 44.4229 5.49224C44.4346 5.60519 44.4223 5.7224 44.3825 5.83672L39.2151 20.6869C39.1415 20.8985 38.9825 21.0695 38.7768 21.1584C38.5712 21.2472 38.3377 21.2458 38.1331 21.1544C36.2609 20.3177 34.186 19.8521 32.0003 19.8521C29.8145 19.8521 27.7396 20.3177 25.8674 21.1544C25.6629 21.2458 25.4294 21.2472 25.2237 21.1584C25.018 21.0695 24.8591 20.8985 24.7854 20.6869L19.618 5.83672C19.578 5.72182 19.5658 5.60398 19.5777 5.49051ZM42.6751 5.87494C39.3824 4.53126 35.7789 3.79043 32.0004 3.79043C28.2219 3.79043 24.6182 4.53131 21.3255 5.87507L26.0177 19.3597C27.8753 18.6441 29.8928 18.2521 32.0003 18.2521C34.1077 18.2521 36.1252 18.6441 37.9828 19.3597L42.6751 5.87494Z"
        fill="#F25A00"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M42.9 38.1899C42.8086 38.3944 42.8071 38.628 42.896 38.8336C42.9848 39.0393 43.1559 39.1983 43.3675 39.2719L58.2198 44.4393C58.3343 44.4792 58.4518 44.4915 58.565 44.4797C58.9022 44.5147 59.2376 44.3313 59.3806 44.004C60.9795 40.3445 61.8661 36.3033 61.8661 32.0571C61.8661 27.8109 60.9795 23.7697 59.3806 20.1101C59.2376 19.7829 58.9021 19.5994 58.5649 19.6345C58.4518 19.6227 58.3343 19.635 58.2198 19.6748L43.3675 24.8422C43.1559 24.9159 42.9848 25.0749 42.896 25.2805C42.8071 25.4862 42.8086 25.7197 42.9 25.9243C43.7368 27.7965 44.2024 29.8714 44.2024 32.0571C44.2024 34.2428 43.7368 36.3177 42.9 38.1899ZM58.1813 21.3823L44.6947 26.0746C45.4104 27.9322 45.8024 29.9497 45.8024 32.0571C45.8024 34.1645 45.4104 36.182 44.6947 38.0396L58.1813 42.7319C59.5252 39.4392 60.2661 35.8356 60.2661 32.0571C60.2661 28.2786 59.5252 24.675 58.1813 21.3823Z"
        fill="#F25A00"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.8674 42.9575C25.6629 42.866 25.4294 42.8646 25.2237 42.9534C25.018 43.0423 24.859 43.2133 24.7854 43.4249L19.618 58.2772C19.578 58.3921 19.5658 58.5099 19.5777 58.6234C19.543 58.9604 19.7265 59.2954 20.0535 59.4383C23.713 61.0372 27.7542 61.9238 32.0004 61.9238C36.2466 61.9238 40.2878 61.0372 43.9474 59.4383C44.275 59.2952 44.4585 58.9592 44.4229 58.6217C44.4346 58.5088 44.4223 58.3915 44.3825 58.2772L39.2151 43.4249C39.1415 43.2133 38.9825 43.0423 38.7768 42.9534C38.5711 42.8646 38.3376 42.866 38.1331 42.9575C36.2609 43.7942 34.186 44.2599 32.0003 44.2599C29.8145 44.2599 27.7396 43.7942 25.8674 42.9575ZM42.6751 58.239L37.9828 44.7521C36.1252 45.4678 34.1077 45.8599 32.0003 45.8599C29.8929 45.8599 27.8753 45.4678 26.0177 44.7521L21.3254 58.2389C24.6182 59.5828 28.2218 60.3238 32.0004 60.3238C35.7789 60.3238 39.3825 59.5829 42.6751 58.239Z"
        fill="#F25A00"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.43364 44.4797C5.09644 44.5148 4.76094 44.3313 4.61798 44.004C3.01928 40.3445 2.13281 36.3032 2.13281 32.0571C2.13281 27.8109 3.01928 23.7697 4.61798 20.1101C4.76094 19.7829 5.09646 19.5994 5.43366 19.6345C5.54683 19.6227 5.66431 19.635 5.77887 19.6748L20.6291 24.8423C20.8407 24.9159 21.0117 25.0749 21.1005 25.2805C21.1894 25.4862 21.188 25.7197 21.0965 25.9242C20.2599 27.7964 19.7943 29.8713 19.7943 32.0571C19.7943 34.2428 20.2599 36.3177 21.0965 38.1899C21.188 38.3945 21.1894 38.628 21.1005 38.8336C21.0117 39.0393 20.8407 39.1983 20.6291 39.2719L5.77887 44.4393C5.66429 44.4792 5.54682 44.4915 5.43364 44.4797ZM5.81736 21.3823L19.3019 26.0745C18.5863 27.9321 18.1943 29.9497 18.1943 32.0571C18.1943 34.1645 18.5863 36.182 19.3019 38.0396L5.81736 42.7318C4.47366 39.4391 3.73281 35.8356 3.73281 32.0571C3.73281 28.2786 4.47366 24.675 5.81736 21.3823Z"
        fill="#F25A00"
      />
    </svg>
  );
};

export const DocumentsPortalIcon = () => {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.0454 2.21442C20.0233 2.07986 19.0856 2.79936 18.9511 3.82147L18.7236 5.54935C18.6659 5.9874 18.9743 6.38926 19.4123 6.44693C19.8504 6.5046 20.2522 6.19624 20.3099 5.75819L20.5374 4.03031C20.5566 3.8843 20.6906 3.78151 20.8366 3.80074L56.6016 8.50929C56.7476 8.52851 56.8504 8.66247 56.8312 8.80848L50.8326 54.3726C50.7749 54.8107 51.0833 55.2125 51.5213 55.2702C51.9594 55.3279 52.3612 55.0195 52.4189 54.5815L58.4175 9.01733C58.5521 7.99521 57.8326 7.05754 56.8105 6.92298L21.0454 2.21442Z"
        fill="#00245B"
      />
      <path
        d="M12.2184 9.3822C12.2858 8.35348 13.1744 7.57419 14.2032 7.64162L50.1996 10.0009C51.2283 10.0684 52.0076 10.957 51.9402 11.9857L48.9344 57.8446C48.9055 58.2855 48.5247 58.6195 48.0838 58.5906C47.6429 58.5617 47.3089 58.1809 47.3378 57.74L50.3436 11.8811C50.3532 11.7341 50.2419 11.6072 50.0949 11.5975L14.0985 9.23819C13.9516 9.22856 13.8246 9.33989 13.815 9.48685L13.721 10.9213C13.6921 11.3622 13.3112 11.6962 12.8704 11.6673C12.4295 11.6384 12.0955 11.2576 12.1244 10.8167L12.2184 9.3822Z"
        fill="#00245B"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.56445 15.0444C5.56445 14.0134 6.40019 13.1777 7.43112 13.1777H43.5048C44.5357 13.1777 45.3714 14.0134 45.3714 15.0444V59.935C45.3714 60.966 44.5357 61.8017 43.5048 61.8017H7.43112C6.40019 61.8017 5.56445 60.966 5.56445 59.935V15.0444ZM7.43112 14.7777C7.28385 14.7777 7.16445 14.8971 7.16445 15.0444V59.935C7.16445 60.0823 7.28384 60.2017 7.43112 60.2017H43.5048C43.6521 60.2017 43.7714 60.0823 43.7714 59.935V15.0444C43.7714 14.8971 43.6521 14.7777 43.5048 14.7777H7.43112Z"
        fill="#00245B"
      />
      <path
        d="M12.912 23.1419C12.912 22.7 13.2702 22.3419 13.712 22.3419H31.346C31.7878 22.3419 32.146 22.7 32.146 23.1419C32.146 23.5837 31.7878 23.9419 31.346 23.9419H13.712C13.2702 23.9419 12.912 23.5837 12.912 23.1419Z"
        fill="#0294F7"
      />
      <path
        d="M12.912 31.9589C12.912 31.517 13.2702 31.1589 13.712 31.1589H37.224C37.6658 31.1589 38.024 31.517 38.024 31.9589C38.024 32.4007 37.6658 32.7589 37.224 32.7589H13.712C13.2702 32.7589 12.912 32.4007 12.912 31.9589Z"
        fill="#0294F7"
      />
      <path
        d="M13.712 37.0369C13.2702 37.0369 12.912 37.395 12.912 37.8369C12.912 38.2787 13.2702 38.6369 13.712 38.6369H37.224C37.6658 38.6369 38.024 38.2787 38.024 37.8369C38.024 37.395 37.6658 37.0369 37.224 37.0369H13.712Z"
        fill="#0294F7"
      />
      <path
        d="M12.912 43.7149C12.912 43.273 13.2702 42.9149 13.712 42.9149H37.224C37.6658 42.9149 38.024 43.273 38.024 43.7149C38.024 44.1567 37.6658 44.5149 37.224 44.5149H13.712C13.2702 44.5149 12.912 44.1567 12.912 43.7149Z"
        fill="#0294F7"
      />
      <path
        d="M13.712 48.7929C13.2702 48.7929 12.912 49.151 12.912 49.5929C12.912 50.0347 13.2702 50.3929 13.712 50.3929H25.468C25.9098 50.3929 26.268 50.0347 26.268 49.5929C26.268 49.151 25.9098 48.7929 25.468 48.7929H13.712Z"
        fill="#0294F7"
      />
    </svg>
  );
};

const GetHelp = ({ intl }) => {
  const isGetHelpEnabled = useSelector((state) => state.requestHome.initialLoad.helpDetails);
  const isHelpFetchError = useSelector(
    (state) => state.requestHome.initialLoad.helpDetails?.isHelpFetchError
  );
  const { helpDetails } = useSelector((state) => state.profile);
  const getHelp = [
    {
      title: intl.formatMessage({
        id: 'NewRequestHome.GetHelp.HelpGuide.Label',
      }),
      description: intl.formatMessage({
        id: 'NewRequestHome.GetHelp.HelpGuide.Description',
      }),
      url: isGetHelpEnabled.helpGuideUrl,
      icon: <HelpGuideIcon />,
      isEnabled: isGetHelpEnabled.isHelpEnabled,
    },
    {
      title: intl.formatMessage({
        id: 'NewRequestHome.GetHelp.Documentation.Label',
      }),
      description: intl.formatMessage({
        id: 'NewRequestHome.GetHelp.Documentation.Description',
      }),
      url: isGetHelpEnabled.docUrl,
      icon: <DocumentsPortalIcon />,
      isEnabled: isGetHelpEnabled.isDocEnabled,
    },
  ];

  const handleNavigation = (url) => {
    if (url) window.open(url, '_blank');
  };
  return (
    <div className="get-help-section">
      <div className="help-title">
        <h4 className="get-help-title">
          {intl.formatMessage({ id: 'NewRequestHome.GetHelp.Title' })}
        </h4>
      </div>
      {isHelpFetchError ? (
        <div className="get-help-fetch-error">
          <div className="get-help-alert-icon">
            <Icon
              kind="AlertCriticalFilledSeparateColors"
              className="ModalIsland-alertContent-icon"
            />
          </div>
          <div>
            <span className="get-help-error-text">Feature Unavailable. </span>
            <span className="get-help-error-description">
              The server can't display the Help Guide because of a network problem. You can try
              again later.
              <Link
                className="link-button"
                RouterComponent={RouterLink}
                url={`${helpDetails.helpGuideUrl}`}
                text="Go to Help Guide"
                size="medium"
                suffixIconKind="ChevronRight"
              />
            </span>
          </div>
        </div>
      ) : (
        <div className="help-cards-section d-flex row">
          {getHelp.map((item) => (
            <div
              className={`help-card col-lg-6 col-md-6 col-sm-12 ${!item.isEnabled ? 'd-none' : ''}`}
              role="button"
              tabIndex="0"
              onClick={() => handleNavigation(item.url)}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  if (item.url) handleNavigation(item.url);
                }
              }}
            >
              <div className="help-section">
                <div className="help-icon">{item.icon}</div>
                <div className="help-content">
                  <h5 className="content-title">{item.title}</h5>
                  <h5 className="content-description">{item.description}</h5>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

GetHelp.propTypes = {
  intl: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};

export default injectIntl(GetHelp);
