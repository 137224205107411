import React, { useState, useEffect, useRef } from 'react';
import { GridSearch } from '../../GridSearch';
import { Pagination } from '../../Pagination';
import styles from '../GridBoot.module.scss';
import { A } from '../../A';
import Drawer from '@material-ui/core/Drawer';
import { Icon } from '../../Icon';
import { AdvancedFilter } from '../../AdvancedFilter';
import PropTypes from 'prop-types';

const GridHeader = ({
  globalSearchText,
  reqBodyOptions,
  setGlobalSearchText,
  setShowAdvancedFilter,
  topOffset,
  disableAdvancedFilters,
  totalCount,
  setReqBodyOptions,
  filtersCount,
  intl,
  showFilterBar,
  appliedFilters,
  setAppliedFilters,
  setCheckedFilters,
  showAdvancedFilter,
  advancedFilterColumns,
  requestColCacheEnabled,
  countLoading,
  setReqBodyOptionsPaginaton,
  dynamicView,
  isArchive,
  disablePagination,
  columnPaginationEnabled,
  countDisabled,
  data,
}) => {
  const filterPanelRef = useRef(null);

  const showGlobalText = globalSearchText => {
    if (!globalSearchText) return null;
    return (
      <li key={globalSearchText}>
        {globalSearchText}
        <A
          href="#"
          className={styles.remove_icon}
          onClick={e => {
            e.preventDefault();
            setGlobalSearchText(undefined);
          }}
        >
          <Icon name="remove" />
        </A>
      </li>
    );
  };


  const openAdvanceFilterCode = e => {
    if (e.keyCode === 13 && e.target.id === 'advanceFilterShowBtn') {
      setShowAdvancedFilter(true);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', openAdvanceFilterCode);
    return () => window.removeEventListener('keydown', openAdvanceFilterCode);
  }, [setShowAdvancedFilter]);

  return (
    <>
      <div className={`${styles.gridFilter}`} style={{ top: `${topOffset}px` }}>
        <div className={styles.gridFilterArea}>
          <div className="grid-filterBlk d-flex">
            <div className="grid_search">
              <GridSearch
                globalSearchText={globalSearchText}
                setGlobalSearchText={setGlobalSearchText}
              />
              {!disableAdvancedFilters && (
                <div
                  className="AdFilter-FullScreen"
                  tabIndex="1"
                  id="advanceFilterShowBtn"
                  href=""
                  onClick={() => {
                    // toggleDrawer('right', true);
                    setShowAdvancedFilter(true);
                  }}
                  role="presentation"
                >
                  <Icon name="advFilter" />
                </div>
              )}
              {dynamicView && dynamicView()}
            </div>
          </div>
          {!disablePagination && (
            <Pagination
              totalRecords={totalCount}
              recordsPerPage={reqBodyOptions.max}
              offset={reqBodyOptions.offset}
              reqBodyOptions={reqBodyOptions}
              setReqBodyOptions={setReqBodyOptions}
              countLoading={countLoading}
              setReqBodyOptionsPaginaton={setReqBodyOptionsPaginaton}
              countDisabled={countDisabled}
              data={data}
            />
          )}
        </div>
      </div>
      {filtersCount > 0 && (
        <div
          ref={filterPanelRef}
          className={`${styles.applied_filter_row} grid-applied-filter`}
          style={{
            top: `${topOffset}px`,
          }}
        >
          <div className={styles.filter_result}>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label>{`${intl.formatMessage({ id: 'SaviyntGrid.Filter' })} (${filtersCount})`}</label>
            <ul className={styles.fl_applied_list}>
              {showFilterBar(appliedFilters)}
              {showGlobalText(globalSearchText)}
            </ul>
          </div>
          <div className={styles.fl_btn}>
            <A
              className={styles.clear}
              href="#"
              onClick={e => {
                e.preventDefault();
                setAppliedFilters({});
                setCheckedFilters({});
                setGlobalSearchText(undefined);
              }}
            >
              {intl.formatMessage({ id: 'SaviyntGrid.Clear' })}
            </A>
          </div>
        </div>
      )}

      <Drawer
        anchor="right"
        open={showAdvancedFilter}
        classes={{ modal: styles.advanceFilterModal }}
        onClose={() => setShowAdvancedFilter(false)}
      >
        <AdvancedFilter
          hideAdvanceFilter={() => setShowAdvancedFilter(false)}
          appliedFilters={appliedFilters}
          setAppliedFilters={setAppliedFilters}
          columns={advancedFilterColumns}
          reqBodyOptions={reqBodyOptions}
          requestColCacheEnabled={requestColCacheEnabled}
          isArchive={isArchive}
          columnPaginationEnabled={columnPaginationEnabled}
        />
      </Drawer>
    </>
  );
};

GridHeader.defaultProps = {
  countDisabled: false,
  columnPaginationEnabled: false,
};

GridHeader.propTypes = {
  topOffset: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  globalSearchText: PropTypes.string.isRequired,
  setReqBodyOptions: PropTypes.func.isRequired,
  setAppliedFilters: PropTypes.func.isRequired,
  setGlobalSearchText: PropTypes.func.isRequired,
  advancedFilterColumns: PropTypes.node.isRequired,
  reqBodyOptions: PropTypes.shape({
    offset: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
  }).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  appliedFilters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  disableAdvancedFilters: PropTypes.bool.isRequired,
  countDisabled: PropTypes.bool,
  intl: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string.isRequired,
      formatMessage: PropTypes.func.isRequired,
    })
  ).isRequired,
  setShowAdvancedFilter: PropTypes.func.isRequired,
  setCheckedFilters: PropTypes.func.isRequired,
  columnPaginationEnabled: PropTypes.bool,
  filtersCount: PropTypes.number.isRequired,
  showAdvancedFilter: PropTypes.bool.isRequired,
  requestColCacheEnabled: PropTypes.bool.isRequired,
  showFilterBar: PropTypes.func.isRequired,
};


export default GridHeader;
