export const STATUS = {
  AVAILABLE: 'available',
  UNAVAILABLE: 'unavailable',
  BOOKED: 'booked',
  COMPLETE: 'complete',
};

export const msgs = {
  pam: {
    scheduler: {
      // Days
      sunday: {
        id: 'pam.scheduler.sunday',
        defaultMessage: 'Sunday',
      },
      monday: {
        id: 'pam.scheduler.monday',
        defaultMessage: 'Monday',
      },
      tuesday: {
        id: 'pam.scheduler.tuesday',
        defaultMessage: 'Tuesday',
      },
      wednesday: {
        id: 'pam.scheduler.wednesday',
        defaultMessage: 'Wednesday',
      },
      thursday: {
        id: 'pam.scheduler.thursday',
        defaultMessage: 'Thursday',
      },
      friday: {
        id: 'pam.scheduler.friday',
        defaultMessage: 'Friday',
      },
      saturday: {
        id: 'pam.scheduler.saturday',
        defaultMessage: 'Saturday',
      },

      // Labels
      availableForRequest: {
        id: 'pam.scheduler.availableForRequest',
        defaultMessage: 'Available for request',
      },
      booked: {
        id: 'pam.scheduler.booked',
        defaultMessage: 'Booked',
      },
      requested: {
        id: 'pam.scheduler.requested',
        defaultMessage: 'Requested',
      },
      complete: {
        id: 'pam.scheduler.complete',
        defaultMessage: 'Complete',
      },
    },
  },
};
