import React from 'react';
import PropTypes from 'prop-types';

import styles from './FileUpload.module.scss';

const DownloadView = props => {
  return (
    <div>
      <h4>
        {' '}
        {props.downloadLabel}
        {props.downloadClick}
      </h4>
      <div className={styles.downloadFile}>
        <span className={styles.fileIcon}>
          {' '}
          <img src="/ECMv6/assets/images/file.svg" width="15" alt="file.svg" />{' '}
        </span>
        <span className={styles.fileName}>{props.downloadFile}</span>
        <span className={styles.fileDownload}>
          {' '}
          <img
            src="/ECMv6/assets/images/Download.svg"
            onClick={props.downloadClick}
            onKeyPress={props.downloadClick}
            alt="Download.svg" className={styles.iconDownload}
          />{' '}
        </span>
      </div>
    </div>
  );
};

DownloadView.propTypes = {
  downloadLabel: PropTypes.string.isRequired,
  downloadClick: PropTypes.func.isRequired,
  downloadFile: PropTypes.string.isRequired,
};

export default DownloadView;
