import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { injectIntl } from 'react-intl';
import { push } from 'react-router-redirect';
import styles from './EmptyScreen.module.scss';

const EmptyScreen = ({
  title,
  Description,
  Description1,
  iconName,
  redirectText,
  redirectLink,
  intl,
  insidePanel,
  footer,
  actualDescription,
  redirectButtonToShow,
}) => {
  if (insidePanel)
    return (
      <div className={styles.insidePanelLayout}>
        {!insidePanel && (
          <div className={styles.recentActivity}>
            <h4 className={`${styles.activityTitle} mb-0`}>
              {intl.formatMessage({ id: 'RequestHome.GettingStartedWithRequests' })}
            </h4>
          </div>
        )}

        <div className={styles.emptyScreenInnerLayout}>
          <div className={styles.imageLayout}>
            <img
              src={`/ECMv6/assets/images/empty_placeholder/${iconName}`}
              className={styles.screenIcon}
              alt="emptyicon"
            />
          </div>
          <div className={styles.title}>{intl.formatMessage({ id: title })}</div>
          <div className={styles.subtitle}>{intl.formatMessage({ id: Description })}</div>
          {!/^((http|https|ftp):\/\/)/.test(intl.formatMessage({ id: redirectLink })) ? (
            <Link
              to={
                intl.formatMessage({ id: redirectLink }) === 'default'
                  ? ''
                  : intl.formatMessage({ id: redirectLink })
              }
            >
              <Button
                variant="outlined"
                size="small"
                color="primary"
                type="button"
                className={styles.btnDetails}
              >
                {intl.formatMessage({ id: redirectText })}
              </Button>
            </Link>
          ) : (
            <Button
              variant="outlined"
              size="small"
              color="primary"
              type="button"
              className={styles.btnDetails}
              onClick={() => window.open(intl.formatMessage({ id: redirectLink }), '_blank')}
            >
              {intl.formatMessage({ id: redirectText })}
            </Button>
          )}
          <div className={styles.support}> {intl.formatMessage({ id: footer })}</div>
        </div>
      </div>
    );
  return (
    <div className={styles.emptyScreenLayout}>
      <div className={styles.emptyScreenInnerLayout}>
        <div className={styles.imageLayout}>
          <img
            src={`/ECMv6/assets/images/empty_placeholder/${iconName}.png`}
            className={styles.screenIcon}
            alt="emptyicon"
          />
        </div>
        <div className={styles.title}>{intl.formatMessage({ id: title })} </div>
        <div className={styles.subtitle}>
          {actualDescription || intl.formatMessage({ id: Description })}{' '}
          {redirectText && (
            <span onClick={() => redirectLink ? push(redirectLink) : {}} className={styles.btnText}>
              {intl.formatMessage({ id: redirectText })}
            </span>
          )}{' '}
          {Description1 ? intl.formatMessage({ id: Description1 }) : ''}
        </div>
        {redirectText && redirectButtonToShow &&
          (redirectLink ? (
            <Button
              variant="outlined"
              color="primary"
              type="button"
              className={styles.btnDetails}
              onClick={() => push(redirectLink)}
            >
              {intl.formatMessage({ id: redirectText })}
            </Button>
          ) : (
            <Button variant="outlined" color="primary" type="button" className={styles.btnDetails}>
              {intl.formatMessage({ id: redirectText })}
            </Button>
          ))}
      </div>
    </div>
  );
};
EmptyScreen.defaultProps = {
  insidePanel: false,
  footer: '',
  Description1: '',
  actualDescription: '',
  redirectButtonToShow: true,
};

EmptyScreen.propTypes = {
  intl: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string.isRequired,
      formatMessage: PropTypes.func.isRequired,
    })
  ).isRequired,
  title: PropTypes.string.isRequired,
  Description: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
  redirectLink: PropTypes.string.isRequired,
  redirectText: PropTypes.string.isRequired,
  insidePanel: PropTypes.bool,
  footer: PropTypes.string,
  Description1: PropTypes.string,
  actualDescription: PropTypes.string,
  redirectButtonToShow: PropTypes.bool,
};

export default injectIntl(EmptyScreen);
