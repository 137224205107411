import React from 'react';
import { useLocalize } from '@saviynt/common';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { usePlatformLabel } from '../../../../../../../hooks/use-labels';
import { ModuleDetails } from '../../../../../../../models/PamModels';
import { msgs } from '../../../../../constants';
import SpvSection from '../../../../SpvSection/SpvSection';

import './SpvDatabase.css';

function SpvDatabase({ data, dataTestId, className }) {
  const classes = classnames('SpvDatabase', className);
  const localize = useLocalize();

  const cloudProvider = data.connectionType?.toUpperCase();

  const hasSummaryFields =
    data.platformType || data.description || data.customproperty10;

  const hasSystemDetailsFields =
    data.customproperty16 || data.customproperty9 || data.customproperty17;

  return (
    <div className={classes} data-testid={dataTestId}>
      {hasSummaryFields && (
        <SpvSection
          title={localize(msgs.pam.sidePanel.summary)}
          fields={[
            {
              label: localize(msgs.pam.sidePanel.platform),
              value: data.platformType,
            },
            {
              label: localize(msgs.pam.sidePanel.description),
              value: data.description,
            },
            {
              label: localize(msgs.pam.sidePanel.location),
              value: data.customproperty10,
            },
          ]}
        />
      )}
      {hasSystemDetailsFields && (
        <SpvSection
          title={localize(msgs.pam.sidePanel.systemDetails)}
          fields={[
            {
              label: localize(msgs.pam.sidePanel.databaseType),
              value: data.customproperty16,
            },
            {
              label: localize(msgs.pam.sidePanel.ipHost),
              value: data.customproperty9,
            },
            {
              label: usePlatformLabel(cloudProvider),
              value: data.customproperty17,
            },
          ]}
          className='SpvDatabase-section'
        />
      )}
    </div>
  );
}

SpvDatabase.propTypes = {
  data: ModuleDetails,
  dataTestId: PropTypes.string,
  className: PropTypes.string,
};

SpvDatabase.defaultProps = {
  data: null,
  dataTestId: null,
  className: null,
};

export default SpvDatabase;
