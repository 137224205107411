/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-curly-spacing */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  getCurrentDate,
  isObjectEmpty,
  removeClassesForHidingElements,
  SECONDS_PER_HOUR,
  useDetectScroll,
  useLocalize,
} from '@saviynt/common';
import {
  AccordionStepper,
  AlertBanner,
  Box,
  Button,
  FooterActionBar,
  Icon,
  InlineMessage,
  Link,
  Typography,
} from '@saviynt/design-system';

import MpaJustificationDetails from '../../../components/ModalPageForms/AccordionContentPanels/MpaJustificationDetails/MpaJustificationDetails';
import MpaTimeAccessDuration from '../../../components/ModalPageForms/AccordionContentPanels/MpaTimeAccessDuration/MpaTimeAccessDuration';
import {
  initialCriticalState,
  MODAL_PAGE_KINDS,
} from '../../../components/ModalPageForms/constants';
import ModalFormHeader from '../../../components/ModalPageForms/ModalFormHeader';
import ViewScheduleLink from '../../../components/ModalPageForms/ModalIslands/ViewScheduleModalIsland/ViewScheduleLink/ViewScheduleLink';
import ViewScheduleModalIsland from '../../../components/ModalPageForms/ModalIslands/ViewScheduleModalIsland/ViewScheduleModalIsland';
import { REQUEST_BASE_URL } from '../../../misc/constants';
import ModalPageDurationService from '../../../services/ModalPages/ModalPageDurationService';
import ModalPageFormSubmissionService from '../../../services/ModalPages/ModalPageFormSubmissionService';
import {
  handleClearCredentialForm,
  handleCredentialFormRequestor,
} from '../../../store/actions';
import hideHostElementsWrapper from '../../../utilities/hideHostElements';

import { renderModalPageFooterActionBar } from './helpers';
import useModalPageStrings from './use-modalpagestrings';

import '../ModalPages.css';

function ModalPageContentRole() {
  const SHOW_SCHEDULE_LINK = false; // update when schedule is ready.

  // Accordion expanded booleans
  const [isExpandedDuration, setIsExpandedDuration] = useState(true);
  const [isExpandedJustificationDetails, setIsExpandedJustificationDetails] =
    useState(false);

  // TimeAccessDuration
  const [isScheduleViewOpen, setIsScheduleViewOpen] = useState(false);
  const [toggleChoice, setToggleChoice] = useState(1);
  const [isDurationCustom, setIsDurationCustom] = useState(false);
  const [shouldDurationContinue, setShouldDurationContinue] = useState(false);
  const [nowStartDateOfNextCredential, setNowStartDateOfNextCredential] =
    useState(null);
  const [futureStartDateOfNextCredential, setFutureStartDateOfNextCredential] =
    useState(null);
  const [nowTabStartDate, setNowTabStartDate] = useState(getCurrentDate());
  const [nowTabEndDate, setNowTabEndDate] = useState(null);
  const [futureTabStartDate, setFutureTabStartDate] = useState(null);
  const [futureTabEndDate, setFutureTabEndDate] = useState(null);
  const [durationAccordionValue, setDurationAccordionValue] = useState(null);
  const [isTimeBlocked, setIsTimeBlocked] = useState(false);
  // Justification Details
  const [hasDurationBeenFilled, setHasDurationBeenFilled] = useState(false);
  const [justificationInputValue, setJustificationInputValue] = useState('');
  const [isJustificationInputCritical, setIsJustificationInputCritical] =
    useState(false);
  const [ticketNumber, setTicketNumber] = useState('');
  // Upload
  const [uploadedDetailFiles, setUploadedDetailFiles] = useState(null);
  const [hasErrorInDetailFiles, setHasErrorInDetailFiles] = useState(null);
  // Critical State
  const [isFormCritical, setIsFormCritical] = useState(initialCriticalState);
  const [isFormFailureAlertVisible, setIsFormFailureAlertVisible] =
    useState(false);
  const [isDurationEditingAlertVisible, setIsDurationEditingAlertVisible] =
    useState(false);

  const [isRequestLoading, setIsRequestLoading] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const localize = useLocalize();
  const accordionContentRef = useRef(null);
  const viewScheduleModalContentRef = useRef(null);

  const [cookies] = useCookies(['user_name']);
  const userName = cookies.user_name;

  const isNowTab = toggleChoice === 1;

  // Redux Selectors
  const endpointDetails = useSelector(
    (state) => state.privilegedAccess.SelectedListDetails
  );

  // eslint-disable-next-line no-unused-vars -- TODO: (ROLES) delete if not needed for scheduler
  const isExclusiveAccess = useSelector(
    (state) => state.privilegedAccess.SelectedListIsExclusiveAccess
  );
  const bootstrapConfigMaxTime = useSelector(
    (state) => state.privilegedAccess.SelectedListMaxCredlessSessionRequestTime
  );
  const isDurationBeingEdited = useSelector(
    (state) => state.credentialRequestForm.isDurationBeingEdited
  );

  // Redirect on refresh due to lost SelectedListDetails store data
  useEffect(() => {
    if (isObjectEmpty(endpointDetails)) {
      // Can't use goBack in react.strict
      history.push(REQUEST_BASE_URL);
    }

    // Clear COC form on history.push() back
    dispatch(handleClearCredentialForm('Clear COC Request Form success'));

    // hide host toast messages, etc when flag is set
    hideHostElementsWrapper();

    // Set form pamtype to 'credential' on COC pages
    // if (isCOCModal)
    //   dispatch(handleIsCredentialsRequest('Updated COC form pamtype'));

    return () => {
      removeClassesForHidingElements();
    };
  }, []);

  const accountSelection = useMemo(
    () => ({
      description: endpointDetails.roleType,
      exclusiveAccess: true,
      federatedAccount: false,
      maxtime: endpointDetails.roleMaxTimeFrameHrs * SECONDS_PER_HOUR,
      title: endpointDetails.roleName,
      value: endpointDetails.roleKey,
    }),
    [endpointDetails]
  );

  // Critical check
  const isDurationCritical = Boolean(
    !isDurationBeingEdited &&
      (isFormCritical.startdate || isFormCritical.enddate)
  );

  // Localization
  const {
    MODAL_HEADER_TEXT,
    SUBMIT_BTN_TEXT,
    SUBMIT_TITLE_TEXT,
    DURATION_HEADER_TEXT,
    DURATION_SUBHEADER_TEXT,
    JUST_DETAILS_HEADER_TEXT,
    JUST_DETAILS_SUBHEADER_TEXT,
    CREDENTIAL_REQUEST_COMMENT_TEXT,
    EDITING_DURATION_CRITICAL_BANNER_TEXT,
    ALERT_JUMP_TO_LINK_TEXT,
  } = useModalPageStrings(MODAL_PAGE_KINDS.COR);

  useEffect(() => {
    // Update the redux requestor on mount
    if (userName)
      dispatch(handleCredentialFormRequestor(userName, 'Requestor success'));
  }, [userName]);

  // Duration Service
  const { sessionsToBeIterated } = ModalPageDurationService(
    MODAL_PAGE_KINDS.COR,
    isNowTab,
    false, // isRemoteAppEnabled,
    accountSelection,
    false, // isSap
    null, // appLauncherSelection,
    isDurationCustom,
    shouldDurationContinue,
    nowTabStartDate,
    nowTabEndDate,
    setNowTabStartDate,
    setNowTabEndDate,
    futureTabStartDate,
    futureTabEndDate,
    setDurationAccordionValue,
    setNowStartDateOfNextCredential,
    setFutureStartDateOfNextCredential,
    userName,
    endpointDetails?.endpointKey,
    false, // isCOCorExclusive, // TODO (ROLES) check if needed t/f
    dispatch
  );

  // Form Submit Service
  const {
    isCredentialRequestFormFilled: isRequestFormFilled,
    submitResponseErrorMessage,
    handleCredentialFormRequest: handleRoleFormRequest,
  } = ModalPageFormSubmissionService(
    false, // isCOCModal,
    true, // isCORModal
    false, // isRemoteAppEnabled,
    false, // isSap,
    uploadedDetailFiles,
    hasErrorInDetailFiles,
    justificationInputValue,
    isJustificationInputCritical,
    isNowTab,
    isTimeBlocked,
    hasDurationBeenFilled,
    ticketNumber,
    userName,
    setIsFormCritical,
    setNowTabStartDate,
    setIsFormFailureAlertVisible,
    setIsDurationEditingAlertVisible,
    setIsJustificationInputCritical,
    setIsRequestLoading,
    dispatch,
    CREDENTIAL_REQUEST_COMMENT_TEXT,
    endpointDetails // TODO: (ROLES) check if param needed after refactor/redux
  );

  // Page scroll references
  const { isScrolled: accordionContentRefIsScrolled } = useDetectScroll(
    null,
    accordionContentRef
  );
  const { isScrolled: viewScheduleModalSectionRefIsScrolled } = useDetectScroll(
    null,
    viewScheduleModalContentRef
  );

  const buildAccordionValue = (string) => {
    if (!string) return null;

    return (
      <Typography
        kind='body1-bold'
        className='AccordionStepper-supportingContent-accordionValue'>
        {string}
      </Typography>
    );
  };

  const accordionCriticalHelperText = (
    <InlineMessage kind='missingRequiredField' size='small' />
  );

  // Close Duration Accordion on selection
  useEffect(() => {
    if (isNowTab && durationAccordionValue && shouldDurationContinue) {
      setIsExpandedDuration(false);
      setIsExpandedJustificationDetails(true);
      setShouldDurationContinue(false);
    }

    if (!isNowTab && durationAccordionValue && shouldDurationContinue) {
      setIsExpandedDuration(false);
      setIsExpandedJustificationDetails(true);
      setShouldDurationContinue(false);
    }
  }, [durationAccordionValue, shouldDurationContinue]);

  useEffect(() => {
    // On critical state open Missing value accordions
    if (isFormCritical.startdate || isFormCritical.enddate)
      setIsExpandedDuration(true);
    if (isJustificationInputCritical) setIsExpandedJustificationDetails(true);
  }, [isFormCritical, isJustificationInputCritical]);

  // Render conditions
  const shouldSubmitBeFilled = Boolean(
    isRequestFormFilled && !hasErrorInDetailFiles
  );

  const shouldRenderJustificationAccordion = () => {
    if (hasDurationBeenFilled) return true;

    return false;
  };

  return (
    <Box className='ModalPage-root'>
      <ModalFormHeader
        title={MODAL_HEADER_TEXT}
        endpointDetails={endpointDetails}
      />
      <Box className='ModalPage-alertBanner'>
        <AlertBanner
          colorTheme='Critical'
          title={SUBMIT_TITLE_TEXT}
          description={localize(submitResponseErrorMessage)}
          isVisible={isFormFailureAlertVisible}
          onCancel={() => setIsFormFailureAlertVisible(false)}
        />
        <AlertBanner
          colorTheme='Critical'
          isColonVisible={false}
          description={EDITING_DURATION_CRITICAL_BANNER_TEXT}
          isVisible={isDurationEditingAlertVisible}
          onCancel={() => setIsDurationEditingAlertVisible(false)}
          LinkComponent={
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <Link
              url='#Section_further_down'
              text={ALERT_JUMP_TO_LINK_TEXT}
              suffixIconKind='ArrowDown'
              color='neutralInverse'
              target='_self'
            />
          }
        />
      </Box>
      <section className='ModalPage' ref={accordionContentRef}>
        <Box className='ModalPage-scrollingArea'>
          {/* Modals related to roles only */}
          <ViewScheduleModalIsland
            viewScheduleModalContentRef={viewScheduleModalContentRef}
            sectionRefIsScrolled={viewScheduleModalSectionRefIsScrolled}
            isScheduleViewOpen={isScheduleViewOpen}
            setIsScheduleViewOpen={setIsScheduleViewOpen}
            selectedAccountName={accountSelection?.title}
            selectedAccountSessions={sessionsToBeIterated}
          />

          {/* Duration Accordion */}
          <Box id='Section_further_down'>
            <AccordionStepper
              isExpanded={isExpandedDuration}
              setIsExpanded={setIsExpandedDuration}
              prefixIconKind='time'
              headerText={DURATION_HEADER_TEXT}
              primarySupportingText={DURATION_SUBHEADER_TEXT}
              accordionValue={buildAccordionValue(durationAccordionValue)}
              suffixLink={
                SHOW_SCHEDULE_LINK && (
                  <ViewScheduleLink
                    setIsScheduleViewOpen={setIsScheduleViewOpen}
                  />
                )
              }
              isCritical={isDurationCritical}
              CriticalMessage={
                isDurationCritical && accordionCriticalHelperText
              }>
              <MpaTimeAccessDuration
                userName={userName}
                toggleChoice={toggleChoice}
                setToggleChoice={setToggleChoice}
                setShouldDurationContinue={setShouldDurationContinue}
                bootstrapConfigMaxTime={bootstrapConfigMaxTime}
                nowStartDateOfNextCredential={nowStartDateOfNextCredential}
                futureStartDateOfNextCredential={
                  futureStartDateOfNextCredential
                }
                nowTabStartDate={nowTabStartDate}
                setNowTabStartDate={setNowTabStartDate}
                nowTabEndDate={nowTabEndDate}
                setNowTabEndDate={setNowTabEndDate}
                futureTabStartDate={futureTabStartDate}
                setFutureTabStartDate={setFutureTabStartDate}
                futureTabEndDate={futureTabEndDate}
                setFutureTabEndDate={setFutureTabEndDate}
                isTimeBlocked={isTimeBlocked}
                setIsTimeBlocked={setIsTimeBlocked}
                setIsScheduleViewOpen={setIsScheduleViewOpen}
                accountSelection={accountSelection}
                setDurationAccordionValue={setDurationAccordionValue}
                isDurationCustom={isDurationCustom}
                setIsDurationCustom={setIsDurationCustom}
                hasDurationBeenFilled={hasDurationBeenFilled}
                setHasDurationBeenFilled={setHasDurationBeenFilled}
                selectedAccountSessions={sessionsToBeIterated}
                isExpandedDuration={isExpandedDuration}
                setIsExpandedDuration={setIsExpandedDuration}
                isFormCritical={isFormCritical}
                isCOCorExclusive // TODO: (ROLES) check if needed for scheduling.
                isCTSAccountExclusive={false}
              />
            </AccordionStepper>
          </Box>

          {/* Details Accordion */}
          {shouldRenderJustificationAccordion() && (
            <AccordionStepper
              isExpanded={isExpandedJustificationDetails}
              setIsExpanded={setIsExpandedJustificationDetails}
              prefixIconKind='document'
              headerText={JUST_DETAILS_HEADER_TEXT}
              primarySupportingText={JUST_DETAILS_SUBHEADER_TEXT}
              accordionValue={buildAccordionValue(justificationInputValue)}>
              <MpaJustificationDetails
                justificationInputValue={justificationInputValue}
                setJustificationInputValue={setJustificationInputValue}
                isJustificationInputCritical={isJustificationInputCritical}
                setIsJustificationInputCritical={
                  setIsJustificationInputCritical
                }
                ticketNumber={ticketNumber}
                setTicketNumber={setTicketNumber}
                setUploadedDetailFiles={setUploadedDetailFiles}
                setHasErrorInDetailFiles={setHasErrorInDetailFiles}
                isPlatformAD={false}
              />
            </AccordionStepper>
          )}
        </Box>
      </section>
      {renderModalPageFooterActionBar(
        FooterActionBar,
        accordionContentRefIsScrolled,
        Button,
        Icon,
        handleRoleFormRequest,
        shouldSubmitBeFilled,
        isRequestLoading,
        SUBMIT_BTN_TEXT
      )}
    </Box>
  );
}

export default ModalPageContentRole;
