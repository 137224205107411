import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  List,
  ListItem,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import SessionStorageService from 'ui-common/src/utils/SessionStorageService';
import { userDetailsAction } from 'ui-common/src/utils/LoginActions';
import { LANGUAGE_CHANGE_STATE } from '../../../features/MainLayout/Login/constants';
import { InitializeUpdateLanguage } from '../../../actions/profileMenuAction';

const useStyles = makeStyles(() => ({
  constainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    '& h4': {
      fontSize: '18px',
      fontWeight: '500 !important',
    },
  },
  list: {
    fontSize: '16px',
  },
}));
const LanguageList = ({
  intl, onClose,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [languageSupport, setLanguageSupport] = useState([]);

  const { editLanguage } = useSelector((state) => state.user || {});

  const { languagesSupported } = useSelector((state) => state.profile);

  const updateLanguage = () => {
    window.location.reload();
  };

  useEffect(() => {
    if (editLanguage === LANGUAGE_CHANGE_STATE.SUCCESS) {
      if (updateLanguage) {
        updateLanguage();
      }
    }
  }, [editLanguage]);

  const changeLanguage = (lang) => {
    dispatch(InitializeUpdateLanguage(lang)).then(() => {
      // Detect if we're on a grails page
      if (window.updateLanguage) {
        window.location.reload();
      }

      // This response contains the selected language. When it is is received,
      // Layout component will trigger a call for the selected translations.
      dispatch(userDetailsAction());
    });

    onClose();
  };

  const getSupportLanguageList = () => {
    const langs = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const [lang] of Object.entries(languagesSupported)) {
      langs.push({ code: lang, value: languagesSupported[lang] });
    }

    setLanguageSupport(langs);
  };

  useEffect(() => {
    getSupportLanguageList();
  }, [languagesSupported]);

  return (
    <div className={classes.constainer}>
      <h4>
        {intl.formatMessage({
          id: 'RequestHome.Dialog.Title.Select.Language',
        })}
      </h4>
      <List>
        {languageSupport.map((langObj) => (
          <ListItem
            className={classes.list}
            button
            onClick={() => changeLanguage(langObj.code)}
          >
            {intl.formatMessage({
              id: `RequestHome.Dialog.Language.Option.${langObj.value}`,
            })}
          </ListItem>
        ))}
      </List>
    </div>
  );
};

LanguageList.propTypes = {
  onClose: PropTypes.func.isRequired,
  intl: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string.isRequired,
      formatMessage: PropTypes.func.isRequired,
    }),
  ).isRequired,
};

export default injectIntl(LanguageList);
