export const GET_MESSAGE_LABELS = 'GET_MESSAGE_LABELS';
export const GET_MESSAGE_LABELS_SUCCESS = 'GET_MESSAGE_LABELS_SUCCESS';

export const EDIT_MESSAGE_PROP = 'EDIT_MESSAGE_PROP';
export const SAVE_MESSAGE_PROP = 'SAVE_MESSAGE_PROP';
export const SAVE_MESSAGE_PROP_SUCCESS = 'SAVE_MESSAGE_PROP_SUCCESS';
export const CANCEL_EDIT_MSG_OP = 'CANCEL_EDIT_MSG_OP';

export const REVERT_EDIT_MSG_PROP = 'REVERT_EDIT_MSG_PROP'

export const SET_SELECTED_LANGUAGE = 'SET_SELECTED_LANGUAGE'

export const SET_ALL_LANG_SELECTED = 'SET_ALL_LANG_SELECTED'
export const SET_ALL_LANG_UNSELECTED = 'SET_ALL_LANG_UNSELECTED'


export const REMOVE_LANGUAGE_SELECTION = 'REMOVE_LANGUAGE_SELECTION'
export const CLEAR_REMOVE_LANGUAGE_SELECTION = 'CLEAR_REMOVE_LANGUAGE_SELECTION'

export const REMOVE_EDITED_LANGUAGE_LABELS = 'REMOVE_EDITED_LANGUAGE_LABELS';

export const getMessageLabels = (reqBodyOptions) => ({
  type: GET_MESSAGE_LABELS,
  payload: { reqBodyOptions }
});

export const getMessageLabelsSuccess = response => ({
  type: GET_MESSAGE_LABELS_SUCCESS,
  payload: response,
});

export const updateMessageLabels = updatedMessageLabels => ({
  type:SAVE_MESSAGE_PROP,
  payload:updatedMessageLabels
})

export const editMessageProp = (msgObject) => ({
  type: EDIT_MESSAGE_PROP,
  ...msgObject
})

export const saveMessagePropSuccess = () => ({
  type: SAVE_MESSAGE_PROP_SUCCESS
})

export const cancelEditMessagePropOp = () => ({
  type: CANCEL_EDIT_MSG_OP
})

export const revertEditMsgProp = (msgObject) => ({
  type: REVERT_EDIT_MSG_PROP,
  ...msgObject
})

export const setSelectededLanguage = (langCode, selected) => ({
  type: SET_SELECTED_LANGUAGE,
  langCode,
  selected
})

export const setAllLangSelected = () => ({
  type: SET_ALL_LANG_SELECTED
})

export const setAllLangUnSelected = () => ({
  type: SET_ALL_LANG_UNSELECTED
})

export const removeLangSelection = (langCode) => ({
  type: REMOVE_LANGUAGE_SELECTION,
  langCode
})

export const clearRemoveLangSelection = () => ({
  type: CLEAR_REMOVE_LANGUAGE_SELECTION
})

export const removeEditedLangLabels = (langCode) => ({
  type: REMOVE_EDITED_LANGUAGE_LABELS,
  langCode
})