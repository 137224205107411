/* eslint-disable no-nested-ternary */
import React, { useState, useRef, useEffect } from 'react';
import { Cookies } from 'react-cookie';
import moment from 'moment';
import PropTypes from 'prop-types';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { injectIntl } from 'react-intl';
import { checkEnvironment, getDisplayName, getStatusClass } from 'ui-common/src/lib/utils';
import { EllipsedLabel } from '../EllipsedLabel';
import FileUpload from '../FileUpload';
import { A } from '../A';
import { Icon } from '../Icon';
import styles from './RequestStatusTimeline.module.scss';
import { divide } from 'lodash';
import momenttimezone from 'moment-timezone';

const cookies = new Cookies();
const RequestStatusTimeline = ({
  request,
  approvals,
  businessJustification,
  tasks,
  statusIndicator,
  commentsRequired,
  addComment,
  entitlement,
  dummyViews,
  fileList,
  setFileList,
  selected,
  setComments,
  intl,
  setInvalidInput,
  setCommentsModal,
  commentAnchor,
  closeCommentsModal,
  erpRole
}) => {
  const commentRef = useRef(null);
  const SERVICE_ENDPOINT = checkEnvironment().ARSMS_API_URL;
  const TASKTYPE_ADD = "tasktype.add";
  const TASKTYPE_DEL = "tasktype.delete";
  const TASKTYPE_NEWACCOUNT = "tasktype.newAccount";
  const TASKTYPE_NEWROLEREQUEST = "tasktype.newrolerequest";
  const TASKTYPE_DELETEACCOUNT = "tasktype.deleteAccount";

  const TASKSTATUS_COMPLETE = "taskstatus.complete";
  const TASKSTATUS_DISCONTINUE = "taskstatus.discontinue";
  const TASKSTATUS_PROVFAILED = "taskstatus.provFailed";
  const TASKSTATUS_ERROR = "taskstatus.error";
  // const [showComments, setShowComments] = useState(false);
  const getTaskStatusClass = (task, isLast) => {
    if (task.statusDescription === TASKSTATUS_DISCONTINUE ||
      task.statusDescription === TASKSTATUS_PROVFAILED || task.statusDescription === TASKSTATUS_ERROR)
      return 'taskReject';
    if (isLast && task.statusDescription === TASKSTATUS_COMPLETE) {
      if (task.taskTypeDescription === TASKTYPE_ADD || task.taskTypeDescription === TASKTYPE_NEWACCOUNT || task.taskTypeDescription === TASKTYPE_NEWROLEREQUEST) {
        return 'taskGranted';
      }
      if (task.taskTypeDescription === TASKTYPE_DEL || task.taskTypeDescription === TASKTYPE_DELETEACCOUNT)
        return 'taskRevoked';
    }
    return 'taskPending';
  }

  const getUserLabelModify = userName => {
    if (request.requestedBy && userName === request.requestedBy.userName) return 'Requestor';
    let items = [];
    if (request.accounts && request.accounts.length > 0) {
      items = request.accounts[0].approvals ? request.accounts[0].approvals :
        request.accounts[0].entitlements && request.accounts[0].entitlements.length > 0 &&
          request.accounts[0].entitlements[0].approvals ? request.accounts[0].entitlements[0].approvals :
          request.accounts[0].applicationRole && request.accounts[0].applicationRole.length > 0 &&
            request.accounts[0].applicationRole[0].approvals ? request.accounts[0].applicationRole[0].approvals : [];
    } else if (request.roles && request.roles.length > 0) {
      items = request.roles[0].approvals;
    }
    else if (request.endpoints && request.endpoints.length > 0) {
      items = request.endpoints[0].approvals;
    }
    if (items && items.length > 0) {
      const assignees = items[0].assignee;
      const isAssignee = assignees.filter(a => a.userName === userName);
      if (isAssignee.length > 0) return 'Assignee';
    }
    return '';
  };

  const getUserLabel = userName => {
    if (userName === cookies.get('user_name')) return 'You';
    if (userName === request.requestedBy.userName) return 'Requestor';
    if (request.accounts) {
      const entitlements = [].concat(...request.accounts.map(a => a.entitlements || a.roles));
      const assignees = [].concat(...entitlements.map(a => a.approvals)).map(a => a.assignee);
      const isAssignee = assignees.reduce((isAssignee, a) => {
        if (isAssignee) return true;
        return a.userName === userName;
      }, false);
      if (isAssignee) return 'Assignee';
    }
    return userName;
  };



  const removeTimeDetailsFromComments = comments => {
    return comments.indexOf(']') > -1 ? comments.substring(comments.indexOf(']') + 2, comments.length) : comments;
  };
  const getTaskOwners = (task) => {
    let taskowners = '';
    if (task.taskOwners && task.taskOwners.owners) {
      taskowners = task.taskOwners.owners.length === 1 ? task.taskOwners.owners[0].displayName
        :
        task.taskOwners.owners.reduce((owners, curr) => {
          return owners.displayName + ', ' + curr.displayName;
        });
    }
    return taskowners;
  }

  if (statusIndicator === 'dot')
    return (
      <div className={`${styles.reqStatus}   d-flex mt-2`}>
        <div>
          <div className={`${styles.expandBar} ${styles.expandArrow} d-flex`}>
            <span className={styles.dotColor}> </span>
            <div className="d-flex flex-column pl-3">
              <h6 className={`${styles.reqStatus} mb-0 ${styles.requested}`}>
                {intl.formatMessage({ id: 'Request.Timeline.Requested' })}
              </h6>
              <i>
                {intl.formatMessage({ id: 'Request.Timeline.by' })}  < span className={styles.assigneeName}> {getDisplayName(request.requestor)}</span>
                {' '}{intl.formatMessage({ id: 'Request.Timeline.on' })}{' '}
                <span className={styles.statusspanColor}>
                  {moment(moment.utc(request.requestedDate).local(), 'YYYY-MM-DD HH:mm:ss').format(
                    'MMM DD YYYY HH:mm:ss'
                  )} {` (${momenttimezone.tz(momenttimezone.tz.guess()).format("z")})`}
                  {businessJustification && ` "${erpRole ? businessJustification:removeTimeDetailsFromComments(businessJustification)}"`}
                </span>
              </i>
            </div>
          </div>

          {approvals &&
            approvals.map((approval, index) => {
              let assigneeName = '';
              if (approval.assignee && Array.isArray(approval.assignee)) {
                approval.assignee.forEach((assignee, inx) => {
                  assigneeName += `${assignee.displayName}`;
                  if (inx !== approval.assignee.length - 1) {
                    assigneeName += ', ';
                  }
                });
              } else if (approval.assignee) {
                assigneeName = `${approval.assignee.firstName}(${approval.assignee.userName})`;
              }
              return (
                <>
                  <div className={`${styles.expandBar} ${styles.expandArrow} d-flex mt-3`}>
                    <div className={styles.statusColors}>
                      <span
                        className={`${styles.dotColor} ${styles[getStatusClass(approval.statusDescription)]} mt-1`}
                      >
                        {' '}
                      </span>
                    </div>
                    <div className={`d-flex flex-column  pl-3  `}>
                      <div>
                        <span
                          className={`${styles[getStatusClass(approval.statusDescription, true)]} ${styles.statusFontWeight
                            } ${approval.statusDescription !== 'REQUEST.ACCESS.STATUS.APPROVED' ? styles.finalstatus : ''}`}
                        >
                          {' '}
                          {approval.statusDescription ? `${approval.statusDescription !== 'REQUEST.ACCESS.STATUS.APPROVED' ? 'Request' : ''} ${intl.formatMessage({ id: approval.statusDescription })}` : ''}
                        </span>
                      </div>
                      <i className={styles.reqStatus}>
                        <span>{intl.formatMessage({ id: 'Request.Timeline.ApprovalType' })}: </span>
                        <span
                          className={`${styles.reqStatus} mb-0`}
                        >{`${approval.approvalType} ${approval.approvalType === 'AutoApprove'
                          ? ''
                          : intl.formatMessage({ id: 'RequestApprovals.Approval' })
                          } `}</span>
                        <span style={{ fontSize: 15 }}> . </span>
                        by  <span className={styles.assigneeName}>{assigneeName}</span> {approval.approvalDate
                          ? 'on' : ''} {' '}
                        <span className={styles.statusspanColor}>
                          {approval.approvalDate
                            ? `${moment(
                              moment.utc(approval.approvalDate).local(),
                              'YYYY-MM-DD HH:mm:ss'
                            ).format('MMM DD YYYY HH:mm:ss')} (${momenttimezone.tz(momenttimezone.tz.guess()).format("z")})`
                            : ''}
                        </span>{' '}
                        <i className={`${styles.reqStatus} ${styles.reqBusinessJusti}`}>
                          {approval.businessJustification && `"${approval.businessJustification}"`}
                        </i>
                      </i>
                    </div>
                  </div>
                </>
              );
            })}
          {tasks &&
            tasks.map((task, index) => {
              const taskowners = getTaskOwners(task);
              return (
                <div className={`${styles.expandBar} d-flex mt-3 ${(task.statusDescription === TASKSTATUS_DISCONTINUE ||
                  task.statusDescription === TASKSTATUS_PROVFAILED || task.statusDescription === TASKSTATUS_ERROR) ? styles.expandArrowFail : styles.expandArrow}`}>
                  <span className={`${styles.dotColor} ${(task.statusDescription === TASKSTATUS_DISCONTINUE ||
                    task.statusDescription === TASKSTATUS_PROVFAILED || task.statusDescription === TASKSTATUS_ERROR) ? styles.reqFailBg :
                    index === tasks.length - 1 && task.statusDescription === TASKSTATUS_COMPLETE ? styles.reqApproveBg : ''}`}> </span>
                  <div className="d-flex flex-column pl-3">
                    <div>
                      <span className={`${styles.reqStatus} ${index === tasks.length - 1 ? styles.lastTaskStyle : ''} 
                        ${styles[getTaskStatusClass(task, index === tasks.length - 1)]} mb-0 h6`}>
                        {index === tasks.length - 1 ? task.statusDescription === TASKSTATUS_COMPLETE
                          ? task.taskTypeDescription === TASKTYPE_ADD || task.taskTypeDescription === TASKTYPE_NEWACCOUNT || task.taskTypeDescription === TASKTYPE_NEWROLEREQUEST
                            ? intl.formatMessage({
                              id: 'RequestStatusTimeline.TaskTypeLabel.AccessGranted',
                            }).toLowerCase()
                            : (task.taskTypeDescription === TASKTYPE_DEL || task.taskTypeDescription === TASKTYPE_DELETEACCOUNT) ? intl.formatMessage({
                              id: 'RequestStatusTimeline.TaskTypeLabel.AccessRevoke',
                            }) : intl.formatMessage({
                              id: '' + task.statusDescription,
                            })
                          : intl.formatMessage({
                            id: '' + task.statusDescription,
                          }) : intl.formatMessage({
                            id: '' + task.statusDescription,
                          })}
                      </span>
                    </div>
                    <i className={styles.reqStatus}>
                      {`Task ID: ${task.taskId} ,`} Task Type:{' '}
                      {intl.formatMessage({
                        id: '' + task.taskTypeDescription,
                      })}
                      {
                        taskowners !== '' ? (<span>
                          {`, ${intl.formatMessage({
                            id: 'Task.TaskOwner',
                          })} : ${' '} ${taskowners}  `}
                        </span>) : ''
                      }
                      <span className={styles.createDateText}>
                        {' '} . {task.creationDate
                          ? `${intl.formatMessage({
                            id: 'Task.CreatedOn.label',
                          })} ${moment(
                            moment.utc(task.creationDate).local(),
                            'YYYY-MM-DD HH:mm:ss'
                          ).format('MMM DD YYYY HH:mm:ss')} (${momenttimezone.tz(momenttimezone.tz.guess()).format("z")})`
                          : ''} {task.statusDescription === TASKSTATUS_COMPLETE && task.updateDate ?
                            ` . ${intl.formatMessage({
                              id: 'Task.CompleteOn.label',
                            })} ${moment(
                              moment.utc(task.updateDate).local(),
                              'YYYY-MM-DD HH:mm:ss'
                            ).format('MMM DD YYYY HH:mm:ss')} (${momenttimezone.tz(momenttimezone.tz.guess()).format("z")})`
                            : ''}
                      </span>
                    </i>
                    <div>
                      {task.workNotes && (
                        <div>
                          <span className={styles.workNotesStatus}>{intl.formatMessage({ id: 'Request.Timeline.SnowTicket.Comment' })}: {task.workNotes}</span>
                        </div>)}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div >
    );

  if (statusIndicator === 'det')
    return (
      <div className={`${styles.reqStatus}   d-flex mt-2`}>
        <div className={styles.cardExpandLayout}>
          <div className={`${styles.expandBar} ${styles.expandArrow} ${styles.det} d-flex`}>
            <span className={styles.dateColorText}>
              {`${moment(moment.utc(request.requestedDate).local(), 'YYYY-MM-DD HH:mm:ss').format(
                'MMM DD'
              )}`}
            </span>
            <div className="d-flex flex-column pl-3">
              <h6 className={`${styles.reqStatus} mb-0`}>
                {' '}
                {intl.formatMessage({ id: 'Request.Timeline.Requested' })}{' '}
                <span className={styles.reqbyheading}>{` by ${getDisplayName(request.requestor)}`}</span>
              </h6>
              {businessJustification && <div className={styles.statusspanColor}>
                {` "${removeTimeDetailsFromComments(businessJustification)}"`}
              </div>}
              <i>
                <span className={styles.statusspanColorDate}> a</span>
              </i>
            </div>
          </div>

          {approvals.map((approval, index) => {
            let assigneeName = '';
            if (approval.assignee && Array.isArray(approval.assignee)) {
              approval.assignee.forEach((assignee, inx) => {
                assigneeName += `${assignee.displayName}`;
                if (inx !== approval.assignee.length - 1) {
                  assigneeName += ', ';
                }
              });
            } else if (
              approval.assignee &&
              approval.assignee.userName === cookies.get('user_name')
            ) {
              assigneeName = 'you';
            } else if (approval.assignee) {
              assigneeName = approval.assignee.firstName;
            }
            const isLastNode = index === approvals.length - 1;
            const key = `${isLastNode}-${selected}`; // this is to avoid nested terneries
            const obj = {
              'true-true': (
                <EditOutlinedIcon
                  fontSize="small"
                  name="comments"
                  size="small"
                  className="ml-2 mt-1"
                  onClick={e => {
                    e.stopPropagation();
                    if (commentAnchor)
                      closeCommentsModal();
                    else
                      setCommentsModal(e);
                  }}
                />
              ),
              'true-false': 'due by',
              'false-true': !approval.approvalDate
                ? 'due by'
                : moment(moment.utc(approval.approvalDate).local(), 'YYYY-MM-DD HH:mm:ss').format(
                  'MMM DD'
                ),
              'false-false': !approval.approvalDate
                ? 'due by'
                : moment(moment.utc(approval.approvalDate).local(), 'YYYY-MM-DD HH:mm:ss').format(
                  'MMM DD'
                ),
            };
            if (!commentAnchor || !isLastNode)
              return (
                <div
                  className={`${styles.expandBar} ${styles.expandArrow} ${styles.det} ${isLastNode ? styles.ready : ''
                    } d-flex mt-2`}
                >
                  <span className={`${key === 'true-false' ? 'mb-1' : ''} ${styles.dateColorText}`}>
                    {obj[key]}
                  </span>
                  <div className="d-flex flex-column pl-3">
                    <div>
                      {/* <span className={`${styles.reqStatus} mb-0 h6`}>{approval.approvalType}</span>
                  <span> {approvalLabels[approval.status]}</span> */}
                      <span className={`${styles.reqStatus}`}>
                        {approval.statusDescription ? intl.formatMessage({ id: approval.statusDescription }) : ''},{' '}
                        <span className={`${styles.reqbyheading}`}>by {assigneeName} </span>
                      </span>
                    </div>
                    {/* {!isLastNode && !approval.businessJustification && (
                      <i className={styles.statusspanColorDate}>a</i>
                    )} */}
                    <span className={styles.statusspanColorDateShown}>
                      {intl.formatMessage({ id: 'Request.Timeline.ApprovalType' })}: {approval.approvalType}
                      {` ${intl.formatMessage({ id: 'RequestApprovals.Approval' })}`}
                      {isLastNode ? entitlement.comment || approval.businessJustification ? ` ,"${entitlement.comment || approval.businessJustification}"` : '' : ''}
                    </span>
                  </div>
                </div>
              );
            return (
              <div
                className={`${styles.expandBar} ${styles.expandArrow} ${styles.editIcon} ${styles.det
                  } ${isLastNode ? styles.ready : ''} d-flex mt-2`}
              >
                <span className={`${key === 'true-false' ? 'mb-1' : ''} ${styles.dateColorText}`}>
                  {obj[key]}
                </span>
              </div>
            );
          })}
          {dummyViews > 0
            ? new Array(dummyViews).fill('1').map(() => <div className={styles.dummyview}> </div>)
            : null}
        </div>
      </div>
    );

  if (statusIndicator === 'comments') {
    return (
      <div className={`${styles.reqStatus} ${styles.morecommentmodal} d-flex  flex-column`}>
        {request.comments &&
          request.comments.map(c => {
            let files = [];
            let displayname = c.commentor && c.commentor.userName ? c.commentor.userName : '';
            if (c.commentor && c.commentor.firstName) {
              displayname = `${getDisplayName(c.commentor)}`;
            }
            return (
              <div className={styles.commentindlayout}>
                <div className={`${styles.approvalIcon}`}>
                  <div>
                    {displayname && <i className={styles.iconSize}>{displayname.slice(0, 1).toUpperCase()}</i>}
                  </div>
                </div>
                <div className="d-flex flex-column pl-3 w-100">
                  <div className={styles.filelayout}>
                    <h6 className={`${styles.commentsAttachedText} ${styles.displayname}`}>
                      {displayname}
                    </h6>
                    <div className={`${styles.commentsAttachedText} ${styles.date}`}>{c.date ?
                      moment(moment.utc(c.date).local()).format(c.date.length > 10 ? 'MMM DD YYYY HH:mm:ss' : 'MMM DD YYYY') : ''}</div>
                  </div>

                  {c.commentor && <span className={`${styles.commentsAttachedText} ${styles.requestor}`}>
                    {getUserLabelModify(c.commentor.userName)}</span>}
                  {c.comment && <span className={styles.commentsText}>{`"${c.comment}"`}</span>}
                </div>
              </div>
            );
          })}

        {request.attachments && request.attachments.length > 0 && <span className={styles.attachtitle}>{`${intl.formatMessage({ id: 'Comments.files.title' })} (${request.attachments.length})`}</span>}
        {request.attachments && request.attachments.length > 0 &&
          < div className={styles.attachmentLayout}>
            {request.attachments.map(file => {
              return (
                <a
                  rel="noopener noreferrer"
                  href={`${SERVICE_ENDPOINT}/requests/attachment/${file.attachmentKey
                    }?username=${cookies.get('user_name')}`}
                  target="_blank"
                >
                  <div className={`${styles.attachedFile} d-flex justify-content-between  flex-column`}>
                    <Icon name="attachedFile" fileType={file.fileType} />
                    <div className={`d-flex flex-column ${styles.attachedText}`}>
                      <span className="d-flex flex-start">
                        {' '}
                        <EllipsedLabel label={file.fileName} maxLength={20} />
                      </span>
                      <span className="d-flex flex-start">{file.size}</span>
                    </div>
                  </div>
                </a>
              );
            })}
          </div>}
      </div >
    );
  }
  return <error>statusIndicator should have a value</error>;
};
RequestStatusTimeline.propTypes = {
  dummyViews: PropTypes.number,
  fileList: PropTypes.arrayOf(
    PropTypes.shape({
      requestComment: PropTypes.string,
    })
  ),
  request: PropTypes.shape({
    requestComment: PropTypes.string,
    attachments: PropTypes.arrayOf({ comment: PropTypes.string }),
    requestedDate: PropTypes.string,
    requestedBy: PropTypes.shape({
      userName: PropTypes.string,
      firstName: PropTypes.string,
    }),
    accounts: PropTypes.arrayOf({ entitlements: PropTypes.arrayOf({}) }),
    requestor: PropTypes.shape({
      userName: PropTypes.string,
      firstName: PropTypes.string,

      lastName: PropTypes.string,
    }),
    comments: PropTypes.arrayOf({ comment: PropTypes.string }),
  }),
  approvals: PropTypes.arrayOf({ approvalDate: PropTypes.string }),
  businessJustification: PropTypes.string,
  tasks: PropTypes.arrayOf({ taskId: PropTypes.number }),
  statusIndicator: PropTypes.string.isRequired,
  commentsRequired: PropTypes.bool,
  addComment: PropTypes.func,
  entitlement: PropTypes.shape({ entitlementId: PropTypes.string, comment: PropTypes.string }),
  setFileList: PropTypes.func,
  selected: PropTypes.bool,
  setComments: PropTypes.func,
  intl: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string.isRequired,
      formatMessage: PropTypes.func.isRequired,
    })
  ).isRequired,
};
RequestStatusTimeline.defaultProps = {
  setFileList: null,
  dummyViews: 0,
  fileList: [],
  request: null,
  approvals: null,
  businessJustification: null,
  tasks: null,
  commentsRequired: null,
  addComment: null,
  entitlement: null,
  selected: false,
  setComments: null,
};
export default injectIntl(RequestStatusTimeline);
