import './InterPoint.css';

import PropTypes from 'prop-types';
import React from 'react';

const COLORS = {
  neutralBold: 'neutralBold',
  neutralSubtle: 'neutralSubtle',
  neutralSubtlest: 'neutralSubtlest',
  criticalBold: 'criticalBold',
};

const FONTS = {
  body2: 'body2',
  body3: 'body3',
};

function InterPoint({ color, font }) {
  return (
    <p className={`InterPoint InterPoint-${color} InterPoint-${font}`}>•</p>
  );
}

InterPoint.propTypes = {
  color: PropTypes.oneOf(Object.values(COLORS)),
  font: PropTypes.oneOf(Object.values(FONTS)),
};

InterPoint.defaultProps = {
  color: COLORS.neutralBold,
  font: FONTS.body3,
};

export default InterPoint;
