import React, { type JSX, useEffect, useMemo } from 'react';
import { Icon, Loader, Typography } from '@saviynt/design-system';
import classNames from 'classnames';

import {
  type MenuId,
  type MenuIdsResponse,
  RequestStatus,
} from '../../../models';
import {
  type AccessibleMenuLink,
  findAccessibleMenuLinks,
  type MenuLink,
  type SideMenuNode,
  useCookies,
  useInvertedLocalize,
} from '../../../utilities';
import {
  type KpiState,
  type RootState,
  thunks,
  useDispatch,
  useSelector,
} from '../store';

import KpiTile from './KpiTile';
import { invertedMessages } from './messages';

import './KpisWidget.css';

interface SelectedState {
  defaultKpiIds?: MenuIdsResponse;
  defaultKpisStatus: RequestStatus;
  sideMenu: SideMenuNode[] | null;
  menuList: MenuLink[] | undefined;
  kpis: Record<MenuId, KpiState | undefined>;
  kpiConfiguration: 'unauthorized' | string[] | undefined;
}

export interface KpisWidgetProps {
  className?: string;
}

KpisWidget.defaultProps = {
  className: undefined,
};

type Dispatch = ReturnType<typeof useDispatch>;

export default function KpisWidget({
  className,
}: KpisWidgetProps): JSX.Element | null {
  const l = useInvertedLocalize(invertedMessages);
  const [{ user_name: username, userKey }] = useCookies([
    'user_name',
    'userKey',
  ]);
  const dispatch = useDispatch();
  const {
    defaultKpiIds,
    defaultKpisStatus,
    sideMenu,
    menuList,
    kpis,
    kpiConfiguration,
  } = useSelector(selectState);
  const accessibleKpis = useMemo(
    () =>
      findAccessibleMenuLinks({
        userKey,
        sideMenu,
        menuList,
        menuIds: defaultKpiIds,
      }),
    [defaultKpiIds, sideMenu, menuList, userKey]
  );

  useEffect(
    () =>
      dispatchDataRequests(
        dispatch,
        defaultKpiIds,
        accessibleKpis,
        username,
        kpis,
        kpiConfiguration
      ),
    [dispatch, defaultKpiIds, username, accessibleKpis, kpis, kpiConfiguration]
  );

  if (defaultKpiIds === 'error') {
    const errorClassName = classNames(
      'Nav_KpisWidget',
      'Nav_KpisWidget--error',
      className
    );

    return (
      <div className={errorClassName}>
        <div className='Nav_KpisWidget-errorIconContainer'>
          <Icon
            kind='AlertCriticalOutline'
            className='Nav_KpisWidget-errorIconContainer-errorIcon'
            color='critical-700'
            size='large'
          />
        </div>
        <Typography kind='body1' className='Nav_KpisWidget-errorMessage'>
          {l("We're having trouble loading your Daily Summary at this time.")}
        </Typography>
      </div>
    );
  }

  if (defaultKpisStatus !== RequestStatus.Idle || !accessibleKpis) {
    const loadingClassName = classNames('Nav_KpisWidget', className);

    return (
      <div className={loadingClassName}>
        {renderHeading(l('Daily Summary'))}
        <div className='Nav_KpisWidget-loading'>
          <Loader kind='dots' format='inline' color='brand' size='small' />
        </div>
      </div>
    );
  }

  const resultsClassName = classNames('Nav_KpisWidget', className);

  return accessibleKpis.length === 0 ? null : (
    <div className={resultsClassName}>
      {renderHeading(l('Daily Summary'))}

      <div className='Nav_KpisWidget-tiles'>
        {accessibleKpis.map(({ link, id }) => {
          const kpiData = kpis[id];

          return (
            <KpiTile
              key={id}
              id={id}
              link={link}
              status={kpiData?.status}
              metric={kpiData?.metric}
              className='Nav_KpisWidget-tiles-tile'
            />
          );
        })}
      </div>
    </div>
  );
}

function selectState(state: RootState): SelectedState {
  const {
    kpis: { defaultKpiIds, defaultKpisStatus, kpis },
    user: {
      homepage: { kpis: kpiConfiguration },
    },
    app: { sideMenu },
    profile: { menuList },
  } = state;

  return {
    defaultKpisStatus,
    defaultKpiIds,
    sideMenu,
    menuList,
    kpis,
    kpiConfiguration,
  };
}

function dispatchDataRequests(
  dispatch: Dispatch,
  defaultKpiIds: MenuIdsResponse,
  accessibleKpis: AccessibleMenuLink[] | null,
  username: string,
  kpis: Record<MenuId, KpiState | undefined>,
  kpiConfiguration: 'unauthorized' | string[] | undefined
) {
  if (defaultKpiIds === undefined && kpiConfiguration !== undefined) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    dispatch(thunks.requestKpis(kpiConfiguration));

    return;
  }

  if (accessibleKpis !== null) {
    accessibleKpis.forEach(({ id }) => {
      const kpiData = kpis[id];

      if (kpiData !== undefined) {
        // We already have a result from a previous call.
        return;
      }

      dispatch(thunks.requestKpi(id, username));
    });
  }
}

function renderHeading(text: string) {
  return (
    <Typography kind='h2' className='Nav_KpisWidget-heading'>
      {text}
    </Typography>
  );
}
