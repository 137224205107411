import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import MenuItem from '../MenuItem/MenuItem';

export const PasswordIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M6.92163 13.2409C6.92163 13.3102 6.94763 13.3622 6.99963 13.3969L7.50229 13.7695C7.53696 13.7869 7.57451 13.7955 7.61496 13.7955C7.69007 13.7955 7.74207 13.7724 7.77096 13.7262C7.78829 13.7089 7.80274 13.6886 7.81429 13.6655L8.13496 12.9289L8.45563 13.6655L8.49029 13.7262C8.51918 13.7724 8.57118 13.7955 8.64629 13.7955C8.68674 13.7955 8.72429 13.7869 8.75896 13.7695L9.26163 13.3969C9.31363 13.3622 9.33963 13.3102 9.33963 13.2409C9.33963 13.2062 9.32807 13.1715 9.30496 13.1369C9.30496 13.1311 9.28763 13.1109 9.25296 13.0762L8.65496 12.5562L9.45229 12.4782L9.52163 12.4609C9.55629 12.4493 9.58518 12.4262 9.60829 12.3915C9.63718 12.3511 9.65163 12.3106 9.65163 12.2702L9.64296 12.2182L9.44363 11.6202C9.43785 11.5798 9.41474 11.548 9.37429 11.5249C9.33963 11.5018 9.29918 11.4902 9.25296 11.4902C9.23563 11.4902 9.22118 11.4931 9.20963 11.4989L9.14029 11.5335L8.44696 11.9409L8.62896 11.1609L8.63763 11.0915C8.63763 11.0453 8.6174 11.002 8.57696 10.9615C8.54229 10.9211 8.49896 10.9009 8.44696 10.9009H7.81429C7.76229 10.9009 7.71607 10.9211 7.67563 10.9615C7.64096 11.002 7.62363 11.0453 7.62363 11.0915L7.63229 11.1609L7.80563 11.9322L7.12096 11.5335C7.09207 11.5104 7.06896 11.4989 7.05163 11.4989C7.03429 11.4931 7.01985 11.4902 7.00829 11.4902C6.97363 11.4902 6.94185 11.4989 6.91296 11.5162C6.86096 11.5393 6.82918 11.574 6.81763 11.6202L6.61829 12.2182C6.61251 12.2355 6.60963 12.2529 6.60963 12.2702C6.60963 12.3049 6.61829 12.3366 6.63563 12.3655C6.65874 12.4175 6.6934 12.4493 6.73963 12.4609L6.80896 12.4782L7.59763 12.5562L7.00829 13.0762C6.97363 13.1109 6.95629 13.1311 6.95629 13.1369C6.93318 13.1715 6.92163 13.2062 6.92163 13.2409Z"
      fill="#00245B"
    />
    <path
      d="M10.781 13.2409C10.781 13.3102 10.807 13.3622 10.859 13.3969L11.3617 13.7695C11.3963 13.7869 11.4339 13.7955 11.4743 13.7955C11.5494 13.7955 11.6014 13.7724 11.6303 13.7262C11.6477 13.7089 11.6621 13.6886 11.6737 13.6655L11.9943 12.9289L12.315 13.6655L12.3497 13.7262C12.3786 13.7724 12.4306 13.7955 12.5057 13.7955C12.5461 13.7955 12.5837 13.7869 12.6183 13.7695L13.121 13.3969C13.173 13.3622 13.199 13.3102 13.199 13.2409C13.199 13.2062 13.1874 13.1715 13.1643 13.1369C13.1643 13.1311 13.147 13.1109 13.1123 13.0762L12.5143 12.5562L13.3117 12.4782L13.381 12.4609C13.4157 12.4493 13.4446 12.4262 13.4677 12.3915C13.4966 12.3511 13.511 12.3106 13.511 12.2702L13.5023 12.2182L13.303 11.6202C13.2972 11.5798 13.2741 11.548 13.2337 11.5249C13.199 11.5018 13.1586 11.4902 13.1123 11.4902C13.095 11.4902 13.0806 11.4931 13.069 11.4989L12.9997 11.5335L12.3063 11.9409L12.4883 11.1609L12.497 11.0915C12.497 11.0453 12.4768 11.002 12.4363 10.9615C12.4017 10.9211 12.3583 10.9009 12.3063 10.9009H11.6737C11.6217 10.9009 11.5754 10.9211 11.535 10.9615C11.5003 11.002 11.483 11.0453 11.483 11.0915L11.4917 11.1609L11.665 11.9322L10.9803 11.5335C10.9514 11.5104 10.9283 11.4989 10.911 11.4989C10.8937 11.4931 10.8792 11.4902 10.8677 11.4902C10.833 11.4902 10.8012 11.4989 10.7723 11.5162C10.7203 11.5393 10.6886 11.574 10.677 11.6202L10.4777 12.2182C10.4719 12.2355 10.469 12.2529 10.469 12.2702C10.469 12.3049 10.4777 12.3366 10.495 12.3655C10.5181 12.4175 10.5528 12.4493 10.599 12.4609L10.6683 12.4782L11.457 12.5562L10.8677 13.0762C10.833 13.1109 10.8157 13.1311 10.8157 13.1369C10.7926 13.1715 10.781 13.2062 10.781 13.2409Z"
      fill="#00245B"
    />
    <path
      d="M14.6404 13.2409C14.6404 13.3102 14.6664 13.3622 14.7184 13.3969L15.221 13.7695C15.2557 13.7869 15.2933 13.7955 15.3337 13.7955C15.4088 13.7955 15.4608 13.7724 15.4897 13.7262C15.507 13.7089 15.5215 13.6886 15.533 13.6655L15.8537 12.9289L16.1744 13.6655L16.209 13.7262C16.2379 13.7724 16.2899 13.7955 16.365 13.7955C16.4055 13.7955 16.443 13.7869 16.4777 13.7695L16.9804 13.3969C17.0324 13.3622 17.0584 13.3102 17.0584 13.2409C17.0584 13.2062 17.0468 13.1715 17.0237 13.1369C17.0237 13.1311 17.0064 13.1109 16.9717 13.0762L16.3737 12.5562L17.171 12.4782L17.2404 12.4609C17.275 12.4493 17.3039 12.4262 17.327 12.3915C17.3559 12.3511 17.3704 12.3106 17.3704 12.2702L17.3617 12.2182L17.1624 11.6202C17.1566 11.5798 17.1335 11.548 17.093 11.5249C17.0584 11.5018 17.0179 11.4902 16.9717 11.4902C16.9544 11.4902 16.9399 11.4931 16.9284 11.4989L16.859 11.5335L16.1657 11.9409L16.3477 11.1609L16.3564 11.0915C16.3564 11.0453 16.3362 11.002 16.2957 10.9615C16.261 10.9211 16.2177 10.9009 16.1657 10.9009H15.533C15.481 10.9009 15.4348 10.9211 15.3944 10.9615C15.3597 11.002 15.3424 11.0453 15.3424 11.0915L15.351 11.1609L15.5244 11.9322L14.8397 11.5335C14.8108 11.5104 14.7877 11.4989 14.7704 11.4989C14.753 11.4931 14.7386 11.4902 14.727 11.4902C14.6924 11.4902 14.6606 11.4989 14.6317 11.5162C14.5797 11.5393 14.5479 11.574 14.5364 11.6202L14.337 12.2182C14.3313 12.2355 14.3284 12.2529 14.3284 12.2702C14.3284 12.3049 14.337 12.3366 14.3544 12.3655C14.3775 12.4175 14.4122 12.4493 14.4584 12.4609L14.5277 12.4782L15.3164 12.5562L14.727 13.0762C14.6924 13.1109 14.675 13.1311 14.675 13.1369C14.6519 13.1715 14.6404 13.2062 14.6404 13.2409Z"
      fill="#00245B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.3003 3.10022C12.1224 2.96659 11.8776 2.96659 11.6997 3.10022L9.98289 4.38978C8.63419 5.40285 6.92936 5.81922 5.26551 5.54191L4.0822 5.34469C3.93722 5.32053 3.78896 5.36138 3.67681 5.45638C3.56467 5.55138 3.5 5.69091 3.5 5.83789V12.1721C3.5 15.0933 5.07384 17.3054 6.88241 18.768C7.78769 19.5001 8.76226 20.0541 9.65377 20.4264C10.5359 20.7947 11.3727 21 12 21C12.6282 21 13.4655 20.7923 14.3473 20.4215C15.2388 20.0468 16.2131 19.4899 17.1181 18.756C18.9256 17.2903 20.5 15.0784 20.5 12.1721V5.83794C20.5 5.69096 20.4353 5.55138 20.3232 5.45638C20.211 5.36138 20.0628 5.32053 19.9178 5.34469L18.7345 5.54191C17.0706 5.81922 15.3658 5.40285 14.0171 4.38978L12.3003 3.10022ZM4.5 6.42812L5.10111 6.5283C7.03478 6.85058 9.01606 6.36669 10.5835 5.18935L12 4.12534L13.4165 5.18935C14.9839 6.36669 16.9652 6.85058 18.8989 6.5283L19.5 6.42812V12.1721C19.5 14.6835 18.1453 16.6355 16.4882 17.9793C15.661 18.6502 14.7692 19.1595 13.9598 19.4997C13.1409 19.844 12.4427 20 12 20C11.5564 20 10.8577 19.8454 10.0391 19.5036C9.22978 19.1657 8.33821 18.6593 7.51122 17.9905C5.85525 16.6513 4.5 14.6995 4.5 12.1721V6.42812Z"
      fill="#00245B"
    />
  </svg>
);

const ChangeUserAPIPassword = ({ intl, menuDetails }) => {
  const onClick = () => {
    window.open(menuDetails.link, '_self');
  };

  return (
    <MenuItem
      label={intl.formatMessage({
        id: `gen2.profileMenu.label.${menuDetails.id}`,
      })}
      icon={<PasswordIcon />}
      handleClick={onClick}
    >
      <PasswordIcon />
    </MenuItem>
  );
};

ChangeUserAPIPassword.propTypes = {
  menuDetails: PropTypes.shape({
    id: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }).isRequired,
  intl: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string.isRequired,
      formatMessage: PropTypes.func.isRequired,
    }),
  ).isRequired,
};

export default injectIntl(ChangeUserAPIPassword);
