import React from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';

const StringToHTML = ({ string, ...rest }) => {
  // eslint-disable-next-line react/no-danger
  return <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(string) }} {...rest} />;
};

StringToHTML.propTypes = {
  string: PropTypes.string.isRequired,
};

export default StringToHTML;
