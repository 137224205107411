export {} from './ShortcutsState';

import type RootState from './RootState';
export { type RootState };

import { type actions } from './actions';
export { thunks } from './actions';

export { default as reducer, INITIAL_STATE } from './reducer';

import {
  type TypedUseSelectorHook,
  useDispatch as useGenericDispatch,
  useSelector as useGenericSelector,
} from 'react-redux';
import { type ThunkDispatch } from 'redux-thunk';
import { type ActionType } from 'typesafe-actions';
export const useSelector: TypedUseSelectorHook<RootState> = useGenericSelector;
export type AppDispatch = ThunkDispatch<
  RootState,
  unknown,
  ActionType<typeof actions>
>;
export const useDispatch: () => AppDispatch = useGenericDispatch;
