import rootReducer from './version-reducer';
import { fetchVersionList, fetchEcmVersion, fetchUIVersion } from './version-saga';

export default function getVersionModule() {
  return {
    // Unique id of the module
    id: 'Version',
    retained: true,
    // Maps the Store key to the reducer
    reducerMap: {
      versionPage: rootReducer,
    },
    sagas: [fetchVersionList, fetchEcmVersion],
  };
}
