import React, { useState } from 'react';

import './IFrameLoader.css';

interface IframeLoaderProps {
  iframeURL: string;
  title: string;
}

export default function IframeLoader({ iframeURL, title }: IframeLoaderProps) {
  const [loaded, setLoaded] = useState<boolean>(false);

  return (
    <section
      className='ISPM-IframeLoaderSection'
      aria-label='Iframe container' // Add accessible name here
    >
      {!loaded && (
        <div className='ISPM-IframeLoaderSection--shimmer-ui'>Loading...</div>
      )}
      <iframe
        title={title}
        data-testid='Apache superset dashboard'
        className='ISPM-IframeLoaderSection-iframe'
        src={iframeURL}
        onLoad={() => setLoaded(true)}
        style={{
          display: loaded ? 'block' : 'none',
        }}
      />
    </section>
  );
}
