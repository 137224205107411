import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import MuiTextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import moment from 'moment';
// import Select from '@material-ui/core/Select';
// import MenuItem from '@material-ui/core/MenuItem';
import { getDateAndTime } from 'ui-common/src/lib/utils';
import NotesIcon from '@material-ui/icons/Notes';
import { SaviyntRangePicker } from '../SaviyntRangePicker';
import { AutocompleteDropdown } from '../FormComponentsOutlined';
import { ManageTicket } from '../ManageTicket';
import style from './TimeframePopup.module.scss';

const useStyles = makeStyles(() => ({
  addTicket: {
    position: 'relative',
  },
  businessIcon: {
    position: 'relative',
    bottom: 25,
    right: 5,
  },
}));
const TextField = withStyles({
  root: {
    marginTop: 0,
  },
})(MuiTextField);

const TimeframeForm = props => {
  const classes = useStyles();
  // const [duration, setDuration] = useState(12);
  // const changeDuration = e => setDuration(e.target.value);
  // if (props.appLauncher === '' || props.appLauncher === undefined) {
  //   props.disableProgress(true);
  // }
  const fromDateTime = moment(props.instanceFromDate).set(props.instanceFromTime);
  const toDateTime = moment(props.instanceToDate).set(props.instanceToTime);
  let disableContinue = '';
  const fromDateHour = moment(fromDateTime).get('hours');

  const defaultRequestableTime =
    props.selectedUser &&
    props.selectedUser.accountConfig &&
    props.selectedUser.accountConfig.defaultrequestabletimeinsecs;
  const maxRequestableTime =
    props.selectedUser &&
    props.selectedUser.accountConfig &&
    props.selectedUser.accountConfig.maxrequestabletimeinsecs;

  const frDateTime = fromDateTime;
  const tDateTime =
    props.selectedUser &&
    !props.selectedUser.edit &&
    defaultRequestableTime &&
    defaultRequestableTime > 0
      ? getDateAndTime(fromDateHour, defaultRequestableTime, fromDateTime)
      : toDateTime;

  const instanceFromDate = frDateTime.clone().set({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const instanceFromTime = {
    hours: frDateTime.get('hours'),
    minutes: frDateTime.get('minutes'),
    seconds: frDateTime.get('seconds'),
  };

  const instanceToDate = tDateTime.clone().set({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const instanceToTime = {
    hours: tDateTime.get('hours'),
    minutes: tDateTime.get('minutes'),
    seconds: tDateTime.get('seconds'),
  };
  const changeRange = range => {
    props.setSelectedDateTime({
      fromDateTime: range[0],
      toDateTime: range[1],
    });
  };
  if (props.selectedDateTime) {
    const duration = moment.duration(
      moment(props.selectedDateTime.toDateTime, 'MMM DD, YYYY h:mm a').diff(
        moment(props.selectedDateTime.fromDateTime, 'MMM DD, YYYY h:mm a')
      )
    );
    const tottalSeconds = duration.asSeconds();
    const maxrequestabletimeinsecs =
      props.selectedUser &&
      props.selectedUser.accountConfig &&
      props.selectedUser.accountConfig.maxrequestabletimeinsecs;
    disableContinue =
      tottalSeconds > 0
        ? maxrequestabletimeinsecs > 0 && tottalSeconds > maxrequestabletimeinsecs
        : false;
    props.setDisabled(disableContinue);
  }
  const indexOfTerminal =
    props.remoteAppDetails &&
    props.remoteAppDetails.findIndex(x => x.displayName === 'Terminal') > -1
      ? props.remoteAppDetails.findIndex(x => x.displayName === 'Terminal')
      : 0;

  const usedTickets = [];
  return (
    <>
      <div className={style.subSection}>
        {disableContinue && (
          <span className={style.errorMsg}>
            Maximum Requestable time exceeded
            <br />
          </span>
        )}
        <span className={style.subSectionTitle}>
          {props.intl.formatMessage({ id: 'TimeframeForm.text.subSectionTitle' })}
        </span>
        <SaviyntRangePicker
          instanceFromDate={instanceFromDate}
          instanceToDate={instanceToDate}
          instanceFromTime={instanceFromTime}
          instanceToTime={instanceToTime}
          dateFormat="MMM DD, YYYY"
          showErrorMessage={false}
          onChange={changeRange}
          disableProgress={props.disableProgress}
        />
      </div>
      {/* <div>
        <span style={{ fontSize: 11, marginLeft: 5 }}>Duration time</span>
        <Select style={{ fontSize: 11, marginLeft: 5 }} value={duration} onChange={changeDuration}>
          <MenuItem style={{ fontSize: 11 }} value={12}>
            12 hours
          </MenuItem>
          <MenuItem style={{ fontSize: 11 }} value={24}>
            24 hours
          </MenuItem>
        </Select>
      </div> */}
      <div className={style.subSection}>
        {/* className={style.businessJustificationMandatory} */}
        <span className={style.subSectionTitle}>
          {props.intl.formatMessage({ id: 'TimeframeForm.label.BusinessJustification' })}
        </span>
        {(props.configData === 0 || props.configData === 1) && (
          <sup className={style.mandateField}>*</sup>
        )}
        <div className={style.bjticketsection}>
          <TextField
            multiline
            type="text"
            rows={4}
            id="filled-full-width"
            margin="dense"
            variant="outlined"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" className={classes.businessIcon}>
                  <NotesIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end" className={classes.addTicket}>
                  <ManageTicket
                    selectedTickets={props.selectedTickets}
                    usedTickets={usedTickets}
                    addTicket={tNum => props.setSelectedTickets([...props.selectedTickets, tNum])}
                    removeTicket={tNum =>
                      props.setSelectedTickets(props.selectedTickets.filter(t => t !== tNum))
                    }
                  />
                </InputAdornment>
              ),
            }}
            value={
              props.businessJustification
                ? props.businessJustification.hasOwnProperty('value')
                  ? props.businessJustification.value.split('[')[0]
                  : props.businessJustification.split('[')[0]
                : ''
            }
            placeholder={props.intl.formatMessage({
              id: 'TimeframeForm.placeholder.BusinessJustification',
            })}
            onChange={e => {
              props.setBusinessJustification(e.target.value);
            }}
          />
        </div>
        {props.remoteAppDetails && props.remoteAppDetails.length > 0 && (
          <div>
            <span className={style.subSectionTitle}>
              {props.intl.formatMessage({
                id: 'TimeframeForm.label.ApplicationLauncher',
              })}
            </span>
            <AutocompleteDropdown
              name={props.intl.formatMessage({
                id: 'TimeframeForm.label.ApplicationLauncher',
              })}
              options={props.remoteAppDetails}
              defaultValue={props.remoteAppDetails[indexOfTerminal]}
              _getLabel={data => data.displayName}
              _handleChange={value => {
                props.setAppLauncher(value);
                if (props.disableProgress !== undefined) {
                  if (props.appLauncher !== '') {
                    props.disableProgress(false);
                  } else {
                    props.disableProgress(true);
                  }
                }
              }}
            />
          </div>
        )}

        {/* {props.selectedUser !== undefined &&
          props.selectedUser.accountConfig['Saviynt-Status'].remoteapp !== undefined && (
            <div>
              <span className={style.subSectionTitle}>Application Launcher</span>
              <TextField
                id="filled-full-width"
                fullWidth
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                  style: { color: 'var(--black)', fontWeight: 500 },
                }}
                InputProps={{
                  style: { height: 40, fontSize: 11 },
                }}
                value={
                  props.selectedUser !== undefined &&
                  props.selectedUser.accountConfig['Saviynt-Status'].remoteapp
                }
              />
            </div>
          )} */}
      </div>
    </>
  );
};

TimeframeForm.propTypes = {
  instanceFromDate: PropTypes.shape.isRequired,
  instanceToDate: PropTypes.shape.isRequired,
  instanceFromTime: PropTypes.shape.isRequired,
  instanceToTime: PropTypes.shape.isRequired,
  setSelectedDateTime: PropTypes.func.isRequired,
  disableProgress: PropTypes.func.isRequired,
  businessJustification: PropTypes.string.isRequired,
  setBusinessJustification: PropTypes.func.isRequired,
  selectedUser: PropTypes.shape.isRequired,
  intl: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string.isRequired,
      formatMessage: PropTypes.func.isRequired,
    })
  ).isRequired,
  remoteAppDetails: PropTypes.shape.isRequired,
  setAppLauncher: PropTypes.func.isRequired,
  appLauncher: PropTypes.string.isRequired,
};

export default TimeframeForm;
