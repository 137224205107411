import { SIDE_MENU_LOADED, SIDE_MENU_LOADING } from '../actions/sideMenuAction';
import addParentMenu from '../utils/addParentMenu';

export const initState = () => ({
  sideMenuLoading: false,
  sideMenu: null,
});

export const appReducer = () => (state = initState(), action) => {
  switch (action.type) {
    case SIDE_MENU_LOADING:
      return { ...state, sideMenuLoading: true };

    case SIDE_MENU_LOADED:
      return { ...state, sideMenuLoading: false, sideMenu: addParentMenu(action.data) };

    default:
      return state;
  }
};

export default appReducer();
