import { useCallback, useEffect, useRef } from 'react';
import {
  debouncePromise,
  DEFAULT_INPUT_DEBOUNCE_DELAY,
  Logger,
} from '@saviynt/common';
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';

import {
  ACCOUNT_JIT_SELECTION,
  MODAL_PAGE_KINDS,
} from '../../components/ModalPageForms/constants';
import {
  handleCredentialFormAccountId,
  handleCredentialFormEndpointKey,
  handleIsJustInTimeRequest,
} from '../../store/actions/index';
import { getAccountListApi } from '../../utilities/api/getDataFromApi';

const ACCORDION_OPEN_DELAY_IN_MS = 500;
const RECORDS_PER_API_CALL = 25;

// get relevant info from accounts to send to Radio buttons
const extractAccountDetails = (accountList, setAccountList) => {
  if (!accountList.result || !accountList.result.accounts) return;

  const { accounts } = accountList.result;
  const extractedDetails = accounts.map((account) => ({
    value: account.accountKey,
    title: account.name,
    description: account.description || account.pamAccountType,
    maxtime: account.accountConfig.maxrequestabletimeinsecs,
    exclusiveAccess: account.exclusiveAccess,
    federatedAccount: account.federatedAccount,
  }));

  setAccountList(extractedDetails);
};

const fetchAccounts = async ({ pageParam = 0, queryKey }) => {
  const [, accountModalSearchTerm, max, serviceEndpointKey, credentialType] =
    queryKey;
  let response;

  try {
    response = await getAccountListApi(
      serviceEndpointKey,
      credentialType,
      accountModalSearchTerm,
      max,
      pageParam * max
    );
  } catch (error) {
    Logger.error('An error occurred while fetching the account list:', error);
  }

  return response;
};

const ModalPageAccountService = (
  modalPageKind,
  accountSelection,
  accountListArray,
  serviceEndpointName,
  serviceEndpointKey,
  setAccountListArray,
  setIsExpandedAccountSelection,
  accountModalSearchTerm,
  setAccountModalSearchTerm,
  dispatch,
  setAccountListFilteredArray,
  isAccountListModal
) => {
  const accountsTotalCountRef = useRef();

  const getAccountsTotalCount = () => accountsTotalCountRef.current;

  const credentialType =
    modalPageKind === MODAL_PAGE_KINDS.COC ? 'CREDENTIAL' : 'CREDENTIALLESS';

  const queryClient = useQueryClient();

  useEffect(() => {
    if (accountSelection?.value === ACCOUNT_JIT_SELECTION.value) {
      dispatch(
        handleIsJustInTimeRequest(
          serviceEndpointName,
          'Just In Time set success'
        )
      );

      return;
    }

    if (!accountSelection) return;

    dispatch(
      handleCredentialFormAccountId(
        accountSelection?.value,
        'Account Id success'
      )
    );

    if (accountSelection?.federatedAccount) {
      dispatch(handleCredentialFormEndpointKey(serviceEndpointKey, 'success'));
    }
  }, [accountSelection]);

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    refetch,
  } = useInfiniteQuery({
    queryKey: [
      'accounts',
      accountModalSearchTerm,
      RECORDS_PER_API_CALL,
      serviceEndpointKey,
      credentialType,
    ],
    queryFn: fetchAccounts,
    enabled: !!serviceEndpointKey, // only enable query when serviceEndpointKey is truthy
    getNextPageParam: (lastPage, pages) => {
      const totalFetched = pages.flatMap((page) => page.result.accounts).length;
      const totalAvailable = lastPage.count;

      // if total fetched is less than total available, there are more pages to fetch
      return totalFetched < totalAvailable
        ? totalFetched / RECORDS_PER_API_CALL
        : undefined;
    },
    refetchOnWindowFocus: false,
    select: (accountsData) => {
      const { count } = accountsData.pages[0];

      // flatten and deduplicate accounts data
      const allAccounts = accountsData.pages.flatMap(
        (page) => page.result.accounts
      );
      const uniqueAccounts = Array.from(
        new Set(allAccounts.map((account) => account.accountKey))
      ).map((value) =>
        allAccounts.find((account) => account.accountKey === value)
      );

      return {
        pages: [{ result: { accounts: uniqueAccounts } }],
        pageParams: accountsData.pageParams,
        count,
      };
    },
  });

  useEffect(() => {
    if (data) {
      if (!isAccountListModal) {
        // only sets the main modal page data on page load
        extractAccountDetails(data.pages[0], setAccountListArray);
        accountsTotalCountRef.current = data.count;
      }

      // sets modal island data on page load + on search and infinite scroll
      extractAccountDetails(data.pages[0], setAccountListFilteredArray);
    }
  }, [data, setAccountListArray, setAccountListFilteredArray]);

  const debouncedSearch = useCallback(
    debouncePromise((value) => {
      setAccountModalSearchTerm(value); // calls api on every change.

      if (!isFetching) {
        return refetch();
      }

      return Promise.resolve(); // return resolved promise if fetching
    }, DEFAULT_INPUT_DEBOUNCE_DELAY),
    [setAccountModalSearchTerm, refetch]
  );

  // Open Account accordion when list is ready
  useEffect(() => {
    if (accountListArray) setIsExpandedAccountSelection(true);

    const isCTSWithNoAccounts =
      modalPageKind === MODAL_PAGE_KINDS.CTS && accountListArray?.length === 0;

    if (isCTSWithNoAccounts) {
      const timer = setTimeout(() => {
        setIsExpandedAccountSelection(true);
      }, ACCORDION_OPEN_DELAY_IN_MS);

      return () => clearTimeout(timer);
    }

    return () => {};
  }, [accountListArray]);

  // invalidate query and reset data when serviceEndpointKey changes or component unmounts
  useEffect(
    () => () => {
      queryClient.invalidateQueries({ queryKey: ['accounts'] });
      queryClient.resetQueries(['accounts']);
    },
    [queryClient, serviceEndpointKey]
  );

  return {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    debouncedSearch,
    getAccountsTotalCount,
  };
};

export default ModalPageAccountService;
