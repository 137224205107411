import React from 'react';
import { Loader } from '@saviynt/design-system';

const AppLoading = () => {
  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Loader kind="loop" format="main" color="brand" size='large' />
    </div>
  );
};
export default AppLoading;
