import React from 'react';
import { useHistory } from 'react-router-dom';
import { InSeparator, InterPoint } from '@saviynt/common';
import { Box, Icon, ModalPageHeader, Typography } from '@saviynt/design-system';
import PropTypes from 'prop-types';

import { ModuleDetails } from '../../models/PamModels';

function ModalFormHeader({ title, endpointDetails, dataTestId }) {
  const history = useHistory();

  const closeHandler = () => {
    history.goBack();
  };

  const getDisplayName = () => {
    if (endpointDetails?.type === 'role')
      return endpointDetails?.roleDisplayName || endpointDetails?.roleName;

    return (
      endpointDetails?.endpointDisplayName ||
      endpointDetails?.endpointName ||
      endpointDetails?.displayName
    );
  };

  const getAssetOrRoleType = () => {
    if (endpointDetails?.type === 'role')
      return (
        <Typography kind='body2' className='ModalPage-subHeadingContentBody'>
          {endpointDetails?.roleType}
        </Typography>
      );

    return (
      <>
        <Icon
          kind={endpointDetails?.assetType}
          color='neutral-000'
          size='small'
        />
        <Typography kind='body2' className='ModalPage-subHeadingContentBody'>
          {endpointDetails?.assetType}
        </Typography>
      </>
    );
  };

  const getSubHeadingContent = () => (
    <Box tag='div' className='ModalPage-subHeadingContent'>
      <Typography kind='body2' className='ModalPage-subHeadingContentBody'>
        {getDisplayName()}
      </Typography>
      <InterPoint color='neutralSubtlest' />
      {getAssetOrRoleType()}
      {endpointDetails?.platformType && (
        <>
          <InSeparator color='neutralInverse' />
          <Typography kind='body2' className='ModalPage-subHeadingContentBody'>
            {endpointDetails?.platformType}
          </Typography>
        </>
      )}
    </Box>
  );

  return (
    <ModalPageHeader
      title={title}
      subHeadingContent={getSubHeadingContent()}
      closeHandler={() => closeHandler()}
      className='ModalPage-header'
      data-testid={dataTestId}
    />
  );
}

ModalFormHeader.propTypes = {
  title: PropTypes.string,
  endpointDetails: PropTypes.shape(ModuleDetails),
  dataTestId: PropTypes.string,
};

ModalFormHeader.defaultProps = {
  title: null,
  endpointDetails: null,
  dataTestId: null,
};

export default ModalFormHeader;
