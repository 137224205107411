
import React from 'react';

const BackChevronIcon = () => (
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.6558 4.98324C12.8185 5.14596 12.8185 5.40978 12.6558 5.5725L8.22821 10.0001L12.6558 14.4277C12.8185 14.5904 12.8185 14.8542 12.6558 15.0169C12.4931 15.1797 12.2293 15.1797 12.0666 15.0169L7.34433 10.2947C7.18161 10.132 7.18161 9.86818 7.34433 9.70547L12.0666 4.98324C12.2293 4.82053 12.4931 4.82053 12.6558 4.98324Z" fill="#9EA1B1"/>
</svg>
);

export default BackChevronIcon;

