import { type ActionType, getType } from 'typesafe-actions';

import publicActions from '../actions';
import * as privateActions from '../actions';
import { type MfaPromptState } from '../MfaPromptState';

import INITIAL_STATE from './initialState';

export default (
  // eslint-disable-next-line @typescript-eslint/default-param-last -- conform to reducer signature
  state = INITIAL_STATE,
  action: ActionType<
    typeof publicActions & Omit<typeof privateActions, 'default'>
  >
): MfaPromptState => {
  switch (action.type) {
    case getType(publicActions.verificationRequired):
      return {
        ...state,
        isOpen: true,
        onSuccessMessage: action.payload,
      };
    case getType(privateActions.verificationDismissed):
      return {
        ...state,
        isOpen: false,
        onSuccessMessage: undefined,
      };
    case getType(privateActions.verificationSucceeded):
      return {
        ...state,
        isOpen: false,
        onSuccessMessage: undefined,
      };
    default:
      return state;
  }
};
