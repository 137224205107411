import React, { useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import styles from './ToggleButton.module.css';

const useStyles = makeStyles(() => ({
  label: {
    fontWeight: 500,
    fontSize: '14px',
    color: '#141419',
  },
}));

const CustomToggle = withStyles((theme) => ({
  root: {
    width: 40,
    height: 24,
    padding: 0,
    left: 20,
  },
  switchBase: {
    padding: 1,
    color: '#676B80',
    '&$checked': {
      transform: 'translateX(16px)',
      color: '#FFFFFF',
      '& + $track': {
        backgroundColor: '#254B86',
        opacity: 1,
        border: 'none',
      },
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  thumb: {
    width: 18,
    height: 18,
    position: 'relative',
    top: 2,
    left: 2,
    boxShadow: 'none',
    borderRadius: '50% !important',
  },
  track: {
    borderRadius: '12px !important',
    // borderRadius: 24 / 2,
    border: '1px solid #676B80',
    backgroundColor: '#EFF1FF',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => (
  <Switch
    focusVisibleClassName={classes.focusVisible}
    disableRipple
    data-testid="toggle"
    classes={{
      root: classes.root,
      switchBase: classes.switchBase,
      thumb: classes.thumb,
      track: classes.track,
      checked: classes.checked,
    }}
    {...props}
  />
));

const ToggleButton = ({
  onToggleChange, checked, label, position,
}) => {
  const classes = useStyles();
  const [state, setState] = useState(checked);

  const toggleClass = classnames(
    styles.toggle,
    position === 'left' && styles.leftAlign,
    position === 'right' && styles.rightAlign,
  );

  const handleChange = (event) => {
    event.stopPropagation();
    setState({ ...state, [event.target.name]: event.target.checked });
    onToggleChange(event.target.checked);
  };

  return (
    <div className={toggleClass}>
      <CustomToggle checked={checked} onChange={handleChange} name="toggle" />
      <span className={classes.label} data-testid="label">
        {label}
      </span>
    </div>
  );
};

ToggleButton.defaultProps = {
  checked: false,
};

ToggleButton.propTypes = {
  onToggleChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  label: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
};

export default ToggleButton;
