/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect, useState } from 'react';
import { InterPoint, useLocalize } from '@saviynt/common';
import {
  ApplicationIcon,
  Box,
  Button,
  FooterActionBar,
  Icon,
  InlineMessage,
  InputField,
  Loader,
  ModalIsland,
  Typography,
} from '@saviynt/design-system';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import useFetchRolesEntitlements from '../../../../../hooks/use-fetchrolesentitlements';
import { Entitlement, ModuleDetails } from '../../../../../models/PamModels';
import { msgs } from '../../../constants';

import './EntitlementsModal.css';

function EntitlementsModal({
  data,
  entitlementsModalContentRef,
  // TODO: determine how to use this prop
  // eslint-disable-next-line no-unused-vars
  sectionRefIsScrolled,
  isEntitlementsModalOpen,
  setIsEntitlementsModalOpen,
  initialEntitlements,
}) {
  const [searchBarValue, setSearchBarValue] = useState('');
  const [entitlements, setEntitlements] = useState(initialEntitlements);
  const [numOfAccountsWithEntitlements, setNumOfAccountsWithEntitlements] =
    useState(0);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const localize = useLocalize();

  // TODO: remove isAccountsEnabled when accounts are integrated with roles API
  const isAccountsEnabled = false;

  const { isRolesEntitlementsLoading } = useFetchRolesEntitlements(
    data.roleKey,
    searchBarValue,
    {},
    0,
    setEntitlements,
    isInitialRender,
    setIsInitialRender
  );

  useEffect(() => {
    let numAccountsWithEntitlements = 0;

    entitlements.forEach((entitlement) => {
      // TODO: update once entitlements API is enhanced with account details
      if (entitlement.accountName) {
        numAccountsWithEntitlements += 1;
      }

      setNumOfAccountsWithEntitlements(numAccountsWithEntitlements);
    });
  }, [numOfAccountsWithEntitlements, entitlements]);

  const hasEndpoint = (entitlement) =>
    entitlement.platformType && entitlement.platformType;

  const hasEntitlementType = (entitlement) =>
    entitlement.etDisplayName || entitlement.etName;

  const getEntitlementRow = (label, value) => (
    <Box tag='div' className='EntitlementsModal-entitlementsRow-fields'>
      <Typography
        kind='body3'
        className='EntitlementsModal-entitlementsRow-label'>
        {`${label}:`}
      </Typography>
      <Typography
        kind='body3'
        className='EntitlementsModal-entitlementsRow-value'>
        {value}
      </Typography>
    </Box>
  );

  const getAppIconAndName = (icon, endpoint) => (
    <Box tag='div' className='EntitlementsModal-entitlementsRow-fields'>
      {icon && <ApplicationIcon logo={icon} size='xxSmall' />}
      <Typography
        kind='body3'
        className='EntitlementsModal-entitlementsRow-value'>
        {endpoint}
      </Typography>
    </Box>
  );

  const modalIslandContent = () => (
    <Box className='EntitlementsModal-modalIslandContent'>
      {/* TODO: remove isAccountsEnabled when accounts are integrated with roles API */}
      {isAccountsEnabled && numOfAccountsWithEntitlements === 0 && (
        <section className='EntitlementsModal-accountsHelperText'>
          <InlineMessage
            text={localize(msgs.pam.sidePanel.noAccountsEntitlementsMessage)}
            colorTheme='info'
            leftIcon={<Icon kind='Info' color='info-700' />}
          />
        </section>
      )}
      <section className='EntitlementsModal-searchContainer'>
        <InputField
          placeholder={localize(
            msgs.pam.sidePanel.searchEntitlementsModalPlaceholder
          )}
          name='basic'
          kind='search'
          prefixIcon={
            <Icon kind='search' color='neutral-600' size='smallMedium' />
          }
          value={searchBarValue}
          setValue={setSearchBarValue}
          className='EntitlementsModal-search'
        />
      </section>
      <section className='EntitlementsModal-entitlementsContainer'>
        {isRolesEntitlementsLoading ? (
          <Box className='EntitlementsModal-loader'>
            <Loader kind='dots' format='inline' color='info' size='medium' />
          </Box>
        ) : (
          entitlements.map((entitlement, index) => {
            const isNotLastValue = index !== entitlements.length - 1;

            return (
              <Box
                tag='div'
                className={classnames(
                  'EntitlementsModal-entitlementsRow',
                  isNotLastValue &&
                    'EntitlementsModal-entitlementsRow--withBorder'
                )}>
                <section className='EntitlementsModal-entitlementsRow-header'>
                  <Typography
                    kind='h3'
                    className='EntitlementsModal-entitlementsRow-entitlementName'>
                    {entitlement.evEntitlementValue}
                  </Typography>
                  {hasEndpoint(entitlement) && (
                    <>
                      {entitlement.evEntitlementValue && <InterPoint />}
                      {getAppIconAndName(
                        entitlement.platformType,
                        hasEndpoint(entitlement)
                      )}
                    </>
                  )}
                  {/* TODO: remove isAccountsEnabled when accounts are integrated with roles API */}
                  {isAccountsEnabled && (
                    <div>
                      {hasEndpoint(entitlement) && <InterPoint />}
                      {getEntitlementRow(
                        'Account',
                        // TODO: update once entitlements API is enhanced with account details
                        entitlement.accountName
                      )}
                    </div>
                  )}
                </section>
                <section className='EntitlementsModal-entitlementsRow-metaData'>
                  {hasEntitlementType(entitlement) &&
                    getEntitlementRow(
                      'Entitlement Type',
                      hasEntitlementType(entitlement)
                    )}
                  {hasEntitlementType(entitlement) && <InterPoint />}
                  {getEntitlementRow(
                    'Security System',
                    entitlement.ssDisplayName
                  )}
                </section>
                <Typography
                  kind='body2'
                  className='EntitlementsModal-entitlementsRow-description'>
                  {entitlement.evDescription}
                </Typography>
              </Box>
            );
          })
        )}
      </section>
    </Box>
  );

  const modalIslandFooterActionBar = () => (
    <Box className='EntitlementsModal-footer'>
      <Button
        kind='ghost'
        size='medium'
        onClick={() => {
          setIsEntitlementsModalOpen(false);
        }}>
        {localize(msgs.pam.sidePanel.cancel)}
      </Button>
    </Box>
  );

  return (
    <ModalIsland
      className='EntitlementsModal'
      onClose={() => {
        setIsEntitlementsModalOpen(false);
      }}
      isOpen={isEntitlementsModalOpen}
      headerIcon={<Icon kind='Entitlement' color='neutral-1000' size='large' />}
      title={localize(msgs.pam.sidePanel.entitlements)}
      subtitle={localize(msgs.pam.sidePanel.entitlementsModalSubheading)}
      contentBody={modalIslandContent()}
      sectionRef={entitlementsModalContentRef}
      FooterActionBarComp={
        <FooterActionBar
          size='large'
          isScrolled={sectionRefIsScrolled}
          className='EntitlementsModal-footerActionBar'>
          {modalIslandFooterActionBar()}
        </FooterActionBar>
      }
    />
  );
}

EntitlementsModal.propTypes = {
  data: ModuleDetails.isRequired,
  entitlementsModalContentRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  sectionRefIsScrolled: PropTypes.bool,
  isEntitlementsModalOpen: PropTypes.bool.isRequired,
  setIsEntitlementsModalOpen: PropTypes.func.isRequired,
  initialEntitlements: PropTypes.arrayOf(Entitlement).isRequired,
};

EntitlementsModal.defaultProps = {
  entitlementsModalContentRef: null,
  sectionRefIsScrolled: null,
};

export default EntitlementsModal;
