import React, { useState, useEffect } from 'react';
// eslint-disable-next-line import/extensions
import PropTypes from 'prop-types';

import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Chip from '@material-ui/core/Chip';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { injectIntl } from 'react-intl';

import { A } from '../A';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ExpansionPanel = withStyles({
  root: {
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
      marginBottom: '30px !important',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    minHeight: 56,
    padding: 0,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: 0,
  },
}))(MuiExpansionPanelDetails);

const useStyles = makeStyles(theme => ({
  checboxWidth: { width: '100%' },
  chiproot: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  chipItem: {
    marginTop: 7,
    marginRight: 5,
    padding: '0px 5px',
    height: '100%',
  },
  chipLabel: {
    whiteSpace: 'pre-wrap',
  },
  formControl: {
    width: 150,
  },
}));

const AutoSuggestView = ({
  getDistinctColumnValues,
  column,
  appliedFilters,
  setAppliedFilters,
  dataSource,
  requestBody,
  preAppliedFilters,
  intl,
  loadingColumns,
}) => {
  const [selectedList, setSelectedList] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const classes = useStyles();

  const filterOptions = createFilterOptions({
    limit: 500,
  });

  const [age, setAge] = React.useState('');

  const handleChange = event => {
    setAge(event.target.value);
  };

  useEffect(() => {
    const url = { ...column.filter };
    const selected = Object.keys(appliedFilters).length === 0 ? [] : appliedFilters[column.key];
    if (selected !== undefined && selected.length > 0) {
      setSelectedList(selected);
    }
    if (selected !== undefined && selected.length === 0) {
      setSelectedList(selected);
    }
  }, [appliedFilters]);

  useEffect(() => {
    const url = { ...column.filter };
    const selected =
      Object.keys(preAppliedFilters).length === 0 ? [] : preAppliedFilters[column.key];
    if (selected !== undefined && selected.length > 0) {
      getDistinctColumnValues(column.key, url.filterURL, requestBody, column);
      setSelectedList(selected);
      setExpanded(true);
    }
  }, [preAppliedFilters]);

  const onExpand = (e, expanded) => {
    if (expanded) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
    if (expanded && dataSource.length === 0) {
      const url = { ...column.filter };
      getDistinctColumnValues(column.key, url.filterURL, requestBody, column);
    }
  };

  let Appkey = column.key;
  if (column.key === 'reqId') Appkey = 'ApprovalId';
  if (column.key === 'requestId') Appkey = 'HistoryId';

  return (
    <div>
      <ExpansionPanel square expanded={expanded} onChange={onExpand}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1d-content"
          id="panel1d-header"
        >
          <div className="advfilter-title">{intl.formatMessage({ id: column.label })}</div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className="d-flex flex-column panel-det-padd">
          {expanded && (
            <>
              {!loadingColumns.includes(column.key) ? (
                <div className="d-flex">
                  <Autocomplete
                    noOptionsText={intl.formatMessage({ id: 'ColumnFilter.NoOptionsText' })}
                    filterOptions={filterOptions}
                    defaultValue={selectedList}
                    multiple
                    filterSelectedOptions
                    id="filter-demo"
                    options={dataSource}
                    popupIcon={null}
                    debug
                    value={selectedList}
                    onChange={(e, selections) => {
                      setAppliedFilters({
                        ...appliedFilters,
                        [column.key]: selections,
                      });
                      setSelectedList(selections);
                    }}
                    getOptionLabel={option =>
                      column.filter && column.filter.intlSupportRequired && option
                        ? intl.formatMessage({ id: option })
                        : option
                    }
                    style={{ width: '100%' }}
                    renderInput={params => (
                      <TextField
                        classes={{
                          root: classes.checboxWidth,
                        }}
                        {...params}
                        label=""
                        placeholder={intl.formatMessage({ id: column.label })}
                      />
                    )}
                  />
                </div>
              ) : (
                <div className="d-flex justify-center adv-fil-loader">
                  <img src="/ECMv6/assets/images/Double Ring-1s-80px.gif" />
                </div>
              )}
            </>
          )}
          <div className={classes.root}>
            {selectedList.map(item => (
              <Chip
                classes={{ root: classes.chipItem, label: classes.chipLabel }}
                label={
                  column.filter && column.filter.intlSupportRequired && item
                    ? intl.formatMessage({ id: item })
                    : item
                }
                onDelete={() => {
                  const list = selectedList.filter(a => a !== item);
                  setAppliedFilters({
                    ...appliedFilters,
                    [column.key]: list,
                  });
                  setSelectedList(list);
                }}
                variant="outlined"
              />
            ))}
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
};

AutoSuggestView.propTypes = {
  getDistinctColumnValues: PropTypes.func.isRequired,
  setAppliedFilters: PropTypes.func.isRequired,
  column: PropTypes.objectOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
      filter: PropTypes.objectOf(
        PropTypes.shape({
          filterURL: PropTypes.string,
          type: PropTypes.string,
        })
      ),
    })
  ).isRequired,
  appliedFilters: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  dataSource: PropTypes.arrayOf(PropTypes.string).isRequired,
  requestBody: PropTypes.objectOf(
    PropTypes.shape({
      connectiontype: PropTypes.string,
    })
  ).isRequired,
  intl: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string.isRequired,
      formatMessage: PropTypes.func.isRequired,
    })
  ).isRequired,
  loadingColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
};
export default injectIntl(AutoSuggestView);
