import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import classnames from 'classnames';
import './styles.scss';

enum NOTIFICATION_TYPE {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

export interface NotificationModalProps {
  /**
   * Notification type
   */
  type: NOTIFICATION_TYPE;
  /**
   * Show dialog
   */
  visible: boolean;
  /**
   * Success Modal Title
   */
  title?: string;
  /**
   * Success Modal details
   */
  details?: string;
  /**
   * Success Modal onclose callback
   */
  onClose?: () => {};
}

const NotificationModal: React.FC<NotificationModalProps> = ({
  type = NOTIFICATION_TYPE.SUCCESS,
  visible = true,
  title,
  details,
  onClose = () => {},
  ...rest
}) => {
  const iconClass = classnames('notification-icon', {
    success: type === NOTIFICATION_TYPE.SUCCESS,
    failure: type === NOTIFICATION_TYPE.FAILURE,
  });

  return (
    <Dialog
      open={visible}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...rest}
    >
      <div className="notification-dialog-container">
        <IconButton aria-label="close" className="notification-dialog-close-btn" onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <div className={iconClass} />
        {title ? <div className="notification-dialog-title">{title}</div> : null}
        {details ? <div className="notification-dialog-details">{details}</div> : null}
      </div>
    </Dialog>
  );
};

export default NotificationModal;
