import { useEffect } from 'react';

const useApiOptions = (apiResponse, setStateFunction) => {
  useEffect(() => {
    if (!apiResponse) return;

    setStateFunction(
      Object.entries(apiResponse).map(([key, resValue]) => {
        let prefixIcon;

        switch (key) {
          // Assets
          case 'DATABASE':
          case 'SERVER':
          case 'APPLICATION':
          case 'VAULT':
            prefixIcon = {
              isApplicationIcon: false,
              kind: key.toLowerCase(),
            };
            break;
          // Platforms
          case 'ON_PREMISE':
            prefixIcon = {
              isApplicationIcon: true,
              kind: 'placeholder',
              size: 'xSmall',
            };
            break;
          // Roles
          case 'EMERGENCYACCESSROLE':
            prefixIcon = {
              isApplicationIcon: false,
              kind: 'role',
              size: 'medium',
            };
            break;
          default:
            prefixIcon = {
              isApplicationIcon: true,
              kind: key,
              size: 'xSmall',
            };
            break;
        }

        return {
          label: resValue,
          value: key,
          isChecked: false,
          prefixIcon,
        };
      })
    );
  }, [apiResponse, setStateFunction]);
};

export default useApiOptions;
