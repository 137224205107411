/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-curly-spacing */
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { localize, Logger } from '@saviynt/common';
import {
  Box,
  Button,
  Icon,
  InlineMessage,
  Loader,
} from '@saviynt/design-system';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { ModuleDetails } from '../../../../models/PamModels';
import { getAccountListApi } from '../../../../utilities/api/getDataFromApi';
import { MODAL_PAGE_KINDS } from '../../../ModalPageForms/constants';
import { ASSET_TYPES, msgs } from '../../constants';

import './SpfEndpoint.css';

const PAM_TYPES = {
  CREDENTIAL: 'CREDENTIAL',
  CREDENTIALLESS: 'CREDENTIALLESS',
};

function SpfEndpoint({
  data,
  credentialRequestOnClick,
  dataTestId,
  className,
}) {
  const classes = classnames('SpfEndpoint', className);
  const [accounts, setAccounts] = useState([]);
  const [credentialedArray, setCredentialedArray] = useState([]);
  const [credentiallessArray, setCredentiallessArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isErrorMessageVisible, setIsErrorMessageVisible] = useState(false);
  const intl = useIntl();
  const isDBorServer =
    data.assetType === ASSET_TYPES.DATABASE ||
    data.assetType === ASSET_TYPES.SERVER;

  const isCredentialed = credentialedArray.length > 0;

  const isCredentialless =
    (accounts.length >= 0 && credentiallessArray.length >= 0 && isDBorServer) ||
    credentiallessArray.length > 0;

  const parseAccountsForPamType = () => {
    const credArray = [];
    const credlessArray = [];

    setIsLoading(true);
    setIsError(false);
    getAccountListApi(data.endpointKey)
      .then((response) => {
        setAccounts(response.result.accounts);

        if (response.result.accounts.length > 0) {
          response.result.accounts.forEach((account) => {
            if (account.pamType?.toUpperCase() === PAM_TYPES.CREDENTIAL) {
              credArray.push(account);
            } else if (
              account.pamType?.toUpperCase() === PAM_TYPES.CREDENTIALLESS
            ) {
              credlessArray.push(account);
            }
          });
        }

        setCredentialedArray(credArray);
        setCredentiallessArray(credlessArray);

        setIsLoading(false);
      })
      .catch((error) => {
        Logger.error(
          'An error occurred while fetching the account list:',
          error
        );
        setIsLoading(false);
        setIsError(true);
        setIsErrorMessageVisible(true);
      });
  };

  useEffect(() => {
    parseAccountsForPamType();
  }, [data]);

  const renderBothButtons = () =>
    isError ? (
      <InlineMessage
        kind='background'
        text={localize(intl, msgs.pam.sidePanel.assetDidntLoadError)}
        colorTheme='critical'
        leftIcon={<Icon kind='AlertCritical' />}
        isVisible={isErrorMessageVisible}
        onDismiss={() => setIsErrorMessageVisible(false)}
        isDismissible
        size='small'
      />
    ) : (
      <Box tag='div' className='SpfEndpoint-twoButtons'>
        {isCredentialed && (
          <Button
            type='button'
            kind='outlined'
            className='SpfEndpoint-twoButtons--buttonStyle'
            onClick={() => credentialRequestOnClick(MODAL_PAGE_KINDS.COC)}
            leftIcon={
              <Icon
                kind='PasswordCredentialUnlock'
                size='smallMedium'
                color='neutral-100'
              />
            }>
            {localize(intl, msgs.pam.sidePanel.checkoutCredential)}
          </Button>
        )}
        {isCredentialless && (
          <Button
            type='button'
            kind='outlined'
            className='SpfEndpoint-twoButtons--buttonStyle'
            onClick={() => credentialRequestOnClick(MODAL_PAGE_KINDS.CTS)}
            leftIcon={
              <Icon kind='ConnectPlug' size='smallMedium' color='neutral-100' />
            }>
            {localize(intl, msgs.pam.sidePanel.connectToSession)}
          </Button>
        )}
      </Box>
    );

  return (
    <Box className={classes} dataTestId={dataTestId}>
      {isLoading ? (
        <Box tag='div' className='SpfEndpoint-loader'>
          <Loader kind='dots' format='inline' color='info' size='medium' />
        </Box>
      ) : (
        renderBothButtons()
      )}
    </Box>
  );
}

SpfEndpoint.propTypes = {
  data: ModuleDetails,
  dataTestId: PropTypes.string,
  credentialRequestOnClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

SpfEndpoint.defaultProps = {
  data: null,
  dataTestId: null,
  className: null,
};

export default SpfEndpoint;
