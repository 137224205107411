import CryptoJS from 'crypto-js';
import { APP_CONSTANTS } from '../constants';

class SessionStorageService {
  set(key, value) {
    if (key === APP_CONSTANTS) {
      const Utf16LE = CryptoJS.enc.Utf16LE.parse(JSON.stringify(value));
      const string = CryptoJS.enc.Utf16.stringify(Utf16LE);

      sessionStorage.setItem(key, string);
    } else {
      sessionStorage.setItem(key, value);
    }
  }

  get(key) {
    const value = sessionStorage.getItem(key);
    if (value && key === APP_CONSTANTS) {
      const Utf16 = CryptoJS.enc.Utf16.parse(value);

      return JSON.parse(CryptoJS.enc.Utf16LE.stringify(Utf16));
    }

    return value;
  }

  remove(key) {
    sessionStorage.removeItem(key);
  }
}

export default new SessionStorageService();
