import React, { useState, useEffect } from 'react';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/extensions
// eslint-disable-next-line import/extensions
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import { getSelectedLanguageObject } from 'ui-common/src/lib/utils';
import { SlimSelect } from '../SlimSelect';
import { Icon } from '../Icon';
import styles from './SaviyntDatePicker.module.scss';
import { allTimeOptions } from 'ui-common/src/constants';

const SaviyntDatePicker = ({
  instanceFromDate,
  instanceFromTime,
  startToday,
  onChange,
  dateFormat,
  iconRightSide,
  hideIcon,
  readOnly,
  updateExternally,
  minDate,
  onOpen,
  quickRequest,
  maxDate,
}) => {
  const timeOptions = allTimeOptions;
  const [fromDate, setFromDate] = useState(instanceFromDate);
  const startDate = minDate;
  const currDateTime = moment().add(15, 'minutes');
  const currDate = moment(currDateTime, 'YYYY-MM-DD HH:mm');
  const [fromTime, setFromTime] = useState(instanceFromTime);
  useEffect(() => {
    if (fromDate !== '' && fromDate !== null) {
      let fromDateNew = fromDate;
      if (typeof fromDate === 'string') {
        fromDateNew = moment(fromDate);
      }
      const fromDateTime = fromDateNew.set(fromTime);
      onChange(fromDateTime);
    }
  }, [fromDate, fromTime]);

  useEffect(() => {
    if (updateExternally !== undefined) {
      if (updateExternally === '') {
        setFromDate(null);
      } else {
        const fromDateTime = moment(updateExternally).startOf('day');
         if (!quickRequest) {
          setFromDate(moment(updateExternally).startOf('day'));
          onChange(fromDateTime);
        }
      }
    }
  }, [updateExternally]);

  const calendarIcon = <Icon className={`${styles.calendarIcon} pl-1`} name="calendar" />;

  return (
    <div className={styles.saviyntRangePicker}>
      {hideIcon === undefined && !iconRightSide && !readOnly && calendarIcon}
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getSelectedLanguageObject()}>
        <DatePicker
          defaultValue={fromDate}
          autoOk
          disableToolbar
          disabled={readOnly}
          variant="inline"
          style={{ width: '4.4rem', marginTop: 3, marginRight: 3 }}
          format={dateFormat}
          value={fromDate}
          onChange={value => {
            const momentFromdate = moment(value);
            momentFromdate.set({ hours: 0, minutes: 0, seconds: 0 });
            setFromDate(momentFromdate);
            // const dd = { fromDate };
            // momentFromdate.set('hour', 13);
          }}
          InputProps={{
            style: {
              fontSize: 11, // dateFontSize,
              fontWeight: 600,
              // marginTop: 3,
            },
            disableUnderline: readOnly,
          }}
          minDate={startToday ? moment(fromDate) : startDate}
          maxDate={maxDate ? maxDate : undefined}
          onOpen={() => {
            setTimeout(() => {
              onOpen(true);
            }, 1000);
          }}
          onClose={() => {
            setTimeout(() => {
              onOpen(false);
            }, 1000);
          }}
          // minDateMessage={false}
          // shouldDisableDate={disabledFromDateTime}
        />
      </MuiPickersUtilsProvider>
      {instanceFromTime && (
        <SlimSelect
          options={timeOptions}
          defaultValue={fromTime}
          disabled={value =>
            moment(instanceFromDate) &&
           (quickRequest
              ? moment(instanceFromDate).isBefore(moment(currDate)) &&
                value.hours * 60 + value.minutes <=
                  currDate.get('hours') * 60 + currDate.get('minutes')
              : value.hours * 60 + value.minutes <=
                instanceFromDate.get('hours') * 60 + instanceFromDate.get('minutes'))

          }
          onChange={option => setFromTime(option)}
        />
      )}
      {hideIcon === undefined && iconRightSide && !readOnly && calendarIcon}
    </div>
  );
};
SaviyntDatePicker.defaultProps = {
  instanceFromTime: null,
  dateFormat: 'MMM, dd yyyy',
  iconRightSide: false,
  readOnly: false,
  startToday: false,
  onOpen: null,
  maxDate: null,
};

SaviyntDatePicker.propTypes = {
  instanceFromDate: PropTypes.objectOf(
    PropTypes.shape({
      hours: PropTypes.number,
      minutes: PropTypes.number,
    })
  ).isRequired,
  instanceFromTime: PropTypes.string,
  startToday: PropTypes.bool,
  dateFormat: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  iconRightSide: PropTypes.bool,
  readOnly: PropTypes.bool,
  updateExternally: PropTypes.string.isRequired,
  hideIcon: PropTypes.bool.isRequired,
  quickRequest: PropTypes.bool.isRequired,
  maxDate: PropTypes.string,
  // disabledFromDateTime: PropTypes.string.isRequired,
};


export default SaviyntDatePicker;
