import Cookies from 'universal-cookie';
import { ACCESS_TOKEN } from '../constants';

class CookieService {
  constructor() {
    this.cookies = new Cookies();
  }

  // eslint-disable-next-line class-methods-use-this
  getDomain() {
    return window.location.hostname;
  }

  set(key, value, maxAge) {
    this.cookies.set(key, value, {
      path: '/',
      domain: this.getDomain(),
      maxAge: maxAge || this.getExpiration(),
      secure: true,
      sameSite: 'strict',
    });
  }

  get(key) {
    return this.cookies.get(key);
  }

  remove(key, isSessionCookie = false) {
    if (isSessionCookie) {
      this.cookies.remove(key, {
        path: '/',
      });
    } else {
      this.cookies.remove(key, {
        path: '/',
        domain: this.getDomain(),
      });
    }
  }

  getExpiration() {
    const accessToken = this.get(ACCESS_TOKEN);
    if (accessToken) {
      const base64Url = accessToken.split('.')[1];
      const base64 = base64Url.replace('-', '+').replace('_', '/');
      const jwt = JSON.parse(window.atob(base64));

      return jwt.exp - new Date().getTime() / 1000;
    }

    return null;
  }
}

export default new CookieService();
