import React, { useEffect } from 'react';
import { injectIntl } from "react-intl";

const Control = props => {
    useEffect(() => {

    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = "/ECMv6/api/rdpgateway/playbackwithevents.css";
    link.media = "print";
    link.onload = function () {
      this.media = 'all';
    };
    document.head.appendChild(link);

    const scripts = [
        {
          src: '/ECMv6/api/rdpgateway/guacamole-common-js/all.js',
        },
        {
          src: '/ECMv6/api/rdpgateway/playback.js',
        },
        {
          src: '/ECMv6/api/sshgateway/saviyntconnect/emulator/hterm_all.js',
        },
        {
          src: '/ECMv6/api/sshgateway/saviyntconnect/emulator/socket.io/socket.io.js',
        },
        {
          src: '/ECMv6/api/sshgateway/saviyntconnect/emulator/live-v2.js',
        },
      ];
      scripts.forEach(data => {
        const script1 = document.createElement('script');
        script1.src = data.src;
        script1.async = true;
        document.body.appendChild(script1);
      });

    }, []);
    return(<></>);
}
export default injectIntl(Control);
