import React, { useState, useEffect, useRef } from 'react';
import { isBefore } from 'date-fns';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import DateFnsUtils from '@date-io/date-fns';
import { getSelectedLanguageObject } from 'ui-common/src/lib/utils';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { Event } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import moment from 'moment';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'flex-end',
    border: '1px solid #e8e8e8',
    marginRight: '15px',
    borderRadius: '5px',
    padding: '1px',
  },
  field: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    alignSelf: 'flex-end',
    height: '100%',
  },
  error: {
    color: 'red',
  },
  leftArrow: {
    color: '#374eff',
    position: 'absolute',
    marginLeft: '500px',
  },
  rightArrow: {
    color: '#374eff',
  },
}));

const helperTextStyles = makeStyles(() => ({
  root: {
    color: 'var(--red) !important',
    position: 'absolute',
    top: '40px',
    minWidth: '220px',
  },
}));

const toolbarStyles = makeStyles(() => ({
  formControl: {
    maxWidth: '60px',
    zIndex: 2,
    position: 'absolute',
    marginLeft: '20px',
    marginTop: '14px',
    width: '100%',
    background: 'white',
  },
}));

const DatePickerComponent = ({
  placeholder,
  _handleChange,
  attributeKey,
  value,
  showError,
  required,
  readOnly,
  minDate,
  maxDate,
  scrollToError,
  isExisting,
}) => {
  const classes = useStyles();
  const helperTestClasses = helperTextStyles();
  const inputTextFieldRef = useRef(null);
  const [defValue, setDefaultValue] = useState(value || null);
  const [selYear, setSelYear] = useState(2021);
  const DATE_FORMAT = useSelector(state => state.user.headerMenu.ecmDateFormat);
  const handleDateChange = (date, yr) => {
    _handleChange(date, attributeKey);
    setDefaultValue(date);
  };

  useEffect(() => {
    setDefaultValue(value);
  }, [value]);

  const Toolbar = ({ year, yearOptions }) => {
    const classesToolbar = toolbarStyles();
    const handleYearChange = e => {
      const yr = Number(e.target.value);
      setSelYear(yr);
      if (selYear === 'infinity') {
        // infinity case here
      } else {
        const newDate = new Date();
        newDate.setFullYear(yr);
        handleDateChange(newDate, yr);
      }
    };
    return (
      <FormControl className={classesToolbar.formControl}>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selYear}
          disabled={readOnly}
          onChange={handleYearChange}
          disableUnderline
          IconComponent={ExpandMoreIcon}
        >
          {yearOptions.map(item => {
            return <MenuItem value={item}>{item}</MenuItem>;
          })}
        </Select>
      </FormControl>
    );
  };

  Toolbar.propTypes = {
    yearOptions: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
    year: PropTypes.string.isRequired,
  };

  useEffect(() => {
    if (scrollToError === 'true') {
      inputTextFieldRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [scrollToError]);
  return (
    <div className={classes.root} ref={inputTextFieldRef}>
      <div className={classes.field}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getSelectedLanguageObject()}>
          <Grid container>
            <DatePicker
              autoOk
              autoComplete="off"
              error={required ? showError : false}
              format={DATE_FORMAT}
              margin="dense"
              id="date-picker-inline"
              value={defValue}
              FormHelperTextProps={{ classes: helperTestClasses }}
              InputAdornmentProps={{ position: 'end', edge: 'end' }}
              InputProps={
                !placeholder
                  ? ''
                  : {
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton edge="end">
                            <Event />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }
              }
              onChange={date => {
                return readOnly ? undefined : handleDateChange(date);
              }}
              fullWidth
              disabled={readOnly}
              disablePast={readOnly}
              disableFuture={readOnly}
              minDate={
                minDate && attributeKey === 'startDate' && isBefore(defValue, minDate)
                  ? defValue
                  : minDate
              }
              maxDate={maxDate}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              label={
                <>
                  <span dangerouslySetInnerHTML={{ __html: placeholder }} />
                  {required && <span className="mandatory">&nbsp;*</span>}
                </>
              }
              variant="static"
              ToolbarComponent={props => (
                <Toolbar
                  year={moment(defValue).get('year')}
                  yearOptions={['2021', '2022', '2023', '2024', '2025']}
                />
              )}
              leftArrowIcon={<ChevronLeftIcon className={classes.leftArrow} />}
              rightArrowIcon={<ChevronRightIcon className={classes.rightArrow} />}
              leftArrowButtonProps={{ 'aria-label': 'Prev month' }}
              rightArrowButtonProps={{ 'aria-label': 'Next month' }}
            />
          </Grid>
        </MuiPickersUtilsProvider>
      </div>
    </div>
  );
};

DatePickerComponent.propTypes = {
  _handleChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  attributeKey: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  showError: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  minDate: PropTypes.instanceOf(Date).isRequired,
  maxDate: PropTypes.instanceOf(Date).isRequired,
  scrollToError: PropTypes.bool.isRequired,
  isExisting: PropTypes.bool.isRequired,
};

export default DatePickerComponent;
