import React, { useState } from 'react';
import { useLocalize } from '@saviynt/common';
import { Icon, TabContent, TabGroup, TabItem } from '@saviynt/design-system';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { useTenantLabel } from '../../../../../hooks/use-labels';
import { mockSessions } from '../../../../../models/MockData/MockData';
import { ModuleDetails } from '../../../../../models/PamModels';
import Scheduler from '../../../../Scheduler/Scheduler';
import { msgs } from '../../../constants';
import SpvSection from '../../SpvSection/SpvSection';

import './SpvAccounts.css';

function SpvAccounts({ data, dataTestId, className }) {
  const classes = classnames('SpvAccounts', className);
  const localize = useLocalize();

  const cloudProvider = data.connectionType?.toUpperCase();

  const [activeTab, setActiveTab] = useState('1');

  const handleChange = (value) => {
    setActiveTab(value);
  };

  const hasAccountSummaryFields = data.connectionType || data.description;

  const hasAccountSystemDetailsFields = data.accountType || data.accountKey;

  const hasAssetSummaryFields =
    data.connectionType || data.customproperty10 || data.accountType;

  const hasAssetSystemDetailsFields =
    data.accountKey || data.customproperty5 || data.connectionType;

  return (
    <section className={classes} data-testid={dataTestId}>
      <TabGroup
        className='SpvAccounts-tabGroup'
        value={activeTab}
        onChange={handleChange}>
        <TabItem
          label={localize(msgs.pam.sidePanel.account)}
          value='1'
          icon={<Icon kind='Account' size='medium' color='neutral-1000' />}
        />
        <TabItem
          label={localize(msgs.pam.sidePanel.asset)}
          value='2'
          icon={
            <Icon kind='AssetTargetSystem' size='medium' color='neutral-1000' />
          }
        />
        <TabItem
          label={localize(msgs.pam.sidePanel.schedule)}
          value='3'
          icon={<Icon kind='Calendar' size='medium' color='neutral-1000' />}
        />
      </TabGroup>
      <TabContent
        className='SpvAccounts-tabContent'
        value={activeTab}
        tabValue='1'>
        {hasAccountSummaryFields && (
          <SpvSection
            title={localize(msgs.pam.sidePanel.summary)}
            fields={[
              {
                label: localize(msgs.pam.sidePanel.platform),
                value: data.connectionType,
              },
              {
                label: localize(msgs.pam.sidePanel.description),
                value: data.description,
              },
            ]}
          />
        )}
        {hasAccountSystemDetailsFields && (
          <SpvSection
            title={localize(msgs.pam.sidePanel.systemDetails)}
            fields={[
              {
                label: localize(msgs.pam.sidePanel.accountType),
                value: data.accountType,
              },
              {
                label: localize(msgs.pam.sidePanel.authenticationMechanism),
                value: data.accountKey ? 'Key' : null,
              },
            ]}
            className='SpvAccounts-tabSection'
          />
        )}
      </TabContent>
      <TabContent
        className='SpvAccounts-tabContent'
        value={activeTab}
        tabValue='2'>
        {hasAssetSummaryFields && (
          <SpvSection
            title={localize(msgs.pam.sidePanel.summary)}
            fields={[
              {
                label: localize(msgs.pam.sidePanel.platform),
                value: data.connectionType,
              },
              {
                label: localize(msgs.pam.sidePanel.location),
                value: data.customproperty10,
              },
              {
                label: localize(msgs.pam.sidePanel.accountType),
                value: data.accountType,
              },
            ]}
          />
        )}
        {hasAssetSystemDetailsFields && (
          <SpvSection
            title={localize(msgs.pam.sidePanel.systemDetails)}
            fields={[
              {
                label: localize(msgs.pam.sidePanel.id),
                value: data.accountName,
              },
              {
                label: useTenantLabel(cloudProvider),
                value: data.accountKey ? 'Key' : null,
              },
              {
                // TODO: Temp mapping; real mapped field unknown; needs update
                label: localize(msgs.pam.sidePanel.instanceOS),
                value: data.accountKey,
              },
              {
                label: localize(msgs.pam.sidePanel.environment),
                value: data.customproperty5,
              },
              {
                label: localize(msgs.pam.sidePanel.network),
                value: data.connectionType,
              },
            ]}
            className='SpvAccounts-tabSection'
          />
        )}
      </TabContent>
      <TabContent
        className='SpvAccounts-tabContent'
        value={activeTab}
        tabValue='3'>
        <Scheduler
          sessions={mockSessions}
          className='SpvAccounts-Scheduler'
          timeFormat={{ roundToNearest: 'minute', is24HourFormat: false }}
          showRequestId={false}
        />
      </TabContent>
    </section>
  );
}

SpvAccounts.propTypes = {
  data: ModuleDetails,
  dataTestId: PropTypes.string,
  className: PropTypes.string,
};

SpvAccounts.defaultProps = {
  data: null,
  dataTestId: null,
  className: null,
};

export default SpvAccounts;
