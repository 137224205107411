import React from 'react';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import Button from '@material-ui/core/Button';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Tooltip from '../Tooltip/Tooltip';
import styles from './Download.module.css';
import { defaultConfig } from '../../../reducers/logsReducer';
import CSVDownload from '../CSVDownload/CSVDownload';
import getDataFromApi from '../../../utils/getDataFromApi';

const Download = ({ isDisplayed, intl }) => {
  const query = useSelector((state) => state.admin?.logs.query);
  const asyncFnComputeDate = () => Promise.resolve(getDataFromApi({
    ...query,
    recordsCount: defaultConfig.noOfRecordsToDownload,
  }));

  if (isDisplayed) {
    return (
      <div className={styles.downloadButtonContainer}>
        <Tooltip
          title={intl.formatMessage({ id: 'Admin.Log.Viewer.downloadToolTip' })}
          aria-label="Download records"
        >
          <Button color="primary" startIcon={<SaveAltIcon />}>
            <CSVDownload data={asyncFnComputeDate} intl={intl} />
          </Button>
        </Tooltip>
      </div>
    );
  }

  return null;
};

export default injectIntl(Download);
