import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import MenuWrapper from '../MenuItem/MenuWrapper';
import ToggleButton from '../../ToggleButton/ToggleButton';

import useFullscreen from './useFullscreen';

export const Fullscreen = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.1598 4.50013L19.2949 4.49927L12.9817 10.8124C12.7865 11.0077 12.7865 11.3243 12.9817 11.5195C13.177 11.7148 13.4936 11.7148 13.6888 11.5195L19.9998 5.20858L19.999 9.33929C19.9989 9.61544 20.2228 9.83934 20.4989 9.83939C20.775 9.83945 20.9989 9.61563 20.999 9.33949L21 3.99912C21.0001 3.86648 20.9474 3.73926 20.8536 3.64547C20.7598 3.55168 20.6326 3.499 20.4999 3.49902L15.1596 3.50013C14.8835 3.50019 14.6597 3.7241 14.6597 4.00024C14.6598 4.27638 14.8837 4.50019 15.1598 4.50013Z"
      fill="#00245B"
    />
    <path
      d="M11.0183 14.1882C11.2136 13.9929 11.2136 13.6763 11.0183 13.4811C10.823 13.2858 10.5065 13.2858 10.3112 13.4811L4.00024 19.792L4.00104 15.6613C4.00109 15.3852 3.77728 15.1613 3.50114 15.1612C3.22499 15.1612 3.00109 15.385 3.00104 15.6611L3 21.0015C2.99997 21.1341 3.05266 21.2614 3.14645 21.3552C3.24024 21.449 3.36746 21.5016 3.5001 21.5016L8.8404 21.5005C9.11654 21.5004 9.34035 21.2765 9.3403 21.0004C9.34024 20.7242 9.11634 20.5004 8.84019 20.5005L4.70514 20.5014L11.0183 14.1882Z"
      fill="#00245B"
    />
  </svg>
);

const useStyles = makeStyles(() => ({
  iconContainer: {
    marginRight: 10,
    minWidth: 0,
  },
}));

const FullScreen = ({ intl }) => {
  const classes = useStyles();
  const [isFullscreen, setIsFullscreen] = useFullscreen();

  const onMenuClick = () => {
    if (isFullscreen) {
      document.exitFullscreen();
    }

    setIsFullscreen();
  };

  return (
    <MenuWrapper onMenuClick={onMenuClick}>
      <span className={classes.iconContainer}>
        <Fullscreen />
      </span>
      <ToggleButton
        onToggleChange={() => {}}
        checked={isFullscreen}
        label={intl.formatMessage({
          id: 'gen2.profileMenu.label.fullScreen',
        })}
        position="left"
      />
    </MenuWrapper>
  );
};

FullScreen.propTypes = {
  intl: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string.isRequired,
      formatMessage: PropTypes.func.isRequired,
    }),
  ).isRequired,
};
export default injectIntl(FullScreen);
