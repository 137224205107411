/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable react/jsx-wrap-multilines */
import '../ModalPageListIsland.css';

import { localize } from '@saviynt/common';
import {
  Box,
  Button,
  FooterActionBar,
  Icon,
  InlineMessage,
  InputField,
  ModalIsland,
  RadioButton,
  Typography,
} from '@saviynt/design-system';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { AppLauncherSelectionModel } from '../../../../models/PamModels';
import { msgs } from '../../constants';

function RemoteAppsListModalIsland({
  remoteAppListArray,
  remoteAppListModalContentRef,
  sectionRefIsScrolled,
  appLauncherSelection,
  setAppLauncherSelection,
  isRemoteAppListModal,
  setIsRemoteAppListModal,
}) {
  const [searchValue, setSearchValue] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [modalAppSelection, setModalAppSelection] = useState(null);
  const intl = useIntl();

  const BTN_SAVE_TEXT = localize(intl, msgs.pam.common.save);
  const BTN_CANCEL_TEXT = localize(intl, msgs.pam.common.cancel);
  const ISLAND_HEADING_TEXT = localize(
    intl,
    msgs.pam.modalPage.accordionHeading.appLauncherHeader
  );

  useEffect(() => {
    setModalAppSelection(appLauncherSelection);
  }, [appLauncherSelection]);

  useEffect(() => {
    setFilteredData(remoteAppListArray);
  }, [remoteAppListArray]);

  useEffect(() => {
    setSearchValue('');
  }, [isRemoteAppListModal]);

  useEffect(() => {
    const searchString = searchValue.toLowerCase();

    setSearchValue(searchString);

    const filteredArray = remoteAppListArray?.filter(
      (item) =>
        item.key.toLowerCase().includes(searchString) ||
        item.description.toLowerCase().includes(searchString)
    );

    setFilteredData(filteredArray);
  }, [searchValue]);

  const handleClick = (e, value) => {
    e.stopPropagation();
    setModalAppSelection(value);
  };

  const handleCancel = () => {
    setIsRemoteAppListModal(false);
    setModalAppSelection(appLauncherSelection);
  };

  const handleSave = () => {
    setIsRemoteAppListModal(false);
    setAppLauncherSelection(modalAppSelection);
  };

  const getModalIslandContent = () => (
    <Box className='ModalPageListIsland-modalIslandContent'>
      <Box className='ModalPageListIsland-modalIslandContent-search'>
        <InputField
          name='modalIsland-search'
          kind='search'
          placeholder='Search by application name or description'
          prefixIcon={<Icon kind='search' size='smallMedium' />}
          value={searchValue}
          setValue={setSearchValue}
        />
      </Box>
      {filteredData?.length === 0 || !filteredData ? (
        <InlineMessage>
          <Box className='ModalPageListIsland-noMatchingSearchRecords'>
            <Icon kind='alertCriticalOutline' />
            <Typography
              kind='h3'
              className='ModalPageListIsland-noMatchingSearchRecords-title'>
              Your search doesn&apos;t match any records
            </Typography>
          </Box>
        </InlineMessage>
      ) : (
        <Box className='ModalPageListIsland-radioButtons'>
          {filteredData?.map((app, index) => (
            <RadioButton
              key={`appLauncher-${app.key}`}
              id={`appLauncher-${index}`}
              name='appLauncherListRadioGroup'
              title={app.displayName}
              description={app.description}
              value={app}
              radioSelection={modalAppSelection}
              onClick={handleClick}
              isTruncated
            />
          ))}
        </Box>
      )}
    </Box>
  );

  const modalIslandFooterActionBar = () => (
    <Box className='ModalPageListIsland-footerActionBar'>
      <Button kind='outlined' size='medium' onClick={() => handleCancel()}>
        {BTN_CANCEL_TEXT}
      </Button>
      <Button
        isDisabled={!modalAppSelection}
        kind='filled'
        size='medium'
        onClick={() => handleSave()}>
        {BTN_SAVE_TEXT}
      </Button>
    </Box>
  );

  return (
    <ModalIsland
      className='ModalPageListIsland'
      onClose={() => setIsRemoteAppListModal(false)}
      isOpen={isRemoteAppListModal}
      headerIcon={<Icon kind='privilegedSession' size='large' />}
      title={ISLAND_HEADING_TEXT}
      contentBody={getModalIslandContent()}
      sectionRef={remoteAppListModalContentRef}
      FooterActionBarComp={
        <FooterActionBar size='large' isScrolled={sectionRefIsScrolled}>
          {modalIslandFooterActionBar()}
        </FooterActionBar>
      }
    />
  );
}

RemoteAppsListModalIsland.propTypes = {
  remoteAppListArray: PropTypes.arrayOf(AppLauncherSelectionModel),
  remoteAppListModalContentRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  sectionRefIsScrolled: PropTypes.bool,
  appLauncherSelection: AppLauncherSelectionModel,
  setAppLauncherSelection: PropTypes.func.isRequired,
  isRemoteAppListModal: PropTypes.bool.isRequired,
  setIsRemoteAppListModal: PropTypes.func.isRequired,
};

RemoteAppsListModalIsland.defaultProps = {
  remoteAppListArray: null,
  remoteAppListModalContentRef: null,
  sectionRefIsScrolled: null,
  appLauncherSelection: null,
};

export default RemoteAppsListModalIsland;
