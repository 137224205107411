import React, { useEffect, useState } from 'react';
import { getCurrentDate } from '@saviynt/common';
import { addOrSubtractMinutesFromDateTime } from '@saviynt/common/src/utilities/date';
import { Box } from '@saviynt/design-system';
import classnames from 'classnames';
import {
  addHours,
  endOfDay,
  isAfter,
  isBefore,
  isEqual,
  isSameHour,
  isToday,
  subMinutes,
} from 'date-fns';
import PropTypes from 'prop-types';

import { Session } from '../../../models/PamModels';
import {
  getScheduleBlock,
  getSessionDetails,
  isLongTermReservation,
  isSameTime,
  isSchedulerDayToday,
  isStillReserved,
  isSubsequentSessionBlock,
} from '../helpers';

const neutralDay = getCurrentDate();

neutralDay.setHours(0, 0, 0, 0);

function SchedulerDay({
  sessions,
  availableStartDate,
  availableHours,
  timeFormat,
  isModalView,
  dataTestId,
  className,
  filters,
  currentDate,
  showRequestId,
}) {
  const classes = classnames('SchedulerDay', className);

  const [filteredSchedule, setFilteredSchedule] = useState([]);

  // NEUTRAL DATETIME STRINGS
  const constructedNeutralStartTime = new Date(availableStartDate);
  const neutralStartTimeString = constructedNeutralStartTime.toString(); // 12:00AM

  let constructedNeutralEndTime;

  // TODO: need to determine if available hours prop
  //  should be removed and default window should be 24 hrs
  // check if available duration hours are provided
  if (availableHours) {
    constructedNeutralEndTime = addHours(
      constructedNeutralStartTime,
      availableHours
    );

    // adjust available end time to be 11:59PM rather than 12:00AM
    // if 24 hours is provided for available duration hours
    if (availableHours === 24) {
      constructedNeutralEndTime = subMinutes(constructedNeutralEndTime, 1);
    }
  } else {
    // default available end time to 11:59PM if available duration hours are not provided
    constructedNeutralEndTime = endOfDay(constructedNeutralStartTime);
  }

  const neutralEndTimeString = constructedNeutralEndTime.toString();

  // TODAY
  const now = getCurrentDate();
  const nowString = now.toString();

  const getAvailableBlock = (startTime, endTime) =>
    getScheduleBlock(
      null,
      startTime,
      endTime,
      timeFormat,
      isModalView,
      showRequestId
    );

  const getReservedBlock = (sessionDetails, startTime, endTime) =>
    getScheduleBlock(
      sessionDetails,
      startTime,
      endTime,
      timeFormat,
      isModalView,
      showRequestId
    );

  // APPLY MODAL STATUS FILTERS
  const filteredDay = () => {
    const scheduleBlocksArray = [];

    // DAY HAS NO SESSIONS
    if (sessions.length === 0) {
      if (isToday(currentDate)) {
        scheduleBlocksArray.push(
          // available
          getAvailableBlock(nowString, neutralEndTimeString)
        );
      } else {
        scheduleBlocksArray.push(
          // available
          getAvailableBlock(neutralStartTimeString, neutralEndTimeString)
        );
      }
    }

    // DAY HAS SESSIONS
    else {
      const sortFunction = (a, b) =>
        new Date(a.sessionStartDate) - new Date(b.sessionStartDate);

      // Ensure that sessions are sorted by sessionStartDate in chronological order
      sessions.sort(sortFunction);

      /* ******************************
       * ******* FIRST SESSION ********
       * **************************** */

      // check first session and set first block
      const firstSession = sessions[0];
      const firstSessionStartString = firstSession.sessionStartDate;
      const firstSessionEndString = firstSession.sessionEndDate;
      const firstSessionStartDate = new Date(firstSessionStartString);
      const firstSessionEndDate = new Date(firstSessionEndString);

      const firstSessionStartTimeAdjusted = addOrSubtractMinutesFromDateTime(
        firstSessionStartDate,
        'subtract',
        1
      ).toString();

      // check that first session does not start at start of day 12:00AM UTC
      const isFirstSessionNotAtStartOfDay = !isEqual(
        firstSessionStartDate,
        constructedNeutralStartTime
      );

      // check first session for multi-day/long term reservation
      if (isLongTermReservation(firstSession)) {
        if (
          isSameHour(firstSessionStartDate, constructedNeutralStartTime) &&
          isStillReserved(currentDate, firstSession)
        ) {
          scheduleBlocksArray.push(
            // reserved
            getReservedBlock(
              getSessionDetails(firstSession),
              neutralStartTimeString,
              neutralEndTimeString
            )
          );
        } else {
          scheduleBlocksArray.push(
            // available
            getAvailableBlock(
              neutralStartTimeString,
              firstSessionStartTimeAdjusted
            ),
            // reserved
            getReservedBlock(
              getSessionDetails(firstSession),
              firstSessionStartString,
              isStillReserved(currentDate, firstSession)
                ? neutralEndTimeString
                : firstSessionEndString
            )
          );
        }
      }

      // Start sessions from NOW by checking first session
      else if (isSchedulerDayToday(firstSessionStartDate)) {
        if (isBefore(now, firstSessionStartDate)) {
          scheduleBlocksArray.push(
            // available
            getAvailableBlock(nowString, firstSessionStartTimeAdjusted),
            // reserved
            getReservedBlock(
              getSessionDetails(firstSession),
              firstSessionStartString,
              firstSessionEndString
            )
          );
        } else if (isBefore(now, firstSessionEndDate)) {
          scheduleBlocksArray.push(
            // reserved
            getReservedBlock(
              getSessionDetails(firstSession),
              nowString,
              firstSessionEndString
            )
          );
        }
      }

      // every other day aside from NOW
      // check that first session does not start at start of day 12:00AM UTC
      else if (isFirstSessionNotAtStartOfDay) {
        scheduleBlocksArray.push(
          // available
          getAvailableBlock(
            neutralStartTimeString,
            firstSessionStartTimeAdjusted
          ),
          // reserved
          getReservedBlock(
            getSessionDetails(firstSession),
            firstSessionStartString,
            firstSessionEndString
          )
        );
      } else {
        scheduleBlocksArray.push(
          // reserved
          getReservedBlock(
            getSessionDetails(firstSession),
            firstSessionStartString,
            firstSessionEndString
          )
        );
      }

      /* ******************************
       * ***** EVERY OTHER SESSION ****
       * ******* ASIDE FROM FIRST *****
       * *********** AND LAST *********
       * **************************** */

      // check the rest of the sessions and set the rest of the blocks
      for (let i = 1; i < sessions.length; i += 1) {
        // current session
        const currentSession = sessions[i];
        const currentSessionStartString = currentSession.sessionStartDate;
        const currentSessionEndString = currentSession.sessionEndDate;
        const currentSessionStartDate = new Date(currentSessionStartString);
        const currentSessionEndDate = new Date(currentSessionEndString);

        // current session start time +1 minute
        const currentSessionStartTimeAdjusted =
          addOrSubtractMinutesFromDateTime(
            currentSessionStartDate,
            'subtract',
            1
          ).toString();

        // previous session
        const previousSession = sessions[i - 1];
        const previousSessionEndDate = new Date(previousSession.sessionEndDate);

        // previous session end time +1 minute
        const previousSessionEndTimeAdjusted = addOrSubtractMinutesFromDateTime(
          previousSessionEndDate,
          'add',
          1
        ).toString();

        // current session end time +1 minute
        const currentSessionEndTimeAdjusted = addOrSubtractMinutesFromDateTime(
          currentSessionEndDate,
          'add',
          1
        ).toString();

        // Start sessions from NOW by checking first session
        // by checking the rest of the sessions
        if (isSchedulerDayToday(currentSessionStartDate)) {
          if (isBefore(now, currentSessionStartDate)) {
            scheduleBlocksArray.push(
              // available
              getAvailableBlock(
                isAfter(now, previousSessionEndDate)
                  ? nowString
                  : previousSessionEndTimeAdjusted,
                currentSessionStartTimeAdjusted
              ),
              // reserved
              getReservedBlock(
                getSessionDetails(currentSession),
                currentSessionStartString,
                currentSessionEndString
              )
            );
          } else if (isBefore(now, currentSessionEndDate)) {
            scheduleBlocksArray.push(
              // reserved
              getReservedBlock(
                getSessionDetails(currentSession),
                nowString,
                currentSessionEndString
              )
            );
          }
        }

        // every other day aside from NOW
        // sequence sessions and build scheduler blocks
        else if (isAfter(currentSessionStartDate, previousSessionEndDate)) {
          if (
            isSubsequentSessionBlock(
              currentSessionStartDate,
              previousSessionEndDate
            )
          ) {
            scheduleBlocksArray.push(
              // reserved
              getReservedBlock(
                getSessionDetails(currentSession),
                currentSessionStartString,
                currentSessionEndString
              )
            );
          } else {
            scheduleBlocksArray.push(
              // available
              getAvailableBlock(
                previousSessionEndTimeAdjusted,
                currentSessionStartTimeAdjusted
              ),
              // reserved
              getReservedBlock(
                getSessionDetails(currentSession),
                currentSessionStartString,
                currentSessionEndString
              )
            );
          }
        } else {
          scheduleBlocksArray.push(
            // reserved
            getReservedBlock(
              getSessionDetails(currentSession),
              currentSessionStartString,
              currentSessionEndString
            ),
            // available
            getAvailableBlock(
              currentSessionEndTimeAdjusted,
              currentSessionStartTimeAdjusted
            )
          );
        }
      }

      /* ******************************
       * ******** LAST SESSION ********
       * **************************** */

      // set last block as available if last session end time is before day end time
      const lastSession = sessions[sessions.length - 1];
      const lastSessionEndString = lastSession.sessionEndDate;
      const lastSessionEndDate = new Date(lastSessionEndString);

      // last session end time +1 minute
      const lastSessionEndTimeAdjusted = addOrSubtractMinutesFromDateTime(
        lastSessionEndDate,
        'add',
        1
      ).toString();

      // check that last session does not end at end of day 12:00AM UTC
      const isLastSessionNotAtEndOfDay = !isSameTime(
        lastSessionEndDate,
        constructedNeutralEndTime
      );

      // check NOW/Today
      if (isSchedulerDayToday(lastSessionEndDate)) {
        if (isAfter(now, lastSessionEndDate)) {
          scheduleBlocksArray.push(
            // available
            getAvailableBlock(nowString, neutralEndTimeString)
          );
        } else if (isBefore(lastSessionEndDate, constructedNeutralEndTime)) {
          // check that last session does not end at end of day 12:00AM UTC
          if (isLastSessionNotAtEndOfDay) {
            scheduleBlocksArray.push(
              // available
              getAvailableBlock(
                lastSessionEndTimeAdjusted,
                neutralEndTimeString
              )
            );
          }
        }
      }

      // every other day aside from NOW
      else if (isBefore(lastSessionEndDate, constructedNeutralEndTime)) {
        // check that last session does not end at end of day 12:00AM UTC
        if (isLastSessionNotAtEndOfDay) {
          scheduleBlocksArray.push(
            // available
            getAvailableBlock(lastSessionEndTimeAdjusted, neutralEndTimeString)
          );
        }
      }
    }

    if (filters.length === 0) {
      return scheduleBlocksArray;
    }

    return scheduleBlocksArray.filter((scheduleBlock) =>
      filters.some((option) => scheduleBlock.props.status === option)
    );
  };

  useEffect(() => {
    setFilteredSchedule(filteredDay());
  }, [filters]);

  const renderDay = (schedule) =>
    schedule.map((block, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <div key={`${block.props.status}-${index}`}>{block}</div>
    ));

  return (
    <Box dataTestId={dataTestId} className={classes}>
      {filters.length === 0
        ? renderDay(filteredDay())
        : renderDay(filteredSchedule)}
    </Box>
  );
}

SchedulerDay.propTypes = {
  sessions: PropTypes.arrayOf(Session).isRequired,
  availableStartDate: PropTypes.instanceOf(Date),
  availableHours: PropTypes.number,
  timeFormat: PropTypes.shape({
    roundToNearest: PropTypes.string,
    is24HourFormat: PropTypes.bool,
  }).isRequired,
  isModalView: PropTypes.bool,
  dataTestId: PropTypes.string,
  className: PropTypes.string,
  filters: PropTypes.arrayOf(PropTypes.string),
  currentDate: PropTypes.instanceOf(Date),
  showRequestId: PropTypes.bool,
};

SchedulerDay.defaultProps = {
  availableStartDate: neutralDay,
  availableHours: null,
  isModalView: false,
  dataTestId: null,
  className: null,
  filters: [],
  currentDate: null,
  showRequestId: true,
};

export default SchedulerDay;
