const messages = {
  'UI.Gen2.Admin.Branding.Theme.Creation.Error.Title': 'Theme creation failed!',
  'UI.Gen2.Admin.Branding.Theme.Update.Error.Title': 'Theme update failed!',
  'UI.Gen2.Admin.Branding.Theme.Update.Success.Title': 'Theme successfully updated!',
  'UI.Gen2.Admin.Branding.Theme.Activate.Error.Title': 'Theme activation failed!',
  'UI.Gen2.Admin.Branding.Theme.Activate.Success.Title': 'Theme successfully activated!',

  'UI.Gen2.Admin.Branding.Theme.SideBar.Section.Theme.Header': 'Create Theme',
  'UI.Gen2.Admin.Branding.Theme.SideBar.Section.ThemeOption': 'Theme Options',
  'UI.Gen2.Admin.Branding.Theme.SideBar.Section.ThemeOption.Dark': 'Dark',
  'UI.Gen2.Admin.Branding.Theme.SideBar.Section.ThemeOption.Light': 'Light',
  'UI.Gen2.Admin.Branding.Theme.SideBar.Section.Colour.Title': 'Navigation Menu Colors',
  'UI.Gen2.Admin.Branding.Theme.SideBar.Section.Colour.Description': 'You can change the look of the interactive elements of the navigation. Pick a color set that suits your brand.',
  'UI.Gen2.Admin.Branding.Theme.SideBar.Section.Logo.Navigation.Title': 'Navigation Logo',
  'UI.Gen2.Admin.Branding.Theme.SideBar.Section.Logo.Navigation.Description': 'The logo that was uploaded on the “Logos” page will be displayed. If no logo is uploaded, a Saviynt logo will be displayed. You can change it if needed.',
  'UI.Gen2.Admin.Branding.Theme.SideBar.Section.Logo.Header.Title': 'Header Logo',
  'UI.Gen2.Admin.Branding.Theme.SideBar.Section.Logo.Header.Description': 'If no logo is uploaded on the “Logos” page, a Saviynt logo will be displayed in the header. You can change it if needed.',
  'UI.Gen2.Admin.Branding.Theme.SideBar.Section.Theme.Name.Title': 'Theme Name',
  'UI.Gen2.Admin.Branding.Theme.SideBar.Section.Theme.Footer.Button.Delete': 'Delete Theme',
  'UI.Gen2.Admin.Branding.Theme.SideBar.Section.Theme.Footer.Button.Preview': 'Preview',
  'UI.Gen2.Admin.Branding.Theme.SideBar.Section.Theme.Footer.Button.Create': 'Create Theme',
  'UI.Gen2.Admin.Branding.Theme.SideBar.Section.Theme.Footer.Button.Save': 'Save Theme',
  'UI.Gen2.Admin.Branding.Theme.SideBar.Section.Theme.Footer.Button.Activate': 'Activate',
  'UI.Gen2.Admin.Branding.Theme.SideBar.Section.Theme.Footer.Active.Label': 'This Theme is Active',

  'UI.Gen2.Admin.Branding.Header': 'Branding',
  'UI.Gen2.Admin.Branding.Description': 'Customize the appearance of the Saviynt platform to complement your company’s brand.',
  'UI.Gen2.Admin.Branding.Theme.Type.Custom': 'Custom Themes',
  'UI.Gen2.Admin.Branding.Theme.Type.OOTB': 'Saviynt Themes',

  'UI.Gen2.Admin.Branding.Logo.BrandLogo.Header': 'Your Brand Logo',
  'UI.Gen2.Admin.Branding.Logo.BrandLogo.Description': 'Upload your primary brand logo that will be displayed in prominent areas of the interface.',
  'UI.Gen2.Admin.Branding.Logo.LoginLogout.Header': 'Your Login/Logout Logo',
  'UI.Gen2.Admin.Branding.Logo.LoginLogout.Description': 'Upload your login logout page logo that will be displayed in prominent areas of the interface.',
  'UI.Gen2.Admin.Branding.Logo.FavIcon.Header': 'Favicon',
  'UI.Gen2.Admin.Branding.Logo.FavIcon.Description': 'Customize the image that will help your users find your website more easily among multiple browser tabs, bookmarks and more.',
  'UI.Gen2.Admin.Branding.Logo.BrandLogo.Button': 'Select Logo',

  'UI.Gen2.Admin.Branding.Theme.Modal.Alert.Activate.Title': 'Your Theme Has Been Created',
  'UI.Gen2.Admin.Branding.Theme.Modal.Alert.Activate.Description': 'Would you like to activate your theme now so your users can start experiencing it?',
  'UI.Gen2.Admin.Branding.Theme.Modal.Alert.Activate.Button.Secondary': 'Not Now',
  'UI.Gen2.Admin.Branding.Theme.Modal.Alert.Activate.Button.Primary': 'Activate',
  'UI.Gen2.Admin.Branding.Theme.Modal.Alert.Delete.Title': 'Delete Theme',
  'UI.Gen2.Admin.Branding.Theme.Modal.Alert.Delete.Description': 'Are you sure you want to delete ###ACTIVE_THEME### from your custom themes? This can’t be undone.',
  'UI.Gen2.Admin.Branding.Theme.Modal.Alert.Delete.Button.Primary': 'Yes, Delete it',
  'UI.Gen2.Admin.Branding.Theme.Modal.Alert.Delete.Button.Secondary': 'Cancel',
  'UI.Gen2.Admin.Branding.Theme.Modal.Alert.Unsaved.Title': 'Unsaved Changes',
  'UI.Gen2.Admin.Branding.Theme.Modal.Alert.Unsaved.Description': 'You’ve made changes to the ###ACTIVE_THEME### theme that haven’t been saved. Do you want to save or discard your changes?',
  'UI.Gen2.Admin.Branding.Theme.Modal.Alert.Unsaved.Button.Primary': 'Yes, Discard Changes',
  'UI.Gen2.Admin.Branding.Theme.Modal.Alert.Unsaved.Button.Secondary': 'Cancel',
  'UI.Gen2.Admin.Branding.Theme.Modal.Alert.createDiscard.Title': 'Unsaved Changes',
  'UI.Gen2.Admin.Branding.Theme.Modal.Alert.createDiscard.Description': 'You’ve made changes to the new theme that haven’t been saved. Do you want to save or discard your changes?',
  'UI.Gen2.Admin.Branding.Theme.Modal.Alert.SelectLogo.Title': 'Select Logo',
  'UI.Gen2.Admin.Branding.Theme.Modal.Alert.SelectLogo.Description': 'You’ve made changes to the ###ACTIVE_THEME### theme that haven’t been saved. Do you want to save or discard your changes?',
  'UI.Gen2.Admin.Branding.Theme.Modal.Alert.SelectLogo.Button.Primary': 'Done',
  'UI.Gen2.Admin.Branding.Theme.Modal.Alert.SelectLogo.Button.Secondary': 'Cancel',

  'UI.Gen2.Admin.Branding.Button.SeeExample': 'See Example',
  'UI.Gen2.Admin.Branding.Theme.ThemeGrid.Active.Tooltip': 'This theme is active',

  'UI.Gen2.Admin.Branding.Theme.Modal.Example.NavColors.Title': 'Left Navigation Menu Colors',
  'UI.Gen2.Admin.Branding.Theme.Modal.Example.NavLogo.Title': 'Left Navigation Logo',
  'UI.Gen2.Admin.Branding.Theme.Modal.Example.HeaderLogo.Title': 'Header Logo',
  'UI.Gen2.Admin.Branding.Theme.Modal.Example.BrandLogo.Title': 'Brand Logo',
  'UI.Gen2.Admin.Branding.Theme.Modal.Example.FavIcon.Title': 'Favicon',
  'UI.Gen2.Admin.Branding.Theme.Modal.Example.Footer.Button': 'Close',
  'UI.Gen2.Admin.Branding.Theme.Modal.Example.NavMenuColors.BodyText': 'As users interact with the navigation, these colors highlight the different features of the product and indicate which menu items have been selected.',
  'UI.Gen2.Admin.Branding.Theme.Modal.Example.NavMenuColors.HighLight.Header': 'Keep accessibility in mind.',
  'UI.Gen2.Admin.Branding.Theme.Modal.Example.NavMenuColors.HighLight.Description': 'As you create your theme, understand that we carefully designed our preset themes to adhere to accessibility standards. Please make sure there’s enough color contrast.',
  'UI.Gen2.Admin.Branding.Theme.Modal.Example.NavLogo.BodyText': 'When the left navigation is expanded, your logo will be displayed at the top.',
  'UI.Gen2.Admin.Branding.Theme.Modal.Example.NavLogo.BodyText.Bullet1': 'Recommended image size: 75 x 24 pixels',
  'UI.Gen2.Admin.Branding.Theme.Modal.Example.NavLogo.BodyText.Bullet2': 'Recommended image format: .png, .svg, .jpg',
  'UI.Gen2.Admin.Branding.Theme.Modal.Example.NavLogo.HighLight.Description': 'Please make sure there is enough color contrast.',
  'UI.Gen2.Admin.Branding.Theme.Modal.Example.BrandLogo.BodyText': 'Your primary brand logo will appear in the header, login page, emails and more, and your users will also navigate to the homepage with it. You can also upload a secondary logo for specific areas (e.g., a white logo against the dark navigation background). A Saviynt logo will be used wherever you don’t upload one of your brand logos.',
  'UI.Gen2.Admin.Branding.Theme.Modal.Example.BrandLogo.BodyText.Bullet1': 'Recommended image size: 75 x 24 pixels',
  'UI.Gen2.Admin.Branding.Theme.Modal.Example.BrandLogo.BodyText.Bullet2': 'Recommended image format: .png, .svg, .jpg',
  'UI.Gen2.Admin.Branding.Theme.Modal.Example.BrandLogo.HighLight.Description': 'Please make sure there is enough color contrast between the logo and the background',
  'UI.Gen2.Admin.Branding.Theme.Modal.Example.FavIcon.BodyText': 'This icon represents your website and brand, and it’s usually located in small spaces like browser tabs, bookmark lists, search results and toolbars.',
  'UI.Gen2.Admin.Branding.Theme.Modal.Example.FavIcon.BodyText.Bullet1': 'Recommended image size: 16 x 16 pixels',
  'UI.Gen2.Admin.Branding.Theme.Modal.Example.FavIcon.BodyText.Bullet2': 'Recommended image format: .ico',
  'UI.Gen2.Admin.Branding.Theme.Modal.Example.WCAG.LinkText': 'See WCAG 2.1',

  // new messages to share
  'UI.Gen2.Admin.Branding.Theme.SideBar.Section.Colour.ResetTooltip': 'Reset Color',
  'UI.Gen2.Admin.Branding.Theme.Modal.Alert.CannotDelete.Title': 'You Can’t Delete Active Theme',
  'UI.Gen2.Admin.Branding.Theme.Modal.Alert.CannotDelete.Description': 'If you need to delete the ###ACTIVE_THEME### theme, you first need to activate a different theme.',
  'UI.Gen2.Admin.Branding.Theme.Modal.Alert.CannotDelete.Button.Primary': 'Close',

  'UI.Gen2.Admin.Branding.Theme.Modal.SideBar.Section.Color.Primary.Label': 'Main Menu Icons and Selections',
  'UI.Gen2.Admin.Branding.Theme.Modal.SideBar.Section.Color.Background.Label': 'Background',
  'UI.Gen2.Admin.Branding.Theme.Modal.SideBar.Section.Color.Secondary.Label': 'Submenu Icons and Text',

  'UI.Gen2.Admin.Branding.Tab.TabHeader.Themes': 'Themes',
  'UI.Gen2.Admin.Branding.Tab.TabHeader.Logos': 'Logos',
  'UI.Gen2.Admin.Branding.Tab.TabHeader.LogosTab': 'Logos',

  'UI.Gen2.Admin.Branding.Theme.Modal.LogoUpload.Tabs.Library.Label': 'Library',
  'UI.Gen2.Admin.Branding.Theme.Modal.LogoUpload.Tabs.Upload.Label': 'Upload',
  'UI.Gen2.Admin.Branding.Theme.Modal.LogoUpload.Tabs.Library.Placeholder': 'No logo uploaded yet.',
  'UI.Gen2.Admin.Branding.Theme.Modal.LogoUpload.Tabs.Upload.Full.Title': 'Your Logo Library Is Full',
  'UI.Gen2.Admin.Branding.Theme.Modal.LogoUpload.Tabs.Upload.Full.Description': 'You’ve reached the limit of 20 logos. To add logos, you need to remove others from the library.',
  'UI.Gen2.Admin.Branding.Theme.Creation.Name.Error.Empty': 'Theme name cannot be empty.',
  'UI.Gen2.Admin.Branding.Theme.Creation.Name.Error.AllowedChars': 'Only numbers, digits, hiphens and underscrores are allowed.',
  'UI.Gen2.Admin.Branding.Theme.Creation.Name.Error.NoOfCharsLimit': 'Theme name can’t exceed 30 characters.'
};

export default messages;
