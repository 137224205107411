/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-curly-spacing */
import React from 'react';
import { SidePanel } from '@saviynt/design-system';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { ModuleDetails } from '../../models/PamModels';

import SidePanelFooter from './SidePanelFooter/SidePanelFooter';
import SidePanelView from './SidePanelView/SidePanelView';
import { ASSET_TYPES, TYPES } from './constants';

import './LandingPageSidePanel.css';

function LandingPageSidePanel({
  data,
  isOpen,
  onClose,
  credentialRequestOnClick,
  checkoutRoleOnClick,
  dataTestId,
  setIsCheckInModalOpen,
  setIconClickedForRow,
  setCheckInModalKind,
  setIsExtendModalOpen,
  setSessionToLaunch,
  setIsSessionTargetModalVisible,
  setSessionDetailsForAd,
  className,
}) {
  const classes = classnames('LandingPageSidePanel', className);
  const APPLICATION = 'Application';

  const setIcon = () => {
    if (data.type === TYPES.ROLE) {
      return 'Role';
    }

    switch (data.assetType) {
      case ASSET_TYPES.ACCOUNT:
        return 'Account';
      case ASSET_TYPES.APPLICATION:
        return APPLICATION;
      case ASSET_TYPES.DATABASE:
        return 'Database';
      case ASSET_TYPES.SERVER:
        return 'Server';
      case ASSET_TYPES.CONSOLE:
        return 'WebAppConsole';
      default:
        return APPLICATION;
    }
  };

  const getHeaderTitle = () => {
    const {
      roleName,
      roleDisplayName,
      endpointDisplayName,
      endointName,
      endpointName,
      accountName,
    } = data;

    if (roleName) {
      if (roleDisplayName) {
        return roleDisplayName;
      }

      return roleName;
    }

    return endpointDisplayName || endointName || endpointName || accountName;
  };

  return (
    <SidePanel
      header={{
        title: getHeaderTitle(),
        description: data.roleType || data.assetType || 'No Description',
        icon: setIcon(),
      }}
      headerClassName={
        data.type === 'endpoint'
          ? 'LandingPageSidePanel-noTabGroupHeader'
          : null
      }
      isOpen={isOpen}
      onClose={onClose}
      className={classes}
      layout={<SidePanelView data={data} />}
      layoutClassName={
        data.type === TYPES.SESSION
          ? 'SidePanel-layout LandingPageSidePanel-layout'
          : null
      }
      footer={
        <SidePanelFooter
          data={data}
          credentialRequestOnClick={credentialRequestOnClick}
          checkoutRoleOnClick={checkoutRoleOnClick}
          setIsCheckInModalOpen={setIsCheckInModalOpen}
          setIconClickedForRow={setIconClickedForRow}
          setSidePanelOnClose={onClose}
          setCheckInModalKind={setCheckInModalKind}
          setIsExtendModalOpen={setIsExtendModalOpen}
          setSessionToLaunch={setSessionToLaunch}
          setIsSessionTargetModalVisible={setIsSessionTargetModalVisible}
          setSessionDetailsForAd={setSessionDetailsForAd}
        />
      }
      showCenterMessage
      // TODO: Remove TEMP bool isResponsivenessDisabled, used to avoid PAM regressions
      isResponsivenessDisabled
      dataTestId={dataTestId}
    />
  );
}

LandingPageSidePanel.propTypes = {
  data: ModuleDetails,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  credentialRequestOnClick: PropTypes.func.isRequired,
  checkoutRoleOnClick: PropTypes.func.isRequired,
  dataTestId: PropTypes.string,
  className: PropTypes.string,
  setIsCheckInModalOpen: PropTypes.func,
  setIconClickedForRow: PropTypes.func,
  setCheckInModalKind: PropTypes.func,
  setIsExtendModalOpen: PropTypes.func,
  setSessionToLaunch: PropTypes.func,
  setIsSessionTargetModalVisible: PropTypes.func,
  setSessionDetailsForAd: PropTypes.func,
};

LandingPageSidePanel.defaultProps = {
  data: null,
  isOpen: false,
  onClose: () => {},
  dataTestId: null,
  className: null,
  setIsCheckInModalOpen: null,
  setIconClickedForRow: null,
  setCheckInModalKind: null,
  setIsExtendModalOpen: null,
  setSessionToLaunch: null,
  setIsSessionTargetModalVisible: null,
  setSessionDetailsForAd: null,
};
export default LandingPageSidePanel;
