import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import styles from './EllipsedLabel.module.scss';

const EllipsedLabel = ({
  maxLength, label, className, linesToDisplay, toolTipDelay = 500,
}) => {
  const [showToolTip, setshowToolTip] = useState(false);
  const handleMouseOver = (e) => {
    if (e.currentTarget.clientWidth < e.currentTarget.scrollWidth
      || e.currentTarget.clientHeight < e.currentTarget.scrollHeight) {
      setshowToolTip(true);
      return;
    }
    setshowToolTip(false);
  };
  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      fontSize: theme.typography.pxToRem(13),
    },
  }))(Tooltip);
  if (linesToDisplay) {
    return label && showToolTip ? (
      <HtmlTooltip enterDelay={toolTipDelay} title={label} arrow>
        <span className={`${styles.lineClamp} ${className}`} style={{ WebkitLineClamp: linesToDisplay }}>{label}</span>
      </HtmlTooltip>
    ) : (
      <span onMouseOver={handleMouseOver} className={`${styles.lineClamp} ${className}`} style={{ WebkitLineClamp: linesToDisplay }}>{label}</span>
    );
  }
  return label && label.length > maxLength ? (
    <HtmlTooltip enterDelay={toolTipDelay} title={label} arrow>
      <span className={className || ''}>{`${label.substring(0, maxLength)}...`}</span>
    </HtmlTooltip>
  ) : (
    <span className={className || ''}>{label}</span>
  );
};
EllipsedLabel.defaultProps = {
  className: '',
};

EllipsedLabel.propTypes = {
  maxLength: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  toolTipDelay: PropTypes.number,
  linesToDisplay: PropTypes.number,
};
export default EllipsedLabel;
