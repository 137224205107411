export const listAPIListReceivedActionType = 'listApi';
export const listRowSelectedActionType = 'listRowSelected';
export const LIST_ACCOUNT_REMOTE_METADATA = 'LIST_ACCOUNT_REMOTE_METADATA';
export const LIST_EXCLUSIVE_ACCESS = 'LIST_EXCLUSIVE_ACCESS';
export const LIST_MAX_CREDLESS_TIME = 'LIST_MAX_CREDLESS_TIME';
export const SET_SESSION_TO_LAUNCH = 'SET_SESSION_TO_LAUNCH';

export const listAPIListReceived = (data, success) => ({
  type: listAPIListReceivedActionType,
  payload: data,
  successMessage: success,
});

export const selectedListDetails = (data, success) => ({
  type: listRowSelectedActionType,
  payload: data,
  successMessage: success,
});

export const selectedListAccountRemoteMetadatas = (data, success) => ({
  type: LIST_ACCOUNT_REMOTE_METADATA,
  payload: data,
  successMessage: success,
});

export const selectedListIsExclusiveAccess = (data, success) => ({
  type: LIST_EXCLUSIVE_ACCESS,
  payload: data,
  successMessage: success,
});

export const selectedListMaxCredlessSessionRequestTime = (data, success) => ({
  type: LIST_MAX_CREDLESS_TIME,
  payload: data,
  successMessage: success,
});

export const setSessionToLaunch = (session) => ({
  type: SET_SESSION_TO_LAUNCH,
  payload: session,
});
