import { useEffect, useState } from 'react';

import { handleScroll } from '../utilities/handleScroll';

/**
 * Custom hook to monitor the scroll position based on a provided function
 * and updates the 'isScrolled' state to indicate whether the scroll position
 * is greater than 0. By default, it monitors the vertical scroll position of the window.
 *
 * @param {Function} [getScrollPosition = () => window.scrollY] - Function to get the current scroll position.
 *   Default: vertical scroll position of the window.
 *   Called on each scroll event.
 *
 * @param {Object} [elementRef = null] - React ref object of the element to monitor for scrolling.
 *   If provided, the scroll event listener will attach to this element instead of the window.
 *
 *
 * @returns {Object} - Object with a single boolean property 'isScrolled'
 *   indicating whether the scroll position is greater than 0.
 *
 * @example
 * const { isScrolled } = useDetectScroll(() => window.scrollY);
 * <SomeComponent isScrolled={isScrolled} />
 */
function useDetectScroll(
  getScrollPosition = () => window.scrollY,
  elementRef = null
) {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    let handleScrollFunction;

    if (elementRef) {
      handleScrollFunction = () => {
        setIsScrolled(elementRef.current?.scrollTop > 0);
      };

      if (elementRef.current) {
        elementRef.current.addEventListener('scroll', handleScrollFunction);
      }
    } else {
      handleScrollFunction = () => {
        setIsScrolled(getScrollPosition() > 0);
      };

      handleScroll(handleScrollFunction);
    }

    return () => {
      if (elementRef && elementRef.current) {
        elementRef.current.removeEventListener('scroll', handleScrollFunction);
      }
      // If you have a cleanup for window scroll in handleScroll, you can put it here
    };
  }, [getScrollPosition, elementRef]);

  return { isScrolled };
}

// eslint-disable-next-line import/prefer-default-export
export { useDetectScroll };
