/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable object-curly-newline */
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { localize } from '@saviynt/common';
import {
  Box,
  Button,
  ButtonIcon,
  Grid,
  Icon,
  InlineMessage,
  Link,
  ModalIsland,
  RadioButton,
  Tooltip,
  Typography,
} from '@saviynt/design-system';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { CredentialRequestAccountSelection } from '../../../../models/PamModels';
import {
  ACCOUNT_JIT_SELECTION,
  MAX_ACCOUNTS_BEFORE_SPLICE,
  MAX_ACCOUNTS_TO_SHOW,
  MODAL_PAGE_KINDS,
  msgs,
} from '../../constants';
import {
  isAcctDescToolTipDisabled,
  isAcctDescWithinCharLimit,
} from '../../helpers';

import './MpaAccountSelection.css';

function MpaAccountSelection({
  modalPageKind,
  isAssetTypeDatabase,
  isAssetTypeServer,
  accountListArray,
  accountSelection,
  setAccountSelection,
  setIsAccountListModal,
  databaseJitAccountInfo,
  setDatabaseJitAccountInfo,
  setIsDatabaseJitCreateAccount,
  accountsTotalCount,
}) {
  const intl = useIntl();
  const { Item } = Grid;
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [selectedAccountWarningModal, setSelectedAccountWarningModal] =
    useState(null);

  const isCTS = modalPageKind === MODAL_PAGE_KINDS.CTS;

  const accountSelectionClasses = classnames(
    'MpaAccountSelection',
    `MpaAccountSelection-${modalPageKind}`
  );

  const MORE_LINK_TEXT = localize(
    intl,
    msgs.pam.modalPage.accountSelection.moreLink
  );
  const DB_LABEL_HEADING_TEXT = localize(
    intl,
    msgs.pam.modalPage.accountSelection.dataBaseLabelHeading
  );
  const DB_LABEL_SUBHEADING_TEXT = localize(
    intl,
    msgs.pam.modalPage.accountSelection.dataBaseLabelSubheading
  );
  const DB_JIT_BUTTON_TEXT = localize(
    intl,
    msgs.pam.modalPage.accountSelection.dataBaseButton
  );
  const JIT_RADIO_LABEL_TEXT = localize(
    intl,
    msgs.pam.modalPage.accountSelection.jitRadioLabel
  );
  const ACCOUNT_RADIO_GROUP_LABEL_TEXT = localize(
    intl,
    msgs.pam.modalPage.accountSelection.accountRadioGroupLabel
  );
  const DB_JIT_TEMP_PRIV_ACCOUNT_TEXT = localize(
    intl,
    msgs.pam.cts.db.jitTempPrivilegedAccount
  );
  const DB_JIT_ENTITLEMENTS_TEXT = localize(
    intl,
    msgs.pam.cts.db.jitEntitlements
  );
  const NO_ACCOUNTS_TEXT = localize(
    intl,
    msgs.pam.modalPage.accordionHeading.noAccountRecords
  );
  const EDIT_TEXT = localize(intl, msgs.pam.common.buttons.edit);
  const ACCT_DESC_TRUNCATE_TEXT = localize(
    intl,
    msgs.pam.modalPage.accountSelection.accountDescriptionTruncateLabel
  );

  const isDatabaseJitAccountCreated =
    databaseJitAccountInfo?.entitlements?.length > 0;

  const handleWarningModalClick = () => {
    if (selectedAccountWarningModal === ACCOUNT_JIT_SELECTION.value) {
      setAccountSelection(ACCOUNT_JIT_SELECTION);
    } else {
      setAccountSelection(selectedAccountWarningModal);
    }

    setDatabaseJitAccountInfo({ entitlements: [] });
    setIsWarningModalOpen(false);
  };

  const handleClick = (e, value) => {
    e.stopPropagation();
    setSelectedAccountWarningModal(value);

    if (isDatabaseJitAccountCreated) {
      // show the warning modal
      setIsWarningModalOpen(true);
    } else if (value === ACCOUNT_JIT_SELECTION.value) {
      setAccountSelection(ACCOUNT_JIT_SELECTION);
    } else {
      setAccountSelection(value);
    }
  };

  const handleAccountList = () => {
    setIsAccountListModal(true);
  };

  const newAccountListArray = Array.isArray(accountListArray)
    ? [...accountListArray]
    : [];

  // find the selected account object to pass to RadioButton
  const selectedAccountObj = accountListArray?.find(
    (account) => account.value === accountSelection?.value
  );

  if (newAccountListArray.length > 0) {
    const selectedIndex = newAccountListArray.findIndex(
      (account) => account.value === accountSelection?.value
    );

    if (selectedIndex > MAX_ACCOUNTS_BEFORE_SPLICE) {
      const [selectedItem] = newAccountListArray.splice(selectedIndex, 1);

      newAccountListArray.unshift(selectedItem);
    }
  }

  // Add new asset types that support JIT here to apply renderJITByAssetType functional components
  const isCTSWithJITSupport = Boolean(
    isCTS && (isAssetTypeServer || isAssetTypeDatabase)
  );

  const renderJITByAssetType = () => {
    switch (true) {
      case isAssetTypeDatabase:
        return (
          <Box className='MpaAccountSelection-createNewAccount'>
            <Box className='MpaAccountSelection-jitDatabaseLabelGroup'>
              <Typography kind='h3'>{DB_LABEL_HEADING_TEXT}</Typography>
              <Typography
                kind='body2'
                className='MpaAccountSelection-subheading'>
                {DB_LABEL_SUBHEADING_TEXT}
              </Typography>
            </Box>
            {isDatabaseJitAccountCreated ? (
              <Box
                tag='section'
                className='MpaAccountSelection-jitDatabaseAccountWrapper'>
                <div className='MpaAccountSelection-jitDatabaseAccountInfo'>
                  <Typography kind='h3'>
                    {DB_JIT_TEMP_PRIV_ACCOUNT_TEXT}
                  </Typography>
                  <Typography
                    kind='body2'
                    className='MpaAccountSelection-entitlementsRow'>
                    <span>{DB_JIT_ENTITLEMENTS_TEXT}:</span>
                    <span className='MpaAccountSelection-entitlements'>
                      {databaseJitAccountInfo.entitlements.join(', ')}
                    </span>
                  </Typography>
                </div>
                <ButtonIcon
                  size='large'
                  kind='outlinedSecondary'
                  icon={<Icon kind='edit' color='neutral-100' size='medium' />}
                  onClick={() => {
                    setIsDatabaseJitCreateAccount(true);
                  }}>
                  {EDIT_TEXT}
                </ButtonIcon>
              </Box>
            ) : (
              <Button
                className='MpaAccountSelection-jitButton'
                type='button'
                kind='filled'
                size='large'
                onClick={() => {
                  setIsDatabaseJitCreateAccount(true);
                }}>
                {DB_JIT_BUTTON_TEXT}
              </Button>
            )}
          </Box>
        );
      case isAssetTypeServer:
        return (
          <Box className='MpaAccountSelection-createNewAccount'>
            <Typography kind='h3'>{JIT_RADIO_LABEL_TEXT}</Typography>
            <Grid spacing={2} className='MpaAccountSelection-radioButtonGroup'>
              <Item
                desktop={6}
                tablet={12}
                className='MpaAccountSelection-radioButton'>
                <RadioButton
                  id='accountRadio-jit'
                  name='accountSelectionRadioGroup'
                  title={ACCOUNT_JIT_SELECTION.title}
                  description={ACCOUNT_JIT_SELECTION.description}
                  value={ACCOUNT_JIT_SELECTION.value}
                  radioSelection={accountSelection?.value}
                  onClick={handleClick}
                  isTruncated
                />
              </Item>
            </Grid>
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Box tag='section' className={accountSelectionClasses}>
      {isCTSWithJITSupport && renderJITByAssetType()}
      <Box className='MpaAccountSelection-selectCurrentAccount'>
        {isCTSWithJITSupport && (
          <Typography kind='h3'>{ACCOUNT_RADIO_GROUP_LABEL_TEXT}</Typography>
        )}
        {newAccountListArray?.length === 0 || !newAccountListArray ? (
          <InlineMessage>
            <Box className='MpaAccountSelection-noRecords'>
              <Icon kind='alertCriticalOutline' color='navy-900' />
              <Typography
                kind='h3'
                className='MpaAccountSelection-noRecords-title'>
                {NO_ACCOUNTS_TEXT}
              </Typography>
            </Box>
          </InlineMessage>
        ) : (
          <Grid spacing={2} className='MpaAccountSelection-radioButtonGroup'>
            {newAccountListArray
              ?.slice(0, MAX_ACCOUNTS_TO_SHOW)
              .map((account, index) => (
                <Item
                  key={`accountRadio-${account.value}`}
                  desktop={6}
                  tablet={12}
                  className='MpaAccountSelection-radioButton'>
                  <Tooltip
                    title={ACCT_DESC_TRUNCATE_TEXT}
                    aria-label={ACCT_DESC_TRUNCATE_TEXT}
                    text={account.description}
                    isDisabled={isAcctDescToolTipDisabled(account.description)}
                    trigger={
                      <RadioButton
                        id={`accountRadio-${index}`}
                        name='accountSelectionRadioGroup'
                        title={account.title}
                        description={
                          isAcctDescWithinCharLimit(account.description)
                            ? account.description
                            : null
                        }
                        value={account}
                        radioSelection={selectedAccountObj}
                        onClick={handleClick}
                        isTruncated
                      />
                    }
                  />
                </Item>
              ))}
          </Grid>
        )}
        {accountsTotalCount > MAX_ACCOUNTS_TO_SHOW ? (
          <Link
            href='Account More Modal Button'
            text={MORE_LINK_TEXT}
            size='large'
            onClick={() => handleAccountList()}
          />
        ) : null}
      </Box>
      {isDatabaseJitAccountCreated && (
        <ModalIsland
          kind='warning'
          alertTitle='Change Accounts?'
          alertSubtitle='If you change accounts, your JIT account and all entitlements you’ve selected will be discarded.'
          onClose={() => setIsWarningModalOpen(false)}
          isOpen={isWarningModalOpen}
          primaryButton={
            <Button
              type='button'
              kind='outlined'
              size='medium'
              onClick={handleWarningModalClick}>
              Change Accounts
            </Button>
          }
          secondaryButton={
            <Button
              type='button'
              kind='ghost'
              size='medium'
              onClick={() => setIsWarningModalOpen(false)}>
              Cancel
            </Button>
          }
        />
      )}
    </Box>
  );
}

MpaAccountSelection.propTypes = {
  modalPageKind: PropTypes.string.isRequired,
  isAssetTypeDatabase: PropTypes.bool.isRequired,
  isAssetTypeServer: PropTypes.bool.isRequired,
  accountListArray: PropTypes.arrayOf(CredentialRequestAccountSelection),
  accountSelection: CredentialRequestAccountSelection,
  setAccountSelection: PropTypes.func.isRequired,
  setIsAccountListModal: PropTypes.func.isRequired,
  databaseJitAccountInfo: PropTypes.shape({
    entitlements: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  setDatabaseJitAccountInfo: PropTypes.func.isRequired,
  setIsDatabaseJitCreateAccount: PropTypes.func.isRequired,
  accountsTotalCount: PropTypes.number.isRequired,
};

MpaAccountSelection.defaultProps = {
  accountListArray: null,
  accountSelection: null,
};

export default MpaAccountSelection;
