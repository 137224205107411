const fullScreenRoutes = [
  '/request/privilegedAccess/selectInstance',
  '/request/privilegedAccess/selection/instanceSelection',
  '/request/privilegedAccess/optionalSelection',
];
const nonFullscreenGriv2routes = [
  '/request/requestHistory',
  '/review/requestApproval',
  '/request/applicationRequest/manageRequestTeam',
];

export const whiteListedLinks = ['/admin', '/ECM'];
export const homePageUrl = '/ECMv6/request/requestHome';
export const sideMenuIsVisibleFor = [
  '/request',
  '/review',
  '/design',
  '/control',
  '/campaign',
  '/admin',
  '/identityMatchAndMerge',
  '/pam',
  '/ECM/users',
  '/ECM/accounts',
  '/ECM/entitlement_values',
  '/ECM/entitlement_types',
  '/ECM/endpoints',
  '/ECM/roles',
  '/ECM/customer',
  '/ECM/workflowmanagement',
  '/ECM/certification',
  '/ECM/dashboard',
  '/ECM/analyticsHistory',
  '/ECM/analyticsHistoryES',
  '/ECM/hanaRule',
  '/ECM/roleanalytics',
  '/ECM/controlCenter',
  '/ECM/privilege',
  '/ECM/soddetail',
  '/ECM/mitigatingControls',
  '/ECM/accessRules',
  '/ECM/user_GroupDetail',
  '/ECM/rolemining',
  '/ECM/applicationOnboarding',
  '/ECM/policyRule',
  '/ECM/analyticsConfig',
  '/ECM/campaign',
  '/ECM/jbpmworkflowmanagement',
  '/ECM/rulesets',
  '/ECM/risks',
  '/ECM/functions',
  '/ECM/busprocs',
  '/ECM/ecmConfig',
  '/ECM/ecmEmailTemplate',
  '/ECM/saviyntlog',
  '/ECM/dashboard',
  '/ECM/oauthProvider',
  '/ECM/webservice',
  '/ECM/externalSetup',
  '/ECM/logs',
  '/ECM/certificateManagement',
  '/ECM/dataAnalyzer',
  '/ECM/savRoles',
  '/ECM/jobcontrol',
  '/ECM/securitysystems',
  '/ECM/dataset',
  '/ECM/maintenance',
  '/ECM/archive',
  '/ECM/flatViewJobcontrol',
  '/ECM/SSO',
  '/ECM/transportAsync',
  '/ECM/transport',
  '/ECM/simulationDetail',
  '/ECM/analytics',
  '/ECM/questionnaire',
  '/ECM/firefighter',
  '/ECM/saml',
  '/ECM/flatViewJobcontrol/flatViewJobList',
  '/ECM/accessControl'
];

export { fullScreenRoutes, nonFullscreenGriv2routes };
