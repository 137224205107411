import { type ThunkAction } from 'redux-thunk';
import { type ActionType } from 'typesafe-actions';

import { RequestStatus } from '../../../../models';
import * as api from '../api';
import type RootState from '../RootState';

import * as actions from './actions';

type Actions = ActionType<typeof actions>;
type RecentAssignmentsThunkAction = ThunkAction<
  void,
  RootState,
  unknown,
  Actions
>;

export const requestCertifications =
  (username: string): RecentAssignmentsThunkAction =>
  async (dispatch, getState) => {
    const { recentAssignments: state } = getState();

    if (state.certificationsStatus === RequestStatus.Pending) {
      return;
    }

    dispatch(actions.certificationsRequested());

    try {
      const certifications = await api.readCertifications(username);

      dispatch(actions.certificationsReceived(certifications));
    } catch (e) {
      dispatch(actions.certificationsNotReceived());
    }
  };

export const requestRequests =
  (username: string): RecentAssignmentsThunkAction =>
  async (dispatch, getState) => {
    if (getState().recentAssignments.requestsStatus === RequestStatus.Pending) {
      return;
    }

    dispatch(actions.requestsRequested());

    try {
      const requests = await api.readRequests(username);

      dispatch(actions.requestsReceived(requests));
    } catch (e) {
      dispatch(actions.requestsNotReceived());
    }
  };
