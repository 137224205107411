import React from 'react';
import { useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import Loader from 'ui-components/src/components/Loader/Loader';
import Row from '../Row/Row';
import styles from './SearchResults.module.css';
import RowContent from '../RowContent/RowContent';

const SearchResults = ({ isDisplayed, intl }) => {
  const loading = useSelector((state) => state.admin.logs.loading);
  const results = useSelector((state) => state.admin.logs.results);
  const noServiceSelected = useSelector((state) => state.admin.logs.noServiceSelected);
  const isError = useSelector((state) => state.admin.logs.isError);

  if (isDisplayed) {
    if (loading) return <Loader />;

    if (results.length > 0) {
      return (
        <div className={styles.container}>
          <Row
            timestamp={intl.formatMessage({ id: 'MANAGE.LOGS.DATE.TIMESTAMP.COLUMN.LABEL' })}
            serviceName={intl.formatMessage({ id: 'MANAGE.LOGS.SERVICE.NAME.COLUMN.LABEL' })}
            level={intl.formatMessage({ id: 'MANAGE.LOGS.LEVEL.COLUMN.LABEL' })}
            threadName={intl.formatMessage({ id: 'MANAGE.LOGS.THREAD.NAME.COLUMN.LABEL' })}
            className={intl.formatMessage({ id: 'MANAGE.LOGS.CLASS.NAME.COLUMN.LABEL' })}
            message={intl.formatMessage({ id: 'MANAGE.LOGS.MESSAGE.COLUMN.LABEL' })}
            isHeading
          />
          <RowContent intl={intl} />
        </div>
      );
    }

    if (noServiceSelected) {
      return <h4 className={styles.noResults}>{intl.formatMessage({ id: 'Admin.Log.Viewer.noServiceSelected' })}</h4>;
    }

    if (isError) {
      return <h4 className={styles.noResults}>{intl.formatMessage({ id: 'Admin.Log.Viewer.apiErrorMessage' })}</h4>;
    }

    if (results.length === 0 && !loading) {
      return <h4 className={styles.noResults}>{intl.formatMessage({ id: 'MANAGE.LOGS.NO.RECORDS.LABEL' })}</h4>;
    }
  }

  return null;
};

export default injectIntl(SearchResults);
