/* eslint-disable import/prefer-default-export */
import { useEffect, useRef } from 'react';

/**
 * custom hook that uses ResizeObserver API to track changes in the size of an element.
 *
 * @param {Function} onResizeCallback - callback function to be called when the element's size changes.
 * @returns {Object} - ref to attach to the element to be observed.
 */
const useResizeObserver = (onResizeCallback) => {
  const observedElementRef = useRef(null);

  useEffect(() => {
    const element = observedElementRef.current;

    if (!element) return;

    const resizeObserver = new ResizeObserver((entries) => {
      if (entries[0]) {
        onResizeCallback(entries[0].contentRect);
      }
    });

    resizeObserver.observe(element);

    // eslint-disable-next-line consistent-return
    return () => resizeObserver.disconnect();
  }, [onResizeCallback]);

  return observedElementRef;
};

export { useResizeObserver };
