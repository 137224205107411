import {
  CLEAR_REQUEST_UPLOAD_ERROR,
  REQUEST_FILE_UPLOAD_FAILED,
} from '../actions';

const fileUploadStatusReducer = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_FILE_UPLOAD_FAILED:
      return {
        ...state,
        userName: action.payload.userName,
        uploadFailed: true,
      };

    case CLEAR_REQUEST_UPLOAD_ERROR:
      return {};

    default:
      return state;
  }
};

export default fileUploadStatusReducer;
