import React from 'react';
import { useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import styles from './FilterInfo.module.css';

const StrongText = ({ text }) => (text ? (<span className={styles.boldText}>{`${text}`}</span>) : '');

const FilterInfo = ({ isDisplayed, children, intl }) => {
  const searchValue = useSelector((state) => state.admin?.logs.query.searchKey);
  const fromDate = useSelector((state) => state.admin?.logs.query.fromDate);
  const toDate = useSelector((state) => state.admin?.logs.query.toDate);
  const displaySearchTimeRange = `${fromDate} - ${toDate}`;
  const searchText = searchValue ? `${searchValue} : ` : '';

  if (isDisplayed) {
    return (
      <div className={styles.container}>
        <div data-testid="filterInfo" className={styles.info}>
          {intl.formatMessage({ id: 'Admin.Log.Viewer.filterMessage' })}
          &nbsp;
          <StrongText text={'"'} />
          <StrongText text={searchText} />
          <StrongText text={displaySearchTimeRange} />
          <StrongText text={'"'} />
        </div>
        <div className={styles.children}>
          {children}
        </div>
      </div>
    );
  }

  return null;
};

export default injectIntl(FilterInfo);
