import CookieService from '../utils/CookieService';

const replaceLinksWithCookies = (str) => {
  let tempStr = str;
  try {
    const patt = /{((?:\\.|[^}])*)}/gim;
    let match = '';
    // eslint-disable-next-line no-cond-assign
    while ((match = patt.exec(tempStr))) {
      tempStr = tempStr.replace(
        `{${tempStr.substring(match.index + 1, patt.lastIndex - 1)}}`,
        CookieService.get(tempStr.substring(match.index + 1, patt.lastIndex - 1)),
      );
    }
  } catch {
    // exception
  }
  return tempStr;
};

export default replaceLinksWithCookies;
