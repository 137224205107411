import React from 'react';
import { Tooltip, Typography } from '@saviynt/design-system';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './SpvSection.css';

function SpvSection({ title, fields, children, dataTestId, className }) {
  const classes = classnames('SpvSection', className);

  const CHAR_COUNT_LIMIT = 118;
  const isValueTooLong = (text) => text.length > CHAR_COUNT_LIMIT;

  return (
    <section className={classes} data-testid={dataTestId}>
      <Typography kind='h4' className='SpvSection-title'>
        {title}
      </Typography>
      {fields.map((field) =>
        field && field.value ? (
          <div key={field.label} className='SpvSection-field'>
            <Typography kind='body3' className='SpvSection-field'>
              {field.label}
            </Typography>
            <Tooltip
              title='Truncated Text'
              aria-label='Truncated Text'
              text={field.value}
              isDisabled={!isValueTooLong(field.value)}
              trigger={
                <Typography
                  kind='body2'
                  className={classnames(
                    'SpvSection-value',
                    isValueTooLong(field.value) &&
                      'SpvSection-value--isTruncated'
                  )}>
                  {field.value}
                </Typography>
              }
            />
          </div>
        ) : null
      )}
      {children}
    </section>
  );
}

SpvSection.propTypes = {
  title: PropTypes.string,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.element,
      ]),
    })
  ),
  children: PropTypes.node,
  dataTestId: PropTypes.string,
  className: PropTypes.string,
};

SpvSection.defaultProps = {
  title: null,
  fields: [],
  dataTestId: null,
  children: null,
  className: null,
};

export default SpvSection;
