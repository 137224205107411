import { defaultConfig } from '../reducers/logsReducer';

const getPrevScrollMarkerId = (scrollMarkers, currentID) => {
  const result = Math.round((defaultConfig.noOfRecordsToDownload / defaultConfig.noOfRecordsToQuery) / 2);

  if (scrollMarkers.length > result) {
    return scrollMarkers[scrollMarkers.findIndex((m) => m === currentID) - result];
  }

  return scrollMarkers[0];
};

export default getPrevScrollMarkerId;
