import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  close: {
    padding: theme.spacing(0.5),
  },
  warning: {
    backgroundColor: '#ff9800',
  },
  error: {
    backgroundColor: '#f44336',
  },
  stickToBottom: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
  },
}));

const SnackbarComponent = ({
  open,
  message,
  onSnackBarClose,
  verticalPosition,
  horizontalPosition,
  duration,
  children,
  type,
  ...props
}) => {
  const classes = useStyles();
  let contentProps = {};
  if (type === 'warning') {
    contentProps = {
      'aria-describedby': 'message-id',
      className: classes.warning,
      classes: classes.stickToBottom,
    };
  } else if (type === 'error') {
    contentProps = {
      'aria-describedby': 'message-id',
      className: classes.error,
      classes: classes.stickToBottom,
    };
  } else {
    contentProps = {
      'aria-describedby': 'message-id',
    };
  }

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: verticalPosition,
          horizontal: horizontalPosition,
        }}
        open={open}
        autoHideDuration={duration || 5000}
        onClose={onSnackBarClose}
        ContentProps={contentProps}
        message={<span id="message-id">{message}</span>}
        {...props}
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            className={classes.close}
            onClick={onSnackBarClose}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      >
        {children}
      </Snackbar>
    </div>
  );
};

SnackbarComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  onSnackBarClose: PropTypes.func.isRequired,
  verticalPosition: PropTypes.string.isRequired,
  horizontalPosition: PropTypes.string.isRequired,
  duration: PropTypes.number,
  // children: PropTypes.node.isRequired,
};

export default SnackbarComponent;
