
import React from 'react';

const SideMenuIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="fix - 2px">
<g id="Vector">
<path d="M18.5715 8.28564C19.0448 8.28564 19.4286 8.6694 19.4286 9.14279C19.4286 9.61618 19.0448 9.99993 18.5715 9.99993H4.85714C4.38376 9.99993 4 9.61618 4 9.14279C4 8.6694 4.38376 8.28564 4.85714 8.28564H18.5715Z" fill="var(--SIDEMENU-ICON-HAMBURGER)"/>
<path d="M28 15.992C27.998 15.7753 27.9143 15.5592 27.749 15.3938L24.749 12.3938C24.4142 12.0591 23.8715 12.0591 23.5368 12.3938C23.2021 12.7286 23.2021 13.2713 23.5368 13.606L25.0736 15.1428H4.85714C4.38376 15.1428 4 15.5266 4 15.9999C4 16.4733 4.38376 16.8571 4.85714 16.8571H25.0736L23.5368 18.3939C23.2021 18.7286 23.2021 19.2713 23.5368 19.606C23.8715 19.9408 24.4142 19.9408 24.749 19.606L27.749 16.606C27.9143 16.4407 27.998 16.2246 28 16.0079" fill="var(--SIDEMENU-ICON-HAMBURGER)"/>
<path d="M19.4286 22.8571C19.4286 22.3837 19.0448 22 18.5715 22H4.85714C4.38376 22 4 22.3837 4 22.8571C4 23.3305 4.38376 23.7142 4.85714 23.7142H18.5715C19.0448 23.7142 19.4286 23.3305 19.4286 22.8571Z" fill="var(--SIDEMENU-ICON-HAMBURGER)"/>
</g>
</g>
</svg>
);

export default SideMenuIcon;

