// https://spin.atomicobject.com/typescript-flexible-nominal-typing/
interface Flavoring<FlavorT> {
  _type?: FlavorT;
}
export type Flavor<T, FlavorT> = T & Flavoring<FlavorT>;

export type MenuId = Flavor<string, 'models.MenuId'>;

/**
 * One of:
 * - MenuId[] - valid response data from API, could be empty
 * - 'unauthorized' - user is not authorized to access these MenuIds
 * - 'error' - API call failed for some other reason
 * - undefined - API call not yet made
 */
export type MenuIdsResponse = MenuId[] | 'error' | 'unauthorized' | undefined;

export enum RequestStatus {
  Idle = 0,
  Pending = 1,
}
