import React, { memo } from 'react';
import classnames from 'classnames';
import styles from './Row.module.css';
import HoverLink from '../HoverLink/HoverLink';

const Row = ({
  refs, timestamp, serviceName,level, threadName, className, message, isHeading = false, url, isSearchID, intl,
}) => {
  const classes = classnames(
    isSearchID && styles.highlightRow,
    isHeading ? styles.headingRow : styles.row,
  );

  return (
    <div data-testid="rowDiv" className={classes} ref={refs}>
      <div className={classnames(styles.message, isHeading && styles.heading)}>
        <span className={styles.item}>
          <span className={classnames(!isHeading && styles.ribbon)}>
            {timestamp}
            <span className={styles.spacer}>-</span>
            {serviceName}
            <span className={styles.spacer}>-</span>
            {className}
            <span className={styles.spacer}>-</span>
            {threadName}
            <span className={styles.spacer}>-</span>
            {level}
          </span>
          <span className={styles.spacer}>-</span>
        </span>
        {message}
      </div>
      <HoverLink
        url={url}
        intl={intl}
        isHeading={isHeading}
        className={classnames(isHeading ? styles.noHoverRow : styles.hoverRow)}
      />
    </div>
  );
};

export default memo(Row);
