import React, { useEffect, useState } from 'react';
import { Icon as AntIcon, Upload } from 'antd';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { ShowMessageModal } from 'ui-common/src/utils/ResponseHandler-actions';
import { connect } from 'react-redux';
import { validateRegex } from 'ui-common/src/lib/utils';
import { IconButton } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import styles from './FileUpload.module.scss';
import { Icon } from '../Icon';

const FileUpload = ({
  title,
  setComments,
  fileList,
  setFileList,
  commentsMandatory,
  fileInfo,
  intl,
  ShowMessageModal,
  setInvalidInput,
  placeholder,
  attachmentsPresent,
  attachmentsPresentSize,
  commentsDisabled,
  defaultValue,
  ...props
}) => {
  let actualFileList = fileList.length > 0 ? fileList : [];
  const [errorMessage, setErrorMessage] = useState('');
  const [invalidInputInternal, setInvalidInputInternal] = useState(false);
  const [fileDialogOpen, setFileDialogOpen] = useState(false);
  const [allowedExtension, setAllowedExtension] = useState('');
  const [allowedExtensionObj, setAllowedExtensionObj] = useState([]);

  useEffect(() => {
    let allowedExtensionInfo = fileInfo.allowedExtension && fileInfo.allowedExtension.split(',');
    const acceptConfig = [];
    allowedExtensionInfo =
      allowedExtensionInfo &&
      allowedExtensionInfo.map(item => {
        acceptConfig.push(`.${item.trim()}`);
        return item.trim();
      });
    setAllowedExtensionObj(allowedExtensionInfo || ['csv', 'json', 'jpg', 'jpeg', 'txt', 'xml']);
    setAllowedExtension(
      acceptConfig.length > 0 ? acceptConfig.join(',') : '.csv,.json,.jpg,.jpeg,.txt,.xml'
    );
  }, [fileInfo]);

  const propsupload = {
    accept: allowedExtension || '.csv,.json,.jpg,.jpeg,.txt,.xml',
    multiple: true,
    onRemove: file => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      return {
        fileList: newFileList,
      };
    },
    beforeUpload: file => {
      let doNotAddFile = false;
      let totalFileSize = 0;

      const extension = file.name.substr(file.name.lastIndexOf('.') + 1).toLowerCase();
      const isFileSupported = allowedExtensionObj.includes(extension);

      // Check for File Supported Extension
      if (!isFileSupported) {
        doNotAddFile = true;
        setErrorMessage(
          intl.formatMessage({ id: 'FileUpload.errorMessage.ExtensionNotSupported' })
        );
      }

      // Check for Duplicate File Name Validation
      fileList.forEach(element => {
        totalFileSize += element.size;
        if (file.name === element.name) {
          doNotAddFile = true;
        }
      });
      // Check for Maximum File Count
      if (
        fileInfo &&
        fileInfo.maxCount > 0 &&
        actualFileList.length + attachmentsPresent >= fileInfo.maxCount
      ) {
        doNotAddFile = true;
        setErrorMessage(intl.formatMessage({ id: 'FileUpload.errorMessage.CountExceeds' }));
      }
      // Check for Maximum File Size
      if (fileInfo && fileInfo.maxSize && totalFileSize + attachmentsPresentSize + file.size > fileInfo.maxSize) {
        doNotAddFile = true;
        setErrorMessage(intl.formatMessage({ id: 'FileUpload.errorMessage.SizeExceeds' }));
      }
      if (!doNotAddFile) {
        actualFileList.push(file);
        setFileList([...actualFileList]);
      }

      return false;
    },
    fileList,
  };

  const removeFile = (name, e) => {
    setFileList(fileList.filter(file => file.name !== name));
    e.stopPropagation();
  };
  useEffect(() => {
    if (errorMessage !== '') {
      ShowMessageModal({
        message: errorMessage, // code to lookup in messages.
        encrypted: false, // extra error info like specific field.
        status: 'failure',
      });
      setErrorMessage('');
    }
  }, [errorMessage]);

  useEffect(() => {
    actualFileList = fileList && fileList.length > 0 ? [...fileList] : [];
  }, [fileList]);
  return (
    <div className="d-flex flex-column w-100">
      <span className={` ${styles.leaveComment}`}>
        {title}
        {commentsMandatory && <span className={styles.mandatory}>{` *`}</span>}
      </span>

      <Upload
        className={`${styles.dropzone} ${
          props.showError && props.showError.showError && props.showError.index === ''
            ? styles.commentsError
            : ''
        }`}
        {...propsupload}
        openFileDialogOnClick={fileDialogOpen}
        disabled={fileInfo.disableAttachment}
      >
        <textarea
          placeholder={commentsDisabled ? '' : placeholder}
          name=""
          id=""
          cols="30"
          rows="3"
          onChange={e => {
            setComments(e.target.value);
            const isvalid = validateRegex(null, e.target.value, true);
            if (!isvalid) {
              if (setInvalidInput) setInvalidInput(true);
              setInvalidInputInternal(true);
            } else {
              if (setInvalidInput) setInvalidInput(false);
              setInvalidInputInternal(false);
            }
          }}
          className={`form-control ${styles.instanceCheckoutComment} mt-1 pt-1 w-100`}
          // onBlur={e => setComments(e)}
          onClick={e => {
            e.stopPropagation();
          }}
          disabled={!!commentsDisabled}
          defaultValue={defaultValue || ''}
        />

        <div
          className={styles.attachmentIcon}
        >
          <AntIcon
            type="paper-clip"
            className={fileInfo.disableAttachment ? styles.disabledIcon : ''}
            onMouseEnter={() => setFileDialogOpen(true)}
            onMouseLeave={() => setFileDialogOpen(false)}
          />
          {fileInfo.mandatoryAttachment && <span className={styles.mandatoryfile}>{` *`}</span>}
          {
            <IconButton title={title}>
              <Tooltip
                enterDelay="300"
                title={`${intl.formatMessage({
                  id: 'FileUpload.errorMessage.extensionAllowed',
                })} ${allowedExtension}`}
                arrow
                placement="top"
                classes={{ tooltip: styles.tooltip }}
              >
                <InfoIcon fontSize="small" />
              </Tooltip>
            </IconButton>
          }
        </div>
        <div className={styles.attachedFileList}>
          {fileList &&
            fileList.map(file => (
              <div className={`${styles.attachedFile} d-flex justify-content-between`}>
                <Icon name="attachedFile" fileType={file.name.split('.')[1].substring(0, 3)} />

                <div className={`d-flex flex-column ${styles.attachedText}`}>
                  <span className="d-flex flex-start">{file.name}</span>
                  <span className="d-flex flex-start">
                    {file.size > 1000000
                      ? `${(file.size / 1000000).toFixed(2)} mb`
                      : `${(file.size / 1000).toFixed(2)} kb`}
                  </span>
                </div>
                <Icon
                  name="close"
                  onClick={e => removeFile(file.name, e)}
                  className={`${styles.closeAttachement}`}
                />
              </div>
            ))}
        </div>
      </Upload>
      <div className="d-flex">
        {fileInfo && (
          <div className="d-flex">
            {fileInfo.maxSize && (
              <div className={styles.maxSize}>
                {`${intl.formatMessage({ id: 'SecurityValidation.Maxfilesize' })} : `}
                <span className={styles.maxSizeVal}>{`${
                  fileInfo.maxSize > 1000000
                    ? `${(fileInfo.maxSize / 1000000).toFixed(2)}MB`
                    : `${(fileInfo.maxSize / 1000).toFixed(2)}KB`
                }`}</span>
              </div>
            )}
            {fileInfo.maxCount && (
              <div className={styles.maxCount}>
                {`${intl.formatMessage({ id: 'SecurityValidation.Maxfilecount' })}: `}
                <span className={styles.maxSizeVal}>{fileInfo.maxCount}</span>
              </div>
            )}
          </div>
        )}
        {invalidInputInternal && (
          <div className={styles.invalid}>
            {intl.formatMessage({
              id: 'ENTERED.VALUE.MUST.NOT.HAVE.HTML.TAGS',
              intl,
            })}
          </div>
        )}
      </div>
    </div>
  );
};

FileUpload.defaultProps = {
  title: '',
  commentsMandatory: false,
  fileInfo: {},
  placeholder: '',
  attachmentsPresent: 0,
  attachmentsPresentSize:0,
  commentsDisabled: false,
};
FileUpload.propTypes = {
  intl: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string.isRequired,
      formatMessage: PropTypes.func.isRequired,
    })
  ).isRequired,
  title: PropTypes.string,
  setComments: PropTypes.func.isRequired,
  fileList: PropTypes.arrayOf(
    PropTypes.shape({
      size: PropTypes.number,
    })
  ).isRequired,
  setFileList: PropTypes.func.isRequired,
  commentsMandatory: PropTypes.bool,
  fileInfo: PropTypes.shape({
    allowedExtension: PropTypes.string,
    mandatoryAttachment: PropTypes.bool,
    maxSize: PropTypes.string,
    maxCount: PropTypes.string,
  }),
  ShowMessageModal: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  attachmentsPresent: PropTypes.number,
  attachmentsPresentSize: PropTypes.number,
  commentsDisabled: PropTypes.bool,
};

const mapDispatchToProps = {
  ShowMessageModal,
};

export default injectIntl(connect(null, mapDispatchToProps)(FileUpload));
