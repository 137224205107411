import { createAction } from 'typesafe-actions';

import { type PendingCertification, type PendingRequest } from '../../models';

export const certificationsRequested = createAction(
  'certifications/certificationsRequested'
)<void>();

export const certificationsReceived = createAction(
  'certifications/certificationsReceived'
)<PendingCertification[]>();

export const certificationsNotReceived = createAction(
  'certifications/certificationsNotReceived'
)<void>();

export const requestsRequested = createAction(
  'requests/requestsRequested'
)<void>();

export const requestsReceived = createAction('requests/requestsReceived')<
  PendingRequest[]
>();

export const requestsNotReceived = createAction(
  'requests/requestsNotReceived'
)<void>();
