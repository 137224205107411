import React from 'react';
import { FormControl, MenuItem, TextField } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import generateIndexFromKeys from 'ui-common/src/utils/generateIndexFromKeys';
import DisplayLabel from '../DisplayLabel/DisplayLabel';
import { lists } from '../../../../constant';
import styles from './QuickSelect.module.css';
import findUniqueItems from '../../../utils/findUnique';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
}));

const QuickSelect = ({
  value,
  duration,
  handleDurationChange,
  handleInputChange,
  handleApply,
  handleCommon,
  intl,
}) => {
  const classes = useStyles();
  const listOfOptions = lists(intl);
  const options = findUniqueItems(listOfOptions);

  return (
    <>
      <form className={styles.container}>
        <span className={styles.label}>{intl.formatMessage({ id: 'Admin.Log.Viewer.last' })}</span>
        <FormControl variant="outlined" className={classes.formControl}>
          <TextField
            margin="dense"
            variant="outlined"
            onChange={handleInputChange}
            value={value}
            data-testid="valueInput"
          />
        </FormControl>
        <FormControl margin="dense" variant="outlined" className={classes.formControl}>
          <Select
            value={duration}
            defaultValue={options[1].duration}
            onChange={handleDurationChange}
          >
            {options.map((opt, i) => (
              <MenuItem value={opt.duration} key={generateIndexFromKeys('duration', i)}>
                {opt.duration}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" className={classes.formControl}>
          <Button variant="contained" color="primary" type="button" data-test="childButton" onClick={handleApply}>
            {intl.formatMessage({ id: 'Admin.Log.Viewer.apply' })}
          </Button>
        </FormControl>
      </form>
      <hr />
      <div className={styles.quickHeading}>{intl.formatMessage({ id: 'Admin.Log.Viewer.commonlyUsed' })}</div>
      <div className={styles.grid}>
        {listOfOptions.map((list, i) => (
          <div key={generateIndexFromKeys('list', i)} className={styles.gridItem}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleCommon(list)}
              data-testid="commonlyUsed"
            >
              <DisplayLabel {...list} intl={intl} />
            </Button>
          </div>
        ))}
      </div>
    </>
  );
};

export default QuickSelect;
