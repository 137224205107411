/* eslint-disable indent */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  formatDateToReduxStore,
  getCurrentDate,
  Logger,
  trimEmptyUndefinedNull,
} from '@saviynt/common';

import { MIN_LENGTH_FOR_JUSTIFICATION } from '../../components/ModalPageForms/constants';
import { REQUEST_BASE_URL } from '../../misc/constants';
import {
  handleClearCredentialForm,
  handleCredentialFormDates,
  handleCredentialFormJustification,
  requestFileUploadFailed,
  requestSubmittedId,
} from '../../store/actions/index';
import {
  getRequestFileUploadApi,
  postCredentialRequest,
} from '../../utilities/api/getDataFromApi';

const assembleFilesForApi = (filesArray) => {
  // TODO: move this to a central location if/when it's needed elsewhere
  const formData = new FormData();

  filesArray.forEach((fileObj) => {
    if (!fileObj.isError) {
      formData.append('file', fileObj.file);
    }
  });

  return formData;
};

const uploadAttachments = async (requestKey, filesData) => {
  try {
    const response = await getRequestFileUploadApi(requestKey, filesData);

    if (response.ok !== undefined && response.ok === false) {
      throw new Error('error');
    }

    return {
      status: 'success',
      data: response,
    };
  } catch (error) {
    return {
      status: 'error',
      error,
    };
  }
};

const REMOTE_APP_DISPLAY_NAME = { TERMINAL: 'Terminal' };

const ModalPageFormSubmissionService = (
  isCOCModal,
  isCORModal,
  isRemoteAppEnabled,
  isSAP,
  uploadedDetailFiles,
  hasErrorInDetailFiles,
  justificationInputValue,
  isJustificationInputCritical,
  isNowTab,
  isTimeBlocked,
  hasDurationBeenFilled,
  ticketNumber,
  userName,
  setIsFormCritical,
  setNowTabStartDate,
  setIsFormFailureAlertVisible,
  setIsDurationEditingAlertVisible,
  setIsJustificationInputCritical,
  setIsRequestLoading,
  dispatch,
  payloadComment,
  endpointOrRoleDetails
) => {
  const isCheckoutRole = isCORModal;

  const [
    hasCredentialFormRequestValidationPassed,
    setHasCredentialFormRequestValidationPassed,
  ] = useState(false);
  const [isUserAbleToSubmit, setIsUserAbleToSubmit] = useState(false);
  const [shouldAttemptSubmit, setShouldAttemptSubmit] = useState(false);
  const [submitResponseErrorMessage, setSubmitResponseErrorMessage] = useState(
    'Submit Response Error Message'
  );
  // Form submit completion
  const [isCredentialRequestSuccessful, setIsCredentialRequestSuccessful] =
    useState(false);
  const [credentialResponseData, setCredentialResponseData] = useState(null);
  const [isFilesUploadSuccessful, setIsFilesUploadSuccessful] = useState(false);
  const [shouldRetryUploadAPI, setShouldRetryUploadAPI] = useState(false);
  const [currentBusinessJustification, setCurrentBusinessJustification] =
    useState(null);

  const history = useHistory();
  // Justification details validation variables
  const hasFilesForUpload = uploadedDetailFiles?.length > 0;
  const isJustificationRequiredLength =
    justificationInputValue?.trim().length >= MIN_LENGTH_FOR_JUSTIFICATION;
  const isJustificationIncludeAngleBrackets = /<|>/.test(
    justificationInputValue
  );

  // TODO: (ROLES) look into store/retrieve roles info if needed
  // Redux store data
  const requestForm = useSelector((state) => state.credentialRequestForm);
  const {
    accountId: formAccountId,
    businessjustification: formJustification,
    startdate: formStartDate,
    enddate: formEndDate,
    requesttype: requestSessionRequestType,
    justintimedetails: { endpointname: jitEndpointName },
    remoteApp: {
      name: remoteAppName,
      displayName: remoteAppDisplayName,
      icon: remoteAppIcon,
      nativeLaunch: remoteNative,
    },
    privileges: formPrivileges,
    codes: formTcodes,
    targetEndpointKey: formTargetEndpointKey,
  } = useSelector((state) => state.credentialRequestForm.sessions[0]);
  const isDurationBeingEdited = useSelector(
    (state) => state.credentialRequestForm.isDurationBeingEdited
  );

  const handleIsCritical = (key, bool) => {
    setIsFormCritical((prevState) => ({
      ...prevState,
      [key]: bool,
    }));
  };

  // Form validation
  const isStartDateValid = () => {
    const currentDate = getCurrentDate();
    const isFormStartDateBeforeCurrentDateFuture =
      new Date(formStartDate) > currentDate;

    // Skip the check for Now, because that is updated in the finalSubmitPayload handlers
    if (isNowTab) return true;

    return isFormStartDateBeforeCurrentDateFuture;
  };

  const isCommonKeysFilled = Boolean(
    requestForm.requestor &&
      requestForm.requestedfor &&
      formStartDate &&
      formEndDate &&
      isJustificationRequiredLength &&
      !isJustificationIncludeAngleBrackets &&
      !isJustificationInputCritical &&
      isStartDateValid()
  );

  // Basic JIT
  const isJITInfoFilled = Boolean(
    isCheckoutRole || // simply set to true if roles
      (requestSessionRequestType &&
        (jitEndpointName || formPrivileges?.length > 0))
  );

  // Basic Remote app enabled
  const isRemoteDetailsFilled = Boolean(
    (formAccountId || isJITInfoFilled) && remoteAppName && remoteAppDisplayName
  );

  const isSapCheckValid = !isSAP || formTcodes?.length > 0;

  const isCredentialRequestFormFilled = Boolean(
    ((!isDurationBeingEdited && isCommonKeysFilled && formAccountId) ||
      (!isDurationBeingEdited && isCommonKeysFilled && isJITInfoFilled)) &&
      isSapCheckValid
  );

  const handleCredentialFormRequest = () => {
    if (isUserAbleToSubmit) setShouldAttemptSubmit(true);

    const isEpochDate = (date) =>
      new Date(date).getTime() <=
      new Date('1970-01-02T00:00:00.000Z').getTime();

    setHasCredentialFormRequestValidationPassed(false);

    // Account
    if (!formAccountId && !isJITInfoFilled) {
      handleIsCritical('accountId', true);
    }

    // App Launcher
    if ((formAccountId || isJITInfoFilled) && !isRemoteDetailsFilled) {
      handleIsCritical('appSelection', true);
    }

    // TCode
    if ((formAccountId || isJITInfoFilled) && formTcodes?.length < 1) {
      handleIsCritical('tCodeSelection', true);
    }

    // Duration
    const isCOCWithAccount = isCOCModal && formAccountId;
    const isNotRAEWithAccount = !isRemoteAppEnabled && formAccountId;
    const isRAEWithAccount = isRemoteAppEnabled && isRemoteDetailsFilled;
    const isDateSet =
      isCOCWithAccount ||
      isNotRAEWithAccount ||
      isRAEWithAccount ||
      isJITInfoFilled;

    if (isDateSet) {
      if (!formStartDate || isEpochDate(formStartDate) || !isStartDateValid())
        handleIsCritical('startdate', true);

      if (!formEndDate || isEpochDate(formEndDate))
        handleIsCritical('enddate', true);
    }

    if (isDurationBeingEdited && hasDurationBeenFilled) {
      handleIsCritical('isDurationBeingEdited', true);
      setIsDurationEditingAlertVisible(true);
    }

    // Justification Details
    if (
      (isJustificationIncludeAngleBrackets || !isJustificationRequiredLength) &&
      hasDurationBeenFilled
    ) {
      setIsJustificationInputCritical(true);
    }

    if (isCredentialRequestFormFilled && !hasErrorInDetailFiles) {
      dispatch(
        handleCredentialFormJustification(
          justificationInputValue,
          'Justification success'
        )
      );
      setCurrentBusinessJustification(justificationInputValue);
      setHasCredentialFormRequestValidationPassed(true);
      setIsRequestLoading(true);
    }

    if (
      isCredentialRequestSuccessful &&
      hasCredentialFormRequestValidationPassed
    ) {
      setShouldRetryUploadAPI(true);
    }
  };

  useEffect(() => {
    const hasJustInReduxAndCorrectFiles =
      isCredentialRequestFormFilled &&
      currentBusinessJustification &&
      !hasErrorInDetailFiles;

    if (hasJustInReduxAndCorrectFiles) {
      if (isNowTab) {
        setNowTabStartDate(getCurrentDate());
      }

      setIsUserAbleToSubmit(true);
    }
  }, [
    requestForm.requestor,
    requestForm.requestedfor,
    formAccountId,
    formStartDate,
    formEndDate,
    formJustification,
    currentBusinessJustification,
  ]);

  const getBizJustification = () =>
    ticketNumber?.length > 0
      ? `${ticketNumber.trim()}[ticket-${ticketNumber.trim()}]`
      : currentBusinessJustification;

  useEffect(() => {
    if (
      isCredentialRequestFormFilled &&
      formJustification &&
      !isTimeBlocked &&
      isUserAbleToSubmit
    ) {
      let finalSubmitPayload = null;
      let submitStartDate = null;
      let submitEndDate = null;

      // Calculate dates based on current tab
      if (isNowTab) {
        const differenceInSeconds =
          (new Date(formEndDate) - new Date(formStartDate)) / 1000;

        submitStartDate = new Date();
        submitStartDate.setSeconds(submitStartDate.getSeconds());
        submitEndDate = new Date(
          submitStartDate.getTime() + differenceInSeconds * 1000
        );
      } else {
        submitStartDate = new Date(formStartDate);
        submitStartDate.setSeconds(submitStartDate.getSeconds() + 1);
        submitEndDate = new Date(formEndDate);
      }

      // Prepare finalSubmitPayload object
      if (isCheckoutRole) {
        finalSubmitPayload = {
          accesstype: 'ROLES',
          rolerequesttype: 'ADD',
          requestedfor: requestForm.requestedfor,
          roles: [
            {
              businessjustification: getBizJustification(),
              startdate: submitStartDate.toISOString(),
              enddate: submitEndDate.toISOString(),
              accountId: null, // this will be stripped out below
              rolename: endpointOrRoleDetails.roleName, // TODO (ROLES) requestForm.roleName?
            },
          ],
          roletype: 'FIREFIGHTER',
          comments: payloadComment,
          requestor: requestForm.requestor,
          username: requestForm.requestor,
        };
      } else {
        finalSubmitPayload = {
          accesstype: 'PRIVILEGEDACCESS',
          // Always username from cookies (requestor && requestedfor)
          requestor: requestForm.requestor,
          requestedfor: requestForm.requestedfor,
          comments: payloadComment,

          sessions: [
            {
              // Always required (businessjustification && startdate && enddate)
              businessjustification: getBizJustification(),
              startdate: submitStartDate.toISOString(),
              enddate: submitEndDate.toISOString(),

              // Used in all flows where an actual account is selected
              accountId: formAccountId,

              // JIT selected
              requesttype: requestSessionRequestType,
              justintimedetails: { endpointname: jitEndpointName },

              // Remote App enabled account selected
              ...{
                remoteApp: {
                  name: remoteAppName,
                  displayName: remoteAppDisplayName,
                  icon:
                    remoteAppDisplayName === REMOTE_APP_DISPLAY_NAME.TERMINAL
                      ? REMOTE_APP_DISPLAY_NAME.TERMINAL.toLowerCase()
                      : remoteAppIcon,
                  nativeLaunch: remoteNative,
                },
              },
              privileges: formPrivileges,
              codes: formTcodes?.map((code) => code.label).join(','),

              // Set Endpoint Key if account is Federated Account
              targetEndpointKey: formTargetEndpointKey,
            },
          ],
          // Only used in COC normal flow: 'credentials'
          pamtype: requestForm.pamtype,
        };
      } // end else

      // Use the function to clean your payload
      finalSubmitPayload = trimEmptyUndefinedNull(finalSubmitPayload);

      // Dispatch actions
      dispatch(
        handleCredentialFormDates(
          formatDateToReduxStore(submitStartDate),
          formatDateToReduxStore(submitEndDate),
          'Date Range success'
        )
      );

      // Post the request and handle the response
      postCredentialRequest(finalSubmitPayload)
        .then(async (res) => {
          // TODO: Update with API res changes, or remix with falsey checks in the future
          const isRequestSuccess = Boolean(res?.requests?.[0]);

          if (!isRequestSuccess) {
            res.json().then((errorBody) => {
              setIsFormFailureAlertVisible(true);
              setSubmitResponseErrorMessage(errorBody.message);
              setIsRequestLoading(false);
            });
          } else {
            dispatch(
              requestSubmittedId(
                res?.requests?.[0]?.requestid,
                isCheckoutRole,
                'success'
              )
            );

            if (hasFilesForUpload) {
              setCredentialResponseData(res?.requests?.[0]);
            } else {
              setIsCredentialRequestSuccessful(true);
              setIsRequestLoading(false);
            }
          }
        })
        .catch((error) => {
          Logger.error('Error during Check Out Request:', error);
        });
    }

    setCurrentBusinessJustification(null);
    setIsUserAbleToSubmit(false);
    setShouldAttemptSubmit(false);
  }, [formEndDate, isUserAbleToSubmit, shouldAttemptSubmit]);

  useEffect(() => {
    // Handle file upload if necessary
    if (credentialResponseData && hasFilesForUpload && !hasErrorInDetailFiles) {
      const filesData = assembleFilesForApi(uploadedDetailFiles);

      (async () => {
        const uploadResponse = await uploadAttachments(
          credentialResponseData.requestkey,
          filesData
        );

        if (uploadResponse.status === 'error' || hasErrorInDetailFiles) {
          setIsCredentialRequestSuccessful(true);
          setIsRequestLoading(false);
        } else {
          setIsFilesUploadSuccessful(true);
          setIsCredentialRequestSuccessful(true);
          setIsRequestLoading(false);
        }
      })();
    }

    if (shouldRetryUploadAPI) {
      setShouldRetryUploadAPI(false);
    }
  }, [credentialResponseData, shouldRetryUploadAPI]);

  // Send user back and reset Form in redux store if request is success and upload success
  useEffect(() => {
    if (!isCredentialRequestSuccessful) return;

    if (hasFilesForUpload) {
      if (isFilesUploadSuccessful) {
        history.push(REQUEST_BASE_URL);
        dispatch(
          handleClearCredentialForm('Clear Credential Request Form success')
        );
      } else {
        // TODO: If there is an upload error, handleCredentialFormRequest should behave in a new way
        // to simple allow the user to submit upload api using the credentialResponseData.
        Logger.error(
          'Check form submission process, potential issue detected in upload process.'
        );
        dispatch(requestFileUploadFailed(userName));
        history.push(REQUEST_BASE_URL);
      }
    } else {
      history.push(REQUEST_BASE_URL);
      dispatch(
        handleClearCredentialForm('Clear Credential Request Form success')
      );
    }
  }, [isCredentialRequestSuccessful, isFilesUploadSuccessful]);

  // Reset Crits OnChange
  useEffect(() => {
    if (formAccountId || isJITInfoFilled) handleIsCritical('accountId', false);
  }, [formAccountId, isJITInfoFilled]);

  useEffect(() => {
    if (remoteAppName) handleIsCritical('appSelection', false);
    // Reset this so when it opens on account switch, it will not be critical
    if (!formAccountId) handleIsCritical('appSelection', false);
  }, [remoteAppName, formAccountId]);

  useEffect(() => {
    if (formStartDate) handleIsCritical('startdate', false);
  }, [formStartDate, isDurationBeingEdited]);

  useEffect(() => {
    if (formEndDate) handleIsCritical('enddate', false);
  }, [formEndDate, isDurationBeingEdited]);

  useEffect(() => {
    if (isDurationBeingEdited) handleIsCritical('isDurationBeingEdited', false);
  }, [isDurationBeingEdited]);

  useEffect(() => {
    if (formTcodes?.length > 0) handleIsCritical('tCodeSelection', false);
  }, [formTcodes]);

  useEffect(() => {
    if (!isJustificationInputCritical && !isJustificationIncludeAngleBrackets)
      setIsJustificationInputCritical(false);
  }, [justificationInputValue]);

  return {
    isCredentialRequestFormFilled,
    submitResponseErrorMessage,
    handleCredentialFormRequest,
  };
};

export default ModalPageFormSubmissionService;
