/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-curly-spacing */
import React, { useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Logger,
  removeClassesForHidingElements,
  useDetectScroll,
  useLocalize,
  useResizeObserver,
} from '@saviynt/common';
import {
  AlertBanner,
  Badge,
  Box,
  ButtonSelect,
  Checkbox,
  Icon,
  InlineMessage,
  InputField,
  Link,
  Loader,
  MenuMulti,
  Typography,
} from '@saviynt/design-system';
import { mfaPrompt } from '@saviynt/nav';
import classnames from 'classnames';

import CheckInModal from '../../components/CheckInModal/CheckInModal';
import Extend from '../../components/Extend/Extend';
import ListRowWrapper from '../../components/LandingPage/ListRowWrapper';
import LandingPageSidePanel from '../../components/LandingPageSidePanel/LandingPageSidePanel';
import { MODAL_PAGE_KINDS } from '../../components/ModalPageForms/constants';
import MoreRecordsMessage from '../../components/MoreRecordsMessage/MoreRecordsMessage';
import SessionLauncher from '../../components/SessionLauncher/SessionLauncher';
import SessionTargetDetailsModalIsland from '../../components/SessionLauncher/SessionTargetDetailsModalIsland/SessionTargetDetailsModalIsland';
import useFetchListRowData from '../../hooks/use-fetchlistrowdata';
import { msgs, REQUEST_BASE_URL } from '../../misc/constants';
import { mockData } from '../../models/MockData/MockData';
import LandingPageFilterService from '../../services/LandingPage/LandingPageFilterService';
import LandingPagePaginationService from '../../services/LandingPage/LandingPagePaginationService';
import {
  clearFileUploadStatus,
  clearRequestSubmittedId,
  listAPIListReceived,
  selectedListAccountRemoteMetadatas,
  selectedListDetails,
  selectedListIsExclusiveAccess,
  selectedListMaxCredlessSessionRequestTime,
  setSessionToLaunch as setSessionToLaunchAction,
} from '../../store/actions';
import displayAlertBannersForRequestSubmit from '../../utilities/displayAlertBannersForRequestSubmit';
import hideHostElementsWrapper, {
  removeDynamicBodyClasses,
} from '../../utilities/hideHostElements';

import { renderFilterPills } from './helpers';

import './LandingPage.css';

function LandingPage() {
  const [currentListItem, setCurrentListItem] = useState(null);
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  const [listRowData, setListRowData] = useState({});
  const [isFilterPillsVisible, setIsFilterPillsVisible] = useState(true);
  // Filter / Search
  const [searchBarValue, setSearchBarValue] = useState('');
  const [filteredEntities, setFilteredEntities] = useState(null);
  // Check In Modal
  const [isCheckInModalOpen, setIsCheckInModalOpen] = useState(false);
  const [checkInModalKind, setCheckInModalKind] = useState('');
  const [iconClickedForRow, setIconClickedForRow] = useState(null);
  // Hide Session Checkbox
  const [isHideSessionsChecked, setisHideSessionsChecked] = useState(false);
  const [alertBannerData, setAlertBannerData] = useState([]);
  // Extend Modal
  const [isExtendModalOpen, setIsExtendModalOpen] = useState(false);
  const [recordsCount, setRecordsCount] = useState(0);

  const [sidePanelWidth, setSidePanelWidth] = useState(0);
  const sidePanelRef = useResizeObserver((rect) => {
    setSidePanelWidth(rect.width);
  });

  const MAX_RECORDS = 100;
  const LISTROW_TYPES = {
    SESSION: 'session',
    ENDPOINT: 'endpoint',
    ROLE: 'role',
  };

  // Launch Session Form
  const [sessionToLaunch, setSessionToLaunch] = useState(null);
  // TODO: Needs to be set by some condition and name changed. Used here for DX and ready for implementation.
  const [isSessionTargetModalVisible, setIsSessionTargetModalVisible] =
    useState(false);
  const [sessionDetailsForAd, setSessionDetailsForAd] = useState(null);
  const localize = useLocalize();

  const { requestId, isRoleRequest } = useSelector(
    (state) => state.requestSubmittedDetails
  );

  const isLastRequestFileUploadFailed =
    useSelector((state) => state.fileUploadStatus.uploadFailed) || false;

  const [cookies] = useCookies(['user_name', 'leftNavGen2']);
  const userName = cookies.user_name;
  const { leftNavGen2 } = cookies;

  const mfaSessionToLaunch = useSelector(
    (state) => state.privilegedAccess.SessionToLaunch
  );

  const handleChange = (event) => {
    setisHideSessionsChecked(event.target.checked);
  };

  // References
  const landingPageContentRef = useRef(null);
  const sessionTargetDetailsModalContentRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const isUsingMockData = false; // TODO: remove mock data when features are complete.

  const verifyMfaAndSetSessionToLaunch = (session) => {
    dispatch(
      mfaPrompt.actions.verificationRequired(setSessionToLaunchAction(session))
    );
  };

  // Filter Dependencies
  const {
    platform,
    asset,
    role,
    isFilterChecked,
    clearAllFilters,
    checkedPlatforms,
    checkedAssets,
    checkedRoles,
  } = LandingPageFilterService(setIsSidePanelOpen, setCurrentListItem);

  // Pagination Dependencies //
  const { pageIndex, paginatedEntities } =
    LandingPagePaginationService(filteredEntities);

  const contentClasses = classnames(
    'LandingPage-content',
    isSidePanelOpen && 'LandingPage-content--withSidePanel'
  );

  const titleContainerClasses = classnames(
    'LandingPage-titleContainer',
    isSidePanelOpen && 'LandingPage-titleContainer--withSidePanel'
  );

  const listRowContainerClasses = classnames(
    'LandingPage-listRows',
    isSidePanelOpen && 'LandingPage-listRows--withSidePanel'
  );

  const rowIsSelected = (listItem) => currentListItem === listItem;

  const listRowOnClick = (row) => {
    setIsSidePanelOpen(true);
    setCurrentListItem(row);
  };

  useEffect(() => {
    // Reset AccountRemoteMetadata and Exclusive Access on mount
    dispatch(selectedListAccountRemoteMetadatas(null, 'reset success'));
    dispatch(selectedListIsExclusiveAccess(false, 'reset success'));
    // TODO: Make sure from AC that this should be 10 hours
    dispatch(
      selectedListMaxCredlessSessionRequestTime('36000', 'reset success')
    );

    removeDynamicBodyClasses();

    if (requestId) {
      displayAlertBannersForRequestSubmit(
        requestId,
        userName,
        isLastRequestFileUploadFailed,
        setAlertBannerData,
        msgs,
        localize,
        isRoleRequest
      );
      dispatch(clearRequestSubmittedId('success'));
      dispatch(clearFileUploadStatus());
    }

    // TODO: remove the next 8 lines when we're only using the new unified nav.
    if (leftNavGen2 === '0') {
      const alertBannersContainer = document.querySelector(
        '.LandingPage-alertBannerContainer'
      );

      alertBannersContainer.style.left = '3rem';
      alertBannersContainer.style.right = '3rem';
    }

    // hide host toast messages, etc when flag is set
    hideHostElementsWrapper();

    return () => {
      removeClassesForHidingElements();
    };
  }, []);

  useEffect(() => {
    // TODO: Ask the team, should we use this for all the state handling for this page now that we have it stored in redux? So we don't have repeated selected listRow states. I need it in Redux for the COC and session headers and account endpointKey.
    dispatch(selectedListDetails(currentListItem, 'success'));
  }, [currentListItem]);

  const sidePanelOnClose = () => {
    setIsSidePanelOpen(false);
    setCurrentListItem(null);
  };

  useEffect(() => {
    // Close the sidepanel when page is changed or search made
    setIsSidePanelOpen(false);
    setCurrentListItem(null);
  }, [pageIndex, searchBarValue]);

  const { isScrolled: sessionTargetDetailsModalSectionRefIsScrolled } =
    useDetectScroll(null, sessionTargetDetailsModalContentRef);

  useEffect(() => {
    if (isUsingMockData) {
      Logger.log('Using mock data on localhost', mockData);
      setListRowData(mockData);
    }

    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  useEffect(() => {
    if (listRowData && Object.keys(listRowData).length !== 0) {
      setFilteredEntities(listRowData);
      setIsSidePanelOpen(false);
    }

    setRecordsCount(listRowData.count);

    // To get data stored in redux store on getting response from API
    if (listRowData && Object.keys(listRowData).length > 0) {
      dispatch(listAPIListReceived(listRowData, 'success'));
    }
  }, [listRowData]);

  useEffect(() => {
    const sidePanel = sidePanelRef.current;

    if (sidePanel) {
      landingPageContentRef.current.style.width = `calc(100% - ${sidePanelWidth}px + 2px)`;
    } else {
      landingPageContentRef.current.style.width = '';
    }
  }, [sidePanelWidth, isSidePanelOpen]);

  const { isListRowApiLoading, isListRowApiError, listRowApiError } =
    useFetchListRowData(
      searchBarValue,
      checkedPlatforms,
      checkedAssets,
      checkedRoles,
      setListRowData,
      isUsingMockData
    );

  useEffect(() => {
    if (isListRowApiError) {
      setAlertBannerData((prevState) => [
        ...prevState,
        {
          colorTheme: 'Critical',
          title: localize(msgs.pam.landingPage.errors.assetsNotLoaded),
          description: localize(
            msgs.pam.landingPage.errors.assetsNotLoadedDesc
          ),
          shouldAutoDismiss: false,
        },
      ]);
    }
  }, [isListRowApiError, listRowApiError]);

  useEffect(() => {
    if (mfaSessionToLaunch) {
      setSessionToLaunch(mfaSessionToLaunch);
      dispatch(setSessionToLaunchAction(null));
    }
  }, [mfaSessionToLaunch]);

  const renderListOrNoData = () => {
    if (!paginatedEntities) {
      return null;
    }

    if (isListRowApiLoading) {
      return (
        <div className='LandingPage-loaderContainer'>
          <Loader kind='loop' format='main' color='brand' size='large' />
        </div>
      );
    }

    if (paginatedEntities.length === 0) {
      return (
        <InlineMessage>
          <Box className='LandingPage-noMatchingSearchRecords'>
            <Icon kind='alertCriticalOutline' />
            <Typography
              kind='h3'
              className='LandingPage-noMatchingSearchRecords-title'>
              {localize(msgs.pam.landingPage.noMatchingSearchRecords)}
            </Typography>
          </Box>
        </InlineMessage>
      );
    }

    const sessionEntities = paginatedEntities.filter(
      (item) => item.type === LISTROW_TYPES.SESSION
    );
    const endpointOrRoleEntities = paginatedEntities.filter(
      (item) =>
        item.type === LISTROW_TYPES.ENDPOINT || item.type === LISTROW_TYPES.ROLE
    );

    const hasSessions = sessionEntities.length > 0;
    const hasEndpointsOrRoles = endpointOrRoleEntities.length > 0;

    return (
      <div>
        {hasSessions && !isHideSessionsChecked && (
          <section>
            <div className='LandingPage-listrow-section-title'>
              <div className='LandingPage-listrow-section-title-startLine' />
              <div className='LandingPage-listrow-section-title-label'>
                {localize(msgs.pam.landingPage.sessionsHeader)}
              </div>
              <div className='LandingPage-listrow-section-title-endLine' />
            </div>

            <div className='LandingPage-listrow-section LandingPage-listrow-section-sessions'>
              {sessionEntities.map((listItemObj, i) => (
                <ListRowWrapper
                  key={listItemObj.requestAccessKey}
                  index={i}
                  kind={LISTROW_TYPES.SESSION}
                  listItemObj={listItemObj}
                  isCheckInModalOpen={isCheckInModalOpen}
                  setIsCheckInModalOpen={setIsCheckInModalOpen}
                  setIconClickedForRow={setIconClickedForRow}
                  setCheckInModalKind={setCheckInModalKind}
                  isSelected={rowIsSelected}
                  listRowOnClick={listRowOnClick}
                  listRowData={listRowData}
                  setListRowData={setListRowData}
                  setIsExtendModalOpen={setIsExtendModalOpen}
                  onLaunchSession={verifyMfaAndSetSessionToLaunch}
                  setIsSessionTargetModalVisible={
                    setIsSessionTargetModalVisible
                  }
                  setSessionDetailsForAd={setSessionDetailsForAd}
                />
              ))}
            </div>
          </section>
        )}

        {hasEndpointsOrRoles && (
          <section>
            <div className='LandingPage-listrow-section-title'>
              <div className='LandingPage-listrow-section-title-startLine' />
              <div className='LandingPage-listrow-section-title-label'>
                {localize(msgs.pam.landingPage.availableAssetsHeader)}
              </div>
              <div className='LandingPage-listrow-section-title-endLine' />
            </div>
            {recordsCount - sessionEntities.length > MAX_RECORDS && (
              <MoreRecordsMessage localize={localize} />
            )}
            <div className='LandingPage-listrow-section LandingPage-listrow-section-endpoints'>
              {endpointOrRoleEntities.map((listItemObj, i) => (
                <ListRowWrapper
                  // eslint-disable-next-line react/no-array-index-key -- endpointKey not unique across types.
                  key={`${listItemObj.endpointKey}-${i}`}
                  index={i}
                  kind={LISTROW_TYPES.ENDPOINT}
                  listItemObj={listItemObj}
                  isCheckInModalOpen={isCheckInModalOpen}
                  setIsCheckInModalOpen={setIsCheckInModalOpen}
                  setIconClickedForRow={setIconClickedForRow}
                  setCheckInModalKind={setCheckInModalKind}
                  isSelected={rowIsSelected}
                  listRowOnClick={listRowOnClick}
                  listRowData={listRowData}
                  setListRowData={setListRowData}
                  setIsExtendModalOpen={setIsExtendModalOpen}
                  onLaunchSession={verifyMfaAndSetSessionToLaunch}
                  setIsSessionTargetModalVisible={
                    setIsSessionTargetModalVisible
                  }
                  setSessionDetailsForAd={setSessionDetailsForAd}
                />
              ))}
            </div>
          </section>
        )}
      </div>
    );
  };

  const handleRequestButtonClick = (page) => {
    if (page === MODAL_PAGE_KINDS.COC) {
      history.push(`${REQUEST_BASE_URL}/check-out-credential`);
    } else if (page === MODAL_PAGE_KINDS.CTS) {
      history.push(`${REQUEST_BASE_URL}/connect-to-session`);
    } else if (page === MODAL_PAGE_KINDS.COR) {
      history.push(`${REQUEST_BASE_URL}/check-out-role`);
    } else {
      Logger.error('Invalid pam page kind');
    }
  };

  return (
    <div
      className={classnames(
        'LandingPage LandingPage--pam',
        leftNavGen2 === '1' && 'LandingPage--leftNavGen2'
      )}
      data-testid='LandingPage'>
      <div className='LandingPage-layout'>
        <section ref={landingPageContentRef} className={contentClasses}>
          <section className={titleContainerClasses}>
            <div className='LandingPage-title'>
              {localize({
                id: 'pam.landingPage.title',
                defaultMessage: 'Privileged Access Assets & Sessions',
              })}
            </div>
            <div className='LandingPage-description'>
              {localize(msgs.pam.landingPage.description)}
            </div>
          </section>
          <section className='LandingPage-barPillsAndPage'>
            <div className='LandingPage-barAndPage'>
              <div className='LandingPage-searchBarContainer'>
                <div className='LandingPage-searchBar'>
                  <InputField
                    name='basic'
                    kind='search'
                    placeholder={localize(
                      msgs.pam.landingPage.userAccessSearchInputPlaceholder
                    )}
                    prefixIcon={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <Icon
                        kind='search'
                        color='neutral-600'
                        size='smallMedium'
                      />
                    }
                    value={searchBarValue}
                    setValue={setSearchBarValue}
                  />
                </div>
                <div className='LandingPage-filterButton'>
                  <ButtonSelect
                    label='Filter'
                    isOpen={isFilterPillsVisible}
                    size='large'
                    prefixIcon={<Icon kind='Filter' color='neutral-600' />}
                    onClick={() =>
                      setIsFilterPillsVisible(!isFilterPillsVisible)
                    }
                    BadgeComp={
                      isFilterChecked ? (
                        <Badge kind='dot' color='primary' size='small' />
                      ) : null
                    }
                  />
                </div>
              </div>
            </div>
            {isFilterPillsVisible && (
              <section
                className={`LandingPage-filterPills ${
                  !isFilterPillsVisible
                    ? 'LandingPage-filterPills--isClosed'
                    : null
                }`}>
                {renderFilterPills(
                  MenuMulti,
                  ButtonSelect,
                  platform,
                  asset,
                  role
                )}
                {isFilterChecked ? (
                  <Link
                    href='Filter Clear Button'
                    text='Clear'
                    kind='button'
                    onClick={() => {
                      clearAllFilters();
                    }}
                  />
                ) : null}
                {paginatedEntities?.filter(
                  (item) => item?.type === LISTROW_TYPES.SESSION
                ).length > 0 && (
                  <div className='LandingPage-hideSessions'>
                    <Checkbox
                      name='hideSessions'
                      kind='simpleText'
                      label={localize(msgs.pam.landingPage.hideSessions)}
                      isChecked={isHideSessionsChecked}
                      onChange={handleChange}
                      dataTestId='hideSessions'
                      size='small'
                      textPosition='after'
                      showHoverEffect
                    />
                  </div>
                )}
              </section>
            )}
          </section>
          <section className={listRowContainerClasses}>
            {renderListOrNoData()}
          </section>
        </section>
      </div>
      {isSidePanelOpen && (
        <aside className='LandingPage-sidePanelContainer' ref={sidePanelRef}>
          <LandingPageSidePanel
            data={currentListItem}
            isOpen={isSidePanelOpen}
            onClose={sidePanelOnClose}
            credentialRequestOnClick={handleRequestButtonClick}
            // TODO: (ROLES) combine these 2 props as possibly 'onRequestButtonClick'
            checkoutRoleOnClick={handleRequestButtonClick}
            setIsCheckInModalOpen={setIsCheckInModalOpen}
            setIconClickedForRow={setIconClickedForRow}
            setCheckInModalKind={setCheckInModalKind}
            setIsExtendModalOpen={setIsExtendModalOpen}
            setSessionToLaunch={verifyMfaAndSetSessionToLaunch}
            setIsSessionTargetModalVisible={setIsSessionTargetModalVisible}
            setSessionDetailsForAd={setSessionDetailsForAd}
            className='LandingPage-sidePanel'
          />
        </aside>
      )}
      {isCheckInModalOpen && (
        <CheckInModal
          setIsModalOpen={setIsCheckInModalOpen}
          isModalOpen={isCheckInModalOpen}
          row={iconClickedForRow}
          setListRowData={setListRowData}
          kind={checkInModalKind}
          setCheckInModalKind={setCheckInModalKind}
          userName={userName}
          setAlertBannerData={setAlertBannerData}
        />
      )}
      {isExtendModalOpen && (
        <Extend
          isExtendModalOpen={isExtendModalOpen}
          setIsExtendModalOpen={setIsExtendModalOpen}
          row={iconClickedForRow}
          setAlertBannerData={setAlertBannerData}
          setListRowData={setListRowData}
          userName={userName}
        />
      )}
      <section className='LandingPage-alertBannerContainer'>
        {alertBannerData.map((banner, index) => (
          <AlertBanner
            key={banner.description}
            colorTheme={banner.colorTheme}
            title={banner.title}
            description={banner.description}
            isVisible={banner.isVisible}
            onCancel={() => {
              // set isVisible to false for the animation.
              setAlertBannerData((prevState) =>
                prevState.map((currBanner, i) =>
                  i === index ? { ...currBanner, isVisible: false } : currBanner
                )
              );
            }}
            isColonVisible={false}
            isFloating
            shouldAutoDismiss={banner?.shouldAutoDismiss ?? true}
            autoDismissDelay={5000}
          />
        ))}
      </section>
      {sessionToLaunch && <SessionLauncher session={sessionToLaunch} />}
      {/* TODO: Update to render/ visible for correct conditions */}
      {isSessionTargetModalVisible && (
        <SessionTargetDetailsModalIsland
          setSessionToLaunch={verifyMfaAndSetSessionToLaunch}
          sessionDetailsForAd={sessionDetailsForAd}
          isSessionTargetModalVisible={isSessionTargetModalVisible}
          setIsSessionTargetModalVisible={setIsSessionTargetModalVisible}
          sessionTargetDetailsModalContentRef={
            sessionTargetDetailsModalContentRef
          }
          sectionRefIsScrolled={sessionTargetDetailsModalSectionRefIsScrolled}
        />
      )}
    </div>
  );
}

export default LandingPage;
