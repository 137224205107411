/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import './RequestAccessModal.scss';

export const Close = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.62204 8.62204C8.78476 8.45932 9.04858 8.45932 9.21129 8.62204L16 15.4107L22.7887 8.62209C22.9514 8.45937 23.2152 8.45937 23.378 8.62209C23.5407 8.78481 23.5407 9.04862 23.3779 9.21134L16.5893 16L23.3779 22.7887C23.5407 22.9514 23.5407 23.2152 23.378 23.3779C23.2152 23.5406 22.9514 23.5406 22.7887 23.3779L16 16.5893L9.21129 23.378C9.04858 23.5407 8.78476 23.5407 8.62204 23.378C8.45932 23.2152 8.45932 22.9514 8.62204 22.7887L15.4108 16L8.62204 9.21129C8.45932 9.04858 8.45932 8.78476 8.62204 8.62204Z"
        fill="#212328"
      />
    </svg>
  );
};

export const NoAccess = () => {
  return (
    <svg
      width="480"
      height="136"
      viewBox="0 0 480 136"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.23857e-07 106C17.3191 99.0713 65.9121 94.1805 127.446 94.1805C188.98 94.1805 281.125 102.638 336.184 106C391.242 109.362 442.061 106 480 99.0713V0H4.23857e-07C4.23857e-07 0 -5.29817e-07 91.2117 4.23857e-07 106Z"
        fill="#F1ECFD"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M202.5 98C202.5 77.2893 219.289 60.5 240 60.5C260.711 60.5 277.5 77.2893 277.5 98C277.5 118.711 260.711 135.5 240 135.5C219.289 135.5 202.5 118.711 202.5 98Z"
        fill="#573BDC"
      />
      <path
        d="M232.698 87.2434C232.698 83.7443 235.813 80.6389 240 80.6389C244.187 80.6389 247.301 83.7443 247.301 87.2434C247.301 89.0953 246.456 90.8 245.031 92.0275L244.838 92.1937C243.459 93.3797 241.799 94.8073 240.486 96.4306C239.096 98.1479 237.916 100.293 237.916 102.876V105.232C237.916 106.382 238.849 107.315 240 107.315C241.15 107.315 242.083 106.382 242.083 105.232V102.876C242.083 101.623 242.646 100.385 243.724 99.0517C244.796 97.7275 246.202 96.5172 247.663 95.2596L247.749 95.185C250.008 93.2398 251.468 90.4172 251.468 87.2434C251.468 81.1462 246.179 76.4722 240 76.4722C233.82 76.4722 228.531 81.1462 228.531 87.2434C228.531 88.394 229.464 89.3267 230.615 89.3267C231.765 89.3267 232.698 88.394 232.698 87.2434Z"
        fill="white"
      />
      <path
        d="M240 117.444C241.728 117.444 243.128 116.044 243.128 114.316C243.128 112.588 241.728 111.188 240 111.188C238.272 111.188 236.872 112.588 236.872 114.316C236.872 116.044 238.272 117.444 240 117.444Z"
        fill="white"
      />
    </svg>
  );
};
const Dialog = withStyles({
  root: {
    zIndex: '1500 !important',
  },
})(MuiDialog);

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: '0',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    padding: '5px',
    '&:hover, &:focus': {
      backgroundColor: `transparent`,
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} timeout={1000} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { classes, onClose } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <div className="no-access-bg-desktop">
        <NoAccess />
      </div>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          data-testid="closeOwnerModalBtn"
        >
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(() => ({
  root: {
    background: '#fff',
  },
}))(MuiDialogContent);

const NoAccessModal = ({ onDialogClose, open, intl }) => {
  const isMobileDevice = useMediaQuery('(max-width:480px)');

  return (
    <Dialog
      open={open}
      onClose={onDialogClose}
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          minHeight: '352px',
          width: '480px',
          maxWidth: '480px',
          borderRadius: `${isMobileDevice ? '0px' : '12px'}`,
        },
      }}
    >
      <DialogTitle id="customized-dialog-title" onClose={onDialogClose} intl={intl} />
      <DialogContent className="no-access-content">
        <div className="no-access-modal">
          <div className="no-access-section text-center">
            <h4>
              {intl.formatMessage({
                id: 'NewRequestHome.QuickLink.NoAccessModal.Title',
              })}
            </h4>
            <p>
              {intl.formatMessage({
                id: 'NewRequestHome.QuickLink.NoAccessModal.Description',
              })}
            </p>
            <Button
              className="no-access-close-btn"
              variant="outlined"
              color="primary"
              onClick={onDialogClose}
            >
              {intl.formatMessage({
                id: 'NewRequestHome.QuickLink.NoAccessModal.CloseButton.Label',
              })}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

NoAccessModal.propTypes = {
  onDialogClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  intl: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};

export default injectIntl(withRouter(NoAccessModal));
