/* eslint-disable no-use-before-define */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import { injectIntl } from 'react-intl';
import './FormComponents.scss';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '8px',
    alignContent: 'flex-end',
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
  label: {
    display: 'flex',
    flexWrap: 'wrap',
    alignSelf: 'flex-end',
  },
  helperText: {
    margin: '10px 0 0',
    fontSize: '12px',
    lineHeight: 1.4,
  },
  field: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    alignSelf: 'flex-end',
  },
  popper: {
    marginTop: 50,
    zIndex: 99999,
  },
}));

const MultiSelectSQLDropDown = ({
  options,
  placeholder,
  _handleChange,
  attributeKey,
  defaultValue,
  required,
  showError,
  single,
  readOnly,
  descriptionType,
  scrollToError,
  intl,
  name,
  supportsHtmlTags,
}) => {
  const classes = useStyles();
  const inputTextFieldRef = useRef(null);
  const [items, setItems] = useState([]);
  const [optionDefaultValue, setOptionDefaultValue] = useState([]);

  const handleChange = (event, val) => {
    if (single) {
      _handleChange(val ? [val.ID] : [], attributeKey, val);
    } else {
      const values = [];
      if (val) {
        val.map(item => {
          values.push(item.ID);
        });
      }
      _handleChange(values || [], attributeKey, val);
    }
  };

  useEffect(() => {
    if (scrollToError === 'true') {
      inputTextFieldRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [scrollToError]);

  useEffect(() => {
    if (options && options.length === 0) {
      setItems([]);
      setOptionDefaultValue([]);
    } else {
      setItems(options);
      if (
        Object.keys(options).length &&
        Object.keys(defaultValue).length &&
        options.some(
          value =>
            value.ID ===
            (defaultValue.ID ? defaultValue.ID : defaultValue[0].ID ? defaultValue[0].ID : false)
        )
      )
        setOptionDefaultValue(defaultValue);
      else setOptionDefaultValue([]);
    }
  }, [options, defaultValue]);

  const optionView = option => {
    switch (true) {
      case descriptionType === 'description':
        if (option.description) {
          return (
            <Tooltip title={option.description} placement="top" arrow>
              <div className="option-tolltip w-100"> {option.ID} </div>
            </Tooltip>
          );
        }
        return <span className="option-tolltip"> {option.ID} </span>;

      case descriptionType === 'inlinedescription':
        return (
          <span className="option-tolltip w-100">
            {option.ID}: {option.description}
          </span>
        );
      default:
        return <span className="option-tolltip"> {option.ID} </span>;
    }
  };

  return (
    <div className={classes.root} ref={inputTextFieldRef}>
      <div className={classes.field}>
        {supportsHtmlTags && (
          <div className="support-html">
            <span dangerouslySetInnerHTML={{ __html: placeholder }} />
            {required && <span className="mandatory">&nbsp;*</span>}
          </div>
        )}
        <Autocomplete
          classes={{ popper: classes.popper }}
          id={name}
          disablePortal
          disabled={readOnly}
          multiple={!single}
          className={classes.root}
          size="small"
          options={items}
          getOptionLabel={option => (typeof option === 'string' ? option : option.ID)}
          renderOption={option => <React.Fragment>{optionView(option)}</React.Fragment>}
          filterSelectedOptions
          onChange={handleChange}
          value={optionDefaultValue}
          noOptionsText={intl.formatMessage({ id: 'ColumnFilter.NoOptionsText' })}
          getOptionSelected={(option, value) => option.ID === value.ID}
          renderInput={params => (
            <TextField
              {...params}
              error={required ? showError : false}
              fullWidth
              label={
                supportsHtmlTags ? (
                  ''
                ) : (
                  <>
                    <span dangerouslySetInnerHTML={{ __html: placeholder }} />
                    {required && <span className="mandatory">&nbsp;*</span>}
                  </>
                )
              }
            />
          )}
        />
      </div>
    </div>
  );
};

MultiSelectSQLDropDown.defaultProps = {
  supportsHtmlTags: false,
};

MultiSelectSQLDropDown.propTypes = {
  _handleChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  attributeKey: PropTypes.string.isRequired,
  defaultValue: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  required: PropTypes.bool.isRequired,
  showError: PropTypes.bool.isRequired,
  single: PropTypes.bool,
  readOnly: PropTypes.bool.isRequired,
  descriptionType: PropTypes.string.isRequired,
  supportsHtmlTags: PropTypes.bool,
};

MultiSelectSQLDropDown.defaultProps = {
  single: false,
};

export default injectIntl(MultiSelectSQLDropDown);
