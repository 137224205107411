import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TuneIcon from '@material-ui/icons/Tune';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Checkbox, FormControlLabel, Popover,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import {
  getResults, updateQuery, getSelectedServices, updateNoServicesSelected,
} from '../../../actions/logsAction';
import styles from './Filter.module.css';
import { defaultServices } from '../../../reducers/logsReducer';
import useQuery from '../../../utils/useQueryHook';
import { noServicesSelected, serviceNamesMapping } from '../../../../constant';

const useStyles = makeStyles({
  root: {
    borderRadius: '25px',
  },
  label: {
    color: '#1e1e1e',
  },
});

const Filter = ({ isDisplayed, intl }) => {
  const history = useHistory();
  const queryParam = useQuery();
  const classes = useStyles();
  const dispatch = useDispatch();
  const query = useSelector((state) => state.admin?.logs.query);
  const serviceNames = useSelector((state) => state.admin?.logs.services);
  const selectedServiceNames = useSelector((state) => state.admin?.logs.selectedServices);
  const { AUTHMS_ENABLED, PAM_SERVICES_ENABLED, TASKRUNNERMS_ENABLED } = useSelector((state) => state.user.domainInfo);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handlePopOver = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [checked, setChecked] = useState(serviceNames);
  const [checkAll, setCheckAll] = useState(true);

  useEffect(() => {
    setChecked(selectedServiceNames);
    setCheckAll(selectedServiceNames.length === defaultServices(AUTHMS_ENABLED,PAM_SERVICES_ENABLED, TASKRUNNERMS_ENABLED).length);
  }, [selectedServiceNames]);

  const clearQueryParams = () => {
    const url = '/logs';

    if (queryParam.get('fromDate')) {
      history.push(url);
    }
  };

  const handleChange = (event) => {
    const newChecked = [...checked];
    const isCheckAll = defaultServices(AUTHMS_ENABLED, PAM_SERVICES_ENABLED, TASKRUNNERMS_ENABLED).length === newChecked.length;

    if (event.target.checked) {
      newChecked.push(event.target.value);
    } else {
      newChecked.splice(checked.indexOf(event.target.value), 1);
    }

    setCheckAll(isCheckAll);
    setChecked(newChecked);

    if (newChecked.length === 0) {
      dispatch(updateQuery(Object.assign(query, {
        servicename: noServicesSelected,
      })));
      dispatch(getResults(query));
    } else {
      dispatch(updateQuery(Object.assign(query, {
        servicename: newChecked,
      })));
      dispatch(getResults(query));
      dispatch(updateNoServicesSelected(false));
    }

    dispatch(getSelectedServices(newChecked));
    clearQueryParams();
  };

  const handleCheckAll = (event) => {
    let newChecked = [...defaultServices(AUTHMS_ENABLED, PAM_SERVICES_ENABLED, TASKRUNNERMS_ENABLED)];

    const newQuery = Object.assign(query, {
      servicename: newChecked,
    });

    if (event.target.checked) {
      setChecked(newChecked);
      dispatch(updateNoServicesSelected(false));
    } else {
      newQuery.servicename = noServicesSelected;
      newChecked = [];
      setChecked(newChecked);
    }

    setCheckAll(!checkAll);
    dispatch(updateQuery(newQuery));
    dispatch(getResults(newQuery));
    dispatch(getSelectedServices(newChecked));
    clearQueryParams();
  };

  if (isDisplayed) {
    const buttonColor = selectedServiceNames.length > 0 ? 'primary' : 'default';

    return (
      <div className={styles.filterButtonContainer}>
        <Button
          data-testid="filterButton"
          variant="outlined"
          color={buttonColor}
          type="submit"
          className={classes.root}
          onClick={handlePopOver}
          startIcon={<TuneIcon />}
          endIcon={<ExpandMoreIcon />}
        >
          {intl.formatMessage({ id: 'MANAGE.LOGS.SERVICE.NAME.COLUMN.LABEL' })}
        </Button>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <div className={styles.heading} data-testid="filter-title">
            {intl.formatMessage({ id: 'MANAGE.LOGS.SERVICE.NAME.COLUMN.LABEL' })}
          </div>
          <div className={styles.listContainer}>
            <div>
              {serviceNames.map((value) => (
                <div key={value} className={styles.checkboxList}>
                  <FormControlLabel
                    labelid={`list-label-${value}`}
                    className={classes.label}
                    control={(
                      <Checkbox
                        checked={selectedServiceNames.indexOf(value) !== -1}
                        onChange={handleChange}
                        name="checkedB"
                        color="primary"
                        value={value}
                        data-testid={`check-${value}`}
                      />
                    )}
                    label={serviceNamesMapping(AUTHMS_ENABLED, PAM_SERVICES_ENABLED, TASKRUNNERMS_ENABLED).find((f) => f.value === value)?.name || value}
                  />
                </div>
              ))}
            </div>
            <div className={styles.hr} />
            <div className={styles.clearButton}>
              <FormControlLabel
                labelid="view-all"
                className={classes.label}
                control={(
                  <Checkbox
                    checked={checkAll}
                    onChange={handleCheckAll}
                    name="checkedB"
                    color="primary"
                    value={checkAll}
                    data-testid="checkAll"
                  />
                )}
                label={intl.formatMessage({ id: 'Admin.Log.Viewer.filter.viewAll' })}
              />
            </div>
          </div>
        </Popover>
      </div>
    );
  }

  return null;
};

export default injectIntl(Filter);
