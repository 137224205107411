import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import BrandingIconsBuilder from './BrandingIconsBuilder';

const SIZES = {
  'xx-xxxSmall': 'xx-xxxSmall',
  'x-xxxSmall': 'x-xxxSmall',
  xxxSmall: 'xxxSmall',
  xxSmall: 'xxSmall',
  xSmall: 'xSmall',
  small: 'small',
  smallMedium: 'smallMedium',
  medium: 'medium',
  large: 'large',
  xLarge: 'xLarge',
  xxLarge: 'xxLarge',
};

function BrandingIconBuilder({
  kind, color, size, rotate, className, dataTestId, colours = [],
}) {
  const iconName = kind.charAt(0).toUpperCase() + kind.slice(1);
  const classes = classnames(
    'Icon',
    size && `Icon--${size}`,
    rotate && `Icon--rotate-${rotate}`,
    className,
  );

  return (
    <span
      style={{ color }}
      className={classes}
      type="icon"
      dataTestId={`Icon-${dataTestId || kind}`}
    >
      {BrandingIconsBuilder[iconName](colours)}
    </span>
  );
}

BrandingIconBuilder.propTypes = {
  kind: PropTypes.oneOf(Object.keys(BrandingIconsBuilder)),
  size: PropTypes.oneOf(Object.values(SIZES)),
  rotate: PropTypes.oneOf(['90', '180', '270']),
  className: PropTypes.string,
  dataTestId: PropTypes.string,
};

BrandingIconBuilder.defaultProps = {
  kind: 'info',
  color: null,
  size: 'medium',
  rotate: null,
  className: null,
  dataTestId: null,
};

export default BrandingIconBuilder;