import React from 'react';
import { A } from '../../A';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { PropTypes, shape } from 'prop-types';
import { ColumnType } from 'ui-common/src/lib/commonPropTypes';
import { injectIntl } from 'react-intl';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';

const UniqueFieldFilter = ({
  column,
  showColumnFilter,
  setShowColumnFilter,
  sortedColumn,
  setSortedColumn,
  distinctColumnValues,
  checkedFilters,
  setCheckedFilters,
  appliedFilters,
  setAppliedFilters,
  intl,
  setAutoComplete,
}) => {
  const { key } = column;
  const filterOptions = createFilterOptions({
    limit: 100,
  });
  return (
    <div className={key === showColumnFilter ? 'd-show col-filter-box' : 'd-none col-filter-box'}>
      <div className="sorting-btn">
        <button
          type="submit"
          className={
            sortedColumn.columnName === key && sortedColumn.order === 'asc'
              ? 'btn btn-outline-primary active'
              : 'btn btn-outline-primary'
          }
          onClick={() =>
            setSortedColumn({
              columnName: key,
              order: 'asc',
            })
          }
        >
          {intl.formatMessage({ id: 'DateFilter.A-Z' })}
        </button>
        <button
          type="submit"
          className={
            sortedColumn.columnName === key && sortedColumn.order === 'desc'
              ? 'btn btn-outline-primary active'
              : 'btn btn-outline-primary'
          }
          onClick={() =>
            setSortedColumn({
              columnName: column.key,
              order: 'desc',
            })
          }
        >
          {intl.formatMessage({ id: 'DateFilter.Z-A' })}
        </button>
      </div>
      <form action="">
        <Autocomplete
          filterOptions={filterOptions}
          onClose={() => setAutoComplete(false)}
          onOpen={() => setAutoComplete(true)}
          noOptionsText={intl.formatMessage({ id: 'ColumnFilter.NoOptionsText' })}
          multiple
          id={column.viewKey}
          options={
            distinctColumnValues[key]
              ? Object.keys(distinctColumnValues[key])
                .map(field => ({
                  id: field,
                  title: field,
                }))
                .filter(x => (checkedFilters[key] ? !checkedFilters[key].includes(x.id) : true))
              : []
          }
          value={
            checkedFilters[key]
              ? checkedFilters[key].map(val => ({
                id: val,
                title: val,
              }))
              : []
          }
          onChange={(e, selections) => {
            setCheckedFilters({
              ...checkedFilters,
              [key]: selections.map(sel => sel.title),
            });
          }}
          getOptionLabel={option => option.title}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              placeholder={`Select ${intl.formatMessage({ id: column.label })}'s`}
              fullWidth
            />
          )}
        />
        <div className="action">
          <A
            href="#"
            className="btn-reset"
            onClick={e => {
              e.preventDefault();
              setAppliedFilters({ ...checkedFilters, [key]: [] });
              setCheckedFilters({ ...checkedFilters, [key]: [] });
              if (column.key === sortedColumn.columnName) {
                setSortedColumn({ order: 'asc' });
              }
            }}
          >
            {intl.formatMessage({ id: 'DateFilter.Reset' })}
          </A>
          <button
            type="button"
            className={
              checkedFilters[key] && checkedFilters[key].length > 0
                ? 'btn btn-outline-primary btn-apply'
                : 'btn btn-outline-primary btn-disabled'
            }
            onClick={e => {
              e.preventDefault();
              setAppliedFilters({
                ...appliedFilters,
                [column.key]: checkedFilters[column.key],
              });
              // setCheckedFilters({
              //   ...checkedFilters,
              //   [key]: []
              // });
              setShowColumnFilter('none');
            }}
          >
            {intl.formatMessage({ id: 'DateFilter.Apply' })}
          </button>
        </div>
      </form>
    </div>
  );
};

UniqueFieldFilter.propTypes = {
  column: ColumnType.isRequired,
  showColumnFilter: PropTypes.func.isRequired,
  setShowColumnFilter: PropTypes.func.isRequired,
  sortedColumn: PropTypes.shape({
    columnName: PropTypes.string,
    order: PropTypes.string,
  }).isRequired,
  setSortedColumn: PropTypes.func.isRequired,
  distinctColumnValues: PropTypes.shape({
    key: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  checkedFilters: PropTypes.shape({
    key: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  setCheckedFilters: PropTypes.func.isRequired,
  appliedFilters: PropTypes.shape({
    key: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  setAppliedFilters: PropTypes.func.isRequired,
  setAutoComplete: PropTypes.func.isRequired,
};
UniqueFieldFilter.defaultProps = {
  // column: DefaultColumnType,
};

const UniqueFieldFilterIntl = injectIntl(UniqueFieldFilter);

export { UniqueFieldFilterIntl as UniqueFieldFilter };

export default null;
