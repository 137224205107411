import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/extensions
import { getHourMinutesSeconds } from 'ui-common/src/lib/utils';
import { A } from '../A';
import './MessageModal.css';

const MessageModal = ({ onDismiss, mesgToDisplay, encrypted, MesgType, intl, data }) => (
  <div className="status-msg">
    {MesgType !== 'success' ? (
      <div className="error-msg">
        {encrypted
          ? intl.formatMessage({ id: mesgToDisplay }) +
            (mesgToDisplay === 'SESSION.REQUEST.MAXREQUESTED.TIMEEXCEEDED' && data.length
              ? ` ${JSON.stringify(
                  data.length > 0 ? getHourMinutesSeconds(parseInt(data[0])) : ''
                )} hrs`
              : mesgToDisplay === 'ENDDATE.STARTDATE.DIFFERENCE.IS.GREATER.THAN.MAXTIMEFRAME' &&
                data.length
              ? ` ${data[0]} hours`
              : '')
          : mesgToDisplay}
        <A className="link float-right" href="" onClick={onDismiss}>
          {intl.formatMessage({ id: 'ErrorMessage.Dismiss' })}
        </A>{' '}
      </div>
    ) : (
      <div className="success-msg">
        {encrypted ? intl.formatMessage({ id: mesgToDisplay }) : mesgToDisplay}
        <A className="link float-right" href="" onClick={onDismiss}>
          {intl.formatMessage({ id: 'ErrorMessage.Dismiss' })}
        </A>{' '}
      </div>
    )}
  </div>
);

MessageModal.propTypes = {
  mesgToDisplay: PropTypes.string.isRequired,
  MesgType: PropTypes.string.isRequired,
  encrypted: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
  intl: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string.isRequired,
      formatMessage: PropTypes.func.isRequired,
    })
  ).isRequired,
};

export default injectIntl(MessageModal);
