import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'isomorphic-fetch';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './ui-home/src/App';
import * as serviceWorker from './serviceWorker';
import AppHeader from './ui-home/src/AppHeader';
import './ECMv6/assets/css/global.css';

if (process.env.REACT_APP_MODE !== 'ECM') {
  // document.addEventListener('DOMContentLoaded', function(event) {
  // console.log = console.warn = console.error = () => {}; // to absorb the ignorable warnings and errors
  ReactDOM.render(<App />, document.getElementById('root'));
  // });
} else {
  ReactDOM.render(<AppHeader />, document.getElementById('header'));
}

serviceWorker.unregister();
