/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-curly-spacing */
import React, { useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  getCurrentDate,
  isObjectEmpty,
  removeClassesForHidingElements,
  useDetectScroll,
  useLocalize,
} from '@saviynt/common';
import {
  AccordionStepper,
  AlertBanner,
  Box,
  Button,
  FooterActionBar,
  Icon,
  InlineMessage,
  Link,
  Typography,
} from '@saviynt/design-system';
import PropTypes from 'prop-types';

import MpaAccountSelection from '../../../components/ModalPageForms/AccordionContentPanels/MpaAccountSelection/MpaAccountSelection';
import MpaJustificationDetails from '../../../components/ModalPageForms/AccordionContentPanels/MpaJustificationDetails/MpaJustificationDetails';
import MpaTcodeSelection from '../../../components/ModalPageForms/AccordionContentPanels/MpaTcodeSelection/MpaTcodeSelection';
import MpaTimeAccessDuration from '../../../components/ModalPageForms/AccordionContentPanels/MpaTimeAccessDuration/MpaTimeAccessDuration';
import {
  ACCOUNT_JIT_SELECTION,
  ASSET_TYPES,
  initialCriticalState,
  MAX_RADIO_OPTIONS_TO_SHOW,
  MODAL_PAGE_KINDS,
  PLATFORM_TYPES,
} from '../../../components/ModalPageForms/constants';
import AppLauncher from '../../../components/ModalPageForms/CTSAccordionContentPanels/AppLauncher/AppLauncher';
import ModalFormHeader from '../../../components/ModalPageForms/ModalFormHeader';
import AccountListModalIsland from '../../../components/ModalPageForms/ModalIslands/AccountListModalIsland/AccountListModalIsland';
import DatabaseJitModalIsland from '../../../components/ModalPageForms/ModalIslands/DatabaseJitModalIsland/DatabaseJitModalIsland';
import RemoteAppsListModalIsland from '../../../components/ModalPageForms/ModalIslands/RemoteAppsListModalIsland/RemoteAppsListModalIsland';
import ViewScheduleLink from '../../../components/ModalPageForms/ModalIslands/ViewScheduleModalIsland/ViewScheduleLink/ViewScheduleLink';
import ViewScheduleModalIsland from '../../../components/ModalPageForms/ModalIslands/ViewScheduleModalIsland/ViewScheduleModalIsland';
import { REQUEST_BASE_URL } from '../../../misc/constants';
import ModalPageAccountService from '../../../services/ModalPages/ModalPageAccountService';
import ModalPageDurationService from '../../../services/ModalPages/ModalPageDurationService';
import ModalPageFormSubmissionService from '../../../services/ModalPages/ModalPageFormSubmissionService';
import {
  handleClearCredentialForm,
  handleCredentialFormDatabasePrivleges,
  handleCredentialFormRemoteAppDetails,
  handleCredentialFormRequestor,
  handleCredentialFormTCodeDetails,
  handleIsCredentialsRequest,
} from '../../../store/actions';
import hideHostElementsWrapper from '../../../utilities/hideHostElements';

import { renderModalPageFooterActionBar } from './helpers';
import useModalPageStrings from './use-modalpagestrings';

import '../ModalPages.css';

function ModalPageContent({ modalPageKind }) {
  const isCOCModal = modalPageKind === MODAL_PAGE_KINDS.COC;
  const isCTSModal = modalPageKind === MODAL_PAGE_KINDS.CTS;
  const isCORModal = modalPageKind === MODAL_PAGE_KINDS.COR;

  // Accordion expanded booleans
  const [isExpandedAccountSelection, setIsExpandedAccountSelection] =
    useState(false);
  const [isExpandedAppLauncher, setIsExpandedAppLauncher] = useState(false);
  const [isExpandedDuration, setIsExpandedDuration] = useState(false);
  const [isExpandedTCode, setIsExpandedTCode] = useState(false);
  const [isExpandedJustificationDetails, setIsExpandedJustificationDetails] =
    useState(false);
  // AccountSelection
  const [accountListArray, setAccountListArray] = useState(null);
  const [accountListFilteredArray, setAccountListFilteredArray] =
    useState(null); // separate state for modal scrolling/searching.
  const [accountSelection, setAccountSelection] = useState(null);
  const [isAccountListModal, setIsAccountListModal] = useState(false);
  const [isCTSAccountExclusive, setIsCTSAccountExclusive] = useState(false);
  const [accountModalSearchTerm, setAccountModalSearchTerm] = useState('');
  // App Launcher
  const [remoteAppListArray, setRemoteAppListArray] = useState(null);
  const [appLauncherSelection, setAppLauncherSelection] = useState(null);
  const [isRemoteAppListModal, setIsRemoteAppListModal] = useState(false);
  // TCodes
  const [tCodesSelection, setTCodesSelection] = useState([]);
  // TimeAccessDuration
  const [isScheduleViewOpen, setIsScheduleViewOpen] = useState(false);
  const [toggleChoice, setToggleChoice] = useState(1);
  const [isDurationCustom, setIsDurationCustom] = useState(false);
  const [shouldDurationContinue, setShouldDurationContinue] = useState(false);
  const [nowStartDateOfNextCredential, setNowStartDateOfNextCredential] =
    useState(null);
  const [futureStartDateOfNextCredential, setFutureStartDateOfNextCredential] =
    useState(null);
  const [nowTabStartDate, setNowTabStartDate] = useState(getCurrentDate());
  const [nowTabEndDate, setNowTabEndDate] = useState(null);
  const [futureTabStartDate, setFutureTabStartDate] = useState(null);
  const [futureTabEndDate, setFutureTabEndDate] = useState(null);
  const [durationAccordionValue, setDurationAccordionValue] = useState(null);
  const [isTimeBlocked, setIsTimeBlocked] = useState(false);
  // Justification Details
  const [hasDurationBeenFilled, setHasDurationBeenFilled] = useState(false);
  const [justificationInputValue, setJustificationInputValue] = useState('');
  const [isJustificationInputCritical, setIsJustificationInputCritical] =
    useState(false);
  const [ticketNumber, setTicketNumber] = useState('');
  // Upload
  const [uploadedDetailFiles, setUploadedDetailFiles] = useState(null);
  const [hasErrorInDetailFiles, setHasErrorInDetailFiles] = useState(null);
  // Critical State
  const [isFormCritical, setIsFormCritical] = useState(initialCriticalState);
  const [isFormFailureAlertVisible, setIsFormFailureAlertVisible] =
    useState(false);
  const [isDurationEditingAlertVisible, setIsDurationEditingAlertVisible] =
    useState(false);

  const [isDatabaseJitCreateAccount, setIsDatabaseJitCreateAccount] =
    useState(false);
  const [databaseJitAccountInfo, setDatabaseJitAccountInfo] = useState({
    entitlements: [],
  });
  const [isRequestLoading, setIsRequestLoading] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const localize = useLocalize();
  const accordionContentRef = useRef(null);
  const accountListModalContentRef = useRef(null);
  const remoteAppListModalContentRef = useRef(null);
  // const tCodeModalContentRef = useRef(null);
  const viewScheduleModalContentRef = useRef(null);
  const databaseJitModalContentRef = useRef(null);

  const [cookies] = useCookies(['user_name']);
  const userName = cookies.user_name;

  const isCOCorExclusive = isCOCModal || isCTSAccountExclusive;
  const isJITSelection =
    accountSelection?.value === ACCOUNT_JIT_SELECTION.value;
  const isNowTab = toggleChoice === 1;

  // Redux Selectors
  const endpointDetails = useSelector(
    (state) => state.privilegedAccess.SelectedListDetails
  );
  const remoteAppMetadata = useSelector(
    (state) => state.privilegedAccess.SelectedListAccountRemoteAppMetadata
  );
  const isExclusiveAccess = useSelector(
    (state) => state.privilegedAccess.SelectedListIsExclusiveAccess
  );
  const bootstrapConfigMaxTime = useSelector(
    (state) => state.privilegedAccess.SelectedListMaxCredlessSessionRequestTime
  );
  const isDurationBeingEdited = useSelector(
    (state) => state.credentialRequestForm.isDurationBeingEdited
  );

  // Redirect on refresh due to lost SelectedListDetails store data
  useEffect(() => {
    if (isObjectEmpty(endpointDetails)) {
      // Can't use goBack in react.strict
      history.push(REQUEST_BASE_URL);
    }

    // Clear COC form on history.push() back
    dispatch(handleClearCredentialForm('Clear COC Request Form success'));

    // hide host toast messages, etc when flag is set
    hideHostElementsWrapper();

    // Update CTS page Exclusive Access
    setIsCTSAccountExclusive(isExclusiveAccess);

    // Set form pamtype to 'credential' on COC pages
    if (isCOCModal)
      dispatch(handleIsCredentialsRequest('Updated COC form pamtype'));

    return () => {
      removeClassesForHidingElements();
    };
  }, []);

  // Bools for flow conditions
  const isRemoteAppEnabled = remoteAppMetadata?.length > 0;
  const isSAP = endpointDetails?.platformType?.toLowerCase() === 'sap';
  const isAssetTypeDatabase =
    endpointDetails.assetType === ASSET_TYPES.DATABASE;
  const isAssetTypeServer = endpointDetails.assetType === ASSET_TYPES.SERVER;
  const isPlatformAD = endpointDetails.platformType === PLATFORM_TYPES.AD;
  // Critical check
  const isDurationCritical = Boolean(
    !isDurationBeingEdited &&
      (isFormCritical.startdate || isFormCritical.enddate)
  );

  // Localization
  const {
    MODAL_HEADER_TEXT,
    SUBMIT_BTN_TEXT,
    SUBMIT_TITLE_TEXT,
    ACCOUNT_HEADER_TEXT,
    ACCOUNT_SUBHEADER_TEXT,
    APP_LAUNCHER_HEADER_TEXT,
    APP_LAUNCHER_SUBHEADER_TEXT,
    DURATION_HEADER_TEXT,
    DURATION_SUBHEADER_TEXT,
    JUST_DETAILS_HEADER_TEXT,
    JUST_DETAILS_SUBHEADER_TEXT,
    CREDENTIAL_REQUEST_COMMENT_TEXT,
    EDITING_DURATION_CRITICAL_BANNER_TEXT,
    ALERT_JUMP_TO_LINK_TEXT,
    TCODE_HEADER_TEXT,
    TCODE_SUBHEADER_TEXT,
  } = useModalPageStrings(modalPageKind);

  useEffect(() => {
    // Update the redux requestor on mount
    if (userName)
      dispatch(handleCredentialFormRequestor(userName, 'Requestor success'));
  }, [userName]);

  const {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    debouncedSearch,
    getAccountsTotalCount,
  } = ModalPageAccountService(
    modalPageKind,
    accountSelection,
    accountListArray,
    endpointDetails?.endpointName,
    endpointDetails?.endpointKey,
    setAccountListArray,
    setIsExpandedAccountSelection,
    accountModalSearchTerm,
    setAccountModalSearchTerm,
    dispatch,
    setAccountListFilteredArray,
    isAccountListModal
  );

  // Duration Service
  const { sessionsToBeIterated } = ModalPageDurationService(
    modalPageKind,
    isNowTab,
    isRemoteAppEnabled,
    accountSelection,
    isSAP,
    appLauncherSelection,
    isDurationCustom,
    shouldDurationContinue,
    nowTabStartDate,
    nowTabEndDate,
    setNowTabStartDate,
    setNowTabEndDate,
    futureTabStartDate,
    futureTabEndDate,
    setDurationAccordionValue,
    setNowStartDateOfNextCredential,
    setFutureStartDateOfNextCredential,
    userName,
    endpointDetails?.endpointKey,
    isCOCorExclusive,
    dispatch
  );

  // Form Submit Service
  const {
    isCredentialRequestFormFilled,
    submitResponseErrorMessage,
    handleCredentialFormRequest,
  } = ModalPageFormSubmissionService(
    isCOCModal,
    isCORModal,
    isRemoteAppEnabled,
    isSAP,
    uploadedDetailFiles,
    hasErrorInDetailFiles,
    justificationInputValue,
    isJustificationInputCritical,
    isNowTab,
    isTimeBlocked,
    hasDurationBeenFilled,
    ticketNumber,
    userName,
    setIsFormCritical,
    setNowTabStartDate,
    setIsFormFailureAlertVisible,
    setIsDurationEditingAlertVisible,
    setIsJustificationInputCritical,
    setIsRequestLoading,
    dispatch,
    CREDENTIAL_REQUEST_COMMENT_TEXT,
    endpointDetails // TODO: (ROLES) check if needed after refactor/redux
  );

  // Page scroll references
  const { isScrolled: accordionContentRefIsScrolled } = useDetectScroll(
    null,
    accordionContentRef
  );
  const { isScrolled: accountModalSectionRefIsScrolled } = useDetectScroll(
    null,
    accountListModalContentRef
  );
  const { isScrolled: remoteAppModalSectionRefIsScrolled } = useDetectScroll(
    null,
    remoteAppListModalContentRef
  );
  // const { isScrolled: tCodeModalContentRefIsScrolled } = useDetectScroll(
  //   null,
  //   tCodeModalContentRef
  // );
  const { isScrolled: viewScheduleModalSectionRefIsScrolled } = useDetectScroll(
    null,
    viewScheduleModalContentRef
  );
  const { isScrolled: databaseJitModalSectionRefIsScrolled } = useDetectScroll(
    null,
    databaseJitModalContentRef
  );

  const buildAccordionValue = (string) => {
    if (!string) return null;

    return (
      <Typography
        kind='body1-bold'
        className='AccordionStepper-supportingContent-accordionValue'>
        {string}
      </Typography>
    );
  };

  const accordionCriticalHelperText = (
    <InlineMessage kind='missingRequiredField' size='small' />
  );

  // Close Account Accordion on selection
  useEffect(() => {
    const isNotRemoteAppEnabledOrIsCOC = isCOCModal || !isRemoteAppEnabled;
    const isRemoteAppEnabledAndIsCTS = isCTSModal && isRemoteAppEnabled;

    // Reset app launcher on app switch
    if (appLauncherSelection && isJITSelection) setAppLauncherSelection(null);

    if (accountSelection && isNotRemoteAppEnabledOrIsCOC) {
      setIsExpandedAccountSelection(false);
      setIsExpandedDuration(true);
      setIsExpandedTCode(false);
    } else if (
      isRemoteAppEnabledAndIsCTS &&
      (isJITSelection || accountSelection)
    ) {
      // Open AppLauncher in JIT and other flows
      setIsExpandedAccountSelection(false);
      setIsExpandedAppLauncher(true);
      setIsExpandedTCode(false);
    }
  }, [accountSelection]);

  // DB JIT
  useEffect(() => {
    dispatch(
      handleCredentialFormDatabasePrivleges(
        databaseJitAccountInfo.entitlements,
        'Form Database Privileges updated successfully'
      )
    );
  }, [databaseJitAccountInfo]);

  // Close AppLauncher Accordion on selection
  useEffect(() => {
    if (appLauncherSelection) {
      setIsExpandedAppLauncher(false);
      setIsExpandedDuration(true);
      setIsExpandedTCode(false);
      dispatch(
        handleCredentialFormRemoteAppDetails(
          appLauncherSelection,
          'Remote App Details update, success'
        )
      );
    }
  }, [appLauncherSelection]);

  // Close Duration Accordion on selection
  useEffect(() => {
    if (isNowTab && durationAccordionValue && shouldDurationContinue) {
      setIsExpandedDuration(false);
      setIsExpandedJustificationDetails(true);
      setShouldDurationContinue(false);
      setIsExpandedTCode(true);
    }

    if (!isNowTab && durationAccordionValue && shouldDurationContinue) {
      setIsExpandedDuration(false);
      setIsExpandedJustificationDetails(true);
      setShouldDurationContinue(false);
      setIsExpandedTCode(true);
    }
  }, [durationAccordionValue, shouldDurationContinue]);

  // Close TCode Accordion on selection
  // TODO: should this accordion ever auto close?
  useEffect(() => {
    if (tCodesSelection.length) {
      setIsExpandedAppLauncher(false);
      setIsExpandedDuration(false);
      setIsExpandedTCode(true);
      dispatch(
        handleCredentialFormTCodeDetails(
          tCodesSelection,
          'TCode Details update, success'
        )
      );
    }
  }, [tCodesSelection]);
  useEffect(() => {
    // On critical state open Missing value accordions
    if (isFormCritical.accountId) setIsExpandedAccountSelection(true);
    if (isFormCritical.appSelection) setIsExpandedAppLauncher(true);
    if (isFormCritical.startdate || isFormCritical.enddate)
      setIsExpandedDuration(true);
    if (isFormCritical?.tCodeSelection?.length > 0) setIsExpandedTCode(true);
    if (isJustificationInputCritical) setIsExpandedJustificationDetails(true);
  }, [isFormCritical, isJustificationInputCritical]);

  // Render conditions
  // Curently account is in every flow except checkout role.
  // roles is now handled by ModalgePageContentRole.js
  const renderAccountAccordion = true;

  const renderAppLauncherAccordion = () => {
    // Never show App Launcher in COC pages
    if (isCOCModal) return false;

    const hasRemoteOptionsAndAnyAccountSelection =
      (isRemoteAppEnabled || isPlatformAD) &&
      (isJITSelection || accountSelection);

    if (hasRemoteOptionsAndAnyAccountSelection) return true;

    return false;
  };

  const renderDurationAccordion = () => {
    const isCOCWithAccount = isCOCModal && accountSelection;
    const isCTSWithAccount = isCTSModal && accountSelection;

    // ---COC---
    // Has Account selection
    if (isCOCWithAccount) return true;

    // ---CTS---
    // Has Account selection when Remote App is not Enabled
    if (isCTSWithAccount && !isRemoteAppEnabled) return true;
    // Has App Launcher selection with Remote App Enabled
    if (isCTSWithAccount && appLauncherSelection) return true;

    return false;
  };

  const shouldSubmitBeFilled = Boolean(
    isCredentialRequestFormFilled && !hasErrorInDetailFiles
  );

  const shouldRenderTCodeAccordion = () => {
    // Show only for SAP
    if (!isSAP) return false;

    if (isCTSModal && isRemoteAppEnabled && !appLauncherSelection) return false;

    const hasTCodeSelection =
      isSAP && (isJITSelection || accountSelection) && hasDurationBeenFilled;

    if (hasTCodeSelection) return true;

    return false;
  };

  const shouldRenderJustificationAccordion = () => {
    if (isSAP && tCodesSelection.length > 0 && hasDurationBeenFilled)
      return true;

    if (!isSAP && hasDurationBeenFilled) return true;

    return false;
  };

  return (
    <Box className='ModalPage-root'>
      <ModalFormHeader
        title={MODAL_HEADER_TEXT}
        endpointDetails={endpointDetails}
      />
      <Box className='ModalPage-alertBanner'>
        <AlertBanner
          colorTheme='Critical'
          title={SUBMIT_TITLE_TEXT}
          description={localize(submitResponseErrorMessage)}
          isVisible={isFormFailureAlertVisible}
          onCancel={() => setIsFormFailureAlertVisible(false)}
        />
        <AlertBanner
          colorTheme='Critical'
          isColonVisible={false}
          description={EDITING_DURATION_CRITICAL_BANNER_TEXT}
          isVisible={isDurationEditingAlertVisible}
          onCancel={() => setIsDurationEditingAlertVisible(false)}
          LinkComponent={
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <Link
              url='#Section_further_down'
              text={ALERT_JUMP_TO_LINK_TEXT}
              suffixIconKind='ArrowDown'
              color='neutralInverse'
              target='_self'
            />
          }
        />
      </Box>
      <section className='ModalPage' ref={accordionContentRef}>
        <Box className='ModalPage-scrollingArea'>
          {/* Modals */}
          {isDatabaseJitCreateAccount && (
            <DatabaseJitModalIsland
              isOpen={isDatabaseJitCreateAccount}
              setIsDatabaseJitCreateAccount={setIsDatabaseJitCreateAccount}
              setDatabaseJitAccountInfo={setDatabaseJitAccountInfo}
              databaseJitAccountInfo={databaseJitAccountInfo}
              setAccountSelection={setAccountSelection}
              sectionRefIsScrolled={databaseJitModalSectionRefIsScrolled}
              databaseJitModalContentRef={databaseJitModalContentRef}
            />
          )}
          {getAccountsTotalCount() > MAX_RADIO_OPTIONS_TO_SHOW ? (
            <AccountListModalIsland
              accountListArray={accountListArray}
              accountListFilteredArray={accountListFilteredArray}
              accountListModalContentRef={accountListModalContentRef}
              sectionRefIsScrolled={accountModalSectionRefIsScrolled}
              accountSelection={accountSelection}
              setAccountSelection={setAccountSelection}
              isAccountListModal={isAccountListModal}
              setIsAccountListModal={setIsAccountListModal}
              fetchNextPage={fetchNextPage}
              hasNextPage={hasNextPage}
              isFetchingNextPage={isFetchingNextPage}
              isFetching={isFetching}
              setAccountListArray={setAccountListArray}
              debouncedSearch={debouncedSearch}
            />
          ) : null}
          {remoteAppListArray?.length > MAX_RADIO_OPTIONS_TO_SHOW ? (
            <RemoteAppsListModalIsland
              remoteAppListArray={remoteAppListArray}
              remoteAppListModalContentRef={remoteAppListModalContentRef}
              sectionRefIsScrolled={remoteAppModalSectionRefIsScrolled}
              appLauncherSelection={appLauncherSelection}
              setAppLauncherSelection={setAppLauncherSelection}
              isRemoteAppListModal={isRemoteAppListModal}
              setIsRemoteAppListModal={setIsRemoteAppListModal}
            />
          ) : null}
          <ViewScheduleModalIsland
            viewScheduleModalContentRef={viewScheduleModalContentRef}
            sectionRefIsScrolled={viewScheduleModalSectionRefIsScrolled}
            isScheduleViewOpen={isScheduleViewOpen}
            setIsScheduleViewOpen={setIsScheduleViewOpen}
            selectedAccountName={accountSelection?.title}
            selectedAccountSessions={sessionsToBeIterated}
          />
          {/* Account Accordion */}
          {renderAccountAccordion ? (
            <AccordionStepper
              isExpanded={isExpandedAccountSelection}
              setIsExpanded={setIsExpandedAccountSelection}
              prefixIconKind='account'
              headerText={ACCOUNT_HEADER_TEXT}
              primarySupportingText={ACCOUNT_SUBHEADER_TEXT}
              accordionValue={buildAccordionValue(accountSelection?.title)}
              isCritical={isFormCritical.accountId}
              contentPanelClassName='ModalPage-ctsAccountSelectionPanel'
              CriticalMessage={
                isFormCritical.accountId && accordionCriticalHelperText
              }>
              <MpaAccountSelection
                modalPageKind={modalPageKind}
                isAssetTypeDatabase={isAssetTypeDatabase}
                isAssetTypeServer={isAssetTypeServer}
                accountListArray={accountListArray}
                accountSelection={accountSelection}
                setAccountSelection={setAccountSelection}
                setIsAccountListModal={setIsAccountListModal}
                databaseJitAccountInfo={databaseJitAccountInfo}
                setDatabaseJitAccountInfo={setDatabaseJitAccountInfo}
                setIsDatabaseJitCreateAccount={setIsDatabaseJitCreateAccount}
                accountsTotalCount={getAccountsTotalCount()}
              />
            </AccordionStepper>
          ) : null}
          {/* App Launcher Accordion */}
          {renderAppLauncherAccordion() ? (
            <AccordionStepper
              isExpanded={isExpandedAppLauncher}
              setIsExpanded={setIsExpandedAppLauncher}
              prefixIconKind='privilegedSession'
              headerText={APP_LAUNCHER_HEADER_TEXT}
              primarySupportingText={APP_LAUNCHER_SUBHEADER_TEXT}
              accordionValue={buildAccordionValue(
                appLauncherSelection?.displayName
              )}
              isCritical={isFormCritical.appSelection}
              CriticalMessage={
                isFormCritical.appSelection && accordionCriticalHelperText
              }>
              <AppLauncher
                appLauncherSelection={appLauncherSelection}
                remoteAppMetadata={remoteAppMetadata}
                remoteAppListArray={remoteAppListArray}
                setRemoteAppListArray={setRemoteAppListArray}
                setAppLauncherSelection={setAppLauncherSelection}
                setIsRemoteAppListModal={setIsRemoteAppListModal}
                setIsExpandedAppLauncher={setIsExpandedAppLauncher}
              />
            </AccordionStepper>
          ) : null}
          {/* Duration Accordion */}
          {renderDurationAccordion() && (
            <Box id='Section_further_down'>
              <AccordionStepper
                isExpanded={isExpandedDuration}
                setIsExpanded={setIsExpandedDuration}
                prefixIconKind='time'
                headerText={DURATION_HEADER_TEXT}
                primarySupportingText={DURATION_SUBHEADER_TEXT}
                accordionValue={buildAccordionValue(durationAccordionValue)}
                suffixLink={
                  <ViewScheduleLink
                    setIsScheduleViewOpen={setIsScheduleViewOpen}
                  />
                }
                isCritical={isDurationCritical}
                CriticalMessage={
                  isDurationCritical && accordionCriticalHelperText
                }>
                <MpaTimeAccessDuration
                  modalPageKind={modalPageKind}
                  userName={userName}
                  toggleChoice={toggleChoice}
                  setToggleChoice={setToggleChoice}
                  setShouldDurationContinue={setShouldDurationContinue}
                  bootstrapConfigMaxTime={bootstrapConfigMaxTime}
                  nowStartDateOfNextCredential={nowStartDateOfNextCredential}
                  futureStartDateOfNextCredential={
                    futureStartDateOfNextCredential
                  }
                  nowTabStartDate={nowTabStartDate}
                  setNowTabStartDate={setNowTabStartDate}
                  nowTabEndDate={nowTabEndDate}
                  setNowTabEndDate={setNowTabEndDate}
                  futureTabStartDate={futureTabStartDate}
                  setFutureTabStartDate={setFutureTabStartDate}
                  futureTabEndDate={futureTabEndDate}
                  setFutureTabEndDate={setFutureTabEndDate}
                  isTimeBlocked={isTimeBlocked}
                  setIsTimeBlocked={setIsTimeBlocked}
                  setIsScheduleViewOpen={setIsScheduleViewOpen}
                  accountSelection={accountSelection}
                  setDurationAccordionValue={setDurationAccordionValue}
                  isDurationCustom={isDurationCustom}
                  setIsDurationCustom={setIsDurationCustom}
                  hasDurationBeenFilled={hasDurationBeenFilled}
                  setHasDurationBeenFilled={setHasDurationBeenFilled}
                  selectedAccountSessions={sessionsToBeIterated}
                  isCOCorExclusive={isCOCorExclusive}
                  isCTSAccountExclusive={isCTSAccountExclusive}
                  isExpandedDuration={isExpandedDuration}
                  setIsExpandedDuration={setIsExpandedDuration}
                  isFormCritical={isFormCritical}
                />
              </AccordionStepper>
            </Box>
          )}

          {/* TCode Accordion */}
          {/* todo: Handle resizing of accordion when dropdown comes up  */}
          {shouldRenderTCodeAccordion() ? (
            <AccordionStepper
              isExpanded={isExpandedTCode}
              setIsExpanded={setIsExpandedTCode}
              prefixIconKind='Workflow'
              headerText={TCODE_HEADER_TEXT}
              primarySupportingText={TCODE_SUBHEADER_TEXT}
              accordionValue={buildAccordionValue(
                tCodesSelection?.map((tcode) => tcode.label).join(', ')
              )}
              isCritical={isFormCritical.tCodeSelection}
              CriticalMessage={
                isFormCritical.tCodeSelection && accordionCriticalHelperText
              }>
              <MpaTcodeSelection
                tCodesSelection={tCodesSelection}
                setTCodesSelection={setTCodesSelection}
                endpointDetails={endpointDetails}
              />
            </AccordionStepper>
          ) : null}
          {/* Details Accordion */}
          {shouldRenderJustificationAccordion() && (
            <AccordionStepper
              isExpanded={isExpandedJustificationDetails}
              setIsExpanded={setIsExpandedJustificationDetails}
              prefixIconKind='document'
              headerText={JUST_DETAILS_HEADER_TEXT}
              primarySupportingText={JUST_DETAILS_SUBHEADER_TEXT}
              accordionValue={buildAccordionValue(justificationInputValue)}>
              <MpaJustificationDetails
                justificationInputValue={justificationInputValue}
                setJustificationInputValue={setJustificationInputValue}
                isJustificationInputCritical={isJustificationInputCritical}
                setIsJustificationInputCritical={
                  setIsJustificationInputCritical
                }
                ticketNumber={ticketNumber}
                setTicketNumber={setTicketNumber}
                setUploadedDetailFiles={setUploadedDetailFiles}
                setHasErrorInDetailFiles={setHasErrorInDetailFiles}
                isPlatformAD={isPlatformAD}
              />
            </AccordionStepper>
          )}
        </Box>
      </section>
      {renderModalPageFooterActionBar(
        FooterActionBar,
        accordionContentRefIsScrolled,
        Button,
        Icon,
        handleCredentialFormRequest,
        shouldSubmitBeFilled,
        isRequestLoading,
        SUBMIT_BTN_TEXT
      )}
    </Box>
  );
}

ModalPageContent.propTypes = { modalPageKind: PropTypes.string.isRequired };

ModalPageContent.defaultProps = {};

export default ModalPageContent;
