import React, { type JSX, type KeyboardEvent, type MouseEvent } from 'react';
import { Typography } from '@saviynt/design-system';
import classNames from 'classnames';

import { Link, useLocalize } from '../../../utilities';

import illustrations from './illustrations';

import './ShortcutTile.css';

interface ShortcutTileProps {
  shortcut: {
    id: string;
    link?: string;
  };
  className?: string;
  onCustomClick: (id: string) => void;
  isMobileFormFactor?: boolean;
}

ShortcutTile.defaultProps = {
  className: null,
  isMobileFormFactor: false,
};

export default function ShortcutTile({
  shortcut: { id, link },
  onCustomClick,
  isMobileFormFactor,
  ...props
}: ShortcutTileProps): JSX.Element {
  const localize = useLocalize();
  const isCustomLink = id.startsWith('custom:');
  const className = classNames(
    'Nav_ShortcutTile',
    props.className,
    isMobileFormFactor && 'Nav_ShortcutTile--mobile'
  );
  const messageKey =
    id === 'custom:start-access-request'
      ? 'NewRequestHome.QuickLink.AccessRequest.Label'
      : `gen2.leftnav.label.${id}`;
  const text = localize(messageKey);
  const Illustration = illustrations[id];

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    onCustomClick(getCustomId(id));
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onCustomClick(getCustomId(id));
    }
  };

  const labelKind = isMobileFormFactor ? 'h3' : 'body1';
  const content = (
    <div className='Nav_ShortcutTile-content'>
      <div className='Nav_ShortcutTile-content-icon'>
        <Illustration />
      </div>
      <div className='Nav_ShortcutTile-content-label'>
        <Typography
          className='Nav_ShortcutTile-content-label-text'
          kind={labelKind}>
          {text}
        </Typography>
      </div>
    </div>
  );

  if (isCustomLink) {
    return (
      <div
        className={className}
        onClick={handleClick}
        role='button'
        title={text}
        tabIndex={0}
        onKeyDown={handleKeyDown}>
        {content}
      </div>
    );
  }

  if (!link) {
    throw new Error(`Shortcut ${id} has no link`);
  }

  return (
    <Link className={className} href={link} title={text}>
      {content}
    </Link>
  );
}

function getCustomId(id: string): string {
  return id.replace('custom:', '');
}
