/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { formatLocalizedDateTime, useLocalize } from '@saviynt/common';
import {
  Box,
  Button,
  ButtonIcon,
  Icon,
  Menu,
  Typography,
} from '@saviynt/design-system';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { ModuleDetails } from '../../../../models/PamModels';
import CountdownTimer from '../../../CountdownTimer/CountdownTimer';
import { MODAL_PAGE_KINDS } from '../../../ModalPageForms/constants';
import { msgs } from '../../constants';

import './SpfSession.css';

function SpfSession({
  data,
  credentialRequestOnClick,
  dataTestId,
  setIsCheckInModalOpen,
  setIconClickedForRow,
  setCheckInModalKind,
  setSidePanelOnClose,
  setIsExtendModalOpen,
  setSessionToLaunch,
  setIsSessionTargetModalVisible,
  setSessionDetailsForAd,
  className,
}) {
  const classes = classnames('SpfSession', className);
  const isCredentialed = data.pamType?.toUpperCase() === 'CREDENTIAL';
  const END_TYPE_CHECKIN = 'checkIn';
  const END_TYPE_CANCEL_REQUEST = 'cancelRequest';
  const END_TYPE_CANCEL_SESSION = 'cancelSession';
  const EXTEND_SESSION = 'extend_session';
  const END_TYPE_END_SESSION = 'end';
  const AD_RDP = 'adrdp';
  const isPendingSession = !data.sessionStatus;
  const isFutureApprovedSession = data.sessionStatus === 1;
  const isActiveSession = data.sessionStatus === 2;
  const isRole = data.roleKey;

  const [isPastStartTime, setIsPastStartTime] = useState(false);
  const [isPastEndTime, setIsPastEndTime] = useState(false);

  const [{ userKey, user_name: userName }] = useCookies([
    'user_name',
    'userKey',
  ]);

  const localize = useLocalize();

  const SESSION_OPTIONS = [
    {
      label: localize(msgs.pam.sidePanel.extendSession),
      value: EXTEND_SESSION,
    },
    {
      label: localize(msgs.pam.sidePanel.endSession),
      value: END_TYPE_END_SESSION,
    },
  ];

  const isRdp = (remoteAppMetadata) =>
    remoteAppMetadata?.name?.toLowerCase() === AD_RDP;

  // Future Approved Session (Both Credentialed and Credentialless)
  const futureApprovedSessionFooter = () => (
    <footer className='SpfSession-footer'>
      <section className='SpfSession-countDownTimerContainer'>
        <Typography
          kind='body1'
          className='SpfSession-countDownTimer-startsInText'>
          Starts in
        </Typography>
        <Typography kind='body1-bold' className='SpfSession-countDownTimer'>
          {isPastStartTime ? (
            <span className='SpfSession-expiredTime'>0 s</span>
          ) : (
            <CountdownTimer
              targetDate={data?.sessionStartDate}
              leftText=''
              item={data}
              onTimerExpired={() => {
                setIsPastStartTime(true);
              }}
            />
          )}
        </Typography>
      </section>
      <section className='SpfSession-credentialedButtons'>
        <Button
          type='button'
          kind='outlined'
          onClick={() => {
            setIsCheckInModalOpen(true);
            setIconClickedForRow(data);
            setCheckInModalKind(END_TYPE_CANCEL_SESSION);
          }}>
          {localize(msgs.pam.sidePanel.cancelSession)}
        </Button>
      </section>
    </footer>
  );

  // Pending Session (Both Credentialed and Credentialless)
  const pendingSessionFooter = () => (
    <Button
      type='button'
      kind='outlined'
      onClick={() => {
        setIsCheckInModalOpen(true);
        setIconClickedForRow(data);
        setCheckInModalKind(END_TYPE_CANCEL_REQUEST);
      }}>
      {localize(msgs.pam.sidePanel.cancelRequest)}
    </Button>
  );

  // Active Session (Credentialed)
  const credentialedActiveSessionFooter = () => (
    <footer className='SpfSession-footer'>
      <section className='SpfSession-countDownTimerContainer'>
        <Typography kind='body1-bold' className='SpfSession-countDownTimer'>
          {isPastEndTime ? (
            <span className='SpfSession-expiredTime'>0 s</span>
          ) : (
            <CountdownTimer
              targetDate={data?.sessionEndDate}
              leftText=''
              item={data}
              onTimerExpired={() => {
                setIsPastEndTime(true);
                setSidePanelOnClose(true);
              }}
            />
          )}
        </Typography>
        <Typography kind='body1' className='SpfSession-countDownTimer-leftText'>
          left
        </Typography>
      </section>
      <section className='SpfSession-credentialedButtons'>
        <Button
          type='button'
          kind='outlined'
          className='SpfSession-twoButtons--buttonStyle'
          isEnabled
          onClick={() => {
            setIsCheckInModalOpen(true);
            setIconClickedForRow(data);
            setCheckInModalKind(END_TYPE_CHECKIN);
          }}>
          {localize(msgs.pam.sidePanel.checkIn)}
        </Button>
        <Button
          type='button'
          kind='outlined'
          isCritical
          className='SpfSession-twoButtons--buttonStyle'
          onClick={() => {
            setIsExtendModalOpen(true);
            setIconClickedForRow(data);
          }}>
          {localize(msgs.pam.sidePanel.extend)}
        </Button>
      </section>
    </footer>
  );

  // Active Session (Credentialless)

  function renderSessionMoreOptions() {
    const handleChange = (selectedOption) => {
      if (selectedOption.value === EXTEND_SESSION) {
        setIsExtendModalOpen(true);
        setIconClickedForRow(data);
      } else if (selectedOption.value === END_TYPE_END_SESSION) {
        setIsCheckInModalOpen(true);
        setIconClickedForRow(data);
        setCheckInModalKind(END_TYPE_END_SESSION);
      }
    };

    const trigger = (
      <ButtonIcon
        size='large'
        kind='ghost'
        icon={<Icon kind='KebabVertical' color='neutral-100' size='medium' />}
      />
    );

    return (
      <Menu
        options={SESSION_OPTIONS}
        onChange={handleChange}
        trigger={trigger}
        placement='top-start'
      />
    );
  }

  const credentiallessActiveSessionFooter = () => (
    <footer className='SpfSession-footer'>
      <section className='SpfSession-countDownTimerContainer'>
        <Typography kind='body1-bold' className='SpfSession-countDownTimer'>
          {isPastEndTime ? (
            <span className='SpfSession-expiredTime'>0 s</span>
          ) : (
            <CountdownTimer
              targetDate={data?.sessionEndDate}
              leftText=''
              item={data}
              onTimerExpired={() => {
                setIsPastEndTime(true);
                setSidePanelOnClose(true);
              }}
            />
          )}
        </Typography>
        <Typography kind='body1' className='SpfSession-countDownTimer-leftText'>
          left
        </Typography>
      </section>
      <section className='SpfSession-credentiallessButtons'>
        <Button
          type='button'
          kind='filled'
          onClick={() => {
            const sessionToBeLaunched = {
              userKey,
              userName,
              endDate: formatLocalizedDateTime(data.sessionEndDate),
              startDate: formatLocalizedDateTime(data.sessionStartDate),
              accountId: data.privilegeId,
              instanceId: data.instanceIdSession,
              status: data.sessionStatus,
              accessKey: data.requestAccessKey.toString(),
              hostIp: data.hostIp,
              osType: data.pamPlatformType,
              requestId: data.requestId,
              entitlementName: 'Instance',
              credentialtype: data.pamType,
              remoteApp: data.remoteApp,
              remoteAppMetadata: data.remoteAppMetadata,
            };

            if (isRdp(data.remoteAppMetadata)) {
              setIsSessionTargetModalVisible(true);
              setSessionDetailsForAd(sessionToBeLaunched);
            } else {
              setSessionToLaunch(sessionToBeLaunched);
            }
          }}
          leftIcon={
            <Icon kind='LaunchRocket' size='smallMedium' color='neutral-100' />
          }>
          {localize(msgs.pam.sidePanel.launchSession)}
        </Button>
        {renderSessionMoreOptions()}
      </section>
    </footer>
  );

  // TODO: Remove after getting requirements for Credentialless
  const defaultButtons = () => (
    <footer className='SpfSession-twoButtons'>
      <Button
        type='button'
        kind='outlined'
        className='SpfSession-twoButtons--buttonStyle'
        onClick={() => credentialRequestOnClick(MODAL_PAGE_KINDS.COC)}
        leftIcon={
          <Icon
            kind='PasswordCredentialUnlock'
            size='smallMedium'
            color='neutral-100'
          />
        }>
        {localize(msgs.pam.sidePanel.checkoutCredential)}
      </Button>
      <Button
        type='button'
        kind='outlined'
        className='SpfSession-twoButtons--buttonStyle'
        onClick={() => credentialRequestOnClick(MODAL_PAGE_KINDS.CTS)}
        leftIcon={
          <Icon kind='ConnectPlug' size='smallMedium' color='neutral-100' />
        }>
        {localize(msgs.pam.sidePanel.connectToSession)}
      </Button>
    </footer>
  );

  const renderFooter = () => {
    if (isActiveSession) {
      if (isCredentialed || isRole) {
        return credentialedActiveSessionFooter();
      }

      return credentiallessActiveSessionFooter();
    }

    if (isPendingSession) {
      return pendingSessionFooter();
    }

    if (isFutureApprovedSession) {
      return futureApprovedSessionFooter();
    }

    return defaultButtons();
  };

  return (
    <Box className={classes} dataTestId={dataTestId}>
      {renderFooter()}
    </Box>
  );
}

SpfSession.propTypes = {
  data: ModuleDetails,
  dataTestId: PropTypes.string,
  credentialRequestOnClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  setIsCheckInModalOpen: PropTypes.func,
  setIconClickedForRow: PropTypes.func,
  setCheckInModalKind: PropTypes.func,
  setSidePanelOnClose: PropTypes.func,
  setIsExtendModalOpen: PropTypes.func,
  setSessionToLaunch: PropTypes.func,
  setIsSessionTargetModalVisible: PropTypes.func,
  setSessionDetailsForAd: PropTypes.func,
};

SpfSession.defaultProps = {
  data: null,
  dataTestId: null,
  className: null,
  setIsCheckInModalOpen: null,
  setIconClickedForRow: null,
  setCheckInModalKind: null,
  setSidePanelOnClose: null,
  setIsExtendModalOpen: null,
  setSessionToLaunch: null,
  setIsSessionTargetModalVisible: null,
  setSessionDetailsForAd: null,
};

export default SpfSession;
