import React from 'react';
import './Footer.css';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { defineMessages } from '@formatjs/intl';
import { StringToHTML } from 'ui-components/src';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const Footer = ({
  intl, version, classes, expandSideMenu,isNotMobileFooter
}) => {
  const messages = defineMessages({
    footer: {
      id: 'APP.FOOTER',
    },
  });
  const footerString = intl.formatMessage(messages.footer, {
    0: new Date().getFullYear(),
    1: 'Saviynt',
    2: version || '',
  }).slice(0, 115);

  return (
    <div
      className={`${classes.leftNavigationFooter} ${
        expandSideMenu ? classes.leftNavigationFooterShow : classes.leftNavigationFooterHide
      } `}
    >
      <StringToHTML
        string={footerString}
        className={isNotMobileFooter && `${classes.notMobileFooter}`}
      />
    </div>
  );
};

Footer.propTypes = {
  version: PropTypes.string.isRequired,
  intl: PropTypes.arrayOf(
    PropTypes.shape({
      defineMessages: PropTypes.func.isRequired,
    }),
  ).isRequired,
};

const mapStateToProps = (state) => ({
  version: state.user.ecmVersion,
});

const ConnectedFooter = injectIntl(withRouter(connect(mapStateToProps)(Footer)));
ConnectedFooter.displayName = 'ConnectedFooter';
export default ConnectedFooter;
