/**
 * Localizes a message using the intl object based on the provided ID or message object.
 * @param {object} intl - The intl object used for message formatting.
 * @param {string|object} message - The message ID or message descriptor object.
 * @param {object} placeholders - Optional object containing placeholder values.
 * @returns {string} The localized message, or (defaultMessage or id) if not found.
 *
 * @example localize(intl, 'hello.name.id'); // using a msg ID string
 * output: "Hello John!"
 *
 * @example localize(intl, { id: 'greeting.message', defaultMessage: 'Hello {name}!' }, { name: 'John' });
 * output: "Hello John!"
 *
 * Localizing a message using a descriptor object
 * @example
 * const messageDescriptor = {
 *  id: 'greeting.message',
 *  defaultMessage: 'Hello {name}!',
 * };
 * const placeholders = {
 *  name: 'John',
 * };
 * localize(intl, messageDescriptor, placeholders); // placeholders can be inline instead.
 * output: "Hello John!"
 */

// eslint-disable-next-line import/prefer-default-export
export const localize = (intl, message, placeholders = {}) => {
  if (typeof message === 'string') {
    return intl.formatMessage({ id: message }, placeholders);
  }

  // message is an object with id and defaultMessage.
  return intl.formatMessage(message, placeholders);
};
