import { 
    ar,
    bg,
    ca,
    zhCN,
    zhTW,
    hr,
    cs,
    da,
    nl,
    nlBE,
    enCA,
    enUS,
    enGB,
    eo,
    // fil,
    fi,
    fr,
    frCH,
    gl,
    de,
    el,
    he,
    is,
    id,
    it,
    ja,
    ko,
    mk,
    nb,
    pl,
    pt,
    ro,
    ru,
    sk,
    es,
    sv,
    th,
    tr,
    // ua,
    vi
} from "date-fns/locale";

  export const locales = [
   { 
    savCode:'ar',
    libObj:ar,
    },
    {
    savCode:'bg',
    libObj:bg,   
    },
    {
    savCode:'ca',
    libObj:ca,
    },
    {
    savCode:'zh_CN',
    libObj:zhCN,
    },
    {
        savCode:'zh_TW',
        libObj:zhTW,
    },
    {
        savCode:'hr',
        libObj:hr,
    },
    {
        savCode:'cs',
        libObj:cs,
    },
    {
        savCode:'da',
        libObj:da,
    },
    {
        savCode:'nl',
        libObj:nl,
    },
    {
        savCode:'nlBE',
        libObj:nlBE,
    },
    {
        savCode:'en_CA',
        libObj:enCA,
    },
    {
        savCode:'en',
        libObj:enUS,
    },
    {
        savCode:'en_US',
        libObj:enUS,
    },
    {
        savCode:'en_GB',
        libObj:enGB,
    },
    {
        savCode:'eo',
        libObj:eo,
    },
    {
        savCode:'fi',
        libObj:fi,
    },
    {
        savCode:'fr',
        libObj:fr,
    },
    {
        savCode:'fr_CA',
        libObj:fr,
    },
    {
        savCode:'fr_CH',
        libObj:frCH,
    },
    {
        savCode:'gl',
        libObj:gl,
    },
    {
        savCode:'de',
        libObj:de,
    },
    {
        savCode:'el',
        libObj:el,
    },
    {
        savCode:'he',
        libObj:he,
    },
    {
        savCode:'is',
        libObj:is,
    },
    {
        savCode:'id',
        libObj:id,
    },
    {
        savCode:'it',
        libObj:it,
    },
    {
        savCode:'ja',
        libObj:ja,
    },
    {
        savCode:'ko',
        libObj:ko,
    },
    {
        savCode:'mk',
        libObj:mk,
    },
    {
        savCode:'nb',
        libObj:nb,
    },
    {
        savCode:'pl',
        libObj:pl,
    },
    {
        savCode:'pt',
        libObj:pt,
    },
    {
        savCode:'ro',
        libObj:ro,
    },
    {
        savCode:'ru',
        libObj:ru,
    },
    {
        savCode:'sk',
        libObj:sk,
    },
    {
        savCode:'es',
        libObj:es,
    },
    {
        savCode:'sv',
        libObj:sv,
    },
    {
        savCode:'th',
        libObj:th,
    },
    {
        savCode:'tr',
        libObj:tr,
    },
    {
        savCode:'vi',
        libObj:vi,
    }
];
export const defaultLocale = {
    savCode: 'en',
    libObj: enUS
};
