import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from './ShowSpinner.module.css';

const ShowSpinner = ({ isDisplayed }) => {
  if (isDisplayed) {
    return (
      <div className={styles.spinner}>
        <CircularProgress />
      </div>
    );
  }

  return null;
};

export default ShowSpinner;
