/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useRef } from 'react';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import NoSsr from '@material-ui/core/NoSsr';
import './ScrollPaginationDropdown.scss';
import { useDebounce } from 'use-debounce';
import { IconButton } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { MultiSelectChip } from 'ui-components/src';
import OptionsWrapper from './OptionsWrapper';

const useStyles = makeStyles(theme => ({
  label: {
    display: 'block',
    fontSize: '12px',
    color: '#9d9d9d',
  },
  input: {
    width: '100%',
    zIndex: 2,
    cursor: 'pointer',
  },
  listbox: {
    width: '100% !important',
    margin: 0,
    padding: 0,
    zIndex: 1,
    paddingLeft: '20px',
    listStyle: 'none',
    maxHeight: 200,
    border: '1px solid rgba(0,0,0,.25)',
    backgroundColor: theme.palette.common.white,
    borderRadius: '4px',
    boxShadow: '2px 10px 13px 1px rgba(0,0,0,0.19)',
    color: `${getComputedStyle(document.body).getPropertyValue('--black-li')} !important`,
    '& .checked svg': {
      color: 'transparent',
    },
    '& li[data-focus="true"]': {
      backgroundColor: `${getComputedStyle(document.body).getPropertyValue(
        '--primary-bg-li'
      )} !important`,
      color: `${getComputedStyle(document.body).getPropertyValue('--black-li')} !important`,
      cursor: 'pointer',
    },

    '& li[aria-selected="true"]': {
      backgroundColor: '#fafafa',
      fontWeight: 600,

      '& .checked svg': {
        color: '#1890ff',
      },
    },
    '& li:active': {
      backgroundColor: `${getComputedStyle(document.body).getPropertyValue(
        '--primary-bg-li'
      )} !important`,
      color: `${getComputedStyle(document.body).getPropertyValue('--black-li')} !important`,
    },
    '& ul li': {
      fontSize: '13px',
      paddingLeft: '10px',
      paddingRight: '10px',
      paddingTop: '4px',
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  inputBox: {
    width: '100% !important',
  },
  root: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '8px',
    alignContent: 'flex-end',
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
  helperText: {
    margin: '10px 0 0',
    fontSize: '12px',
    lineHeight: 1.4,
  },
  field: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    alignSelf: 'flex-end',
  },
  popper: {
    marginTop: 50,
  },
}));

const ScrollPaginationDropdown = ({
  multiSelect,
  placeholder,
  fetchItems,
  items,
  totalCount,
  hasNext = false,
  getLabel = option =>
    option.ID ? option.ID : option.title ? option.title : option.id ? option.id : option,
  getId = option => (option.ID ? option.ID : option.id ? option.id : option),
  offset,
  _handleChange,
  wrappedHandleChange,
  attributeKey,
  defaultValue,
  required,
  showError,
  readOnly,
  descriptionType,
  scrollToError,
  intl,
  name,
  supportsHtmlTags,
  filterSelectedOptions,
  from,
  noOptionsText,
  clearOnBlur = false,
  clearOnEscape = false,
  position,
  module,
  optionsNotRequired,
  hideCheckMark,
  halfWidth,
  itemHeight,
  getCalculatedHeight,
  ...props
}) => {
  const classes = useStyles();
  const inputTextFieldRef = useRef(null);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [isNextPageLoading, setIsNextPageLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [searchValue, setSearchValue] = useState(null);
  const [debouncedText] = useDebounce(searchValue, 500);

  const loadNextPage = (...args) => {
    if (
      module !== 'grid' ||
      (module === 'grid' &&
        items.length < totalCount &&
        totalCount > 25 &&
        totalCount >= page + offset &&
        (!debouncedText || debouncedText.length > 2))
    ) {
      setIsNextPageLoading(true);
      fetchItems(page + offset, searchValue);
      setPage(page + offset);
      setHasNextPage(items.length < totalCount || hasNext);
    }
    // It should total records which API is sending
    setIsNextPageLoading(false);
  };

  const fetchInitialItems = clearText => {
    setHasNextPage(true);
    if (from !== 'applicationRequest') setIsNextPageLoading(true);
    setPage(0);
    setSearchValue(debouncedText);
    fetchItems(0, clearText ? '' : debouncedText, true);
  };

  const reset = () => {
    fetchInitialItems(true);
    setSearchValue('');
  };

  useEffect(() => {
    if (from !== 'applicationRequest') {
      if (!hasNext) {
        setHasNextPage(false);
      }
      setIsNextPageLoading(false);
    }else if(from === 'applicationRequest' && items?.length === 0){
      setHasNextPage(true);
    }
    if (module === 'grid') setHasNextPage(items.length < totalCount);
  }, [hasNext, items]);

  useEffect(() => {
    if (debouncedText || debouncedText === '') {
      if (
        module !== 'grid' ||
        (module === 'grid' && (debouncedText.length >= 3 || debouncedText === ''))
      ) {
        fetchInitialItems();
      }
    }
    return () => { };
  }, [debouncedText]);

  const handleFocus = () => {
    if ((!items || !items.length) && hasNextPage) {
      fetchInitialItems();
    }
  };

  useEffect(() => {
    if (scrollToError === 'true') {
      inputTextFieldRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [scrollToError]);

  const [prevURL, setPrevURL] = useState('');
  useEffect(() => {
    if (props.apiURL) {
      if (props.apiURL !== prevURL) {
        fetchInitialItems();
      }
      setPrevURL(props.apiURL);
    }
  }, [props.apiURL]);

  const searchChange = (e, val) => {
    if (e) {
      e.preventDefault();
      setSearchValue(e.target.value);
    }
  };

  const handleChange = (event, val) => {
    if (!multiSelect) {
      _handleChange(val ? [getId(val)] : [], attributeKey, val);
    } else {
      const values = [];
      if (val) {
        val.map(item => {
          values.push(getId(item));
        });
      }
      _handleChange(values || [], attributeKey, val);
      if (module === 'grid') reset();
      else if(from === 'applicationRequest' && searchValue)
        reset();
    }
  };

  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    if (!defaultValue) setValue(multiSelect ? [] : {});
    else setValue(defaultValue);
  }, [defaultValue]);

  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    getTagProps,
    groupedOptions,
  } = useAutocomplete({
    id: 'use-autocomplete-demo',
    options: items,
    multiple: multiSelect,
    clearOnBlur,
    clearOnEscape,
    getOptionLabel: getLabel,
    onInputChange: searchChange,
    value,
    onChange: wrappedHandleChange || handleChange,
    openOnFocus: true,
    onOpen: handleFocus,
    getOptionSelected:
      from === 'applicationRequest' ? (option, value) => option.ID === value.ID : (option, value) => option.value === value.value,
    filterSelectedOptions: from === 'applicationRequest' ? undefined : filterSelectedOptions,
  });

  const adornment =
    multiSelect && value.length > 0 && !optionsNotRequired ? (
      <>
        {value.map((option, index) => (
          <div {...getTagProps({ index })} className="selected-options">
            <MultiSelectChip
              label={getLabel(option)}
              {...getTagProps({ index })}
              disabled={readOnly}
            />
          </div>
        ))}
      </>
    ) : null;

  return (
    <NoSsr>
      <div className={classes.root} ref={inputTextFieldRef}>
        <div className={classes.field}>
          {supportsHtmlTags && (
            <div className="support-html">
              <span dangerouslySetInnerHTML={{ __html: placeholder }} />
              {required && <span className="mandatory">&nbsp;*</span>}
            </div>
          )}
          <div className="pagination-scroll-dropdown">
            <div {...getRootProps()}>
              <div className={classes.inputBox}>
                <div className="formlayout">
                  <TextField
                    name="pagination-dropdown"
                    autoComplete="off"
                    margin="dense"
                    className={classes.input}
                    fullWidth
                    disabled={readOnly}
                    label={
                      supportsHtmlTags ? (
                        ''
                      ) : (
                        <>
                          <span dangerouslySetInnerHTML={{ __html: placeholder }} />
                          {required && <span className="mandatory">&nbsp;*</span>}
                        </>
                      )
                    }
                    error={required ? showError : false}
                    InputLabelProps={
                      adornment
                        ? {
                          shrink: true,
                        }
                        : {}
                    }
                    hidePlaceholder={!!adornment}
                    InputProps={{
                      startAdornment: adornment,
                      style: {
                        display: 'flex',
                        flexFlow: 'row wrap',
                      },
                    }}
                    inputProps={{
                      ...getInputProps(),
                      style: {
                        width: 'auto',
                        minWidth: '30%',
                        maxWidth: '100%',
                        flexGrow: 1,
                      },
                    }}
                  />
                  <IconButton aria-label="close" className="dropdownicon">
                    <ArrowDropDownIcon />
                  </IconButton>
                </div>
              </div>
            </div>
            {groupedOptions.length > 0 ? (
              <>
                <OptionsWrapper
                  hasNextPage={hasNextPage}
                  isNextPageLoading={isNextPageLoading}
                  items={items}
                  loadNextPage={loadNextPage}
                  wrapperClassName={classes.listbox}
                  getOptionProps={getOptionProps}
                  groupedOptions={groupedOptions}
                  listboxProps={getListboxProps()}
                  getLabel={getLabel}
                  menuStyle={props.menuStyle}
                  noOptionsText={noOptionsText}
                  position={position}
                  hideCheckMark={hideCheckMark}
                  halfWidth={halfWidth}
                  itemHeight={itemHeight}
                  searchText={debouncedText}
                  module={module}
                  totalCount={totalCount}
                  getCalculatedHeight={getCalculatedHeight}
                  descriptionType={descriptionType}
                />
              </>
            ) : null}
          </div>
        </div>
      </div>
    </NoSsr>
  );
};

// ScrollPaginationDropdown.defaultProps = {
//   _handleChange: () => {},
//   wrappedHandleChange: () => {},
// };

export default ScrollPaginationDropdown;
