import CsvDownloader from 'react-csv-downloader';
import React from 'react';
import moment from 'moment';

const fileName = `Logs_ExportData_${moment().utc().format('YYYY-MM-DD_hh-mm-ss-A')}(UTC)`;

const csvColumns = (intl) => ([
  {
    id: 'timestamp',
    displayName: `${intl.formatMessage({ id: 'MANAGE.LOGS.DATE.TIMESTAMP.COLUMN.LABEL' })} (UTC)`,
  },
  {
    id: 'servicename',
    displayName: intl.formatMessage({ id: 'MANAGE.LOGS.SERVICE.NAME.COLUMN.LABEL' }),
  },
  {
    id: 'className',
    displayName: intl.formatMessage({ id: 'MANAGE.LOGS.CLASS.NAME.COLUMN.LABEL' }),
  },
  {
    id: 'threadName',
    displayName: intl.formatMessage({ id: 'MANAGE.LOGS.THREAD.NAME.COLUMN.LABEL' }),
  },
  {
    id: 'level',
    displayName: intl.formatMessage({ id: 'MANAGE.LOGS.LEVEL.COLUMN.LABEL' }),
  },
  {
    id: 'message',
    displayName: intl.formatMessage({ id: 'MANAGE.LOGS.MESSAGE.COLUMN.LABEL' }),
  },
]);

const DisplayText = ({ intl, showLabel }) => (showLabel ? (
  <span className="font-medium">
    {intl.formatMessage({ id: 'MANAGE.LOGS.DOWNLOAD.LABEL' })}
    {' '}
    {intl.formatMessage({ id: 'MANAGE.LOGS.RECORDS.LABEL' })}
    {' '}
    (
    {intl.formatMessage({ id: 'MANAGE.LOGS.FILE.FORMAT.LABEL' })}
    )
  </span>
) : '');

const CSVDownload = ({
  intl, data, showLabel = true, children,
}) => (
  <CsvDownloader
    text={<DisplayText intl={intl} showLabel={showLabel} />}
    datas={data}
    filename={fileName}
    extension=".csv"
    columns={csvColumns(intl)}
    wrapColumnChar='"'
    separator=","
  >
    {children}
  </CsvDownloader>
);

export default CSVDownload;
