/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import { useLocalize } from '@saviynt/common';
import { Box, Button, Icon } from '@saviynt/design-system';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { MODAL_PAGE_KINDS } from '../../../ModalPageForms/constants';
import { msgs } from '../../constants';

import './SpfRoles.css';

function SpfRoles({ checkoutRoleOnClick, dataTestId, className }) {
  const classes = classnames('SpfRoles', className);
  const localize = useLocalize();

  return (
    <Box className={classes} dataTestId={dataTestId}>
      <Button
        type='button'
        kind='outlined'
        leftIcon={
          <Icon
            kind='PasswordCredentialUnlock'
            size='smallMedium'
            color='neutral-100'
          />
        }
        onClick={() => checkoutRoleOnClick(MODAL_PAGE_KINDS.COR)}>
        {localize(msgs.pam.sidePanel.checkoutRole)}
      </Button>
    </Box>
  );
}

SpfRoles.propTypes = {
  dataTestId: PropTypes.string,
  checkoutRoleOnClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

SpfRoles.defaultProps = {
  dataTestId: null,
  className: null,
};

export default SpfRoles;
