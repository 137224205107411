import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import useOnClickOutside from 'use-onclickoutside';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DateRangeIcon from '@material-ui/icons/DateRange';
import Fade from '@material-ui/core/Fade';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { isBefore, addHours } from 'date-fns';
import { getSelectedLanguageObject } from 'ui-common/src/lib/utils';
import DateFnsUtils from '@date-io/date-fns';
import { SlimSelectStandard } from '../SlimSelectStandard';
import DatePickerComponent from './DatePicker';
import './DateRangePicker.scss';

const useStyles = makeStyles(theme => ({
  popper: {
    zIndex: theme.zIndex.tooltip,
    position: 'absolute',
    top: 0,
    left: 0,
    flip: false, // disable jss-rtl plugin
  },
  paper: {
    padding: '0px 20px 20px',
    display: 'flex',
    marginTop: '6px',
    marginLeft: '20px',
  },
  blueLabel: {
    color: '#374eff',
  },
  timeInput: {
    marginTop: '20px',
  },
  cancelButton: {
    fontSize: '14px',
    marginRight: '13px',
    fontWeight: 500,
    padding: '5px 16px',
  },
  applyButton: {
    fontSize: '12px',
    marginRight: '13px',
    fontWeight: 500,
    padding: '5px 24px',
  },
  initialDateStyle: {
    display: 'flex',
    width: '100%',
    marginBottom: '20px',
    position: 'relative',
  },
  calendarIcon: {
    position: 'absolute',
    left: '95%',
    top: '39%',
    cursor: 'pointer',
  },
  hyphen: {
    position: 'absolute',
    top: '39%',
    left: '45%',
  },
  calendarIconMedium: {
    position: 'absolute',
    left: '50%',
    top: '40%',
    cursor: 'pointer',
  },
  hyphenMedium: {
    position: 'absolute',
    top: '40%',
    left: '24%',
  },
  containerSection: {
    display: 'flex',
    flexDirection: 'column',
  },
  datetimeSection: {
    display: 'flex',
    flexDirection: 'row',
  },
  contentSection: {
    display: 'flex',
    flexDirection: 'column',
  },
  dateTimeInput: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '10px',
    marginTop: '20px',
    '& .MuiInputAdornment-root': {
      display: 'none',
    },
  },
}));

const DateTimeRangePicker = ({
  startDate,
  endDate,
  startDateMandatory,
  endDateMandatory,
  handleDateChangeProp,
  item,
  size,
  disableStartDate,
  disableEndDate,
  defaultTimeFrameHRS,
  minStartDate,
  maxStartDate,
  maxEndDate,
  disableYear,
  disableManualDateEntry,
  disableFutureDates,
  dateLocaleFormat,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [startDateState, setStartDate] = useState(null);
  const [endDateState, setEndDate] = useState(null);
  const [dateError, setDateError] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (startDate) {
      setStartDate(new Date(startDate.getTime()));
    }
  }, [startDate]);

  useEffect(() => {
    if (endDate) {
      setEndDate(new Date(endDate.getTime()));
    }
  }, [endDate]);

  const handleDateError = error => {
    if (
      error === 'Date should not be before minimal date' ||
      error === 'Date should not be after maximal date'
    ) {
      setDateError(true);
    }
  };

  const handleDateChange = (value, attributeKey) => {
    if (value && value.toString() === 'Invalid Date') {
      setDateError(true);
    } else if (value) {
      setDateError(false);
      switch (attributeKey) {
        case 'startDate': {
          if (value.toDateString() !== new Date().toDateString()) {
            value.setMilliseconds(0);
            value.setMinutes(0);
            value.setHours(0);
            setStartDate(value);
            if (defaultTimeFrameHRS) {
              const endDate = addHours(value, defaultTimeFrameHRS);
              setEndDate(endDate);
            }
          } else {
            setStartDate(new Date());
            if (defaultTimeFrameHRS) {
              const endDate = addHours(new Date(), defaultTimeFrameHRS);
              setEndDate(endDate);
            }
          }
          const edDate = endDateState;
          if (edDate && value.getTime() - edDate.getTime() > 0) {
            setEndDate(null);
          }
          break;
        }
        case 'endDate': {
          value.setMilliseconds(0);
          value.setMinutes(59);
          value.setHours(23);
          if (!startDateState) {
            setEndDate(value);
          } else if (startDateState.getTime() - value.getTime() <= 0) {
            setEndDate(value);
          }
          const stDate = startDateState;
          setStartDate(stDate);
          break;
        }
        default:
          break;
      }
    }
  };

  const handleTimeSlot = (value, key) => {
    if (key === 'startTime') {
      const stDate = startDateState;
      if (stDate) {
        const temp = new Date();
        if (stDate.toDateString() === temp.toDateString()) {
          if (temp.getHours() < value.value.hours) return true;
          if (temp.getHours() === value.value.hours && temp.getMinutes() < value.value.minutes)
            return true;
        }
        if (disableFutureDates) {
          if (!isBefore(temp, stDate)) return true;
        } else if (isBefore(temp, stDate)) return true;
      }
    }
    if (key === 'endTime') {
      if (endDateState) {
        const edDate = endDateState;
        const temp = startDateState;
        if (temp.toDateString() === edDate.toDateString()) {
          if (temp.getHours() < value.value.hours) return true;
          if (temp.getHours() === value.value.hours && temp.getMinutes() < value.value.minutes)
            return true;
        } else return true;
      }
    }
    return false;
  };

  const handleTimeChange = (e, key) => {
    if (key === 'startTime') {
      const stDate = startDateState;
      const edDate = endDateState;
      stDate.setMinutes(e.value.minutes);
      stDate.setHours(e.value.hours);
      stDate.setMilliseconds(e.value.seconds);
      setStartDate(new Date(stDate.getTime()));
      if (defaultTimeFrameHRS) setEndDate(addHours(stDate, defaultTimeFrameHRS));
      else if (edDate && stDate.getTime() - edDate.getTime() > 0) setEndDate(null);
    } else {
      const edDate = new Date(endDateState.getTime());
      edDate.setMinutes(e.value.minutes);
      edDate.setHours(e.value.hours);
      edDate.setMilliseconds(e.value.seconds);
      setEndDate(new Date(edDate.getTime()));
    }
  };

  const listenScrollEvent = event => {
    if (event.srcElement.nodeName === '#document') {
      if (anchorEl) {
        anchorEl.focus();
      }
      setAnchorEl(null);
    }
  };

  useLayoutEffect(() => {
    window.addEventListener('scroll', listenScrollEvent, true);
    return () => window.removeEventListener('scroll', listenScrollEvent, true);
  }, []);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = type => {
    if (type === 'close') {
      setStartDate(new Date(startDate.getTime()));
      if (endDate) setEndDate(new Date(endDate.getTime()));
      else setEndDate(null);
    }
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = !open ? 'simple-popover' : undefined;

  const dateValidation = () => {
    if (!dateError) {
      if (
        startDateState &&
        startDateState.toString() !== 'Invalid Date' &&
        endDateState &&
        endDateState.toString() !== 'Invalid Date'
      ) {
        return false;
      }
    }
    return true;
  };

  useOnClickOutside(ref, () => {
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  });

  const dateInputs = (label, dateValue, mandatory) => {
    return (
      <TextField
        key={label}
        autoComplete="off"
        type="text"
        value={
          dateValue
            ? dateValue.toLocaleString(dateLocaleFormat, {
                year: '2-digit',
                day: '2-digit',
                month: '2-digit',
                hour12: false,
                hour: 'numeric',
                minute: '2-digit',
              })
            : ''
        }
        classes={{ root: classes.input, input: classes.input }}
        fullWidth={size === 'large'}
        inputProps={{ 'aria-label': 'description' }}
        InputProps={{ disableUnderline: false }}
        onFocus={handleClick}
        InputLabelProps={{ shrink: true }}
        label={
          <>
            <span className={classes.blueLabel}>{label}</span>
            {mandatory && <span className="mandatory">&nbsp;*</span>}
          </>
        }
      />
    );
  };

  return (
    <div>
      <div className={classes.initialDateStyle}>
        {dateInputs('From', startDate, startDateMandatory)}
        <div className={size === 'medium' ? classes.hyphenMedium : classes.hyphen}>-</div>
        {dateInputs('To', endDate, endDateMandatory)}

        {(!disableStartDate || !disableEndDate) && (
          <DateRangeIcon
            className={size === 'medium' ? classes.calendarIconMedium : classes.calendarIcon}
            onClick={handleClick}
          />
        )}
      </div>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        className={clsx(classes.popper)}
        placement="bottom-end"
        disablePortal
        onClose={() => handleClose('close')}
        ref={ref}
        modifiers={{
          flip: {
            enabled: false,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: 'viewport',
          },
          arrow: {
            enabled: false,
          },
        }}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={550}>
            <Paper className={classes.paper}>
              <div className={clsx(classes.containerSection, 'datePicker-custom-style')}>
                <div className={classes.datetimeSection}>
                  <div className={classes.contentSection}>
                    <div className={classes.dateTimeInput}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getSelectedLanguageObject()}>
                        <KeyboardDatePicker
                          key="startDate"
                          variant="inline"
                          inputVariant="standard"
                          label={
                            <>
                              <span className={classes.blueLabel}>From</span>
                              {startDateMandatory && <span className="mandatory">&nbsp;*</span>}
                            </>
                          }
                          format="MM/dd/yyyy"
                          placeholder="MM/dd/yyyy"
                          value={startDateState}
                          disabled={disableManualDateEntry}
                          minDate={minStartDate}
                          maxDate={maxStartDate}
                          onChange={date => handleDateChange(date, 'startDate', item)}
                          onError={error => handleDateError(error)}
                        />
                      </MuiPickersUtilsProvider>

                      <div className={classes.timeInput}>
                        <SlimSelectStandard
                          attributeKey="startTime"
                          defaultValue={startDateState}
                          disabled={disableStartDate}
                          visibleTimeSlot={(option, attrKey) => {
                            return handleTimeSlot(option, attrKey, item);
                          }}
                          onChange={(option, attrKey) => {
                            handleTimeChange(option, attrKey, item);
                          }}
                        />
                      </div>
                    </div>

                    <DatePickerComponent
                      attributeKey="startDate"
                      value={startDateState}
                      _handleChange={date => {
                        handleDateChange(date, 'startDate', item);
                      }}
                      isExisting={false}
                      readOnly={disableStartDate}
                      minDate={minStartDate}
                      maxDate={maxStartDate}
                      disableYear={disableYear}
                    />
                  </div>
                  <div className={classes.contentSection}>
                    <div className={classes.dateTimeInput}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getSelectedLanguageObject()}>
                        <KeyboardDatePicker
                          key="endDate"
                          variant="inline"
                          inputVariant="standard"
                          label={
                            <>
                              <span className={classes.blueLabel}>To</span>
                              {endDateMandatory && <span className="mandatory">&nbsp;*</span>}
                            </>
                          }
                          format="MM/dd/yyyy"
                          placeholder="MM/dd/yyyy"
                          value={endDateState}
                          disabled={disableManualDateEntry}
                          minDate={startDateState}
                          maxDate={maxEndDate}
                          onChange={date => handleDateChange(date, 'endDate', item)}
                          onError={error => handleDateError(error)}
                        />
                      </MuiPickersUtilsProvider>
                      <div className={classes.timeInput}>
                        <SlimSelectStandard
                          attributeKey="endTime"
                          defaultValue={endDateState}
                          disabled={disableEndDate}
                          visibleTimeSlot={(option, attrKey) => {
                            return handleTimeSlot(option, attrKey, item);
                          }}
                          onChange={(option, attrKey) => {
                            handleTimeChange(option, attrKey, item);
                          }}
                        />
                      </div>
                    </div>
                    <div>
                      <DatePickerComponent
                        attributeKey="endDate"
                        value={endDateState}
                        _handleChange={date => {
                          handleDateChange(date, 'endDate', item);
                        }}
                        isExisting={false}
                        readOnly={disableEndDate}
                        minDate={startDateState}
                        maxDate={maxEndDate}
                        disableYear={disableYear}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex justify-end mt-3">
                  <Button
                    color="primary"
                    size="medium"
                    variant="outlined"
                    disableElevation
                    className={classes.cancelButton}
                    onClick={() => handleClose('close')}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    size="medium"
                    variant="contained"
                    disableElevation
                    disabled={dateValidation()}
                    className={classes.applyButton}
                    onClick={() => {
                      handleDateChangeProp(startDateState, endDateState, item);
                      handleClose();
                    }}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
};

DateTimeRangePicker.defaultProps = {
  startDateMandatory: false,
  endDateMandatory: false,
  item: '',
  size: 'small',
  minStartDate: new Date(),
  maxStartDate: new Date('2100-01-01'), // Max Date should be coming from CONFIG
  maxEndDate: new Date('2100-01-01'), // Max Date should be coming from CONFIG
  disableYear: false,
  disableManualDateEntry: true,
  disableFutureDates: false,
  dateLocaleFormat: 'en-US',
};

DateTimeRangePicker.propTypes = {
  startDate: PropTypes.instanceOf(Object).isRequired,
  endDate: PropTypes.instanceOf(Object).isRequired,
  startDateMandatory: PropTypes.bool,
  endDateMandatory: PropTypes.bool,
  handleDateChangeProp: PropTypes.func.isRequired,
  item: PropTypes.string,
  size: PropTypes.string,
  disableStartDate: PropTypes.bool.isRequired,
  disableEndDate: PropTypes.bool.isRequired,
  defaultTimeFrameHRS: PropTypes.bool.isRequired,
  minStartDate: PropTypes.instanceOf(Date),
  maxStartDate: PropTypes.instanceOf(Date),
  maxEndDate: PropTypes.instanceOf(Date),
  disableYear: PropTypes.bool,
  disableManualDateEntry: PropTypes.bool,
  disableFutureDates: PropTypes.bool,
};

export default DateTimeRangePicker;
