
import React from 'react';

const ExpandMoreIcon = () => (
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.34433 15.0169C7.18161 14.8542 7.18161 14.5904 7.34433 14.4277L11.7719 10.0001L7.34433 5.5725C7.18161 5.40978 7.18161 5.14596 7.34433 4.98325C7.50705 4.82053 7.77087 4.82053 7.93358 4.98325L12.6558 9.70547C12.8185 9.86819 12.8185 10.132 12.6558 10.2947L7.93358 15.0169C7.77087 15.1797 7.50705 15.1797 7.34433 15.0169Z" fill="#9EA1B1"/>
</svg>
);

export default ExpandMoreIcon;

