import React from 'react';
import './style.scss';

const Loader = () => (
  <div className="loading-container">
    <div className="loading-bg" />
    <div id="preview-area">
      <div className="spinner">
        <div className="bounce1" />
        <div className="bounce2" />
        <div className="bounce3" />
      </div>
    </div>
  </div>
);

export default Loader;
