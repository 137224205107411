import HttpService from 'ui-home/src/misc/HttpService/HttpService';
import checkEnvironment from 'ui-common/src/lib/checkEnvironment';
import apiData from '../Layout/apiData';
import { Cookies } from 'react-cookie';
export const SIDE_MENU_LOADING = 'SIDE_MENU_LOADING';
export const SIDE_MENU_LOADED = 'SIDE_MENU_LOADED';

const sideMenuLoading = () => ({ type: SIDE_MENU_LOADING, loading: true });

const InitializeSideMenu = () => (dispatch) => {
  const cookies = new Cookies();

  dispatch(sideMenuLoading());
  const { ECM_URL } = checkEnvironment();
  const url = window.location.href;
  const response = HttpService.get(`/ECM/navigation/leftNav`);

  response.then((res) => {
    dispatch({
      type: SIDE_MENU_LOADED,
      data: res.topLevel,
    });
  });
};

export { InitializeSideMenu };
