import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { injectIntl } from 'react-intl';
import { makeStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

const useStyles = makeStyles(theme => ({
  close: {
    padding: theme.spacing(0.5),
  },
  themeMessage: {
    color: '#000',
  },
  snackbarStyleViaContentProps: {
    backgroundColor: '#fff',
    borderRadius: '9px',
    boxShadow: '0 2px 8px 2px rgba(151, 151, 151, 0.5)',
  },
  button: {
    marginRight: '11px',
  },
  outlined: {
    padding: '2px 7px',
    fontWeight: 700,
  },
  contentSection: {
    display: 'flex',
    flexDirection: 'row',
  },
  containedPrimary: {
    backgroundColor: '#3751FF',
  },
  iconSection: {
    width: '30px',
    height: '30px',
    background: '#3751FF',
    borderRadius: '100%',
    position: 'relative',
  },
  timerIcon: {
    position: 'absolute',
    top: '48%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  messageSction: {
    color: '#000',
    paddingLeft: '16px',
    '& h4': {
      fontSize: '14px',
      marginBottom: 0,
      marginTop: '7px',
    },
    '& p': {
      marginBottom: 0,
    },
  },
}));

const theme = createMuiTheme({
  palette: {
    primary: {
      main: 'rgb(55, 81, 255, 1)',
      contrastText: '#fff',
    },
    typography: {
      fontFamily: 'Rubik, sans-serif',
      fontSize: 12,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'capitalize',
        lineHeight: 'inherit',
        border: 'none',
        borderRadius: 3,
        fontSize: 12,
        textAlign: 'center',
        letterSpacing: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
      },
      contained: {
        padding: '9px 15px',
      },
    },
  },
});

const ThemePreviewSnackbarComponent = ({
  open,
  onSnackBarClose,
  onUpdateTheme,
  children,
  intl,
  ...props
}) => {
  const classes = useStyles();

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        bodyStyle={{ backgroundColor: '#fff', color: '#000' }}
        open={open}
        ContentProps={{
          'aria-describedby': 'message-id',
          className: classes.snackbarStyleViaContentProps,
        }}
        message={
          <React.Fragment>
            <div className={classes.contentSection}>
              <div className={classes.iconSection}>
                <span className={classes.timerIcon}>
                  <AccessTimeIcon />
                </span>
              </div>
              <div className={classes.messageSction}>
                <h4>{intl.formatMessage({ id: 'UIBrandConfig.ThemePreview.Title' })}</h4>
              </div>
            </div>
          </React.Fragment>
        }
        {...props}
        action={[
          <React.Fragment>
            <MuiThemeProvider theme={theme}>
              <Button
                color="primary"
                variant="outlined"
                onClick={() => {
                  onUpdateTheme();
                }}
                classes={{
                  root: clsx(classes.button, classes.outlined),
                }}
              >
                {intl.formatMessage({ id: 'UIBrandConfig.UpdatePreview.Label' })}
              </Button>
              <Button
                color="primary"
                variant="outlined"
                onClick={onSnackBarClose}
                className={classes.outlined}
              >
                {intl.formatMessage({ id: 'UIBrandConfig.ClosePreview.Label' })}
              </Button>
            </MuiThemeProvider>
          </React.Fragment>,
        ]}
      >
        {children}
      </Snackbar>
    </div>
  );
};

ThemePreviewSnackbarComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  onSnackBarClose: PropTypes.func.isRequired,
  onUpdateTheme: PropTypes.func.isRequired,
  // children: PropTypes.node.isRequired,
  intl: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};

export default injectIntl(ThemePreviewSnackbarComponent);
