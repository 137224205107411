import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiChip from '@material-ui/core/Chip';

const Chip = withStyles(theme => ({
  /* DO NOT REMOVE - yibang
  root: {
    height: 'unset',
  },
  label: {
    padding: '4px 8px',
  },
  deleteIcon: {
    marginRight: '4px !important',
    width: '19px !important',
  },
  */
}))(MuiChip);

const MultiSelectChip = props => {
  // const { children } = props;
  return <Chip size="small" variant="outlined" {...props} />;
};

export default MultiSelectChip;
