/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import { formatLocalizedDateTime, useLocalize } from '@saviynt/common';
import {
  ApplicationIcon,
  ButtonIcon,
  Chip,
  Icon,
  Menu,
  Tooltip,
} from '@saviynt/design-system';

import CountdownTimer from '../components/CountdownTimer/CountdownTimer';
import { REQUEST_BASE_URL } from '../misc/constants';

const useCreateRowObject = (
  row,
  isModalOpen,
  setIsModalOpen,
  setIconClickedForRow,
  setCheckInModalKind,
  listRowData,
  setListRowData,
  setIsExtendModalOpen,
  onLaunchSession,
  setIsSessionTargetModalVisible,
  setSessionDetailsForAd
) => {
  const history = useHistory();
  const localize = useLocalize();
  const [{ userKey, user_name: userName }] = useCookies([
    'user_name',
    'userKey',
  ]);
  const JIT = 'JIT';

  const KINDS = {
    LABEL_VALUE: 'label-value',
    TEXT: 'text',
    SUBTLE_FILLED: 'subtleFilled',
    GHOST: 'ghost',
    SIMPLE: 'simple',
  };
  const SESSION_STATUSES = {
    SESSION_APPROVED: 1,
    SESSION_ACTIVE: 2,
  };
  const SESSION_STARTED =
    new Date(row?.sessionStartDate).getTime() <= new Date().getTime() &&
    row?.sessionStatus === SESSION_STATUSES.SESSION_ACTIVE;
  const ENDPOINT = 'endpoint';
  const SESSION = 'session';
  const ACCOUNT = 'account';
  const ROLE = 'role';
  const CREDENTIALLESS = 'CREDENTIALLESS';
  const END_TYPE_CHECKIN = 'checkIn';
  const END_TYPE_END_SESSION = 'end';
  const END_TYPE_CANCEL_SESSION = 'cancelSession';
  const END_TYPE_CANCEL_REQUEST = 'cancelRequest';
  const EXTEND_SESSION = 'extendSession';
  const AD_RDP = 'adrdp';
  const IS_CREDENTIALLESS = row?.pamType?.toUpperCase() === CREDENTIALLESS;
  const EMERGENCY_ACCESS_ROLE = 'emergency access role';

  const msgs = {
    pamCheckInModal: {
      extendLabel: {
        id: 'pam.extend.title',
        defaultMessage: 'Extend',
      },
      connectButtonTooltip: {
        id: 'pam.connect.tooltip',
        defaultMessage: 'Launch Session',
      },
      endButtonCredentiallessTooltip: {
        id: 'pam.end.tooltip',
        defaultMessage: 'End Session',
      },
      endButtonCredentialedTooltip: {
        id: 'pam.checkIn.tooltip',
        defaultMessage: 'Check In Credential',
      },
      checkInRoleTooltip: {
        id: 'pam.checkIn.tooltip',
        defaultMessage: 'Check In Role',
      },
      cancelRequestLabel: {
        id: 'pam.cancelRequest.title',
        defaultMessage: 'Cancel Request',
      },
      cancelSessionLabel: {
        id: 'pam.cancelSession.title',
        defaultMessage: 'Cancel Session',
      },
    },
  };

  const isRdp = (remoteAppMetadata) =>
    remoteAppMetadata?.name?.toLowerCase() === AD_RDP;

  const createIcon = (type, kind, color, size) => {
    if (type === ACCOUNT) {
      return <Icon kind='Account' color='neutral-1000' size='small' />;
    }

    return <Icon kind={kind} color={color} size={size} />;
  };

  const createButtonIcon = (actionMap, kind, icon, size, isDisabled) => (
    <ButtonIcon
      size={size}
      kind={kind}
      icon={icon}
      isDisabled={isDisabled}
      onClick={(e) => {
        e.stopPropagation();
        actionMap();
      }}
    />
  );

  const getObjectForCategory = (account, platform, type) => {
    const icon = createIcon(type);

    const obj = {
      account,
      platform,
      icon,
    };

    return obj;
  };

  const getObjectForTitle = (endpointName, accountName, pamAccountType) => {
    const obj = [
      {
        kind: KINDS.TEXT,
        text: endpointName,
      },
    ];

    if (accountName) {
      obj.push({
        kind: KINDS.LABEL_VALUE,
        label: pamAccountType?.toUpperCase().includes(JIT)
          ? 'Account (JIT)'
          : 'Account',
        value: accountName,
      });
    }

    return obj;
  };

  const getPlatformType = (data) => {
    switch (data?.platformType?.toLowerCase()) {
      case 'aws':
      case 'gcp':
      case 'azure':
      case 'okta':
      case 'sap':
        return {
          kind: 'avatar-text',
          avatar: (
            <ApplicationIcon
              size='xxSmall'
              kind='logo'
              logo={data.platformType}
            />
          ),
          text: data.platformType,
        };
      case 'ad':
      case 'active directory':
        return {
          kind: 'avatar-text',
          avatar: <ApplicationIcon size='xxSmall' kind='logo' logo='AD' />,
          text: data.platformType,
        };
      default:
        return null;
    }
  };

  const buildRoleListRowDescription = (obj, data, showPlatformType) => {
    if (data?.roleType?.toLowerCase() === EMERGENCY_ACCESS_ROLE) {
      obj.push({
        kind: 'icon-text',
        icon: createIcon(ROLE, ROLE, null, 'small'),
        text: data.roleType,
      });
    }

    // TODO: Enhance for cross-app after endpoints mapping is corrected by the backend
    if (showPlatformType) {
      const platformType = getPlatformType(data);

      if (platformType) {
        obj.push(platformType);
      }
    }

    if (data.roleDescription) {
      obj.push({ kind: KINDS.TEXT, text: data.roleDescription });
    }
  };

  const getObjectForDescription = (data) => {
    let obj = [];

    if (data.type === ACCOUNT) {
      obj = [
        {
          kind: KINDS.TEXT,
          text: data.accountDescription,
        },
      ];
    } else if (data.type === ENDPOINT || data.type === SESSION) {
      if (data.roleKey) {
        buildRoleListRowDescription(obj, data, true);
      } else {
        switch (data?.assetType?.toLowerCase()) {
          case 'server':
          case 'application':
          case 'database':
            obj.push({
              kind: 'icon-text',
              icon: createIcon(data.type, data.assetType, null, 'small'),
              text: data.assetType,
            });
            break;
          default:
            break;
        }

        const platformType = getPlatformType(data);

        if (platformType) {
          obj.push(platformType);
        }

        if (
          data?.remoteAppMetadata?.displayName &&
          data?.remoteAppMetadata?.displayName?.toLowerCase() !== 'terminal'
        ) {
          obj.push({
            kind: KINDS.LABEL_VALUE,
            label: 'Remote App',
            value: data?.remoteAppMetadata?.displayName,
          });
        }

        if (data.epCustomproperty10) {
          obj.push({
            kind: KINDS.LABEL_VALUE,
            label: 'Location',
            value: data.epCustomproperty10,
          });
        }

        if (data.endpointDescription) {
          obj.push({ kind: KINDS.TEXT, text: data.endpointDescription });
        }
      }
    } else if (data.type === ROLE) {
      buildRoleListRowDescription(obj, data, true);
    }

    return obj;
  };

  function getTitleObjectBasedOnType() {
    if (row.type === SESSION) {
      const assetName = row.roleKey
        ? row.roleDisplayName || row.roleName
        : row.endpointDisplayName || row.endpointName;

      return getObjectForTitle(assetName, row.accountName, row.pamAccountType);
    }

    if (row.type === ACCOUNT) {
      return [{ kind: KINDS.TEXT, text: row.accountName }];
    }

    if (row.type === ROLE) {
      return [{ kind: KINDS.TEXT, text: row.roleDisplayName || row.roleName }];
    }

    return [
      { kind: KINDS.TEXT, text: row.endpointDisplayName || row.endpointName },
    ];
  }

  const createPrimaryIconOnClick = () => {
    let primaryIconOnClick = () => {};

    if (row.type === ENDPOINT) {
      primaryIconOnClick = () => {
        history.push(`${REQUEST_BASE_URL}/check-out-credential`);
      };
    } else if (row.type === SESSION) {
      setIsModalOpen(!isModalOpen);
      setIconClickedForRow(row);
      setCheckInModalKind(
        IS_CREDENTIALLESS ? END_TYPE_END_SESSION : END_TYPE_CHECKIN
      );
    }

    return primaryIconOnClick();
  };

  const createSecondaryIconOnClick = () => {
    if (row.type === ENDPOINT) {
      // todo : go to connect-to-session page
    } else if (row.type === SESSION) {
      const sessionToBeLaunched = {
        userKey,
        userName,
        endDate: formatLocalizedDateTime(row.sessionEndDate),
        startDate: formatLocalizedDateTime(row.sessionStartDate),
        accountId: row.privilegeId,
        instanceId: row.instanceIdSession,
        status: row.sessionStatus,
        accessKey: row.requestAccessKey.toString(),
        hostIp: row.hostIp,
        osType: row.pamPlatformType,
        requestId: row.requestId,
        entitlementName: 'Instance',
        credentialtype: row.pamType,
        remoteApp: row.remoteApp,
        remoteAppMetadata: row.remoteAppMetadata,
      };

      if (isRdp(row.remoteAppMetadata)) {
        setIsSessionTargetModalVisible(true);
        setSessionDetailsForAd(sessionToBeLaunched);
      } else {
        onLaunchSession(sessionToBeLaunched);
      }
    }
  };

  function getPrimaryIconObject() {
    if (row.type === ENDPOINT || row.type === ROLE) {
      return createIcon(null, 'ArrowRight', 'navy-900', 'medium');
    }

    if (row.type === SESSION) {
      const icon = createIcon(null, 'SubtractCircle', 'critical-700', 'medium');

      let endSessionTooltip =
        msgs?.pamCheckInModal?.endButtonCredentialedTooltip;

      if (row.roleKey) {
        endSessionTooltip = msgs?.pamCheckInModal?.checkInRoleTooltip;
      }

      if (row.pamType === CREDENTIALLESS) {
        endSessionTooltip =
          msgs?.pamCheckInModal?.endButtonCredentiallessTooltip;
      }

      if (SESSION_STARTED) {
        return (
          <Tooltip
            text={localize(endSessionTooltip)}
            placement='left'
            trigger={createButtonIcon(
              createPrimaryIconOnClick,
              'outlinedCritical',
              icon,
              'large'
            )}
          />
        );
      }

      return createButtonIcon(
        createPrimaryIconOnClick,
        'bold',
        icon,
        'large',
        true
      );
    }

    return null;
  }

  function getSecondaryIconObject() {
    if (row.type === ENDPOINT) {
      return (
        <Tooltip
          text={localize(msgs?.pamCheckInModal?.connectButtonTooltip)}
          placement='left'
          trigger={createIcon(null, 'ConnectPlug', 'navy-700', 'medium')}
        />
      );
    }

    if (
      row.type === SESSION &&
      row?.pamType?.toUpperCase() === CREDENTIALLESS
    ) {
      const icon = createIcon(null, 'LaunchRocket', 'neutral-000', 'medium');

      if (SESSION_STARTED) {
        return (
          <Tooltip
            text={localize(msgs?.pamCheckInModal?.connectButtonTooltip)}
            placement='left'
            trigger={createButtonIcon(
              createSecondaryIconOnClick,
              'bold',
              icon,
              'large'
            )}
          />
        );
      }

      return createButtonIcon(
        createSecondaryIconOnClick,
        'bold',
        icon,
        'large',
        true
      );
    }

    return null;
  }

  function getStatusObjectBasedOnType() {
    if (row?.type?.toLowerCase() === SESSION) {
      if (SESSION_STARTED) {
        return (
          <Chip
            kind={KINDS.SUBTLE_FILLED}
            color='brandSecondary'
            label={
              <CountdownTimer
                targetDate={row?.sessionEndDate}
                leftText='left'
                onTimerExpired={() => {
                  // todo : add fade out transition
                  const updatedListRowData = { ...listRowData };

                  updatedListRowData.result.userAccesses =
                    updatedListRowData.result.userAccesses.filter(
                      (access) =>
                        access.requestAccessKey !== row.requestAccessKey
                    );
                  setListRowData(updatedListRowData);
                }}
              />
            }
            leftIcon={<Icon kind='Time' size='medium' />}
          />
        );
      }

      if (!row.sessionStatus) {
        return (
          <Chip
            kind={KINDS.SUBTLE_FILLED}
            color='info'
            label='Pending Approval'
          />
        );
      }

      if (row.sessionStatus === SESSION_STATUSES.SESSION_APPROVED) {
        return (
          <Chip
            kind={KINDS.SUBTLE_FILLED}
            color='brandSecondary'
            label={formatLocalizedDateTime(row?.sessionStartDate, false, false)}
            leftIcon={<Icon kind='Calendar' size='medium' />}
          />
        );
      }
    }

    return null;
  }

  const optionsForActiveSessions = [
    {
      label: localize(msgs?.pamCheckInModal?.extendLabel),
      value: EXTEND_SESSION,
    },
  ];

  const optionsForFutureApprovedSession = [
    {
      label: localize(msgs?.pamCheckInModal?.cancelSessionLabel),
      value: END_TYPE_CANCEL_SESSION,
    },
  ];

  const optionsForPendingSessions = [
    {
      label: localize(msgs?.pamCheckInModal?.cancelRequestLabel),
      value: END_TYPE_CANCEL_REQUEST,
    },
  ];

  let options = [];

  if (!row.sessionStatus) {
    options = optionsForPendingSessions;
  } else if (row.sessionStatus === 1) {
    options = optionsForFutureApprovedSession;
  } else if (row.sessionStatus === 2) {
    options = optionsForActiveSessions;
  }

  function getMoreOptions() {
    const handleChange = (selectedOption) => {
      if (selectedOption.value === EXTEND_SESSION) {
        setIsExtendModalOpen(true);
        setIconClickedForRow(row);
      } else {
        setIsModalOpen(!isModalOpen);
        setIconClickedForRow(row);
        setCheckInModalKind(selectedOption.value);
      }
    };

    const trigger = (
      <ButtonIcon
        size='medium'
        kind={KINDS.GHOST}
        icon={<Icon kind='KebabVertical' color='neutral-100' size='medium' />}
      />
    );

    return <Menu options={options} onChange={handleChange} trigger={trigger} />;
  }

  const createObjectForRow = () => {
    const rowObject = {
      category:
        row.type === ACCOUNT
          ? getObjectForCategory(row.accountName, row.platformType, row.type)
          : null,
      title: getTitleObjectBasedOnType(),
      description: getObjectForDescription(row),

      status: getStatusObjectBasedOnType(row),

      primaryIcon: getPrimaryIconObject(),
      secondaryIcon: getSecondaryIconObject(),
      moreOptionsIcon: row.type === SESSION ? getMoreOptions() : null,
      type: row.type,
    };

    return rowObject;
  };

  return createObjectForRow;
};

export default useCreateRowObject;
