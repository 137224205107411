import React from 'react';
import MenuList from '../MenuList/MenuList';
import findAssociatedMenuForNavigatedUrl from '../../utils/findAssociatedMenuForNavigatedUrl';
import SessionStorageService from 'ui-common/src/utils/SessionStorageService';

const ChildMenu = ({
  data,
  selected,
  handleBackClick,
  topLevelMenuOpen,
  expandSideMenu,
  collapseSideMenu,
  selectedMenuId,
  isSearchActive,
}) => {
  if (!topLevelMenuOpen) {
    return (
      <MenuList
        data={data}
        handleBackClick={handleBackClick}
        isParentOpen={topLevelMenuOpen}
        expandSideMenu={expandSideMenu}
        selected={selected}
        selectedMenuId={selectedMenuId}
        collapseSideMenu={collapseSideMenu}
        isSearchActive={isSearchActive}
      />
    );
  }
  let menu = findAssociatedMenuForNavigatedUrl(data, selected);
  if (!menu) {
    menu = JSON.parse(localStorage.getItem('currentMenu'));
    SessionStorageService.set('currentMenu', localStorage.getItem('currentMenu'));
  }

  let filteredData;
  const stillBrowsingThruMenu = () => selectedMenuId !== '';
  const filterForSelectedMenu = () => data.filter((f) => f.id === selectedMenuId);
  const isNavigatedUrlAssociatedWithMenu = () => menu !== 'null' && menu !== null;
  const filterForAllParentMenus = () => data.filter((f) => menu?.parentID?.includes(f.id));

  if (isSearchActive) {
    filteredData = data;
  } else if (stillBrowsingThruMenu()) {
    filteredData = filterForSelectedMenu();
  } else if (isNavigatedUrlAssociatedWithMenu()) {
    filteredData = filterForAllParentMenus();
  } else {
    handleBackClick(selectedMenuId);
    filteredData = data;
  }

  const MENU_TO_ALLOWED_IF_PAM_ENABLED = [
    'assets-sessions',
    'privileged-service-account-management',
  ];
  const MENU_TO_ALLOWED_IF_PAM_DISABLED = [
    'new-privileged-access',
    'privileged-sessions',
    'past-sessions',
    'privileged-service-account-management',
  ];
  const pamGen2Flag = window.localStorage.getItem('pamGen2');
  if (filteredData[0]?.id === 'home') {
    filteredData[0].subMenu.forEach((item) => {
      if (item.id === 'privileged-access') {
        let allowedIds = Boolean(parseInt(pamGen2Flag))
          ? MENU_TO_ALLOWED_IF_PAM_ENABLED
          : MENU_TO_ALLOWED_IF_PAM_DISABLED;
        item.subMenu = item.subMenu.filter((ele) => allowedIds.includes(ele.id));
      }
    });
  }

  return (
    <MenuList
      data={filteredData}
      selected={selected}
      handleBackClick={handleBackClick}
      isParentOpen={topLevelMenuOpen}
      expandSideMenu={expandSideMenu}
      collapseSideMenu={collapseSideMenu}
      selectedMenuId={selectedMenuId}
      isSearchActive={isSearchActive}
    />
  );
};

export default ChildMenu;
