import { type MenuId, type MenuIdsResponse } from '../models';

import { type MenuLink, type SideMenuNode } from './common';

type MenuLinkById = Map<string, MenuLink>;

interface Args {
  userKey: string;
  sideMenu: SideMenuNode[] | null;
  menuList: MenuLink[] | undefined;
  menuIds?: MenuIdsResponse;
}

export interface AccessibleMenuLink {
  id: MenuId;

  // May be undefined for "custom" menu items, e.g. opens a modal instead of navigating
  link?: string;
}

export default function findAccessibleMenuLinks({
  userKey,
  sideMenu,
  menuList,
  menuIds,
}: Args): AccessibleMenuLink[] | null {
  if (!sideMenu || !menuList || !menuIds || menuIds === 'error') {
    return null;
  }

  // Treat unauthorized as if no menuIds were selected.
  if (menuIds === 'unauthorized') {
    return [];
  }

  const permittedMenuLinks: MenuLinkById = new Map<MenuId, MenuLink>();

  sideMenu.reduce(reduceMenu, permittedMenuLinks);
  menuList.reduce(
    (acc, { id, ...curr }) => acc.set(id, { ...curr, id }),
    permittedMenuLinks
  );

  return menuIds
    .filter(
      (menuId) => permittedMenuLinks.has(menuId) || menuId.startsWith('custom:')
    )
    .map((menuId) => {
      const menuLink = permittedMenuLinks.get(menuId);

      return {
        id: menuId,
        link: menuLink?.link.replace('{userKey}', userKey),
      };
    });
}

function reduceMenu(
  acc: MenuLinkById,
  { id, ...curr }: SideMenuNode
): MenuLinkById {
  if ('subMenu' in curr) {
    curr.subMenu
      .reduce(reduceMenu, new Map<string, MenuLink>())
      .forEach((subMenu, subId) => acc.set(subId, subMenu));

    return acc;
  }

  return acc.set(id, { ...curr, id });
}
