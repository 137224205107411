/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable object-curly-newline */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  getCurrentDate,
  SECONDS_PER_MINUTE,
  useLocalize,
} from '@saviynt/common';
import {
  Box,
  Icon,
  InlineMessage,
  ToggleButton,
  ToggleButtonGroup,
} from '@saviynt/design-system';
import { add } from 'date-fns';
import PropTypes from 'prop-types';

import {
  CredentialRequestAccountSelection,
  Session,
} from '../../../../models/PamModels';
import {
  ACCOUNT_JIT_SELECTION,
  DEFAULT_USER_MAX_TIME,
  initialAccordionSnapshotState,
  initialCriticalState,
  initialIntervalOptions,
  INTERVALS,
  msgs,
} from '../../constants';
import ViewScheduleLink from '../../ModalIslands/ViewScheduleModalIsland/ViewScheduleLink/ViewScheduleLink';
import ByTypeDateAndTimePicker from '../helpers/ByTypeDateAndTimePicker';
import CustomDuration from '../helpers/CustomDuration';
import DurationChips from '../helpers/DurationChips';
import DurationHandlerButtons from '../helpers/DurationHandlerButtons';

import { SECONDS_IN_FIVE_MINUTES } from './constants';
import {
  formatCredentialWarningDate,
  handleAccountSelection,
  handleDateConflicts,
  handleDurationByInterval,
  handleDurationSnapShot,
  handleExcludedDateAndTime,
  handleNowTabEndDate,
} from './helpers';

import './MpaTimeAccessDuration.css';

function MpaTimeAccessDuration({
  userName,
  toggleChoice,
  setToggleChoice,
  setShouldDurationContinue,
  bootstrapConfigMaxTime,
  nowStartDateOfNextCredential,
  futureStartDateOfNextCredential,
  nowTabStartDate,
  nowTabEndDate,
  setNowTabStartDate,
  setNowTabEndDate,
  futureTabStartDate,
  setFutureTabStartDate,
  futureTabEndDate,
  setFutureTabEndDate,
  isTimeBlocked,
  setIsTimeBlocked,
  setIsScheduleViewOpen,
  accountSelection,
  setDurationAccordionValue,
  isDurationCustom,
  setIsDurationCustom,
  hasDurationBeenFilled,
  setHasDurationBeenFilled,
  selectedAccountSessions,
  isCOCorExclusive,
  isExpandedDuration,
  setIsExpandedDuration,
  isFormCritical,
}) {
  // Inline message warning
  const [isScheduleConflictWarning, setIsScheduleConflictWarning] =
    useState(false);
  const [isFutureTimeExpiredWarning, setIsFutureTimeExpiredWarning] =
    useState(false);
  // NOW tab related states
  const [customDurationInputValue, setCustomDurationInputValue] = useState('');
  const [intervalOptions, setIntervalOptions] = useState(
    initialIntervalOptions
  );
  const [formattedNowWarningDate, setFormattedNowWarningDate] = useState(null);
  const [isNowBlocked, setIsNowBlocked] = useState(false);
  // FUTURE tab realated states
  const [durationChipValue, setDurationChipValue] = useState(0);
  const [futureDatesToExclude, setFutureDatesToExclude] = useState([]);
  const [futureTimesToExclude, setFutureTimesToExclude] = useState([]);
  const [formattedFutureWarningDate, setFormattedFutureWarningDate] =
    useState(null);
  // Custom states
  const [checkedIntervalOption, setCheckedIntervalOption] = useState(
    INTERVALS.MINUTES
  );
  const [durationInSeconds, setDurationInSeconds] = useState(null);
  const [isCustomOutOfRange, setIsCustomOutOfRange] = useState(false);
  const [customInputValueAsNumber, setCustomInputValueAsNumber] = useState(
    parseFloat(customDurationInputValue)
  );
  // Editing
  const [accordionValueSnapshot, setAccordionValueSnapshot] = useState(
    initialAccordionSnapshotState
  );

  const isJITSelection = accountSelection === ACCOUNT_JIT_SELECTION;
  const accountSelectionMaxTimeAsNumber =
    accountSelection && accountSelection.maxtime !== ''
      ? parseInt(accountSelection.maxtime, 10)
      : DEFAULT_USER_MAX_TIME;

  const maxRequestTimeAsNumber = isJITSelection
    ? parseInt(bootstrapConfigMaxTime, 10)
    : accountSelectionMaxTimeAsNumber;

  const [intervalMaxTime, setIntervalMaxTime] = useState(
    maxRequestTimeAsNumber / SECONDS_PER_MINUTE
  );
  const roundedIntervalMaxTime = Math.floor(intervalMaxTime * 100) / 100;
  const rangeMaxDate = add(new Date(), { years: 1 });
  const isNowTab = toggleChoice === 1;
  const dispatch = useDispatch();
  const localize = useLocalize();

  const NOW_TAB_TEXT = localize(
    msgs.pam.modalPage.timeAccessDuration.toggleTabNow
  );
  const FUTURE_TAB_TEXT = localize(
    msgs.pam.modalPage.timeAccessDuration.toggleTabFuture
  );
  const EXPIRED_TIME_WARNING_TEXT = localize(
    msgs.pam.modalPage.critical.sessionExpiredWarning
  );

  const conflictWarningMessage = (date) =>
    localize(msgs.pam.modalPage.critical.sessionConflictWarning, {
      date,
    });

  // Local Effects
  useEffect(() => {
    const formattedNowDate = formatCredentialWarningDate(
      nowStartDateOfNextCredential
    );
    const formattedFutureDate = formatCredentialWarningDate(
      futureStartDateOfNextCredential
    );

    setFormattedNowWarningDate(formattedNowDate);
    setFormattedFutureWarningDate(formattedFutureDate);
  }, [nowStartDateOfNextCredential, futureStartDateOfNextCredential]);

  useEffect(() => {
    if (isNowBlocked) setToggleChoice(2);
  }, [isNowBlocked]);

  useEffect(() => {
    setCustomInputValueAsNumber(parseFloat(customDurationInputValue));
  }, [customDurationInputValue]);

  // Set the StartDate based on the context
  useEffect(() => {
    // NOW needs to start at the current user time in their timezone
    if (isNowTab) setNowTabStartDate(getCurrentDate());
    if (isNowTab && !isDurationCustom)
      setDurationChipValue(accordionValueSnapshot.durationChipValue);
  }, [isNowTab, durationChipValue]);

  useEffect(() => {
    const isCustomUnderFiveMin = durationInSeconds < SECONDS_IN_FIVE_MINUTES;
    const isValueOutOfRange = Boolean(
      customDurationInputValue &&
        (isCustomUnderFiveMin ||
          customInputValueAsNumber > roundedIntervalMaxTime)
    );

    setIsCustomOutOfRange(isValueOutOfRange);
  }, [
    accountSelection,
    customInputValueAsNumber,
    durationInSeconds,
    customDurationInputValue,
  ]);

  useEffect(() => {
    if (durationInSeconds <= maxRequestTimeAsNumber) {
      setDurationChipValue(durationInSeconds);
    } else {
      setDurationChipValue(maxRequestTimeAsNumber);
    }
  }, [durationInSeconds]);

  // Helper Effects
  useEffect(() => {
    handleDurationSnapShot(
      isNowBlocked,
      accordionValueSnapshot,
      isExpandedDuration,
      setToggleChoice,
      setIsDurationCustom,
      setFutureTabStartDate,
      setFutureTabEndDate,
      setIntervalOptions,
      setDurationChipValue,
      setCheckedIntervalOption,
      setCustomDurationInputValue
    );
  }, [isExpandedDuration]);

  useEffect(() => {
    handleExcludedDateAndTime(
      userName,
      isCOCorExclusive,
      selectedAccountSessions,
      setFutureDatesToExclude,
      setFutureTimesToExclude
    );
  }, [selectedAccountSessions]);

  useEffect(() => {
    handleAccountSelection(
      isJITSelection,
      setToggleChoice,
      accountSelection,
      bootstrapConfigMaxTime,
      setDurationAccordionValue,
      dispatch,
      setIsNowBlocked,
      setIntervalOptions
    );
  }, [accountSelection]);

  useEffect(() => {
    handleNowTabEndDate(
      isNowTab,
      durationChipValue,
      nowTabStartDate,
      setNowTabEndDate,
      isDurationCustom
    );
  }, [nowTabStartDate, durationChipValue]);

  useEffect(() => {
    handleDateConflicts(
      isNowTab,
      durationChipValue,
      durationInSeconds,
      accordionValueSnapshot,
      isDurationCustom,
      isFormCritical,
      nowTabStartDate,
      nowTabEndDate,
      futureTabStartDate,
      futureTabEndDate,
      hasDurationBeenFilled,
      nowStartDateOfNextCredential,
      futureStartDateOfNextCredential,
      setIsTimeBlocked,
      setIsNowBlocked,
      setIsScheduleConflictWarning,
      setIsFutureTimeExpiredWarning
    );
  }, [
    isNowTab,
    isDurationCustom,
    durationChipValue,
    nowTabStartDate,
    nowStartDateOfNextCredential,
    futureTabStartDate,
    futureTabEndDate,
    futureStartDateOfNextCredential,
  ]);

  useEffect(() => {
    handleDurationByInterval(
      checkedIntervalOption,
      maxRequestTimeAsNumber,
      customDurationInputValue,
      customInputValueAsNumber,
      isDurationCustom,
      setIntervalMaxTime,
      setDurationInSeconds
    );
  }, [
    customInputValueAsNumber,
    customDurationInputValue,
    checkedIntervalOption,
  ]);

  return (
    <Box tag='section' className='MpaTimeAccessDuration'>
      <InlineMessage
        kind='background'
        text={conflictWarningMessage(
          isNowTab ? formattedNowWarningDate : formattedFutureWarningDate
        )}
        colorTheme='warning'
        leftIcon={<Icon kind='Warning' />}
        isVisible={isScheduleConflictWarning || isTimeBlocked}
        isDismissible
        onDismiss={() => setIsScheduleConflictWarning(false)}
        linkComponent={
          <ViewScheduleLink
            setIsScheduleViewOpen={setIsScheduleViewOpen}
            kind='warning'
          />
        }
      />
      <InlineMessage
        kind='background'
        text={EXPIRED_TIME_WARNING_TEXT}
        colorTheme='warning'
        leftIcon={<Icon kind='Warning' />}
        isVisible={isFutureTimeExpiredWarning}
        isDismissible
        onDismiss={() => setIsScheduleConflictWarning(false)}
      />
      <ToggleButtonGroup
        selection={toggleChoice}
        setSelection={setToggleChoice}>
        <ToggleButton label={NOW_TAB_TEXT} isDisabled={isNowBlocked} />
        <ToggleButton label={FUTURE_TAB_TEXT} />
      </ToggleButtonGroup>
      <ByTypeDateAndTimePicker
        type='start'
        isNowTab={isNowTab}
        futureTabStartDate={futureTabStartDate}
        setFutureTabStartDate={setFutureTabStartDate}
        futureTabEndDate={futureTabEndDate}
        setFutureTabEndDate={setFutureTabEndDate}
        isDurationCustom={isDurationCustom}
        isExpandedDuration={isExpandedDuration}
        durationChipValue={durationChipValue}
        rangeMaxDate={rangeMaxDate}
        futureDatesToExclude={futureDatesToExclude}
        futureTimesToExclude={futureTimesToExclude}
        accordionValueSnapshot={accordionValueSnapshot}
      />
      <DurationChips
        isNowTab={isNowTab}
        maxRequestTimeAsNumber={maxRequestTimeAsNumber}
        accordionValueSnapshot={accordionValueSnapshot}
        nowTabStartDate={nowTabStartDate}
        setNowTabStartDate={setNowTabStartDate}
        setNowTabEndDate={setNowTabEndDate}
        futureTabStartDate={futureTabStartDate}
        setFutureTabEndDate={setFutureTabEndDate}
        isDurationCustom={isDurationCustom}
        setIsDurationCustom={setIsDurationCustom}
        durationChipValue={durationChipValue}
        setDurationChipValue={setDurationChipValue}
        setShouldDurationContinue={setShouldDurationContinue}
        nowStartDateOfNextCredential={nowStartDateOfNextCredential}
        futureStartDateOfNextCredential={futureStartDateOfNextCredential}
        setHasDurationBeenFilled={setHasDurationBeenFilled}
        accountSelection={accountSelection}
        setCustomDurationInputValue={setCustomDurationInputValue}
        setAccordionValueSnapshot={setAccordionValueSnapshot}
      />
      <CustomDuration
        isNowTab={isNowTab}
        isDurationCustom={isDurationCustom}
        customDurationInputValue={customDurationInputValue}
        setCustomDurationInputValue={setCustomDurationInputValue}
        durationChipValue={durationChipValue}
        checkedIntervalOption={checkedIntervalOption}
        setCheckedIntervalOption={setCheckedIntervalOption}
        intervalOptions={intervalOptions}
        setIntervalOptions={setIntervalOptions}
        maxRequestTimeAsNumber={maxRequestTimeAsNumber}
        rangeMaxDate={rangeMaxDate}
        futureTabStartDate={futureTabStartDate}
        setFutureTabStartDate={setFutureTabStartDate}
        futureTabEndDate={futureTabEndDate}
        setFutureTabEndDate={setFutureTabEndDate}
        isCustomOutOfRange={isCustomOutOfRange}
        isExpandedDuration={isExpandedDuration}
      />
      <DurationHandlerButtons
        isNowTab={isNowTab}
        isDurationCustom={isDurationCustom}
        isTimeBlocked={isTimeBlocked}
        isScheduleConflictWarning={isScheduleConflictWarning}
        isCustomOutOfRange={isCustomOutOfRange}
        customDurationInputValue={customDurationInputValue}
        durationChipValue={durationChipValue}
        hasDurationBeenFilled={hasDurationBeenFilled}
        futureTabStartDate={futureTabStartDate}
        futureTabEndDate={futureTabEndDate}
        maxRequestTimeAsNumber={maxRequestTimeAsNumber}
        checkedIntervalOption={checkedIntervalOption}
        setNowTabStartDate={setNowTabStartDate}
        setNowTabEndDate={setNowTabEndDate}
        setFutureTabEndDate={setFutureTabEndDate}
        setDurationChipValue={setDurationChipValue}
        setShouldDurationContinue={setShouldDurationContinue}
        setHasDurationBeenFilled={setHasDurationBeenFilled}
        isExpandedDuration={isExpandedDuration}
        setIsExpandedDuration={setIsExpandedDuration}
        isDurationBeingEditedCritical={isFormCritical.isDurationBeingEdited}
        accordionValueSnapshot={accordionValueSnapshot}
        setAccordionValueSnapshot={setAccordionValueSnapshot}
      />
    </Box>
  );
}

MpaTimeAccessDuration.propTypes = {
  userName: PropTypes.string.isRequired,
  toggleChoice: PropTypes.number.isRequired,
  setToggleChoice: PropTypes.func.isRequired,
  setShouldDurationContinue: PropTypes.func.isRequired,
  bootstrapConfigMaxTime: PropTypes.string.isRequired,
  nowStartDateOfNextCredential: PropTypes.string,
  futureStartDateOfNextCredential: PropTypes.string,
  nowTabStartDate: PropTypes.instanceOf(Date),
  nowTabEndDate: PropTypes.instanceOf(Date),
  setNowTabStartDate: PropTypes.func.isRequired,
  setNowTabEndDate: PropTypes.func.isRequired,
  futureTabStartDate: PropTypes.instanceOf(Date),
  futureTabEndDate: PropTypes.instanceOf(Date),
  setFutureTabStartDate: PropTypes.func.isRequired,
  setFutureTabEndDate: PropTypes.func.isRequired,
  isTimeBlocked: PropTypes.bool.isRequired,
  setIsTimeBlocked: PropTypes.func.isRequired,
  setIsScheduleViewOpen: PropTypes.func.isRequired,
  accountSelection: CredentialRequestAccountSelection,
  setDurationAccordionValue: PropTypes.func.isRequired,
  isDurationCustom: PropTypes.bool.isRequired,
  setIsDurationCustom: PropTypes.func.isRequired,
  hasDurationBeenFilled: PropTypes.bool.isRequired,
  setHasDurationBeenFilled: PropTypes.func.isRequired,
  selectedAccountSessions: PropTypes.arrayOf(Session),
  isCOCorExclusive: PropTypes.bool.isRequired,
  isExpandedDuration: PropTypes.bool.isRequired,
  setIsExpandedDuration: PropTypes.func.isRequired,
  isFormCritical: PropTypes.shape(initialCriticalState).isRequired,
};

MpaTimeAccessDuration.defaultProps = {
  nowStartDateOfNextCredential: null,
  futureStartDateOfNextCredential: null,
  nowTabStartDate: null,
  nowTabEndDate: null,
  futureTabStartDate: null,
  futureTabEndDate: null,
  accountSelection: null,
  selectedAccountSessions: [],
};

export default React.memo(MpaTimeAccessDuration);
