export const matchRouteToRegExArr = (options) => {
    const { url, featureName, allowedFeatures, blockedFeatures } = options;
    let { allowedUrls, blockedUrl } = options;
    allowedUrls = sanitizeUrlList(allowedUrls);
    blockedUrl = sanitizeUrlList(blockedUrl);
    if (url) {
        const sanitizedUrl = sanitize(url);
        if (blockedUrl && blockedUrl.length > 0) {
            if (hasExactMatch(sanitizedUrl, blockedUrl)) {
                return false;
            }
            if (hasExactMatch(sanitizedUrl, allowedUrls)) {
                return true;
            } 
            if (hasPatternMatch(sanitizedUrl, blockedUrl)) {
                return false;
            }
            return hasPatternMatch(sanitizedUrl, allowedUrls);
        } else {
            return hasExactMatch(sanitizedUrl, allowedUrls) || hasPatternMatch(sanitizedUrl, allowedUrls);
        }
    } else if (featureName) {
        if (hasExactMatch(featureName, blockedFeatures)) {
            return false;
        } 
        return hasExactMatch(featureName, allowedFeatures);
    }
    return false;
  };

  const trimEcm = url => url.replace(/\/ECMv6\/|\/ecmv6\/|\/ECM\/|\/ecm\//, '/');

  const sanitize = (url) => {
    url = url.includes('?') ? url.split('?')[0] : url; // not dealing with url params at all
    return trimEcm(url);
  }

  const sanitizeUrlList = (urlList) => {
    if (urlList && urlList.length > 0) {
        return urlList.map(url => trimEcm(url))
    }
    return null;
  }

  const hasExactMatch = (url, urlList) => {
      if (url && urlList) {
        return urlList.some(val => {
            if (!val.includes('**') && val.includes('*')) {
                const routePathParts = url.split('/');
                const regExStrPathParts = val.split('/');
                const starIndexes = [];
                regExStrPathParts.forEach((part, i) => {
                  if (part === '*') starIndexes.push(i);
                });
                starIndexes.forEach(indexOfStar => {
                    if (routePathParts[indexOfStar] && routePathParts[indexOfStar].startsWith('{')) {
                        delete routePathParts[indexOfStar];
                        delete regExStrPathParts[indexOfStar];
                    }
                });
                const newRoute = routePathParts.join('/');
                const newRegexStr = regExStrPathParts.join('/');
                return newRoute === newRegexStr;
            } else {
                return val.trim().toLowerCase() === url.trim().toLowerCase()
            }
        });
      }
  }

  const hasPatternMatch = (url, urlList) => {
    if (url && urlList) {
        return urlList.some(val => {
            if (val.includes('**')) {
                val = val.replace('**', '');
                return url.match(val) !== null;
            } else if (val.includes('*')) {
                const routePathParts = url.split('/');
                const regExStrPathParts = val.split('/');
                // get all indexes of star and delete them, same with route, so you get identical strings to match
                const starIndexes = [];
                regExStrPathParts.forEach((part, i) => {
                  if (part === '*') starIndexes.push(i);
                });
                starIndexes.forEach(indexOfStar => {
                  delete routePathParts[indexOfStar];
                  delete regExStrPathParts[indexOfStar];
                });
                const newRoute = routePathParts.join('/');
                const newRegexStr = regExStrPathParts.join('/');
                return newRoute === newRegexStr;
            }
        })
      }
      return false;
  }
