import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';

import styles from './FileUpload.module.scss';

const UploadView = (props) => {
  return (
    <div id="upload" className={styles.cscTitle}>
      {props.errorDescription && <div className={styles.error}> {props.errorDescription} </div>}

      <h4 className="text-center dropZoneTitle"> {props.uploadLabel} </h4>

      <Dropzone onDrop={props.uploadedFiles}>
        {({ getRootProps, getInputProps }) => (
          <section className={styles.dorpZoneArea}>
            <div {...getRootProps()}>
              <input {...getInputProps()} />

              <div className={styles.dropMessage}>
                <div className={styles.uploadIconBlock}>
                  <div className={styles.uploadIcon} />
                </div>
                <p> {props.dragDropLabel}</p>
              </div>

              <div className="btn btn-primary">{props.uploadSubmit}</div>
            </div>
          </section>
        )}
      </Dropzone>
      <p className={styles.noteInfo}> {props.noteDescription} </p>
    </div>
  );
};

UploadView.propTypes = {
  errorDescription: PropTypes.string.isRequired,
  noteDescription: PropTypes.string.isRequired,
  uploadSubmit: PropTypes.string.isRequired,
  dragDropLabel: PropTypes.string.isRequired,
  uploadedFiles: PropTypes.func.isRequired,
  uploadLabel: PropTypes.string.isRequired,
};
export default UploadView;
