import { localize } from '@saviynt/common';
import { Link } from '@saviynt/design-system';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';

import { msgs } from '../../../constants';

function ViewScheduleLink({ setIsScheduleViewOpen, kind }) {
  const intl = useIntl();

  const LINK_TEXT = localize(intl, msgs.pam.modalPage.page.viewScheduleLink);

  const handleScheduleModal = (event) => {
    event.preventDefault();

    setIsScheduleViewOpen(true);
  };

  const isKindAccordion = kind === 'accordion';

  return (
    <Link
      href='/'
      url='/'
      onClick={(e) => {
        handleScheduleModal(e);
      }}
      text={LINK_TEXT}
      prefixIconKind={isKindAccordion ? 'Calendar' : null}
      suffixIconKind={!isKindAccordion ? 'ChevronRight' : null}
      target='_self'
      shouldStopPropagation
    />
  );
}

ViewScheduleLink.propTypes = {
  setIsScheduleViewOpen: PropTypes.func.isRequired,
  kind: PropTypes.string,
};

ViewScheduleLink.defaultProps = { kind: 'accordion' };

export default ViewScheduleLink;
