import React, { useState } from 'react';
import { formatLocalizedDateTime, useLocalize } from '@saviynt/common';
import { Icon, TabContent, TabGroup, TabItem } from '@saviynt/design-system';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import {
  Entitlement,
  ModuleDetails,
  RequestDetails,
  RoleDetails,
  Session,
} from '../../../../../../models/PamModels';
import { msgs } from '../../../../constants';
import SpvEndpoint from '../../../Endpoints/SpvEndpoint/SpvEndpoint';
import SpvEntitlements from '../../../Roles/SpvEntitlements/SpvEntitlements';
import SpvRole from '../../../Roles/SpvRole/SpvRole';
import SpvSection from '../../../SpvSection/SpvSection';

import './SpvFutureApprovedSession.css';

function SpvFutureApprovedSession({
  userAccessData,
  accountDetails,
  endpointDetails,
  pendingSession,
  requestDetails,
  roleDetails,
  entitlements,
  dataTestId,
  className,
}) {
  const classes = classnames('SpvFutureApprovedSession', className);

  const [activeTab, setActiveTab] = useState('1');
  const localize = useLocalize();

  const handleChange = (value) => {
    setActiveTab(value);
  };

  const hasTimeFields =
    pendingSession?.sessionStartDate || pendingSession?.sessionEndDate;

  const hasTargetSystemFields =
    endpointDetails?.customproperty9 || endpointDetails?.customproperty35;

  const isCredentialless =
    pendingSession?.pamType?.toUpperCase() === 'CREDENTIALLESS';
  const remoteAppName = userAccessData?.remoteAppMetadata?.displayName;

  const showRemoteAppName =
    isCredentialless && remoteAppName && remoteAppName !== 'Terminal';

  const approvedBy =
    requestDetails?.endpoints?.length > 0 &&
    requestDetails?.endpoints?.[0].approvals?.[0].assignee?.[0]?.displayName;
  const approvalTime =
    requestDetails?.endpoints?.[0]?.approvals?.[0]?.approvalDate ||
    requestDetails?.roles?.[0]?.approvals?.[0]?.approvalDate;

  const isApproved = approvedBy && approvalTime;

  const hasRequestDetailsFields =
    pendingSession?.requestId ||
    (requestDetails?.endpoints?.length > 0 &&
      requestDetails?.endpoints?.[0]?.businessJustification) ||
    isApproved;

  const hasSessionAccountFields =
    pendingSession?.accountName ||
    accountDetails?.description ||
    userAccessData?.accountDescription ||
    accountDetails?.pamAccountType ||
    accountDetails?.accountConfig?.authenticationType;

  const hasRole = userAccessData?.roleKey && roleDetails;
  const hasEntitlements = userAccessData?.roleKey && entitlements?.length > 0;

  const remoteAppSectionHasDivider = hasTimeFields || hasTargetSystemFields;
  const requestDetailsSectionHasDivider =
    remoteAppSectionHasDivider || showRemoteAppName;
  const accountSectionHasDivider =
    requestDetailsSectionHasDivider || hasRequestDetailsFields;

  return (
    <section className={classes} data-testid={dataTestId}>
      <TabGroup
        className='SpvFutureApprovedSession-tabGroup'
        value={activeTab}
        onChange={handleChange}>
        <TabItem
          label={localize(msgs.pam.sidePanel.session)}
          value='1'
          icon={
            <Icon kind='PrivilegedSession' size='medium' color='neutral-1000' />
          }
        />
        {!userAccessData?.roleKey && (
          <TabItem
            label={localize(msgs.pam.sidePanel.asset)}
            value='2'
            icon={
              <Icon
                kind='AssetTargetSystem'
                size='medium'
                color='neutral-1000'
              />
            }
          />
        )}
        {hasRole && (
          <TabItem
            label={localize(msgs.pam.sidePanel.role)}
            value='3'
            icon={<Icon kind='Role' size='medium' color='neutral-1000' />}
          />
        )}
        {hasEntitlements && (
          <TabItem
            label={localize(msgs.pam.sidePanel.entitlements)}
            value='4'
            icon={
              <Icon kind='Entitlement' size='medium' color='neutral-1000' />
            }
          />
        )}
      </TabGroup>
      <TabContent
        className='SpvFutureApprovedSession-tabContent'
        value={activeTab}
        tabValue='1'>
        {hasTimeFields && (
          <SpvSection
            title={localize(msgs.pam.sidePanel.time)}
            fields={[
              {
                label: localize(msgs.pam.sidePanel.startTime),
                value: formatLocalizedDateTime(
                  pendingSession?.sessionStartDate
                ),
              },
              {
                label: localize(msgs.pam.sidePanel.endTime),
                value: formatLocalizedDateTime(pendingSession?.sessionEndDate),
              },
            ]}
          />
        )}
        {hasTargetSystemFields && (
          <SpvSection
            className={hasTimeFields && 'SpvFutureApprovedSession-tabSection'}
            title={localize(msgs.pam.sidePanel.targetSystem)}
            fields={[
              {
                label: localize(msgs.pam.sidePanel.targetServer),
                value: endpointDetails?.customproperty9,
              },
              {
                label: localize(msgs.pam.sidePanel.portNumber),
                value: endpointDetails?.customproperty35,
              },
            ]}
          />
        )}
        {showRemoteAppName && (
          <SpvSection
            className={
              remoteAppSectionHasDivider &&
              'SpvFutureApprovedSession-tabSection'
            }
            title={localize(msgs.pam.sidePanel.remoteApplication)}
            fields={[
              {
                label: localize(msgs.pam.sidePanel.name),
                value: remoteAppName,
              },
            ]}
          />
        )}
        {hasRequestDetailsFields && (
          <SpvSection
            className={
              requestDetailsSectionHasDivider &&
              'SpvFutureApprovedSession-tabSection'
            }
            title={localize(msgs.pam.sidePanel.requestDetails)}
            fields={[
              {
                label: localize(msgs.pam.sidePanel.requestId),
                value: pendingSession?.requestId,
              },
              {
                label: localize(msgs.pam.sidePanel.justification),
                value: requestDetails?.endpoints?.[0]?.businessJustification,
              },
              // TODO: update to not use mock tcodes when applicable
              {
                label: localize(msgs.pam.sidePanel.sapTransactionCodes),
                value:
                  requestDetails?.codes?.split(',').join(', ') ||
                  pendingSession?.codes?.split(',').join(', '),
              },
              {
                label: localize(msgs.pam.sidePanel.approvedBy),
                value: approvedBy,
              },
              {
                label: localize(msgs.pam.sidePanel.approvalTime),
                value: formatLocalizedDateTime(`${approvalTime}Z`),
              },
            ]}
          />
        )}
        {hasSessionAccountFields && !hasRole && (
          <SpvSection
            className={
              accountSectionHasDivider && 'SpvFutureApprovedSession-tabSection'
            }
            title={localize(msgs.pam.sidePanel.account)}
            fields={[
              {
                label: localize(msgs.pam.sidePanel.name),
                value: pendingSession?.accountName,
              },
              {
                label: localize(msgs.pam.sidePanel.platform),
                value: accountDetails?.platformType,
              },
              {
                label: localize(msgs.pam.sidePanel.description),
                value:
                  accountDetails?.description ||
                  userAccessData?.accountDescription,
              },
              {
                label: localize(msgs.pam.sidePanel.accountType),
                value: accountDetails?.pamAccountType,
              },
              {
                label: localize(msgs.pam.sidePanel.authenticationMechanism),
                value: accountDetails?.accountConfig?.authenticationType,
              },
            ]}
          />
        )}
      </TabContent>
      {!userAccessData?.roleKey && (
        <TabContent
          className='SpvFutureApprovedSession-assetTab'
          value={activeTab}
          tabValue='2'>
          <SpvEndpoint data={endpointDetails} />
        </TabContent>
      )}
      {hasRole && (
        <TabContent
          className='SpvFutureApprovedSession-tabContent'
          value={activeTab}
          tabValue='3'>
          <SpvRole data={userAccessData} roleDetails={roleDetails} />
        </TabContent>
      )}
      {hasEntitlements && (
        <TabContent
          className='SpvFutureApprovedSession-tabContent'
          value={activeTab}
          tabValue='4'>
          <SpvEntitlements
            data={userAccessData}
            initialEntitlements={entitlements}
          />
        </TabContent>
      )}
    </section>
  );
}

SpvFutureApprovedSession.propTypes = {
  userAccessData: ModuleDetails,
  accountDetails: ModuleDetails,
  endpointDetails: ModuleDetails,
  pendingSession: Session,
  requestDetails: RequestDetails,
  roleDetails: RoleDetails,
  entitlements: PropTypes.arrayOf(Entitlement),
  dataTestId: PropTypes.string,
  className: PropTypes.string,
};

SpvFutureApprovedSession.defaultProps = {
  userAccessData: null,
  accountDetails: null,
  endpointDetails: null,
  pendingSession: null,
  requestDetails: null,
  roleDetails: null,
  entitlements: null,
  dataTestId: null,
  className: null,
};

export default SpvFutureApprovedSession;
