import { Cookies } from 'react-cookie';

const cookies = new Cookies();

export const getDynamicUrl = (url: string) => {
    if (url) {
        const timestamp = new Date().getTime();
        return url.concat(`?t=${timestamp}`);
    }
    return url;
}

export const setSecureCookies = (key: string, val: string, options: { [key: string]: any }) => {
    if (key && val) {
      cookies.set(key, val, { path: '/', ...options, secure: true });
    }
  };