import moment from 'moment';
import { ShowMessageModal } from 'ui-common/src/utils/ResponseHandler-actions';
import { testConnection } from 'ui-common/src/lib/utils';
import { scrollTo } from 'ui-components/src';
import { messages } from 'ui-design/src/features/CatalogManagement/messages';

const handleFallbackMessage = (intl, messageId, fallbackMessage) =>
  intl.formatMessage({ id: messageId, defaultMessage: fallbackMessage }) || '';

const dispatchErrorMessage = message => {
  window.store.dispatch(
    ShowMessageModal({
      status: 'failure',
      message,
      encrypted: false,
    })
  );
};

const scrollToTop = (wrapperRef, context, callback = () => {}) => {
  if (!wrapperRef.current) return undefined;
  const newPosition = 0;
  return scrollTo({
    element: wrapperRef.current,
    to: newPosition,
    duration: 300,
    scrollDirection: 'scrollTop',
    callback,
    context,
  });
};

const sortOrder = (item1, item2) => {
  if (parseInt(item1.Order, 10) < parseInt(item2.Order, 10)) return -1;
  if (parseInt(item1.Order, 10) > parseInt(item2.Order, 10)) return 1;
  return 0;
};

const msg = (intl, id, defaultMessage) => {
  if (!intl || id === undefined || id === null || typeof id !== 'string' || id === '') return '';
  if (defaultMessage) return intl.formatMessage({ id, defaultMessage });
  if (messages[id]) return intl.formatMessage({ id, defaultMessage: messages[id] });
  return intl.formatMessage({ id, defaultMessage: id });
};

const convertDate = rawDate => {
  const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
  return moment(new Date(rawDate)).format(DATE_FORMAT);
};

const testAppConnection = payload => {
  const formattedPayload = Object.keys(payload || {}).reduce((acc, cur) => {
    const value = payload[cur];
    const arrayEl = val => (val.length === 0 ? '' : val[0]);
    return {
      ...acc,
      [cur]: Array.isArray(value) ? arrayEl(value) : value,
    };
  }, {});
  return testConnection(formattedPayload);
};
const convertBoolean = val => {
  if (typeof val === 'boolean') return val;
  if (!val) return null;
  if (val.toUpperCase() === 'TRUE') return true;
  if (val.toUpperCase() === 'FALSE') return false;
  if (val === 1 || val === '1') return true;
  return false;
};

export {
  handleFallbackMessage,
  dispatchErrorMessage,
  sortOrder,
  scrollToTop,
  msg,
  convertDate,
  testAppConnection,
  convertBoolean,
};
