import {
  getCurrentDate,
  localize,
  SECONDS_PER_365_DAYS,
  SECONDS_PER_DAY,
  SECONDS_PER_HOUR,
  SECONDS_PER_MINUTE,
} from '@saviynt/common';
import { Box, Button, Icon, InlineMessage } from '@saviynt/design-system';
import { addSeconds, isBefore, isEqual, startOfSecond } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { DurationAccordionSnapshot } from '../../../../models/PamModels';
import { handleIsDurationBeingEditedWithChanges } from '../../../../store/actions';
import { CHIP_OPTIONS, INTERVALS, msgs } from '../../constants';

const SECONDS_IN_FIVE_MINUTES = SECONDS_PER_MINUTE * 5;

function DurationHandlerButtons({
  isNowTab,
  isDurationCustom,
  isTimeBlocked,
  isScheduleConflictWarning,
  isCustomOutOfRange,
  customDurationInputValue,
  hasDurationBeenFilled,
  durationChipValue,
  futureTabStartDate,
  futureTabEndDate,
  maxRequestTimeAsNumber,
  checkedIntervalOption,
  setNowTabStartDate,
  setNowTabEndDate,
  setFutureTabEndDate,
  setDurationChipValue,
  setShouldDurationContinue,
  setHasDurationBeenFilled,
  isExpandedDuration,
  setIsExpandedDuration,
  isDurationBeingEditedCritical,
  accordionValueSnapshot,
  setAccordionValueSnapshot,
}) {
  const customInputValueAsNumber = parseFloat(customDurationInputValue);
  const [intervalDurationInSeconds, setIntervalDurationInSeconds] = useState(0);
  const [shouldRenderContinueButton, setShouldRenderContinueButton] =
    useState(false);
  const [shouldRenderEditButtons, setShouldRenderEditButtons] = useState(false);
  const intl = useIntl();
  const dispatch = useDispatch();

  // Start Check
  const startOfFutureDate = startOfSecond(new Date(futureTabStartDate));
  const startOfSavedDate = startOfSecond(
    new Date(accordionValueSnapshot?.futureTabStartDate)
  );

  const isFutureStartDateUnchanged = () => {
    if (!isNowTab) return isEqual(startOfSavedDate, startOfFutureDate);

    return true;
  };

  // Duration Check
  const isDurationUnchanged =
    accordionValueSnapshot.durationChipValue === durationChipValue;
  const isNowStartDateAndUnchanged =
    accordionValueSnapshot.isNowTab === isNowTab && isDurationUnchanged;

  const isNowCustomSameValue = Boolean(
    isNowTab && isDurationCustom && !isCustomOutOfRange && isDurationUnchanged
  );
  const isDurationBeingEdited =
    !isFutureStartDateUnchanged() || !isDurationUnchanged;

  const isDurationBeingEditedRedux = useSelector(
    (state) => state.credentialRequestForm.isDurationBeingEdited
  );

  // Localization
  const DURATION_CONTINUE_BTN_TEXT = localize(
    intl,
    msgs.pam.modalPage.durationContinueButton.buttonText
  );
  const DURATION_EDIT_CRITICAL_TEXT = localize(
    intl,
    msgs.pam.modalPage.critical.editButtonsMessage
  );

  useEffect(() => {
    // Checks
    const chipValues = CHIP_OPTIONS.map((option) => option.value);
    const isSavedStartDatePassedNow =
      !isNowTab && isBefore(startOfSavedDate, getCurrentDate());

    // Conditions
    const isOtherTabAndSameCustomInput = Boolean(
      isDurationCustom &&
        accordionValueSnapshot.isNowTab === !isNowTab &&
        accordionValueSnapshot.customDurationInputValue ===
          customDurationInputValue
    );
    const isUserBackToTheSnapshotValues = Boolean(
      accordionValueSnapshot.isNowTab === isNowTab &&
        accordionValueSnapshot.isDurationCustom === isDurationCustom &&
        accordionValueSnapshot.customDurationInputValue ===
          customDurationInputValue &&
        isFutureStartDateUnchanged() &&
        isDurationUnchanged
    );
    const isNowUsingChips = Boolean(
      isExpandedDuration &&
        accordionValueSnapshot.isNowTab === isNowTab &&
        (chipValues.includes(accordionValueSnapshot.durationChipValue) ||
          (isDurationCustom && isDurationUnchanged))
    );
    const isExpandedAndEditing = Boolean(
      isExpandedDuration &&
        !isNowCustomSameValue &&
        !isNowStartDateAndUnchanged &&
        isDurationBeingEdited &&
        !isDurationBeingEditedRedux
    );

    // Combined
    const isDateValidUsingNowChipsOrediting = Boolean(
      isSavedStartDatePassedNow ||
        !isNowUsingChips ||
        isExpandedAndEditing ||
        isOtherTabAndSameCustomInput
    );
    const isClosedOrFutureWithNoChanges = Boolean(
      !isExpandedDuration ||
        !isNowTab ||
        (!isNowTab && isUserBackToTheSnapshotValues)
    );

    let handleDurationChange = null;

    if (
      hasDurationBeenFilled &&
      isDateValidUsingNowChipsOrediting &&
      !isClosedOrFutureWithNoChanges
    ) {
      handleDurationChange = true;
    } else if (isClosedOrFutureWithNoChanges) {
      handleDurationChange = false;
    }

    if (typeof handleDurationChange === 'boolean') {
      dispatch(
        handleIsDurationBeingEditedWithChanges(handleDurationChange, 'success')
      );
    }
  }, [
    isNowTab,
    isDurationBeingEdited,
    hasDurationBeenFilled,
    isExpandedDuration,
    futureTabStartDate,
    futureTabEndDate,
    durationChipValue,
    accordionValueSnapshot,
    customDurationInputValue,
  ]);

  useEffect(() => {
    let durationInSeconds;

    switch (checkedIntervalOption) {
      case INTERVALS.MINUTES:
        durationInSeconds = customInputValueAsNumber * SECONDS_PER_MINUTE;
        break;
      case INTERVALS.HOURS:
        durationInSeconds = customInputValueAsNumber * SECONDS_PER_HOUR;
        break;
      case INTERVALS.DAYS:
        durationInSeconds = customInputValueAsNumber * SECONDS_PER_DAY;
        break;
      default:
        durationInSeconds = 0;
    }

    setIntervalDurationInSeconds(durationInSeconds);

    const isFutureStartDatePassedNow = isBefore(futureTabStartDate, new Date());

    const hasCustomValueNotInRange =
      customDurationInputValue &&
      (durationChipValue < SECONDS_IN_FIVE_MINUTES ||
        durationInSeconds > maxRequestTimeAsNumber);
    // NOW
    const isNowCustomWithValueNotBlockedAndInRange =
      isNowTab &&
      isDurationCustom &&
      customDurationInputValue &&
      !isTimeBlocked &&
      !isScheduleConflictWarning &&
      !isCustomOutOfRange;
    // FUTURE
    const isFutureCustomAndOutOfRangeNoChangesMade = Boolean(
      !isNowTab && isDurationCustom && isCustomOutOfRange
    );

    const isFutureWithDatesNotBlockedAndInRange =
      !isNowTab &&
      futureTabStartDate &&
      !isFutureStartDatePassedNow &&
      (customDurationInputValue?.length > 0 || durationChipValue) &&
      !isTimeBlocked &&
      !isScheduleConflictWarning;

    switch (true) {
      case isFutureCustomAndOutOfRangeNoChangesMade:
        setShouldRenderContinueButton(false);
        break;
      case isFutureWithDatesNotBlockedAndInRange:
        setShouldRenderContinueButton(true);
        break;
      case hasCustomValueNotInRange:
        setShouldRenderContinueButton(false);
        break;
      case isNowCustomWithValueNotBlockedAndInRange:
        setShouldRenderContinueButton(true);
        break;
      default:
        setShouldRenderContinueButton(false);
    }
  }, [
    isNowTab,
    isScheduleConflictWarning,
    isTimeBlocked,
    checkedIntervalOption,
    isCustomOutOfRange,
    durationChipValue,
    customDurationInputValue,
    futureTabStartDate,
  ]);

  useEffect(() => {
    const isEditingAndValidToContinue = Boolean(
      hasDurationBeenFilled &&
        shouldRenderContinueButton &&
        isDurationBeingEdited &&
        !isNowCustomSameValue
    );
    const isAnotherTabAndSameCustomInput = Boolean(
      hasDurationBeenFilled &&
        isDurationCustom &&
        accordionValueSnapshot.isNowTab !== isNowTab &&
        customDurationInputValue
    );

    if (isEditingAndValidToContinue || isAnotherTabAndSameCustomInput) {
      setShouldRenderEditButtons(true);
    } else {
      setShouldRenderEditButtons(false);
    }
  }, [
    isNowTab,
    isExpandedDuration,
    futureTabStartDate,
    durationChipValue,
    shouldRenderContinueButton,
    intervalDurationInSeconds,
  ]);

  const handleContinueButton = () => {
    const isFutureChipSelectedWithDates =
      !isNowTab && futureTabStartDate && futureTabEndDate;

    if (durationChipValue > SECONDS_PER_365_DAYS) {
      setDurationChipValue(SECONDS_PER_365_DAYS);
      setHasDurationBeenFilled(true);
      setAccordionValueSnapshot({
        isNowTab,
        isDurationCustom,
        customDurationInputValue,
        checkedIntervalOption,
        futureTabStartDate: null,
        futureTabEndDate: null,
        durationChipValue,
      });
    }

    if (isNowTab && isDurationCustom) {
      setNowTabStartDate(getCurrentDate());
      setNowTabEndDate(getCurrentDate(durationChipValue));
      setShouldDurationContinue(true);
      setHasDurationBeenFilled(true);
      setAccordionValueSnapshot({
        isNowTab,
        isDurationCustom,
        customDurationInputValue,
        checkedIntervalOption,
        futureTabStartDate: null,
        futureTabEndDate: null,
        durationChipValue,
      });
    }

    if ((!isNowTab && isDurationCustom) || isFutureChipSelectedWithDates) {
      setFutureTabEndDate(addSeconds(futureTabStartDate, durationChipValue));
      setShouldDurationContinue(true);
      setHasDurationBeenFilled(true);
      setAccordionValueSnapshot({
        isNowTab,
        isDurationCustom,
        customDurationInputValue,
        checkedIntervalOption,
        futureTabStartDate,
        futureTabEndDate,
        durationChipValue,
      });
    }
  };

  const handleDiscardChangesButton = () => {
    setIsExpandedDuration(false);
  };

  if (shouldRenderEditButtons) {
    return (
      <Box className='MpaTimeAccessDuration-inlineMessageContainer'>
        <Box className='MpaTimeAccessDuration-continueButtonGroup'>
          <Button
            type='button'
            kind='outlined'
            onClick={() => handleDiscardChangesButton()}>
            Discard Changes
          </Button>
          <Button
            type='button'
            kind='filled'
            onClick={() => handleContinueButton()}>
            Save
          </Button>
        </Box>
        {isDurationBeingEditedCritical && (
          <InlineMessage
            size='small'
            text={DURATION_EDIT_CRITICAL_TEXT}
            colorTheme='critical'
            leftIcon={<Icon kind='AlertCritical' />}
          />
        )}
      </Box>
    );
  }

  if (!hasDurationBeenFilled && shouldRenderContinueButton) {
    return (
      <Box className='MpaTimeAccessDuration-continueButton'>
        <Button
          type='button'
          kind='filled'
          onClick={() => handleContinueButton()}>
          {DURATION_CONTINUE_BTN_TEXT}
        </Button>
      </Box>
    );
  }

  return null;
}

DurationHandlerButtons.propTypes = {
  isNowTab: PropTypes.bool.isRequired,
  isDurationCustom: PropTypes.bool.isRequired,
  isTimeBlocked: PropTypes.bool.isRequired,
  isScheduleConflictWarning: PropTypes.bool.isRequired,
  isCustomOutOfRange: PropTypes.bool.isRequired,
  customDurationInputValue: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  hasDurationBeenFilled: PropTypes.bool,
  durationChipValue: PropTypes.number,
  futureTabStartDate: PropTypes.instanceOf(Date),
  futureTabEndDate: PropTypes.instanceOf(Date),
  maxRequestTimeAsNumber: PropTypes.number.isRequired,
  checkedIntervalOption: PropTypes.string.isRequired,
  setNowTabStartDate: PropTypes.func.isRequired,
  setNowTabEndDate: PropTypes.func.isRequired,
  setFutureTabEndDate: PropTypes.func.isRequired,
  setDurationChipValue: PropTypes.func.isRequired,
  setShouldDurationContinue: PropTypes.func.isRequired,
  setHasDurationBeenFilled: PropTypes.func.isRequired,
  isExpandedDuration: PropTypes.bool.isRequired,
  setIsExpandedDuration: PropTypes.func.isRequired,
  isDurationBeingEditedCritical: PropTypes.bool.isRequired,
  accordionValueSnapshot: PropTypes.shape(DurationAccordionSnapshot).isRequired,
  setAccordionValueSnapshot: PropTypes.func.isRequired,
};

DurationHandlerButtons.defaultProps = {
  futureTabStartDate: null,
  futureTabEndDate: null,
  customDurationInputValue: '',
  hasDurationBeenFilled: null,
  durationChipValue: null,
};

export default DurationHandlerButtons;
