import React, { useState, useEffect } from 'react';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { LiveEditor, LiveProvider, LivePreview } from 'react-live';
import { NumberTextField, MultiSelectDropDown, Icon } from 'ui-components/src';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Accordion from '@material-ui/core/Accordion';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { useConfirm } from 'material-ui-confirm';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import Popover from '@material-ui/core/Popover';
import { injectIntl } from 'react-intl';
import styles from './KpiSrcEdit.module.scss';
import {
  newKpiCode,
  updateKpiAttributes,
  newKpiAttributes,
  fetchFeatureNameList,
} from '../redux/kpiForm-actions';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  popoverContent: {
    top: '10px !important',
    left: '65vw !important',
    height: '252px',
    width: '402px',
  },
}));

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    height: 'auto',
  },
}))(MuiDialogContent);

const KPIDialog = isSafari
  ? withStyles(() => ({
    paperScrollPaper: {
      margin: 10,
      marginLeft: '-12vw !important',
    },
  }))(Dialog)
  : withStyles(() => ({
    root: {
      height: 'max-content',
    },
    paperScrollPaper: {
      margin: 10,
      marginLeft: '-12vw !important',
    },
  }))(Dialog);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const miniAppValues = [
  { ID: 'requestMiniApp' },
  { ID: 'reviewMiniApp' },
  { ID: 'adminMiniApp' },
  { ID: 'controlMiniApp' },
  { ID: 'designMiniApp' },
  { ID: 'attestationMiniApp' },
  { ID: 'intelligenceMiniApp' },
  { ID: 'sodMiniApp' },
  { ID: 'roleMiniApp' },
];
const KpiSrcEdit = ({ kpiInfo, setCodeStr, scopeObj, deleteKpi, isNew, intl }) => {
  const { featureNamesList } = useSelector(state => state.requestHome.initialLoad);

  const [addKpiOpen, setAddKpiOpen] = useState(false);
  // const [features, setFeatures] = useState(() => kpiInfo.features.map(f => ({ ID: f })));
  const [kpiName, setKpiName] = useState(kpiInfo.name);
  const [rank, setRank] = useState(kpiInfo.rank);
  const [miniApp, setMiniApp] = useState(() => kpiInfo.miniApp.map(f => ({ ID: f })));
  const [editKpiInfo, setEditKpiInfo] = useState(false);
  const dispatch = useDispatch();
  const [editorCode, setEditorCode] = useState(kpiInfo.source);
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [selectedFeature, setSelectedFeature] = useState(() => kpiInfo.features);
  const confirm = useConfirm();

  useEffect(() => {
    dispatch(fetchFeatureNameList());
  }, []);

  const saveCustomCodeOnServer = () => {
    const codeUpdateAction = newKpiCode(
      {
        fileName: isNew ? `${kpiName}.js` : kpiInfo.sourceLocation.split('/').reverse()[0],
        codeStr: editorCode,
      },
      !editKpiInfo && !isNew
    );
    dispatch(codeUpdateAction);
    if (!isNew) {
      const payload = {
        ...kpiInfo,
        // features: features.map(f => f.ID),
        features: selectedFeature,
        rank,
        name: kpiName,
        miniApp: miniApp.map(m => m.ID),
        source: undefined,
      };

      const attrUpdateAction = updateKpiAttributes(payload);
      dispatch(attrUpdateAction);
    }
    if (isNew) {
      const payload = {
        // features: features.map(f => f.ID),
        features: selectedFeature,
        rank,
        name: kpiName,
        miniApp: miniApp.map(m => m.ID),
        sourceLocation: `/res/file?path=/app/kpi/${kpiName}.js`,
      };
      dispatch(newKpiAttributes(payload));
    }
    setAddKpiOpen(false);
  };

  const handleChange = (event, val) => {
    setSelectedFeature([...val.map(v => v.featureName)]);
  };

  const fetchSelectedValues = () => {
    let filternames = [];
    filternames = featureNamesList.filter(person => selectedFeature.includes(person.featureName));
    return filternames;
  };

  const [previewPopup, setPreviewPopup] = React.useState(null);

  const previewOpen = event => setPreviewPopup(event.currentTarget);

  const previewClose = () => setPreviewPopup(null);

  const open = !!previewPopup;
  const id = open ? 'simple-popover' : undefined;

  const confirmAndDeleteKpi = () => {
    confirm({
      description: `${intl.formatMessage({ id: 'requestHome.kpi.confirmDeleteMsg' })}`,
    }).then(() => deleteKpi(kpiInfo.id)).catch(() => console.log('Deletion cancelled.'));
  };
  return (
    <div className={`${isNew ? styles.addIcon : `${styles.editIcons} editIconsdisplay`}`}>
      <div variant="contained" color="primary" className="editIconsDiv">
        {!isNew && (
          <>
            <Icon
              name="edit"
              onClick={() => setAddKpiOpen(true)}
              className={styles.editIcon}
              color
            />
            <Icon
              name="trashDel"
              onClick={confirmAndDeleteKpi}
              className={`${styles.editIcon} ${styles.removeIcon}`}
            />
          </>
        )}
      </div>
      <KPIDialog
        onClose={() => setAddKpiOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={addKpiOpen}
      >
        <DialogTitle id="customized-dialog-title">
          <div className="d-flex justify-between">
            <div>
              {!isNew && <span>{intl.formatMessage({ id: 'requestHome.kpi.EDitKpi' })}</span>}
              {isNew && <span>{intl.formatMessage({ id: 'requestHome.kpi.AddKpi' })}</span>}
            </div>
            <div>
              <CloseIcon onClick={() => setAddKpiOpen(false)} />
            </div>
          </div>
        </DialogTitle>
        <div className={isNew ? styles.newkpiText : styles.textarea}>
          <DialogContent dividers>
            <div className={styles.contentBody}>
              {isNew && (
                <>
                  <div className="d-flex flex-column">
                    <TextField
                      label={intl.formatMessage({ id: 'requestHome.kpi.Name' })}
                      defaultValue=""
                      id={kpiName}
                      value={kpiName}
                      onChange={e => setKpiName(e.target.value)}
                      disabled={!(isNew || editKpiInfo)}
                    />
                    <TextField
                      label={intl.formatMessage({ id: 'requestHome.kpi.Rank' })}
                      defaultValue=""
                      id={rank}
                      value={rank}
                      onChange={e => setRank(e.target.value)}
                      disabled={!(isNew || editKpiInfo)}
                    />

                    {/* <MultiSelectDropDown
                      options={featureValues}
                      placeholder="select feature name"
                      defaultValue={features}
                      _handleChange={(_, __, origVal) => setFeatures(origVal)}
                      readOnly={!(isNew || editKpiInfo)}
                    /> */}

                    <Autocomplete
                      multiple
                      id="tags-standard"
                      options={featureNamesList}
                      getOptionLabel={option => option.featureDisplayName}
                      filterSelectedOptions
                      onChange={handleChange}
                      value={fetchSelectedValues()}
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Select Feature Name"
                          placeholder="Select Feature Name"
                        />
                      )}
                    />
                    {/* <MultiSelectDropDown
                  options={miniAppValues}
                  placeholder={'select miniapp'}
                  defaultValue={miniApp}
                  _handleChange={(_, __, origVal) => setMiniApp(origVal)}
                  readOnly={!(isNew || editKpiInfo)}
                /> */}
                  </div>
                </>
              )}
              {!isNew && (
                <>
                  <Accordion
                    className={{ content: styles.summaryBottom }}
                    expanded={expanded}
                    onChange={(e, expanded) => {
                      setExpanded(expanded);
                    }}
                  >
                    <AccordionSummary
                      className={{ content: styles.summaryBottom }}
                      expanded={expanded}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading}>Other attributes</Typography>
                      {expanded && (
                        <>
                          {!isNew && (
                            <div
                              className="flex justify-content-between ml-auto"
                              onClick={e => e.stopPropagation()}
                            >
                              {/* <span className="h5">Widget -- {kpiInfo.sourceLocation.split('/').reverse()[0]}</span> */}
                              <FormControlLabel
                                control={(
                                  <Switch
                                    checked={editKpiInfo}
                                    onChange={e => {
                                      setEditKpiInfo(e.target.checked);
                                      e.stopPropagation();
                                    }}
                                    color="primary"
                                  />
                                )}
                                label="Edit"
                              />
                            </div>
                          )}
                        </>
                      )}
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="d-flex flex-column w-100">
                        {/* <div className='d-flex justify-around'>
                <span className="text-sm">KPI Name:</span>
                <input className={`${styles.inputName}  mr-8`} id={kpiName} value={kpiName} onChange={(e) => setKpiName(e.target.value)} disabled={!(isNew || editKpiInfo)}>
                </input>
                </div> */}
                        {/* <div className='d-flex justify-around'>
                <span className="text-sm">KPI Rank:</span>
                <input className={`${styles.inputName}  mt-2 mr-8`} id={rank} value={rank} onChange={(e) => setRank(e.target.value)} disabled={!(isNew || editKpiInfo)}>
                  </input>
                  </div> */}
                        <TextField
                          label={intl.formatMessage({ id: 'requestHome.kpi.Rank' })}
                          defaultValue="Rank"
                          id={rank}
                          value={rank}
                          onChange={e => setRank(e.target.value)}
                          disabled={!(isNew || editKpiInfo)}
                        />
                        {/* <MultiSelectDropDown
                          options={featureValues}
                          placeholder="select feature name"
                          defaultValue={features}
                          _handleChange={(_, __, origVal) => setFeatures(origVal)}
                          readOnly={!(isNew || editKpiInfo)}
                        /> */}
                        <Autocomplete
                          multiple
                          id="tags-standard"
                          options={featureNamesList}
                          getOptionLabel={option => option.featureDisplayName}
                          filterSelectedOptions
                          onChange={handleChange}
                          value={fetchSelectedValues()}
                          renderInput={params => (
                            <TextField
                              {...params}
                              variant="standard"
                              label="Select Feature Name"
                              placeholder="Select Feature Name"
                            />
                          )}
                          disabled={!(isNew || editKpiInfo)}
                        />
                        {/* <MultiSelectDropDown
                  options={miniAppValues}
                  placeholder={'select miniapp'}
                  defaultValue={miniApp}
                  _handleChange={(_, __, origVal) => setMiniApp(origVal)}
                  readOnly={!(isNew || editKpiInfo)}
                /> */}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                  <TextField
                    className="w-100 pl-3"
                    label={intl.formatMessage({ id: 'requestHome.kpi.Name' })}
                    defaultValue="Name"
                    id={kpiName}
                    value={kpiName}
                    onChange={e => setKpiName(e.target.value)}
                  />
                </>
              )}
            </div>
            <Popover
              id={id}
              open={open}
              anchorEl={previewPopup}
              onClose={previewClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              classes={{ paper: classes.popoverContent }}
            >
              <div>
                <div className={`${styles.previewContaner} d-flex justify-between`}>
                  <h6>KPI Sample Preview </h6>
                  <CloseIcon onClick={previewClose} />
                </div>
                <h6 className={styles.previewText}>Overview</h6>
                <div className="d-flex">
                  <div className={styles.requestActionTiles}>
                    <LiveProvider code={editorCode} scope={scopeObj}>
                      <LivePreview />
                    </LiveProvider>
                  </div>
                </div>
              </div>
            </Popover>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              // onClick={() => {
              //   setCodeStr(editorCode);
              //   setAddKpiOpen(false);
              // }}
              onClick={previewOpen}
              color="primary"
            >
              {intl.formatMessage({ id: 'requestHome.kpi.Preview' })}
            </Button>
            <Button onClick={saveCustomCodeOnServer}>
              {intl.formatMessage({ id: 'requestHome.kpi.SaveonServer' })}
            </Button>
          </DialogActions>
        </div>
      </KPIDialog>
    </div>
  );
};

KpiSrcEdit.propTypes = {
  kpiInfo: PropTypes.shape({
    features: PropTypes.arrayOf().isRequired,
    miniApp: PropTypes.arrayOf().isRequired,
    name: PropTypes.string.isRequired,
    rank: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
    sourceLocation: PropTypes.string.isRequired,
  }).isRequired,
  intl: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  isNew: PropTypes.bool.isRequired,
  scopeObj: PropTypes.shape({}).isRequired,
  deleteKpi: PropTypes.func.isRequired,
};

export default injectIntl(KpiSrcEdit);
