/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect, useState } from 'react';
import { useLocalize } from '@saviynt/common';
import {
  ApplicationIcon,
  Box,
  Button,
  FooterActionBar,
  Icon,
  InlineMessage,
  InputField,
  ModalIsland,
  Typography,
} from '@saviynt/design-system';
import PropTypes from 'prop-types';
import validator from 'validator';

import { SessionLauncherDetails } from '../../../models/PamModels';
import { msgs } from '../constants';

import './SessionTargetDetailsModalIsland.css';

const DEFAULT_PORT_NUMBER = '3389';
const CRITICAL_INLINE_MESSAGE_KINDS = {
  IP: 'ip',
  PORT: 'port',
};

function SessionTargetDetailsModalIsland({
  setSessionToLaunch,
  sessionDetailsForAd,
  isSessionTargetModalVisible,
  setIsSessionTargetModalVisible,
  sessionTargetDetailsModalContentRef,
  sectionRefIsScrolled,
}) {
  // TODO: Set to number before sending to sessionToLaunch???? Set the sessionToLaunch sort of like this => sessionToLaunch: {...state, ipValue, portValue }
  // Input Values
  const [ipValue, setIpValue] = useState('');
  const [portValue, setPortValue] = useState(DEFAULT_PORT_NUMBER);
  const [isSubmitReady, setIsSubmitReady] = useState(false);
  //   Critical
  const [isIPCritical, setIsIPCritical] = useState(false);
  const [isPortCritical, setIsPortCritical] = useState(false);
  const localize = useLocalize();

  // IP Validation
  const isIPv4 = validator.isIP(ipValue, 4);
  const isIPv6 = validator.isIP(ipValue, 6);

  // DNS Validation
  // PAM-13470 (customer bug) examples: VT00CLM01, VT00CLM01.test.jse.co.za
  // generic examples: example.com, subdomain.example.com, my-site.co.uk, example.travel, xn--example-dk9c.com

  const validDNS =
    /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]).)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])$/;

  const isValidDNS = validDNS.test(ipValue);

  const isIpValid = isIPv6 || isIPv4 || isValidDNS;
  // Port Validation
  const isPortValid = validator.isInt(portValue, { min: 0, max: 65353 });

  useEffect(() => {
    setIsSubmitReady(isIpValid && isPortValid);
  }, [ipValue, portValue]);

  // Localization
  const WINDOWS_SERVER_TEXT = localize(msgs.pam.sessionLauncher.label.ipInput);
  const PORT_NUMBER_TEXT = localize(msgs.pam.sessionLauncher.label.portInput);
  const IP_PLACEHOLDER_TEXT = localize(
    msgs.pam.sessionLauncher.placeholder.ipInput
  );
  const PORT_INPUT_PLACEHOLDER_TEXT = localize(
    msgs.pam.sessionLauncher.placeholder.portInput
  );
  const CANCEL_TEXT = localize(msgs.pam.sessionLauncher.buttons.cancel);
  const LAUNCH_TEXT = localize(msgs.pam.sessionLauncher.buttons.launch);
  const LAUNCH_SESSION_TEXT = localize(
    msgs.pam.sessionLauncher.heading.detailsHeading
  );
  const MODAL_ISLAND_SUBHEADING_TEXT = localize(
    msgs.pam.sessionLauncher.heading.detailsSubheading
  );
  const SECONDARY_IP_CRIT_TEXT = localize(
    msgs.pam.sessionLauncher.critical.ipHelperText
  );
  const SECONDARY_PORT_CRIT_TEXT = localize(
    msgs.pam.sessionLauncher.critical.portHelperText
  );

  // Form handler functions
  const handleModalClose = () => {
    setIpValue('');
    setPortValue(DEFAULT_PORT_NUMBER);
    setIsSessionTargetModalVisible(false);
  };

  const handleLaunchClick = () => {
    if (isSubmitReady) {
      setSessionToLaunch({ ...sessionDetailsForAd, ipValue, portValue });
      handleModalClose();
    } else {
      if (!isIpValid) setIsIPCritical(true);
      if (!isPortValid) setIsPortCritical(true);
    }
  };

  // Inline content is Based on input kind and state
  const inputCriticalHelperText = (kind) => {
    if (kind === CRITICAL_INLINE_MESSAGE_KINDS.IP && ipValue?.length !== 0) {
      return (
        <InlineMessage
          text={SECONDARY_IP_CRIT_TEXT}
          colorTheme='critical'
          size='small'
          leftIcon={<Icon kind='AlertCritical' />}
        />
      );
    }

    if (
      kind === CRITICAL_INLINE_MESSAGE_KINDS.PORT &&
      portValue?.length !== 0
    ) {
      return (
        <InlineMessage
          text={SECONDARY_PORT_CRIT_TEXT}
          colorTheme='critical'
          size='small'
          leftIcon={<Icon kind='AlertCritical' />}
        />
      );
    }

    return <InlineMessage kind='missingRequiredField' size='small' />;
  };

  // Content nodes
  const getModalIslandContent = () => (
    <Box className='SessionTargetDetailsModalIsland-ModalIslandContent'>
      <InputField
        name='SessionTargetDetailsModalIsland-ipInput'
        placeholder={IP_PLACEHOLDER_TEXT}
        value={ipValue}
        setValue={setIpValue}
        label={
          <Typography
            kind='label'
            htmlFor='SessionTargetDetailsModalIsland-ipInput'>
            {WINDOWS_SERVER_TEXT}
          </Typography>
        }
        CriticalHelperText={inputCriticalHelperText(
          CRITICAL_INLINE_MESSAGE_KINDS.IP
        )}
        isRequired
        isCritical={isIPCritical}
        setIsCritical={setIsIPCritical}
      />
      <InputField
        name='SessionTargetDetailsModalIsland-portInput'
        placeholder={PORT_INPUT_PLACEHOLDER_TEXT}
        value={portValue}
        setValue={setPortValue}
        label={
          <Typography
            kind='label'
            htmlFor='SessionTargetDetailsModalIsland-portInput'>
            {PORT_NUMBER_TEXT}
          </Typography>
        }
        CriticalHelperText={inputCriticalHelperText(
          CRITICAL_INLINE_MESSAGE_KINDS.PORT
        )}
        isRequired
        isCritical={isPortCritical}
        setIsCritical={setIsPortCritical}
        regexPattern='[0-9]*'
        inputmode='numeric'
      />
    </Box>
  );

  const modalIslandFooterActionBar = () => (
    <Box className='SessionTargetDetailsModalIsland-FooterActionBar'>
      <Button kind='ghost' size='medium' onClick={handleModalClose}>
        {CANCEL_TEXT}
      </Button>
      <Button
        kind={isSubmitReady ? 'filled' : 'outlined'}
        size='medium'
        onClick={handleLaunchClick}>
        {LAUNCH_TEXT}
      </Button>
    </Box>
  );

  return (
    <ModalIsland
      className='SessionTargetDetailsModalIsland'
      onClose={handleModalClose}
      isOpen={isSessionTargetModalVisible}
      title={LAUNCH_SESSION_TEXT}
      subtitle={MODAL_ISLAND_SUBHEADING_TEXT}
      headerIcon={<ApplicationIcon size='large' kind='logo' logo='Windows' />}
      contentBody={getModalIslandContent()}
      sectionRef={sessionTargetDetailsModalContentRef}
      FooterActionBarComp={
        <FooterActionBar size='large' isScrolled={sectionRefIsScrolled}>
          {modalIslandFooterActionBar()}
        </FooterActionBar>
      }
    />
  );
}

SessionTargetDetailsModalIsland.propTypes = {
  sessionDetailsForAd: PropTypes.shape(SessionLauncherDetails).isRequired,
  isSessionTargetModalVisible: PropTypes.bool.isRequired,
  setIsSessionTargetModalVisible: PropTypes.func.isRequired,
  sessionTargetDetailsModalContentRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  sectionRefIsScrolled: PropTypes.bool.isRequired,
  setSessionToLaunch: PropTypes.func.isRequired,
};

export default SessionTargetDetailsModalIsland;
