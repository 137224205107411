import { createAction } from 'typesafe-actions';

import { type MenuId } from '../../../../models';

export const shortcutsRequested = createAction(
  'shortcuts/shortcutsRequested'
)<void>();

export const shortcutsReceived = createAction('shortcuts/shortcutsReceived')<
  MenuId[]
>();

export const shortcutsUnauthorized = createAction(
  'shortcuts/shortcutsUnauthorized'
)<void>();

export const shortcutsNotReceived = createAction(
  'shortcuts/shortcutsNotReceived'
)<void>();
