import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

export const EndpointIcon = () => {
  return (
    <svg
      width="20.416"
      height="20"
      viewBox="0 0 20.416 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        x="0.306641"
        width="48"
        height="48"
        rx="24"
        fill="#EBEDFF"
        d="M10.127 0a10 10 0 0 1 10 10 10 10 0 0 1 -10 10A10 10 0 0 1 0.128 10 10 10 0 0 1 10.127 0z"
      />
      <path
        d="m5.596 10.312 -0.312 -3.593 4.063 -1.25 0.312 0.312V9.218l-0.312 0.469 -3.437 1.093 -0.312 -0.469Z"
        fill="white"
      />
      <path
        d="M5.44 15.937v-3.749l4.063 -1.249 0.312 0.312v3.437l-0.312 0.469 -3.593 1.093 -0.469 -0.312Z"
        fill="white"
      />
      <path
        d="m10.909 9.063 -0.312 -3.907 4.063 -1.249 0.312 0.312V7.656l-0.312 0.625 -3.437 0.937 -0.312 -0.156Z"
        fill="white"
      />
      <path
        d="m10.752 14.219 -0.156 -3.749 4.063 -1.093 0.312 0.156v3.593l-0.312 0.625 -3.593 1.093 -0.312 -0.625Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.364 7.234c0 -0.451 0.351 -0.921 0.783 -1.049l2.955 -0.876c0.433 -0.129 0.784 0.134 0.784 0.585v3.083c0 0.451 -0.351 0.921 -0.783 1.049l-2.956 0.875c-0.433 0.129 -0.783 -0.134 -0.783 -0.585V7.234Zm0.783 -0.723a0.694 0.694 0 0 0 -0.47 0.629v3.083c0 0.271 0.21 0.428 0.47 0.351l2.955 -0.876a0.697 0.697 0 0 0 0.471 -0.63V5.987c0 -0.271 -0.21 -0.428 -0.471 -0.351l-2.955 0.875Z"
        fill="#033176"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.815 6.96c0 -0.451 0.351 -0.921 0.783 -1.049l3.085 -0.914c0.361 -0.107 0.654 0.111 0.654 0.489l-0.313 0.093c0 -0.197 -0.153 -0.056 -0.342 0l-2.73 0.844c-0.26 0.077 -0.49 0.383 -0.49 0.653l0.058 2.942c0 0.226 -0.216 0.369 0 0.304v0.328c-0.39 0.115 -0.705 -0.12 -0.705 -0.527V6.96Z"
        fill="#033176"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.364 12.721c0 -0.451 0.351 -0.921 0.783 -1.049l2.955 -0.876c0.433 -0.129 0.784 0.134 0.784 0.585v3.083c0 0.451 -0.351 0.921 -0.783 1.049l-2.956 0.876c-0.433 0.129 -0.783 -0.134 -0.783 -0.585v-3.083Zm0.783 -0.723a0.697 0.697 0 0 0 -0.47 0.63v3.082c0 0.271 0.21 0.428 0.47 0.351l2.955 -0.876a0.697 0.697 0 0 0 0.471 -0.63v-3.083c0 -0.271 -0.21 -0.428 -0.471 -0.351l-2.955 0.876Z"
        fill="#033176"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.815 12.447c0 -0.451 0.351 -0.921 0.783 -1.049l3.077 -0.912c0.365 -0.108 0.662 0.113 0.662 0.495l-0.313 0.093c0 -0.201 -0.156 -0.15 -0.349 -0.093l-2.722 0.836c-0.26 0.077 -0.432 0.359 -0.432 0.63v2.966c0 0.226 -0.216 0.461 0 0.397v0.327c-0.39 0.115 -0.705 -0.12 -0.705 -0.527v-3.163Z"
        fill="#033176"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.644 11.21c0 -0.432 0.336 -0.881 0.749 -1.003l3.01 -0.891c0.413 -0.122 0.749 0.128 0.749 0.559v3.14c0 0.432 -0.336 0.881 -0.749 1.003l-3.01 0.891c-0.413 0.122 -0.749 -0.128 -0.749 -0.559v-3.14Zm0.749 -0.691a0.666 0.666 0 0 0 -0.449 0.602v3.14c0 0.258 0.201 0.409 0.449 0.336l3.01 -0.891a0.666 0.666 0 0 0 0.449 -0.602V9.964c0 -0.258 -0.201 -0.409 -0.449 -0.336l-3.01 0.891Z"
        fill="#033176"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.088 10.966c0 -0.451 0.351 -0.921 0.784 -1.049l3.085 -0.914c0.361 -0.107 0.654 0.111 0.654 0.488l-0.313 0.093c0 -0.196 -0.152 -0.056 -0.34 0l-2.774 0.76c-0.259 0.078 -0.327 0.384 -0.327 0.654l-0.053 2.942c0 0.232 -0.223 0.451 0 0.386v0.327c-0.395 0.116 -0.716 -0.122 -0.716 -0.535v-3.153Z"
        fill="#033176"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.644 5.723c0 -0.432 0.336 -0.881 0.749 -1.003l3.01 -0.891c0.413 -0.122 0.749 0.128 0.749 0.559v3.14c0 0.432 -0.336 0.881 -0.749 1.003l-3.01 0.891c-0.413 0.122 -0.749 -0.128 -0.749 -0.559V5.723Zm0.749 -0.691a0.666 0.666 0 0 0 -0.449 0.602v3.14c0 0.258 0.201 0.409 0.449 0.336l3.01 -0.891a0.666 0.666 0 0 0 0.449 -0.602V4.475c0 -0.258 -0.201 -0.409 -0.449 -0.336l-3.01 0.891Z"
        fill="#033176"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.088 5.479c0 -0.451 0.351 -0.921 0.784 -1.049l3.072 -0.91c0.369 -0.109 0.668 0.114 0.668 0.498l-0.313 0.093c0 -0.204 -0.467 -0.035 -0.661 0.023l-2.289 0.818c-0.259 0.077 -0.543 0.384 -0.543 0.654v2.833c0 0.232 -0.223 0.468 0 0.401v0.327c-0.395 0.116 -0.716 -0.122 -0.716 -0.535V5.48Z"
        fill="#033176"
      />
    </svg>
  );
};
const useStyles = makeStyles({
  toolTip: {
    fontSize: '14px',
    lineHeight: '21px',
    padding: '8px 12px',
    backgroundColor: 'var(--black)',
  },
  arrow: {
    '&::before': {
      backgroundColor: 'var(--black)',
    },
  },
});

const EndpointItems = ({ endpoint }) => {
  return (
    <>
      <div className="endpoint d-flex">
        <EndpointIcon />
        <div className="endpoint-name">{endpoint}</div>
      </div>
    </>
  );
};

const EndpointsList = ({ intl, apps }) => {
  const classes = useStyles();

  const endpoint =
    apps && apps.slice(0, 2).map((endpointname) => <EndpointItems endpoint={endpointname} />);

  const getMoreEndpintsName =
    apps &&
    apps.slice(2).map((endpointname, index) => {
      return (index ? ', ' : '') + endpointname;
    });

  return (
    <div className="endpoints-list">
      {endpoint}

      {apps.length > 2 && (
        <Tooltip
          placement="top"
          title={getMoreEndpintsName}
          aria-label="more-items"
          arrow
          classes={{
            tooltip: classes.toolTip,
            arrow: classes.arrow,
          }}
        >
          <span className="more-option">
            +{apps.length - 2}{' '}
            {intl.formatMessage({
              id: 'NewRequestHome.RecentRequests.More.Label',
            })}
          </span>
        </Tooltip>
      )}
    </div>
  );
};

EndpointsList.propTypes = {
  intl: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};

export default injectIntl(EndpointsList);
