import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Loader } from 'ui-components/src';

const Loading = props => {
  if (props.isLoading) {
    return (
      <Loader />
    );
  }

  return <div />;
};

const mapStateToProps = state => ({
  isLoading: state.loader.isLoading,
});

Loading.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

const ConnectedLoadingHandling = connect(mapStateToProps)(Loading);

export default ConnectedLoadingHandling;
