import React, { useEffect, useState } from 'react';
import { Cookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import { Logger } from '@saviynt/common';
import { Box, Loader } from '@saviynt/design-system';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { ModuleDetails } from '../../../models/PamModels';
import {
  selectedListAccountRemoteMetadatas,
  selectedListIsExclusiveAccess,
  selectedListMaxCredlessSessionRequestTime,
} from '../../../store/actions';
import {
  getAccountDetailsApi,
  getEndpointDetailsApi,
  getRequestDetailsApi,
  getRolesDetailsApi,
  getRolesEntitlementsApi,
  getSessionDetailsApi,
} from '../../../utilities/api/getDataFromApi';
import {
  ASSET_TYPES,
  PAM_PLATFORM_BY_CONSOLE,
  PAM_PLATFORM_TYPES,
  TYPES,
} from '../constants';

import SpvAccounts from './Accounts/SpvAccounts/SpvAccounts';
import SpvEndpoint from './Endpoints/SpvEndpoint/SpvEndpoint';
import SpvRoles from './Roles/SpvRoles/SpvRoles';
import SpvSession from './Sessions/SpvSession/SpvSession';

import './SidePanelView.css';

function SidePanelView({ data, dataTestId, className }) {
  const classes = classnames('SidePanelView', className);

  const [platformDetails, setPlatformDetails] = useState(data);

  // Sessions
  const [session, setSession] = useState(data);
  const [sessionDetails, setSessionDetails] = useState(null);
  const [sessionAccountDetails, setSessionAccountDetails] = useState(data);
  const [sessionEndpointDetails, setSessionEndpointDetails] = useState(data);
  const [requestDetails, setRequestDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const cookies = new Cookies();
  const dispatch = useDispatch();

  const isPendingSession = !data?.sessionStatus;
  const isFutureApprovedSession = data?.sessionStatus === 1;
  const isActiveSession = data?.sessionStatus === 2;

  const isCredentialed = data?.pamType?.toUpperCase() === 'CREDENTIAL';
  const isCredentialless =
    data?.accountConfig?.['Saviynt-Status']?.pamType?.toUpperCase() ===
    'CREDENTIALLESS';
  const isPasswordEnabledCredentialless =
    data?.accountConfig?.['Saviynt-Status']?.showPassword === 'ENABLED';

  const isCredentiallessWithPasswordEnabled =
    isCredentialless && isPasswordEnabledCredentialless;

  // Roles
  const [entitlements, setEntitlements] = useState([]);
  const [roleDetails, setRoleDetails] = useState(data);

  const getRolesAndEntitlements = () => {
    if (data?.roleKey) {
      setIsLoading(true);
      getRolesEntitlementsApi(data?.roleKey, '', {}, 0)
        .then((response) => {
          setEntitlements(response?.result?.roleEntitlements);
        })
        .catch((e) => {
          Logger.error(e);
        })
        .finally(() => {
          setIsLoading(false);
        });

      setIsLoading(true);
      getRolesDetailsApi(data?.roleKey)
        .then((response) => {
          setPlatformDetails(data);
          setRoleDetails(response);
        })
        .catch((e) => {
          Logger.error(e);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const callApiByType = () => {
    switch (data?.type) {
      case TYPES.ACCOUNT:
        setIsLoading(true);

        if (data?.accountKey) {
          getAccountDetailsApi(data?.accountKey)
            .then((response) => {
              setPlatformDetails(response);
            })
            .finally(() => {
              setIsLoading(false);
            });
        }

        break;
      case TYPES.SESSION:
        setIsLoading(true);

        if (data?.accountKey) {
          getAccountDetailsApi(data?.accountKey).then((response) => {
            const updatedResponse = data?.federatedAccount
              ? { ...response, platformType: data?.platformType }
              : response;

            setSessionAccountDetails(updatedResponse);
          });
        }

        if (data?.endpointKey) {
          getEndpointDetailsApi(data?.endpointKey).then((response) => {
            setSessionEndpointDetails(response);
          });
        }

        // set role details and entitlements if sessions have roles
        getRolesAndEntitlements();

        // Payload is Pending Session or Future Approved Session
        if (isPendingSession || isFutureApprovedSession) {
          setSession(data);

          if (data?.requestId) {
            getRequestDetailsApi(cookies.get('user_name'), data?.requestId)
              .then((response) => {
                setRequestDetails(response);
              })
              .finally(() => {
                setIsLoading(false);
              });
          }
        }

        // Payload is Active Session
        else if (isActiveSession) {
          setSession(data);

          if (isCredentialed || isCredentiallessWithPasswordEnabled) {
            if (data?.requestAccessKey) {
              getSessionDetailsApi(
                data?.requestAccessKey,
                cookies.get('userKey')
              )
                .then((response) => {
                  if (response.error) {
                    Logger.error(response.error);
                    setSessionDetails(null);

                    return;
                  }

                  setSessionDetails(response);
                })
                .finally(() => {
                  setIsLoading(false);
                });
            }
          } else {
            setIsLoading(false);
          }
        }

        // All other session types
        else {
          setSession(data);
          setSessionDetails(null);
          setRequestDetails(null);
          setIsLoading(false);
        }

        break;
      case TYPES.ENDPOINT:
        setIsLoading(true);

        if (data?.endpointKey) {
          getEndpointDetailsApi(data?.endpointKey).then((response) => {
            setPlatformDetails(response);
            setIsLoading(false);
            // Remote App and JIT max time setters for Credential Request and Extend session
            // Get the maxCredlessSessionRequestTime based on pamPlatformType
            const pamPlatformType = response.pamPlatformType?.toLowerCase();
            const isReferablePlatformType =
              Object.values(PAM_PLATFORM_TYPES).includes(pamPlatformType);
            const isConsoleVariant = Boolean(
              pamPlatformType === PAM_PLATFORM_BY_CONSOLE.AD_CONSOLE ||
                pamPlatformType === PAM_PLATFORM_BY_CONSOLE.GCP_CONSOLE
            );

            const getConfigByString = () => {
              // If pamPlatformType is one of the console variants, return ASSET_TYPES.CONSOLE
              if (isConsoleVariant) {
                return ASSET_TYPES.CONSOLE.toLowerCase();
              }

              return pamPlatformType;
            };

            if (isReferablePlatformType || isConsoleVariant) {
              dispatch(
                selectedListMaxCredlessSessionRequestTime(
                  response.bootstrapConfigModel[getConfigByString()]
                    .maxCredlessSessionRequestTime,
                  'success'
                )
              );
            }

            // Is exclusive access
            dispatch(
              selectedListIsExclusiveAccess(response.exclusiveAccess, 'success')
            );

            // Is remote app enabled
            if (response.remoteAppMetadatas?.length > 0) {
              dispatch(
                selectedListAccountRemoteMetadatas(
                  response.remoteAppMetadatas,
                  'success'
                )
              );
            } else {
              dispatch(selectedListAccountRemoteMetadatas(null, 'success'));
            }
          });
        }

        break;
      case TYPES.ROLE:
        getRolesAndEntitlements();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    // only make 1 API call at a time
    callApiByType();
  }, [data, session]);

  const renderSidePanelView = () => {
    switch (data?.type) {
      case TYPES.ACCOUNT:
        return <SpvAccounts data={platformDetails} />;
      case TYPES.SESSION:
        return (
          <SpvSession
            data={data}
            accountDetails={sessionAccountDetails}
            endpointDetails={sessionEndpointDetails}
            session={session}
            sessionDetails={sessionDetails}
            requestDetails={requestDetails}
            roleDetails={roleDetails}
            entitlements={entitlements}
          />
        );
      case TYPES.ENDPOINT:
        return <SpvEndpoint data={platformDetails} />;
      case TYPES.ROLE:
        return (
          <SpvRoles
            data={platformDetails}
            roleDetails={roleDetails}
            entitlements={entitlements}
          />
        );
      default:
        return null;
    }
  };

  return (
    <aside className={classes} data-testid={dataTestId}>
      {isLoading ? (
        <Box className='SidePanelView-loader'>
          <Loader kind='dots' format='inline' color='info' size='medium' />
        </Box>
      ) : (
        renderSidePanelView()
      )}
    </aside>
  );
}

SidePanelView.propTypes = {
  data: ModuleDetails,
  dataTestId: PropTypes.string,
  className: PropTypes.string,
};

SidePanelView.defaultProps = {
  data: null,
  dataTestId: null,
  className: null,
};

export default SidePanelView;
