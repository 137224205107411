/**
 * Calculates the time difference between a target date and the current date.
 * @param { number } countDown - Time difference between the target date and the current date in milliseconds.
 * @param {boolean} [isVisibilityManaged=true] - flag to pause the interval when the tab/window is not in view.
 * @returns { number[] } An array containing the time left in days, hours, minutes, and seconds.
 */
import { useEffect, useRef, useState } from 'react';
import { MS_PER_MINUTE, MS_PER_SECOND } from '@saviynt/common';

const MILLISECONDS_PER_HOUR = MS_PER_MINUTE * 60;
const MILLISECONDS_PER_DAY = MILLISECONDS_PER_HOUR * 24;

const getReturnValues = (countDown) => {
  // calculate time left
  const days = Math.floor(countDown / MILLISECONDS_PER_DAY);
  const hours = Math.floor(
    (countDown % MILLISECONDS_PER_DAY) / MILLISECONDS_PER_HOUR
  );
  const minutes = Math.floor(
    (countDown % MILLISECONDS_PER_HOUR) / MS_PER_MINUTE
  );
  const seconds = Math.floor((countDown % MS_PER_MINUTE) / MS_PER_SECOND);

  return [+days, +hours, +minutes, +seconds];
};

const useCountdown = (targetDate, isVisibilityManaged = true) => {
  const countDownDate = new Date(targetDate).getTime();

  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime()
  );
  const intervalRef = useRef(null); // PAM-12527 updated to use a ref

  useEffect(() => {
    const updateCountDown = () => {
      setCountDown(countDownDate - new Date().getTime());
    };

    intervalRef.current = setInterval(updateCountDown, MS_PER_SECOND);

    if (isVisibilityManaged) {
      const handleVisibilityChange = () => {
        if (document.hidden) {
          clearInterval(intervalRef.current);
        } else {
          intervalRef.current = setInterval(updateCountDown, MS_PER_SECOND);
        }
      };

      document.addEventListener('visibilitychange', handleVisibilityChange);

      return () => {
        clearInterval(intervalRef.current);
        document.removeEventListener(
          'visibilitychange',
          handleVisibilityChange
        );
      };
    }

    return () => clearInterval(intervalRef.current);
  }, [countDownDate, isVisibilityManaged]);

  return getReturnValues(countDown);
};

// eslint-disable-next-line import/prefer-default-export
export { useCountdown };
