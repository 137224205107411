/* eslint-disable object-curly-newline */
import { getRequestDetailsApi } from './api/getDataFromApi';

const STATUS_PENDING = 1;
const STATUS_APPROVED = 3;

const showFailedUploadAlertBanner = (
  response,
  isLastRequestFileUploadFailed,
  setAlertBannerData,
  msgs,
  localize,
  isRoleRequest
) => {
  if (isLastRequestFileUploadFailed) {
    setAlertBannerData((prevState) => [
      ...prevState,
      {
        colorTheme: 'Warning',
        title: localize(msgs.pam.upload.uploadFailed),
        description: localize(msgs.pam.upload.uploadFailedDesc, {
          accountName: isRoleRequest
            ? response.roles[0].displayname
            : response.endpoints[0].accountName,
        }),
      },
    ]);
  }
};

function displayAlertBannersForRequestSubmit(
  requestId,
  userName,
  isLastRequestFileUploadFailed,
  setAlertBannerData,
  msgs,
  localize,
  isRoleRequest
) {
  getRequestDetailsApi(userName, requestId).then((response) => {
    if (isRoleRequest) {
      setAlertBannerData((prevState) => [
        ...prevState,
        {
          colorTheme: 'Success',
          title: localize(msgs.pam.common.successExclamation),
          description: localize(
            msgs.pam.requestRoleSubmitAlertBanner.submitted,
            {
              roleName:
                response?.roles?.[0]?.displayname ||
                response?.roles?.[0]?.roleName,
            }
          ),
        },
      ]);
      // related to endpoints below (not roles)
    } else if (response?.status === STATUS_PENDING) {
      setAlertBannerData((prevState) => [
        ...prevState,
        {
          colorTheme: 'Success',
          title: localize(msgs.pam.common.successExclamation),
          description: localize(msgs.pam.requestSubmitAlertBanner.pending, {
            accountName: response?.endpoints?.[0]?.accountName,
          }),
        },
      ]);
    } else if (response?.status === STATUS_APPROVED) {
      setAlertBannerData((prevState) => [
        ...prevState,
        {
          colorTheme: 'Success',
          title: localize(msgs.pam.common.successExclamation),
          description: localize(msgs.pam.requestSubmitAlertBanner.approved, {
            accountName: response?.endpoints?.[0]?.accountName,
          }),
        },
      ]);
    } // end else-ifs for endpoints

    showFailedUploadAlertBanner(
      response,
      isLastRequestFileUploadFailed,
      setAlertBannerData,
      msgs,
      localize,
      isRoleRequest
    );
  });
}

export default displayAlertBannersForRequestSubmit;
