import React, { type ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface Props {
  href: string;
  children: ReactNode;
  className?: string;
  title: string;
}

Link.defaultProps = {
  className: undefined,
};

export default function Link({
  href,
  children,
  className,
  title,
}: Props): JSX.Element {
  if (['/ECM/', '/admin/'].some((str) => href.startsWith(str))) {
    return (
      <a href={href} className={className} title={title}>
        {children}
      </a>
    );
  }

  return (
    <RouterLink to={href} className={className} title={title}>
      {children}
    </RouterLink>
  );
}
