import type { PendingCertification, PendingRequest } from '../models';

// Use CRUD naming to align with RESTful API (as opposed to GraphQL or RPC)
const baseUrl = process.env.REACT_APP_NAV_API ?? '';

interface ReadCertificationsResponse {
  totalCount: number;
  errorCode: string;
  certifications: {
    id: number;
    status: string;
    certificationName: string;
    campaignKey: number;
    campaignName: string;
    certifier: string;
    type: string;
    startdate: string;
    enddate: string;
    dueIn: string;
    progress: string;
    comments: string;
    audit_trail: string;
    action: string;
  }[];
}

const DISPLAY_COUNT = 2;

export async function readCertifications(
  username: string
): Promise<PendingCertification[]> {
  // First call just to get count
  const response1 = await fetchCertificationList(username, 1, 0);

  const { totalCount } = (await response1.json()) as ReadCertificationsResponse;

  if (totalCount === 0) {
    return [];
  }

  const offset = Math.max(0, totalCount - DISPLAY_COUNT);

  // Second call to get only 2 most recent certifications
  const response2 = await fetchCertificationList(
    username,
    DISPLAY_COUNT,
    offset
  );
  const { certifications } =
    (await response2.json()) as ReadCertificationsResponse;

  return certifications.map((item) => ({
    ...item,
    startdate: new Date(item.startdate),
    enddate: new Date(item.enddate),
  }));
}

async function fetchCertificationList(
  username: string,
  max: number,
  offset: number
): Promise<Response> {
  return fetch(`${baseUrl}/ECM/cert/fetchCertificationList`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      certifierUsername: username,
      max: max.toString(),
      offset: offset.toString(),
      status: 'new,readytosubmit,inprogress',
    }),
  });
}

interface ReadRequestsResponse {
  totalcount: number;
  errorCode: string;
  requests: {
    requestid: string;
    reqkey: string;
    requesttype: string;
    requestor: string;
    requestedfor: string;
    requestsubmittedon: string;
    duedate: string;
    status: string;
    assignee: string;
    endpoints: string;
    endpointname: string;
    requestandtaskstatus: string;
  }[];
}

export async function readRequests(
  username: string
): Promise<PendingRequest[]> {
  const response = await fetch(`${baseUrl}/ECM/api/v5/fetchRequestHistory`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      max: 2,
      username,
      status: ['OPEN', 'DRAFT', 'INPROCESS'],
      sort: 'requestid',
      order: 'desc',
    }),
  });

  const data = (await response.json()) as ReadRequestsResponse;

  return data.requests.map((item) => ({
    ...item,
    requestsubmittedon: new Date(item.requestsubmittedon),
    duedate: new Date(item.duedate),
  }));
}
