export const ShowLoaderType = 'showloadertype';
export const HideLoaderType = 'hideloadertype';

export const SHOW_NOTIFICATION_MODAL = 'SHOW_NOTIFICATION_MODAL';
export const HIDE_NOTIFICATION_MODAL = 'HIDE_NOTIFICATION_MODAL';

export const ShowLoader = () => ({
  type: ShowLoaderType,
});

export const HideLoader = () => ({
  type: HideLoaderType,
});

export const showNotificationModal = ({ type, title, details }) => ({
  type: SHOW_NOTIFICATION_MODAL,
  notificationType: type,
  title,
  details,
});

export const hideNotificationModal = () => ({
  type: HIDE_NOTIFICATION_MODAL,
});
