import PropTypes from 'prop-types';

const DisplayLabel = ({
  flag, label, value, durationLabel, intl,
}) => (flag ? label : `${intl.formatMessage({ id: 'Admin.Log.Viewer.last' })} ${value} ${durationLabel}`);

DisplayLabel.propType = {
  value: PropTypes.number.isRequired,
  duration: PropTypes.string.isRequired,
  flag: PropTypes.bool,
  label: PropTypes.string,
};

DisplayLabel.defaultProps = {
  flag: false,
};

export default DisplayLabel;
