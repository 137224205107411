import React, { type JSX, useEffect } from 'react';
import { Icon, Loader, Typography } from '@saviynt/design-system';

import { RequestStatus } from '../../../models';
import { useCookies, useInvertedLocalize } from '../../../utilities';
import { type PendingCertification, type PendingRequest } from '../models';
import {
  type RecentAssignmentsState,
  thunks,
  useDispatch,
  useSelector,
} from '../store';

import CertificationRow from './CertificationRow';
import { invertedMessages } from './messages';
import RequestRow from './RequestRow';
import {
  type Assignment,
  type ConvertToUtc,
  type Keyed,
  type RecentAssignmentsInvertedLocalize,
} from './types';

import './RecentAssignmentsWidget.css';

type Row<T extends Assignment> = Keyed<T> | 'error' | 'loading';

type DerivedState =
  | {
      certificationRows: Row<PendingCertification>[];
      requestRows: Row<PendingRequest>[];
    }
  | 'error';

export interface RecentAssignmentsWidgetProps {
  className?: string;
  convertToUtc: ConvertToUtc;
}

RecentAssignmentsWidget.defaultProps = {
  className: '',
};

export default function RecentAssignmentsWidget({
  className = '',
  convertToUtc,
}: RecentAssignmentsWidgetProps): JSX.Element {
  const l = useInvertedLocalize(invertedMessages);
  const [{ user_name: username }] = useCookies('user_name');
  const dispatch = useDispatch();
  const selected = useSelector((state) => state.recentAssignments);
  const { certifications: storeCertifications, requests: storeRequests } =
    selected;
  const state = deriveRows(selected);
  const renderRow = renderRowScope(l);

  useEffect(() => {
    if (storeCertifications === undefined) {
      dispatch(thunks.requestCertifications(username));
    }

    if (storeRequests === undefined) {
      dispatch(thunks.requestRequests(username));
    }
  }, [dispatch, username, storeCertifications, storeRequests]);

  if (state === 'error') {
    return renderError(className, l);
  }

  const { certificationRows, requestRows } = state;
  const hasRows = certificationRows.length > 0 || requestRows.length > 0;

  return (
    <div className={`Nav_RecentAssignmentsWidget ${className}`}>
      <Typography kind='h2' className='Nav_RecentAssignmentsWidget-heading'>
        {l('My Recent Assignments')}
      </Typography>

      <div className='Nav_RecentAssignmentsWidget-rows'>
        {hasRows ? (
          requestRows
            .map(
              renderRow((req) => (
                <RequestRow
                  key={req.key}
                  req={req}
                  convertToUtc={convertToUtc}
                />
              ))
            )
            .concat(
              certificationRows.map(
                renderRow((cert) => (
                  <CertificationRow
                    key={cert.key}
                    cert={cert}
                    convertToUtc={convertToUtc}
                  />
                ))
              )
            )
        ) : (
          <div className='Nav_RecentAssignmentsWidget-empty'>
            <Icon kind='Party' size='large' color='gray-1000' />
            <Typography
              kind='body1'
              className='Nav_RecentAssignmentsWidget-emptyMessage'>
              {l('Nothing to see here, you’re all caught up for now!')}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
}

function deriveRows({
  certifications,
  certificationsStatus,
  requests,
  requestsStatus,
}: RecentAssignmentsState): DerivedState {
  if (certifications === 'error' && requests === 'error') {
    return 'error';
  }

  const certificationRows = getRows(
    certifications,
    certificationsStatus,
    (item) => `c:${item.id}`
  );
  const requestRows = getRows(
    requests,
    requestsStatus,
    (item) => `r:${item.requestid}`
  );

  return { certificationRows, requestRows };
}

/** Translates request status and data into rows for display */
function getRows<T extends Assignment>(
  assignments: T[] | 'error' | undefined,
  status: RequestStatus,
  extractKey: (item: T) => string
): Row<Keyed<T>>[] {
  // When the data is not ready, we render two rows reflecting status

  if (status === RequestStatus.Pending || !assignments) {
    return ['loading', 'loading'];
  }

  if (assignments === 'error') {
    return ['error', 'error'];
  }

  return assignments.map((item) => ({
    ...item,
    key: extractKey(item),
  }));
}

/** Factory to track index across all invocations of `renderRow`
 * @returns Function to handle rendering a non-ready row, and uses passed render callback to handle ready data
 */
function renderRowScope(
  l: RecentAssignmentsInvertedLocalize
): <T extends Assignment>(
  renderAssignment: (assignment: Keyed<T>) => JSX.Element
) => (assignmentRow: Row<T>) => JSX.Element | null {
  let i = 0;

  return function renderRow<S extends Assignment>(
    renderAssignment: (assignment: Keyed<S>) => JSX.Element
  ) {
    return function AssignmentRow(assignmentRow: Row<S>): JSX.Element | null {
      i += 1;

      switch (assignmentRow) {
        case 'error': {
          return (
            <div
              className='Nav_RecentAssignmentsWidget-rows-row Nav_RecentAssignmentsWidget-rows-row--error'
              key={i}>
              <Icon
                kind='AlertCriticalOutline'
                color='critical-700'
                size='large'
                className='Nav_RecentAssignmentsWidget-rows-row-errorIcon'
              />
              <span className='Nav_RecentAssignmentsWidget-rows-row-errorMessage'>
                {l('There was a problem loading this item.')}
              </span>
            </div>
          );
        }

        case 'loading': {
          return (
            <div
              className='Nav_RecentAssignmentsWidget-rows-row Nav_RecentAssignmentsWidget-rows-row--loading'
              key={i}>
              <Loader kind='dots' format='inline' color='brand' size='small' />
            </div>
          );
        }

        default:
          return renderAssignment(assignmentRow);
      }
    };
  };
}

function renderError(className: string, l: RecentAssignmentsInvertedLocalize) {
  return (
    <div
      className={`Nav_RecentAssignmentsWidget Nav_RecentAssignmentsWidget--error ${className}`}>
      <div className='Nav_RecentAssignmentsWidget-errorIconContainer'>
        <Icon
          kind='AlertCriticalOutline'
          className='Nav_RecentAssignmentsWidget-errorIconContainer-errorIcon'
          color='critical-700'
          size='large'
        />
      </div>
      <Typography
        kind='body1'
        className='Nav_RecentAssignmentsWidget-errorMessage'>
        {l(
          "We're having trouble loading your Recent Assignments at this time."
        )}
      </Typography>
    </div>
  );
}
