import React from 'react';
import { Box, Icon, Typography } from '@saviynt/design-system';

import './MoreRecordsMessage.css';

const msgs = {
  moreRecordsMessageTitle: {
    id: 'pam.landingPage.moreRecords.title',
    defaultMessage: 'Too many assets to show.',
  },
  moreRecordsMessageDescription: {
    id: 'pam.landingPage.moreRecords.description',
    defaultMessage:
      'To find the available assets you need, type in the search bar and use filters.',
  },
};

// todo: Update the typography with Inlinemessage comopnent.
function MoreRecordsMessage({ localize }) {
  // localize param is instance of the useLocalize hook.
  return (
    <Box className='MoreRecordsMessage'>
      <Icon kind='alertCriticalOutline' />

      <Typography kind='h4'>
        {localize(msgs.moreRecordsMessageTitle)}
      </Typography>
      <Typography kind='body2'>
        {localize(msgs.moreRecordsMessageDescription)}
      </Typography>
    </Box>
  );
}

export default MoreRecordsMessage;
