import React from 'react';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import styles from './Activity.module.scss';

const ActivityDetails = ({
  intl, onClose,
}) => {
  const { lastLoginTime, lastLoginIP } = useSelector((state) => state.profile);

  return (
    <div className={styles.container}>
      <div className={styles.headerSection}>
        <h2>
          {intl.formatMessage({ id: 'RequestHome.RecentSecurity.Activity' })}
        </h2>
        <CloseIcon onClick={onClose} />
      </div>

      <div className={styles.content}>
        <h6 className={styles.lastLoginTimeTitle}>
          {intl.formatMessage({
            id: 'RequestHome.Dialog.RecentSecurityActivity.LastSuccessLogin',
          })}
        </h6>
        <div>
          <h6 className={styles.lastLoginTime}>
            {lastLoginTime}
          </h6>
          <h6 className={styles.lastLoginIP}>
            ip:
            {' '}
            {lastLoginIP}
          </h6>
        </div>
      </div>
    </div>
  );
};

ActivityDetails.propTypes = {
  onClose: PropTypes.func.isRequired,
  intl: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string.isRequired,
      formatMessage: PropTypes.func.isRequired,
    }),
  ).isRequired,
};

export default injectIntl(ActivityDetails);
