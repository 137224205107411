import { useEffect, useRef } from 'react';
import { debounce } from '@saviynt/common';
import { useQuery } from '@tanstack/react-query';

import { getRolesEntitlementsApi } from '../utilities/api/getDataFromApi';
import { INPUT_DEBOUNCE_DELAY } from '../utilities/debounce';

const getRolesEntitlementsQueryConfigs = (
  roleKey,
  keyWord,
  filterBy,
  offset
) => ({
  queryKey: [roleKey, keyWord, filterBy, offset],
  queryFn: () =>
    getRolesEntitlementsApi(roleKey, keyWord, filterBy, offset).then(
      (response) => {
        if (response.ok !== undefined && response.ok === false) {
          throw new Error('getRolesEntitlementsApi API call failed.');
        }

        return response?.result?.roleEntitlements;
      }
    ),
  refetchOnWindowFocus: true,
  enabled: false,
  retry: 1, // for failed requests
});

/**
 * A custom hook for fetching against the Roles Entitlements API.
 * It sets the entitlements return values upon finding a match against the inputted keyword.
 *
 * @function
 *
 * @param roleKey a role key to search entitlements against
 * @param keyWord a key word to search entitlements against
 * @param filterBy parameterized object (assetTypes, platformType) for filtering the entitlements
 * @param offset the search offset
 * @param setEntitlements a useState hook for setting the searched entitlements
 * @param isInitialRender a useState variable denoting whether an entitlements view is initially rendered
 * @param setIsInitialRender a useState hook for setting whether an entitlements view is initially rendered
 */
function useFetchRolesEntitlements(
  roleKey,
  keyWord,
  filterBy,
  offset,
  setEntitlements,
  isInitialRender,
  setIsInitialRender
) {
  const {
    data: fetchedRolesEntitlements,
    isLoading: isRolesEntitlementsLoading,
    isFetching: isRolesEntitlementsFetching,
    error: rolesEntitlementsApiError,
    isSuccess: isRolesEntitlementsApiSuccess,
    isError: isRolesEntitlementsApiError,
    refetch,
  } = useQuery(
    getRolesEntitlementsQueryConfigs(roleKey, keyWord, filterBy, offset)
  );

  const debouncedRefetch = useRef(
    debounce(() => {
      refetch();
    }, INPUT_DEBOUNCE_DELAY)
  ).current;

  useEffect(() => {
    if (!isInitialRender) {
      refetch();
    }
  }, []);

  useEffect(() => {
    if (keyWord !== '') {
      setIsInitialRender(false);
    }

    if (!isInitialRender) {
      debouncedRefetch();
    }

    return () => {
      debouncedRefetch?.cancel();
    };
  }, [keyWord, isInitialRender, debouncedRefetch]);

  useEffect(() => {
    if (isRolesEntitlementsApiSuccess) {
      setEntitlements(fetchedRolesEntitlements);
    }
  }, [isRolesEntitlementsApiSuccess, fetchedRolesEntitlements]);

  return {
    fetchedRolesEntitlements,
    isRolesEntitlementsLoading,
    isRolesEntitlementsFetching,
    rolesEntitlementsApiError,
    isRolesEntitlementsApiSuccess,
    isRolesEntitlementsApiError,
  };
}

export default useFetchRolesEntitlements;
