import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import './Global404Certs.css';

function Global404Certs() {
  const { IDWMS_API_URL, APPLICATION_LOGO } = useSelector(
    (state) => state.user.domainInfo || {}
  );

  return (
    <div className='Global404Certs-pageNotFound'>
      <div className='Global404Certs-pageNotFound'>
        <a href='/request/requestHome'>
          <img
            className='Global404Certs-logo'
            src={`${IDWMS_API_URL}/resource/image/app/${APPLICATION_LOGO}`}
            alt='app-logo'
          />
        </a>
        <div className='Global404Certs-error-content'>
          <div className='Global404Certs-error-body'>
            Neo Experience for Certifications has been disabled.
          </div>
          <div className='Global404Certs-error-details'>
            Since this certification was launched with the Neo Experience,
            please contact your administrator to Enable Neo Experience for
            Certifications to work on this certification.
          </div>
          <div className='Global404Certs-btn-back-container'>
            <Link
              to='/ECM/campaign/list?status=5'
              className='Global404Certs-btn-back'
              onClick={() =>
                window.location.replace('/ECM/campaign/list?status=5')
              }>
              Back to Campaign List
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Global404Certs;
