import HttpService from 'ui-home/src/misc/HttpService/HttpService';

export const FETCH_PARALLEL_ENV = 'FETCH_PARALLEL_ENV';
export const FETCH_PARALLEL_ENV_SUCCESS = 'FETCH_PARALLEL_ENV_SUCCESS';
export const FETCH_PARALLEL_ENV_ERROR = 'FETCH_PARALLEL_ENV_ERROR';

const fetchParallelEnv = () => (dispatch, getState) => {
  const state = getState();
  const isParallelEnvCheckDisabled = state.user.domainInfo.DISABLE_PARALLEL_ENV_CHECK;

  if (isParallelEnvCheckDisabled) {
    return;
  } else {
    dispatch({ type: FETCH_PARALLEL_ENV });
    const response = HttpService.get(`/ECM/api/v5/maintenance/upgrade/version`);
    response
      .then((res) => {
        dispatch({
          type: FETCH_PARALLEL_ENV_SUCCESS,
          data: res,
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_PARALLEL_ENV_ERROR,
          error: error,
        });
      });
  }

  //ToDo - Mocked API data
  // const data = {
  //   "current_version": "24.4",
  //   "upgrade_status": "UPGRADE_READY",
  //   "upgrade_from_version": "24.4",
  //   "upgrade_to_version": "24.5",
  // };

  // dispatch({
  //   type: FETCH_PARALLEL_ENV_SUCCESS,
  //   data: data,
  // });
};

export { fetchParallelEnv };
