import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  MenuItem,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    padding: '7px 40px 7px 26px',
  },
}));

const MenuWrapper = ({ children, onMenuClick }) => {
  const classes = useStyles();

  return (
    <MenuItem
      onClick={onMenuClick}
      disableGutters
      classes={{
        root: classes.root,
      }}
    >
      {children}
    </MenuItem>
  );
};

MenuWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  onMenuClick: PropTypes.func.isRequired,
};

export default MenuWrapper;
