import React from 'react';
import { useIntl } from 'react-intl';
import { formatSchedulerLocalizedDateString, localize } from '@saviynt/common';
import { Box } from '@saviynt/design-system';
import classnames from 'classnames';
import {
  addDays,
  endOfDay,
  intervalToDuration,
  isSameDay,
  startOfDay,
} from 'date-fns';
import PropTypes from 'prop-types';

import { Session } from '../../models/PamModels';

import SchedulerDay from './ScheduleDay/SchedulerDay';
import { msgs, STATUS } from './constants';
import {
  isLongTermReservation,
  isMultiDayReservation,
  isShortTermReservation,
} from './helpers';

import './Scheduler.css';

function Scheduler({
  sessions,
  numOfDaysDisplayed,
  timeFormat,
  isModalView,
  dataTestId,
  className,
  filters,
  showRequestId,
}) {
  const classes = classnames('Scheduler', className);

  const intl = useIntl();

  const SUNDAY = localize(intl, msgs.pam.scheduler.sunday);
  const MONDAY = localize(intl, msgs.pam.scheduler.monday);
  const TUESDAY = localize(intl, msgs.pam.scheduler.tuesday);
  const WEDNESDAY = localize(intl, msgs.pam.scheduler.wednesday);
  const THURSDAY = localize(intl, msgs.pam.scheduler.thursday);
  const FRIDAY = localize(intl, msgs.pam.scheduler.friday);
  const SATURDAY = localize(intl, msgs.pam.scheduler.saturday);

  const isFiltered = (reservations) => {
    const isNoFilters = filters.length === 0;

    const isAvailable = filters.includes(STATUS.AVAILABLE);

    const isAvailableOnly =
      filters.every((filter) => filter === STATUS.AVAILABLE) &&
      reservations.length === 0;

    const isUnavailableOnly =
      filters.every((filter) => filter === STATUS.UNAVAILABLE) &&
      reservations.length > 0;

    return isNoFilters || isAvailable || isAvailableOnly || isUnavailableOnly;
  };

  const getMutatedSessionsWithMultiDayRes = (initialSessions) => {
    const filteredSessions = initialSessions.filter(
      (initialSession) => !isShortTermReservation(initialSession)
    );

    return filteredSessions.map((filteredSession) =>
      isLongTermReservation(filteredSession)
        ? {
            ...filteredSession,
            sessionEndDate: endOfDay(
              new Date(filteredSession.sessionStartDate)
            ).toString(),
          }
        : filteredSession
    );
  };

  const renderBlock = () => {
    const weekdays = [
      SUNDAY,
      MONDAY,
      TUESDAY,
      WEDNESDAY,
      THURSDAY,
      FRIDAY,
      SATURDAY,
    ];
    const daysArray = [];

    for (let i = 0; i < numOfDaysDisplayed; i += 1) {
      const currentDate = new Date();

      currentDate.setDate(currentDate.getDate() + i);
      daysArray.push(currentDate);
    }

    // ASSIGN SESSIONS BY DAY
    // TODO: Optimize nested loops
    return daysArray.map((day) => {
      const reservedSessions = [];
      const multiDayResSessionsByDay = [];

      // Parse sessions for multi-day reservations
      sessions.forEach((session) => {
        const sessionStartDate = new Date(session.sessionStartDate);
        const sessionEndDate = new Date(session.sessionEndDate);

        // Separate each multi-day reservation
        if (isMultiDayReservation(session)) {
          const durationOfMultiDayRes = intervalToDuration({
            start: sessionStartDate,
            end: sessionEndDate,
          });

          const numOfReservedDays = durationOfMultiDayRes.days + 1;

          // Short-term Reservation (=<24hrs)
          if (isShortTermReservation(session)) {
            let shortTermResByDay;

            for (let i = 0; i < numOfReservedDays + 1; i += 1) {
              const startDate = new Date(session.sessionStartDate);
              const endDate = new Date(session.sessionEndDate);

              if (i === 0) {
                shortTermResByDay = {
                  ...session,
                  sessionStartDate: startDate.toString(),
                  sessionEndDate: endOfDay(startDate).toString(),
                };
              } else {
                shortTermResByDay = {
                  ...session,
                  sessionStartDate: startOfDay(endDate).toString(),
                  sessionEndDate: endDate.toString(),
                };
              }

              multiDayResSessionsByDay.push(shortTermResByDay);
            }
          } else {
            // Long-term reservation (>24hrs)
            for (let i = 0; i < numOfReservedDays; i += 1) {
              let longTermResByDay;

              let longTermResSessionStartDate = new Date(
                session.sessionStartDate
              );

              const longTermResSessionEndDate = new Date(
                session.sessionEndDate
              );

              longTermResSessionStartDate = addDays(
                longTermResSessionStartDate,
                i + 1
              );

              const longTermResSessionStartDateNeutral = startOfDay(
                longTermResSessionStartDate
              );

              if (
                isSameDay(
                  longTermResSessionStartDate,
                  longTermResSessionEndDate
                )
              ) {
                longTermResByDay = {
                  ...session,
                  sessionStartDate:
                    longTermResSessionStartDateNeutral.toString(),
                };
              } else {
                longTermResByDay = {
                  ...session,
                  sessionStartDate:
                    longTermResSessionStartDateNeutral.toString(),
                  sessionEndDate: sessionEndDate.toString(),
                };
              }

              multiDayResSessionsByDay.push(longTermResByDay);
            }
          }
        }
      });

      // mutate/filter out original multi-day reservations
      const mutatedSessions = getMutatedSessionsWithMultiDayRes(sessions);

      // Mutate the original sessions array to include the newly daily-divided long-term reservations
      const mutatedSessionsArray = Array.prototype.concat(
        mutatedSessions,
        multiDayResSessionsByDay
      );

      // Traverse the final mutated sessions array and assign the session(s) to each scheduler day by start date
      mutatedSessionsArray.forEach((session) => {
        const sessionStartDate = new Date(session.sessionStartDate);
        const currentDay = new Date(day);

        if (isSameDay(sessionStartDate, currentDay)) {
          reservedSessions.push(session);
        }
      });

      const availableStartDate = new Date(day);

      availableStartDate.setHours(0, 0, 0, 0);

      const isToday = daysArray.indexOf(day) === 0;

      return (
        <Box
          key={daysArray.indexOf(day)}
          className={
            isFiltered(reservedSessions)
              ? 'Scheduler-block'
              : 'Scheduler-block--hidden'
          }>
          {isFiltered(reservedSessions) && (
            <section className='Scheduler-blockLabel'>
              <div className='Scheduler-blockLabel--weekday'>
                {`${isToday ? 'Today' : weekdays[day.getDay()]}, `}
              </div>
              <div className='Scheduler-blockLabel--date'>
                {formatSchedulerLocalizedDateString(day)}
              </div>
            </section>
          )}
          <SchedulerDay
            sessions={reservedSessions}
            timeFormat={timeFormat}
            isModalView={isModalView}
            availableStartDate={availableStartDate}
            filters={filters}
            currentDate={day}
            showRequestId={showRequestId}
          />
        </Box>
      );
    });
  };

  return (
    <section className={classes} data-testid={dataTestId}>
      {renderBlock()}
    </section>
  );
}

Scheduler.propTypes = {
  sessions: PropTypes.arrayOf(Session).isRequired,
  numOfDaysDisplayed: PropTypes.number,
  timeFormat: PropTypes.shape({
    roundToNearest: PropTypes.string,
    is24HourFormat: PropTypes.bool,
  }).isRequired,
  isModalView: PropTypes.bool,
  dataTestId: PropTypes.string,
  className: PropTypes.string,
  filters: PropTypes.arrayOf(PropTypes.string),
  showRequestId: PropTypes.bool,
};

Scheduler.defaultProps = {
  numOfDaysDisplayed: 10,
  isModalView: false,
  dataTestId: null,
  className: null,
  filters: [],
  showRequestId: true,
};

export default Scheduler;
