/* eslint-disable import/prefer-default-export */
import { useIntl } from 'react-intl';

import { localize } from '../utilities/localization';

/**
 * Hook that abstracts away some of the logic of the `localize` function in the common package.
 * It imports and uses the `useIntl` hook, eliminating the need for every component to do so individually.
 * See the `localize` function in `localization.js` for more info.
 *
 * @param {string|object} message - message ID or message descriptor object.
 * @param {object} [placeholders] - optional placeholders for dynamic content.
 *
 * @returns {Function} a function that takes a message ID and optional placeholders, then returns the localized string.
 *
 * @example
 * import { useLocalize } from '@saviynt/common';
 * const localize = useLocalize();
 * localize('hello.name.id');
 *
 * @example
 * import { useLocalize } from '@saviynt/common';
 * const localize = useLocalize();
 * localize('hello.name.id', { name: 'John' });
 *
 * @example
 * import { useLocalize } from '@saviynt/common';
 * const localize = useLocalize();
 * localize({ id: 'hello.name.id', defaultMessage: 'Hello {name}' }, { name: 'John' });
 */

const useLocalize = () => {
  const intl = useIntl();

  return (message, placeholders) => localize(intl, message, placeholders);
};

export { useLocalize };
