import { SHOW_NOTIFICATION, CLOSE_NOTIFICATION } from '../actions/NotificationAction';

export const initState = () => ({
  title: null,
  description: null,
  location: null,
  type: null,
  buttonText: null,
  onClick: null,
});

export const notificationReducer =
  () =>
  (state = initState(), action) => {
    switch (action.type) {
      case SHOW_NOTIFICATION:
        return {
          ...state,
          title: action.payload.title,
          description: action.payload.description,
          location: action.payload.location,
          type: action.payload.type,
          buttonText:  action.payload?.buttonText,
          onClick:  action.payload?.onClick,
          errors: action.payload.errors,
        };

      case CLOSE_NOTIFICATION: {
        return {
          ...state,
          title: null,
          description: null,
          location: null,
          type: null,
          buttonText: null,
          onClick: null,
          errors: null,
        };
      }

      default:
        return state;
    }
  };

export default notificationReducer();
