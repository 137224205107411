import React from 'react';
import { useLocalize } from '@saviynt/common';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { usePlatformLabel } from '../../../../../../../hooks/use-labels';
import { ModuleDetails } from '../../../../../../../models/PamModels';
import { msgs } from '../../../../../constants';
import SpvSection from '../../../../SpvSection/SpvSection';

import './SpvServer.css';

function SpvServer({ data, dataTestId, className }) {
  const classes = classnames('SpvServer', className);
  const localize = useLocalize();

  const cloudProvider = data.connectionType?.toUpperCase();

  const hasSummaryFields =
    data.platformType || data.description || data.customproperty10;

  const hasSystemDetailsFields =
    data.name ||
    data.customproperty17 ||
    data.customproperty16 ||
    data.customproperty5 ||
    data.customproperty11 ||
    data.customproperty9 ||
    data.customproperty15;

  return (
    <div className={classes} data-testid={dataTestId}>
      {hasSummaryFields && (
        <SpvSection
          title={localize(msgs.pam.sidePanel.summary)}
          fields={[
            {
              label: localize(msgs.pam.sidePanel.platform),
              value: data.platformType,
            },
            {
              label: localize(msgs.pam.sidePanel.description),
              value: data.description,
            },
            {
              label: localize(msgs.pam.sidePanel.location),
              value: data.customproperty10,
            },
          ]}
        />
      )}
      {hasSystemDetailsFields && (
        <SpvSection
          title={localize(msgs.pam.sidePanel.systemDetails)}
          fields={[
            {
              // TODO: Temp mapping; real mapped field unknown; needs update
              label: localize(msgs.pam.sidePanel.id),
              value: data.name,
            },
            {
              label: usePlatformLabel(cloudProvider),
              value: data.customproperty17,
            },
            {
              label: localize(msgs.pam.sidePanel.operatingSystem),
              value: data.customproperty16,
            },
            {
              label: localize(msgs.pam.sidePanel.environment),
              value: data.customproperty5,
            },
            {
              label: localize(msgs.pam.sidePanel.network),
              value: data.customproperty11,
            },
            {
              label: localize(msgs.pam.sidePanel.publicIP),
              value: data.customproperty9,
            },
            {
              label: localize(msgs.pam.sidePanel.privateIP),
              value: data.customproperty15,
            },
          ]}
          className='SpvServer-section'
        />
      )}
    </div>
  );
}

SpvServer.propTypes = {
  data: ModuleDetails,
  dataTestId: PropTypes.string,
  className: PropTypes.string,
};

SpvServer.defaultProps = {
  data: null,
  dataTestId: null,
  className: null,
};

export default SpvServer;
