import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import styles from './Title.module.css';

const Title = ({ intl, position }) => {
  const classes = classnames(
    styles.container,
    position === 'center' && styles.center,
    position === 'left' && styles.leftAlign,
  );

  return (
    <div className={classes} data-testid="title-container">
      <h5 className={styles.title}>{intl.formatMessage({ id: 'Admin.Log.Viewer.title' })}</h5>
      <h6 className={styles.subTitle}>{intl.formatMessage({ id: 'Admin.Log.Viewer.subTitle' })}</h6>
    </div>
  );
};

Title.propTypes = {
  position: PropTypes.oneOf(['center', 'left']),
};

Title.defaultProps = {
  position: 'center',
};

export default injectIntl(Title);
