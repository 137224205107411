export const REQUEST_FILE_UPLOAD_FAILED = 'REQUEST_FILE_UPLOAD_FAILED';
export const CLEAR_REQUEST_UPLOAD_ERROR = 'CLEAR_REQUEST_UPLOAD_ERROR';

export const requestFileUploadFailed = (userName) => ({
  type: REQUEST_FILE_UPLOAD_FAILED,
  payload: { userName },
});

export const clearFileUploadStatus = () => ({
  type: CLEAR_REQUEST_UPLOAD_ERROR,
});
