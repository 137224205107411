
import React from 'react';

const ExpandLessIcon = () => (
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.98312 8.45553C5.14584 8.29282 5.40966 8.29282 5.57238 8.45553L9.99997 12.8831L14.4276 8.45553C14.5903 8.29282 14.8541 8.29282 15.0168 8.45553C15.1795 8.61825 15.1795 8.88207 15.0168 9.04479L10.2946 13.767C10.1319 13.9297 9.86806 13.9297 9.70534 13.767L4.98312 9.04479C4.8204 8.88207 4.8204 8.61825 4.98312 8.45553Z" fill="#9EA1B1"/>
</svg>
);

export default ExpandLessIcon;

