import { useSelector } from 'react-redux';
import { capitalizeFirstLetter } from '@saviynt/common';

export default function useDisplayName() {
  const { firstName, lastName } = useSelector((state) => state.user.loginInfo);

  const fName = firstName && capitalizeFirstLetter(firstName);
  const lName = lastName && capitalizeFirstLetter(lastName);

  if (fName && lName) {
    return fName.charAt(0) + lName.charAt(0);
  } else if (fName) {
    return fName.charAt(0);
  } else if (lName) {
    return lName.charAt(0);
  }
  return null;
}
