import { checkEnvironment } from '@saviynt/common';
import { Cookies } from 'react-cookie';

/**
 * HttpService is a utility class for making HTTP requests.
 * It uses the Fetch API under the hood and provides methods
 * for setting, deleting, and resetting headers.
 *
 * This class is exported as both a singleton instance and a class.
 * You can import the singleton instance for shared use across the
 * application, or import the class to create a new instance.
 * For simple http calls, it's recommended to use the singleton instance.
 *
 * @example
 * // Importing the singleton instance:
 * import HttpServiceSingleton from 'HttpService';
 * When using the singleton, do not modify headers as this can affect other
 * calls that are using the shared instance while your modified call is in flight.
 *
 * // Importing the class to create a new instance:
 * import { HttpService } from 'HttpService';
 * const httpServiceObj = new HttpService();
 * httpServiceObj.deleteHeader('Content-Type');
 *
 * @export
 * @class HttpService
 */
export class HttpService {
  constructor() {
    this.cookies = new Cookies();
    this.initialHeaders = () => ({
      'Content-Type': 'application/json',
      ...(checkEnvironment().FEATURE_HTTPONLY_ACCESSTOKEN || {
        Authorization: `${this.cookies.get('token_type')} ${this.cookies.get(
          'access_token'
        )}`,
      }),
    });
  }

  setHeaders(headers) {
    this.headers = {
      ...this.headers || this.initialHeaders(),
      ...headers,
    };
  }

  deleteHeader(headerName) {
    this.headers = this.headers || this.initialHeaders();
    delete this.headers[headerName];
  }

  resetHeaders() {
    this.headers = this.initialHeaders();
  }

  get(url) {
    return fetch(url, {
      method: 'GET',
      headers: this.headers || this.initialHeaders(),
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        }

        return res;
      })
      .finally(() => {
        this.resetHeaders();
      });
  }

  post(url, req, errorCallback, shouldStringifyBody = true) {
    const body = shouldStringifyBody ? JSON.stringify(req) : req;

    return fetch(url, {
      method: 'POST',
      headers: this.headers || this.initialHeaders(),
      body,
    })
      .then((res) => {
        if (res.status === 200) {
          const contentType = res.headers.get('content-type');

          if (contentType && contentType.includes('application/json')) {
            return res.json();
          }

          return res;
        }

        // Check if errorCallback is a function before calling it
        if (errorCallback && typeof errorCallback === 'function') {
          errorCallback();
        }

        return res;
      })
      .finally(() => {
        this.resetHeaders();
      });
  }

  put(url, req, errorCallback, shouldStringifyBody = true) {
    const body = shouldStringifyBody ? JSON.stringify(req) : req;

    return fetch(url, {
      method: 'PUT',
      headers: this.headers || this.initialHeaders(),
      body,
    })
      .then((res) => {
        if (res.status === 200) {
          const contentType = res.headers.get('content-type');

          if (contentType && contentType.includes('application/json')) {
            return res.json();
          }

          return res;
        }

        if (errorCallback && typeof errorCallback === 'function') {
          errorCallback();
        }

        return res;
      })
      .finally(() => {
        this.resetHeaders();
      });
  }

  delete() {
    // TODO: Enhance this when needed!
  }
}

export default new HttpService();
