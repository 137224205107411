/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Cookies } from 'react-cookie';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { Button, IconButton } from '@material-ui/core';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { SaveProfileImage, ClearProfileImage } from '../../../actions/profileMenuAction';
import ImagePreviewModal from './ImagePreviewModal';
import './ImageUploadModal.scss';

const cookies = new Cookies();

export const Close = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.62204 8.62204C8.78476 8.45932 9.04858 8.45932 9.21129 8.62204L16 15.4107L22.7887 8.62209C22.9514 8.45937 23.2152 8.45937 23.378 8.62209C23.5407 8.78481 23.5407 9.04862 23.3779 9.21134L16.5893 16L23.3779 22.7887C23.5407 22.9514 23.5407 23.2152 23.378 23.3779C23.2152 23.5406 22.9514 23.5406 22.7887 23.3779L16 16.5893L9.21129 23.378C9.04858 23.5407 8.78476 23.5407 8.62204 23.378C8.45932 23.2152 8.45932 22.9514 8.62204 22.7887L15.4108 16L8.62204 9.21129C8.45932 9.04858 8.45932 8.78476 8.62204 8.62204Z"
      fill="#212328"
    />
  </svg>
);

export const Upload = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.0001 21C11.7239 21 11.5001 20.7761 11.5001 20.5L11.5001 7.62165L7.17597 11.9476C6.98075 12.1429 6.66417 12.1429 6.46887 11.9477C6.27356 11.7525 6.2735 11.4359 6.46872 11.2406L11.6463 6.06085C11.7401 5.96703 11.8673 5.91433 11.9999 5.91433C12.0446 5.91433 12.0886 5.92029 12.1309 5.93178C12.1776 5.9444 12.2216 5.96362 12.2618 5.98838C12.2949 6.0087 12.3257 6.03295 12.3536 6.06084L17.5313 11.2406C17.7265 11.4359 17.7265 11.7525 17.5312 11.9477C17.3359 12.1429 17.0193 12.1429 16.8241 11.9476L12.5001 7.6219L12.5001 20.5C12.5001 20.7761 12.2762 21 12.0001 21Z"
      fill="white"
    />
    <path
      d="M19.9392 4C20.2153 4 20.4392 3.77614 20.4392 3.5C20.4392 3.22386 20.2153 3 19.9392 3H4.06104C3.78489 3 3.56104 3.22386 3.56104 3.5C3.56104 3.77614 3.78489 4 4.06104 4H19.9392Z"
      fill="white"
    />
  </svg>
);

export const Delete = () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.09115 4.83333V5.21452H6.08105C5.80491 5.21452 5.58105 5.43838 5.58105 5.71452C5.58105 5.99066 5.80491 6.21452 6.08105 6.21452H18.919C19.1952 6.21452 19.419 5.99066 19.419 5.71452C19.419 5.43838 19.1952 5.21452 18.919 5.21452H15.9088V4.83333C15.9088 3.82081 15.088 3 14.0755 3H10.9245C9.91196 3 9.09115 3.82081 9.09115 4.83333ZM10.9245 4C10.4642 4 10.0911 4.3731 10.0911 4.83333V5.21452H14.9088V4.83333C14.9088 4.3731 14.5357 4 14.0755 4H10.9245Z"
      fill="#3751FF"
    />
    <path
      d="M14.6396 10.4678C14.9158 10.4678 15.1396 10.6916 15.1396 10.9678V17.0302C15.1396 17.3063 14.9158 17.5302 14.6396 17.5302C14.3635 17.5302 14.1396 17.3063 14.1396 17.0302V10.9678C14.1396 10.6916 14.3635 10.4678 14.6396 10.4678Z"
      fill="#3751FF"
    />
    <path
      d="M10.8602 10.9678C10.8602 10.6916 10.6363 10.4678 10.3602 10.4678C10.084 10.4678 9.86019 10.6916 9.86019 10.9678V17.0302C9.86019 17.3063 10.084 17.5302 10.3602 17.5302C10.6363 17.5302 10.8602 17.3063 10.8602 17.0302V10.9678Z"
      fill="#3751FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.82484 6.9974C6.13014 6.9974 5.58938 7.60073 5.66513 8.29129L6.9453 19.9605C7.0102 20.552 7.50992 20.9999 8.10501 20.9999H16.8951C17.4901 20.9999 17.9899 20.552 18.0548 19.9605L19.3349 8.29129C19.4107 7.60073 18.8699 6.9974 18.1752 6.9974H6.82484ZM6.65917 8.18224C6.64835 8.08359 6.7256 7.9974 6.82484 7.9974H18.1752C18.2745 7.9974 18.3517 8.08359 18.3409 8.18224L17.0607 19.8514C17.0515 19.9359 16.9801 19.9999 16.8951 19.9999H8.10501C8.02 19.9999 7.94861 19.9359 7.93934 19.8514L6.65917 8.18224Z"
      fill="#3751FF"
    />
  </svg>
);

export const AlertCriticalFilled = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.91675 2C4.60304 2 1.91675 4.68629 1.91675 8C1.91675 11.3137 4.60304 14 7.91675 14C11.2305 14 13.9167 11.3137 13.9167 8C13.9167 4.68629 11.2305 2 7.91675 2ZM7.16633 5.73284C7.12194 5.28888 7.47057 4.90365 7.91675 4.90365C8.36292 4.90365 8.71156 5.28888 8.66716 5.73284L8.37487 8.65571C8.35134 8.89108 8.15328 9.07031 7.91675 9.07031C7.68021 9.07031 7.48216 8.89108 7.45862 8.65571L7.16633 5.73284ZM8.58341 10.4299C8.58341 10.7981 8.28494 11.0966 7.91675 11.0966C7.54856 11.0966 7.25008 10.7981 7.25008 10.4299C7.25008 10.0617 7.54856 9.76324 7.91675 9.76324C8.28494 9.76324 8.58341 10.0617 8.58341 10.4299Z"
      fill="#CC1C10"
    />
  </svg>
);

const Dialog = withStyles({
  root: {
    zIndex: '1500 !important',
  },
})(MuiDialog);

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: '12px 24px',
    borderBottom: '1px solid #CECFD9',
  },
  title: {
    color: '#212328',
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '28px',
    marginBottom: '0',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    padding: '5px',
  },
  margin: {
    margin: theme.spacing(1),
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} timeout={1000} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { classes, onClose } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} style={{ width: 600 }}>
      <h6 className={classes.title}>Update Profile Picture</h6>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          data-testid="closeOwnerModalBtn"
        >
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(() => ({
  root: {
    padding: '24px',
    background: '#F2F4F8',
  },
}))(MuiDialogContent);

const ImageUploadModal = ({ onDialogClose, open, intl }) => {
  const dispatch = useDispatch();
  const { profileImage } = useSelector((state) => state.profile);

  const [image, setImage] = useState(null);
  const [croppedImg, setCroppedImg] = useState(profileImage);
  const [previewModal, setPreviewModal] = useState(false);
  const [fileSizeError, setFileSizeError] = useState(false);

  // function fileSizeConvertor(size) {
  //   const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));

  //   return `${(size / 1024 ** i).toFixed(2) * 1} ${['B', 'kB', 'MB', 'GB', 'TB'][i]}`;
  // }

  const handleFileSelect = async (e) => {
    try {
      e.preventDefault();
      let files;

      if (e.dataTransfer) {
        files = e.dataTransfer.files;
      } else if (e.target) {
        files = e.target.files;
      }

      const reader = new FileReader();

      if (!reader) return;

      // Validate the File Limit size to 800KB
      if (files[0].size <= 819201) {
        reader.onload = () => {
          setImage(reader.result?.toString());
          e.target.value = null;
        };
        setPreviewModal(true);
        setFileSizeError(false);
      } else {
        setImage(null);
        setFileSizeError(true);
      }

      reader.readAsDataURL(files[0]);
    } catch (error) {
      // console.log(error);
      setImage(null);
      setFileSizeError(true);
    }
  };

  const onPreviewDialogClose = () => {
    setPreviewModal(false);
  };

  const onPreviewImage = (img) => {
    setCroppedImg(img);
    dispatch(SaveProfileImage(img));
    setPreviewModal(false);
  };

  const onDeleteImage = () => {
    setCroppedImg(null);
    setFileSizeError(false);
    dispatch(ClearProfileImage());
  };

  const removeCharacter = (str) => {
    if (str) {
      return (str.match(/[a-zA-Z0-9]/) || []).pop();
    }

    return null;
  };

  return (
    <Dialog
      open={open}
      onClose={onDialogClose}
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          maxHeight: '540px',
          height: '540px',
          maxWidth: '650px',
          borderRadius: '4px',
        },
      }}
    >
      <DialogTitle id="customized-dialog-title" onClose={onDialogClose} intl={intl} />
      <DialogContent>
        <div className="image-upload">
          <div className="upload-title">
            A profile picture helps people recognize you throughout the platform. It should be JPG,
            GIF or PNG. Max size of 800K
          </div>
          {fileSizeError ? (
            <div className="upload-error">
              <AlertCriticalFilled />
              <p>The image you chose to upload exceeds 800k</p>
            </div>
          ) : null}
          {croppedImg ? (
            <div className="img-preview">
              <img src={croppedImg} alt="uploaded pic" />
            </div>
          ) : (
            <div className="no-img-preview">{removeCharacter(cookies.get('user_name'))}</div>
          )}
          <div className="image-button-actions">
            <input
              className="file-input"
              id="contained-button-file"
              type="file"
              onChange={handleFileSelect}
              accept="image/gif, image/jpeg, image/png"
            />
            <label htmlFor="contained-button-file">
              <Button
                variant="contained"
                disableElevation
                color="primary"
                component="span"
                startIcon={<Upload />}
                className="upload-button"
              >
                Upload New Image
              </Button>
            </label>
            {croppedImg ? (
              <Button
                variant="outlined"
                color="primary"
                disableElevation
                className="delete-button"
                onClick={onDeleteImage}
                startIcon={<Delete />}
              >
                Delete Image
              </Button>
            ) : null}
            <ImagePreviewModal
              open={previewModal}
              imgURL={image}
              onDialogClose={onPreviewDialogClose}
              onPreviewImage={onPreviewImage}
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

ImageUploadModal.propTypes = {
  onDialogClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  intl: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};

export default injectIntl(withRouter(ImageUploadModal));
