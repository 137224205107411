import {
  HOURS_PER_DAY,
  SECONDS_PER_DAY,
  SECONDS_PER_HOUR,
  SECONDS_PER_MINUTE,
} from '@saviynt/common';
import {
  addDays,
  addMinutes,
  addSeconds,
  differenceInHours,
  differenceInMinutes,
  eachDayOfInterval,
  isBefore,
  parseISO,
  setMinutes,
  setSeconds,
  startOfDay,
} from 'date-fns';

import { handleClearCredentialFormDates } from '../../../../store/actions';
import {
  initialIntervalOptions,
  INTERVAL_LENGTH_IN_MINS,
  INTERVALS,
  SESSION_STATUSES,
} from '../../constants';
import { HOURS_IN_THREE_DAYS } from './constants';

export const formatCredentialWarningDate = (dateString) => {
  const localTimeOfCredential = new Date(dateString);

  if (localTimeOfCredential && !Number.isNaN(localTimeOfCredential.getTime())) {
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      timeZoneName: 'short',
    }).format(localTimeOfCredential);
  }

  return 'TIME';
};

// useEffect Helpers
export const handleDurationSnapShot = (
  isNowBlocked,
  accordionValueSnapshot,
  isExpandedDuration,
  setToggleChoice,
  setIsDurationCustom,
  setFutureTabStartDate,
  setFutureTabEndDate,
  setIntervalOptions,
  setDurationChipValue,
  setCheckedIntervalOption,
  setCustomDurationInputValue
) => {
  // If the user closes the accordion, Reset to the saved state
  if (!isExpandedDuration) {
    // Tab selection
    if (!isNowBlocked) setToggleChoice(accordionValueSnapshot.isNowTab ? 1 : 2);
    // Custom
    setIsDurationCustom(accordionValueSnapshot.isDurationCustom);
    setCheckedIntervalOption(accordionValueSnapshot.checkedIntervalOption);
    setIntervalOptions((currentOptions) =>
      currentOptions.map((option) => ({
        ...option,
        isChecked:
          option.value === accordionValueSnapshot.checkedIntervalOption,
      }))
    );
    setCustomDurationInputValue(
      accordionValueSnapshot.customDurationInputValue
    );
    // Future
    setFutureTabStartDate(accordionValueSnapshot.futureTabStartDate);
    setFutureTabEndDate(accordionValueSnapshot.futureTabEndDate);
    // Duration
    setDurationChipValue(accordionValueSnapshot.durationChipValue);
  }
};

export const handleExcludedDateAndTime = (
  userName,
  isCOCorExclusive,
  selectedAccountSessions,
  setFutureDatesToExclude,
  setFutureTimesToExclude
) => {
  setFutureDatesToExclude([]);
  setFutureTimesToExclude([]);

  if (selectedAccountSessions?.length > 0) {
    let fullDaysToExclude = [];
    const intervalsToExclude = [];
    const sessionsWithValidStatusCOC = selectedAccountSessions.filter(
      (session) =>
        (session.sessionStatus !== SESSION_STATUSES.COMPLETED &&
          session.username === userName) || // Filter in matching usernames with any status except 3
        (session.sessionStatus === undefined &&
          session.username === userName) || // Filter in undefined status only for matching username
        (session.sessionStatus !== undefined &&
          session.sessionStatus !== SESSION_STATUSES.COMPLETED) // Keep the original condition for other usernames
    );
    const sessionsWithValidStatusCTS = selectedAccountSessions.filter(
      // UserName is being filtered in The duration service file, so only removing completed sessions here
      (session) => session.sessionStatus !== SESSION_STATUSES.COMPLETED
    );

    const sessionsToBeIterated = isCOCorExclusive
      ? sessionsWithValidStatusCOC
      : sessionsWithValidStatusCTS;

    sessionsToBeIterated.forEach((session) => {
      const startDate = parseISO(session.sessionStartDate);
      const endDate = parseISO(session.sessionEndDate);

      // If the session spans at least a full day (24 hours)
      if (differenceInHours(endDate, startDate) >= HOURS_PER_DAY) {
        // Get an array of full days between the start and end date, excluding the end date
        const days = eachDayOfInterval({
          start: startOfDay(startDate),
          end: addDays(startOfDay(endDate), -1),
        });

        fullDaysToExclude = fullDaysToExclude.concat(days);
      }
      // If the session is more than three days but less than a full day in hours
      else if (differenceInHours(endDate, startDate) > HOURS_IN_THREE_DAYS) {
        const days = eachDayOfInterval({
          // Start from the day after the start date and day before the end date to avoid lossing segments of times not selected
          start: addDays(startOfDay(startDate), 1),
          end: addDays(startOfDay(endDate), -1),
        });

        fullDaysToExclude = fullDaysToExclude.concat(days);
      } else {
        let currentTime = startDate;

        // Round start time down to the nearest interval
        currentTime = setMinutes(
          currentTime,
          Math.floor(currentTime.getMinutes() / INTERVAL_LENGTH_IN_MINS) *
            INTERVAL_LENGTH_IN_MINS
        );
        // Set seconds to 0 for clean intervals
        currentTime = setSeconds(currentTime, 0);

        while (differenceInMinutes(endDate, currentTime) >= 0) {
          // Add the current time to the intervals to exclude
          intervalsToExclude.push(new Date(currentTime));
          // Increment the current time by the interval length
          currentTime = addMinutes(currentTime, INTERVAL_LENGTH_IN_MINS);
        }
      }
    });

    setFutureDatesToExclude(fullDaysToExclude);
    setFutureTimesToExclude([...new Set(intervalsToExclude)]);
  }
};

export const handleAccountSelection = (
  isJITSelection,
  setToggleChoice,
  accountSelection,
  bootstrapConfigMaxTime,
  setDurationAccordionValue,
  dispatch,
  setIsNowBlocked,
  setIntervalOptions
) => {
  setIsNowBlocked(false);
  setToggleChoice(1);
  setDurationAccordionValue('');
  dispatch(handleClearCredentialFormDates('Date Range reset success'));
  const isAccountBasedMaxTimeDay = accountSelection?.maxtime < SECONDS_PER_DAY;
  const isJITBasedMaxTimeDay = bootstrapConfigMaxTime < SECONDS_PER_DAY;
  const isMaxTimeLessThanOneDay = isJITSelection
    ? isJITBasedMaxTimeDay
    : isAccountBasedMaxTimeDay;

  if (isMaxTimeLessThanOneDay) {
    // Filter out the 'Days' option
    const updatedIntervalOptions = initialIntervalOptions.filter(
      (option) => option.value !== INTERVALS.DAYS
    );

    setIntervalOptions(updatedIntervalOptions);
  } else {
    setIntervalOptions(initialIntervalOptions);
  }
};

export const handleNowTabEndDate = (
  isNowTab,
  durationChipValue,
  nowTabStartDate,
  setNowTabEndDate,
  isDurationCustom
) => {
  const isFuturePredefinedChipWithStartDate =
    !isNowTab && nowTabStartDate && durationChipValue && !isDurationCustom;
  const isNowWithStartDate =
    isNowTab && nowTabStartDate && durationChipValue && !isDurationCustom;
  const isCustomNowWithStartDate =
    isNowTab && nowTabStartDate && durationChipValue && isDurationCustom;

  const shouldSetEndDate =
    (isFuturePredefinedChipWithStartDate ||
      isNowWithStartDate ||
      isCustomNowWithStartDate) &&
    nowTabStartDate &&
    durationChipValue;

  if (shouldSetEndDate) {
    setNowTabEndDate(addSeconds(nowTabStartDate, durationChipValue));
  }
};

export const handleDateConflicts = (
  isNowTab,
  durationChipValue,
  durationInSeconds,
  accordionValueSnapshot,
  isDurationCustom,
  isFormCritical,
  nowTabStartDate,
  nowTabEndDate,
  futureTabStartDate,
  futureTabEndDate,
  hasDurationBeenFilled,
  nowStartDateOfNextCredential,
  futureStartDateOfNextCredential,
  setIsTimeBlocked,
  setIsNowBlocked,
  setIsScheduleConflictWarning,
  setIsFutureTimeExpiredWarning
) => {
  // Reset before eash calclation
  setIsTimeBlocked(false);
  setIsScheduleConflictWarning(false);
  setIsFutureTimeExpiredWarning(false);

  // NOW blocking conditions
  if (isNowTab && nowStartDateOfNextCredential) {
    const nowStartDateOfNextCredentialObj = new Date(
      nowStartDateOfNextCredential
    );
    const nowStartDateObj = new Date(nowTabStartDate);
    const nowEndDateObj = new Date(nowTabEndDate);
    const nowEndDateWithAddedDuration = addSeconds(
      nowStartDateObj,
      durationChipValue
    );
    const checkNowBlockerWhenCustom =
      nowEndDateWithAddedDuration >= nowStartDateOfNextCredentialObj;

    const isBlocked = Boolean(
      !isDurationCustom
        ? nowStartDateOfNextCredential &&
            nowTabEndDate &&
            nowEndDateObj >= nowStartDateOfNextCredentialObj
        : checkNowBlockerWhenCustom
    );

    setIsTimeBlocked(isBlocked);
    setIsScheduleConflictWarning(isBlocked);
  } else if (!isNowTab && futureStartDateOfNextCredential) {
    // FUTURE blocking conditions
    const futureStartDateObj = new Date(futureTabStartDate);
    const futureEndDateObj = new Date(futureTabEndDate);
    const futureStartDateOfNextCredentialObj = new Date(
      futureStartDateOfNextCredential
    );

    const futureEndDateWithAddedDuration = addSeconds(
      futureStartDateObj,
      durationInSeconds
    );

    const hasFutureStartDateAndNextCred =
      futureTabStartDate && futureStartDateOfNextCredential;

    const checkFutureBlocker =
      hasFutureStartDateAndNextCred &&
      futureEndDateObj >= futureStartDateOfNextCredentialObj;

    const checkFutureBlockerWhenCustom =
      hasFutureStartDateAndNextCred &&
      futureEndDateWithAddedDuration >= futureStartDateOfNextCredentialObj;

    const isBlocked = Boolean(
      isDurationCustom ? checkFutureBlockerWhenCustom : checkFutureBlocker
    );

    setIsTimeBlocked(isBlocked);
    setIsScheduleConflictWarning(isBlocked);
  }

  // TAB blocking conditions
  if (nowStartDateOfNextCredential) {
    const nowPlusFiveMinutes = addMinutes(new Date(), 5);
    const isNowPlusFiveBlocked = isBefore(
      nowPlusFiveMinutes,
      new Date(nowStartDateOfNextCredential)
    );

    setIsNowBlocked(!isNowPlusFiveBlocked);
  } else {
    setIsNowBlocked(false);
  }

  // Warning message for future expired request time
  const isFutureStartDatePassedNow = isBefore(
    accordionValueSnapshot.futureTabStartDate,
    new Date()
  );
  const isUserAttemptingToSubmitFutureCloseTime =
    !isNowTab && hasDurationBeenFilled && !isFormCritical.startDate;

  if (isFutureStartDatePassedNow && isUserAttemptingToSubmitFutureCloseTime)
    setIsFutureTimeExpiredWarning(true);
};

export const handleDurationByInterval = (
  checkedIntervalOption,
  maxRequestTimeAsNumber,
  customDurationInputValue,
  customInputValueAsNumber,
  isDurationCustom,
  setIntervalMaxTime,
  setDurationInSeconds
) => {
  if (isDurationCustom && customDurationInputValue) {
    switch (checkedIntervalOption) {
      case INTERVALS.MINUTES:
        setDurationInSeconds(customInputValueAsNumber * SECONDS_PER_MINUTE);
        setIntervalMaxTime(maxRequestTimeAsNumber / SECONDS_PER_MINUTE);
        break;
      case INTERVALS.HOURS:
        setDurationInSeconds(customInputValueAsNumber * SECONDS_PER_HOUR);
        setIntervalMaxTime(maxRequestTimeAsNumber / SECONDS_PER_HOUR);
        break;
      case INTERVALS.DAYS:
        setDurationInSeconds(customInputValueAsNumber * SECONDS_PER_DAY);
        setIntervalMaxTime(maxRequestTimeAsNumber / SECONDS_PER_DAY);
        break;
      default:
        setDurationInSeconds(0);
    }
  }
};
