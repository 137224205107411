import { Cookies } from 'react-cookie';
import { checkEnvironment } from './checkEnvironment';

const cookies = new Cookies();

/**
 * A utility function to determine if the user is currently logged in.
 *
 * @function
 * @returns {boolean} True if the user is logged in. Otherwise, false.
 */
export const isLoggedIn = () => {
  // We cannot use access_token cookie to infer authenticated state because it is HttpOnly. Since
  // user_name is always set when access_cookie is set, we can use it instead.
  const cookieName = checkEnvironment().FEATURE_HTTPONLY_ACCESSTOKEN
    ? 'user_name'
    : 'access_token';
  return cookies.get(cookieName) !== undefined;
};
