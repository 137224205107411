import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './SaviyntPopoverDateRangePicker.scss';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import { SlimSelectStandard } from 'ui-components/src';
import TextField from '@material-ui/core/TextField';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { isBefore, addHours } from 'date-fns';
import DatePickerComponent from './DatePickerCustomToolbar';

const useStyles = makeStyles(() => ({
  paper: {
    padding: '20px',
    display: 'flex',
    marginTop: '6px',
    marginLeft: '20px',
  },
  popover: {
    padding: '10px',
    display: 'flex',
  },
  blueLabel: {
    color: '#374eff',
  },
  DateTimeInput: {
    display: 'flex',
    marginBottom: '10px',
    marginTop: '20px',
    width: '140px',
  },
  timeInput: {
    marginTop: '20px',
  },
  buttonDiv: {
    marginTop: '90%',
  },
  button: {
    marginRight: '13px',
    fontSize: '13px',
  },
  initialDateStyle: {
    display: 'flex',
    width: '100%',
    marginBottom: '20px',
    position: 'relative',
  },
  calendarIcon: {
    position: 'absolute',
    left: '95%',
    top: '39%',
    cursor: 'pointer',
  },
  hyphen: {
    position: 'absolute',
    top: '39%',
    left: '45%',
  },
  calendarIconMedium: {
    position: 'absolute',
    left: '50%',
    top: '40%',
    cursor: 'pointer',
  },
  hyphenMedium: {
    position: 'absolute',
    top: '40%',
    left: '24%',
  },
}));

const SaviyntPopoverDateRangePicker = ({
  startDate,
  endDate,
  startDateMandatory,
  endDateMandatory,
  handleDateChangeProp,
  item,
  size,
  disableStartDate,
  disableEndDate,
  defaultTimeFrameHRS,
  minStartDate,
  maxStartDate,
  maxEndDate,
  type,
  isDisabledTransparentColor,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [startDateState, setStartDate] = useState(null);
  const [endDateState, setEndDate] = useState(null);
  const [event, setEvent] = useState(null);
  // const [endDateCopy, setEndDateCopy] = useState(null);
  let endDateCopy = null;

  useEffect(() => {
    if (startDate) {
      if(typeof(startDate) !== 'string') {
        setStartDate(new Date(startDate.getTime()));
      } else {
        startDate = new Date(startDate);
        setStartDate(new Date(startDate));
      }
    }
  }, [startDate]);

  useEffect(() => {
    if (endDate) {
      if(typeof(endDate) !== 'string') {
        setEndDate(new Date(endDate.getTime()));
      } else {
        endDate = new Date(endDate);
        setEndDate(new Date(endDate));
      }
    }
  }, [endDate]);

  const handleEvent = e => {
    setEvent(e);
    if (endDateCopy) {
      endDateCopy.setMilliseconds(0);
      endDateCopy.setMinutes(59);
      endDateCopy.setHours(23);
      if (!startDateState) {
        setEndDate(endDateCopy);
      } else if (startDateState.getTime() - endDateCopy.getTime() <= 0) {
        setEndDate(endDateCopy);
      }
      const stDate = startDateState;
      setStartDate(stDate);
    }
  };

  const handleDateChange = (value, attributeKey, item) => {
    if (attributeKey === 'endDate') {
      endDateCopy = value;
    }
    switch (attributeKey) {
      case 'startDate':
        if (value.toDateString() !== new Date().toDateString()) {
          value.setMilliseconds(0);
          value.setMinutes(0);
          value.setHours(0);
          setStartDate(value);
          if (defaultTimeFrameHRS) {
            const endDate = addHours(value, defaultTimeFrameHRS);
            setEndDate(endDate);
          }
        } else {
          setStartDate(new Date());
          if (defaultTimeFrameHRS) {
            const endDate = addHours(new Date(), defaultTimeFrameHRS);
            setEndDate(endDate);
          }
        }
        const edDate = endDateState;
        if (edDate && value.getTime() - edDate.getTime() > 0) {
          setEndDate(null);
        }
        break;
      case 'endDate':
        if (event) {
          value.setMilliseconds(0);
          value.setMinutes(59);
          value.setHours(23);
          if (!startDateState) {
            setEndDate(value);
          } else if (startDateState.getTime() - value.getTime() <= 0) {
            setEndDate(value);
          }
          const stDate = startDateState;
          setStartDate(stDate);
        }
        break;
      default:
        break;
    }
  };

  const handleTimeSlot = (value, key, item) => {
    if (key === 'startTime') {
      const stDate = startDateState;
      if (stDate) {
        const temp = new Date();
        if (stDate.toDateString() === temp.toDateString()) {
          if (temp.getHours() < value.value.hours) return true;
          if (temp.getHours() === value.value.hours && temp.getMinutes() < value.value.minutes)
            return true;
        }
        if (isBefore(temp, stDate)) return true;
      }
    } else if (endDateState) {
      const edDate = endDateState;
      const temp = startDateState || new Date();
      if (temp.toDateString() === edDate.toDateString()) {
        if (temp.getHours() < value.value.hours) return true;
        if (temp.getHours() === value.value.hours && temp.getMinutes() < value.value.minutes)
          return true;
      } else return true;
    }
    // return false;
    return type !== 'default'; // Temporary Code, will be removed later.
  };

  const handleTimeChange = (e, key, item) => {
    if (key === 'startTime') {
      const stDate = startDateState;
      const edDate = endDateState;
      stDate.setMinutes(e.value.minutes);
      stDate.setHours(e.value.hours);
      stDate.setMilliseconds(e.value.seconds);
      setStartDate(new Date(stDate.getTime()));
      if (defaultTimeFrameHRS) setEndDate(addHours(stDate, defaultTimeFrameHRS));
      else if (edDate && stDate.getTime() - edDate.getTime() > 0) setEndDate(null);
    } else {
      const edDate = new Date(endDateState.getTime());
      edDate.setMinutes(e.value.minutes);
      edDate.setHours(e.value.hours);
      edDate.setMilliseconds(e.value.seconds);
      setEndDate(new Date(edDate.getTime()));
    }
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = type => {
    if (type === 'close') {
      if(typeof(startDate) !== 'string') {
        setStartDate(new Date(startDate.getTime()));
      } else {
        startDate = new Date(startDate);
        setStartDate(new Date(startDate));
      }
      if (endDate) {
        if(typeof(endDate) !== 'string') {
          setEndDate(new Date(endDate.getTime()));
        } else {
          endDate = new Date(endDate);
          setEndDate(new Date(endDate));
        }
      }
      else setEndDate(null);
    }
    setAnchorEl(null);
    setEvent(null);
    endDateCopy = null;
  };

  const open = Boolean(anchorEl);
  const id = !open ? 'simple-popover' : undefined;

  return (
    <div>
      <div className={classes.initialDateStyle}>
        <TextField
          key="startDate"
          autoComplete="off"
          type="text"
          value={
            startDate
              ? 
                typeof startDate  !== 'string' ? startDate.toLocaleString('en-GB', {
                  year: '2-digit',
                  day: '2-digit',
                  month: '2-digit',
                  hour12: false,
                  hour: 'numeric',
                  minute: '2-digit',
                })
              : new Date(startDate).toLocaleString('en-GB', {
                year: '2-digit',
                day: '2-digit',
                month: '2-digit',
                hour12: false,
                hour: 'numeric',
                minute: '2-digit',
              })
              :''
          }
          classes={{ root: classes.input, input: classes.input }}
          disabled
          fullWidth={size === 'large'}
          disableUnderline={false}
          inputProps={{ 'aria-label': 'description' }}
          InputLabelProps={{ shrink: true }}
          label={
            <>
              <span className={classes.blueLabel}>From</span>
              {startDateMandatory && <span className="mandatory">&nbsp;*</span>}
            </>
          }
        />
        <div className={size === 'medium' ? classes.hyphenMedium : classes.hyphen}>-</div>
        <TextField
          key="endDate"
          autoComplete="off"
          type="text"
          value={
            endDate
              ? typeof endDate !== 'string' 
              ? endDate.toLocaleString('en-GB', {
                  year: '2-digit',
                  day: '2-digit',
                  month: '2-digit',
                  hour12: false,
                  hour: 'numeric',
                  minute: '2-digit',
                })
              : new Date(endDate).toLocaleString('en-GB', {
                year: '2-digit',
                day: '2-digit',
                month: '2-digit',
                hour12: false,
                hour: 'numeric',
                minute: '2-digit',
              }) 
              : ''
          }
          classes={{ root: classes.input, input: classes.input }}
          disabled
          fullWidth={size === 'large'}
          disableUnderline={false}
          inputProps={{ 'aria-label': 'description' }}
          InputLabelProps={{ shrink: true }}
          label={
            <>
              <span className={classes.blueLabel}>To</span>
              {endDateMandatory && <span className="mandatory">&nbsp;*</span>}
            </>
          }
        />
        {(!disableStartDate || !disableEndDate) && (
          <DateRangeIcon
            className={size === 'medium' ? classes.calendarIconMedium : classes.calendarIcon}
            onClick={handleClick}
          />
        )}
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
      >
        <DatePickerComponent
          attributeKey="startDate"
          value={startDateState}
          _handleChange={date => {
            handleDateChange(date, 'startDate', item);
          }}
          isExisting={false}
          readOnly={disableStartDate}
          minDate={minStartDate}
          maxDate={maxStartDate}
        />
        <div onClick={handleEvent}>
          <DatePickerComponent
            attributeKey="endDate"
            value={endDateState}
            _handleChange={date => {
              handleDateChange(date, 'endDate', item);
            }}
            isExisting={false}
            readOnly={disableEndDate}
            minDate={startDateState}
            maxDate={maxEndDate}
          />
        </div>
        <div className={`${isDisabledTransparentColor ? 'req_datePickerContainer' : ''} dateRangePicker`}>
          <div className={classes.DateTimeInput}>
            <TextField
              key="startDate"
              autoComplete="off"
              type="text"
              value={startDateState ? startDateState.toLocaleDateString() : ''}
              classes={{ root: classes.input, input: classes.input }}
              disabled
              disableUnderline={false}
              inputProps={{ 'aria-label': 'description' }}
              InputLabelProps={{ shrink: true }}
              label={
                <>
                  <span className={classes.blueLabel}>From</span>
                  {startDateMandatory && <span className="mandatory">&nbsp;*</span>}
                </>
              }
            />
            <div className={classes.timeInput}>
              <SlimSelectStandard
                attributeKey="startTime"
                defaultValue={startDateState}
                disabled={disableStartDate}
                visibleTimeSlot={(option, attrKey) => {
                  return handleTimeSlot(option, attrKey, item);
                }}
                onChange={(option, attrKey) => {
                  handleTimeChange(option, attrKey, item);
                }}
              />
            </div>
          </div>
          <div className={classes.DateTimeInput}>
            <TextField
              key="endDate"
              autoComplete="off"
              type="text"
              value={endDateState ? endDateState.toLocaleDateString() : ''}
              classes={{ root: classes.input, input: classes.input }}
              disabled
              disableUnderline={false}
              inputProps={{ 'aria-label': 'description' }}
              InputLabelProps={{ shrink: true }}
              label={
                <>
                  <span className={classes.blueLabel}>To</span>
                  {endDateMandatory && <span className="mandatory">&nbsp;*</span>}
                </>
              }
            />
            <div className={classes.timeInput}>
              <SlimSelectStandard
                attributeKey="endTime"
                defaultValue={endDateState}
                disabled={disableEndDate}
                visibleTimeSlot={(option, attrKey) => {
                  return handleTimeSlot(option, attrKey, item);
                }}
                onChange={(option, attrKey) => {
                  handleTimeChange(option, attrKey, item);
                }}
              />
            </div>
          </div>
          <div className={classes.buttonDiv}>
            <Button
              color="primary"
              size="medium"
              variant="outlined"
              disableElevation
              className={classes.button}
              onClick={() => handleClose('close')}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              size="medium"
              variant="contained"
              disableElevation
              className={classes.button}
              onClick={() => {
                handleDateChangeProp(startDateState, endDateState, item);
                handleClose();
              }}
            >
              Apply
            </Button>
          </div>
        </div>
      </Popover>
    </div>
  );
};
SaviyntPopoverDateRangePicker.defaultProps = {
  startDateMandatory: false,
  endDateMandatory: false,
  item: '',
  size: 'small',
  minStartDate: new Date(),
  maxStartDate: new Date('2100-01-01'), // Max Date should be coming from CONFIG
  maxEndDate: new Date('2100-01-01'), // Max Date should be coming from CONFIG
  type: 'default', // Added temporary flag, will be removed later.
  isDisabledTransparentColor: false,
};

SaviyntPopoverDateRangePicker.propTypes = {
  startDate: PropTypes.instanceOf(Object).isRequired,
  endDate: PropTypes.instanceOf(Object).isRequired,
  startDateMandatory: PropTypes.bool,
  endDateMandatory: PropTypes.bool,
  handleDateChangeProp: PropTypes.func.isRequired,
  item: PropTypes.string,
  size: PropTypes.string,
  disableStartDate: PropTypes.bool.isRequired,
  disableEndDate: PropTypes.bool.isRequired,
  defaultTimeFrameHRS: PropTypes.bool.isRequired,
  minStartDate: PropTypes.instanceOf(Date),
  maxStartDate: PropTypes.instanceOf(Date),
  maxEndDate: PropTypes.instanceOf(Date),
  type: PropTypes.string,
  isDisabledTransparentColor: PropTypes.bool,
};
export default SaviyntPopoverDateRangePicker;
