import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ChromePicker } from 'react-color';
import { Manager, Reference, Popper } from 'react-popper';
import './Colourpicker.scss';

const ColorPicker = ({ children, colour, handleColourChange, callBack, edit }) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const popperRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popperRef.current && !popperRef.current.contains(event.target)) {
        setDisplayColorPicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleToggle = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  useEffect(() => {
    if (callBack) callBack(displayColorPicker);
  }, [displayColorPicker]);

  return (
    <Manager>
      <Reference>
        {({ ref }) => React.cloneElement(children, { onClick: handleToggle, ref })}
      </Reference>
      {displayColorPicker && (
        <Popper innerRef={popperRef} placement="left">
          {({ ref, style, placement }) => (
            <div
              ref={ref}
              style={{
                ...style,
                position: 'fixed',
                right: '30px',
              }}
              className={edit ? 'colourPickerPopperEdit' : 'colourPickerPopper'}
              data-placement={placement}
            >
              <ChromePicker color={colour} onChange={(col) => handleColourChange(col)} />
            </div>
          )}
        </Popper>
      )}
    </Manager>
  );
};

ColorPicker.propTypes = {
  children: PropTypes.element.isRequired,
  colour: PropTypes.string.isRequired,
  handleColourChange: PropTypes.func.isRequired,
  callBack: PropTypes.func.isRequired,
  edit: PropTypes.bool.isRequired,
};

export default ColorPicker;
