import React, { useState, Suspense, useEffect } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Loader } from 'ui-components/src/components/Loader';
import styles from './Logs.module.css';
import Title from './components/Title/Title';
import {
  getResults, getSelectedServices, initState, updateQuery, updateSelection,
} from '../actions/logsAction';
import SearchBox from './components/SearchBox/SearchBox';
import SearchResults from './components/SearchResults/SearchResults';
import Filter from './components/Filter/Filter';
import Chips from './components/Chip/Chips';
import FilterInfo from './components/FilterInfo/FilterInfo';
import Download from './components/Download/Download';
import ErrorBoundary from '../../ErrorBoundry';
import useQuery from '../utils/useQueryHook';
import isTrue from '../utils/isTrue';
import { changePageTitle } from 'ui-common/src/lib/utils';
import { injectIntl } from 'react-intl';

const Logs = ({intl}) => {
  const history = useHistory();
  const queryParam = useQuery();
  const dispatch = useDispatch();
  const query = useSelector((state) => state.admin.logs.query);
  const selection = useSelector((state) => state.admin.logs.selection);
  const { AUTHMS_ENABLED, PAM_SERVICES_ENABLED, TASKRUNNERMS_ENABLED } = useSelector((state) => state.user.domainInfo);
  const [isDisplayed, setIsDisplayed] = useState(false);
  const titlePosition = isDisplayed ? 'left' : 'center';

  const containerClasses = classnames(isDisplayed ? styles.resultsContainer : styles.defaultContainer);
  const searchBoxClasses = classnames(
    styles.searchBoxContainer,
    !isDisplayed && styles.searchBoxContainerBox,
  );
  const contentClasses = classnames(isDisplayed ? styles.content : styles.defaultContent);

  const handleGetResults = (q) => {
    setIsDisplayed(true);
    dispatch(getResults(q));
  };

  const handleUpdateQuery = (payload, passthrough = true) => {
    dispatch(updateQuery(payload));

    if (queryParam.get('fromDate') && passthrough) {
      history.push('/logs');
    }
  };

  const handleUpdateSelection = (payload) => {
    dispatch(updateSelection(payload));
  };

  useEffect(() => {
    const label = changePageTitle(intl.formatMessage({ id: 'Admin.Log.Viewer.title' }));
    dispatch(initState(AUTHMS_ENABLED, PAM_SERVICES_ENABLED,TASKRUNNERMS_ENABLED, isTrue(queryParam.get('advanced'))));

    if (queryParam.get('fromDate') && queryParam.get('toDate')) {
      const serviceNames = queryParam.get('servicename')?.split(',').filter((f) => f);
      const searchQuery = {
        ...query,
        fromDate: moment(queryParam.get('fromDate')),
        toDate: moment(queryParam.get('toDate')),
        searchKey: queryParam.get('searchKey'),
        servicename: serviceNames.length > 0 ? serviceNames : query.servicename,
      };
      const selected = {
        ...selection,
        fromDate: moment(queryParam.get('fromDate')),
        toDate: moment(queryParam.get('toDate')),
      };

      handleGetResults(searchQuery);
      // handleGetResults({ ...searchQuery, searchAfter: queryParam.get('searchAfter') });
      handleUpdateQuery(searchQuery, false);
      handleUpdateSelection(selected);

      if (serviceNames.length > 0) {
        dispatch(getSelectedServices(serviceNames));
      }
    }
  }, []);

  return (
    <Suspense key="UI-Admin" fallback={<Loader />}>
      <div className={containerClasses}>
        <Title position={titlePosition} />
        <div className={contentClasses}>
          <div className={searchBoxClasses}>
            <SearchBox
              handleUpdateQuery={handleUpdateQuery}
              handleGetResults={handleGetResults}
              handleUpdateSelection={handleUpdateSelection}
            />
            <Filter isDisplayed={isDisplayed} />
            <Download isDisplayed={isDisplayed} />
          </div>
          <FilterInfo isDisplayed={isDisplayed}>
            <Chips isDisplayed={isDisplayed} />
          </FilterInfo>
        </div>
        <ErrorBoundary>
          <SearchResults isDisplayed={isDisplayed} />
        </ErrorBoundary>
      </div>
    </Suspense>
  );
};

export default injectIntl(Logs);
