import React from 'react';
import { VariableSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckIcon from '@material-ui/icons/Check';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';

const OptionsWrapper = ({
  hasNextPage,
  isNextPageLoading,
  items,
  loadNextPage,
  wrapperClassName,
  getOptionProps,
  groupedOptions,
  listboxProps,
  getLabel,
  menuStyle,
  noOptionsText,
  position,
  hideCheckMark,
  halfWidth,
  itemHeight,
  searchText,
  module,
  totalCount,
  getCalculatedHeight,
  descriptionType
}) => {
  const itemCount = hasNextPage ? items.length + 1 : items.length;
  const loadMoreItems = isNextPageLoading ? () => { } : loadNextPage;
  const isItemLoaded = index => !hasNextPage || index < items.length;

  const itemSize = index => {
    if (getCalculatedHeight) return getCalculatedHeight(index, groupedOptions, getLabel);
    if (halfWidth) return 50;
    if (itemHeight) return itemHeight;
    return 30;
  };


  // Render an item or a loading indicator.
  const Item = ({ index, style }) => {
    if (!isItemLoaded(index)) {
      if (
        module === 'grid' &&
        (totalCount < 25 ||
          (searchText && (searchText.length === 1 || searchText.length === 2)) ||
          totalCount === index + 1)
      )
        return null;
      return (
        <li style={style}>
          <div className="d-flex justify-center">
            <CircularProgress size={20} />
          </div>
        </li>
      );
    }
    if (!groupedOptions || index >= groupedOptions.length) {
      return <li style={style} />;
    }
    const option = groupedOptions[index];
    return (
      <li
        style={{
          ...style,
          display: 'flex',
          alignSelf: 'center',
          alignItems: 'center',
        }}
        {...getOptionProps({ option, index })}
      >
        <span>
          {option.description ? (<>
            { descriptionType === 'inlinedescription' ? <div className="d-block w-100"> {getLabel(option)}: {option.description}</div> :
            <Tooltip title={option.description} placement="top" arrow>
              <div className="d-block w-100"> {getLabel(option)}</div>
            </Tooltip>}
            </>) : (
            getLabel(option) || noOptionsText || 'no options'
          )}
        </span>
        {!hideCheckMark && (
          <span className="checked">
            <CheckIcon fontSize="small" />
          </span>
        )}
      </li>
    );
  };

  return (
    <InfiniteLoader isItemLoaded={isItemLoaded} itemCount={itemCount} loadMoreItems={loadMoreItems}>
      {({ onItemsRendered, ref }) => (
        <div
          style={{ position: position || 'absolute', zIndex: 11112, ...menuStyle }}
          {...listboxProps}
        >
          <List
            className={wrapperClassName}
            height={150}
            itemCount={itemCount}
            itemSize={itemSize}
            onItemsRendered={onItemsRendered}
            ref={ref}
            width={300}
            innerElementType="ul"
          >
            {Item}
          </List>
        </div>
      )}
    </InfiniteLoader>
  );
};

OptionsWrapper.defaultProps = {
  descriptionType : null,
};

OptionsWrapper.propTypes = {
  wrapperClassName: PropTypes.string.isRequired,
  loadNextPage: PropTypes.bool.isRequired,
  hasNextPage: PropTypes.bool.isRequired,
  isNextPageLoading: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf({
    ID: PropTypes.string.isRequired,
  }).isRequired,
  getOptionProps: PropTypes.shape({}).isRequired,
  groupedOptions: PropTypes.arrayOf({}).isRequired,
  listboxProps: PropTypes.shape({}).isRequired,
  getLabel: PropTypes.func.isRequired,
  menuStyle: PropTypes.shape({}).isRequired,
  descriptionType: PropTypes.string,
};

export default OptionsWrapper;
