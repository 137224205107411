import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { ListItem, List, Menu } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import MenuItemComponent from './MenuItemComponent';
import CustomMenuItemComponent from './CustomMenuItemComponent';
import { ButtonIcon, Icon } from '@saviynt/design-system';
import '../Header/Header.module.css';
import { Logger } from '@saviynt/common';
import Overlay from './Overlay';

export const HelperMenuIcon = () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15 9.79167C13.744 9.79167 12.8096 10.7233 12.8096 11.773C12.8096 12.1182 12.5297 12.398 12.1846 12.398C11.8394 12.398 11.5596 12.1182 11.5596 11.773C11.5596 9.94384 13.1462 8.54167 15 8.54167C16.8539 8.54167 18.4405 9.94384 18.4405 11.773C18.4405 12.7252 18.0026 13.572 17.3249 14.1555L17.2989 14.1779C16.8607 14.5552 16.4389 14.9182 16.1174 15.3155C15.7938 15.7154 15.625 16.0869 15.625 16.4627V17.1695C15.625 17.5147 15.3452 17.7945 15 17.7945C14.6549 17.7945 14.375 17.5147 14.375 17.1695V16.4627C14.375 15.6879 14.7288 15.0444 15.1458 14.5292C15.5399 14.0422 16.0377 13.6139 16.4514 13.2581L16.5093 13.2082C16.937 12.84 17.1905 12.3286 17.1905 11.773C17.1905 10.7233 16.2561 9.79167 15 9.79167Z"
      fill="#212328"
    />
    <path
      d="M15 20.8333C15.5184 20.8333 15.9385 20.4132 15.9385 19.8948C15.9385 19.3765 15.5184 18.9563 15 18.9563C14.4817 18.9563 14.0615 19.3765 14.0615 19.8948C14.0615 20.4132 14.4817 20.8333 15 20.8333Z"
      fill="#212328"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.75 15C3.75 8.7868 8.7868 3.75 15 3.75C21.2132 3.75 26.25 8.7868 26.25 15C26.25 21.2132 21.2132 26.25 15 26.25C8.7868 26.25 3.75 21.2132 3.75 15ZM15 5C9.47715 5 5 9.47715 5 15C5 20.5228 9.47715 25 15 25C20.5228 25 25 20.5228 25 15C25 9.47715 20.5228 5 15 5Z"
      fill="#212328"
    />
  </svg>
);
const useStyles = makeStyles(() => ({
  list: {
    margin: '0',
    padding: '0',
    marginRight: '6px',
  },
  paper: {
    background: '#FFFFFF',
    boxShadow: '0px 2px 16px 2px rgba(17, 21, 48, 0.08)',
    borderRadius: '3px',
    width: '19.625rem',
    padding: '0.75rem',
    marginTop: '1.25rem',
  },
  helper: {
    padding: 0,
    borderRadius: '100%',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

const DISCOVERY_CENTER_ID = 'discovery-center';

const SimpleListMenu = () => {
  const anchorRef = useRef(null);
  const { helpDetails } = useSelector((state) => state.profile);
  const { pendoInfo } = useSelector((state) => state.user);
  const [isResourceCenterOpen, setIsResourceCenterOpen] = useState(false);
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);

  //  Menu List items
  const options = [
    {
      id: 5,
      label: 'Support Resources',
      children: [
        {
          id: 6,
          label: 'Help Guide',
          icon: true,
          url: helpDetails.helpGuideUrl,
          isEnabled: helpDetails.isHelpEnabled,
          className: 'HelperMenu-guide',
        },
        {
          id: DISCOVERY_CENTER_ID,
          label: 'Discovery Center',
          icon: true,
          url: '/discovery-center',
          isEnabled: pendoInfo?.apiKey !== null,
          className: 'HelperMenu-discoveryCenter',
          showInfoIcon: true,
          showChip: true,
        },
        {
          id: 7,
          label: 'Documentation',
          icon: true,
          url: helpDetails.docUrl,
          isEnabled: helpDetails.isDocEnabled,
          className: 'HelperMenu-documentation',
        },
      ],
    },
  ];

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const open = Boolean(anchorEl);

  const classes = useStyles(open);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const launchResourceCenter = () => {
    Logger.log('Attempting to launch Discovery Center');
    if (window.pendo?.BuildingBlocks?.BuildingBlockResourceCenter) {
      window.pendo.BuildingBlocks.BuildingBlockResourceCenter.getResourceCenter().show();
      setIsResourceCenterOpen(true);
      setIsOverlayOpen(true);
      Logger.log('Discovery center is open');
    } else {
      Logger.error('Discovery Center is not available');
    }
  };

  const dismissResourceCenter = () => {
    if (window.pendo?.BuildingBlocks?.BuildingBlockResourceCenter) {
      window.pendo.BuildingBlocks.BuildingBlockResourceCenter.dismissResourceCenter();
      setIsResourceCenterOpen(false);
      setIsOverlayOpen(false);
      Logger.log('Discovery center is closed');
    } else {
      Logger.error('Discovery Center is not available');
    }
  };

  const handleOutsideClick = (event) => {
    if (
      isResourceCenterOpen &&
      (event.target.closest('._pendo-resource-center-close-button') ||
        !event.target.closest('#pendo-resource-center-container'))
    ) {
      dismissResourceCenter();
    }
  };

  const handleMenuItemClick = (event, index, url) => {
    event.preventDefault();
    event.stopPropagation();
    if (index === DISCOVERY_CENTER_ID) {
      launchResourceCenter();
    } else {
      window.open(url, '_blank');
    }
    setSelectedIndex(null);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsOverlayOpen(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isResourceCenterOpen]);

  return (
    <>
      <Overlay isOpen={isOverlayOpen} onClose={handleClose} />
      <List component="nav" ref={anchorRef} className={classes.list}>
        <ListItem
          disableRipple
          button
          id="lock-button"
          aria-haspopup="listbox"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClickListItem}
          className={classes.helper}
        >
          <ButtonIcon
            size="large"
            kind="ghost"
            isSelected={open}
            icon={<Icon size="large" kind="HelpOutlined" />}
          />
        </ListItem>
      </List>

      <Menu
        id="helper-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        classes={{
          paper: classes.paper,
        }}
      >
        {options.map((item) => (
          <>
            <CustomMenuItemComponent item={item} />
            {item.children
              ? item.children.map(
                  (option) =>
                    option.isEnabled && (
                      <MenuItemComponent
                        key={option.id}
                        selectedIndex={selectedIndex}
                        handleMenuItemClick={handleMenuItemClick}
                        option={option}
                      />
                    )
                )
              : null}
          </>
        ))}
      </Menu>
    </>
  );
};

export default injectIntl(SimpleListMenu);
