import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import styles from './SearchBox.module.css';
import QuickSearch from '../QuickSearch/QuickSearch';
import Input from '../Input/Input';
import { defaultDateTimeQuery } from '../../../../constant';

const SearchBox = ({
  handleGetResults, handleUpdateQuery, handleUpdateSelection, intl,
}) => {
  const query = useSelector((state) => state.admin.logs.query);
  const selection = useSelector((state) => state.admin.logs.selection);
  const [searchValue, setSearchValue] = useState('');
  const defaultQuery = defaultDateTimeQuery(intl);
  const [searchText, setSearchText] = useState(
    {
      val: defaultQuery.value,
      dur: defaultQuery.duration,
      labelValue: intl.formatMessage({ id: 'Admin.Log.Viewer.last' }),
    },
  );
  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  useEffect(() => {
    if (query.searchKey !== searchValue) {
      setSearchValue(query.searchKey);
    }
  }, [query]);

  const handleSubmit = (e) => {
    e.preventDefault();
    handleGetResults({ ...query, searchKey: searchValue });
  };

  const handleSearchClick = (e) => {
    e.preventDefault();

    if (searchText.val !== '' && searchText.dur !== '') {
      // Val is 0 for today
      const daysToSubtract = searchText.val === 0 ? 1 : searchText.val;
      const calculatedFromDate = moment().subtract(daysToSubtract, searchText.dur);
      const calculatedToDate = moment();

      handleUpdateSelection(Object.assign(selection, {
        fromDate: calculatedFromDate,
        toDate: calculatedToDate,
      }));
      handleUpdateQuery(Object.assign(query, {
        searchKey: searchValue,
        fromDate: calculatedFromDate,
        toDate: calculatedToDate,
      }));
    } else {
      handleUpdateQuery(Object.assign(query, {
        searchKey: searchValue,
        fromDate: selection.fromDate,
        toDate: selection.toDate,
      }));
    }

    handleGetResults({ ...query, searchKey: searchValue });
  };

  return (
    <form data-testid="form" onSubmit={handleSubmit} className={styles.container}>
      <Input
        data-testid="searchValue"
        placeholder={intl.formatMessage({ id: 'MANAGE.LOGS.SEARCH.KEYWORD.LABEL' })}
        className={styles.gridItemAuto}
        value={searchValue}
        onChange={handleInputChange}
        startAdornment={(
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        )}
      />
      <QuickSearch
        className={styles.gridItemSpan4}
        handleUpdateSelection={handleUpdateSelection}
        intl={intl}
        searchText={searchText}
        setSearchText={setSearchText}
      />
      <Button
        variant="contained"
        color="primary"
        type="submit"
        className={styles.searchButton}
        onClick={handleSearchClick}
      >
        {intl.formatMessage({ id: 'MANAGE.LOGS.SEARCH.BUTTON.LABEL' })}
      </Button>
    </form>
  );
};

export default injectIntl(SearchBox);
