export const TABS = {
  ACCOUNT: 'Account',
  ASSET: 'Asset',
  SCHEDULE: 'Schedule',
  SESSION: 'Session',
  ROLE: 'Role',
  ENTITLEMENTS: 'Entitlements',
};

export const TITLES = {
  SUMMARY: 'Summary',
  SYSTEM_DETAILS: 'System Details',
  TIME: 'Time',
  ACCOUNT: 'Account',
  REQUEST_DETAILS: 'Request Details',
  COMMENTS: 'Comments',
  ATTACHMENTS: 'Attachments',
  TARGET_SYSTEM: 'Target System',
  REMOTE_APPLICATION: 'Remote Application',
  SAP_TRANSACTION_CODES: 'SAP Transaction Codes',
  CUSTOM_PROPERTIES: 'Custom Properties',
  SECURITY_RANKINGS: 'Security Rankings',
};

export const TYPES = {
  ACCOUNT: 'account',
  SESSION: 'session',
  ENDPOINT: 'endpoint',
  ROLE: 'role',
};

export const ASSET_TYPES = {
  ACCOUNT: 'Account',
  APPLICATION: 'Application',
  DATABASE: 'Database',
  SERVER: 'Server',
  CONSOLE: 'Console',
  ROLE: 'Role',
};

export const CLOUD_PROVIDERS = {
  AWS: 'AWS',
  AZURE: 'AZURE',
  GCP: 'GCP',
};

export const PLATFORMS_LABELS = {
  AWS_ACCOUNT: 'AWS Account',
  AZURE_SUBSCRIPTION: 'Azure Subscription',
  GCP_PROJECT: 'GCP Project',
};

export const TENANTS_LABELS = {
  TENANT_AWS: 'Tenant (AWS Account)',
  TENANT_AZURE: 'Tenant (Azure Account)',
  TENANT_GCP: 'Tenant (GCP Account)',
};

export const BUTTON_LABELS = {
  CHECK_IN: 'Check In',
  EXTEND: 'Extend',
  // TODO: Remove after getting requirements for Credentialless
  CHECKOUT_CREDENTIALS: 'Check Out Credential',
  CONNECT_TO_SESSION: 'Connect to Session',
  CANCEL_REQUEST: 'Cancel Request',
  END: 'End',
  CANCEL_SESSION: 'Cancel Session',
  LAUNCH_SESSION: 'Launch Session',
  EXTEND_SESSION: 'Extend Session',
  END_SESSION: 'End Session',
  CHECKOUT_ROLE: 'Check Out Role',
  SHOW_ALL: 'Show All',
  VIEW_DETAILS: 'View Details',
  CANCEL: 'Cancel',
};

export const LABELS = {
  NAME: 'Name',
  PLATFORM: 'Platform',
  DESCRIPTION: 'Description',
  LOCATION: 'Location',
  ACCOUNT_TYPE: 'Account Type',
  AUTHENTICATION_MECHANISM: 'Authentication Mechanism',
  ID: 'ID',
  OPERATING_SYSTEM: 'Operating System',
  ENVIRONMENT: 'Environment',
  NETWORK: 'Network',
  DATABASE_NAME: 'Database Name',
  INSTANCE: 'Instance',
  INSTANCE_OS: 'Instance (OS)',
  RDS_TYPE: 'RDS Type',
  PUBLIC_IP: 'Public IP',
  PRIVATE_IP: 'Private IP',
  START_TIME: 'Start Time',
  END_TIME: 'End Time',
  REQUEST_STATUS: 'Request Status',
  REQUEST_ID: 'Request ID',
  REQUEST_TIME: 'Request Time',
  ASSIGNED_TO: 'Assigned to',
  TICKET_NUMBER: 'Ticket Number',
  JUSTIFICATION: 'Justification',
  T_CODES_SELECTED: 'T-Codes Selected',
  APPROVED_BY: 'Approved By',
  APPROVAL_TIME: 'Approval Time',
  TARGET_SERVER: 'Target Server',
  PORT_NUMBER: 'Port Number',
  DATABASE_TYPE: 'Database Type',
  IP_HOST: 'IP/HOST',
  RISK: 'Risk',
  SOX_CRITICAL: 'Sox Critical',
  SYS_CRITICAL: 'Sys Critical',
  PRIVILEGED: 'Privileged',
  CONFIDENTIALITY: 'Confidentiality',
  PENDING_APPROVAL: 'Pending Approval',
};

export const SECURITY_RANKINGS_LABELS = {
  VERY_LOW: 'Very Low',
  LOW: 'Low',
  MEDIUM: 'Medium',
  HIGH: 'High',
  CRITICAL: 'Critical',
};

export const PAM_PLATFORM_TYPES = {
  UNIX: 'unix',
  WINDOWS: 'windows',
  DB: 'db',
  AD_CONSOLE: 'adconsole',
  GCP_CONSOLE: 'gcpconsole',
};
export const PAM_PLATFORM_BY_CONSOLE = {
  AD_CONSOLE: 'adconsole',
  GCP_CONSOLE: 'gcpconsole',
};

export const msgs = {
  pam: {
    sidePanel: {
      // Buttons
      checkoutCredential: {
        id: 'pam.sidePanel.checkoutCredential',
        defaultMessage: BUTTON_LABELS.CHECKOUT_CREDENTIALS,
      },
      connectToSession: {
        id: 'pam.sidePanel.connectToSession',
        defaultMessage: BUTTON_LABELS.CONNECT_TO_SESSION,
      },
      checkIn: {
        id: 'pam.sidePanel.checkIn',
        defaultMessage: BUTTON_LABELS.CHECK_IN,
      },
      end: {
        id: 'pam.sidePanel.end',
        defaultMessage: BUTTON_LABELS.END,
      },
      extend: {
        id: 'pam.sidePanel.extend',
        defaultMessage: BUTTON_LABELS.EXTEND,
      },
      cancelRequest: {
        id: 'pam.sidePanel.cancelRequest',
        defaultMessage: BUTTON_LABELS.CANCEL_REQUEST,
      },
      cancelSession: {
        id: 'pam.sidePanel.cancelSession',
        defaultMessage: BUTTON_LABELS.CANCEL_SESSION,
      },
      launchSession: {
        id: 'pam.sidePanel.launchSession',
        defaultMessage: BUTTON_LABELS.LAUNCH_SESSION,
      },
      extendSession: {
        id: 'pam.sidePanel.extendSession',
        defaultMessage: BUTTON_LABELS.EXTEND_SESSION,
      },
      endSession: {
        id: 'pam.sidePanel.endSession',
        defaultMessage: BUTTON_LABELS.END_SESSION,
      },
      checkoutRole: {
        id: 'pam.sidePanel.checkoutRole',
        defaultMessage: BUTTON_LABELS.CHECKOUT_ROLE,
      },
      showAll: {
        id: 'pam.sidePanel.showAll',
        defaultMessage: BUTTON_LABELS.SHOW_ALL,
      },
      viewDetails: {
        id: 'pam.sidePanel.viewDetails',
        defaultMessage: BUTTON_LABELS.VIEW_DETAILS,
      },
      cancel: {
        id: 'pam.sidePanel.cancel',
        defaultMessage: BUTTON_LABELS.CANCEL,
      },

      // Assets
      server: {
        id: 'pam.sidePanel.server',
        defaultMessage: ASSET_TYPES.SERVER,
      },
      application: {
        id: 'pam.sidePanel.application',
        defaultMessage: ASSET_TYPES.APPLICATION,
      },
      database: {
        id: 'pam.sidePanel.database',
        defaultMessage: ASSET_TYPES.DATABASE,
      },

      // Tab Titles
      session: {
        id: 'pam.sidePanel.session',
        defaultMessage: TABS.SESSION,
      },
      asset: {
        id: 'pam.sidePanel.asset',
        defaultMessage: TABS.ASSET,
      },
      account: {
        id: 'pam.sidePanel.account',
        defaultMessage: TABS.ACCOUNT,
      },
      role: {
        id: 'pam.sidePanel.role',
        defaultMessage: TABS.ROLE,
      },
      entitlements: {
        id: 'pam.sidePanel.entitlements',
        defaultMessage: TABS.ENTITLEMENTS,
      },
      schedule: {
        id: 'pam.sidePanel.schedule',
        defaultMessage: TABS.SCHEDULE,
      },

      // Tab Headers
      summary: {
        id: 'pam.sidePanel.summary',
        defaultMessage: TITLES.SUMMARY,
      },
      systemDetails: {
        id: 'pam.sidePanel.systemDetails',
        defaultMessage: TITLES.SYSTEM_DETAILS,
      },
      time: {
        id: 'pam.sidePanel.time',
        defaultMessage: TITLES.TIME,
      },
      requestDetails: {
        id: 'pam.sidePanel.requestDetails',
        defaultMessage: TITLES.REQUEST_DETAILS,
      },
      comments: {
        id: 'pam.sidePanel.comments',
        defaultMessage: TITLES.COMMENTS,
      },
      attachments: {
        id: 'pam.sidePanel.attachments',
        defaultMessage: TITLES.ATTACHMENTS,
      },
      targetSystem: {
        id: 'pam.sidePanel.targetSystem',
        defaultMessage: TITLES.TARGET_SYSTEM,
      },
      remoteApplication: {
        id: 'pam.sidePanel.remoteApplication',
        defaultMessage: TITLES.REMOTE_APPLICATION,
      },
      sapTransactionCodes: {
        id: 'pam.sidePanel.sapTransactionCodes',
        defaultMessage: TITLES.SAP_TRANSACTION_CODES,
      },
      customProperties: {
        id: 'pam.sidePanel.customProperties',
        defaultMessage: TITLES.CUSTOM_PROPERTIES,
      },
      securityRankings: {
        id: 'pam.sidePanel.securityRankings',
        defaultMessage: TITLES.SECURITY_RANKINGS,
      },

      // Labels
      accountType: {
        id: 'pam.sidePanel.accountType',
        defaultMessage: LABELS.ACCOUNT_TYPE,
      },
      startTime: {
        id: 'pam.sidePanel.startTime',
        defaultMessage: LABELS.START_TIME,
      },
      endTime: {
        id: 'pam.sidePanel.endTime',
        defaultMessage: LABELS.END_TIME,
      },
      name: {
        id: 'pam.sidePanel.name',
        defaultMessage: LABELS.NAME,
      },
      platform: {
        id: 'pam.sidePanel.platform',
        defaultMessage: LABELS.PLATFORM,
      },
      location: {
        id: 'pam.sidePanel.location',
        defaultMessage: LABELS.LOCATION,
      },
      id: {
        id: 'pam.sidePanel.id',
        defaultMessage: LABELS.ID,
      },
      awsAccount: {
        id: 'pam.sidePanel.awsAccount',
        defaultMessage: PLATFORMS_LABELS.AWS_ACCOUNT,
      },
      gcpAccount: {
        id: 'pam.sidePanel.gcpAccount',
        defaultMessage: PLATFORMS_LABELS.GCP_PROJECT,
      },
      databaseName: {
        id: 'pam.sidePanel.databaseName',
        defaultMessage: LABELS.DATABASE_NAME,
      },
      rdsType: {
        id: 'pam.sidePanel.rdsType',
        defaultMessage: LABELS.RDS_TYPE,
      },
      operatingSystem: {
        id: 'pam.sidePanel.operatingSystem',
        defaultMessage: LABELS.OPERATING_SYSTEM,
      },
      environment: {
        id: 'pam.sidePanel.environment',
        defaultMessage: LABELS.ENVIRONMENT,
      },
      network: {
        id: 'pam.sidePanel.network',
        defaultMessage: LABELS.NETWORK,
      },
      publicIP: {
        id: 'pam.sidePanel.publicIP',
        defaultMessage: LABELS.PUBLIC_IP,
      },
      privateIP: {
        id: 'pam.sidePanel.privateIP',
        defaultMessage: LABELS.PRIVATE_IP,
      },
      description: {
        id: 'pam.sidePanel.description',
        defaultMessage: LABELS.DESCRIPTION,
      },
      authenticationMechanism: {
        id: 'pam.sidePanel.authenticationMechanism',
        defaultMessage: LABELS.AUTHENTICATION_MECHANISM,
      },
      requestStatus: {
        id: 'pam.sidePanel.requestStatus',
        defaultMessage: LABELS.REQUEST_STATUS,
      },
      requestId: {
        id: 'pam.sidePanel.requestId',
        defaultMessage: LABELS.REQUEST_ID,
      },
      requestTime: {
        id: 'pam.sidePanel.requestTime',
        defaultMessage: LABELS.REQUEST_TIME,
      },
      assignedTo: {
        id: 'pam.sidePanel.assignedTo',
        defaultMessage: LABELS.ASSIGNED_TO,
      },
      ticketNumber: {
        id: 'pam.sidePanel.ticketNumber',
        defaultMessage: LABELS.TICKET_NUMBER,
      },
      justification: {
        id: 'pam.sidePanel.justification',
        defaultMessage: LABELS.JUSTIFICATION,
      },
      tCodesSelected: {
        id: 'pam.sidePanel.tCodesSelected',
        defaultMessage: LABELS.T_CODES_SELECTED,
      },
      approvedBy: {
        id: 'pam.sidePanel.approvedBy',
        defaultMessage: LABELS.APPROVED_BY,
      },
      approvalTime: {
        id: 'pam.sidePanel.approvalTime',
        defaultMessage: LABELS.APPROVAL_TIME,
      },
      targetServer: {
        id: 'pam.sidePanel.targetServer',
        defaultMessage: LABELS.TARGET_SERVER,
      },
      portNumber: {
        id: 'pam.sidePanel.portNumber',
        defaultMessage: LABELS.PORT_NUMBER,
      },
      databaseType: {
        id: 'pam.sidePanel.databaseType',
        defaultMessage: LABELS.DATABASE_TYPE,
      },
      ipHost: {
        id: 'pam.sidePanel.ipHost',
        defaultMessage: LABELS.IP_HOST,
      },
      instanceOS: {
        id: 'pam.sidePanel.instanceOS',
        defaultMessage: LABELS.INSTANCE_OS,
      },
      pendingApproval: {
        id: 'pam.sidePanel.pendingApproval',
        defaultMessage: LABELS.PENDING_APPROVAL,
      },

      // Inline Messages
      assetDidntLoadError: {
        // when accounts api fails and footer buttons don't show.
        id: 'pam.sidePanel.assetDidntLoadError',
        defaultMessage:
          "This asset didn't load properly. Try again or contact your administrator.",
      },

      // Security Rankings Labels
      risk: {
        id: 'pam.sidePanel.risk',
        defaultMessage: LABELS.RISK,
      },
      soxCritical: {
        id: 'pam.sidePanel.soxCritical',
        defaultMessage: LABELS.SOX_CRITICAL,
      },
      sysCritical: {
        id: 'pam.sidePanel.sysCritical',
        defaultMessage: LABELS.SYS_CRITICAL,
      },
      privileged: {
        id: 'pam.sidePanel.privileged',
        defaultMessage: LABELS.PRIVILEGED,
      },
      confidentiality: {
        id: 'pam.sidePanel.confidentiality',
        defaultMessage: LABELS.CONFIDENTIALITY,
      },

      // Security Rankings
      securityRankingValues: {
        veryLow: {
          id: 'pam.sidePanel.veryLow',
          defaultMessage: SECURITY_RANKINGS_LABELS.VERY_LOW,
        },
        low: {
          id: 'pam.sidePanel.low',
          defaultMessage: SECURITY_RANKINGS_LABELS.LOW,
        },
        medium: {
          id: 'pam.sidePanel.medium',
          defaultMessage: SECURITY_RANKINGS_LABELS.MEDIUM,
        },
        high: {
          id: 'pam.sidePanel.high',
          defaultMessage: SECURITY_RANKINGS_LABELS.HIGH,
        },
        critical: {
          id: 'pam.sidePanel.critical',
          defaultMessage: SECURITY_RANKINGS_LABELS.CRITICAL,
        },
      },

      // Entitlements
      searchEntitlementsPlaceholder: {
        id: 'pam.landingPage.searchEntitlementsPlaceholder',
        defaultMessage: 'Search by entitlement name or description',
      },
      searchEntitlementsModalPlaceholder: {
        id: 'pam.landingPage.searchEntitlementsModalPlaceholder',
        defaultMessage:
          'Search by entitlement name, type, value, description, or app name',
      },
      noAccountsEntitlementsMessage: {
        id: 'pam.landingPage.noAccountsEntitlementsMessage',
        defaultMessage:
          "An account with the associated entitlements will be created once you're approved to check out this role. Only entitlements without an account will have one created.",
      },

      // Modal subheadings
      customPropertiesModalSubheading: {
        id: 'pam.sidePanel.customPropertiesModalSubheading',
        defaultMessage:
          'Review the unique properties included with this role that are specific to your organization and its policies.',
      },
      entitlementsModalSubheading: {
        id: 'pam.sidePanel.entitlementsModalSubheading',
        defaultMessage: 'View the entitlements you need within this role.',
      },
    },
  },
};

// TODO: remove once we have real Tcode data
export const mockTcodes = [
  'MBC3',
  'SPRO',
  'SPAD',
  'MBW1',
  'MM MM60',
  'MBC3',
  'SPRO',
  'SPAD',
  'MBW1',
  'MM MM60',
  'MBC3',
  'SPRO',
  'SPAD',
  'MBW1',
  'MM MM60',
  'MBC3',
  'SPRO',
  'SPAD',
  'MBW1',
  'MM MM60',
  'MBC3',
  'SPRO',
  'SPAD',
  'MBW1',
  'MM MM60',
  'MBC3',
  'SPRO',
  'SPAD',
  'MBW1',
  'MM MM60',
  'MBC3',
  'SPRO',
  'SPAD',
  'MBW1',
  'MM MM60',
  'MBC3',
  'SPRO',
  'SPAD',
  'MBW1',
  'MM MM60',
];
