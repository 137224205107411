import React, { useEffect, useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { map, throttle, cloneDeep } from 'lodash';
import useOnClickOutside from 'use-onclickoutside';
import {
  convertReduxToApi,
  getDistinctColumnValues,
  isClickedOnAutocomplete,
  isClickedOnFilterBox,
} from 'ui-common/src/lib/utils';
import { injectIntl } from 'react-intl';
import { getGridCell } from './gridutils';
import ColumnSortPanel from './components/ColumnSortPanel';
import PopoverDialog from './components/PopoverDialog';
import styles from './GridBoot.module.scss';
import { DateFilter, UniqueFieldFilter, StringFilter } from './filterpanels';
import { A } from '../A';
import { Icon } from '../Icon';
import PropTypes from 'prop-types';

const initialSoretedColumn = { order: 'asc' };
const Table = React.memo(
  ({
    id,
    tableRef,
    columns,
    gridData,
    setGridData,
    onRowSelected,
    appliedFilters,
    setAppliedFilters,
    reqBodyOptions,
    setReqBodyOptions,
    checkedFilters,
    setCheckedFilters,
    shownColumns,
    uniqueKey,
    classNames,
    isLoading,
    filterPanelHeight,
    topOffset,
    hideRearrangeColumn,
    displayColumns,
    setDisplayColumns,
    unCheckedColumns,
    setUnCheckedColumns,
    width,
    requestColCacheEnabled,
    isArchive,
    columnPaginationEnabled,
    columnHeaderAlignment,
    resetCheckBoxSelection,
    intl,
  }) => {
    const [sortedColumn, setSortedColumn] = useState(initialSoretedColumn);
    const [distinctColumnValues, setDistinctColumnValues] = useState({});
    const [filteredDistinctColumnValues, setFilteredDistinctColumnValues] = useState({});
    const [antdComponentOpen, setAntdComponentOpen] = useState(false); // Needed to check if we are clicking on antd component becos antd components are rendered always outside of react.
    const defaultShowcolumnfilter = { column: null, filterId: 'none' };
    const [showColumnFilter, setShowColumnFilter] = useState(defaultShowcolumnfilter);
    const [resultCount, setResultCount] = useState(-1);
    // const movingRef = useRef(null);
    if (tableRef) {
      useOnClickOutside(tableRef, e => {
        if (
          !antdComponentOpen &&
          !isClickedOnAutocomplete(e) &&
          !isClickedOnFilterBox(e) &&
          showColumnFilter.filterId !== 'none'
        )
          hideFilterPanels();
        //  setShowColumnFilter(defaultShowcolumnfilter);
      });
    }

    useEffect(() => {
      const closeFilterModalOnEsc = e => {
        if (e.keyCode === 27) {
          setShowColumnFilter(defaultShowcolumnfilter);
          hideFilterPanels();
        }
      };
      window.addEventListener('keydown', closeFilterModalOnEsc);
      return () => window.removeEventListener('keydown', closeFilterModalOnEsc);
    }, [defaultShowcolumnfilter]);

    useEffect(() => {
      if (resetCheckBoxSelection) {
        selectAll(false);
      }
    }, [resetCheckBoxSelection]);

    const defaultFilterOffsets = { xOffset: -20, yOffset: 30 };

    const addHoverClass = index => {
      const hoveredrows = document.querySelectorAll(`.row${index}`);
      hoveredrows.forEach(r => r.classList.add('hovered'));
    };
    const removeHoverClass = index => {
      const hoveredrows = document.querySelectorAll(`.row${index}`);
      hoveredrows.forEach(r => r.classList.remove('hovered'));
    };

    const getMappedKey = key => {
      if (
        key === 'requestedFor' &&
        ((appliedFilters[key] && appliedFilters[key].length) ||
          (appliedFilters.requestedForUserAttrs &&
            Object.keys(appliedFilters.requestedForUserAttrs).length))
      ) {
        return true;
      }

      if (
        key === 'requestor' &&
        ((appliedFilters[key] && appliedFilters[key].length) ||
          (appliedFilters.requestorUserAttrs &&
            Object.keys(appliedFilters.requestorUserAttrs).length))
      ) {
        return true;
      }

      if (appliedFilters[key] && appliedFilters[key].length) return true;

      return false;
    };

    const moveToViewArea = e => {
      if (
        e.target.getBoundingClientRect().x >
        document.querySelector('.fixedTable2').getBoundingClientRect().x
      ) {
        document.querySelector('.movingTable').scrollTo(500, 0);
      }
    };

    const openFilterModel = (e, column) => {
      e.preventDefault();
      const isActiveLink =
        e.target.getAttribute('tabindex') === '1' ||
        e.target.parentNode.getAttribute('tabindex') === '1' ||
        e.target.parentNode.parentNode.getAttribute('tabindex') === '1' ||
        e.target.parentNode.parentNode.parentNode.getAttribute('tabindex') === '1';
      const { xOffset, yOffset } = column.filterPopPosition || defaultFilterOffsets;
      const filterColumn = {
        column,
        isActiveLink,
        filterId: column.key,
        position: {
          top: e.target.getBoundingClientRect().top + yOffset,
          left: e.target.getBoundingClientRect().left + xOffset,
        },
        isLoading: true,
      };
      if (column.filter.type !== 'date') {
        showDistinctColumnValues(filterColumn);
      }
      setShowColumnFilter(filterColumn);
    };

    const getFilterAnchor = ({ column, tableId, colIndex }) => {
      let tabindex = -1;
      let tabbable = false;
      if (tableId === 'fixedTable' && column.isFixedColumn && colIndex < 4) {
        tabbable = true;
        tabindex = 1;
      } // upto max 4 fixed columns on either side is supported.
      if (tableId === 'fixedTable2' && column.isFixedColumn && colIndex < 4) {
        tabbable = true;
        tabindex = 1;
      }
      if (!column.isFixedColumn && tableId === 'movingTable') {
        tabbable = true;
        tabindex = 1;
      }
      // if (column.isFixedColumn && colIndex < 4) tabbable = true;
      if (
        column.isFixedColumn &&
        colIndex < 4 &&
        (tableId === 'fixedTable' || tableId === 'fixedTable transparentTable')
      )
        tabbable = true;
      if (
        column.isFixedColumn &&
        colIndex > 6 &&
        (tableId === 'fixedTable2' || tableId === 'fixedTable transparentTable')
      )
        tabbable = true;
      if (
        !column.isFixedColumn &&
        (tableId === 'movingTable' || tableId === 'movingTable transparentTable')
      )
        tabbable = true;
      const tabbableClass = tabbable ? 'tabbable' : '';

      return (
        <A
          href="#"
          tabindex={tabindex}
          className={`filter_dropdown ${tabbableClass} anchor-${column.key}
        ${
          (appliedFilters[column.key] && appliedFilters[column.key].length > 0) ||
          sortedColumn.columnName === column.key
            ? 'active'
            : ''
        }`}
          // onClick={openFilterModel}
          onClick={e => openFilterModel(e, column)}
          onFocus={moveToViewArea}
        >
          <Icon name="filter" className={getMappedKey(column.key) ? 'selected-filter' : ''} />
        </A>
      );
    };
    const getFilterUI = ({ column }) => {
      if (!column) return <div />;
      if (column.filter.type === 'string') {
        return (
          <StringFilter
            column={column}
            tableId={id}
            appliedFilters={appliedFilters}
            setAppliedFilters={setAppliedFilters}
            checkedFilters={checkedFilters}
            setCheckedFilters={setCheckedFilters}
            distinctColumnValues={distinctColumnValues}
            sortedColumn={sortedColumn}
            setSortedColumn={setSortedColumn}
            showColumnFilter={showColumnFilter}
            setShowColumnFilter={setShowColumnFilter}
            filteredDistinctColumnValues={filteredDistinctColumnValues}
            setFilteredDistinctColumnValues={setFilteredDistinctColumnValues}
            requestColCacheEnabled={requestColCacheEnabled}
          />
        );
      }
      if (column.filter.type === 'unique') {
        return (
          <UniqueFieldFilter
            column={column}
            tableId={id}
            appliedFilters={appliedFilters}
            setAppliedFilters={setAppliedFilters}
            checkedFilters={checkedFilters}
            setCheckedFilters={setCheckedFilters}
            distinctColumnValues={distinctColumnValues}
            sortedColumn={sortedColumn}
            setSortedColumn={setSortedColumn}
            showColumnFilter={showColumnFilter}
            setShowColumnFilter={setShowColumnFilter}
            reqBodyOptions={reqBodyOptions}
            isArchive={isArchive}
            resultCount={resultCount}
            columnPaginationEnabled={columnPaginationEnabled}
          />
        );
      }
      if (column.filter.type === 'date') {
        return (
          <DateFilter
            column={column}
            tableId={id}
            checkedFilters={checkedFilters}
            setCheckedFilters={setCheckedFilters}
            showColumnFilter={showColumnFilter}
            setShowColumnFilter={setShowColumnFilter}
            appliedFilters={appliedFilters}
            setAppliedFilters={setAppliedFilters}
            setAntdComponentOpen={setAntdComponentOpen}
            antdComponentOpen={antdComponentOpen}
            sortedColumn={sortedColumn}
            setSortedColumn={setSortedColumn}
          />
        );
      }
      return TypeError('filter type should be "date" | "unique" | "string"');
    };

    const getTableBody = ({ gridData, shownColumns }) =>
      map(gridData, (row, index) => (
        <div
        className={`divTableRow ${ row.italic ? "styleItalic" : ""} row${index} ${row.isError ? 'row-error' : ''}`}
          key={row[uniqueKey]}
          onMouseOver={() => addHoverClass(index)}
          onMouseLeave={() => removeHoverClass(index)}
        >
          {uniqueKey && (
            <div className="divTableCell customCheck" key={row[uniqueKey]}>
              <input
                type="checkbox"
                className={styles.tableBodyCheckBox}
                tabIndex={id === 'fixedTable' ? index + 2 : -1}
                id={row[uniqueKey] + id}
                aria-label={row[uniqueKey] + id}
                onClick={e => selectRow(e.target.checked, row)}
                checked={row.checked}
                value={row.checked}
                disabled={false}
              />
              {/* <label htmlFor={row[uniqueKey] + id} /> */}
            </div>
          )}
          {shownColumns.map((column, cIndex) => (
            <div
              className={`divTableCell ${column.key} ${column.isFixedColumn ? 'fixedColumn' : ''}`}
            >
              {column.width ? (
                <div
                  style={{
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-word',
                    width: column.width,
                  }}
                  
                >
                  {getGridCell({ row, column, tableId: id, dataIndex: index, columnIndex: cIndex, intl })}
                </div>
              ) : (
                <>
                  {getGridCell({ row, column, tableId: id, dataIndex: index, columnIndex: cIndex, intl })}
                </>
              )}
            </div>
          ))}
        </div>
      ));
    const showDistinctColumnValues = filterColumn => {
      const { column } = filterColumn;
      getDistinctColumnValues(
        column,
        appliedFilters,
        reqBodyOptions,
        requestColCacheEnabled,
        isArchive,
        columnPaginationEnabled
      ).then(response => {
        let distinctValues = response;
        if (columnPaginationEnabled) {
          if (column.filter && column.filter.type === 'unique')
            setResultCount(distinctValues.count);
          distinctValues = distinctValues.data;
          if (distinctValues && distinctValues['']) delete distinctValues[''];
        }
        setShowColumnFilter(prevState => ({ ...prevState, isLoading: false }));
        const { key } = column;
        setDistinctColumnValues({
          ...distinctColumnValues,
          [key]:
            column.filter && column.filter.filterFn
              ? column.filter.filterFn(distinctValues)
              : distinctValues,
        });
        setFilteredDistinctColumnValues({
          ...distinctColumnValues,
          [key]:
            column.filter && column.filter.filterFn
              ? column.filter.filterFn(distinctValues)
              : distinctValues,
        });
      });
    };
    const selectRow = (checked, rowData) => {
      let newData;
      if (checked) {
        newData = gridData.map(row => {
          if (rowData[uniqueKey] === row[uniqueKey]) {
            return { ...row, checked: true };
          }
          return row;
        });
        setGridData(newData);
      } else {
        newData = gridData.map(row => {
          if (rowData[uniqueKey] === row[uniqueKey]) {
            return { ...row, checked: false };
          }
          return row;
        });
        setGridData(newData);
      }
      onRowSelected(cloneDeep(newData.filter(row => row.checked)));
    };
    const selectAll = checked => {
      let newData;
      if (checked) {
        newData = gridData.map(row => (row.disabled ? row : { ...row, checked: true }));
        setGridData(cloneDeep(newData));
      } else {
        newData = gridData.map(row => (row.disabled ? row : { ...row, checked: false }));
        setGridData(cloneDeep(newData));
      }
      onRowSelected(cloneDeep(newData.filter(row => row.checked)));
    };
    const hideFilterPanels = useCallback(
      throttle(
        () => {
          // if (showColumnFilter.filterId!=='none') {
          setShowColumnFilter(defaultShowcolumnfilter);
          // }
        },
        500,
        { leading: true }
      )
    );
    const debouncedCheckPosition = throttle(
      () => {
        const presentTop = tableRef.current.getBoundingClientRect().top;
        if (tableRef && presentTop <= topOffset) {
          document
            .querySelectorAll('.transparentTable>.divTable .divTableHead .tabbable')
            .forEach(x => {
              x.setAttribute('tabindex', '1');
            });
          document
            .querySelectorAll(':not(.transparentTable)>.divTable .divTableHead .tabbable')
            .forEach(x => {
              x.setAttribute('tabindex', '-1');
            });
          document.querySelectorAll('.transparentTable').forEach(x => {
            x.style.opacity = 1;
          });
          // tableRef.current.addEventListener('scroll', hideFilterPanels);
        }
        if (tableRef && presentTop > topOffset) {
          document
            .querySelectorAll('.transparentTable>.divTable .divTableHead .tabbable')
            .forEach(x => {
              x.setAttribute('tabindex', '-1');
            });
          document
            .querySelectorAll(':not(.transparentTable)>.divTable .divTableHead .tabbable')
            .forEach(x => {
              x.setAttribute('tabindex', '1');
            });
          document.querySelectorAll('.transparentTable').forEach(x => {
            x.style.opacity = 0;
          });
          // tableRef.current.removeEventListener('scroll', hideFilterPanels);
        }
      },
      300,
      { leading: true }
    );
    useEffect(() => {
      window.addEventListener('scroll', hideFilterPanels, { passive: true });
      // if (tableRef) {
      //   tableRef.current.addEventListener('scroll', hideFilterPanels);
      // }

      if (id === 'movingTable' || id === 'fixedTable') {
        window.addEventListener('scroll', debouncedCheckPosition, { passive: true });
        return () => {
          window.removeEventListener('scroll', debouncedCheckPosition, { passive: true });
          window.removeEventListener('scroll', hideFilterPanels, { passive: true });
        };
      }
    }, []);

    useEffect(() => {
      if (setReqBodyOptions && initialSoretedColumn !== sortedColumn)
        setReqBodyOptions({
          ...reqBodyOptions,
          sortcolumn: convertReduxToApi('columnvalues')[sortedColumn.columnName],
          sortorder: sortedColumn.order,
        });
    }, [sortedColumn]);

    const getTableHeadClassName = column =>
      [
        (appliedFilters && appliedFilters[column.key] && appliedFilters[column.key].length > 0) ||
        sortedColumn.columnName === column.key
          ? 'active'
          : null,
        'divTableHead',
        column.isFixedColumn ? 'fixedColumn' : null,
        column.filter ? 'hasFilter' : null,
        // !column.colKey && column.isFixedColumn && id === 'fixedTable2' ? 'invisible' : '',
      ]
        .filter(cn => !!cn)
        .join(' ');

    return (
      <div
        className={`gridData ${classNames}  ${id}`}
        ref={tableRef}
        style={{
          opacity: id.split(' ')[1] === 'transparentTable' ? '0' : '1',
          top: id.indexOf('transparentTable') > -1 ? `${topOffset}px` : null,
          width,
          visibility: id.split(' ')[1] === 'transparentTable' ? 'hidden' : 'visible',
        }}
      >
        <div className="divTable table-hover">
          {true && (
            <div className={`divTableHeading ${styles.tableHeader}`}>
              <div className="divTableRow">
                {uniqueKey && (
                  <div className="divTableHead customCheck">
                    <input
                      type="checkbox"
                      className={`${styles.tableCheckBox} ${
                        id === 'fixedTable' || id === 'fixedTable transparentTable'
                          ? 'tabbable'
                          : ''
                      }`}
                      tabIndex={id === 'fixedTable' ? 1 : -1}
                      id={`selectAll${classNames}${id}`}
                      aria-label={`selectAll${classNames}${id}`}
                      onClick={e => selectAll(e.target.checked)}
                      checked={gridData.filter(row => row.checked).length === gridData.length}
                    />
                    {/* <label htmlFor={`selectAll${classNames}${id}`}  /> */}
                  </div>
                )}
                {shownColumns.map((column, i) => (
                  <div
                    key={column.viewKey}
                    className={getTableHeadClassName(column)}
                    style={{
                      zIndex: showColumnFilter.filterId === column.key ? 35 : 25,
                      top: `${filterPanelHeight + topOffset}px`,
                      width: column.width ? column.width : null,
                    }}
                  >
                    <div
                      className={
                        shownColumns.length - 1 === i
                          ? 'd-flex justify-content-end'
                          : columnHeaderAlignment
                          ? 'd-flex'
                          : 'd-flex justify-center'
                      }
                    >
                      {column.label && (
                        <>
                          <div
                            className={
                              (appliedFilters &&
                                appliedFilters[column.key] &&
                                appliedFilters[column.key].length > 0) ||
                              sortedColumn.columnName === column.key
                                ? `${column.key} active-col active ${
                                    getMappedKey(column.key) ? 'selectedfilter-font' : ''
                                  }`
                                : `${column.key} active-col ${
                                    getMappedKey(column.key) ? 'selectedfilter-font' : ''
                                  }`
                            }
                          >
                            {`${intl.formatMessage({ id: column.label })}`}

                            {column.filter && getFilterAnchor({ column, tableId: id, colIndex: i })}
                            {showColumnFilter.filterId === column.key &&
                              showColumnFilter.isActiveLink &&
                              getFilterUI(showColumnFilter)}
                          </div>
                        </>
                      )}
                      {column.showRearrangeColumns && !hideRearrangeColumn && (
                        <PopoverDialog
                          className={id.indexOf('fixedTable2') > -1 ? 'tabbable' : ''}
                          tabIndex={id === 'fixedTable2' ? 1 : -1}
                        >
                          <ColumnSortPanel
                            displayColumns={displayColumns}
                            setDisplayColumns={setDisplayColumns}
                            unCheckedColumns={unCheckedColumns}
                            setUnCheckedColumns={setUnCheckedColumns}
                            defaultColumns={columns}
                          />
                        </PopoverDialog>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className="divTableBody">
            {!isLoading && getTableBody({ gridData, shownColumns })}
            {isLoading &&
              [1, 2, 3].map(row => (
                <div className="divTableRow" key={row}>
                  {shownColumns.map(column => (
                    <div className="divTableCell" key={column.label}>
                      <span
                        className={
                          (column.isFixedColumn && (id === 'fixedTable' || id === 'fixedTable2')) ||
                          (id === 'movingTable' && !column.isFixedColumn)
                            ? 'gridLoading'
                            : ''
                        }
                      />
                    </div>
                  ))}
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  }
);
Table.defaultProps = {
  resetCheckBoxSelection: false,
};
Table.propTypes = {
  resetCheckBoxSelection: PropTypes.bool,
};
export default injectIntl(Table);
