/**
 * A utility function to capitalize the first letter of a string
 *
 * @function
 * @param string {string} The string text to capitalize
 * @returns {string} A string text with the first letter capitalized
 *
 * @example
 * hello -> Hello
 */
const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

/**
 * A utility function to convert a string from camel-case to title-case
 * creating a space-delimited string
 *
 * @function
 * @param string {string} The camel-cased string text to convert
 * @returns {string} A converted space-delimited title-cased string
 *
 * @example
 * helloWorld -> Hello World
 */
const camelCaseToTitleCase = (string) => {
  const result = string.replace(/([A-Z])/g, ' $1');

  return result.charAt(0).toUpperCase() + result.slice(1);
};

export { camelCaseToTitleCase, capitalizeFirstLetter };
