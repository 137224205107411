import { put, takeLatest, call } from 'redux-saga/effects';
import {
  requestVersionListSuccess,
  requestVersionListFailure,
  fetchECMVersionSucces,
  fetchECMVersionFailed,
} from './version-actions';
import { checkEnvironment } from 'ui-common/src/lib/utils';
import { Cookies } from 'react-cookie';
import * as C from './constants';

const cookies = new Cookies();

function* requestVersionListSaga() {
  const { GATEWAY_API_URL } = checkEnvironment();
  try {
    const url = `${GATEWAY_API_URL}/api/version/show`;
    const response = yield call(fetch, url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-NOLOADER': true,
        ...(checkEnvironment().FEATURE_HTTPONLY_ACCESSTOKEN || {
          Authorization: `${cookies.get('token_type')} ${cookies.get('access_token')}`,
        }),
      },
    });
    if (response && response.status === 200) {
      const json = yield call([response, response.json]);
      yield put(requestVersionListSuccess(json));
    } else {
      yield put(requestVersionListFailure('Failure'));
    }
  } catch (error) {
    yield put(requestVersionListFailure('Failure'));
  }
}

export function* requestEcmVersionSaga() {
  const { ECM_API_URL, ECM_URL } = checkEnvironment();
  try {
    const url = checkEnvironment().FEATURE_HTTPONLY_ACCESSTOKEN
      ? `${ECM_URL}/app/getEcmVersion`
      : `${ECM_API_URL}/v5/getEcmVersion`;
    const response = yield call(fetch, url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-NOLOADER': true,
        ...(checkEnvironment().FEATURE_HTTPONLY_ACCESSTOKEN || {
          Authorization: `${cookies.get('token_type')} ${cookies.get('access_token')}`,
        }),
      },
    });
    if (response && response.status === 200) {
      const res = yield call([response, response.json]);
      yield put(fetchECMVersionSucces(res));
    } else {
      yield put(fetchECMVersionFailed('Failure'));
    }
  } catch (error) {
    yield put(fetchECMVersionFailed('Failure'));
  }
}

export function* fetchVersionList() {
  yield takeLatest(C.REQUEST_VERSION_LIST, requestVersionListSaga);
}

export function* fetchEcmVersion() {
  yield takeLatest(C.FETCH_ECM_VERSION, requestEcmVersionSaga);
}
