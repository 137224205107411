import { createTheme } from '@material-ui/core/styles';

const getColor = (rgb, opacity) => `rgba(${(opacity || opacity === 0 ? rgb.concat([opacity]) : rgb).join(',')})`;

export const getCssTheme = (rgb) => ({
  // '--qr-card-backrground-image': 'linear-gradient(295deg, blue 99%, #5270f2 2%)',
  '--primary-main': rgb.primary ? getColor(rgb.primary, 1) : getColor([55, 81, 255], 1),
  '--primary-main-li': rgb.primary ? getColor(rgb.primary, 0.15) : getColor([55, 81, 255], 0.15),
  '--primary-main-me-op': rgb.primary ? getColor(rgb.primary, 0.5) : getColor([55, 81, 255], 1),
  '--primary-card-bg': rgb.primaryCardBg
    ? getColor(rgb.primaryCardBg, 1)
    : getColor([105, 116, 208], 1),
  '--primary-card-bg-text': rgb.primaryCardBgText
    ? getColor(rgb.primaryCardBgText, 1)
    : getColor([199, 205, 255], 1),
  '--primary-bg': rgb.primaryBg ? getColor(rgb.primaryBg, 1) : getColor([223, 224, 235], 1),
  '--primary-bg-li': rgb.primaryBgLi ? getColor(rgb.primaryBgLi, 1) : getColor([242, 244, 255], 1),
  '--secondaryBg': rgb.secondaryBg ? getColor(rgb.secondaryBg, 1) : getColor([234, 238, 251], 1),
  '--light-border': rgb.lightBorder ? getColor(rgb.lightBorder, 1) : getColor([210, 219, 255], 1),
  '--hover-Bg': rgb.hoverBg ? getColor(rgb.hoverBg, 1) : getColor([244, 246, 250], 1),
  '--secondary-main': rgb.secondary ? getColor(rgb.secondary, 1) : getColor([234, 169, 69], 1),
  '--page-bg': rgb.pageBg ? getColor(rgb.pageBg, 1) : getColor([247, 248, 252], 1),
  '--togglebutton-bg': rgb.toggleButtonBg
    ? getColor(rgb.toggleButtonBg, 1)
    : getColor([0, 0, 0], 0),
  '--error-text': rgb.errorText ? getColor(rgb.errorText, 1) : getColor([177, 0, 0], 1),
  '--copy-access-text': rgb.copyAccessText
    ? getColor(rgb.copyAccessText, 1)
    : getColor([177, 0, 0], 1),
  '--subtitle-text': rgb.subtitleText
    ? getColor(rgb.subtitleText, 1)
    : getColor([100, 100, 100], 1),

  // -Home page Themes
  '--home-main-title': rgb.homeMainTitle ? getColor(rgb.homeMainTitle, 1) : getColor([0, 0, 0], 1),
  '--home-black-dark': rgb.homeBlackDark
    ? getColor(rgb.homeBlackDark, 1)
    : getColor([36, 36, 36], 1),
  '--home-featuretile-title': rgb.homeFeatureTileTitle
    ? getColor(rgb.homeFeatureTileTitle, 1)
    : getColor([30, 30, 30], 1),
  '--home-subtitle': rgb.homeSubtitle ? getColor(rgb.homeSubtitle, 1) : getColor([84, 84, 84], 1),
  '--kpiwidget-bg': rgb.homePrimary ? getColor(rgb.kpiWidgetBg, 1) : getColor([255, 255, 255], 1),
  '--featuretile-Bg': rgb.featureTileBg
    ? getColor(rgb.featureTileBg, 1)
    : getColor([255, 255, 255], 1),
  '--home-rightpanel-bg': rgb.homeRightPanelBg
    ? getColor(rgb.homeRightPanelBg, 1)
    : getColor([255, 255, 255], 1),
  '--pendingaction-icon-bg': rgb.pendingActionIconBg
    ? getColor(rgb.pendingActionIconBg, 1)
    : getColor([239, 241, 255], 1),
  '--activetab-bg': rgb.homeTabBg ? getColor(rgb.homeTabBg, 1) : getColor([239, 238, 255], 1),
  '--recentAct-icon-bg': rgb.recentActIconBg
    ? getColor(rgb.recentActIconBg, 1)
    : getColor([55, 71, 90], 1),

  // Sidebar colors
  '--navbar-title': rgb.navBarTitle ? getColor(rgb.navBarTitle, 1) : getColor([100, 100, 100], 1),
  '--navbar-bg': rgb.navBarBg ? getColor(rgb.navBarBg, 1) : getColor([255, 255, 255], 1),
  '--navbar-label-color': rgb.navBarLabel
    ? getColor(rgb.navBarLabel, 1)
    : getColor([131, 129, 129], 1),
  '--navbar-label-hover': rgb.navBarLabelHover
    ? getColor(rgb.navBarLabelHover, 1)
    : getColor([55, 81, 255], 1),
  '--navbar-label-active': rgb.navBarLabelActive
    ? getColor(rgb.navBarLabelActive, 1)
    : getColor([55, 81, 255], 1),
  '--navbar-icon-color': rgb.navBarIcon
    ? getColor(rgb.navBarIcon, 1)
    : getColor([131, 129, 129], 1),
  '--navbar-icon-hover': rgb.navBarIconHover
    ? getColor(rgb.navBarIconHover, 1)
    : getColor([55, 81, 255], 1),
  '--navbar-icon-active': rgb.navBarIconActive
    ? getColor(rgb.navBarIconActive, 1)
    : getColor([55, 81, 255], 1),

  // SOD colors
  '--sod-bg': rgb.sodBg ? getColor(rgb.sodBg, 1) : getColor([234, 231, 253], 1),
  '--sod-critical': rgb.sodCritical ? getColor(rgb.sodCritical, 1) : getColor([218, 58, 52], 1),
  '--sod-header-text': rgb.sodHeadertext
    ? getColor(rgb.sodHeadertext, 1)
    : getColor([36, 36, 36], 1),

  // RequestForm colors
  '--dark-grey-text': rgb.anchorCancelBtnText
    ? getColor(rgb.anchorCancelBtnText, 1)
    : getColor([103, 107, 128], 1),

  // approvals:
  'approval-footer-bg': rgb.approvalDetailFooterBg
    ? getColor(rgb.approvalDetailFooterBg, 1)
    : getColor([255, 255, 255], 1),
  'approval-footer-color': rgb.approvalFooterColor
    ? getColor(rgb.approvalFooterColor, 1)
    : getColor([116, 116, 116], 1),
  '--newaccountbadge-bg': rgb.newAccountBadgeBgColor
    ? getColor(rgb.newAccountBadgeBgColor, 1)
    : getColor([248, 249, 253], 1),
  '--grid-scroll': rgb.gridScrollColor
    ? getColor(rgb.gridScrollColor, 1)
    : getColor([204, 204, 204], 1),
  '--approvalProgress-approved-color': rgb.approvedColor
    ? getColor(rgb.approvedColor, 1)
    : getColor([49, 183, 147], 1),
  '--approvalProgress-pending-color': rgb.pendingColor
    ? getColor(rgb.pendingColor, 1)
    : getColor([116, 116, 116], 1),
  '--approvalProgress-rejected-color': rgb.rejectedColor
    ? getColor(rgb.rejectedColor, 1)
    : getColor([238, 83, 87], 1),
  '--approvalProgress-pendingApproval-color': rgb.pendingApprovalColor
    ? getColor(rgb.pendingApprovalColor, 1)
    : getColor([0, 0, 0], 1),
  '--approval-accessApprove-color': rgb.accessApproveColor
    ? getColor(rgb.accessApproveColor, 1)
    : getColor([3, 101, 3], 1),
  '--approval-accessApproveBg-color': rgb.accessApproveColorBg
    ? getColor(rgb.accessApproveColorBg, 1)
    : getColor([224, 245, 241], 1),
  '--approval-accessApproveText-color': rgb.accessApproveColorText
    ? getColor(rgb.accessApproveColorText, 1)
    : getColor([255, 255, 255], 1),
  '--approval-accessReject-color': rgb.accessRejectColor
    ? getColor(rgb.accessRejectColor, 1)
    : getColor([162, 0, 0], 1),
  '--approval-accessRejectBg-color': rgb.accessRejectColorBg
    ? getColor(rgb.accessRejectColorBg, 1)
    : getColor([249, 223, 221], 1),
  '--fileupload-mandatory-color': rgb.fileUploadMandatoryColor
    ? getColor(rgb.fileUploadMandatoryColor, 1)
    : getColor([179, 6, 0], 1),
  '--request-comments-usericon-color': rgb.requestCommentsUserIconColor
    ? getColor(rgb.requestCommentsUserIconColor, 1)
    : getColor([255, 255, 255], 1),
  '--request-comments-usericon-bgcolor': rgb.requestCommentsUserIconBgColor
    ? getColor(rgb.requestCommentsUserIconBgColor, 1)
    : getColor([49, 90, 218], 1),
  '--historygrid-actionDotsBg': rgb.historyGridActionDotsColorBg
    ? getColor(rgb.historyGridActionDotsColorBg, 1)
    : getColor([218, 205, 205], 1),
  '--grid-tooltip-textColor': rgb.gridToolTipTextColor
    ? getColor(rgb.gridToolTipTextColor, 1)
    : getColor([0, 0, 0], 1),

  // Primary and Disabled color
  '--disabled-text': rgb.disabledColor
    ? getColor(rgb.disabledColor, 1)
    : getColor([116, 116, 116], 1),
  '--primary-text': rgb.primaryTextColor
    ? getColor(rgb.primaryTextColor, 1)
    : getColor([116, 116, 116], 1),
  '--text-label-color': rgb.textLabelColor
    ? getColor(rgb.textLabelColor, 1)
    : getColor([112, 117, 122], 1),

  // 'dark' theme of application
  '--dark-bg': rgb.darkBg ? getColor(rgb.darkBg, 1) : getColor([38, 39, 49], 1),
  '--dark-bg-li': rgb.darkBgLi ? getColor(rgb.darkBgLi, 1) : getColor([50, 50, 62], 1),
  '--dark-text': rgb.darkText ? getColor(rgb.darkText, 1) : getColor([208, 208, 208], 1),
  '--dark-text-title': rgb.darkTitle ? getColor(rgb.darkTitle, 1) : getColor([208, 208, 208], 1),
  '--dark-line': rgb.darkLine ? getColor(rgb.darkLine, 1) : getColor([65, 65, 80], 1),

  // OM theme colors
  '--error-purple': '#5634e0',
  '--error-purple-bg': '#eae7fd',
  '--error-red': '#cc1c10',
  '--error-red-bg': '#fdeceb',
  '--tag-red': '#ede0fd',
  '--tag-blue': '#e3e8ff',
  '--grey-font-li': '#898989',
  '--primary-btn-bg': '#ebeeff',
  '--primary-border-color': '#404880',

  // Side bar Naviagtion theme colors
  // '--SIDEMENU-BACKGROUND': rgb.sideMenuBg ? getColor(rgb.sideMenuBg, 1) : '#111530',
  '--SIDEMENU-BACKGROUND': rgb.sideMenuBg ? getColor(rgb.sideMenuBg, 1) : '#001A40',
  '--SIDEMENU-COLOR': rgb.sideMenuColor ? getColor(rgb.sideMenuColor, 1) : '#FFFFFF',
  '--SIDEMENU-HOVER-BACKGROUND': rgb.sideMenuHoverBg
    ? getColor(rgb.sideMenuHoverBg, 1)
    : 'rgba(103, 107, 128, 0.12)',
  '--SIDEMENU-HOVER-TEXT-COLOR': rgb.sideMenuHoverTextColor
    ? getColor(rgb.sideMenuHoverTextColor, 1)
    : '#e7e6e6',
  '--SIDEMENU-TEXT-COLOR': rgb.sideMenuTextColor ? getColor(rgb.sideMenuTextColor, 1) : '#ffffff',
  '--SIDEMENU-SUBTEXT-COLOR': rgb.sideMenuSubTextColor
    ? getColor(rgb.sideMenuSubTextColor, 1)
    : '#A1A3B3',
  '--SIDEMENU-ICON-HAMBURGER': rgb.sideMenuHamburgerIcon
    ? getColor(rgb.sideMenuHamburgerIcon, 1)
    : '#ffffff',
  '--SIDEMENU-ICON-BACKCHEVRON': rgb.sideMenuBackChevronIcon
    ? getColor(rgb.sideMenuBackChevronIcon, 1)
    : '#ffffff',
  '--SIDEMENU-ICON-PRIMARY': '',
  '--SIDEMENU-ICON-ACCESS': rgb.sideMenuAccessIcon
    ? getColor(rgb.sideMenuAccessIcon, 1)
    : '#16D1D8',
  '--SIDEMENU-ICON-ANALYZE': rgb.sideMenuAnalyzeIcon
    ? getColor(rgb.sideMenuAnalyzeIcon, 1)
    : '#FF66BA',
  '--SIDEMENU-ICON-CONFIGURE': rgb.sideMenuConfigureIcon
    ? getColor(rgb.sideMenuConfigureIcon, 1)
    : '#FF8141',
  '--SIDEMENU-ICON-SETTING': rgb.sideMenuSettingIcon
    ? getColor(rgb.sideMenuSettingIcon, 1)
    : '#DD80FF',
  '--SIDEMENU-MENUITEM-BACKGROUND': rgb.sideMenuItemBg
    ? getColor(rgb.sideMenuItemBg, 1)
    : '#141419',
  '--SIDEMENU-SEARCH-ACTIVE': rgb.sideMenuSearchActive
    ? getColor(rgb.sideMenuSearchActive, 1)
    : '#9DAAFF',
  '--SIDEMENU-ICON-COLOR-DEFAULT': rgb.sideMenuIconDefaultColor
    ? getColor(rgb.sideMenuIconDefaultColor, 1)
    : '#FFFFFF',

  // universal colors, regardless of theme
  '--black': '#000000',
  '--black-li': '#242424',
  '--white': '#ffffff',
  '--dark-grey': '#545454',
  '--grey-font': '#747474',
  '--grey-border': '#ececec',
  '--grey-bg': '#f0f0f0',
  '--grey-font-input': '#999999', // input box value ONLY
  '--grey-border-input': '#dddddd', // input box border ONLY
  '--grey-font-text': '#848484',
  '--grey-text': '#9d9d9d',
  '--medium-bg': '#ffb100',
  '--black-medium-text': '#1e1e1e',
  '--light-bg': '#fafafa',
  '--dark-text-cart': '#212121',
  '--btn-outline-primary': '#b4b4b4',
  '--failure-red': '#f06a60',
  '--failure-red-bg': '#fbe8eb',
  '--success-green': '#31b793',
  '--success-green-bg': '#d4f2ea',
  '--mandatory-red': '#e70000',
  '--dovegrey': '#727272',
  '--mariner': '#1b52b1',
  '--fungreen': '#006800',
  '--boldorange': '#e7880a',
  '--orange-li': '#fff6eb',
  '--light-grey': '#CDCED9',
  '--dark-green': '#006800',
  '--green-bg': '#E6FFEE',
  '--green-text': '#028329',
  '--yellow-bg': '#FFF6D8',
  '--yellow-text': '#9E6500',
  '--pink-bg': '#FFE5E3',
});

export const getMuiTheme = (cssTheme) => createTheme({
  palette: {
    primary: {
      main: cssTheme['--primary-main'],
      contrastText: cssTheme['--white'],
    },
    error: {
      main: '#F06A60',
      light: '#FBE4E8',
    },
    success: {
      main: '#54cba1',
    },
    warning: {
      main: '#ffb100',
    },
    text: {
      primary: cssTheme['--primary-text'],
      secondary: '#9d9d9d',
      disabled: cssTheme['--disabled-text'],
    },
  },
  typography: {
    fontFamily: 'Rubik, sans-serif',
    fontSize: 12,
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  overrides: {
    MuiListItem: {
      root: {
        '&$selected': { backgroundColor: cssTheme['--primary-bg'] },
      },
      button: {
        '&:hover, &:focus': {
          backgroundColor: cssTheme['--primary-bg-li'],
        },
      },
    },
    MuiToggleButton: {
      root: {
        '&$selected': {
          color: cssTheme['--primary-main'],
          backgroundColor: cssTheme['--primary-bg'],
          border: 'none !important',
        },
        '&:hover, &:focus': {
          backgroundColor: cssTheme['--togglebutton-bg'],
        },
      },
    },
    MuiChip: {
      outlined: {
        backgroundColor: 'transparent',
        color: cssTheme['--black-li'],
        border: '1px solid #e2e2e2',
        fontSize: 11,
      },
    },
    MuiAutocomplete: {
      option: {
        '&[data-focus="true"]': {
          backgroundColor: cssTheme['--primary-bg'],
        },
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: cssTheme['--primary-bg'],
        },
      },
    },
    MuiIconButton: {
      root: {
        '&:hover, &:focus': {
          backgroundColor: cssTheme['--primary-bg-li'],
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: 'capitalize',
        lineHeight: 'inherit',
        border: 'none',
        borderRadius: 3,
        fontSize: 12,
        textAlign: 'center',
        letterSpacing: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
      },
      outlinedSizeSmall: {
        padding: '4px 9px',
      },
      outlined: {
        padding: '8px 15px',
        border: '1px solid',
      },
      outlinedSizeLarge: {
        padding: '11.5px 21px',
        fontSize: 14,
      },
      containedSizeSmall: {
        padding: '4px 9px',
      },
      contained: {
        padding: '9px 15px',
      },
      containedSizeLarge: {
        padding: '13px 21px',
        fontSize: 14,
      },
    },
    MuiInputBase: {
      root: {
        '&$disabled': {
          backgroundColor: 'rgba(0, 0, 0, 0.12)',
        },
      },
    },
  },
});
