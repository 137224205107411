import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const Modal = ({ children, open, handleClose }) => (
  <Dialog
    open={open}
    TransitionComponent={Transition}
    onClose={handleClose}
    aria-labelledby="form-dialog-title"
  >
    {children}
  </Dialog>
);

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default Modal;
