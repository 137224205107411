import React, { useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Dialog, Button, IconButton } from '@material-ui/core';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';
import './RoleDetailsTabView.scss';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import RoleSummaryTab from './RoleSummaryTab';
import RoleEntitlementTab from './RoleEntitlementTab';
import { injectIntl } from 'react-intl';

const styles = theme => ({
  root: {
    margin: 0,
    padding: '26px 24px 0px',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  margin: {
    margin: theme.spacing(1),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: -15,
    minHeight: 38,
    textTransform: 'none',
  },
  flexContainer: {
    maxHeight: 38,
    borderBottom: 'solid 1px #ececec',
  },
  wrapper: {
    textTransform: 'none',
    maxWidth: 100,
    color: 'var(--grey-header)'
  },
  button_request: {
    height: 32,
    width: 91,
    paddingTop: 7,
  },
  tabroot: {
    minWidth: 40,
    padding: 2,
    margin: '0 9px',
  },

  paperWidthSm: {
    maxWidth: '38.25rem !important',
    minWidth: '38.25rem !important',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} timeout={1000} {...props} />;
});

const DialogTitle = withStyles(styles)(props => {
  const { classes, onClose, headerName } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <div className="roleReq-header">
        <div className="roleReq-title">
          {' '}
          {/* {`Role Request: ${roledata.item ? roledata.item.name : ''}`} */}
          {headerName}
        </div>
      </div>
      <IconButton aria-label="close" className={classes.closeButton} onClick={() => onClose()}>
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(() => ({
  root: {
    padding: '0 24px',
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    boxShadow: '0 -3px 8px 0 rgba(205, 206, 217, 0.5)',
  },
}))(MuiDialogActions);

const TabPanel = props => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {children}
    </Typography>
  );
};

TabPanel.defaultProps = {
  children: null,
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
};

const RoleDetailsTabView = ({
  setRoleRequest,
  selectedRoleItem,
  open,
  roledata,
  switchRolePopup,
  backToSelection,
  headerName,
  footer,
  intl,
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (roledata && roledata.fromDetails) {
      const val = roledata.fromDetails === 'role' ? 0 : 1;
      setValue(val);
    }
  }, [roledata]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      disableBackdropClick
      classes={{ paperWidthSm: classes.paperWidthSm }}
      PaperProps={{
        style: {
          maxHeight: '100%',
          height: '100%',
          borderRadius: 0,
          // maxWidth: 580,
        },
      }}
    >
      {backToSelection && (
        <div className="backToDetailsPageSection">
          <ArrowBackIosIcon onClick={() => setRoleRequest()} />
          <span
            className="backButton backButton-selection"
            onClick={() => setRoleRequest()}
            role="button"
            tabIndex={0}
            onKeyPress=""
          >
            Back to Selection
          </span>
        </div>
      )}
      <DialogTitle
        id="customized-dialog-title"
        onClose={setRoleRequest}
        roledata={roledata}
        headerName={headerName}
      />
      <DialogContent>
        <div className="roledetails-content">
          <Tabs
            indicatorColor="primary"
            classes={{ flexContainer: classes.flexContainer, root: classes.root }}
            textColor="primary"
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <Tab tabIndex="0"
              label={intl.formatMessage({ id: 'RoleDetailsTabview.RoleSummary' })}
              classes={{ wrapper: classes.wrapper, root: classes.tabroot }}
            />
            <Tab tabIndex="0"
              label={intl.formatMessage({ id: 'RoleDetailsTabview.Entitlements' })}
              classes={{ wrapper: classes.wrapper, root: classes.tabroot }}
            />
          </Tabs>
          <TabPanel value={value} index={0}>
            <RoleSummaryTab
              roledata={roledata.data && roledata.data.summary ? roledata.data.summary : {}}
              selectedRoleItem={selectedRoleItem}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <RoleEntitlementTab
              entitlementvalue={roledata.data && roledata.data.entitlementvalue ? roledata.data.entitlementvalue : []}
            />
          </TabPanel>
        </div>
      </DialogContent>
      {footer && (
        <DialogActions>
          <Button
            variant="outlined"
            className={(classes.button, classes.button_request)}
            onClick={() => setRoleRequest()}
          >
            {intl.formatMessage({ id: 'RoleDetailsTabview.Close' })}
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={
              roledata.item
                ? roledata.item.isCart ||
                (roledata.item.item ? roledata.item.item.disableRoleRequest : false)
                : false
            }
            className={clsx(classes.button, classes.button_request)}
            onClick={() => switchRolePopup(true, roledata.item)}
          >
            {intl.formatMessage({ id: 'RoleDetailsTabview.Request' })}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};
RoleDetailsTabView.defaultProps = {
  selectedRoleItem: {},
};
RoleDetailsTabView.propTypes = {
  roledata: PropTypes.shape({
    item: PropTypes.shape({ name: PropTypes.string, isCart: PropTypes.bool }),
    fromDetails: PropTypes.string.isRequired,
    data: PropTypes.shape({
      entitlementvalue: PropTypes.arrayOf(
        PropTypes.shape({
          entitlementValue: PropTypes.string,
        })
      ),
      summary: PropTypes.shape({
        confidentiality: PropTypes.string,
      }),
    }),
  }).isRequired,
  setRoleRequest: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  backToSelection: PropTypes.bool.isRequired,
  headerName: PropTypes.string.isRequired,
  footer: PropTypes.bool.isRequired,
  switchRolePopup: PropTypes.func.isRequired,
  selectedRoleItem: PropTypes.shape({}),
};

export default injectIntl(RoleDetailsTabView);
