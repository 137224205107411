const getIconColors = (id) => {
  switch (id) {
    // case 'home':
    //   return '#00C1C7'; // TODO - Need to remove hardcoded color code after testing.
    // case 'intelligence':
    //     return '#FF66BA';
    // case 'design':
    //   return '#FF6F26';
    // case 'control-centre':
    //     return '#00C675';
    // case 'admin':
    //   return '#DD80FF';
    // case 'sod':
    //   return '#ff6666';
    // case 'certifications':
    //   return '#66acff';
    // default:
    //   return 'var(--SIDEMENU-ICON-PRIMARY)';
    case 'home':
      return 'var(--SIDEMENU-ICON-ACCESS)';
    case 'intelligence': // #FF66BA //var(--color-foreground-pink-subtle, #FF66BA);
      return 'var(--SIDEMENU-ICON-ANALYZE)';
    case 'design': // var(--color-foreground-orange-subtle, #FF6F26);
      return 'var(--SIDEMENU-ICON-DESIGN)';
    case 'control-centre': // #00C675
      return 'var(--SIDEMENU-ICON-CONFIGURE)';
    case 'admin': // #DD80FF
      return 'var(--SIDEMENU-ICON-ADMIN)';
    case 'sod': // #ff6666
      return 'var(--SIDEMENU-ICON-SOD)';
    case 'certifications': // #66acff
      return 'var(--SIDEMENU-ICON-SETTING)';
    default:
      return 'var(--SIDEMENU-ICON-PRIMARY)';
  }
};

export const getIconColorCodes = (id) => {
  switch (id) {
    case 'home':
      return '#00C1C7';
    case 'intelligence':
      return '#FF66BA';
    case 'design':
      return '#FF6F26';
    case 'control-centre':
      return '#00C675';
    case 'admin':
      return '#DD80FF';
    case 'sod':
      return '#ff6666';
    case 'certifications':
      return '#66acff';
    default:
      return 'var(--SIDEMENU-ICON-PRIMARY)';
  }
};

export default getIconColors;
