export const DEFAULT_INPUT_DEBOUNCE_DELAY = 250;
export const DEFAULT_SCROLL_DEBOUNCE_DELAY = 50;

export const debounce = (func, delay) => {
  let timeoutId;

  const debouncedFunction = (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };

  debouncedFunction.cancel = () => {
    clearTimeout(timeoutId);
  };

  return debouncedFunction;
};

/**
 * Creates a debounced function that returns a promise, effectively wrapping the
 * `debounce` function in a promise-based interface. This allows for debouncing
 * asynchronous operations such as API calls, where the function `fn` is
 * expected to return a promise.
 * The debounced function will delay invoking `fn` until after `delay` millis
 * has elapsed since the last invocation.
 *
 *
 * @param {Function} fn - function to debounce. This function should return a promise.
 * @param {number} delay - number of milliseconds to delay the invocation of `fn`.
 * @returns {Function} new function that debounces the given function `fn`. This function
 * accepts any number of arguments, which are passed to `fn` when it's invoked.
 */
export const debouncePromise = (fn, delay) => {
  const debouncedFn = debounce((resolve, reject, args) => {
    fn(...args)
      .then(resolve)
      .catch(reject);
  }, delay);

  return (...args) =>
    new Promise((resolve, reject) => {
      debouncedFn(resolve, reject, args);
    });
};

/**
 * Creates a throttled function that calls the specified function `func` at most once every
 * `limit` milliseconds. This is useful for rate-limiting execution of functions that
 * trigger repeatedly, such as during window resizing or scrolling.
 *
 * @param {Function} func - The function to be throttled. This function can accept any number
 * of arguments, and it will be called with the context and arguments it receives.
 * @param {number} limit - The minimum time interval in milliseconds between invocations of `func`.
 * @returns {Function} A new function that limits the rate at which `func` is called.
 */
export const throttle = (func, limit) => {
  let inThrottle;

  return (...args) => {
    if (!inThrottle) {
      func(...args);
      inThrottle = true;
      setTimeout(() => {
        inThrottle = false;
      }, limit);
    }
  };
};

/**
 * Combines both debounce and throttle techniques to manage function invocations. It ensures
 * the function `func` is executed immediately on the first call, then subsequent calls are
 * debounced. The function is only executed again if it is called after the `throttleTime` has
 * elapsed since the last execution.
 *
 * This hybrid function is particularly useful for scenarios where you need to handle
 * initial events immediately but want to limit subsequent invocations during intensive
 * sequences of calls, such as typing or window resizing.
 *
 * @param {Function} func - The function to debounce and throttle. This function can accept any number
 * of arguments.
 * @param {number} debounceTime - The time in milliseconds to wait before the next invocation if
 * subsequent calls are made.
 * @param {number} throttleTime - The minimum time interval in milliseconds between invocations of `func`.
 * @returns {Function} A new function that debounces and throttles invocations of `func`.
 */
export const debounceThrottle = (func, debounceTime, throttleTime) => {
  let lastRan;

  const debouncedFunc = debounce((args) => {
    if (Date.now() - lastRan >= throttleTime) {
      func(...args);
      lastRan = Date.now();
    }
  }, debounceTime);

  return (...args) => {
    if (!lastRan || Date.now() - lastRan >= throttleTime) {
      func(...args);
      lastRan = Date.now();
    } else {
      debouncedFunc(args);
    }
  };
};
