import React, { useState } from 'react';
import { checkEnvironment } from 'ui-common/src/lib/utils';

const MyTeamsUserImage = props => {
  const [imgNotFound, setImgNotFound] = useState(false);
  const IDWMS_ENDPOINT = checkEnvironment().IDWMS_API_URL;
  const showUserImage = checkEnvironment().BETA_FEATURES
    ? checkEnvironment().BETA_FEATURES.includes('SHOW_USER_IMAGE')
    : false;

  const onImgError = e => {
    e.target.style.display = 'none';
    setImgNotFound(true);
  };

  return (
    <>
      {(!showUserImage || imgNotFound) && (
        <div className="approvalIcon">
          <div className="textColor">{props.name.slice(0, 1).toUpperCase()}</div>
        </div>
      )}
      {showUserImage && !imgNotFound && (
        <img
          className="approvalIcon"
          src={`${IDWMS_ENDPOINT}/userImage/${props.name}`}
          alt=""
          onError={onImgError}
        />
      )}
    </>
  );
};

export default MyTeamsUserImage;
