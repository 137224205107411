import React from 'react';
import {
  IntlContext,
  type IntlShape,
  RawIntlProvider,
  useIntl,
} from 'react-intl';
import { localize } from '@saviynt/common';

export const useLocalize = () => {
  const intl = useIntl();

  return (
    key: string | { id: string; defaultMessage: string },
    placeholders?: object
  ): string => localize(intl, key, placeholders);
};

export type InvertedLocalize<M extends string> = (
  defaultMessage: M,
  placeholders?: object
) => string;

export const useInvertedLocalize = <M extends string>(
  invertedLookup: Record<M, string>
): InvertedLocalize<M> => {
  const intl = useIntl();

  return (defaultMessage: M, placeholders?: object): string => {
    const id = invertedLookup[defaultMessage];

    if (!id) {
      return defaultMessage;
    }

    return localize(intl, { id, defaultMessage }, placeholders);
  };
};

/* eslint-disable react/jsx-props-no-spreading -- needed for HOC */

export const IntlBridge = <P extends object>(
  Component: React.ComponentType<P>
): React.ComponentType<P> =>
  // eslint-disable-next-line react/require-default-props -- handled in the function body
  function WrappedByIntlBridge(props: P & { intl?: IntlShape }) {
    const { intl, ...rest } = props;
    const intlContext = React.useContext(IntlContext) as IntlShape | null;

    if (intlContext !== null) {
      return <Component {...(rest as P)} />;
    }

    if (intl !== undefined) {
      return (
        <RawIntlProvider value={intl}>
          <Component {...(rest as P)} />
        </RawIntlProvider>
      );
    }

    throw Error('No intl');
  };
