import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { injectIntl, useIntl } from 'react-intl';
import AlertBanner from './AlertBanner/AlertBanner';
import WelcomeSection from './WelcomeSection/WelcomeSection';
import RecentRequests from './RecentRequests/RecentRequests';
import GetHelp from './GetHelp/GetHelp';
import { changePageTitle, convertToUtc } from 'ui-common/src/lib/utils';
import AppsRecommendation from './AppsRecommendation/AppsRecommendation';
import {
  fetchWelcomeTiles,
  fetchBannerRequests,
  fetchRecentRequests,
  fetchHelpRequests,
  fetchAppRecommendations,
  fetchRequestDetails,
  fetchDefaultWidgets,
} from '../../redux/requestHome-actions';
import './Homepage.scss';
import { Cookies } from 'react-cookie';
import { KpisWidget, RecentAssignmentsWidget } from '@saviynt/nav';
const leftNavGen2 = window.localStorage.getItem('leftNavGen2');
const HomePageContainer = (props) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  useEffect(() => {
    changePageTitle(intl.formatMessage({ id: 'requestHome.RequestHome' }));
    dispatch(fetchWelcomeTiles());
    dispatch(fetchRequestDetails());
    dispatch(fetchBannerRequests());
    dispatch(fetchRecentRequests());
    dispatch(fetchHelpRequests());
    dispatch(fetchDefaultWidgets());
    // ToDo - Don't delete the below line as it be used later on
    // dispatch(fetchAppRecommendations());
  }, []);
  const isActiveThemeUnauthorized = useSelector(
    (state) => state.adminHome?.brandingConfig?.isActiveThemeUnauthorized
  );
  const { helpDetails, bannerDetails, appRecommendations, defaultWidget } = useSelector(
    (state) => state.requestHome.initialLoad
  );
  const {
    helpDetails: { isHelpFetchError },
  } = useSelector((state) => state.requestHome.initialLoad);

  if (isActiveThemeUnauthorized) {
    return null;
  }
  return (
    <div className="requestHome privilegedAccounts box-layout">
      <div
        className="homepage-container"
        style={{ paddingTop: `${leftNavGen2 === '0' ? '62px' : '16px'}` }}
      >
        {bannerDetails.isBannerEnabled && <AlertBanner />}
        <WelcomeSection />
        <div className="homepage-body">
          <KpisWidget intl={intl} className="homepage-kpis" />
          {defaultWidget?.configData === 'MyRecentAssignments' && (
            <RecentAssignmentsWidget
              intl={intl}
              className="homepage-recentAssignments"
              convertToUtc={convertToUtc}
            />
          )}
          {defaultWidget?.configData === 'MyRecentRequests' && <RecentRequests />}
          {/* ToDo -DONT DELETE this commented line as we need AppsRecommendation later*/}
          {/* {appRecommendations.length > 0 && <AppsRecommendation />} */}
          {(helpDetails.isHelpEnabled || helpDetails.isDocEnabled || isHelpFetchError) && (
            <GetHelp />
          )}
        </div>
      </div>
    </div>
  );
};
export default HomePageContainer;
