import { Cookies } from 'react-cookie';
import { checkEnvironment } from 'ui-common/src/lib/utils';

class HttpService {
  constructor() {
    this.cookies = new Cookies();
    this.headers = () => ({
      'Content-Type': 'application/json',
      ...(checkEnvironment().FEATURE_HTTPONLY_ACCESSTOKEN || {
        Authorization: `${this.cookies.get('token_type')} ${this.cookies.get('access_token')}`,
      }),
    });
  }

  get(url) {
    return fetch(url, {
      method: 'GET',
      headers: this.headers(),
    }).then((res) => res.json());
  }

  post(url, req, errorCallback) {
    return fetch(url, {
      method: 'POST',
      headers: this.headers(),
      body: JSON.stringify(req),
    }).then((res) => {
      if (res.status === 200) {
        return res.json();
      }

      errorCallback(res);

      return res;
    });
  }

  put(url, req) {
    return fetch(url, {
      method: 'PUT',
      headers: this.headers(),
      body: JSON.stringify(req),
    }).then((res) => res.json());
  }

  delete(url, errorCallback) {
    return fetch(url, {
      method: 'DELETE',
      headers: this.headers()
    }).then((res) => {
      if (res.status === 200) {
        return res.json();
      }

      errorCallback(res);

      return res;
    });
  }

  postFormData(url, formdata, onsuccess, errorCallback) {
    const headers = this.headers();
    delete headers['Content-Type'];
    return fetch(url, {
      method: 'POST',
      headers: headers,
      body: formdata,
    })
    .then(res => res.json())
    .then((response) => {
      if (response.status === 200) {
        onsuccess(response)
        return response;
      }
      errorCallback(response);
      return response;
    });
  }
}

export default new HttpService();
