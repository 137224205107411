import HttpService from 'ui-home/src/misc/HttpService/HttpService';
import getGatewayApiUrl from '../utils/getGatewayApiUrl';
import { noServicesSelected } from '../../constant';

export const INIT_STATE = 'INIT_STATE';
export const UPDATE_QUERY = 'UPDATE_QUERY';
export const GET_LOGS = 'GET_LOGS';
export const GET_SELECTED_SERVICES = 'GET_SELECTED_SERVICES';
export const LOADING_RESULTS = 'LOADING_RESULTS';
export const UPDATE_HAS_MORE_DATA = 'UPDATE_HAS_MORE_DATA';
export const UPDATE_SCROLL_MARKER = 'UPDATE_SCROLL_MARKER';
export const APPEND_LOGS = 'APPEND_LOGS';
export const PREPEND_LOGS = 'PREPEND_LOGS';
export const UPDATE_SELECTION = 'UPDATE_SELECTION';
export const NO_SERVICE_SELECTED = 'NO_SERVICE_SELECTED';
export const LOADING_DATA = 'LOADING_DATA';
export const CLEAR_AND_SHOW_ERROR = 'CLEAR_AND_SHOW_ERROR';

const updateQuery = (query) => ({ type: UPDATE_QUERY, query });

const initState = (isAuthMSEnabled, isPAMServicesEnabled, isTaskRunnerMSEnabled) => ({
  type: INIT_STATE,
  isAuthMSEnabled,
  isPAMServicesEnabled,
  isTaskRunnerMSEnabled,
});

const loadingResults = () => ({ type: LOADING_RESULTS, loading: true });

const loadedResults = () => ({ type: LOADING_RESULTS, loading: false });

const loadingData = () => ({ type: LOADING_DATA, loading: true });

const loadedData = () => ({ type: LOADING_DATA, loading: false });

const clearAndShowError = () => ({ type: CLEAR_AND_SHOW_ERROR });

const defaultCallBackAction = (res) => ({ type: GET_LOGS, results: res.logs });

const infiniteScrollAppendCallBackAction = (res) => ({ type: APPEND_LOGS, results: res.logs });

const infiniteScrollPrependCallBackAction = (res) => ({ type: PREPEND_LOGS, results: res.logs });

const updateSelection = (selection) => ({ type: UPDATE_SELECTION, selection });

const updateNoServicesSelected = (selected) => ({ type: NO_SERVICE_SELECTED, selected });

const getResults = (data, callBackAction = defaultCallBackAction, isLoadingFlag = true) => (dispatch) => {
  if (isLoadingFlag) {
    dispatch(loadingResults());
  } else {
    dispatch(loadingData());
  }

  if (data.servicename === noServicesSelected) {
    dispatch({
      type: UPDATE_HAS_MORE_DATA,
      hasMoreData: false,
    });
    dispatch({ type: GET_LOGS, results: [] });
    dispatch(updateNoServicesSelected(true));
  } else {
    const payload = { ...data, servicename: data.servicename.flatMap((s) => (s.indexOf(',') > -1 ? s.split(',') : s)) };
    const errorCallback = () => {
      dispatch(clearAndShowError());
      dispatch(loadedResults());
      dispatch(loadedData());
    };
    const response = HttpService.post(getGatewayApiUrl(), payload, errorCallback);

    response
      .then((res) => {
        dispatch({
          type: UPDATE_HAS_MORE_DATA,
          hasMoreData: res.logs.length >= payload.recordsCount,
        });
        dispatch(callBackAction(res));
        dispatch(loadedData());
      });
  }
};

const getResultsForScroll = (payload, isScrollDown = true) => (dispatch) => {
  const callback = isScrollDown ? infiniteScrollAppendCallBackAction : infiniteScrollPrependCallBackAction;

  dispatch(getResults(payload, callback, false));
};

const getSelectedServices = (checkedServices) => ({ type: GET_SELECTED_SERVICES, selectedServices: [...checkedServices] });

const updateMarkers = (scrollMarkers) => ({ type: UPDATE_SCROLL_MARKER, scrollMarkers });

export {
  loadingResults,
  loadedResults,
  updateQuery,
  updateMarkers,
  updateSelection,
  getResults,
  getSelectedServices,
  getResultsForScroll,
  initState,
  updateNoServicesSelected,
};
