import { useSelector } from 'react-redux';
import getIconColors, { getIconColorCodes } from 'ui-home/src/utils/getIconColors';
import { checkEnvironment } from 'ui-common/src/lib/utils';
import messages from './messages';

function getRGBFactors(color) {
  try {
    const tempElem = document.createElement('div');
    tempElem.style.color = color;
    document.body.appendChild(tempElem);

    const rgbColor = window.getComputedStyle(tempElem).color;
    const match = rgbColor.match(/\d+/g);

    if (!match || match.length < 3) {
      return null;
    }

    const r = parseInt(match[0]);
    const g = parseInt(match[1]);
    const b = parseInt(match[2]);

    document.body.removeChild(tempElem);

    return { r, g, b };
  } catch (error) {
    console.error('Error determining color brightness:', error.message);
    return null;
  }
}

function isColorLight(color) {
  try {
    const { r, g, b } = getRGBFactors(color);

    const brightness = (r * 299 + g * 587 + b * 114) / 1000;

    return brightness > 128;
  } catch (error) {
    console.error('Error determining color brightness:', error.message);
    return null;
  }
}

export const useTheme = () => {
  const activeTheme = useSelector((state) => state.adminHome?.brandingConfig?.activeTheme);
  const {
    primary, secondary, background, isMulticolor,
  } = activeTheme?.styles || {};

  const getPrimaryColor = (type) => primary || getIconColors(type);
  const getSecondaryColor = (type) => secondary || getIconColors(type);
  const getTextColor = () => getSecondaryColor();
  const getBackgroundColor = () => background || getIconColorCodes('background');

  const root = document.documentElement;
  const isLight = isColorLight(getBackgroundColor());
  const searchBg = isLight ? 'rgba(0, 99, 247, 0.04)' : 'rgba(0, 0, 0, 0.8)';
  const searchBorder = isLight ? '#CFD9E9' : '#00245B';
  const subtTextColor = isLight ? 'rgba(0, 0, 0, 0.7)' : 'rgba(255, 255, 255, 0.7)';
  const hamburgerColor = isLight ? 'rgba(0, 0, 0, 1)' : 'rgba(255, 255, 255, 1)';
  const { r, g, b } = getRGBFactors(isMulticolor ? getIconColorCodes('home') : getPrimaryColor());
  const hoverBackground = isLight ? `rgba(${r}, ${g}, ${b}, 0.08)` : `rgba(${r}, ${g}, ${b}, 0.08)`;
  const selectedBackground = isLight ? `rgba(${r}, ${g}, ${b}, 0.1)` : `rgba(${r}, ${g}, ${b}, 0.1)`;

  const setThemeVars = () => {
    root.style.setProperty('--SIDEMENU-BACKGROUND', getBackgroundColor());
    root.style.setProperty('--SIDEMENU-TEXT-COLOR', getSecondaryColor());
    root.style.setProperty('--SIDEMENU-ICON-PRIMARY', getPrimaryColor());
    root.style.setProperty('--SIDEMENU-MENUITEM-BACKGROUND', searchBg);
    root.style.setProperty('--SIDEMENU-SEARCH-BORDER', searchBorder);
    root.style.setProperty('--SIDEMENU-SUBTEXT-COLOR', subtTextColor);
    root.style.setProperty('--SIDEMENU-ICON-HAMBURGER', hamburgerColor);

    root.style.setProperty('--SIDEMENU-ICON-ACCESS', isMulticolor ? getIconColorCodes('home') : getPrimaryColor());
    root.style.setProperty('--SIDEMENU-ICON-ANALYZE', isMulticolor ? getIconColorCodes('intelligence') : getPrimaryColor());
    root.style.setProperty('--SIDEMENU-ICON-DESIGN', isMulticolor ? getIconColorCodes('design') : getPrimaryColor());
    root.style.setProperty('--SIDEMENU-ICON-CONFIGURE', isMulticolor ? getIconColorCodes('control-centre') : getPrimaryColor());
    root.style.setProperty('--SIDEMENU-ICON-ADMIN', isMulticolor ? getIconColorCodes('admin') : getPrimaryColor());
    root.style.setProperty('--SIDEMENU-ICON-SOD', isMulticolor ? getIconColorCodes('sod') : getPrimaryColor());
    root.style.setProperty('--SIDEMENU-ICON-SETTING', isMulticolor ? getIconColorCodes('certifications') : getPrimaryColor());
    // root.style.setProperty('--SIDEMENU-HOVER-TEXT-COLOR');
    root.style.setProperty('--SIDEMENU-HOVER-BACKGROUND', hoverBackground);
    root.style.setProperty('--SIDEMENU-SELECTED-BACKGROUND', selectedBackground);
  };

  setThemeVars();

  return {
    activeTheme,
    getPrimaryColor,
    getSecondaryColor,
    getTextColor,
    getBackgroundColor,
    setThemeVars,
    isLight,
  };
};

export const getPrimaryColor = (type, themeConfig) => 'red' || getIconColors(type);
export const getSecondaryColor = (type, themeConfig) => 'yellow' || getIconColors(type);
export const getTextColor = (type, themeConfig) => getSecondaryColor();
export const getBackgroundColor = (themeConfig) => 'darkblue';

export const getDisplayedTheme = (themeData) => {
  if (themeData) {
    if (themeData.isOOTB) {
      const darkVariant = themeData.find((variant) => variant.appearance === 'dark');
      return darkVariant || themeData[0];
    }
    return themeData[0];
  }
  return false;
};

export const setToLS = (key, value) => {
  window.localStorage.setItem(key, JSON.stringify(value));
};

export const getFromLS = (key) => {
  const value = window.localStorage.getItem(key);

  if (value) {
    return JSON.parse(value);
  }
};

export const convertDisplayNametoId = (inputString) => {
  if (typeof inputString !== 'string') {
    return false;
  }

  if (inputString.trim() === '') {
    return false;
  }

  const resultString = inputString.toLowerCase().replace(/\s+/g, '-');

  return resultString;
};

// A to Z, a-z, numbers, hiphen, underscrore and space
const themeNameRegex = /^[A-Za-z0-9\s_-]+$/g;
export const isInvalid = (type, val) => {
  if (type === 'name') {
    const themeNameReg = new RegExp(themeNameRegex);
    if (!val) {
      return 'UI.Gen2.Admin.Branding.Theme.Creation.Name.Error.Empty';
    }
    if (!themeNameReg.test(val)) {
      return 'UI.Gen2.Admin.Branding.Theme.Creation.Name.Error.AllowedChars';
    }
    if (val?.length > 30) {
      return 'UI.Gen2.Admin.Branding.Theme.Creation.Name.Error.NoOfCharsLimit';
    }
  }
  return false;
};

export const isEmpty = (value) => {
  if (value === null || value === undefined) {
    return true;
  }

  if (Array.isArray(value)) {
    return value.length === 0;
  }

  if (typeof value === 'object') {
    return Object.keys(value).length === 0;
  }

  // For other falsy values (0, false, '')
  return !value;
};

export const message = (intl, id, defaultMessage) => {
  if (!intl || id === undefined || id === null || typeof id !== 'string' || id === '') return '';
  if (defaultMessage) return intl.formatMessage({ id, defaultMessage });
  if (messages[id]) return intl.formatMessage({ id, defaultMessage: messages[id] });
  return intl.formatMessage({ id, defaultMessage: id });
};

export const getHostUrl = () => (window.location.hostname === 'localhost' ? '/ECM' : checkEnvironment().ECM_URL);

export const loadImage = (data) => {
  // Convert Blob to data URL
  try {
    return URL.createObjectURL(data);
  } catch {
    return '';
  }
};
