import React from 'react';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Fab from '@material-ui/core/Fab';
import { useSelector } from 'react-redux';
import styles from './DownloadFloat.module.css';
import Tooltip from '../Tooltip/Tooltip';
import { defaultConfig } from '../../../reducers/logsReducer';
import CSVDownload from '../CSVDownload/CSVDownload';
import getPrevScrollMarkerId from '../../../utils/getPrevScrollMarkerId';
import getCurrentScrollMarkerId from '../../../utils/getCurrentScrollMarkerId';
import getDataFromApi from '../../../utils/getDataFromApi';

const DownloadFloat = ({ isDisplayed = false, intl }) => {
  const {
    searchKey, fromDate, toDate, servicename,
  } = useSelector((state) => state.admin?.logs.query);
  const scrollMarkers = useSelector((state) => state.admin?.logs.scrollMarkers);
  const scrollId = getPrevScrollMarkerId(scrollMarkers, getCurrentScrollMarkerId(scrollMarkers));
  const query = {
    searchKey,
    fromDate,
    toDate,
    searchAfter: scrollId,
    recordsCount: defaultConfig.noOfRecordsToDownload,
    servicename,
  };
  const asyncFnComputeData = () => Promise.resolve(getDataFromApi(query));

  if (isDisplayed) {
    return (
      <Tooltip
        title={intl.formatMessage({ id: 'Admin.Log.Viewer.contextual.downloadToolTip' })}
        aria-label="Download records"
      >
        <div className={styles.downloadIcon}>
          <CSVDownload data={asyncFnComputeData} intl={intl} showLabel={false}>
            <Fab color="primary" aria-label="download">
              <CloudDownloadIcon />
            </Fab>
          </CSVDownload>
        </div>
      </Tooltip>
    );
  }

  return null;
};

export default DownloadFloat;
