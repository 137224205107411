import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Global404, NotFound, Route404 } from '@saviynt/common';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import LocalizedWrapper from '../components/LandingPage/LocalizedWrapper';
import { REQUEST_BASE_URL } from '../misc/constants';

import LandingPage from './LandingPage/LandingPage';
import CheckOutCredential from './ModalPages/CheckOutCredential';
import CheckOutRole from './ModalPages/CheckOutRole';
import ConnectToSession from './ModalPages/ConnectToSession';

const queryClient = new QueryClient();

function Routes() {
  const allowedRoutes = {
    staticRoutes: [
      REQUEST_BASE_URL,
      `${REQUEST_BASE_URL}/check-out-credential`,
      `${REQUEST_BASE_URL}/connect-to-session`,
      `${REQUEST_BASE_URL}/check-out-role`,
      `${REQUEST_BASE_URL}/launch/:launchId`,
    ],
    dynamicRoutes: [],
  };

  const pamGen2Flag = window.localStorage.getItem('pamGen2');

  const showGen2Pages = Boolean(parseInt(pamGen2Flag, 10));

  if (!showGen2Pages) return null; // prevent gen1 components in host from rendering the global404 below.

  return (
    <div>
      <LocalizedWrapper>
        <QueryClientProvider client={queryClient}>
          <Suspense key='PAM' fallback={() => {}}>
            <Route404
              routes={allowedRoutes}
              render={(path) => <Global404 path={path} />}>
              <Switch>
                {showGen2Pages && (
                  <>
                    <Route
                      exact
                      path={REQUEST_BASE_URL}
                      component={LandingPage}
                    />
                    <Route
                      exact
                      path={`${REQUEST_BASE_URL}/check-out-credential`}
                      component={CheckOutCredential}
                    />
                    <Route
                      exact
                      path={`${REQUEST_BASE_URL}/connect-to-session`}
                      component={ConnectToSession}
                    />
                    <Route
                      exact
                      path={`${REQUEST_BASE_URL}/check-out-role`}
                      component={CheckOutRole}
                    />
                  </>
                )}
                {showGen2Pages && <Route component={NotFound} />}
              </Switch>
            </Route404>
          </Suspense>
        </QueryClientProvider>
      </LocalizedWrapper>
    </div>
  );
}

export default Routes;
