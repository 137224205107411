import './InSeparator.css';

import PropTypes from 'prop-types';
import React from 'react';

const COLORS = {
  neutralSubtle: 'neutralSubtle',
  neutralInverse: 'neutralInverse',
};

const FONTS = {
  body2: 'body2',
  body3: 'body3',
};

function InSeparator({ color, font }) {
  return (
    <p className={`InSeparator InSeparator-${color} InSeparator-${font}`}>in</p>
  );
}

InSeparator.propTypes = {
  color: PropTypes.oneOf(Object.values(COLORS)),
  font: PropTypes.oneOf(Object.values(FONTS)),
};

InSeparator.defaultProps = {
  color: COLORS.neutralSubtle,
  font: FONTS.body3,
};

export default InSeparator;
