import React from 'react';
const MenuHamburger = () => (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5 28.8885C5 28.4282 5.3731 28.0551 5.83333 28.0551H34.1667C34.6269 28.0551 35 28.4282 35 28.8885C35 29.3487 34.6269 29.7218 34.1667 29.7218H5.83333C5.3731 29.7218 5 29.3487 5 28.8885Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5 19.9996C5 19.5393 5.3731 19.1662 5.83333 19.1662H34.1667C34.6269 19.1662 35 19.5393 35 19.9996C35 20.4598 34.6269 20.8329 34.1667 20.8329H5.83333C5.3731 20.8329 5 20.4598 5 19.9996Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5 11.1107C5 10.6504 5.3731 10.2773 5.83333 10.2773H34.1667C34.6269 10.2773 35 10.6504 35 11.1107C35 11.5709 34.6269 11.944 34.1667 11.944H5.83333C5.3731 11.944 5 11.5709 5 11.1107Z" fill="white"/>
    </svg>
);
export default MenuHamburger;

