import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { ListItemIcon, ListItemText } from '@material-ui/core';
import MenuWrapper from './MenuWrapper';

const useStyles = makeStyles(() => ({
  iconContainer: {
    marginRight: 10,
    minWidth: 0,
  },
  label: {
    fontWeight: 500,
    fontSize: '14px',
    color: '#141419',
  },
}));

const MenuItem = ({ label, icon, handleClick }) => {
  const classes = useStyles();

  return (
    <MenuWrapper
      onMenuClick={handleClick}
    >
      <ListItemIcon
        className={classes.iconContainer}
      >
        {icon}
      </ListItemIcon>
      <ListItemText
        primary={label}
        classes={{
          primary: classes.label,
        }}
      />
    </MenuWrapper>
  );
};

MenuItem.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default injectIntl(MenuItem);
