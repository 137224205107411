import React from 'react';
import { Logger, useLocalize } from '@saviynt/common';
import { Button, ModalIsland } from '@saviynt/design-system';
import PropTypes from 'prop-types';

import {
  getCancelApi,
  getCheckInApi,
  getListRowApi,
} from '../../utilities/api/getDataFromApi';

const KIND = {
  CHECKIN: 'checkIn',
  END: 'end',
  CANCEL_SESSION: 'cancelSession',
  CANCEL_REQUEST: 'cancelRequest',
};

function CheckInModal({
  isModalOpen,
  setIsModalOpen,
  row,
  setListRowData,
  kind,
  userName,
  setCheckInModalKind,
  setAlertBannerData,
}) {
  const accountName = row?.accountName;

  // Roles
  const isRole = Boolean(row?.roleKey);
  const roleName = row?.roleDisplayName || row?.roleName;
  const isRoleWithRoleName = isRole && roleName;

  const localize = useLocalize();

  const msgs = {
    // Check In
    pam: {
      checkIn: {
        modalTitle: {
          id: 'pam.checkIn.title',
          defaultMessage: 'Check In',
        },
        modalDescription: {
          id: 'pam.checkIn.description',
          defaultMessage:
            'Are you done with the credential and ready to check in?',
        },
        successBanner: {
          id: 'pam.checkIn.success',
          defaultMessage:
            "You've checked in the credential and your session has ended.",
        },
        modalDescriptionRole: {
          id: 'pam.checkIn.descriptionRole',
          defaultMessage: 'Are you done with the role and ready to check in?',
        },
        successBannerRole: {
          id: 'pam.checkIn.successRole',
          defaultMessage: "You've checked in your session for {roleName}.",
        },
        alreadyBanner: {
          id: 'SESSION.ALREADY.ENDED',
          defaultMessage: 'This session has already ended.',
        },
        error: {
          id: 'pam.checkIn.error',
          defaultMessage: 'Error in check-in API:',
        },
      },

      end: {
        // End Session
        modalTitle: {
          id: 'pam.end.title',
          defaultMessage: 'End Session',
        },
        modalDescription: {
          id: 'pam.end.description',
          defaultMessage: 'Are you done with the session and ready to end it?',
        },
        successBanner: {
          id: 'pam.end.success',
          defaultMessage: `You've ended your session for {accountName}.`,
        },
        failureBanner: {
          id: 'pam.end.failure',
          defaultMessage: 'End Session Failed',
        },
        successBannerRole: {
          id: 'pam.end.successRole',
          defaultMessage: `You've ended your session for {roleName}.`,
        },
      },

      cancelSession: {
        // Cancel Session
        modalTitle: {
          id: 'pam.cancelSession.title',
          defaultMessage: 'Cancel Session',
        },
        modalDescription: {
          id: 'pam.cancelSession.description',
          defaultMessage: 'Do you want to cancel this session?',
        },
        successBanner: {
          id: 'pam.cancelSession.success',
          defaultMessage: `You successfully canceled your session for {accountName}.`,
        },
        failureBanner: {
          id: 'pam.cancelSession.failure',
          defaultMessage: `There was a problem canceling your session for {accountName}. You can try again or contact your administrator.`,
        },
        successBannerRole: {
          id: 'pam.cancelSession.successRole',
          defaultMessage: `You successfully canceled your session for {roleName}.`,
        },
        failureBannerRole: {
          id: 'pam.cancelSession.failureRole',
          defaultMessage: `There was a problem canceling your session for {roleName}. You can try again or contact your administrator.`,
        },
      },

      cancelRequest: {
        // Cancel Request
        modalTitle: {
          id: 'pam.cancelRequest.title',
          defaultMessage: 'Cancel Request',
        },
        modalDescription: {
          id: 'pam.cancelRequest.description',
          defaultMessage: 'Do you want to cancel this request?',
        },
        successBanner: {
          id: 'pam.cancelRequest.success',
          defaultMessage: `You successfully canceled your request for {accountName}.`,
        },
        failureBanner: {
          id: 'pam.cancelRequest.failure',
          defaultMessage: `There was a problem canceling your request for {accountName}. You can try again or contact your administrator.`,
        },
        successBannerRole: {
          id: 'pam.cancelRequest.successRole',
          defaultMessage: `You successfully canceled your request for {roleName}.`,
        },
        failureBannerRole: {
          id: 'pam.cancelRequest.failureRole',
          defaultMessage: `There was a problem canceling your request for {roleName}. You can try again or contact your administrator.`,
        },
      },

      alertBanner: {
        failureTitle: {
          id: 'pam.checkin.failedTitle',
          defaultMessage: `Failed!`,
        },
        successTitle: {
          id: 'pam.checkin.successTitle',
          defaultMessage: `Success!`,
        },
        sessionCanceledTitle: {
          id: 'pam.checkin.sessionCanceledTitle',
          defaultMessage: `Session Canceled.`,
        },
        sessionNotCanceledTitle: {
          id: 'pam.checkin.sessionNotCanceledTitle',
          defaultMessage: `Session Not Canceled.`,
        },
        requestCanceledTitle: {
          id: 'pam.checkin.requestCanceledTitle',
          defaultMessage: `Request Canceled.`,
        },
        requestNotCanceledTitle: {
          id: 'pam.checkin.requestNotCanceledTitle',
          defaultMessage: `Request Not Canceled.`,
        },
      },
    },
  };

  function alertBannerDescription() {
    if (KIND.CHECKIN === kind) {
      if (isRoleWithRoleName) {
        return localize(msgs.pam.checkIn.successBannerRole, { roleName });
      }

      return localize(msgs.pam.checkIn.successBanner);
    }

    if (KIND.END === kind) {
      if (isRoleWithRoleName) {
        return localize(msgs.pam.end.successBannerRole, { roleName });
      }

      return localize(msgs.pam.end.successBanner, { accountName });
    }

    if (KIND.CANCEL_SESSION === kind) {
      if (isRoleWithRoleName) {
        return localize(msgs.pam.cancelSession.successBannerRole, { roleName });
      }

      return localize(msgs.pam.cancelSession.successBanner, { accountName });
    }

    return localize(msgs.pam.end.successBanner);
  }

  const handleCheckIn = () => {
    setIsModalOpen(false);

    if (
      KIND.CHECKIN === kind ||
      KIND.END === kind ||
      KIND.CANCEL_SESSION === kind
    ) {
      getCheckInApi(row)
        .then((response) => {
          const isSuccess = response.status === 200;

          function getDescription() {
            if (isSuccess) {
              return Promise.resolve(alertBannerDescription());
            }

            return response.json().then((jsonData) => {
              if (jsonData.error === 'SESSION.ALREADY.ENDED') {
                return localize(msgs.pam.checkIn.alreadyBanner);
              }

              if (KIND.CANCEL_SESSION === kind) {
                if (isRoleWithRoleName) {
                  return localize(msgs.pam.cancelSession.failureBannerRole, {
                    roleName,
                  });
                }

                return localize(msgs.pam.cancelSession.failureBanner, {
                  accountName,
                });
              }

              return localize(jsonData.error);
            });
          }

          let colorTheme;
          let title;

          if (isSuccess) {
            if (KIND.CANCEL_SESSION === kind) {
              colorTheme = 'Information';
              title = localize(msgs.pam.alertBanner.sessionCanceledTitle);
            } else {
              colorTheme = 'Success';
              title = localize(msgs.pam.alertBanner.successTitle);
            }
          } else {
            colorTheme = 'Critical';

            if (KIND.CANCEL_SESSION === kind) {
              title = localize(msgs.pam.alertBanner.sessionNotCanceledTitle);
            } else {
              title = localize(msgs.pam.alertBanner.failureTitle);
            }
          }

          getDescription().then((description) => {
            setAlertBannerData((prevState) => [
              ...prevState,
              {
                colorTheme,
                title: localize(title),
                description: localize(description),
              },
            ]);

            setCheckInModalKind('');

            getListRowApi().then((res) => {
              setListRowData(res);
            });
          });
        })
        .catch((error) => {
          Logger.error(localize(msgs.pam.checkIn.error), error);
        });
    }

    if (KIND.CANCEL_REQUEST === kind) {
      getCancelApi(row.arsRequestKey, userName)
        .then((response) => {
          const isSuccess =
            response?.response?.result?.toLowerCase() === 'successfull';

          function getDescription() {
            if (isSuccess) {
              if (isRoleWithRoleName) {
                return localize(msgs.pam.cancelRequest.successBannerRole, {
                  roleName,
                });
              }

              return localize(msgs.pam.cancelRequest.successBanner, {
                accountName,
              });
            }

            if (isRoleWithRoleName) {
              return localize(msgs.pam.cancelRequest.failureBannerRole, {
                roleName,
              });
            }

            return localize(msgs.pam.cancelRequest.failureBanner, {
              accountName,
            });
          }

          setAlertBannerData((prevState) => [
            ...prevState,
            {
              colorTheme: isSuccess ? 'Information' : 'Critical',
              title: isSuccess
                ? localize(msgs.pam.alertBanner.requestCanceledTitle)
                : localize(msgs.pam.alertBanner.requestNotCanceledTitle),
              description: getDescription(),
            },
          ]);
          setCheckInModalKind('');
          getListRowApi().then((res) => {
            setListRowData(res);
          });
        })
        .catch((error) => {
          Logger.error(localize(msgs.pam.checkIn.error), error);
        });
    }
  };

  function alertModalDescription() {
    if (KIND.CHECKIN === kind) {
      if (isRole) {
        return localize(msgs.pam.checkIn.modalDescriptionRole);
      }

      return localize(msgs.pam.checkIn.modalDescription);
    }

    if (KIND.END === kind) {
      return localize(msgs.pam.end.modalDescription);
    }

    if (KIND.CANCEL_SESSION === kind) {
      return localize(msgs.pam.cancelSession.modalDescription);
    }

    if (KIND.CANCEL_REQUEST === kind) {
      return localize(msgs.pam.cancelRequest.modalDescription);
    }

    return localize(msgs.pam.end.modalDescription);
  }

  function alertModalTitle() {
    if (KIND.CHECKIN === kind) {
      return localize(msgs.pam.checkIn.modalTitle);
    }

    if (KIND.END === kind) {
      return localize(msgs.pam.end.modalTitle);
    }

    if (KIND.CANCEL_SESSION === kind) {
      return localize(msgs.pam.cancelSession.modalTitle);
    }

    if (KIND.CANCEL_REQUEST === kind) {
      return localize(msgs.pam.cancelRequest.modalTitle);
    }

    return localize(msgs.pam.end.modalTitle);
  }

  return (
    <ModalIsland
      kind='warning'
      alertTitle={alertModalDescription()}
      onClose={() => setIsModalOpen(false)}
      isOpen={isModalOpen}
      primaryButton={
        <Button
          type='button'
          kind='outlined'
          size='medium'
          onClick={handleCheckIn}>
          {alertModalTitle()}
        </Button>
      }
      secondaryButton={
        <Button
          type='button'
          kind='ghost'
          size='medium'
          onClick={() => {
            setIsModalOpen(false);
          }}>
          Cancel
        </Button>
      }
    />
  );
}

CheckInModal.defaultProps = { isModalOpen: false };

CheckInModal.propTypes = {
  setIsModalOpen: PropTypes.func.isRequired,
  setListRowData: PropTypes.func.isRequired,
  row: PropTypes.shape({
    requestAccessKey: PropTypes.number,
    accountName: PropTypes.string,
    roleKey: PropTypes.string,
    roleName: PropTypes.string,
    roleDisplayName: PropTypes.string,
    endpointDisplayName: PropTypes.string,
    arsRequestKey: PropTypes.number,
  }).isRequired,
  isModalOpen: PropTypes.bool,
  kind: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  setCheckInModalKind: PropTypes.func.isRequired,
  setAlertBannerData: PropTypes.func.isRequired,
};

export default CheckInModal;
