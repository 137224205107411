import PropTypes from 'prop-types';

export const ModuleDetails = PropTypes.shape({
  requestAccessKey: PropTypes.number,
  name: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  accountName: PropTypes.string,
  accountType: PropTypes.string,
  displayName: PropTypes.string,
  credentialType: PropTypes.string,
  description: PropTypes.string,
  accountKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  accountConfig: PropTypes.shape({
    defaultrequestabletimeforidinsecs: PropTypes.string,
    maxrequestabletimeforidinsecs: PropTypes.string,
    defaultrequestabletimeinsecs: PropTypes.string,
    maxrequestabletimeinsecs: PropTypes.string,
    diffbetweenrequests: PropTypes.string,
    authenticationType: PropTypes.string,
    'Saviynt-Status': PropTypes.shape({
      pamState: PropTypes.string,
      pamType: PropTypes.string,
      errorDetails: PropTypes.string,
      justInTime: PropTypes.string,
      showPassword: PropTypes.string,
    }),
  }),
  customproperty2: PropTypes.string,
  customproperty5: PropTypes.string,
  customproperty7: PropTypes.string,
  customproperty9: PropTypes.string,
  customproperty10: PropTypes.string,
  customproperty11: PropTypes.string,
  customproperty15: PropTypes.string,
  customproperty16: PropTypes.string,
  customproperty17: PropTypes.string,
  customproperty30: PropTypes.string,
  customproperty35: PropTypes.string,
  endpointKey: PropTypes.number,
  endointName: PropTypes.string,
  resourceType: PropTypes.string,
  applicationType: PropTypes.string,
  connectionType: PropTypes.string,
  status: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  applicationTypeDisplayName: PropTypes.string,
  applicationTypeKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  applicationTypeName: PropTypes.string,
  assetType: PropTypes.string,
  endpointDisplayName: PropTypes.string,
  endpointName: PropTypes.string,
  endpointStatus: PropTypes.number,
  epCustomproperty6: PropTypes.string,
  epPamConfig: PropTypes.shape({
    maxConcurrentSession: PropTypes.string,
    rotateKey: PropTypes.string,
  }),
  exclusiveAccess: PropTypes.bool,
  location: PropTypes.string,
  pamPlatformType: PropTypes.string,
  platformType: PropTypes.string,
  remoteAppEnabled: PropTypes.bool,
  sessionStartDate: PropTypes.string,
  sessionEndDate: PropTypes.string,
  sessionStatus: PropTypes.number,
  remoteAppMetadata: PropTypes.shape({
    name: PropTypes.string,
    displayName: PropTypes.string,
    icon: PropTypes.string,
    internalAttr: PropTypes.shape({ client: PropTypes.string }),
    key: PropTypes.string,
    setup: PropTypes.string,
  }),
  summary: PropTypes.shape({
    syscritical: PropTypes.string,
    privileged: PropTypes.string,
    updatedate: PropTypes.string,
    rolename: PropTypes.string,
    confidentiality: PropTypes.string,
    rolekey: PropTypes.string,
    soxcritical: PropTypes.string,
    description: PropTypes.string,
    roletype: PropTypes.string,
    risk: PropTypes.string,
    status: PropTypes.number,
    updateuser: PropTypes.number,
  }),
});

export const Session = PropTypes.shape({
  requestAccessKey: PropTypes.number,
  requestId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  arsRequestKey: PropTypes.number,
  sessionStartDate: PropTypes.string,
  sessionEndDate: PropTypes.string,
  sessionStatus: PropTypes.number,
  ticketId: PropTypes.string,
  requestType: PropTypes.number,
  requestAccessStatus: PropTypes.number,
  endpointName: PropTypes.string,
  endpointDisplayName: PropTypes.string,
  username: PropTypes.string,
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  accountName: PropTypes.string,
  accountKey: PropTypes.number,
  accountId: PropTypes.string,
  endpointKey: PropTypes.number,
  pamType: PropTypes.string,
  accountConfig: PropTypes.shape({
    defaultrequestabletimeforidinsecs: PropTypes.string,
    maxrequestabletimeforidinsecs: PropTypes.string,
    defaultrequestabletimeinsecs: PropTypes.string,
    maxrequestabletimeinsecs: PropTypes.string,
    diffbetweenrequests: PropTypes.string,
    authenticationType: PropTypes.string,
    'Saviynt-Status': PropTypes.shape({
      pamState: PropTypes.string,
      pamType: PropTypes.string,
      errorDetails: PropTypes.string,
      justInTime: PropTypes.string,
      showPassword: PropTypes.string,
    }),
  }),
  sendTargetEndpointKey: PropTypes.bool,
  accountType: PropTypes.string,
  pamAccountType: PropTypes.string,
  federatedAccount: PropTypes.bool,
  accountStatus: PropTypes.string,
  endpointDescription: PropTypes.string,
  epCustomproperty9: PropTypes.string,
  epCustomproperty10: PropTypes.string,
  epCustomproperty16: PropTypes.string,
  assetType: PropTypes.string,
  connectionType: PropTypes.string,
  platformType: PropTypes.string,
  pamPlatformType: PropTypes.string,
  type: PropTypes.string,
});

export const SessionDetails = PropTypes.shape({
  userName: PropTypes.shape({
    value: PropTypes.string,
    effect: PropTypes.string,
  }),
  password: PropTypes.shape({
    value: PropTypes.string,
    effect: PropTypes.string,
  }),
  host: PropTypes.shape({
    value: PropTypes.string,
    effect: PropTypes.string,
  }),
  port: PropTypes.shape({
    value: PropTypes.string,
    effect: PropTypes.string,
  }),
  publicIp: PropTypes.shape({
    value: PropTypes.string,
    effect: PropTypes.string,
  }),
  privateKey: PropTypes.shape({
    value: PropTypes.string,
    effect: PropTypes.string,
  }),
});

export const RequestDetails = PropTypes.shape({
  requestType: PropTypes.number,
  requestKey: PropTypes.number,
  requestDescription: PropTypes.string,
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      commentor: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        userName: PropTypes.string,
      }),
      comment: PropTypes.string,
    })
  ),
  requestDate: PropTypes.string,
  status: PropTypes.number,
  statusDescription: PropTypes.string,
  showMitigatingControls: PropTypes.bool,
  showSODExportInRequest: PropTypes.bool,
  commentReqAtRequestApprovalLevelWithSod: PropTypes.bool,
  commentReqAtRequestApprovalLevelWithOrWithoutSod: PropTypes.bool,
  commentReqAtBothLevel: PropTypes.bool,
  commentReqAtEachSodLevel: PropTypes.bool,
  userAttributeWhileRequest: PropTypes.arrayOf(PropTypes.shape({})),
  requestedBy: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    userName: PropTypes.string,
    userKey: PropTypes.string,
  }),
  requestedFor: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    userName: PropTypes.string,
    email: PropTypes.string,
  }),
  endpoints: PropTypes.arrayOf(
    PropTypes.shape({
      applicationType: PropTypes.string,
      requestType: PropTypes.number,
      accountName: PropTypes.string,
      endDate: PropTypes.string,
      endpointKey: PropTypes.number,
      sessionStatus: PropTypes.string,
      endpointName: PropTypes.string,
      endpointDisplayName: PropTypes.string,
      remoteApp: PropTypes.shape({}),
      uniqueEndpointName: PropTypes.string,
      connectionType: PropTypes.string,
      requestDescription: PropTypes.string,
      accountKey: PropTypes.number,
      credentialType: PropTypes.string,
      statusDescription: PropTypes.string,
      approvalStatusHistory: PropTypes.string,
      accessKey: PropTypes.number,
      approvals: PropTypes.arrayOf(
        PropTypes.shape({
          statusDescription: PropTypes.string,
          approvalDate: PropTypes.string,
          dueDate: PropTypes.string,
          approvalType: PropTypes.string,
          assignee: PropTypes.arrayOf(
            PropTypes.shape({
              firstName: PropTypes.string,
              lastName: PropTypes.string,
              userName: PropTypes.string,
              displayName: PropTypes.string,
              assigneeStatus: PropTypes.string,
            })
          ),
          businessJustification: PropTypes.string,
          status: PropTypes.number,
        })
      ),
      systemType: PropTypes.string,
      businessJustification: PropTypes.string,
      pamType: PropTypes.string,
      startDate: PropTypes.string,
      tasks: PropTypes.arrayOf(PropTypes.string),
    })
  ),
  enableSnowShow: PropTypes.bool,
  userAttributes: PropTypes.arrayOf(
    PropTypes.shape({
      attributeName: PropTypes.string,
      attributeValue: PropTypes.string,
    })
  ),
  allowRequestUpdate: PropTypes.bool,
  showUserAttrInRequestHomeHistAndAppr: PropTypes.arrayOf(PropTypes.string),
  attachmentConfiguration: PropTypes.shape({
    allowedExtension: PropTypes.string,
    mandatoryAttachment: PropTypes.bool,
    maxSize: PropTypes.string,
    maxCount: PropTypes.string,
    disableAttachment: PropTypes.bool,
  }),
  showEntitlementDetails: PropTypes.bool,
});

export const CredentialRequest = PropTypes.shape({
  accesstype: PropTypes.string.isRequired,
  requestor: PropTypes.string.isRequired,
  requestedfor: PropTypes.string.isRequired,
  comments: PropTypes.string,
  sessions: PropTypes.arrayOf(
    PropTypes.shape({
      businessjustification: PropTypes.string.isRequired,
      startdate: PropTypes.string.isRequired,
      enddate: PropTypes.string.isRequired,
      accountId: PropTypes.number.isRequired,
    })
  ),
  pamtype: PropTypes.oneOf(['credentials', 'justintime', undefined]),
});

export const CredentialRequestAccountSelection = PropTypes.shape({
  value: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
  maxtime: PropTypes.string,
});

export const DurationMenuOption = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.string,
  isChecked: PropTypes.bool,
});

export const AppLauncherSelectionModel = PropTypes.shape({
  key: PropTypes.string,
  displayName: PropTypes.string,
  icon: PropTypes.string,
  description: PropTypes.string,
  // AD only
  nativeLaunch: PropTypes.string,
});

export const RemoteAppMetadataArrayModel = PropTypes.arrayOf(
  PropTypes.shape({
    key: PropTypes.string,
    displayName: PropTypes.string,
    icon: PropTypes.string,
    setup: PropTypes.string,
    internalAttr: PropTypes.shape({
      client: PropTypes.string,
      key: PropTypes.string,
      setup: PropTypes.string,
    }),
    commandTemplate: PropTypes.string,
    // AD only
    nativeLaunch: PropTypes.string,
  })
);

export const DurationAccordionSnapshot = PropTypes.shape({
  isNowTab: PropTypes.bool,
  isDurationCustom: PropTypes.bool,
  customDurationInputValue: PropTypes.string,
  checkedIntervalOption: PropTypes.string,
  futureTabStartDate: PropTypes.instanceOf(Date),
  futureTabEndDate: PropTypes.instanceOf(Date),
  durationChipValue: PropTypes.number,
});

export const SessionLauncherDetails = PropTypes.shape({
  osType: PropTypes.string,
  remoteAppMetadata: PropTypes.shape({
    nativeLaunch: PropTypes.string,
    displayName: PropTypes.string,
  }),
  accessKey: PropTypes.string.isRequired,
  requestId: PropTypes.string.isRequired,
  userKey: PropTypes.number.isRequired,
});

export const RoleDetails = PropTypes.shape({
  roleName: PropTypes.string,
  roleStatus: PropTypes.string,
  roleType: PropTypes.string,
  soxCritical: PropTypes.string,
  sysCritical: PropTypes.string,
  privileged: PropTypes.string,
  // TODO: remove after it is corrected on the backend to be spelled correctly
  priviliged: PropTypes.string,
  confidentiality: PropTypes.string,
  endpoints: PropTypes.arrayOf(
    PropTypes.shape({
      endpointKey: PropTypes.number,
      name: PropTypes.string,
      displayName: PropTypes.string,
      assetType: PropTypes.string,
      connectionType: PropTypes.string,
      platformType: PropTypes.string,
    })
  ),
});

export const Entitlement = PropTypes.shape({
  evKey: PropTypes.number,
  evEntitlementId: PropTypes.string,
  evEntitlementValue: PropTypes.string,
  etName: PropTypes.string,
  etDisplayName: PropTypes.string,
  evDescription: PropTypes.string,
  evDisplayName: PropTypes.string,
  evStatus: PropTypes.number,
  epKey: PropTypes.number,
  endpointName: PropTypes.string,
  epDisplayName: PropTypes.string,
  ssKey: PropTypes.number,
  ssSystemName: PropTypes.string,
  ssDisplayName: PropTypes.string,
  assetType: PropTypes.string,
  platformType: PropTypes.string,
});
