import { useCallback, useRef, useState } from 'react';

const MIN_VALUE_OF_TRAVEL = 20;
const MAX_VALUE_OF_TRAVEL = 80;
const SWIPE_DIRECTIONS = {
  UP: 'U',
  DOWN: 'D',
  LEFT: 'L',
  RIGHT: 'R',
};
const TIME_TO_RESET_DISTANCE = 10;

/**
 * A utility function to calculate the maximum swipeable distance
 * based on predefined limits and provided minimum distance.
 *
 * @param {number} minDistanceToTriggerFunction - The user-defined minimum distance.
 * @returns {number} - The computed maximum swipeable distance.
 */
const calculateMaxSwipeDistance = (minDistanceToTriggerFunction) =>
  Math.min(
    MAX_VALUE_OF_TRAVEL,
    Math.max(MIN_VALUE_OF_TRAVEL, minDistanceToTriggerFunction)
  );

/**
 * A utility function to calculate swipe direction.
 *
 * @param {number} distance - The swipe distance.
 * @param {string} positiveDirection - The direction for positive distance.
 * @param {string} negativeDirection - The direction for negative distance.
 * @returns {string} - The computed swipe direction.
 */
const calculateSwipeDirection = (
  distance,
  positiveDirection,
  negativeDirection
) => (distance > 0 ? positiveDirection : negativeDirection);

/**
 * A shared hook to handle both vertical and horizontal swipes.
 *
 * @param {Function} onSwipePositive - Callback for positive swipe direction.
 * @param {Function} onSwipeNegative - Callback for negative swipe direction.
 * @param {number} minDistanceToTriggerFunction - The minimum distance to trigger actions.
 * @param {boolean} isVertical - Determines if the swipe is vertical or horizontal.
 * @returns {Object} - Contains swipe details and event handlers.
 */
function useSwipe(
  onSwipePositive,
  onSwipeNegative,
  minDistanceToTriggerFunction = 50,
  isVertical = true
) {
  const touchStartRef = useRef(0);
  const distanceRef = useRef(0);
  const resetTimerRef = useRef(null);
  const [distance, setDistance] = useState(0);

  const maxSwipeableDistance = calculateMaxSwipeDistance(
    minDistanceToTriggerFunction
  );

  const onTouchStart = useCallback(
    (e) => {
      if (resetTimerRef.current) {
        clearTimeout(resetTimerRef.current);
      }

      setDistance(0);
      distanceRef.current = 0;
      touchStartRef.current = isVertical
        ? e.targetTouches[0].clientY
        : e.targetTouches[0].clientX;
    },
    [isVertical]
  );

  const onTouchMove = useCallback(
    (e) => {
      const currentPosition = isVertical
        ? e.targetTouches[0].clientY
        : e.targetTouches[0].clientX;

      distanceRef.current = Math.ceil(touchStartRef.current - currentPosition);
      setDistance(distanceRef.current);
    },
    [isVertical]
  );

  const onTouchEnd = useCallback(() => {
    if (!touchStartRef.current || !distanceRef.current) return;

    const isPositiveSwipe = distanceRef.current > maxSwipeableDistance;
    const isNegativeSwipe = distanceRef.current < -maxSwipeableDistance;

    if (isPositiveSwipe) {
      onSwipePositive();
    } else if (isNegativeSwipe) {
      onSwipeNegative();
    } else {
      resetTimerRef.current = setTimeout(
        () => setDistance(0),
        TIME_TO_RESET_DISTANCE
      );
    }
  }, [onSwipePositive, onSwipeNegative, maxSwipeableDistance]);

  return {
    displacement: {
      magnitude: Math.abs(distance),
      direction: calculateSwipeDirection(
        distance,
        isVertical ? SWIPE_DIRECTIONS.UP : SWIPE_DIRECTIONS.LEFT,
        isVertical ? SWIPE_DIRECTIONS.DOWN : SWIPE_DIRECTIONS.RIGHT
      ),
    },
    boundedDistance: Math.min(maxSwipeableDistance, Math.abs(distance)),
    handlers: {
      onTouchStart,
      onTouchMove,
      onTouchEnd,
    },
  };
}

/**
 * Custom hook to handle vertical swipe gestures (up or down) on a touch-enabled interface.
 *
 * @param {Object} actions - An object containing action callbacks for swipe events.
 * @param {Function} actions.onSwipedUp - Callback function to execute when an upward swipe is detected.
 * @param {Function} actions.onSwipedDown - Callback function to execute when a downward swipe is detected.
 * @param {number} [minDistanceToTriggerFunction=50] - The distance in pixels required to trigger a swipe action.
 *
 * @returns {Object} - Contains swipe details and event handlers.
 */
function useVerticalSwipe(actions, minDistanceToTriggerFunction = 50) {
  return useSwipe(
    actions.onSwipedUp,
    actions.onSwipedDown,
    minDistanceToTriggerFunction,
    true
  );
}

/**
 * Custom hook to handle horizontal swipe gestures (right or left) on a touch-enabled interface.
 *
 * @param {Object} actions - An object containing action callbacks for swipe events.
 * @param {Function} actions.onSwipedRight - Callback function to execute when a right swipe is detected.
 * @param {Function} actions.onSwipedLeft - Callback function to execute when a left swipe is detected.
 * @param {number} [minDistanceToTriggerFunction=50] - The distance in pixels required to trigger a swipe action.
 *
 * @returns {Object} - Contains swipe details and event handlers.
 */
function useHorizontalSwipe(actions, minDistanceToTriggerFunction = 50) {
  return useSwipe(
    actions.onSwipedLeft,
    actions.onSwipedRight,
    minDistanceToTriggerFunction,
    false
  );
}

export { useHorizontalSwipe, useVerticalSwipe };
