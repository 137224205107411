import React, { useState, useEffect, useRef } from 'react';
import { isBefore } from 'date-fns';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import DateFnsUtils from '@date-io/date-fns';
import { getSelectedLanguageObject } from 'ui-common/src/lib/utils';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { Event } from '@material-ui/icons';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'flex-end',
  },
  field: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    alignSelf: 'flex-end',
  },
  error: {
    color: 'red',
  },
}));

const helperTextStyles = makeStyles(() => ({
  root: {
    color: 'var(--red) !important',
    position: 'absolute',
    top: '40px',
    minWidth: '220px',
  },
}));

const DatePickerComponent = ({
  placeholder,
  _handleChange,
  attributeKey,
  defaultValue,
  showError,
  required,
  readOnly,
  minDate,
  scrollToError,
  isExisting,
}) => {
  const classes = useStyles();
  const helperTestClasses = helperTextStyles();
  const inputTextFieldRef = useRef(null);
  const DATE_FORMAT = useSelector(state => state.user.headerMenu.ecmDateFormat);
  const handleDateChange = date => {
    _handleChange(date, attributeKey);
  };

  useEffect(() => {
    if (scrollToError === 'true') {
      inputTextFieldRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [scrollToError]);

  return (
    <div className={classes.root} ref={inputTextFieldRef}>
      <div className={classes.field}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getSelectedLanguageObject()}>
          <Grid container>
            <DatePicker
              autoOk
              disableToolbar
              variant="inline"
              autoComplete="off"
              error={required ? showError : false}
              format={DATE_FORMAT}
              margin="dense"
              id="date-picker-inline"
              value={defaultValue || null}
              FormHelperTextProps={{ classes: helperTestClasses }}
              InputAdornmentProps={{ position: 'end', edge: 'end' }}
              InputProps={
                !placeholder
                  ? ''
                  : {
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton edge="end">
                            <Event />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }
              }
              onChange={handleDateChange}
              fullWidth
              disabled={readOnly}
              minDate={
                minDate &&
                attributeKey === 'startDate' &&
                isExisting &&
                isBefore(defaultValue, minDate)
                  ? defaultValue
                  : minDate
              }
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              label={
                <>
                  <span dangerouslySetInnerHTML={{ __html: placeholder }} />
                  {required && <span className="mandatory">&nbsp;*</span>}
                </>
              }
            />
          </Grid>
        </MuiPickersUtilsProvider>
      </div>
    </div>
  );
};

DatePickerComponent.propTypes = {
  _handleChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  attributeKey: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  showError: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  defaultValue: PropTypes.string.isRequired,
  minDate: PropTypes.string.isRequired,
};

export default DatePickerComponent;
