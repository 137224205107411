import React, { useState } from 'react';
import { useLocalize } from '@saviynt/common';
import { Box, Button, Icon, InputField, Loader } from '@saviynt/design-system';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import useFetchRolesEntitlements from '../../../../../hooks/use-fetchrolesentitlements';
import { Entitlement, ModuleDetails } from '../../../../../models/PamModels';
import { msgs } from '../../../constants';
import EntitlementsModal from '../../../Modals/Roles/EntitlementsModal/EntitlementsModal';
import SpvSection from '../../SpvSection/SpvSection';

import './SpvEntitlements.css';

function SpvEntitlements({ data, initialEntitlements, dataTestId, className }) {
  const classes = classnames('SpvEntitlements', className);
  const localize = useLocalize();
  const [searchBarValue, setSearchBarValue] = useState('');
  const [isEntitlementsModalOpen, setIsEntitlementsModalOpen] = useState(false);
  const [entitlements, setEntitlements] = useState(initialEntitlements);
  const [isInitialRender, setIsInitialRender] = useState(true);

  const { isRolesEntitlementsLoading } = useFetchRolesEntitlements(
    data.roleKey,
    searchBarValue,
    {},
    0,
    setEntitlements,
    isInitialRender,
    setIsInitialRender
  );

  return (
    <div className={classes} data-testid={dataTestId}>
      <section className='SpvEntitlements-searchContainer'>
        <InputField
          placeholder={localize(
            msgs.pam.sidePanel.searchEntitlementsPlaceholder
          )}
          name='basic'
          kind='search'
          size='small'
          prefixIcon={
            <Icon kind='search' color='neutral-600' size='smallMedium' />
          }
          value={searchBarValue}
          setValue={setSearchBarValue}
          className='SpvEntitlements-search'
        />
        <Button
          type='button'
          kind='ghost'
          size='small'
          rightIcon={
            <Icon kind='ArrowRight' size='medium' color='neutral-100' />
          }
          onClick={() => {
            setIsEntitlementsModalOpen(true);
          }}
          className='SpvEntitlements-showMoreEntitlements'>
          {localize(msgs.pam.sidePanel.viewDetails)}
        </Button>
      </section>
      <section className='SpvEntitlements-entitlementsContainer'>
        {isRolesEntitlementsLoading ? (
          <Box className='SpvEntitlements-loader'>
            <Loader kind='dots' format='inline' color='info' size='medium' />
          </Box>
        ) : (
          <section>
            {entitlements?.length > 0 &&
              entitlements.map((entitlement, index) => (
                <SpvSection
                  title={entitlement.evEntitlementValue}
                  fields={[
                    {
                      label: localize(msgs.pam.sidePanel.description),
                      value: entitlement.evDescription,
                    },
                  ]}
                  className={classnames(
                    index !== 0 && 'SpvEntitlements-section'
                  )}
                />
              ))}
          </section>
        )}
      </section>
      {isEntitlementsModalOpen && entitlements && (
        <EntitlementsModal
          data={data}
          isEntitlementsModalOpen={isEntitlementsModalOpen}
          setIsEntitlementsModalOpen={setIsEntitlementsModalOpen}
          initialEntitlements={initialEntitlements}
        />
      )}
    </div>
  );
}

SpvEntitlements.propTypes = {
  data: ModuleDetails,
  initialEntitlements: PropTypes.arrayOf(Entitlement).isRequired,
  dataTestId: PropTypes.string,
  className: PropTypes.string,
};

SpvEntitlements.defaultProps = {
  data: null,
  dataTestId: null,
  className: null,
};

export default SpvEntitlements;
