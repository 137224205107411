import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { makeStyles } from '@material-ui/core/styles';
import { Icon } from 'ui-components/src';

const useStyles = makeStyles({
  iconRoot: {
    '&:hover, &:focus': {
      backgroundColor: '#fff',
    },
    '& .icon': {
      color: '#c8c8c8',
      opacity: 1,
    },
    backgroundColor: '#fff',
    color: 'white',
    borderRadius: 3,
  },
  customIcon:{
    '& .icon': {
      color: '#c8c8c8',
      opacity: 1,
    },
    backgroundColor: '#fff',
    color: 'white',
    borderRadius: '50%',
    padding: '3px !important',
  },
  gridPosition: {
    float: 'right',
    margin: '-.5rem -1rem 0 0',
  },
  listPosition: {
    padding: 0,
  },
  menuPaper: {
    borderRadius: 1,
    minWidth: '8rem',
    padding: 0,
  },
  button: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: 0,
    tabIndex: 0,
    color: 'black',
    fontFamily: 'inherit',
    padding: '0 5px 0 1rem',
    height: '2rem',
    maxWidth: '250px',
    '&:hover': {
      backgroundColor: '#ececec',
      color: 'black',
    },
    '&:focus': {
      color: 'black',
    },
  },
});

const VerticalIconMenu = ({ actionMenu, gridMode, listMode, menuPaper, customIcon }) => {
  const classes = useStyles({ listMode });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const menuRef = React.createRef();

  const handleClick = e => {
    setAnchorEl(e.currentTarget);
    menuRef.current.focus();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        disableRipple
        onClick={handleClick}
        className={[
          customIcon ? classes.customIcon : classes.iconRoot,
          gridMode ? classes.gridPosition : null,
          listMode ? classes.listPosition : null,
        ].join(' ')}
      >
        {customIcon ? <Icon name={customIcon} /> : <MoreVertIcon className="icon" />}
       
      </IconButton> 
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted
        open={open}
        onClose={handleClose}
        MenuListProps={{
          disablePadding: true,
        }}
        classes={{
          paper: menuPaper || classes.menuPaper,
        }}
        ref={menuRef}
      >
        {actionMenu.map(item => (
          <MenuItem
            onClose={handleClose}
            onClick={() => {
              item.handleClick();
              handleClose();
            }}
            className={classes.button}
          >
            {item.title}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

VerticalIconMenu.propTypes = {
  actionMenu: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      handleClick: PropTypes.func.isRequired,
    })
  ).isRequired,
  gridMode: PropTypes.bool,
  listMode: PropTypes.bool,
  menuPaper: PropTypes.shape({}),
};

VerticalIconMenu.defaultProps = {
  gridMode: false,
  listMode: false,
  menuPaper: null,
};

export default VerticalIconMenu;
