import React, { useEffect } from 'react';
// import { DatePicker } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import { instanceCheckoutStartDateTime, instanceCheckoutEndDateTime, getSelectedLanguageObject } from 'ui-common/src/lib/utils';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Icon } from '../Icon';
import { PlainDropDown } from '../PlainDropDown';
import styles from './DateFilterWidget.module.scss';
import { SaviyntRangePicker } from '../SaviyntRangePicker';

const DateFilterWidget = ({
  setAntdComponentOpen,
  selectedDate,
  setSelectedDate,
  resetDates,
  dropup,
  maxAllowedDate,
}) => {
  const selectedStartDate = mode => {
    if (selectedDate.value && Array.isArray(selectedDate.value)) {
      if (mode === 'start') {
        const startdateNow = moment(selectedDate.value[0]);
        const startfromTime = {
          hours: startdateNow.get('hours'),
          minutes: startdateNow.get('minutes'),
          seconds: 0,
        };
        const startfromDate = startdateNow.set({
          hours: 0,
          minutes: 0,
          seconds: 0,
        });
        return {
          defaultFromTime: startfromTime,
          defaultFromDate: startfromDate,
        };
      }
      if (mode === 'end') {
        const enddateNow = moment(selectedDate.value[1]);
        const endfromTime = {
          hours: enddateNow.get('hours'),
          minutes: enddateNow.get('minutes'),
          seconds: 0,
        };
        const endfromDate = enddateNow.set({
          hours: 0,
          minutes: 0,
          seconds: 0,
        });
        return { defaultToTime: endfromTime, defaultToDate: endfromDate };
      }
    } else if (selectedDate.value && typeof selectedDate.value === 'string') {
      const dateNow = moment(selectedDate.value);
      const fromTime = {
        hours: dateNow.get('hours'),
        minutes: dateNow.get('minutes'),
        seconds: 0,
      };
      const fromDate = dateNow.set({ hours: 0, minutes: 0, seconds: 0 });
      return { defaultFromTime: fromTime, defaultFromDate: fromDate };
    } else {
      if (mode === 'start') return instanceCheckoutStartDateTime();
      return instanceCheckoutEndDateTime();
    }
  };

  const { defaultFromTime, defaultFromDate } = selectedStartDate('start');

  const { defaultToTime, defaultToDate } = selectedStartDate('end');

  useEffect(() => {
    if (resetDates) {
      setSelectedDate({
        value: null,
        mode: 'within',
      });
    }
  }, [resetDates]);

  return (
    <div className="d-flex">
      <div className={styles.filterRangePicker}>
        <div className={styles.datefilterLayout}>
          {(selectedDate.mode === 'after' || selectedDate.mode === 'before') && (
            <div className={styles.DatepickerLayout}>
              <Icon className={`${styles.calendarIcon} pl-1`} name="calendar" />
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getSelectedLanguageObject()}>
                <DatePicker
                  defaultValue={defaultFromDate}
                  autoOk
                  disableToolbar
                  onOpen={() => {
                    setAntdComponentOpen(true);
                  }}
                  onClose={() => {
                    setAntdComponentOpen(false);
                  }}
                  value={defaultFromDate}
                  variant="inline"
                  format="MMM, dd yyyy"
                  onChange={date => {
                    const momentFromdate = moment(date);
                    momentFromdate.set({ hours: 0, minutes: 0, seconds: 0 });
                    let dateStr = '';
                    if (selectedDate.mode === 'before') {
                      dateStr = momentFromdate.startOf('day').format('YYYY-MM-DD HH:mm:ss');
                    }
                    if (selectedDate.mode === 'after') {
                      dateStr = momentFromdate.endOf('day').format('YYYY-MM-DD HH:mm:ss');
                    }
                    setSelectedDate({
                      ...selectedDate,
                      value: dateStr,
                    });
                  }}
                  InputProps={{
                    style: {
                      fontSize: 11,
                      fontWeight: 600,
                    },
                  }}
                  maxDate={maxAllowedDate ? new Date() : undefined}
                />
              </MuiPickersUtilsProvider>
            </div>
          )}
          {selectedDate.mode === 'within' && (
            <SaviyntRangePicker
              noTimeRequired
              instanceFromDate={defaultFromDate}
              instanceFromTime={defaultFromTime}
              instanceToDate={defaultToDate}
              instanceToTime={defaultToTime}
              dateFormat="MMM, DD"
              fullwidth
              onChange={range => {
                setSelectedDate({
                  ...selectedDate,
                  value: [
                    range[0].startOf('day').format('YYYY-MM-DD HH:mm:ss'),
                    range[1].endOf('day').format('YYYY-MM-DD HH:mm:ss'),
                  ],
                });
              }}
              setCalendarOpen={setAntdComponentOpen}
              allowPreviousDates
              maxAllowedDate={maxAllowedDate}
            />
          )}
        </div>
      </div>
    </div>
  );
};

DateFilterWidget.propTypes = {
  selectedDate: PropTypes.shape({
    mode: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  setSelectedDate: PropTypes.func.isRequired,
  resetDates: PropTypes.func.isRequired,
  setAntdComponentOpen: PropTypes.func.isRequired,
  dropup: PropTypes.func.isRequired,
};

DateFilterWidget.defaultProps = {
  maxAllowedDate: false,
};

// eslint-disable-next-line import/prefer-default-export
export { DateFilterWidget };
