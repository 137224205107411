import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Cookies } from 'react-cookie';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PropTypes from 'prop-types';
import RequestAccessModal from './RequestAccessModal';
import RequestAccessMobileModal from './RequestAccessMobileModal';
import NoAccessModal from './NoAccessModal';
import './WelcomeSection.scss';
import { Tooltip } from '@saviynt/design-system';
import { ShortcutsWidget } from '@saviynt/nav';

const cookies = new Cookies();

const WelcomeSection = ({ intl }) => {
  // Add Custom Hooks for useMediaQuery, currently being used from MUI
  const isMobileDevice = useMediaQuery('(max-width:480px)');

  const [requestAccessModal, setRequestAccessModal] = useState(false);
  const [requestAccessMobileModal, setRequestAccessMobileModal] = useState(false);

  const [noAccessModal, setNoAccessModal] = useState(false);

  const { hasRequestAccess } = useSelector((state) => state.requestHome.initialLoad);
  const [username, setUserName] = useState('');
  const userInfo = useSelector((state) => state.user.userInfo) || {};

  const { PAM_SERVICES_ENABLED } = useSelector((state) => state.user.domainInfo);
  const PAM_TILE_ID = 'new-privileged-access';

  useEffect(() => {
    if (userInfo) {
      let name = cookies.get('user_name');
      if (userInfo.firstname || userInfo.lastname) {
        name = `${userInfo.firstname || ''} ${userInfo.lastname || ''}`;
      }
      setUserName(name);
    }
  }, []);

  const setModal = () => {
    if (hasRequestAccess) {
      setNoAccessModal(true);
    } else if (isMobileDevice) {
      setRequestAccessMobileModal(true);
    } else {
      setRequestAccessModal(true);
    }
  };

  const handleRequestAccessModalClose = () => {
    setRequestAccessModal(false);
  };
  const handleRequestAccessMobileModalClose = () => {
    setRequestAccessMobileModal(false);
  };

  const handleNoAccessModalClose = () => {
    setNoAccessModal(false);
  };

  const handleCustomClick = (customShortcutId) => {
    if (customShortcutId === 'start-access-request') {
      setModal();
    }
  };

  return (
    <div className="welcome-section">
      <div className="welcome-container">
        <div className="welcome-message">
          <h1 className="welcome-text">
            {intl.formatMessage({ id: 'NewRequestHome.Welcome.Title' })}
            ,
            <br />{' '}
            {username && username.length > 25 ? (
              <Tooltip
                delay={250}
                position="top"
                text={username}
                className="welcome-message-tooltip-section"
              >
                <div className="welcome-message-title">{username}</div>
              </Tooltip>
            ) : (
              <div>{username}!</div>
            )}
          </h1>
        </div>{' '}
        <ShortcutsWidget
          intl={intl}
          onCustomClick={handleCustomClick}
          className="welcome-shortcuts"
        />
        <RequestAccessModal
          open={requestAccessModal}
          onDialogClose={handleRequestAccessModalClose}
          username={username}
          isPamEnabled={PAM_SERVICES_ENABLED}
          pamTileId={PAM_TILE_ID}
        />
        <RequestAccessMobileModal
          open={requestAccessMobileModal}
          onDialogClose={handleRequestAccessMobileModalClose}
          username={username}
          isPamEnabled={PAM_SERVICES_ENABLED}
          pamTileId={PAM_TILE_ID}
        />
        <NoAccessModal open={noAccessModal} onDialogClose={handleNoAccessModalClose} />
      </div>
    </div>
  );
};

WelcomeSection.propTypes = {
  intl: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};

export default injectIntl(WelcomeSection);
