import { useEffect, useRef } from 'react';

const useAvoidFirstRender = (callback, dependencies) => {
  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current) callback();
    else isMounted.current = true;
  }, dependencies);
};

// eslint-disable-next-line import/prefer-default-export
export { useAvoidFirstRender };
