/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { checkEnvironment } from 'ui-common/src/lib/utils';

import './RequestAccessModal.scss';

export const Close = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.62204 8.62204C8.78476 8.45932 9.04858 8.45932 9.21129 8.62204L16 15.4107L22.7887 8.62209C22.9514 8.45937 23.2152 8.45937 23.378 8.62209C23.5407 8.78481 23.5407 9.04862 23.3779 9.21134L16.5893 16L23.3779 22.7887C23.5407 22.9514 23.5407 23.2152 23.378 23.3779C23.2152 23.5406 22.9514 23.5406 22.7887 23.3779L16 16.5893L9.21129 23.378C9.04858 23.5407 8.78476 23.5407 8.62204 23.378C8.45932 23.2152 8.45932 22.9514 8.62204 22.7887L15.4108 16L8.62204 9.21129C8.45932 9.04858 8.45932 8.78476 8.62204 8.62204Z"
      fill="#212328"
    />
  </svg>
);

export const ApplicationResponsive = ({ style }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.83333 3.86133C8.55719 3.86133 8.33333 4.08519 8.33333 4.36133C8.33333 4.63747 8.55719 4.86133 8.83333 4.86133H15.1667C15.4428 4.86133 15.6667 4.63747 15.6667 4.36133C15.6667 4.08519 15.4428 3.86133 15.1667 3.86133H8.83333Z"
      fill={style.color}
    />
    <path
      d="M5.66667 6.36133C5.66667 6.08519 5.89052 5.86133 6.16667 5.86133H17.8333C18.1095 5.86133 18.3333 6.08519 18.3333 6.36133C18.3333 6.63747 18.1095 6.86133 17.8333 6.86133H6.16667C5.89052 6.86133 5.66667 6.63747 5.66667 6.36133Z"
      fill={style.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 9.639C3 8.62648 3.82081 7.80566 4.83333 7.80566H19.1667C20.1792 7.80566 21 8.62648 21 9.639V18.3057C21 19.3182 20.1792 20.139 19.1667 20.139H4.83333C3.82081 20.139 3 19.3182 3 18.3057V9.639ZM4.83333 8.80566C4.3731 8.80566 4 9.17876 4 9.639V18.3057C4 18.7659 4.3731 19.139 4.83333 19.139H19.1667C19.6269 19.139 20 18.7659 20 18.3057V9.639C20 9.17876 19.6269 8.80566 19.1667 8.80566H4.83333Z"
      fill={style.color}
    />
  </svg>
);

export const PrivilegedAccessResponsive = ({ style }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.449 3.77985C12.365 3.60856 12.1908 3.5 12 3.5C11.8093 3.5 11.6351 3.60856 11.5511 3.77985L8.25496 10.5014L3.84471 6.30446C3.6878 6.15514 3.45257 6.12383 3.26206 6.22692C3.07156 6.33002 2.9691 6.54406 3.00828 6.7571L4.96981 17.4236C5.01345 17.6609 5.2203 17.8332 5.46157 17.8332H18.5385C18.7798 17.8332 18.9866 17.6609 19.0302 17.4236L20.9918 6.7571C21.031 6.54406 20.9285 6.33002 20.738 6.22692C20.5475 6.12383 20.3123 6.15514 20.1553 6.30446L15.7451 10.5014L12.449 3.77985ZM8.8528 11.5535L12 5.13561L15.1473 11.5535C15.2174 11.6964 15.3514 11.7975 15.5081 11.8255C15.6648 11.8536 15.8255 11.8053 15.9409 11.6955L19.73 8.08972L18.1221 16.8332H5.878L4.27011 8.08972L8.05919 11.6955C8.17453 11.8053 8.33525 11.8536 8.49198 11.8255C8.6487 11.7975 8.7827 11.6964 8.8528 11.5535Z"
      fill={style.color}
    />
    <path
      d="M5.46162 19.5C5.18548 19.5 4.96162 19.7239 4.96162 20C4.96162 20.2761 5.18548 20.5 5.46162 20.5H18.5385C18.8147 20.5 19.0385 20.2761 19.0385 20C19.0385 19.7239 18.8147 19.5 18.5385 19.5H5.46162Z"
      fill={style.color}
    />
  </svg>
);

export const RoleResponsive = ({ style }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0688 3.83398C10.3197 3.83398 8.90177 5.2519 8.90177 7.00098C8.90177 8.75006 10.3197 10.168 12.0688 10.168C13.8178 10.168 15.2358 8.75006 15.2358 7.00098C15.2358 5.2519 13.8178 3.83398 12.0688 3.83398ZM9.90177 7.00098C9.90177 5.80418 10.872 4.83398 12.0688 4.83398C13.2656 4.83398 14.2358 5.80418 14.2358 7.00098C14.2358 8.19778 13.2656 9.16797 12.0688 9.16797C10.872 9.16797 9.90177 8.19778 9.90177 7.00098Z"
      fill={style.color}
    />
    <path
      d="M8.03875 12.783C10.4795 11.329 13.5206 11.329 15.9613 12.783C16.4336 13.0644 16.7229 13.5735 16.7229 14.1232V19.6673C16.7229 19.9435 16.9468 20.1673 17.2229 20.1673C17.4991 20.1673 17.7229 19.9435 17.7229 19.6673V14.1232C17.7229 13.2211 17.2481 12.3856 16.4732 11.9239C16.154 11.7338 15.8258 11.5657 15.4906 11.4196C16.7895 10.681 18.3877 10.6951 19.6752 11.4618C19.8766 11.5817 20 11.7988 20 12.0332V16.4996C20 16.7757 20.2239 16.9996 20.5 16.9996C20.7761 16.9996 21 16.7757 21 16.4996V12.0332C21 11.4463 20.6911 10.9028 20.1869 10.6026C18.56 9.63381 16.5333 9.63381 14.9065 10.6026L14.3375 10.9414C14.3124 10.9563 14.2891 10.9731 14.2676 10.9915C12.7823 10.5926 11.2157 10.5928 9.73047 10.9921C9.70885 10.9735 9.68534 10.9565 9.66001 10.9414L9.09159 10.6028C7.46526 9.63391 5.43884 9.63391 3.81252 10.6028C3.30867 10.903 3 11.4461 3 12.0326V16.4996C3 16.7757 3.22386 16.9996 3.5 16.9996C3.77614 16.9996 4 16.7757 4 16.4996V12.0326C4 11.7985 4.12321 11.5817 4.32432 11.4619C5.61153 10.695 7.20967 10.6811 8.50816 11.4202C8.17342 11.5661 7.84565 11.734 7.52694 11.9239C6.75196 12.3856 6.27718 13.2211 6.27718 14.1232V19.6673C6.27718 19.9435 6.50104 20.1673 6.77718 20.1673C7.05332 20.1673 7.27718 19.9435 7.27718 19.6673V14.1232C7.27718 13.5735 7.5665 13.0644 8.03875 12.783Z"
      fill={style.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.4834 7.34044C4.4834 6.23177 5.38216 5.33301 6.49083 5.33301C7.5995 5.33301 8.49826 6.23177 8.49826 7.34044C8.49826 8.44911 7.5995 9.34787 6.49083 9.34787C5.38216 9.34787 4.4834 8.44911 4.4834 7.34044ZM6.49083 6.33301C5.93444 6.33301 5.4834 6.78405 5.4834 7.34044C5.4834 7.89683 5.93444 8.34787 6.49083 8.34787C7.04722 8.34787 7.49826 7.89683 7.49826 7.34044C7.49826 6.78405 7.04722 6.33301 6.49083 6.33301Z"
      fill={style.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.516 7.34044C19.516 6.23156 18.6167 5.33301 17.5079 5.33301C16.3991 5.33301 15.4998 6.23156 15.4998 7.34044C15.4998 8.44932 16.3991 9.34787 17.5079 9.34787C18.6167 9.34787 19.516 8.44932 19.516 7.34044ZM17.5079 6.33301C18.0648 6.33301 18.516 6.78425 18.516 7.34044C18.516 7.89662 18.0648 8.34787 17.5079 8.34787C16.9509 8.34787 16.4998 7.89662 16.4998 7.34044C16.4998 6.78425 16.9509 6.33301 17.5079 6.33301Z"
      fill={style.color}
    />
  </svg>
);

export const ChevronRight = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.39632 20.8538C7.20106 20.6585 7.20106 20.342 7.39632 20.1467L15.5428 12.0002L7.39633 3.8538C7.20106 3.65854 7.20106 3.34195 7.39633 3.14669C7.59159 2.95143 7.90817 2.95143 8.10343 3.14669L16.6034 11.6467C16.7987 11.842 16.7987 12.1585 16.6034 12.3538L8.10343 20.8538C7.90817 21.0491 7.59159 21.0491 7.39632 20.8538Z"
      fill="currentColor"
    />
  </svg>
);
const mockdata = {
  Myself: [
    {
      id: 'application',
      link: '/request/applicationRequest',
      accessible: true,
    },
    {
      id: 'role',
      link: '/request/applicationRequest',
      accessible: false,
    },
    {
      id: 'privileged',
      link: '/request/applicationRequest',
      accessible: true,
    },
  ],
  SomeOneElse: [
    {
      id: 'application',
      link: '/request/applicationRequest',
      accessible: true,
    },
    {
      id: 'role',
      link: '/request/applicationRequest',
      accessible: true,
    },
    {
      id: 'privileged',
      link: '/request/applicationRequest',
      accessible: false,
    },
  ],
  Others: [
    {
      id: 'application',
      link: '/request/applicationRequest',
      accessible: true,
    },
    {
      id: 'role',
      link: '/request/applicationRequest',
      accessible: false,
    },
    {
      id: 'privileged',
      link: '/request/applicationRequest',
      accessible: false,
    },
  ],
};

const Dialog = withStyles({
  root: {
    zIndex: '1500 !important',
  },
})(MuiDialog);

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: '20px 24px',
  },
  title: {
    color: '#212328',
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '32px',
    marginBottom: '0',
    marginLeft: '32px',
  },
  closeButton: {
    position: 'absolute',
    left: '12px',
    top: '14px',
    color: '#212328',
    padding: '5px',
  },
  margin: {
    margin: theme.spacing(1),
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} timeout={1000} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { classes, onClose } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <h4 className={classes.title}>Request Access</h4>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          data-testid="closeOwnerModalBtn"
        >
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(() => ({
  root: {
    padding: '32px',
    background: '#fff',
  },
}))(MuiDialogContent);

const renderAccessDetails = (intl, type, isAccessible) => {
  switch (true) {
    case type === 'application':
      return (
        <>
          <div className="d-flex">
            <span className="request-icon">
              <ApplicationResponsive style={{ color: `${isAccessible ? '#00245B' : '#9EA1B1'}` }} />
            </span>
            <div className="request-title">
              {intl.formatMessage({
                id: 'NewRequestHome.QuickLink.AccessModal.RequestingTo.Application.Label',
              })}
            </div>
          </div>
          <ChevronRight style={{ color: `${isAccessible ? '#00245B' : '#9EA1B1'}` }} />
        </>
      );
    case type === 'role':
      return (
        <>
          <div className="d-flex">
            <span className="request-icon">
              <RoleResponsive style={{ color: `${isAccessible ? '#00245B' : '#9EA1B1'}` }} />
            </span>
            <div className="request-title">
              {intl.formatMessage({
                id: 'NewRequestHome.QuickLink.AccessModal.RequestingTo.Role.Label',
              })}
            </div>
          </div>
          <ChevronRight style={{ color: `${isAccessible ? '#00245B' : '#9EA1B1'}` }} />
        </>
      );
    case type === 'privilegedSession':
      return (
        <>
          <div className="d-flex">
            <span className="request-icon">
              <PrivilegedAccessResponsive
                style={{ color: `${isAccessible ? '#00245B' : '#9EA1B1'}` }}
              />
            </span>
            <div className="request-title">
              {intl.formatMessage({
                id: 'NewRequestHome.QuickLink.AccessModal.RequestingTo.PrivilegedSession.Label',
              })}
            </div>
          </div>
          <ChevronRight style={{ color: `${isAccessible ? '#00245B' : '#9EA1B1'}` }} />
        </>
      );
    default:
      return null;
  }
};

const RequestAccessMobileModal = ({
  onDialogClose,
  open,
  intl,
  username,
  isPamEnabled,
  pamTileId,
}) => {
  let domainUrl = checkEnvironment().GATEWAY_API_URL;
  const history = useHistory();

  const { requestAccessDetails } = useSelector((state) => state.requestHome.initialLoad);

  const [requestTiles, setRequestTiles] = useState([]);
  const [requestingForOptions, setRequestingForOptions] = useState([]);
  const [value, setValue] = useState('');

  const onCardChange = (value) => {
    setValue(value);
    const selectedOption = requestAccessDetails.filter((option) => option.id === value)[0];
    setRequestTiles(selectedOption.subMenu);
  };

  const handleNavigation = (link) => {
    if (link.includes('ECM')) {
      domainUrl = domainUrl.replace(/\/ECMv6/g, '');
      window.location.replace(`${domainUrl}${link}`);
    } else {
      history.push(link);
    }
  };

  const getOption = (id) => {
    if (id === 'myself') {
      return {
        label: intl.formatMessage({
          id: 'NewRequestHome.QuickLink.AccessModal.RequestingFor.Myself.Label',
        }),
        id,
      };
    }
    if (id === 'someOneElse') {
      return {
        label: intl.formatMessage({
          id: 'NewRequestHome.QuickLink.AccessModal.RequestingFor.SomeoneElse.Label',
        }),
        id,
      };
    }
    if (id === 'multiplePeople') {
      return {
        label: intl.formatMessage({
          id: 'NewRequestHome.QuickLink.AccessModal.RequestingFor.MultiplePeople.Label',
        }),
        id,
      };
    }
  };

  useEffect(() => {
    if (requestAccessDetails.length > 0) {
      setValue(requestAccessDetails[0].id);
      setRequestTiles(requestAccessDetails[0].subMenu);
      const optionsIds = requestAccessDetails.map((option) => option.id);
      const dropdownOptions = Array.from(optionsIds, (val) => getOption(val));
      setRequestingForOptions(dropdownOptions);
    }
  }, [requestAccessDetails]);

  return (
    <Dialog
      open={open}
      onClose={onDialogClose}
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          maxHeight: '700px',
          height: '700px',
          maxWidth: '480px',
          width: '480px',
          borderRadius: '0px',
        },
      }}
    >
      <DialogTitle id="customized-dialog-title" onClose={onDialogClose} intl={intl} />
      <DialogContent>
        <div className="request-access-mobile-modal">
          <div className="requesting-for requestSection-title">
            <h4>
              {intl.formatMessage({
                id: 'NewRequestHome.QuickLink.AccessModal.RequestingFor.Title',
              })}
            </h4>
            <div className="radio-cards">
              <RadioGroup
                aria-label="requesting-for-options"
                name="requesting-for-options"
                value={value}
              >
                {requestingForOptions?.map((item) => (
                  <div
                    className={`radio-card ${item.id === value ? 'active' : 'inactive'}`}
                    role="button"
                    tabIndex="0"
                    onClick={() => onCardChange(item.id)}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        onCardChange(item.id);
                      }
                    }}
                  >
                    <h5>
                      {item.label}{' '}
                      {item.label ===
                      intl.formatMessage({
                        id: 'NewRequestHome.QuickLink.AccessModal.RequestingFor.Myself.Label',
                      })
                        ? `(${username})`
                        : ''}
                    </h5>
                    <FormControlLabel value={item.id} control={<Radio color="primary" />} />
                  </div>
                ))}
              </RadioGroup>
            </div>
          </div>
          <div className="requesting-to requestSection-title">
            <h4>
              {intl.formatMessage({
                id: 'NewRequestHome.QuickLink.AccessModal.RequestingTo.Title',
              })}
            </h4>
            <div className="request-tiles">
              {requestTiles?.map((item) => {
                if (item.id === pamTileId && !isPamEnabled) {
                  return null;
                }

                return (
                  <div
                    className={`request-tile ${item.isAccessible ? 'active' : 'disabled'}`}
                    role="button"
                    tabIndex="0"
                    onClick={() => {
                      if (item.isAccessible) {
                        handleNavigation(item.link);
                      }
                    }}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter' && item.isAccessible) {
                        handleNavigation(item.link);
                      }
                    }}
                  >
                    {renderAccessDetails(intl, item.name, item.isAccessible)}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

RequestAccessMobileModal.propTypes = {
  onDialogClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  intl: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  username: PropTypes.string.isRequired,
};

export default injectIntl(withRouter(RequestAccessMobileModal));
