const messages = {
  // Tech onboarding - pushed to msg props
  'CatalogManagement.Pending.Status': 'Enrollment in progress',
  // General app - pushed to msg props
  'AOB.OnboardingGeneralApp.recon.genericintegration.title': 'Automated',
  'AOB.OnboardingGeneralApp.recon.genericintegration.desc':
    'Use standard integrations like SOAP, REST and DB to import your application data into EIC',
  'AOB.OnboardingGeneralApp.recon.uploaddatafile.title': 'Manual',
  'AOB.OnboardingGeneralApp.recon.uploaddatafile.desc':
    'Upload accounts, entitlements and entitlement associations files to import your application data into EIC',
  'AOB.OnboardingGeneralApp.provision.genericintegration.title': 'Automated',
  'AOB.OnboardingGeneralApp.provision.genericintegration.desc':
    'Use standard application integration options like REST, SOAP or DB for provisioning operations.',
  'AOB.OnboardingGeneralApp.provision.helpdesk.title': 'Help Desk',
  'AOB.OnboardingGeneralApp.provision.helpdesk.desc':
    "Use EIC for manual provisioning if there's no direct connection to the application",
  'AOB.OnboardingGeneralApp.provision.servicedesk.title': 'Service Desk Integration',
  'AOB.OnboardingGeneralApp.provision.servicedesk.desc':
    "Use integration services like ServiceNow for provisioning if there's no direct connection to the application.",
  'AOB.OnboardingGeneralApp.provision.idbot.title': 'Identity Bot',
  'AOB.OnboardingGeneralApp.provision.idbot.desc':
    'Use a bot to automate provisioning if there is no direct connection to the application',
  'AOB.OnboardingGeneralApp.disconnected.helpdoc': 'Help Document',
  'AOB.OnboardingGeneralApp.import': 'Import',
  'AOB.OnboardingGeneralApp.import.instruct.title': 'Instructions',
  'AOB.OnboardingGeneralApp.import.entitle.desc':
    'Entitlement Import enables you to upload entitlements for an application and add owners. It can create security system, endpoint, entitlement type if it does not exist.',
  'AOB.OnboardingGeneralApp.import.entitleasso.desc':
    'Entitlement Associations import enables you to establish parent-child relationship between entitlements.',
  'AOB.OnboardingGeneralApp.import.samplefilename': 'Sample Data File',
  'AOB.OnboardingGeneralApp.import.samplefile.available': 'is available to download',
  'AOB.OnboardingGeneralApp.provision.idbot.pretitle': 'Pre-requisite',
  'AOB.OnboardingGeneralApp.provision.idbot.launchtitle': 'Launch',
  'AOB.OnboardingGeneralApp.provision.idbot.recordtitle': 'Record',
  'AOB.OnboardingGeneralApp.provision.idbot.completetitle': 'Complete',
  'AOB.OnboardingGeneralApp.provision.idbot.configuretitle': 'Configure',
  'AOB.OnboardingGeneralApp.provision.helpdesk.appaccess': 'Request for application access',
  'AOB.OnboardingGeneralApp.provision.helpdesk.heading': 'Action in target application',
  'AOB.OnboardingGeneralApp.provision.helpdesk.complete': 'Complete provisioning',
  'AOB.OnboardingGeneralApp.provision.idbot.descstart':
    'Make sure your identity bot is installed and configured for provisioning, and then follow these steps. You can download the identity bot',
  'AOB.OnboardingGeneralApp.provision.idbot.docfilename': 'IdentityBotDocumentation.pdf',
  'AOB.OnboardingGeneralApp.provision.idbot.doclinktext': 'this documentation',
  'AOB.OnboardingGeneralApp.provision.idbot.descend': 'and read installation instructions',
  'AOB.OnboardingGeneralApp.provision.idbot.deliverlink':
  'https://docs.saviyntcloud.com/bundle/Saviynt-Identity-Bot/page/Content/Managing-Identity-Bot.htm',
  'AOB.OnboardingGeneralApp.provision.idbot.deliverlinktext': 'here.',
  'AOB.OnboardingGeneralApp.provision.idbot.stepstitle':
    'If you have installed and configured different components of Identity Bot, then',
  'AOB.OnboardingGeneralApp.provision.idbot.step1':
    'Launch Identity Bot recorder in the server where you want to train the Bot.',
  'AOB.OnboardingGeneralApp.provision.idbot.step2':
    'Record the sequence of steps and export the script.',
  'AOB.OnboardingGeneralApp.provision.idbot.step3': 'Complete the onboarding of this application.',
  'AOB.OnboardingGeneralApp.provision.idbot.step4':
    'Go to the application catalog and perform any configuration changes needed in the provisioning configurations (like changing the Bot url, payload etc.,)',
  'AOB.OnboardingGeneralApp.provision.helpdesk.stepstitle': 'Instructions:',
  'AOB.OnboardingGeneralApp.provision.helpdesk.step1':
    'A task will be created in Saviynt when an user requests for any application access.',
  'AOB.OnboardingGeneralApp.provision.helpdesk.step2':
    'Perform the required operation manually in the application which grants this application access to the user.',
  'AOB.OnboardingGeneralApp.provision.helpdesk.step3': 'Complete the task in saviynt manually.',
  'AOB.OnboardingGeneralApp.fileUploadErrorForPrefix':
    'Uploaded filenames should have same prefix. e.g. entitlement_1.csv, entitlement_2.csv etc...',
  'AOB.OnboardingGeneralApp.fileUploadErrorAccountImportOnlyOneFileAllowed':
    'Only one file can be uploaded for account import.',
  'AOB.OnboardingGeneralApp.importForm.field.basic.label': 'Basic',
  'AOB.OnboardingGeneralApp.importForm.field.advanced.label': 'Advanced',
  'AOB.OnboardingGeneralApp.importForm.fieldgroup.advanced.shortDesc':
    'Advanced configuration for the import upload data file.',
  'AOB.VaultConfig.title': 'Use Secret Data From Vault',
  'AOB.VaultConfig.description':
    "You can store this application's secret data like passwords or tokens in any credential vault and select this option to use the secrets stored in vault. You can also enter the values for the secrets and choose to save in the vault.",
  'AOB.VaultConfig.savyintattribute': 'Saviynt Attribute',
  'AOB.VaultConfig.vaultattribute': 'Vault Attribute',
  'AOB.VaultConfig.format': 'Format',
  'AOB.VaultConfig.path': 'Vault Config',
  'AOB.VaultConfig.secretdata': 'Secret Data',
  'AOB.VaultConfig.savetovault.text': 'Do you want to save <b>Secret Data</b> in vault?',
  'AOB.VaultConfig.labelMappedAttribute': 'Map Vault Attribute',
  'AOB.VaultConfig.popUpHeader': 'Credential Vault Configuration',
  'AOB.VaultConfig.labelButtonSave': 'Save changes',
  'AOB.VaultConfig.labelButtonBack': 'Back',
  'AOB.VaultConfig.labelViewVaultData': 'View Vault Data',
  'AOB.VaultConfig.TestConnection.Validation':
    'Submit the vault configuration changes before testing the connection',
  'AOB.InstanceConfiguration.accountnameplaceholder': 'Account Name',
  'AOB.InstanceConfiguration.homepage.start': 'Get Started',
  'AOB.InstanceConfiguration.homepage.assistedconfig': 'Assisted configuration',
  'AOB.InstanceConfiguration.homepage.advancedconfig': 'Advanced configuration',
  'AOB.InstanceConfiguration.homepage.status': 'Latest Status',
  'AOB.InstanceConfiguration.homepage.enrolled': 'Enrollment is completed.',
  'AOB.InstanceConfiguration.homepage.pendingdeploy': 'Items are pending deployment!',
  'AOB.InstanceConfiguration.homepage.features': 'Features',
  'AOB.InstanceConfiguration.homepage.selected': 'Selected',
  'AOB.InstanceConfiguration.homepage.congratulations': 'Congratulations',
  'AOB.OnboardingGeneralApp.importForm.fieldgroup.advanced.account.shortDesc':
    'Advanced configurations for Accounts import',
  'AOB.OnboardingGeneralApp.importForm.fieldgroup.advanced.entitlement.shortDesc':
    'Advanced configurations for Entitlements import',
  'AOB.OnboardingGeneralApp.importForm.fieldgroup.advanced.entitlementAssociation.shortDesc':
    'Advanced configurations for Entitlement Associations import',
  'AOB.VaultConfig.labelConfigure': 'Configure',
  'AOB.ApplicationCatalog.tab.Catalog': 'Catalog',
  'AOB.ApplicationCatalog.CatalogTitle': 'Application Management',
  'AOB.ApplicationCatalog.CatalogManagementSubTitle': 'Enroll and manage applications',
  'AOB.ApplicationCatalog.CatalogManagement.ApplicationStatus': 'Pending Enrollments',
  'AOB.VaultConfig.description.v2':
    'Read or store sensitive connection details for this application in a vault.',
  'AOB.VaultConfig.title.v2': 'Use Credential Vault',
  'AOB.Onboarding.concectionConfig.messageBody':
    'You can skip adding the connection details now and specify them after the enrollment is completed ',
  'AOB.Onboarding.concectionConfig.cancelButton': 'Cancel',
  'AOB.Onboarding.concectionConfig.confirmButton': 'Skip',
  'AOB.Onboarding.basicgroup.name': 'Application',
  'AOB.Onboarding.basicgroup.description': 'Specify unique Application Name and Description',
  'AOB.Onboarding.ownergroup.name': '',
  'AOB.Onboarding.ownergroup.description':
    'Select the user or group responsible for this application',
  'AOB.OnboardingGeneralApp.import.download.popup.header': 'Download Sample Files',
  'AOB.OnboardingGeneralApp.import.download.popup.accounts': 'Accounts only',
  'AOB.OnboardingGeneralApp.import.download.popup.accountsEntitlements':
    'Accounts and Entitlements',
  'AOB.OnboardingGeneralApp.import.download.download': 'Download',
  'AOB.OnboardingGeneralApp.import.upload.popup.header': 'Upload More Files',
  'AOB.OnboardingGeneralApp.import.upload.popup.select': 'Select a file type to upload',
  'AOB.OnboardingGeneralApp.import.upload.popup.entitlements': 'Upload Entitlements',
  'AOB.OnboardingGeneralApp.import.upload.popup.entitlementassociation':
    'Upload Entitlement Association',
  'AOB.OnboardingGeneralApp.import.upload.popup.entitlements.description':
    'Provide the entitlements file associated with this application. This file should have the entitlements and entitlement types of this application. In addition, it can have other entitlement attributes and entitlement owners as well.',
  'AOB.OnboardingGeneralApp.import.upload.popup.entitlementassociation.description':
    'Provide the entitlement associations file for this application. This file should have the parent-child relationship between two entitlements which is driven through the parent-child relationship between entitlement types. These entitlement types can belong to same application or different applications.',
  'AOB.OnboardingGeneralApp.import.upload.popup.sampletext': 'Please refer to the sample file:',
  'AOB.OnboardingGeneralApp.import.upload.popup.selecttext': 'Upload',
  'AOB.OnboardingGeneralApp.import.upload.popup.entitlementsample': 'Sample Entitlements File',
  'AOB.OnboardingGeneralApp.import.upload.popup.entitlementassociationsample':
    'Sample Entitlement Association File',
  'AOB.OnboardingGeneralApp.import.upload.popup.droparea.title': 'Browse here',
  'AOB.OnboardingGeneralApp.import.upload.popup.droparea.subtitle':
    'Select only one file in .csv format',
  'AOB.Onboarding.deploy.header': 'You are almost done!',
  'AOB.Onboarding.deploy.completeEnroll': 'Complete Enrollment',
  'AOB.Onboarding.deploy.continueconfig': 'Continue Assisted Configuration',
  'AOB.saveButton': 'Save',
  'AOB.label.DynamicAttribute': 'Dynamic Attributes',
  'AOB.label.AddNew': 'Add New',
  'AOB.label.addAttribute': 'Add Attribute',
  'AOB.label.UpdateAttribute': 'Update Attribute',
  'AOB.OnboardingGeneralApp.provision.idbotv2.title': 'Automated - Identity Bot',
  'AOB.OnboardingGeneralApp.provision.servicedeskv2.title': 'Manual - Service Desk Integration',
  'AOB.OnboardingGeneralApp.provision.helpdeskv2.title': 'Manual - Saviynt Help Desk',
  'AOB.Policies.AccountCorrelation.description':
    'To correlate users and application accounts, define either a string Rule logic or an advanced function',
  'AOB.Policies.MappedApplication.description':
    'Configure dependent applications to be allowed during new account request',
  'AOB.Policies.EntitlementswithNewAccount.description':
    'Select entitlements to be added when provisioning a new account',
  'AOB.Policies.AccountNameRule.description':
    'Specify rule for naming an account during provisioning',
  'AOB.Policies.EmailTemplates.description':
    'Set email templates to send notifications based on status of provisioning tasks',
  'AOB.Policies.EmailTemplates.message':
    'This setting allows you to select an email template, associate it to a task and send emails based on the status of the task. You can reuse exiting templates for multiple tasks and status',
  'AOB.Policies.Certifiers.description':
    'Add certifiers for this application to run application owner campaigns',
  'AOB.Policies.Certifiers.addButtonLabel': 'Add Certifier',
  'AOB.Policies.Certifiers.removeButtonLabel': 'Remove Certifier',
  'AOB.Policies.Certifiers.add.description': 'Add certifier to the list',
  'AOB.OnboardingGeneralApp.provision.method.title': 'Provisioning Method',
  'AOB.InstanceConfiguration.Features': 'Features',
  'AOB.InstanceConfiguration.AccountNameRule.Desc':
    'To generate a unique name for the account, define either a string rule logic or an advanced function',
  'AOB.InstanceConfiguration.homepage.pendingdeployv2':
    'Configurations are pending to be committed!',
  'AOB.Onboarding.basicgroup.label.IamOwner': 'I am the owner',
  'AOB.OnboardingGeneralApp.basic.heading.welcome': 'Start with the Basics',
  'AOB.OnboardingGeneralApp.features.heading': 'Select Features',
  'AOB.OnboardingGeneralApp.Connection.heading': 'Connection',
  'AOB.OnboardingGeneralApp.Import.headingV2': 'Import Data',
  'AOB.OnboardingGeneralApp.Provisioning.heading': 'Provision Data',
  'AOB.OnboardingGeneralApp.AccountImport.heading': 'Account Import',
  'AOB.OnboardingGeneralApp.EntitlementImport.heading': 'Entitlement Import',
  'AOB.OnboardingGeneralApp.EntitlementAssociationImport.heading': 'Entitlement Association Import',
  'AOB.OnboardingGeneralApp.uploadDataFile.heading': 'Upload Data File',
  'AOB.OnboardingGeneralApp.methodPicker.heading':
    'Select how you’d like to import data for this application',
  'AOB.OnboardingGeneralApp.selectProvisionType.heading': 'Select how the data will be provisioned',
  'AOB.OnboardingGeneralApp.ProvisioningCheckbox.label': 'Use this integration for provisioning.',
  'AOB.AC3.PrivilegedAccounts.lable': 'Privileged Accounts',
  'AOB.AC3.InstanceTypes.lable': 'Instance Types',
  'AOB.VaultData.label': 'Vault Data',
  'AOB.JsonEditor.AdvancedDescription':
    'Provide any advanced value. This can be a groovy script having multiple conditional evaluations',
  'AOB.OnboardingGeneralApp.provision.servicedeskv3.title': 'Manual - Service Desk',
  'AOB.AC3.EntitlementNewAccount.deletebutton': 'Remove',
  'AOB.AC3.EntitlementNewAccount.cancelbutton': 'Cancel',
  'AOB.AC3.Rules.InitialCharLabel': 'Initial Char',
  'AOB.AC3.Preview.CommentsLabel': 'Comments',
  'AOB.ManageApplications.Configure': 'Configure',
  'AOB.JsonEditor.DiscardChanges': 'Do you want to discard?',
  'AOB.JsonEditor.InvalidJSON': 'Invalid JSON.',
  'AOB.JsonEditor.InvalidConfiguration': ' is an invalid configuration.',
  'AOB.JsonEditor.InvalidConfigurations': 's are invalid configurations.',
  'AOB.JsonBuilder.Validation.title': 'Error Alert',
  'AOB.JsonBuilder.Validation.Cancel': 'No',
  'AOB.JsonBuilder.Validation.Discard': 'Discard',
  'AOB.JsonBuilder.Confirm.title': 'Confirm Changes',
  'AOB.JsonBuilder.Confirm.content': 'Do you want to save the changes?',
  'AOB.JsonBuilder.Confirm.cancel': 'No',
  'AOB.JsonBuilder.Confirm.confirm': 'Yes',
  'AOB.Onboarding.StepTitle.ImportSubHeaderManual': 'Manual',
  'AOB.Onboarding.StepTitle.ImportSubHeaderAutomated': 'Automated',
  'AOB.Onboarding.methodPicker.heading':
    'Select the EIC features you want to enable for this application',
  'AOB.OnboardingGeneralApp.methodPicker.formHeading':
    'Enter details to automatically import your application data',
  'AOB.OnboardingGeneralApp.selectProvisionType.formHeading': '',
  'AOB.OnboardingGeneralApp.idBotTitle': 'Enable provisioning with your identity bot',
  'AOB.OnboardingGeneralApp.helpDeskTitle': 'Manual - Saviynt Help Desk',
  'AOB.OnboardingGeneralApp.disconnected.needHelp': 'Need file help?',
  'AOB.OnboardingGeneralApp.import.form.heading': 'Upload Application Data',
  'AOB.OnboardingGeneralApp.import.form.desc':
    'Start by providing the accounts file associated with this application. It can include entitlements, or those can be uploaded separately.',
  'AOB.OnboardingGeneralApp.import.sampleAccountFiles': 'Sample Account Files',
  'AOB.Onboarding.ConnectionForm.heading': 'Enter your application details',
  'AOB.Onboarding.file.import.upload.error':
    'This file cannot be uploaded. Number of headers doesn’t match the number of columns.',
  'AOB.Onboarding.file.import.upload.delimiter.not.found.error':
    'This file cannot be uploaded. File must have delimiter as Comma, Pipe or Tab',
  'AOB.Onboarding.file.import.upload.multiple.delimiter.error':
    'This file cannot be uploaded. Multiple delimiters ( #DM# ) are not allowed.',
  'AOB.Onboarding.file.import.upload.not.enough.data.error':
    'This file cannot be uploaded as it does not have any data',
  'AOB.OnboardingGeneralApp.UploadedFile.Delete': 'Delete',
  'AOB.OnboardingGeneralApp.UploadedFile.Replace': 'Replace',
  'AOB.OnboardingGeneralApp.UploadedFile.ReUpload': 'Re-Upload',
  'AOB.OnboardingGeneralApp.UploadedFile.PreviewAndEdit': 'Preview & Edit',
  'AOB.OnboardingGeneralApp.UploadedFile.Failed': 'Failed',
  'AOB.OnboardingGeneralApp.UploadedFile.title.prefix': 'Drop your #FILETYPE# File, or',
  'AOB.OnboardingGeneralApp.UploadedFile.HighlightAlert.E.Title': 'Upload Entitlement?',
  'AOB.OnboardingGeneralApp.UploadedFile.HighlightAlert.EE.Title': 'Upload More Entitlement?',
  'AOB.OnboardingGeneralApp.UploadedFile.HighlightAlert.EA.Title':
    'Upload Entitlement Associations?',
  'AOB.OnboardingGeneralApp.UploadedFile.UploadMoreFiles': 'Upload More Files',
  'AOB.OnboardingGeneralApp.UploadedFiles.Title': 'Uploaded Files',
  'AOB.Onboarding.file.import.upload.filetype.error': 'Filetype must be .csv file.',
  'AOB.Onboarding.file.import.upload.fileExists.error':
    'This file cannot be uploaded. A file with same name already exists.',
  'AOB.Onboarding.FilePreview.HeaderAccountAttribute': 'This is an Account Attribute',
  'AOB.FilePreview.Title': 'Preview #FILETYPE# Data',
  'AOB.FilePreview.Account.Desc.1':
    'We’ve organized the data from the file you uploaded into columns that correspond to your account’s entitlements and attributes.',
  'AOB.FilePreview.Account.Desc.2':
    'Please make sure it’s accurate, and fix any errors before saving.',
  'AOB.FilePreview.Entitlement.Desc.1':
    'Provide the entitlements file associated with this application.',
  'AOB.FilePreview.Entitlement.Desc.2':
    'This file should have the entitlements and entitlement types of this application. In addition, it can have other entitlement attributes and entitlement owners as well.',
  'AOB.FilePreview.EntitlementAssociation.Desc.1':
    'Provide the entitlement associations file for this application.',
  'AOB.FilePreview.EntitlementAssociation.Desc.2':
    'This file should have the parent-child relationship between two entitlements which is driven through the parent-child relationship between entitlement types. These entitlement types can belong to same application or different applications.',
  'AOB.FilePreview.FileError.General0': 'Errors found:',
  'AOB.FilePreview.FileError.General1': ' Please fix the flagged columns',
  'AOB.FilePreview.FileError.General2': 'to ensure each one is defined',
  'AOB.FilePreview.FileError.NoHeader0': 'No headers found:',
  'AOB.FilePreview.FileError.NoHeader1':
    'We couldn’t map your data to any header. Would you like to use the first line in each column as the header?',
  'AOB.FilePreview.ToastMessage.DataPreviewSaved': 'File preview has been saved',
  'AOB.FilePreview.FileError.ErrorHeader': '',
  'AOB.OnboardingGeneralApp.UploadedFile.HighlightAlert.Entitlment.Title': 'Upload Entitlements?',
  'AOB.OnboardingGeneralApp.UploadedFile.HighlightAlert.Entitlment.Description':
    'Because you uploaded an accounts file for this application, you may want to upload entitlements as well. For sample files and to upload entitlements, select Upload More Files.',
  'AOB.OnboardingGeneralApp.UploadedFile.HighlightAlert.EntitlmentMore.Title':
    'Upload Supplemental Entitlements?',
  'AOB.OnboardingGeneralApp.UploadedFile.HighlightAlert.EntitlmentMore.Description':
    'Because you uploaded entitlements for this application, you may want to upload additional permissions that have been granted to specific roles, groups, etc. For sample files and to upload supplemental entitlements, select Upload More Files.',
  'AOB.OnboardingGeneralApp.UploadedFile.HighlightAlert.EntitlmentAssoc.Title':
    'Upload Entitlement Associations?',
  'AOB.OnboardingGeneralApp.UploadedFile.HighlightAlert.EntitlmentAssoc.Description':
    'Because you uploaded entitlements for this application, you may want to define the relationships (e.g., parent-child) between entitlements. For sample files and to upload entitlement associations, select Upload More Files.',
  'AOB.Onboarding.basicgroup.label.owner': 'Application Owner',
  'AOB.Onboarding.basicgroup.label.ownerdesc':
    'Select the user or group responsible for this application',
  'AOB.Onboarding.basicgroup.ownertype.placeholder': 'Select owner type',
  'AOB.Onboarding.basicgroup.owner.placeholder': 'Select owner name',
  'AOB.OnboardingGeneralApp.import.see': 'See',

  // onboarding: update exisiting messages
  'AOB.OnboardingGeneralApp.import.account.desc':
    'Provide the accounts file associated with this application. It can include entitlements, or those can be uploaded separately.',
  // onborading:
  // AC3 file upload
  'AOB.Onboarding.file.import.upload.noofcols.count.error':
    "Number of columns in this file does not match with the earlier uploaded file's column count. Please correct the file and upload again.",
  'AOB.Onboarding.file.import.upload.cols.match.error':
    'Column names in this file does not match with the earlier uploaded file columns. Please correct the file and upload again.',
  'AOB.OnboardingGeneralApp.UploadedFile.AddEntitlements': 'Add Entitlements',
  'AOB.OnboardingGeneralApp.import.upload.popup.select.dataType': 'Select Data Type',
  'AOB.OnboardingGeneralApp.import.upload.popup.entitlements.select': 'Entitlements',
  'AOB.OnboardingGeneralApp.import.upload.popup.entitlementassociation.select':
    'Entitlement Association',
  'AOB.OnboardingGeneralApp.import.upload.popup.select.header': 'Add Entitlements',
  'AOB.OnboardingGeneralApp.import.upload.popup.alert.diffCols.error':
    'The edit applies to all uploaded #FILETYPE# files. Would you like to proceed?',
  'AOB.OnboardingGeneralApp.Confirm.no': 'No',
  'AOB.OnboardingGeneralApp.Confirm.yes': 'Yes',
  'AOB.OnboardingGeneralApp.Confirm.title': 'Confirm',
  'AOB.FilePreview.FileError.Error.Header': 'Error Found:',
  // NEW - NOT SHARED WITH PM TEAM
  // Onboarding - json editor error card - NOT pushed
  'AOB.JSONEditor.ErrorCard.Header': 'Error Found: ',
  'AOB.JSONEditor.ErrorCard.Header.Description': 'Please fix it before submitting the form.',
  'AOB.JSONEditor.ErrorCard.Mandatory.Title': 'Provide values in these mandatory fields:',
  'AOB.JSONEditor.ErrorCard.ViewDetails': 'View Details >',

  // fileimport - file upload error messages - NOT pushed
  'AOB.Onboarding.file.import.upload.fileNameSpace.error':
    'This file cannot be uploaded, file name should not contain spaces.',

  // saviynt exchange integration -  not pushed
  'AOB.ApplicationCatalog.AddNewApplication': 'Add an Application',
  'AOB.SavExchangeAppTypes.popup.header': 'App Integrations',
  'AOB.SavExchangeAppTypes.popup.desc':
    'Find and import integrations from Saviynt Exchange that will allow you to add the applications your organization needs.',
  'AOB.SavExchangeAppTypes.popup.poweredBy': 'Powered by Saviynt Exchange',
  'AOB.SavExchangeAppTypes.card.header': 'Add App Integration',
  'AOB.SavExchangeAppTypes.card.desc':
    'By importing the {app} integration, it will be available for you to enroll it as an application in your Saviynt environments. Would you like to import it now?',
  'AOB.SavExchangeAppTypes.card.cancel': 'Cancel',
  'AOB.SavExchangeAppTypes.card.import': 'Import',
  'AOB.ApplicationCatalog.enrollment.searchboxPlacewHolder': 'Search applications',
  'AOB.SavExchangeAppTypes.popup.searchboxPlacewHolder': 'Search app integrations',
  'AOB.SavExchangeAppTypes.alert.view': 'View',

  'AOB.SavExchangeAppTypes.alert.import.failed': ' Import Failed',
  'AOB.SavExchangeAppTypes.alert.import.message':
    'There was a problem with the network that prevented the integration from being imported. Please try again later.',
  // Onboarding - NOT pushed
  'AOB.OnboardingGeneralApp.Connection': 'Set Up *[label]Connection',
  // reconciliation field - NOT pushed
  'AOB.JSONEditor.mappertable.reconfield.label':
    'Select the attribute which will be used to check for object uniqueness',
  // IdentityRepositoryTitle
  IdentityRepositoryTitle: 'Identity Repository',

  // Rest configuration - NOT pushed
  'AOB.JsonBuilder.switch.toEditor.label': 'Switch to Editor View',
  'AOB.JsonBuilder.switch.toBuilder.label': 'Switch to Builder View',
  'AOB.JsonBuilder.switch.alert.title': 'Confirm',
  'AOB.JsonBuilder.switch.alert.message':
    'Do you want to save the changes before switching to another view?',
  'AOB.JsonBuilder.discardChanges': 'Discard',
  'AOB.JsonBuilder.switch.alert.confirm': 'Save',
  'AOB.JsonBuilder.cancelButton.label': 'Cancel',
  'AOB.JsonBuilder.successToast.message':
    'You can continue making changes if needed, or you can exit the page.',

  // UI - minor fix OM-4058  NOT Pushed
  'AOB.ApplicationCatalog.tab.AddApplications': 'Add Applications',
  'AOB.Onboarding.BasicInfoForm.heading': 'Describe your application and assign an owner',
  'AOB.Onboarding.InstanceConfiguration.HeaderDescriptionText':
    'Review your application configurations and customize as needed. Our assisted configuration gives you step-by-step guidance, and you’ll find more custom settings in advanced.',
  'AOB.SavExchangeAppTypes.popup.filterLabel': 'Show All',

  // UI - minor fix OM-4064  NOT Pushed
  'AOB.ApplicationCatalog.tab.Enrollment.Create.Label': 'Create',
  'AOB.ApplicationCatalog.tab.Enrollment.Create.Tooltip':
    'Configure an instance of this application',
  'AOB.ApplicationCatalog.FindApplication.GET.Label': 'Get',
  'AOB.SavExchangeAppTypes.button.Find.Applications': 'Find Applications',
  'AOB.SavExchangeAppTypes.Imported.Applications.Tooltip': 'Added from Saviynt Exchange',
  'AOB.SavExchangeAppTypes.Show.All.Applications.Tooltip':
    'Show app integrations already added to your catalog',
  'AOB.SavExchangeAppTypes.Configure.Message':
    'The Saviynt Exchange isn’t configured in your system. Please contact your administrator.',
  'AOB.ApplicationCatalog.Card.More.Label': 'More',
  'AOB.ApplicationCatalog.More.Details.ViewDocumentation.Label': 'View Documentation',
  'AOB.ApplicationCatalog.More.Details.Create.Label': 'Create',
  'AOB.ApplicationCatalog.More.Details.Get.Label': 'Get',
  'AOB.ApplicationCatalog.More.Details.Features.Label': 'Features',
  'AOB.ApplicationCatalog.More.Details.SoftwareVersion.Label': 'Software Version',
  'AOB.ApplicationCatalog.More.Details.EIC.Label': 'EIC',
  'AOB.ApplicationCatalog.More.Details.Published.Label': 'Published by',
  'AOB.SavExchangeAppTypes.Confirm.Import.Title': 'Get Application',
  'AOB.SavExchangeAppTypes.Confirm.Import.Get.Label': 'Get',
  'AOB.SavExchangeAppTypes.Confirm.Import.Cancel.Label': 'Cancel',
  'AOB.SavExchangeAppTypes.Confirm.Import.Message':
    'When you get this application from the Saviynt Exchange, it can’t be removed from your catalog, Would you like to get it now?',

  // OM-3978 label localization - NOT pushed
  'AOB.FileImport.view': 'View',
  'AOB.Disconnected.GeneralSelection.name': 'GenericSelection',
  'AOB.Disconnected.GeneralSelection.label': 'Use the same Generic Integration for Provisioning.',
  'AOB.AccountNameRule.Remove.alert.message': 'Rule will be removed permanently',
  'AOB.CorrelationRule.Remove.alert.message': 'Rule will be removed permanently',
  'AOB.Certifiers.search.placeholder': 'Search by Username',
  'AOB.InstanceConfiguration.button.edit': 'Edit',
  'AOB.InstanceConfiguration.button.NestStep': 'Next Step',
  'AOB.InstanceConfiguration.button.PreviousStep': 'Previous Step',
  'AOB.InstanceConfiguration.button.Finish': 'Finish',
  'AOB.InstanceConfiguration.button.Done': 'Done',
  'AOB.DataNotFound.title': 'No data available.',
  'AOB.DataNotFound.description': 'There is no data to show you right now',
  'AOB.InstanceConfiguration.ConfirmDelete': 'Confirm Delete',
  'AOB.DnD.Application': 'Application',
  'AOB.DnD.None': 'None',
  'AOB.DynamicAttribute.name': 'DYNAMIC ATTRIBUTES',
  'AOB.DynamicAttribute.DESCRIPTION': 'DESCRIPTION',
  'AOB.DynamicAttribute.VALUES': 'VALUES',
  'AOB.DynamicAttribute.Remove': 'Remove',
  'AOB.DynamicAttribute.Manage': 'Manage',
  'AOB.EmailTemplate.TaskType': 'Task Type',
  'AOB.EmailTemplate.EmailActions': 'Email Actions',
  'AOB.EmailTemplate.EmailTemplate': 'Email Template',
  'AOB.EmailTemplate.Remove': 'Remove',
  'AOB.EmailTemplate.CancelEdit': 'Cancel Edit',
  'AOB.Entitlements.Nolabels': 'No labels',
  'AOB.Entitlements.CustomProperty': 'Custom Property',
  'AOB.Entitlements.Yes': 'Yes',
  'AOB.Entitlements.No': 'No',
  'AOB.Entitlements.True': 'True',
  'AOB.Entitlements.False': 'False',
  'AOB.Homepage.CommitChanges': 'Commit Changes',
  'AOB.SchemaMapper.SAVIYNT': 'SAVIYNT',
  'AOB.SchemaMapper.APPLICATION': 'APPLICATION',
  'AOB.SchemaMapper.TYPE': 'TYPE',
  'AOB.JsonBuilder.Clear': 'Clear',
  'AOB.JsonBuilder.Add': 'Add',
  'AOB.RuleSwitcher.alert.message': 'Existing rules will be lost permanently',
  'AOB.SuccessToast.ChangeSaved': 'Changes Saved:',
  'AOB.InstanceAccounts.Search.placeholder': 'Search something',
  'AOB.InstanceEntitlement.Manage': 'Manage',
  'AOB.InstanceEntitlement.title': 'Entitlement Types',
  'AOB.PrivilegedAccount.Option.PAM': 'PAM Controlled',
  'AOB.PrivilegedAccount.Option.NOPAM': 'Not PAM Controlled',

  // OM-3980 label localization - NOT pushed
  'AOB.PAGINATION.SHOWING.Label': 'Showing',
  'AOB.PAGINATION.ENTRIES.Label': 'entries',
  'AOB.PAGINATION.TO.Label': 'to',
  'AOB.PAGINATION.OF.Label': 'of',

  // OM-4108 - NOT pushed
  'AOB.ApplicationListing.Create.Button.Label': 'Create New #APP# Instance',

  // OM-4025 - NOT pushed
  'AOB.TestConnection.alert.title': 'Warning',
  'AOB.TestConnection.alert.content':
    'Test connection can be done only after changes are committed or discarded',
  'AOB.TestConnection.alert.confirm': 'OK',

  // OM-4214 - NOT pushed
  'AOB.Applications.Type.Label': 'Application Types',

  // OM-4011 - NOT pushed
  'MappedApplications.ApplicationType.Label': 'Application Type',

  // OM-4611 - NOT pushed
  'AOB.DnD.Done.Label': 'Done',
  'AOB.Certifiers.AddOwner.Username.Label': 'User Name',
  'AOB.Certifiers.AddOwner.Firstname.Label': 'First Name',
  'AOB.Certifiers.AddOwner.Lastname.Label': 'Last Name',
  'AOB.Entitlements.InstanceTypes.Name.Label': 'Name',
  'AOB.Entitlements.InstanceTypes.Description.Label': 'Description',
  'AOB.Entitlements.PriviledgesTab.Attribute.Label': 'Attribute',
  'AOB.Entitlements.PriviledgesTab.Type.Label': 'Typ1',
  'AOB.Entitlements.PriviledgesTab.DefaultValue.Label': 'Default Value',
  'AOB.Entitlements.PriviledgesTab.UpdateDate.Label': 'Update Date',
  'AOB.Entitlements.PriviledgesTab.UpdateUser.Label': 'Update User',
  'AOB.Entitlements.EntitlementValuesTab.EntitlementName.Label': 'Entitlement Name',
  'AOB.Entitlements.EntitlementValuesTab.EntitlementType.Label': 'Entitlement Type',
  'AOB.Entitlements.EntitlementValuesTab.SOXCritical.Label': 'SOX Critical',
  'AOB.EntitlementswithNewAccount.EntitlementsList.EntitlementValue.Label': 'Entitlement Value',
  'AOB.EntitlementswithNewAccount.EntitlementsList.Description.Label': 'Description',
  'AOB.EntitlementswithNewAccount.EntitlementsList.Action.Label': 'Action',
  'AOB.EntitlementswithNewAccount.AddEntitlementsList.Search.Label':
    'Search for Entitlement Values',
  'AOB.EntitlementswithNewAccount.AddEntitlementsList.EntitlementValue.Label': 'Entitlement Value',
  'AOB.ApplicationCatalog.More.Details.SaviyntCertified.Label': 'Saviynt Certified',
  'AOB.ApplicationCatalog.More.Details.SaviyntVerified.Label': 'Saviynt Verified',
  'AOB.ApplicationCatalog.More.Details.Unverified.Label': 'Unverified',
  'AOB.Onboarding.Step.Label': 'Step',

  // OM-4638
  'AOB.Compliance.ExternalRiskConnection.SODEvaluation.Label': 'SOD Evaluation',
  'AOB.Compliance.ExternalRiskConnection.OutlierAnalysis.Label': 'Outlier Analysis',
  'AOB.Compliance.ExternalRiskConnection.RiskScoreEvaluation.Label': 'Risk Score Evaluation',
  'AOB.Compliance.ExternalRiskConnection.RetryCount.Label': 'Retry Count',
  'AOB.Compliance.ExternalRiskConnection.SaveRequest.Label': 'Save Request',

  // OM-4747
  'AOB.Policies.AccountCorrelation.Restrictions.SpecialCharacters.Tooltip':
    'Add special characters that need to be preserved (e.g., the “@” in an email address)',
  'AOB.Policies.AccountCorrelation.Restrictions.SpecialCharacters.Placeholder':
    'Enter allowed special characters (@.-_)',
  'AOB.TestConnection.Success.Description': 'You can now save this connection, and you can edit it later if needed.',
  'AOB.TestConnection.Failure.Description': 'However you can edit these configurations later.',
  'AOB.TestConnection.Failure.Title': 'Connection Failed',
  'AOB.TestConnection.Failure.Title.Description': 'Some issues are preventing a successful connection and need to be corrected.',

  // OM-5359
  'AOB.ApplicationCatalog.Version.Details.WhatsNew.Label': 'What’s New',
  'AOB.ApplicationCatalog.Version.Details.WhatsNew.in': 'in V.',
  'AOB.ApplicationCatalog.Version.Details.wantmore': 'Want more details?',
  'AOB.ApplicationCatalog.Version.Details.viewreleasenotes': 'See release notes & history',
  'AOB.ApplicationCatalog.Version.Details.importantnote': 'IMPORTANT: This update won’t be automatically applied to all instances of this application.',
  'AOB.ApplicationCatalog.Version.Details.skip': 'Skip',
  'AOB.ApplicationCatalog.Version.newversion.label': 'New Version Available',
  'AOB.ApplicationCatalog.Version.WhatsNew.nodata': 'No Data Available',
  'AOB.ApplicationCatalog.Version.WhatsNew.version': 'Version',
  'AOB.ApplicationCatalog.Version.WhatsNew.current': 'Current Version',

  // OM-5364
  'AOB.ApplicationCatalog.Version.Update.Success.Title': 'Your Application Is Up to Date',
  'AOB.ApplicationCatalog.Version.Update.Success.Msg': 'You can now update your instances to the latest version, or you can create a new instance.',
  'AOB.ApplicationCatalog.Version.Update.Failure.Title': 'Your App Couldn’t Be Updated',
  'AOB.ApplicationCatalog.Version.Update.Failure.Msg': 'You can try updating again or continue using the current version. If the problem persists, check your logs to see what’s causing the error.',
  'AOB.ApplicationCatalog.Version.Update.Success.buttonPrimary': 'Create an Instance',
  'AOB.ApplicationCatalog.Version.Update.Failure.buttonPrimary': 'Close',
  'AOB.ApplicationCatalog.More.Details.SoftwareVersion.later': 'and later',
  'AOB.ApplicationCatalog.More.Details.SoftwareVersion.versionlabelsuffix.short': 'v.',
  'AOB.ApplicationCatalog.Version.Update.button.label': 'Install Update',

  // OM-7428 - Instance update
  'AOB.ApplicationCatalog.Instance.Update.Available.Title': 'Do you want to update this application instance?',
  'AOB.ApplicationCatalog.Instance.Update.Available.NotifyButton': 'See What’s New',
  'AOB.ApplicationCatalog.Version.Update.Success.buttonSecondary': 'Update Instances',
  'AOB.ApplicationCatalog.Instance.Update.listing.Tooltip': 'New version available. You can install the update on the configuration page.',
  'AOB.ApplicationCatalog.Instance.Update.WhatsNew.ConfigRequired': 'Configurations are required after installing the update.',
  'AOB.ApplicationCatalog.Instance.Update.Error.Generic.Title': 'Your App Couldn’t Be Updated',
  'AOB.ApplicationCatalog.Instance.Update.Error.Generic.Description': 'You can try updating again or continue using the current version. If the problem persists, check your logs to see what’s causing the error.',
  'AOB.ApplicationCatalog.Instance.Update.Error.hasDraft.Title': 'Update Unavailable',
  'AOB.ApplicationCatalog.Instance.Update.Error.hasDraft.Description': 'You’ve made configuration changes that haven’t been committed yet. If you want to install the update, you must first commit or discard the changes.',
  'AOB.ApplicationCatalog.Instance.Update.Error.ChildApp.Title': 'Update Unavailable',
  'AOB.ApplicationCatalog.Instance.Update.Error.ChildApp.Description': 'To update this instance to the latest version, you need to install the update on the main parent application, ###APPNAME###.',
  'AOB.ApplicationCatalog.Instance.Update.Success.Title': 'Success!',
  'AOB.ApplicationCatalog.Instance.Update.Success.Description': 'Your ###APPNAME### application instance has been updated to the latest version.',

  // OM-8377 - Discard update
  'AOB.ApplicationCatalog.Instance.Update.DiscardDraft.button': 'Discard Update',
  'AOB.ApplicationCatalog.Instance.Update.DiscardDraft.Success': 'Successfully discarded the pending updates',
  'AOB.ApplicationCatalog.Instance.Update.DiscardDraft.Failure': 'Failed to discard the updates',
  'AOB.ApplicationCatalog.Instance.Update.Info.ParentApp': 'By installing this update, all child instances of this application will be updated to the latest version.',

  // OM-7763
  'AOB.ApplicationCatalog.Instance.Update.Warning.ConfigRequired.Title': 'Configurations Required',
  'AOB.ApplicationCatalog.Instance.Update.Warning.ConfigRequired.Description': 'To finish installing this update, complete the required configurations before committing.',
  'AOB.ApplicationCatalog.Instance.Update.ConfigRequired.DisabledMenu.Tooltip': 'This is unavailable during required configurations',
  'AOB.ApplicationCatalog.Instance.Update.Commit.Button': 'Commit Saved Changes',
  'AOB.ApplicationCatalog.Instance.Update.Error.Exit.Title': 'Update Incomplete',
  'AOB.ApplicationCatalog.Instance.Update.Error.Exit.Description': 'This application instance can’t be updated until the required configurations are complete and the changes are committed.',
  'AOB.ApplicationCatalog.Instance.Update.DiscardDraft.button.primary': 'Continue Updating',
  'AOB.ApplicationCatalog.Instance.Update.Error.fieldsMissing.Title': 'Required Fields (###ATTRCOUNT###) Missing',
  'AOB.ApplicationCatalog.Instance.Update.Error.switchPageAlert.title': 'Save Changes?',
  'AOB.ApplicationCatalog.Instance.Update.Error.switchPageAlert.Description': 'You’ve made changes that haven’t been saved. Would you like to save them before exiting?',
  'AOB.ApplicationCatalog.Instance.Update.Draft.Discard': 'Discard Changes',
  'AOB.ApplicationCatalog.Instance.Update.Error.fieldsMissing.Description': 'To finish installing this update, complete the required configurations before committing.',
  'AOB.ApplicationCatalog.Instance.Update.Draft.Complete.Title': 'Commit Required',
  'AOB.ApplicationCatalog.Instance.Update.Draft.Complete.Description': 'To finish installing this update, commit saved changes',
  'AOB.Application.Enrollment.Success': 'Application onboarded successfully',
  'AOB.InstanceConfiguration.ImageUpload.Back': 'Back',
  'AOB.InstanceConfiguration.ImageUpload.Upload': 'Upload',
  'AOB.InstanceConfiguration.ImageUpload.Delete': 'Delete',
  'AOB.InstanceConfiguration.ImageUpload.Change': 'Change Icon',
  'AOB.InstanceConfiguration.ImageUpload.Cancel': 'Cancel',
  'AOB.InstanceConfiguration.ImageUpload.DragNDropMessageLine1': 'Only .svg files. Max 20KB. ',
  'AOB.InstanceConfiguration.ImageUpload.DragNDropMessageLine2':'Square dimensions produce best results.',
  'AOB.InstanceConfiguration.ImageUpload.Header': 'Update Your Application Icon',
  'AOB.InstanceConfiguration.ImageUpload.DeleteConfirm': 'Delete Icon?',
  'AOB.InstanceConfiguration.ImageUpload.DeleteConfirmSubtitle': "If you delete an icon that's currently in use, the default placeholder icon will replace it. Do you want to delete it?",
  'AOB.InstanceConfiguration.ImageUpload.DefaultImageMessage': 'If no application icon is uploaded, this default icon will be displayed.',
};

export { messages };
