/* eslint-disable import/prefer-default-export */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

/**
 * Custom hook that uses media queries to determine screen size and updates the screen size label in the Redux store.
 * The hook takes an action creator as an argument and uses the useDispatch hook from react-redux to obtain the dispatch function.
 * It evaluates the media queries and dispatches the action to update the state with the appropriate label based on the current screen width.
 *
 * @param {function} updateScreenSizeAction - Action creator that updates the screen size label in the Redux store.
 */

// UX Design Proposed Breakpoints and Naming Structure:
// Mobile:
// 360px: 130 MobileXSmall (This is below our minimum, but it’s a common Android size, adding it here just in case)
// 375px: 120 MobileSmall
// 480px: 110 MobileLarge
// 600px: 100 MobileXLarge

// Tablet:
// 768px: 90 TabletXSmall
// 834px: 80 TabletMedium
// 960px: 70 TabletLarge
// 1024px: 60 TabletXLarge

// Desktop:
// 1280px: 50 DesktopSmall
// 1366px: 40 DesktopMedium
// 1440px: 30 DesktopLarge
// 1600px: 20 DesktopXLarge
// 1920px: 10 DesktopXXLarge

const useResponsiveValue = (updateScreenSizeAction, queries) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const evaluateMediaQueries = () => {
      const activeQuery = queries.find(
        (q) => window.matchMedia(q.query).matches
      );

      dispatch(updateScreenSizeAction(activeQuery ? activeQuery.value : 0));
    };

    window.addEventListener('resize', evaluateMediaQueries);
    evaluateMediaQueries();

    return () => {
      window.removeEventListener('resize', evaluateMediaQueries);
    };
  }, [dispatch, updateScreenSizeAction]);
};

export { useResponsiveValue };
