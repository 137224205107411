import React, { useState, useRef, useEffect } from 'react';
import { A } from '../../A';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { PropTypes, shape } from 'prop-types';
import { ColumnType } from 'ui-common/src/lib/commonPropTypes';
import { injectIntl } from 'react-intl';
import './UniqueFieldFilter.scss';

import Chip from '@material-ui/core/Chip';
import { ScrollPaginationDropdown } from 'ui-components/src';
import { convertReduxToApi, getUIMappedStatusValues } from 'ui-common/src/lib/utils';
import { Cookies } from 'react-cookie';
import styles from '../GridBoot.module.scss';

const PaginatedUniqueFieldFilter = ({
  column,
  options,
  tableId,
  showColumnFilter,
  setShowColumnFilter,
  sortedColumn,
  setSortedColumn,
  distinctColumnValues,
  checkedFilters,
  setCheckedFilters,
  appliedFilters,
  intl,
  onChange,
  value,
  reqBodyOptions,
  isArchive,
  resultCount,
  ...props
}) => {
  const { key } = column;
  const [items, setItems] = useState(options);
  const [resCount, setResultCount] = useState(resultCount);
  const cookies = new Cookies();

  async function getNextColumnValues(max, offset, search, clearList) {
    // Do ajax and get distinct column values.
    const { key } = column;
    const apiFiltersObj = {};
    Object.keys(appliedFilters).forEach(key => {
      if (Array.isArray(appliedFilters[key])) {
        const value = appliedFilters[key].length
          ? column.filter.filterURL.includes(`requests/`)
            ? appliedFilters[key].join(';')
            : appliedFilters[key].join()
          : undefined;
        apiFiltersObj[convertReduxToApi('columnvalues')[key] || key] = value;
      }
      if (typeof appliedFilters[key] === 'string') {
        const value = appliedFilters[key].length ? appliedFilters[key] : undefined;

        apiFiltersObj[convertReduxToApi('columnvalues')[key] || key] = value;
      }
    });
    const defaultRequestBody = {
      connectiontype: 'AWS',
      accounttype: 'FIREFIGHTERID',
      userKey: cookies.get('userKey'),
      orderBy: 'asc',
      filterby: {
        ...apiFiltersObj,
        [convertReduxToApi('columnvalues')[key] || key]: undefined,
      },
      column: convertReduxToApi('columnvalues')[key] || key,
    };
    let requestBody = {
      ...defaultRequestBody,
      ...column.options,
      ...reqBodyOptions,
    };
    if (column.filter && column.filter.defaultReqBody) {
      requestBody = { ...requestBody, ...column.filter.defaultReqBody };
      if (column.filter.defaultReqBody.filterBy) {
        requestBody.filterBy = requestBody.filterby;
      }
    }
    if (column.filter.filterURL.includes(`requests/`)) {
      requestBody = {
        offset: 0,
        max: 25,
        filterBy: {
          ...apiFiltersObj,
          [convertReduxToApi('columnvalues')[key] || key]: undefined,
        },
      };
      requestBody.column = convertReduxToApi('columnvalues')[key] || key;
      requestBody.username = cookies.get('user_name');
    }
    requestBody.offset = offset;
    requestBody.max = max;
    requestBody.search = search;

    if (isArchive) requestBody.showArchival = true;

    const response = await fetch(column.filter.filterURL, {
      method: 'POST',
      body: JSON.stringify(requestBody),
      headers: {
        'Content-Type': 'application/json',
        'X-NOLOADER': true,
      },
    });

    try {
      let finalresp = await response.json();
      // if (column.filter.filterURL.includes(`requests/`) && requestBody.column === 'status') {
      //   finalresp.data = await getUIMappedStatusValues(finalresp.data, true);
      // }
      setResultCount(finalresp.count);
      finalresp = finalresp.data;
      if (finalresp && finalresp['']) delete finalresp[''];
      finalresp = Object.keys(finalresp).map(field => ({
        id: field,
        title:
          column.filter && column.filter.intlSupportRequired
            ? field
              ? intl.formatMessage({ id: field })
              : field
            : field,
      }));
      if (clearList) {
        setItems(finalresp);
      } else {
        setItems([...items].concat(finalresp));
      }
    } catch (error) {
      // error
    }
    return null;
  }

  useEffect(() => {
    setItems(options);
  }, [options]);

  useEffect(() => {
    setResultCount(resultCount);
  }, [resultCount]);

  const fetchItems = async (offset, search, clearList) => {
    if (clearList) await setItems([]);
    getNextColumnValues(25, offset, search, clearList);
  };

  const menuStyle = { minWidth: 450 };

  return (
    <ScrollPaginationDropdown
      multiSelect
      fetchItems={fetchItems}
      items={items}
      totalCount={resCount}
      offset={25}
      placeholder={`Select ${intl.formatMessage({ id: column.label })}'s`}
      _handleChange={onChange}
      attributeKey={column.key}
      from="applicationRequest"
      module="grid"
      position="relative"
      defaultValue={value}
      hideCheckMark
      itemHeight={40}
      {...props}
    />
  );
};

PaginatedUniqueFieldFilter.propTypes = {
  column: ColumnType.isRequired,
  showColumnFilter: PropTypes.func.isRequired,
  setShowColumnFilter: PropTypes.func.isRequired,
  sortedColumn: PropTypes.shape({
    columnName: PropTypes.string,
    order: PropTypes.string,
  }).isRequired,
  setSortedColumn: PropTypes.func.isRequired,
  distinctColumnValues: PropTypes.shape({
    key: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  checkedFilters: PropTypes.shape({
    key: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  setCheckedFilters: PropTypes.func.isRequired,
  appliedFilters: PropTypes.shape({
    key: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};
PaginatedUniqueFieldFilter.defaultProps = {
  // column: DefaultColumnType,
};

const PaginatedUniqueFieldFilterIntl = injectIntl(PaginatedUniqueFieldFilter);

export { PaginatedUniqueFieldFilterIntl as PaginatedUniqueFieldFilter };

export default injectIntl(PaginatedUniqueFieldFilter);
