import { createAction } from 'typesafe-actions';

import { type MenuId } from '../../../../models';

export const kpisRequested = createAction('kpis/kpisRequested')<void>();

export const kpisReceived = createAction('kpis/kpisReceived')<MenuId[]>();

export const kpisNotReceived = createAction('kpis/kpisNotReceived')<void>();

export const kpisUnauthorized = createAction('kpis/kpisUnauthorized')<void>();

export const kpiRequested = createAction('kpis/kpiRequested')<MenuId>();

export const kpiReceived = createAction('kpis/kpiReceived')<{
  id: MenuId;
  metric: number;
}>();

export const kpiNotReceived = createAction('kpis/kpiNotReceived')<MenuId>();
