
import React from 'react';

const ResponsiveBackIcon = () => (
<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
<path d="M27.3333 8.22266C27.7015 8.22266 28 8.52113 28 8.88932C28 9.25751 27.7015 9.55599 27.3333 9.55599H12.6667C12.2985 9.55599 12 9.25751 12 8.88932C12 8.52113 12.2985 8.22266 12.6667 8.22266H27.3333Z" fill="var(--SIDEMENU-ICON-HAMBURGER)"/>
<path d="M27.3333 15.3338C27.7015 15.3338 28 15.6322 28 16.0004C28 16.3686 27.7015 16.6671 27.3333 16.6671H6.27625L8.69374 19.0846C8.95408 19.3449 8.95408 19.767 8.69374 20.0274C8.43339 20.2877 8.01128 20.2877 7.75093 20.0274L4.2025 16.479C4.07761 16.3578 4 16.1882 4 16.0004C4 15.8394 4.05713 15.6916 4.15223 15.5764C4.16582 15.5599 4.18018 15.5441 4.19526 15.529C4.19524 15.5291 4.19528 15.529 4.19526 15.529L7.75093 11.9735C8.01129 11.7131 8.4334 11.7131 8.69374 11.9735C8.95409 12.2338 8.95408 12.6559 8.69373 12.9163L6.27617 15.3338H27.3333Z" fill="var(--SIDEMENU-ICON-HAMBURGER)"/>
<path d="M28 23.1115C28 22.7434 27.7015 22.4449 27.3333 22.4449H12.6667C12.2985 22.4449 12 22.7434 12 23.1115C12 23.4797 12.2985 23.7782 12.6667 23.7782H27.3333C27.7015 23.7782 28 23.4797 28 23.1115Z" fill="var(--SIDEMENU-ICON-HAMBURGER)"/>
</svg>

);

export default ResponsiveBackIcon;







