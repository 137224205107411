/* tslint:disable */
import React from 'react';
import UploadView from './UploadView';
import UploadedView from './UploadedView';
import DownloadView from './DownloadView';

export interface FileUploadPanelProps {
  /**
   * if file upload is completed
   */
  isUploaded: boolean;
  downloadPanelTitle: string;
  uploadPanelTitle: string;
  dragDropLabel: string;
  uploadNote: string;
  uploadErrorMsg: string;
  uploadBtnText: string;
  onUpload: () => void;
  className: string;
  uploadedLabel: string;
  onCloseUploadedPanel: () => void;
  uploadedFileName: string;
  previewImagePath: string;
  onDownload: () => void;
  downloadedLabelMsg: string;
  downloadFileName: string;
}

const FileUploadPanel: React.FC<FileUploadPanelProps> = ({
  isUploaded,
  downloadPanelTitle,
  uploadPanelTitle,
  dragDropLabel,
  uploadNote,
  uploadErrorMsg,
  uploadBtnText,
  onUpload,
  className,
  uploadedLabel,
  onCloseUploadedPanel,
  uploadedFileName,
  previewImagePath,
  onDownload,
  downloadedLabelMsg,
  downloadFileName,
}) => {
  return (
    <div className={className}>
      {isUploaded ? (
        <UploadedView
          uploadedLabel={uploadedLabel}
          uploadedFile={uploadedFileName}
          previewImage={previewImagePath}
          click={onCloseUploadedPanel}
        />
      ) : (
        <div>
          <DownloadView
            downloadLabel={downloadPanelTitle}
            downloadFile={downloadFileName}
            downloadClick={onDownload}
          />
          <hr /> <br />
          <UploadView
            uploadLabel={uploadPanelTitle}
            dragDropLabel={dragDropLabel}
            uploadSubmit={uploadBtnText}
            noteDescription={uploadNote}
            errorDescription={uploadErrorMsg}
            uploadedFiles={onUpload}
          />
        </div>
      )}
    </div>
  );
};
export default FileUploadPanel;